import { PaginatedResponse } from '@frontend/api-utils';
import { EntityType } from '@frontend/common';

export interface Identification {
    type: IdentificationType;
    entity_type: EntityType | null;
    entity_id: string | null;
    valid_timestamp: string | null;
    update_timestamp: string | null;
    key: string;
    id: string;
    account_id: string;
    creation_timestamp: string;
    secret: string | null;
}

export interface CreateIdentification {
    secret?: string | null;
    type?: IdentificationType;
    entity_type?: EntityType | null;
    entity_id?: string | null;
    key?: string;
    valid_timestamp?: string | null;
}

export interface UpdateIdentification {
    key?: string;
    secret?: string | null;
    entity_type?: EntityType | null;
    entity_id?: string | null;
    valid_timestamp?: string | null;
}

export interface UpdateIdentificationUnlink extends UpdateIdentification {
    null_fields?: string[];
}

export enum IdentificationType {
    ACCESS_KEY = 'access_key',
    BADGE = 'badge',
    BASIC_AUTH = 'basic_auth',
    IDENTIFICATION = 'identification',
    IOT = 'iot',
    TAG = 'tag'
}
export type IdentificationListResponse = PaginatedResponse<Identification>;

export enum IdentificationQueryParams {
    TYPE = 'type',
    ENTITY_TYPE = 'entity_type',
    ENTITY_ID = 'entity_id',
    KEY = 'key'
}
