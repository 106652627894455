import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

const AnswerOverview = lazy(() => import('../../question/answer/answer-overview'));
const AnswerCreate = lazy(() => import('../../question/answer/answer-create/answer-create.component'));
const AnswerDetail = lazy(() => import('../../question/answer/answer-detail/answer-detail.component'));
const AnswerUpdate = lazy(() => import('../../question/answer/answer-update/answer-update.component'));

export function AnswerRoutes() {
    return (
        <Route path='answers'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <AnswerOverview />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <AnswerCreate />
                    </AsyncComponent>
                }
            />
            <Route path=':answerId'>
                <Route
                    path='detail'
                    element={
                        <AsyncComponent>
                            <AnswerDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <AnswerUpdate />
                        </AsyncComponent>
                    }
                />
            </Route>
            <Route element={<Navigate to='../detail' />} />
        </Route>
    );
}
