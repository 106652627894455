import { IdentificationClient } from '@frontend/identification/api';
import { Identification, UpdateIdentification } from '@frontend/identification/types';
import { useAccountRepository } from '@frontend/repository';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

interface ViewProps {
    formValues: UpdateIdentification;
    changeFormValues: React.Dispatch<React.SetStateAction<UpdateIdentification>>;
    onCancel: () => void;
    onSubmitSuccess: (identification: Identification) => void;
    isValidIndefinitely: boolean;
    changeIsValidIndefinitely: React.Dispatch<React.SetStateAction<boolean>>;
    identification: Identification | null;
}

const useIdentificationUpdate = (): ViewProps => {
    const navigate = useNavigate();
    const {
        store: { currentAccount }
    } = useAccountRepository();
    const { identificationId } = useParams();
    const [formValues, changeFormValues] = useState<UpdateIdentification>({});
    const [isValidIndefinitely, changeIsValidIndefinitely] = useState<boolean>(false);
    const [identification, changeIdentification] = useState<Identification | null>(null);

    useEffect(() => {
        if (!currentAccount || !identificationId) return;
        IdentificationClient.fetchIdentification(currentAccount, identificationId).then((res) => {
            changeIdentification(res);
            changeFormValues({ ...res });
        });
    }, [currentAccount]);

    const onCancel = () => {
        navigate(-1);
    };

    const onSubmitSuccess = (identification: Identification) => {
        onCancel();
    };

    return {
        formValues,
        changeFormValues,
        onCancel,
        onSubmitSuccess,
        isValidIndefinitely,
        changeIsValidIndefinitely,
        identification
    };
};

export default useIdentificationUpdate;
