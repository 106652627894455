import { APIClient, ApiQueryParams } from "@frontend/api-utils";
import { CertificateRequirement, CertificateRequirementListResponse, CertificateRequirementQueryParams, CreateCertificateRequirement } from "@frontend/certificate/types";

const endpoint = '/certificate-api/v1';
export class CertificateRequirementClient extends APIClient {
    public static async fetchCertificateRequirements(queryParams?: ApiQueryParams<CertificateRequirementQueryParams>): Promise<CertificateRequirementListResponse> {
        return await this.apiPaginated<CertificateRequirementListResponse, CertificateRequirementQueryParams>(`${endpoint}/certificate-requirements`, queryParams);
    }

    public static async resolveCertificateRequirements(ids: string[]): Promise<CertificateRequirementListResponse> {
        const response = await this.post(`${endpoint}/certificate-requirements-resolve`, { ids });
        return await this.handleResponse<CertificateRequirementListResponse>(response);
    }

    public static async deleteCertificateRequirements(ids: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/certificate-requirements-delete`, { ids });
        return await this.handleVoidResponse(response);
    }

    public static async postCertificateRequirement(accountId: string, certificateId: string, certificateRequirement: CreateCertificateRequirement): Promise<CertificateRequirement> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/certificates/${certificateId}/requirements`, certificateRequirement);
        return await this.handleResponse<CertificateRequirement>(response);
    }

    public static async fetchCertificateRequirement(accountId: string, certificateId: string, certificateRequirementId: string): Promise<CertificateRequirement> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/certificates/${certificateId}/requirements/${certificateRequirementId}`);
        return await this.handleResponse<CertificateRequirement>(response);
    }
    public static async deleteCertificateRequirement(accountId: string, certificateId: string, certificateRequirementId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/certificates/${certificateId}/requirements/${certificateRequirementId}`);
        return await this.handleVoidResponse(response);
    }
}