import { CheckboxInput } from '@frontend/basic-forms';
import { EntityType } from '@frontend/common';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import useCustomRoleTemplateSelect from './custom-role-template-select.controller';

const ID = 'role-permission-template-select';
export interface CustomRoleTemplateSelectProps {
    id?: string;
    label?: string;
    className?: string;
    required?: boolean;
    submitted: boolean;
    entity_type?: EntityType;
    onChange: (newValue: { [key: string]: string[] }) => void;
    value?: { [key: string]: string[] };
}

export const CustomRoleTemplateSelect = (props: CustomRoleTemplateSelectProps) => {
    const viewProps = useCustomRoleTemplateSelect(props);
    return (
        <form
            id={ID}
            className='d-flex flex-column'>
            <label>
                {props.label && props.required ? <span className='text-danger me-1'>&#9679;</span> : <></>}
                {props.label}
            </label>
            <div>
                {viewProps.options &&
                    Object.keys(viewProps.options).map((key) => {
                        return (
                            <span
                                key={key}
                                className='d-flex'
                                style={{ borderBottom: '1px solid #e9ecef' }}>
                                <p className='col-3'>{key}: </p>
                                <div className='d-flex flex-wrap w-100'>
                                    {viewProps.options![key].map((v) => (
                                        <CheckboxInput
                                            key={v}
                                            id={key + '-' + v}
                                            label={v}
                                            className='me-3 col-2'
                                            value={props.value && props.value[key] ? props.value[key].includes(v) : false}
                                            onChange={(newValue) => viewProps.onChange(key, v, newValue)}
                                            submitted={props.submitted}
                                        />
                                    ))}
                                </div>
                            </span>
                        );
                    })}
                {props.entity_type === undefined ? (
                    <span className='badge bg-gradient-warning mt-2'>
                        <small>
                            <FormattedMessage
                                id='rolePermissionTemplate.select.selectEntityTypeWarning'
                                defaultMessage='First select a entity type.'
                                description='Warning telling the user to select an entity type'
                            />
                        </small>
                    </span>
                ) : (
                    viewProps.options === undefined && (
                        <span className='badge bg-gradient-warning mt-2'>
                            <small>
                                <FormattedMessage
                                    defaultMessage='No possible permissions for this entity type.'
                                    description='Warning telling the user that there are no possible permissions for this entity type'
                                    id='rolePermissionTemplate.select.noPermissionsWarning'
                                />
                            </small>
                        </span>
                    )
                )}
            </div>
        </form>
    );
};

export default CustomRoleTemplateSelect;
