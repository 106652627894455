
import { EventListener } from '@frontend/pub-sub';
import { CustomRole } from '@frontend/role/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

export class CustomRoleEventListener extends EventListener<CustomRole> {
    
    private static instance: CustomRoleEventListener | null = null;
    private constructor(dispatch: ThunkDispatch<any, any, Action>) {
        super('authorization', dispatch);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>): CustomRoleEventListener {
        if (this.instance == null) {
            this.instance = new CustomRoleEventListener(dispatch);
        }
        return this.instance;
    }

    getInstance(dispatch: ThunkDispatch<any, any, Action>): EventListener<CustomRole> {
        return CustomRoleEventListener.getInstance(dispatch);
    }

    protected onUpdate() {}

    protected onDelete() {}

    protected onCreate() {}
}