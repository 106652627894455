import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { Budget, BudgetListResponse, BudgetQueryParams, CreateBudget, UpdateBudget } from '@frontend/budget/types';

const endpoint = '/budget-api/v1';
export class BudgetClient extends APIClient {
    public static async fetchBudgets(queryParams?: ApiQueryParams<DefaultQueryParams & BudgetQueryParams>): Promise<BudgetListResponse> {
        return await this.apiPaginated<BudgetListResponse, DefaultQueryParams & BudgetQueryParams>(`${endpoint}/budgets`, queryParams);
    }

    public static async resolveBudgets(ids: string[]): Promise<BudgetListResponse> {
        const response = await this.post(`${endpoint}/budgets-resolve`, { ids: ids });
        return await this.handleResponse<BudgetListResponse>(response);
    }

    public static async deleteBudgets(ids: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/budgets-delete`, { ids: ids });
        return await this.handleVoidResponse(response);
    }

    public static async fetchAccountBudgets(accountId: string, queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<BudgetListResponse> {
        return await this.apiPaginated<BudgetListResponse, DefaultQueryParams>(`${endpoint}/accounts/${accountId}/budgets`, queryParams);
    }

    public static async postBudget(accountId: string, body: CreateBudget): Promise<Budget> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/budgets`, body);
        return await this.handleResponse<Budget>(response);
    }

    public static async fetchAccountBudget(accountId: string, budgetId: string): Promise<Budget> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/budgets/${budgetId}`);
        return await this.handleResponse<Budget>(response);
    }

    public static async patchBudget(accountId: string, budgetId: string, body: UpdateBudget): Promise<Budget> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/budgets/${budgetId}`, body);
        return await this.handleResponse<Budget>(response);
    }

    public static async deleteBudget(accountId: string, budgetId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/budgets/${budgetId}`);
        return await this.handleVoidResponse(response);
    }

    public static async fetchUserBudgets(accountId: string, userId: string, queryParams?: ApiQueryParams<BudgetQueryParams>): Promise<BudgetListResponse> {
        return this.apiPaginated<BudgetListResponse, BudgetQueryParams>(`${endpoint}/accounts/${accountId}/users/${userId}/budgets`, queryParams);
    }

    public static async postIoTSeed(accountId: string, iotId: string, body: { account_id: string }): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots/${iotId}/seed`, body);
        return this.handleResponse(response);
    }
}
