import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { ConstraintRoutes } from '../constraint/constraint-routes';
import { ModuleRoutes } from '../module/module-routes';
import { RequirementRoutes } from '../requirement/requirement-routes';

const SpotOverview = lazy(() => import('../spot/spot-overview'));
const SpotCreate = lazy(() => import('../spot/spot-create/spot-create.component'));
const SpotDetail = lazy(() => import('../spot/spot-detail/spot-detail.component'));
const SpotUpdate = lazy(() => import('../spot/spot-update/spot-update.component'));
const SpotSettingsUpdate = lazy(() => import('../spot/spot-settings-update/spot-settings-update.component'));

const ConstraintRoute = ConstraintRoutes();
const ModuleRoute = ModuleRoutes();
const RequirementRoute = RequirementRoutes();

export function SpotRoutes() {
    return (
        <Route path='spots'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <SpotOverview />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <SpotCreate />
                    </AsyncComponent>
                }
            />
            <Route path=':spotId'>
                <Route
                    index
                    path='detail'
                    element={
                        <AsyncComponent>
                            <SpotDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <SpotUpdate />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='settings/update'
                    element={
                        <AsyncComponent>
                            <SpotSettingsUpdate />
                        </AsyncComponent>
                    }
                />
                {/** 
                      // #region Account spot constraints
                     */}
                {ConstraintRoute}
                {ModuleRoute}
                {RequirementRoute}
            </Route>
            <Route
                index
                element={<Navigate to='/admin/spots' />}
            />
        </Route>
    );
}
