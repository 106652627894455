import { APIClient, ApiQueryParams, buildApiEndpoint } from '@frontend/api-utils';
import { BarSeries, ChartQueryParams, HeatmapChartData, TotalBarData } from '@frontend/chart/types';

const endpoint = '/stock-api/v1';

export class StockChartClient extends APIClient {
    public static async fetchTotalFillRate(accountId: string, queryParams?: ApiQueryParams<ChartQueryParams>): Promise<TotalBarData> {
        const url = buildApiEndpoint(`${endpoint}/accounts/${accountId}/fill-rate-total`, queryParams);
        const response = await this.fetch(url);
        return await this.handleResponse<TotalBarData>(response);
    }

    public static async fetchFillRateByProduct(accountId: string, queryParams?: ApiQueryParams<ChartQueryParams>): Promise<BarSeries> {
        const url = buildApiEndpoint(`${endpoint}/accounts/${accountId}/fill-rate-by-product`, queryParams);
        const response = await this.fetch(url);
        return await this.handleResponse<BarSeries>(response);
    }

    public static async fetchFillRateBySpot(accountId: string, queryParams?: ApiQueryParams<ChartQueryParams>): Promise<BarSeries> {
        const url = buildApiEndpoint(`${endpoint}/accounts/${accountId}/fill-rate-by-spot`, queryParams);
        const response = await this.fetch(url);
        return await this.handleResponse<BarSeries>(response);
    }

    public static async fetchOccupiedSlots(
        accountId: string,
        spotId: string,
        moduleId: string,
        queryParams?: ApiQueryParams<ChartQueryParams>
    ): Promise<HeatmapChartData> {
        const url = buildApiEndpoint(`${endpoint}/accounts/${accountId}/spots/${spotId}/modules/${moduleId}/occupied-slots`, queryParams);
        const response = await this.fetch(url);
        return await this.handleResponse<HeatmapChartData>(response);
    }
}
