import { ClassType } from '@frontend/common';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export enum EquipmentStateName {
    EQUIPMENT_CREATED = 'equipment_created',
    EQUIPMENT_INITIALIZING = 'equipment_initializing',
    EQUIPMENT_INITIALIZED = 'equipment_initialized',
    EQUIPMENT_NEW = 'equipment_new',
    EQUIPMENT_AVAILABLE = 'equipment_available',
    EQUIPMENT_IN_USE = 'equipment_in_use',
    EQUIPMENT_MAINTENANCE = 'equipment_maintenance',
    EQUIPMENT_CHARGING = 'equipment_charging',
    EQUIPMENT_UNAVAILABLE = 'equipment_unavailable',
    EQUIPMENT_CERTIFICATION = 'equipment_certification',
    EQUIPMENT_LOST = 'equipment_lost',
    EQUIPMENT_BROKEN = 'equipment_broken',
    EQUIPMENT_CONTAMINATED = 'equipment_contaminated',
    UNKNOWN = '',
}

export class EquipmentState {
    static readonly EQUIPMENT_CREATED = new EquipmentState(
        EquipmentStateName.EQUIPMENT_CREATED,
        (
            <FormattedMessage
                id='EquipmentState.equipment_created'
                description='The displayed value for the EquipmentState equipment_created'
                defaultMessage='Equipment created'
            />
        )
    );

    static readonly EQUIPMENT_INITIALIZING = new EquipmentState(
        EquipmentStateName.EQUIPMENT_INITIALIZING,
        (
            <FormattedMessage
                id='EquipmentState.equipment_initializing'
                description='The displayed value for the EquipmentState equipment_initializing'
                defaultMessage='Equipment initializing'
            />
        )
    );

    static readonly EQUIPMENT_INITIALIZED = new EquipmentState(
        EquipmentStateName.EQUIPMENT_INITIALIZED,
        (
            <FormattedMessage
                id='EquipmentState.equipment_initialized'
                description='The displayed value for the EquipmentState equipment_initialized'
                defaultMessage='Equipment initialized'
            />
        )
    );

    static readonly EQUIPMENT_NEW = new EquipmentState(
        EquipmentStateName.EQUIPMENT_NEW,
        (
            <FormattedMessage
                id='EquipmentState.equipment_new'
                description='The displayed value for the EquipmentState equipment_new'
                defaultMessage='Equipment new'
            />
        )
    );

    static readonly EQUIPMENT_AVAILABLE = new EquipmentState(
        EquipmentStateName.EQUIPMENT_AVAILABLE,
        (
            <FormattedMessage
                id='EquipmentState.equipment_available'
                description='The displayed value for the EquipmentState equipment_available'
                defaultMessage='Equipment available'
            />
        )
    );

    static readonly EQUIPMENT_IN_USE = new EquipmentState(
        EquipmentStateName.EQUIPMENT_IN_USE,
        (
            <FormattedMessage
                id='EquipmentState.equipment_in_use'
                description='The displayed value for the EquipmentState equipment_in_use'
                defaultMessage='Equipment in use'
            />
        )
    );

    static readonly EQUIPMENT_MAINTENANCE = new EquipmentState(
        EquipmentStateName.EQUIPMENT_MAINTENANCE,
        (
            <FormattedMessage
                id='EquipmentState.equipment_maintenance'
                description='The displayed value for the EquipmentState equipment_maintenance'
                defaultMessage='Equipment maintenance'
            />
        )
    );

    static readonly EQUIPMENT_CHARGING = new EquipmentState(
        EquipmentStateName.EQUIPMENT_CHARGING,
        (
            <FormattedMessage
                id='EquipmentState.equipment_charging'
                description='The displayed value for the EquipmentState equipment_charging'
                defaultMessage='Equipment charging'
            />
        )
    );

    static readonly EQUIPMENT_UNAVAILABLE = new EquipmentState(
        EquipmentStateName.EQUIPMENT_UNAVAILABLE,
        (
            <FormattedMessage
                id='EquipmentState.equipment_unavailable'
                description='The displayed value for the EquipmentState equipment_unavailable'
                defaultMessage='Equipment unavailable'
            />
        )
    );

    static readonly EQUIPMENT_CERTIFICATION = new EquipmentState(
        EquipmentStateName.EQUIPMENT_CERTIFICATION,
        (
            <FormattedMessage
                id='EquipmentState.equipment_certification'
                description='The displayed value for the EquipmentState equipment_certification'
                defaultMessage='Equipment certification'
            />
        )
    );

    static readonly EQUIPMENT_LOST = new EquipmentState(
        EquipmentStateName.EQUIPMENT_LOST,
        (
            <FormattedMessage
                id='EquipmentState.equipment_lost'
                description='The displayed value for the EquipmentState equipment_lost'
                defaultMessage='Equipment lost'
            />
        )
    );

    static readonly EQUIPMENT_BROKEN = new EquipmentState(
        EquipmentStateName.EQUIPMENT_BROKEN,
        (
            <FormattedMessage
                id='EquipmentState.equipment_broken'
                description='The displayed value for the EquipmentState equipment_broken'
                defaultMessage='Equipment broken'
            />
        )
    );

    static readonly EQUIPMENT_CONTAMINATED = new EquipmentState(
        EquipmentStateName.EQUIPMENT_CONTAMINATED,
        (
            <FormattedMessage
                id='EquipmentState.equipment_contaminated'
                description='The displayed value for the EquipmentState equipment_contaminated'
                defaultMessage='Equipment contaminated'
            />
        )
    );

    static readonly ALL = [
        EquipmentState.EQUIPMENT_CREATED,
        EquipmentState.EQUIPMENT_INITIALIZING,
        EquipmentState.EQUIPMENT_INITIALIZED,
        EquipmentState.EQUIPMENT_NEW,
        EquipmentState.EQUIPMENT_AVAILABLE,
        EquipmentState.EQUIPMENT_IN_USE,
        EquipmentState.EQUIPMENT_MAINTENANCE,
        EquipmentState.EQUIPMENT_CHARGING,
        EquipmentState.EQUIPMENT_UNAVAILABLE,
        EquipmentState.EQUIPMENT_CERTIFICATION,
        EquipmentState.EQUIPMENT_LOST,
        EquipmentState.EQUIPMENT_BROKEN,
        EquipmentState.EQUIPMENT_CONTAMINATED,
    ];

    private constructor(public readonly value: EquipmentStateName, public readonly displayName: React.ReactNode) {}

    static getByValue(action: EquipmentStateName): EquipmentState | undefined {
        return this.ALL.find((a) => a.value == action);
    }

    static getType(state: EquipmentStateName): ClassType {
        switch (state) {
            case EquipmentStateName.EQUIPMENT_AVAILABLE:
                return ClassType.SUCCESS;
            case EquipmentStateName.EQUIPMENT_BROKEN:
            case EquipmentStateName.EQUIPMENT_CONTAMINATED:
            case EquipmentStateName.EQUIPMENT_UNAVAILABLE:
            case EquipmentStateName.EQUIPMENT_LOST:
                return ClassType.DANGER;
            case EquipmentStateName.EQUIPMENT_IN_USE:
            case EquipmentStateName.EQUIPMENT_MAINTENANCE:
            case EquipmentStateName.EQUIPMENT_CHARGING:
                return ClassType.WARNING;
            case EquipmentStateName.EQUIPMENT_INITIALIZING:
                return ClassType.SECONDARY;
            case EquipmentStateName.EQUIPMENT_CREATED:
            case EquipmentStateName.EQUIPMENT_INITIALIZED:
            case EquipmentStateName.EQUIPMENT_NEW:
            case EquipmentStateName.EQUIPMENT_CERTIFICATION:
                return ClassType.INFO;
        }

        return ClassType.DANGER;
    }
}
