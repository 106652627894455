import React from 'react';

const ID = 'progress-circle';

export enum Color {
    GREEN = 'green',
    RED = 'red',
    YELLOW = 'yellow'
}

export interface ProgressCircleProps {
    percentage: number;
    colorCallback?: (percentage: number) => Color;
}

export const ProgressCircle = (props: ProgressCircleProps) => {
    const circleRef = React.useRef<SVGCircleElement>(null);

    const updateProgress = () => {
        const precentage = props.percentage > 100 ? 100 : props.percentage < 0 ? 0 : props.percentage;
        const element = circleRef.current;
        if (element) {
            // Calculate the stroke-dashoffset based on the percentage
            const circleRadius = 20; // This is the value of 'r' in the <circle> element
            const circumference = 2 * Math.PI * circleRadius;
            const offset = circumference - (circumference * precentage) / 100;

            element.style.strokeDashoffset = offset.toString();
        }
    };

    const getCollor = (value: number) => {
        updateProgress();
        if (props.colorCallback) {
            return props.colorCallback(value);
        }
        if (value < 30) {
            return Color.RED;
        }
        if (value < 75) {
            return Color.YELLOW;
        }
        return Color.GREEN;
    };

    return (
        <div
            id={ID}
            className={`progress-container ${getCollor(props.percentage)}`}>
            <div className='progress-circle'>
                <span id='progress-text'>{props.percentage}%</span>
                <svg
                    className='progress-ring'
                    width='60'
                    height='60'>
                    <circle
                        className='progress-ring__background'
                        strokeWidth='5'
                        fill='transparent'
                        r='20'
                        cx='30'
                        cy='30'></circle>
                    <circle
                        ref={circleRef}
                        className='progress-ring__circle'
                        strokeWidth='5'
                        fill='transparent'
                        r='20'
                        cx='30'
                        cy='30'></circle>
                </svg>
            </div>
        </div>
    );
};

export default ProgressCircle;
