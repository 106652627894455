import { BudgetChartClient } from '@frontend/chart/api';
import { useAccountRepository } from '@frontend/repository';
import { ApexOptions } from 'apexcharts';
import { useEffect, useMemo, useState } from 'react';

import { ConsumptionProps } from './consumption.component';

interface ViewProps {
    options: ApexOptions;
    data: ApexAxisChartSeries | ApexNonAxisChartSeries;
}

const useConsumption = (props: ConsumptionProps): ViewProps => {
    const { store } = useAccountRepository();
    const [data, changeData] = useState<ApexAxisChartSeries | ApexNonAxisChartSeries>([]);

    useEffect(() => {
        if (!store.currentAccount) return;
        BudgetChartClient.fetchConsumption(store.currentAccount, { ...props }).then((res) => changeData(res.series));
    }, [store.currentAccount, props]);

    const options: ApexOptions = useMemo(() => {
        return {
            chart: {
                type: 'area',
                stacked: false,
                height: 350,
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true
                },
                toolbar: {
                    autoSelected: 'zoom'
                }
            },
            dataLabels: {
                enabled: false
            },
            markers: {
                size: 0
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    inverseColors: false,
                    opacityFrom: 0.5,
                    opacityTo: 0,
                    stops: [0, 90, 100]
                }
            },
            yaxis: {
                title: {
                    text: 'Amount'
                }
            },
            xaxis: {
                type: 'datetime'
            }
        };
    }, []);

    return {
        options,
        data
    };
};

export default useConsumption;
