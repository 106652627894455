import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

const ProblemCreate = lazy(() => import('../problem/problem-create/problem-create.component'));
const ProblemDetail = lazy(() => import('../problem/problem-detail/problem-detail.component'));
const ProblemUpdate = lazy(() => import('../problem/problem-update/problem-update.component'));
const ProblemOverview = lazy(() => import('../problem/problem-overview/problem-overview'));
const ProblemEntityLink = lazy(() => import('../problem/problem-entity-link/problem-entity-link/problem-entity-link.component'));

export function ProblemRoutes() {
    return (
        <Route path='problems'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <ProblemOverview />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <ProblemCreate />
                    </AsyncComponent>
                }
            />
            <Route
                index
                element={<Navigate to='/admin/problems' />}
            />
            <Route path=':problemId'>
                <Route
                    path='detail'
                    element={
                        <AsyncComponent>
                            <ProblemDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <ProblemUpdate />
                        </AsyncComponent>
                    }
                />
                <Route path=':entityType'>
                    <Route
                        path='link'
                        element={
                            <AsyncComponent>
                                <ProblemEntityLink />
                            </AsyncComponent>
                        }
                    />
                    <Route
                        index
                        element={<Navigate to='../detail' />}
                    />
                </Route>
            </Route>
        </Route>
    );
}
