import { HorizontalButtonGroupButton, HorizontalButtonGroupProps } from './horizontal-button-group.component';

interface ViewProps {
    style: any;
    buttons: HorizontalButtonGroupButton[];
}

const useHorizontalButtonGroup = (props: HorizontalButtonGroupProps): ViewProps => {
    const buttons = props.buttons.filter((b) => !b.hide);
    const flexDirection = props.direction === 'right' ? 'row-reverse' : 'row'; // Adjust flex direction
    const justifyContent = props.direction === 'right' ? 'flex-end' : 'flex-start'; // Adjust justify content
    const style: React.CSSProperties = {
        whiteSpace: 'nowrap',
        display: props.noFlex ? '' : 'flex',
        flexDirection: flexDirection,
        justifyContent: justifyContent,
        flexWrap: 'wrap',
        gap: '10px'
    };
    return { style, buttons };
};

export default useHorizontalButtonGroup;
