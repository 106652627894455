import { Header, flexRender } from '@tanstack/react-table';
import React from 'react';

interface Props {
    header: Header<any, unknown>;
    sort: boolean;
    className?: string;
}

export function THeaderItem(props: Props) {
    if (props.header.id === 'selection' || props.header.id === 'expand') {
        return (
            <th
                key={props.header.id}
                colSpan={props.header.colSpan}
                className={props.className || 'ps-2'}>
                {flexRender(props.header.column.columnDef.header, props.header.getContext())}
            </th>
        );
    }
    return (
        <th
            key={props.header.id}
            colSpan={props.header.colSpan}
            onClick={() => props.header.column.toggleSorting()}
            className={
                ((!props.sort && props.className) || 'text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 ') +
                (props.header.column.getIsSorted() ? (props.header.column.getIsSorted() === 'desc' ? 'desc' : 'asc') : '')
            }>
            {props.sort ? (
                <a className={'dataTable-sorter ' + props.className}>{flexRender(props.header.column.columnDef.header, props.header.getContext())}</a>
            ) : (
                flexRender(props.header.column.columnDef.header, props.header.getContext())
            )}
        </th>
    );
}
