import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { Answer, AnswerListResponse, AnswerQueryParams, CreateAnswer, UpdateAnswer } from '@frontend/question/types';

const endpoint = '/question-api/v1';
export class AnswerClient extends APIClient {
    public static async fetchAnswers(queryParams?: ApiQueryParams<DefaultQueryParams | AnswerQueryParams>): Promise<AnswerListResponse> {
        return await this.apiPaginated<AnswerListResponse, DefaultQueryParams | AnswerQueryParams>(`${endpoint}/answers`, queryParams);
    }

    public static async resolveAnswers(ids: string[]): Promise<AnswerListResponse> {
        const response = await this.post(`${endpoint}/answers-resolve`, { ids: ids });
        return await this.handleResponse<AnswerListResponse>(response);
    }

    public static async deleteAnswers(ids: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/answers-delete`, { ids: ids });
        return await this.handleVoidResponse(response);
    }

    public static async postAnswer(accountId: string, question_id: string, body: CreateAnswer): Promise<Answer> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/questions/${question_id}/answers`, body);
        return await this.handleResponse<Answer>(response);
    }

    public static async patchAnswer(accountId: string, question_id: string, answerId: string, body: UpdateAnswer): Promise<Answer> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/questions/${question_id}/answers/${answerId}`, body);
        return await this.handleResponse<Answer>(response);
    }

    public static async fetchAnswer(accountId: string, question_id: string, answerId: string): Promise<Answer> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/questions/${question_id}/answers/${answerId}`);
        return await this.handleResponse<Answer>(response);
    }

    public static async deleteAnswer(accountId: string, question_id: string, answerId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/questions/${question_id}/answers/${answerId}`);
        return await this.handleVoidResponse(response);
    }
}
