import { Action, ThunkDispatch } from '@reduxjs/toolkit';

import { EntityType } from '@frontend/common';
import { useResolver } from '@frontend/repository';
import { TransactionClient } from '@frontend/transaction/api';
import React from 'react';

declare global {
    interface WindowEventMap {
        'useTransaction:request': CustomEvent<string>;
    }
}

const REQUEST_TRANSACTION_EVENT = 'useTransaction:request';
const STORAGE_KEY = 'TRX_REQ';

export function transactionRequest(transactionId: string): string {
    window.dispatchEvent(new CustomEvent(REQUEST_TRANSACTION_EVENT, { detail: transactionId }));
    return STORAGE_KEY + '_loading';
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function TransactionResolver(props: Props) {
    useResolver(STORAGE_KEY, REQUEST_TRANSACTION_EVENT, EntityType.TRANSACTION, (ids) => TransactionClient.resolveTransactions(ids), props.dispatch);
    return <></>;
}
