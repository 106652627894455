import { EntityType } from '@frontend/common';
import { ProblemClient } from '@frontend/problem/api';
import { useResolver } from '@frontend/repository';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

declare global {
    interface WindowEventMap {
        'useProblem:request': CustomEvent<string>;
    }
}

const REQUEST_PROBLEM_EVENT = 'useProblem:request';
const STORAGE_KEY = 'PRB_REQ';

export function problemRequest(problemId: string): string {
    window.dispatchEvent(new CustomEvent(REQUEST_PROBLEM_EVENT, { detail: problemId }));
    return STORAGE_KEY + '_loading';
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function ProblemResolver(props: Props) {
    useResolver(STORAGE_KEY, REQUEST_PROBLEM_EVENT, EntityType.PROBLEM, (ids) => ProblemClient.resolveProblems(ids), props.dispatch);
    return <></>;
}
