import { CommonMessage } from '@frontend/lang';

import StatisticsDetail from '../../statistics-detail/statistics-detail.component';
import Consumption from '../consumption.component';

export interface ConsumptionDetailProps {
    allowBack?: boolean;
}

const ConsumptionDetail = (props: ConsumptionDetailProps) => {
    return (
        <StatisticsDetail
            title={CommonMessage.STATISTICS.VENDING.CONSUMPTION}
            chart={(args) => <Consumption {...args} />}
            filters={{ keys: [] }}
            allowBack={props.allowBack}
        />
    );
};

export default ConsumptionDetail;
