import { Locale, getCookie, setCookie } from '@frontend/common';
import { useEffect, useState } from 'react';
import { MessageFormatElement } from 'react-intl';

import * as de from './de.json';
import * as en from './en.json';
import * as fr from './fr.json';
import * as nl from './nl.json';

export const useLocale = () => {
    const [messages, changeMessages] = useState<Record<string, string> | Record<string, MessageFormatElement[]>>(en);
    const [locale, setLocale] = useState<Locale>();
    const defaultBrowserLanguage = getCorrectLocale(window.navigator.language);

    const listener = () => {
        const cookieLanguage = getCookie('language');
        if (cookieLanguage !== undefined) {
            setLocale(getCorrectLocale(cookieLanguage.toString()));
        } else {
            setLocale(getCorrectLocale(defaultBrowserLanguage.toString()));
        }
    };

    useEffect(() => {
        listener();
        window.addEventListener('languageChanged', listener);
        return () => {
            window.removeEventListener('languageChanged', listener);
        };
    }, []);

    useEffect(() => {
        if (locale) {
            changeMessages(messagesMap.get(locale));
        }
    }, [locale]);

    const changeLocale = (locale: Locale) => {
        setCookie('language', locale.valueOf(), 365);
        window.dispatchEvent(new CustomEvent('languageChanged'));
    };

    return { messages, locale, changeLocale } as const;
};
export default useLocale;

export function getCorrectLocale(lang: string): Locale {
    switch (lang) {
        case 'en':
        case 'en-US':
            return Locale.ENGLISH;
        case 'nl-NL':
        case 'nl-BE':
        case 'nl':
            return Locale.DUTCH;
        case 'fr':
        case 'fr-FR':
        case 'fr-BE':
            return Locale.FRENCH;
        case 'de':
        case 'de-DE':
            return Locale.GERMAN;
        default:
            return Locale.ENGLISH;
    }
}

const messagesMap = new Map<Locale, any>();
messagesMap.set(Locale.ENGLISH, en);
messagesMap.set(Locale.DUTCH, nl);
messagesMap.set(Locale.FRENCH, fr);
messagesMap.set(Locale.GERMAN, de);
