import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import {
    CreateNotificationContent,
    NotificationContent,
    NotificationContentListResponse,
    NotificationContentPreview,
    Placeholder,
    UpdateNotificationContent
} from '@frontend/notification-service/types';

const endpoint = '/notification-api/v1';

export class NotificationContentClient extends APIClient {
    public static async fetchNotificationContents(queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<NotificationContentListResponse> {
        return await this.apiPaginated<NotificationContentListResponse, DefaultQueryParams>(`${endpoint}/contents`, queryParams);
    }

    public static async resolveNotificationContents(ids: string[]): Promise<NotificationContentListResponse> {
        const response = await this.post(`${endpoint}/contents-resolve`, { ids: ids });
        return await this.handleResponse<NotificationContentListResponse>(response);
    }

    public static async fetchAccountNotificationContents(
        accountId: string,
        templateId: string,
        queryParams?: ApiQueryParams<DefaultQueryParams>
    ): Promise<NotificationContentListResponse> {
        return await this.apiPaginated<NotificationContentListResponse, string | DefaultQueryParams>(
            `${endpoint}/accounts/${accountId}/templates/${templateId}/contents`,
            queryParams
        );
    }

    public static async postNotificationContent(accountId: string, templateId: string, content: CreateNotificationContent): Promise<NotificationContent> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/templates/${templateId}/contents`, content);
        return await this.handleResponse<NotificationContent>(response);
    }

    public static async fetchNotificationContent(accountId: string, templateId: string, contentId: string): Promise<NotificationContent> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/templates/${templateId}/contents/${contentId}`);
        return await this.handleResponse<NotificationContent>(response);
    }

    public static async patchNotificationContent(
        accountId: string,
        templateId: string,
        contentId: string,
        content: UpdateNotificationContent
    ): Promise<NotificationContent> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/templates/${templateId}/contents/${contentId}`, content);
        return await this.handleResponse<NotificationContent>(response);
    }

    public static async deleteNotificationContent(accountId: string, templateId: string, contentId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/templates/${templateId}/contents/${contentId}`);
        return await this.handleVoidResponse(response);
    }

    public static async fetchPlaceholders(): Promise<Placeholder> {
        const response = await this.fetch(`${endpoint}/placeholders`);
        return await this.handleResponse<Placeholder>(response);
    }

    public static async postNotificationPreview(accountId: string, body: NotificationContentPreview): Promise<NotificationContentPreview> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/preview`, body);
        return await this.handleResponse<NotificationContentPreview>(response);
    }
}
