import { ApiQueryParams, DefaultQueryParams, PaginatedResponse } from '@frontend/api-utils';
import { Permission } from '@frontend/authorization';
import { ClassType, EntityType, Locale } from '@frontend/common';
import { ConfirmationModal, HorizontalButtonGroupButton, TablePlaceholder } from '@frontend/elements';
import { Export, Import } from '@frontend/impex/components';
import { FormatTypeName, ImportResponse } from '@frontend/impex/types';
import { DefaultListValues } from '@frontend/repository';
import { RowElement, Table, TableProps } from '@frontend/table';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { ColumnDef } from '@tanstack/react-table';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { FilterOptions, HeaderFilter } from '../header/header-filter';
import { ObjectColumnsProps } from '../object-column-mapper';
import { ObjectListActionButtons } from './object-list-action-buttons';
import useObjectList from './object-list.controller';

export interface ObjectListProps<T extends { id: string }> {
    listStoreIdCallback?: (id: string) => void;
    label?: React.ReactNode;
    fetch: (arg?: ApiQueryParams<DefaultQueryParams | string | number>) => Promise<PaginatedResponse<T>>;
    dispatch: ThunkDispatch<any, any, Action>;
    eventListener?: EntityType;
    id?: string;
    columns: Omit<ObjectColumnsProps<T>, 'dispatch'>;
    multiSelectOptions?: MultiSelectOptions<T>;
    onSelectElement?: (object: T) => void;
    sortingColumns?: string[];
    create?: {
        label?: React.ReactNode;
        onCreate: () => void;
        requiredPermissions?: Permission[];
        overwriteCreateLabel?: React.ReactNode;
    };
    includeDeleted?: {
        requiredPermissions: Permission[];
    };
    type: EntityType;
    filter?: FilterOptions<T>;
    selectedObjects?: (obj: T[]) => void;
    defaultListValues?: DefaultListValues;

    cardClassName?: string;
    cardHeaderClassName?: string;
    cardBodyClassName?: string;
    customButtons?: HorizontalButtonGroupButton[];
    customHeaderComponent?: React.ReactNode;
    tableProps?: { [K in keyof TableProps]?: TableProps[K] };
    hideColumns?: string[];

    showCount?: boolean;
    locale?: Locale;
}

export interface MultiSelectOptions<T> {
    delete?: {
        enabled: (obj: T[]) => boolean;
        delete: (ids: string[]) => Promise<void>;
        parent?: React.ReactNode;
        requiredPermissions?: Permission[];
    };
    import?: {
        fetchTemplate: () => Promise<void>;
        import: (args: { accountId: string; file: File; dry_run: boolean }) => Promise<ImportResponse>;
        type: EntityType;
        requiredPermissions?: Permission[];
    };
    export?: {
        export: (format: FormatTypeName) => Promise<void>;
        type: EntityType;
        requiredPermissions?: Permission[];
    };
    custom?: HorizontalButtonGroupButton[];
}

export const ObjectList = <T extends { id: string }>(props: ObjectListProps<T>) => {
    const viewProps = useObjectList<T>(props);

    return (
        <>
            <div
                id={props.id}
                className={props.cardClassName !== undefined ? props.cardClassName : 'card mt-3'}>
                <div
                    className={
                        props.cardHeaderClassName !== undefined
                            ? props.cardHeaderClassName
                            : 'card-header d-flex flex-wrap justify-content-between align-items-stretch w-100 pb-0'
                    }>
                    <div className='d-flex flex-column align-items-start flex-md-row justify-content-md-start align-items-md-center w-100'>
                        <div className='order-md-1 order-2 w-md-35 mb-md-0 mb-2'>
                            <ObjectListActionButtons<T>
                                {...props}
                                {...viewProps}
                            />
                        </div>
                        <h4 className='order-md-2 order-1 m-0 ms-2 w-md-30 mb-md-0 mb-2 text-md-center'>{props.label}</h4>

                        {props.customHeaderComponent && props.customHeaderComponent}

                        {props.filter && (
                            <div className='order-3 w-md-35 mb-md-0 mb-2'>
                                <HeaderFilter<T>
                                    dispatch={props.dispatch}
                                    filter={props.filter}
                                    {...viewProps}
                                />
                            </div>
                        )}
                    </div>
                </div>

                {
                    <div className={props.cardBodyClassName ? props.cardBodyClassName : 'card-body'}>
                        {viewProps.data ? (
                            <Table
                                dispatch={props.dispatch}
                                listId={viewProps.listId}
                                key={props.id}
                                id={props.id + '-table'}
                                columns={viewProps.columns as ColumnDef<any>[]}
                                RowElement={RowElement}
                                data={viewProps.data}
                                selectEnabled={!!props.multiSelectOptions}
                                onSelectElement={props.onSelectElement}
                                sortingColumns={props.sortingColumns ? props.sortingColumns : viewProps.sortKeys}
                                hideColumns={props.hideColumns}
                                showCount={props.showCount}
                                {...props.tableProps}
                            />
                        ) : (
                            <TablePlaceholder />
                        )}
                    </div>
                }
            </div>

            {viewProps.showMultiDeleteModal && !!props.multiSelectOptions?.delete && (
                <ConfirmationModal
                    handleClose={() => viewProps.changeShowMultiDeleteModal(false)}
                    message={
                        props.multiSelectOptions.delete.parent
                            ? parentMultiDeleteMessage(viewProps.selectedObjects.length, props.type, props.multiSelectOptions.delete.parent)
                            : listMultiDeleteMessage(viewProps.selectedObjects.length, props.type)
                    }
                    onConfirm={() => {
                        props
                            .multiSelectOptions!.delete!.delete(viewProps.selectedObjects.map((o) => o.id))
                            .then(() => viewProps.changeSelectedObjects([]))
                            .then(() => viewProps.changeShowMultiDeleteModal(false));
                    }}
                    severity={ClassType.DANGER}
                    show={viewProps.showMultiDeleteModal}
                    cascadeWarning
                />
            )}
            {viewProps.showImportModal && !!props.multiSelectOptions!.import && (
                <Import
                    show={viewProps.showImportModal}
                    onClose={() => viewProps.changeShowImportModal(false)}
                    fetchTemplate={props.multiSelectOptions!.import?.fetchTemplate}
                    import={props.multiSelectOptions!.import?.import}
                    type={props.multiSelectOptions!.import?.type}
                />
            )}
            {viewProps.showExportModal && !!props.multiSelectOptions!.export && (
                <Export
                    show={viewProps.showExportModal}
                    onClose={() => viewProps.changeShowExportModal(false)}
                    export={props.multiSelectOptions!.export?.export}
                    type={props.multiSelectOptions!.export?.type}
                />
            )}
        </>
    );
};

const listMultiDeleteMessage = (count: number, type: EntityType) => {
    return (
        <FormattedMessage
            id='ObjectList.multi-delete-modal.message'
            defaultMessage='Are you sure you want to remove {count} {type}s?'
            values={{ count, type: type.replace(/_/g, ' ') }}
        />
    );
};

const parentMultiDeleteMessage = (count: number, type: EntityType, parent: React.ReactNode) => {
    return (
        <FormattedMessage
            id='ObjectList.multi-delete-modal.message_with_parent'
            defaultMessage='Are you sure you want to remove {count} {type}s from this {parent}?'
            values={{ count, type: type.replace(/_/g, ' '), parent }}
        />
    );
};
