import { userCertificateRequest } from '@frontend/certificate/events';
import { UserCertificate } from '@frontend/certificate/types';
import { EntityType } from '@frontend/common';
import { ObjectBadge } from '@frontend/rendering/badge';
import React from 'react';

export interface UserCertificateBadgeProps {
    id?: string;
    name?: string;
    className?: string;
}

export const UserCertificateBadge = (props: UserCertificateBadgeProps) => {
    return (
        <ObjectBadge<UserCertificate>
            id={props.id}
            type={EntityType.USER_CERTIFICATE}
            mapDisplayName={(ce: UserCertificate) => ce.id}
            resolve={userCertificateRequest}
            key={props.id}
            navigateString={(object: UserCertificate) =>
                `/accounts/${object.account_id}/users/${object.user_id}/certificates/${object.certificate_id}/user-certificates/${object.id}`
            }
            {...props}
        />
    );
};
