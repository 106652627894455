import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

const ProductGroupCreate = lazy(() => import('../product-group/product-group-create/product-group-create.component'));
const ProductGroupOverview = lazy(() => import('../product-group/product-group-overview'));
const ProductGroupDetail = lazy(() => import('../product-group/product-group-detail/product-group-detail.component'));
const ProductGroupUpdate = lazy(() => import('../product-group/product-group-update/product-group-update.component'));
const ProductGroupMemberAdd = lazy(() => import('../product-group/product-group-member-add/product-group-member-add.component'));

export function ProductGroupRoutes() {
    return (
        <Route path='product-groups'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <ProductGroupOverview />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <ProductGroupCreate />
                    </AsyncComponent>
                }
            />
            <Route path=':groupId'>
                <Route
                    index
                    path='detail'
                    element={
                        <AsyncComponent>
                            <ProductGroupDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <ProductGroupUpdate />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='members/add'
                    element={
                        <AsyncComponent>
                            <ProductGroupMemberAdd />
                        </AsyncComponent>
                    }
                />
            </Route>
            <Route
                index
                element={<Navigate to='/admin/product-groups' />}
            />
        </Route>
    );
}
