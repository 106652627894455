import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { CreateIdentification, Identification, IdentificationListResponse, IdentificationQueryParams, UpdateIdentificationUnlink } from '@frontend/identification/types';

const endpoint = '/identification-api/v1';

export class IdentificationClient extends APIClient {
    public static async fetchIdentifications(queryParams?: ApiQueryParams<DefaultQueryParams | IdentificationQueryParams>): Promise<IdentificationListResponse> {
        return await this.apiPaginated<IdentificationListResponse, DefaultQueryParams>(`${endpoint}/identifications`, queryParams);
    }

    public static async resolveIdentifications(ids: string[]): Promise<IdentificationListResponse> {
        const response = await this.post(`${endpoint}/identifications-resolve`, { ids: ids });
        return await this.handleResponse<IdentificationListResponse>(response);
    }

    public static async deleteIdentifications(ids: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/identifications-delete`, { ids: ids });
        return await this.handleVoidResponse(response);
    }

    public static async postIdentification(accountId: string, body: CreateIdentification): Promise<Identification> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/identifications`, body);
        return await this.handleResponse<Identification>(response);
    }

    public static async fetchIdentification(accountId: string, identificationId: string): Promise<Identification> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/identifications/${identificationId}`);
        return await this.handleResponse<Identification>(response);
    }

    public static async patchIdentification(accountId: string, identificationId: string, body: UpdateIdentificationUnlink): Promise<Identification> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/identifications/${identificationId}`, body);
        return await this.handleResponse<Identification>(response);
    }

    public static async deleteIdentification(accountId: string, identificationId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/identifications/${identificationId}`);
        return await this.handleVoidResponse(response);
    }

    public static async postIoTSeed(accountId: string, iotId: string, body: { account_id: string }): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots/${iotId}/seed`, body);
        return this.handleResponse(response);
    }
}
