import { DateInput } from '@frontend/basic-forms';
import { HorizontalButtonGroup } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import { EnumSelectListV2, ObjectSelectMapper } from '@frontend/rendering/select';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import useStatisticsDetail from './statistics-detail.controller';

export interface StatisticsDetailProps {
    title: React.ReactNode;
    chart: (args: any) => React.ReactNode;
    filters?: {
        keys: string[];
        extraProps?: (params: { [key: string]: string | null }) => Map<string, any>;
        queryParamKeyOverwrite?: Map<string, string>;
        defaultValues?: Map<string, string>;
    };
    allowBack?: boolean;
}

const StatisticsDetail = (props: StatisticsDetailProps) => {
    const viewProps = useStatisticsDetail(props);
    const navigate = useNavigate();

    const filters = useConvert({
        filters: props.filters ?? { keys: [] },
        onChange: (k, v) => {
            viewProps.changeFilterSet((prev) => {
                if (!prev) return { [k]: v };
                else return { ...prev, [k]: v };
            });
        },
        filterSet: viewProps.filterSet
    });

    return (
        <div className='card mt-3'>
            <div className='card-header d-flex justify-content-center'>
                <h4>{props.title}</h4>
            </div>
            <div className='stats-container me-3 ms-3'>{props.filters && filters}</div>
            <div className='card-body'>
                <props.chart {...viewProps.filterSet} />
            </div>
            {props.allowBack && (
                <div className='card-footer'>
                    <HorizontalButtonGroup
                        direction='left'
                        buttons={[{ text: CommonMessage.BUTTONS.BACK, onClick: () => navigate(-1) }]}
                    />
                </div>
            )}
        </div>
    );
};

export default StatisticsDetail;

const useConvert = (props: {
    filters: {
        keys: string[];
        extraProps?: (params: { [key: string]: string | null }) => Map<string, { [key: string]: any }>;
        queryParamKeyOverwrite?: Map<string, string>;
        defaultValues?: Map<string, string>;
    };
    onChange: (key: string, value: string | null) => void;
    filterSet: { [key: string]: string | null };
}) => {
    return useMemo(() => {
        return props.filters.keys.map((filter) => {
            const extraProps = props.filters.extraProps?.(props.filterSet).get(filter) ?? {};
            const defaultValue = props.filters.defaultValues?.get(filter);
            if (typeof filter === 'string') {
                if (filter.includes('_id')) {
                    return (
                        <ObjectSelectMapper
                            key={filter}
                            id={filter}
                            objectKey={filter}
                            submitted={false}
                            onChange={(v) => props.onChange(filter, v)}
                            className='stats-item'
                            isClearable
                            useConditionedStyling={false}
                            {...extraProps}
                        />
                    );
                }
                if (['from', 'to', 'timestamp'].includes(filter)) {
                    let keyValue = filter;
                    if (filter.includes('from')) keyValue = 'after';
                    if (filter.includes('to')) keyValue = 'before';
                    const value = props.filterSet[props.filters.queryParamKeyOverwrite?.get(keyValue.toString()) ?? keyValue] ?? defaultValue;
                    return (
                        <DateInput
                            key={keyValue}
                            id={filter}
                            label={filter}
                            submitted={false}
                            value={value ? new Date(value) : undefined}
                            className='stats-item'
                            onChange={(v) => {
                                if (extraProps.extraChange) {
                                    const param = props.filterSet[extraProps.extraChange.constraint];
                                    const unit = param === 'weekly' ? 'week' : param === 'monthly' ? 'month' : param === 'yearly' ? 'year' : null;
                                    if (unit) {
                                        const date = v ? moment(v)[keyValue === 'after' ? 'add' : 'subtract'](1, unit).format('YYYY-MM-DD') : null;
                                        props.onChange(extraProps.extraChange.key, date);
                                    }
                                }
                                props.onChange(keyValue, v ? moment(v).format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ') : null);
                            }}
                            {...extraProps}
                        />
                    );
                }
            }
            if (filter in EnumSelectListV2) {
                const EnumInput = EnumSelectListV2[filter as keyof typeof EnumSelectListV2];
                const overWriteValue = props.filters.queryParamKeyOverwrite?.get(filter.toString());
                const initValue = props.filterSet[overWriteValue ?? filter] ?? defaultValue;
                if (defaultValue && !props.filterSet[overWriteValue ?? filter]) {
                    props.onChange(overWriteValue ?? filter, defaultValue);
                }
                return (
                    <EnumInput
                        key={filter}
                        id={filter}
                        submitted={false}
                        value={initValue}
                        required={false}
                        onSelect={(v) => {
                            if (extraProps.extraChange) {
                                const unit = v?.value === 'weekly' ? 'week' : v?.value === 'monthly' ? 'month' : v?.value === 'yearly' ? 'year' : null;
                                if (unit) {
                                    const date = moment(props.filterSet['before']).subtract(1, unit).format('YYYY-MM-DD');
                                    props.onChange('after', date);
                                }
                            }
                            const value = v ? (typeof v.value === 'string' ? v.value : (v as { value: string; label: string }).value) : null;
                            props.onChange(overWriteValue ?? filter, value);
                        }}
                        className='stats-item'
                        {...extraProps}
                    />
                );
            }
            return <></>;
        });
    }, [props]);
};
