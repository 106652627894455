import { EntityType } from '@frontend/common';
import { playlistRequest } from '@frontend/playlist/events';
import { Playlist } from '@frontend/playlist/types';
import { ObjectBadge } from '@frontend/rendering/badge';
import React from 'react';

export interface PlaylistBadgeProps {
    id?: string;
    className?: string;
}

export const PlaylistBadge = (props: PlaylistBadgeProps) => {
    return (
        <ObjectBadge<Playlist>
            id={props.id}
            type={EntityType.PLAYLIST}
            mapDisplayName={(o: Playlist) => o.name}
            resolve={playlistRequest}
            navigateString={(object: Playlist) => `/accounts/${object.account_id}/playlists/${object.id}`}
            {...props}
        />
    );
};
