import { AttributeValueClient } from '@frontend/attribute/api';
import { EntityType } from '@frontend/common';
import { useResolver } from '@frontend/repository';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

declare global {
    interface WindowEventMap {
        'useAttributeValue:request': CustomEvent<string>;
    }
}

const REQUEST_ATTRIBUTE_VALUE_EVENT = 'useAttributeValue:request';
const STORAGE_KEY = 'ATTRVAL_REQ';

export function attributeValueRequest(attributeValueId: string): string {
    window.dispatchEvent(new CustomEvent(REQUEST_ATTRIBUTE_VALUE_EVENT, { detail: attributeValueId }));
    return STORAGE_KEY + '_loading';
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function AttributeValueResolver(props: Props) {
    useResolver(
        STORAGE_KEY,
        REQUEST_ATTRIBUTE_VALUE_EVENT,
        EntityType.ATTRIBUTE_VALUE,
        (ids) => AttributeValueClient.resolveAttributeValues(ids),
        props.dispatch
    );
    return <></>;
}
