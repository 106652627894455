import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { PackageRoutes } from '../package/package-routes';

const TransactionOverview = lazy(() => import('../transaction/transaction-overview'));
const TransactionCreate = lazy(() => import('../transaction/pudo-transaction-create/pudo-transaction-create.component'));
const TransactionDetail = lazy(() => import('../transaction/transaction-detail/transaction-detail.component'));

const PackageRoute = PackageRoutes();

export function TransactionRoutes() {
    return (
        <Route path='transactions'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <TransactionOverview />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <TransactionCreate />
                    </AsyncComponent>
                }
            />
            <Route
                index
                element={<Navigate to='/admin/transactions' />}
            />
            <Route path=':transactionId'>
                <Route
                    path='detail'
                    element={
                        <AsyncComponent>
                            <TransactionDetail />
                        </AsyncComponent>
                    }
                />
                {PackageRoute}
            </Route>
        </Route>
    );
}
