import { ToastUtil } from '@frontend/toast-utils';
import { KeyboardEvent, KeyboardEventHandler, useEffect, useState } from 'react';

import { CreatableSelectInputProps } from './creatable-select-input.component';

interface ViewProps {
    value: readonly Option[];
    handleKeyDown: KeyboardEventHandler;
    inputValue: string;
    changeValue: React.Dispatch<React.SetStateAction<readonly Option[]>>;
    changeInputValue: React.Dispatch<React.SetStateAction<string>>;
}

const useCreatableSelectInput = (props: CreatableSelectInputProps): ViewProps => {
    const [inputValue, changeInputValue] = useState<string>('');
    const [value, changeValue] = useState<readonly Option[]>([]);
    const handleKeyDown: KeyboardEventHandler = (event: KeyboardEvent) => {
        if (!inputValue) return;
        const foundValue = value.find((v) => v.value === inputValue);
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                event.preventDefault();
                if (foundValue) {
                    ToastUtil.info('Value already exists');
                    return;
                }
                changeValue((prev) => [...prev, createOption(inputValue)]);
                changeInputValue('');
        }
    };

    useEffect(() => {
        props.onChange(value.map((v) => v.value));
    }, [value]);

    useEffect(() => {
        if (props.value && props.value.length > 0 && props.options) {
            changeValue(props.options.filter((op) => props.value!.includes(op.value)));
        }
    }, []);

    return {
        value,
        handleKeyDown,
        changeInputValue,
        changeValue,
        inputValue
    };
};

export default useCreatableSelectInput;

export interface Option {
    readonly label: string;
    readonly value: string;
}

const createOption = (label: string): Option => ({
    label,
    value: label
});
