export interface PaginatedResponse<T> {
    count: number;
    results: T[];
}

export class ApiError extends Error {
    json?: unknown;
    code?: number;

    constructor(message?: string, json?: unknown, code?: number) {
        super(message);

        Object.setPrototypeOf(this, ApiError.prototype);
        this.json = json;
        this.code = code;
    }
}

export interface ApiNonFieldErrors {
    non_field_errors: string[];
}

export enum DefaultViewSetActions {
    LIST = 'list',
    DETAIL = 'detail'
}

export type ApiQueryParams<T extends string | number> = { [key in T]?: string | string[] | undefined | null };
export enum DefaultQueryParams {
    INDEX = 'index',
    SIZE = 'size',
    SEARCH = 'search',
    ORDERING = 'ordering',
    SORTFIELD = 'sort_field',
    SORTDIRECTION = 'sort_direction',
    TIMESTAMP_FIELD = 'timestamp_field',
    TIMESTAMP_FORMAT = 'timestamp_format',
    BEFORE = 'before',
    AFTER = 'after',
    ACCOUNT_ID = 'account_id'
}

export enum DefaultSearchQueryParams {
    PARTIAL_TEXT = 'partial_text'
}

export type DetailOptions =
    | {
          id: string | number;
          url?: never;
      }
    | {
          id?: never;
          url: string;
      };
