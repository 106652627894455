import { ClassType, Languages } from '@frontend/common';
import { useLocale } from '@frontend/lang';
import React from 'react';

import DropdownItem from '../dropdown/dropdown-item/dropdown-item.component';
import { Dropdown } from '../dropdown/dropdown.component';

interface LanguageSelectProps {
    className?: string;
    itemClassName?: string;
}

/**
 * Language select that changes the language of the application (therefore this should not be used in forms)
 * @param props
 * @returns
 */
export const LanguageSelect = (props: LanguageSelectProps) => {
    const { locale, changeLocale } = useLocale();

    return (
        <Dropdown
            label={Languages.find((l) => l.value === locale)?.displayName || locale}
            classType={ClassType.LIGHT}
            className={props.className}>
            {Languages.map((lang) => {
                return (
                    <DropdownItem
                        key={lang.value}
                        label={lang.displayName}
                        onClick={() => changeLocale(lang.value)}
                        className={props.itemClassName}
                    />
                );
            })}
        </Dropdown>
    );
};

export default LanguageSelect;
