import { lazy } from 'react';
import { Route } from 'react-router-dom';

const UserBadgeOverview = lazy(() => import('../user-badge/user-badge-overview'));

export function UserBadgeRoutes() {
    return (
        <Route path='user-badges'>
            <Route
                index
                element={<UserBadgeOverview />}
            />
        </Route>
    );
}
