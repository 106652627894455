import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

const TagOverview = lazy(() => import('../tag/tag-overview'));
const TagCreate = lazy(() => import('../tag/tag-create/tag-create.component'));
const TagUpdate = lazy(() => import('../tag/tag-update/tag-update.component'));
const TagDetail = lazy(() => import('../tag/tag-detail/tag-detail.component'));

export function TagRoutes() {
    return (
        <Route path='tags'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <TagOverview />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <TagCreate />
                    </AsyncComponent>
                }
            />
            <Route path=':tagId'>
                <Route
                    path='detail'
                    element={
                        <AsyncComponent>
                            <TagDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <TagUpdate />
                        </AsyncComponent>
                    }
                />
                <Route
                    index
                    element={<Navigate to='../' />}
                />
            </Route>
        </Route>
    );
}

export default TagRoutes;
