import React from 'react';
import { FaFile } from 'react-icons/fa';
import { GrClose } from 'react-icons/gr';
import { FormattedMessage } from 'react-intl';

import useDropZone from './drop-zone.controller';

export interface DropZoneProps {
    dropZoneMessage?: React.ReactNode;
    enableImportButton?: boolean;
    importButtonLabel?: React.ReactNode;
    onChange: (file: File | null | undefined) => void;
    file: File | null | undefined;
    type?: 'image' | 'video' | 'both';
}

export const DropZone = (props: DropZoneProps) => {
    const viewProps = useDropZone(props);
    if (props.file) {
        return (
            <div className='card card-body border card-plain border-radius-lg d-flex align-items-center flex-row justify-content-between'>
                <div className='d-flex justify-content-start align-items-center'>
                    {props.file.type.includes('image') ? (
                        <img
                            src={URL.createObjectURL(props.file)}
                            alt='file'
                            className=' img-fluid ms-2'
                            style={{ height: '300px' }}
                        />
                    ) : (
                        <>
                            <FaFile className='me-2' />
                            <span className='font-weight-bolder'>{props.file.name}</span>
                        </>
                    )}
                </div>

                <GrClose
                    className='cursor-pointer'
                    onClick={() => props.onChange(undefined)}
                />
            </div>
        );
    }
    return (
        <div
            ref={viewProps.dropZoneRef}
            className={`d-flex flex-column justify-content-center align-items-center w-100 height-200 border-dashed border-1 border-secondary ${
                viewProps.isDropping ? 'bg-light' : ''
            }`}>
            <span className='font-weight-bolder'>
                {props.dropZoneMessage ? (
                    props.dropZoneMessage
                ) : (
                    <FormattedMessage
                        id='DropZone.DropZoneMessage'
                        description='Dropzone message'
                        defaultMessage='Drop a file here'
                    />
                )}
            </span>
            {props.enableImportButton && (
                <label
                    htmlFor='file-input'
                    className='btn btn-sm bg-primary text-white'>
                    <input
                        type='file'
                        id='file-input'
                        className='display-none'
                        accept={props.type === 'image' ? 'image/*' : props.type === 'video' ? 'video/*' : 'image/*, video/*'}
                        onChange={(e) => props.onChange(e.target.files?.[0])}
                    />
                    {props.importButtonLabel}
                </label>
            )}
        </div>
    );
};
