import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { ProductAttributesRoutes } from '../product-attribute/product-attribute-routes';

const StockCreate = lazy(() => import('../stock/stock-create/stock-create.component'));
const AddCertificate = lazy(() => import('./product-detail/add-certificate/add-certificate.component'));
const ProductDetail = lazy(() => import('./product-detail/product-detail.component'));
const ProductUpdate = lazy(() => import('./product-update/product-update.component'));
const ProductCreate = lazy(() => import('./product-create/product-create.component'));

const ProductOverview = lazy(() => import('./product-overview'));

const ProductAttributeRoute = ProductAttributesRoutes();

export function ProductRoutes() {
    return (
        <Route path='products'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <ProductOverview />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <ProductCreate />
                    </AsyncComponent>
                }
            />
            <Route path=':productId'>
                <Route
                    index
                    path='detail'
                    element={
                        <AsyncComponent>
                            <ProductDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <ProductUpdate />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='add-stock'
                    element={
                        <AsyncComponent>
                            <StockCreate />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='add-certificate'
                    element={
                        <AsyncComponent>
                            <AddCertificate />
                        </AsyncComponent>
                    }
                />
                {ProductAttributeRoute}
            </Route>
        </Route>
    );
}
