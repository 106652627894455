import React from 'react';

import { Option } from '../option';
import { Select } from '../select';
import { SelectOption, SingleOption, isSingleOption } from '../select-types';
import useAsyncSelectInputV2 from './async-select-input-v2.controller';

const ID = 'select-input-v2';

export interface AsyncSelectInputV2Props<T extends object, D extends string | number> {
    id?: string;
    label?: React.ReactNode;
    helpMessage?: React.ReactNode;

    options: SelectOption<T>[] | null;
    onSelect?: (option: SingleOption<T> | null) => void;
    value?: string | null;
    searchValueOverwrite?: string;

    singleSearch?: (value: string) => Promise<SingleOption<T>>;
    onSearch?: (value: string) => void;
    onScrollToBottom?: () => void;

    required?: boolean;
    disabled?: boolean;

    isClearable?: boolean;
    isLoading?: boolean;

    className?: string;

    onFocus?: React.FocusEventHandler<HTMLInputElement> | undefined;
    onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
    isOpenOverwrite?: boolean;
}

export const AsyncSelectInputV2 = <T extends object, D extends string | number>(props: AsyncSelectInputV2Props<T, D>) => {
    const viewProps = useAsyncSelectInputV2(props);

    return (
        <div
            id={ID}
            className={'form-group w-100 ' + props.className}>
            <label className='text-primary'>
                {props.required ? <span className='text-danger me-1'>&#9679;</span> : <></>} {props.label}
                {props.helpMessage && (
                    <>
                        <br />
                        <small className='form-text text-muted'>{props.helpMessage}</small>
                    </>
                )}
            </label>
            <Select
                id={props.id || ID}
                onSelect={viewProps.onSelect}
                value={viewProps.value}
                onChange={viewProps.onSearch}
                isClearable={props.isClearable}
                optionsLoading={viewProps.isLoading}
                onScrollToBottom={props.onScrollToBottom}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                disabled={props.disabled}
                isOpenOverwrite={props.isOpenOverwrite}>
                {viewProps.searchResultOptions &&
                    viewProps.searchResultOptions.map((option) => {
                        if (isSingleOption(option)) {
                            return (
                                <Option
                                    key={option.value}
                                    {...option}
                                />
                            );
                        }
                        return (
                            <>
                                {option.label}
                                {option.options.map((option: SingleOption<T>) => (
                                    <Option
                                        key={option.value}
                                        {...option}
                                    />
                                ))}
                            </>
                        );
                    })}
            </Select>
        </div>
    );
};

export default AsyncSelectInputV2;
