import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

const PackageOverview = lazy(() => import('../package/package-overview/package-overview'));
const PackageDetail = lazy(() => import('../package/package-detail/package-detail.component'));

export function PackageRoutes() {
    return (
        <Route path='packages'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <PackageOverview />
                    </AsyncComponent>
                }
            />
            <Route
                index
                element={<Navigate to='/admin/packages' />}
            />
            <Route path=':packageId'>
                <Route
                    path='detail'
                    element={
                        <AsyncComponent>
                            <PackageDetail />
                        </AsyncComponent>
                    }
                />
            </Route>
        </Route>
    );
}
