import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Route } from 'react-router-dom';

const NotificationTemplateOverview = lazy(() => import('./notification-template-overview/notification-template-overview'));
const NotificationTemplateDetail = lazy(() => import('./notification-template-detail/notification-template-detail.component'));
const NotificationTemplateCreate = lazy(() => import('./notification-template-create/notification-template-create.component'));
const NotificationTemplateCopy = lazy(() => import('./notification-template-copy/notification-template-copy.component'));

const NotificationContentCreate = lazy(() => import('../notification-content/notification-content-create/notification-content-create.component'));
const NotificationContentDetail = lazy(() => import('../notification-content/notification-content-detail/notification-content-detail.component'));
const NotificationTemplateUpdate = lazy(() => import('./notification-template-update/notification-template-update.component'));

export function NotificationTemplateRoutes() {
    return (
        <Route path='notification-templates'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <NotificationTemplateOverview />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <NotificationTemplateCreate />
                    </AsyncComponent>
                }
            />
            <Route path=':templateId'>
                <Route
                    index
                    path='detail'
                    element={
                        <AsyncComponent>
                            <NotificationTemplateDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <NotificationTemplateUpdate />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='copy'
                    element={
                        <AsyncComponent>
                            <NotificationTemplateCopy />
                        </AsyncComponent>
                    }
                />
                <Route path='content'>
                    <Route
                        path='create'
                        element={
                            <AsyncComponent>
                                <NotificationContentCreate />
                            </AsyncComponent>
                        }
                    />
                    <Route path=':contentId'>
                        <Route
                            index
                            path='detail'
                            element={
                                <AsyncComponent>
                                    <NotificationContentDetail />
                                </AsyncComponent>
                            }
                        />
                    </Route>
                </Route>
            </Route>
        </Route>
    );
}

export default NotificationTemplateRoutes;
