import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { ConstraintRoutes } from '../constraint/constraint-routes';
import { StockRoutes } from '../stock/stock-routes';

const SlotCreate = lazy(() => import('../slot/slot-create/slot-create.component'));
const SlotDetail = lazy(() => import('../slot/slot-detail/slot-detail.component'));
const SlotUpdate = lazy(() => import('../slot/slot-update/slot-update.component'));
const MovementDetail = lazy(() => import('../movement/movement-detail/movement-detail.component'));
const ConstraintRoute = ConstraintRoutes();
const StockRoute = StockRoutes();
export function SlotRoutes() {
    return (
        <Route path='slots'>
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <SlotCreate />
                    </AsyncComponent>
                }
            />
            <Route path=':slotId'>
                <Route
                    index
                    path='detail'
                    element={
                        <AsyncComponent>
                            <SlotDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <SlotUpdate />
                        </AsyncComponent>
                    }
                />
                {ConstraintRoute}
                {StockRoute}
                <Route path='stock-updates'>
                    <Route path=':movementId'>
                        <Route
                            path='detail'
                            element={
                                <AsyncComponent>
                                    <MovementDetail />
                                </AsyncComponent>
                            }
                        />
                    </Route>
                </Route>
            </Route>
            <Route
                index
                element={<Navigate to='../detail' />}
            />
        </Route>
    );
}
