import { NotificationEmail } from '@frontend/notification-service/types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface ViewProps {
    formValues: { account_id: string };
    changeFormValues: React.Dispatch<React.SetStateAction<{ account_id: string }>>;
    onCancel: () => void;
    onSubmitSuccess: (object: NotificationEmail) => void;
}

const useNotificationEmailCopy = (): ViewProps => {
    const navigate = useNavigate();
    const [formValues, changeFormValues] = useState<{ account_id: string }>({ account_id: '' });

    const onCancel = () => {
        navigate('../detail');
    };

    const onSubmitSuccess = (object: NotificationEmail) => {
        onCancel();
    };

    return {
        formValues,
        changeFormValues,
        onCancel,
        onSubmitSuccess
    };
};

export default useNotificationEmailCopy;
