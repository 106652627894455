import { EntityType } from '@frontend/common';
import React, { useEffect, useState } from 'react';

import { DefaultSelectProps } from '../basic-forms';
import { SelectInput } from '../select-input/select-input.component';

const ID = 'entity-type-select';

export interface EntityTypeSelectProps extends DefaultSelectProps {
    subset?: EntityType[] | (() => Promise<EntityType[]>);
}

export const EntityTypeSelect = (props: EntityTypeSelectProps) => {
    const [options, changeOptions] = useState<EntityType[]>(props.subset ? Object.values(EntityType) : Object.values(EntityType).slice(1));
    useEffect(() => {
        if (props.subset) {
            if (typeof props.subset === 'function') props.subset().then(changeOptions);
            else changeOptions(props.subset);
        }
    }, [props.subset]);

    return (
        <SelectInput
            {...props}
            id={props.id || ID}
            isClearable={props.isClearable}
            className={props.className}
            options={mapEntityTypesToOptions(options)}
            value={props.value}
            label={props.label || 'Entity type'}
            submitted={props.submitted}
            required={props.required}
            onChange={(value) => props.onChange && props.onChange(value)}
        />
    );
};

function mapEntityTypesToOptions(entityTypes: EntityType[]): { label: string; value: string }[] {
    return entityTypes.map((entityType) => {
        const label = entityType == EntityType.ALL ? 'All' : entityType == EntityType.UNDEFINED ? '' : entityType[0].toUpperCase() + entityType.slice(1);
        return {
            value: entityType,
            label
        };
    });
}
