import { APIClient } from '@frontend/api-utils';
import { EquipmentChartData } from '@frontend/equipment/types';

const endpoint = '/equipment-api/v1';

export class EquipmentChartClient extends APIClient {
    public static async fetchAllEquipmentState(accountId: string): Promise<EquipmentChartData> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/equipment-state-all-chart`);
        return await this.handleResponse<EquipmentChartData>(response);
    }

    public static async fetchAvailableEquipmentState(accountId: string): Promise<EquipmentChartData> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/equipment-state-available-chart`);
        return await this.handleResponse<EquipmentChartData>(response);
    }
}
