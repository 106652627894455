import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { Job, PaginatedJobResponse } from '@frontend/import/types';

const endpoint = '/import-api/v1';

export class JobClient extends APIClient {
    public static async fetchJobs(queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<PaginatedJobResponse> {
        return await this.apiPaginated<PaginatedJobResponse, DefaultQueryParams>(`${endpoint}/jobs`, queryParams);
    }

    public static async resolveJobs(jobIds: string[]): Promise<PaginatedJobResponse> {
        const response = await this.post(`${endpoint}/jobs-resolve`, { ids: jobIds });
        return await this.handleResponse<PaginatedJobResponse>(response);
    }

    public static async fetchJob(accountId: string, jobId: string): Promise<Job> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/jobs/${jobId}`);
        return await this.handleResponse<Job>(response);
    }

    public static async deleteJob(accountId: string, jobId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/jobs/${jobId}`);
        return await this.handleVoidResponse(response);
    }
}
