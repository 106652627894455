import { Route } from 'react-router-dom';

import ReportOverview from './report-overview';

export function ReportRoutes() {
    return (
        <Route path='reports'>
            <Route
                index
                element={<ReportOverview />}
            />
        </Route>
    );
}
