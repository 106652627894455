import { ClassType, EntityType } from '@frontend/common';
import { ConfirmationModal, HorizontalButtonGroup, HorizontalButtonGroupButton, Spinner } from '@frontend/elements';
import { ICONS } from '@frontend/icons';
import { CommonMessage } from '@frontend/lang';
import { ObjectDetailMapper } from '@frontend/rendering/details';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

import useObjectDetail2 from './object-detail-2.controller';
import Subsection from './subsection/subsection';

export interface ObjectDetailProps2<T> {
    fetch: (...args: any) => Promise<T>;
    onDelete: (...args: any) => Promise<void>;
    onDeleteSuccess?: () => void;
    onUpdate?: () => void;
    /**
     * @description keys should be provided in the correct order to use the fetch and delete function
     */
    urlParamKeys: string[];
    /**
     * @description is used to route back in case of any failure or on delete
     */
    baseRoute: string;
    /**
     * @description is used to map navigation keys to their replacement values for URL construction
     */
    navigationKeyReplacements?: { [key: string]: string };
    updateKeys?: string[];
    eventListener?: EntityType;
    dispatch?: ThunkDispatch<any, any, Action>;
    id: string;
    loadingTitle?: React.ReactNode;
    objectHeader: (value: T) => React.ReactNode;
    /**
     * @description is used to map object keys to display
     */
    order: (keyof T)[];
    /**
     * @description All children should be a DetailItem. If you want to display multiple children then wrap them inside a fragment or div
     * @description If you want to display a subsection on the Main page then set the main prop to true (max 1)
     * @example <DetailItem identifier='main' main><div>Children</div></DetailItem>
     * @example <> <DetailItem identifier='main' main><div>Children</div></DetailItem> <DetailItem identifier='linked'><div>Children</div></DetailItem> </>
     */
    children?: (entity: T) => React.ReactNode;
    /**
     * @description key of the object that is used to display the delete message
     */
    deleteKey: keyof T;
    /**
     * @description displayed before the deleteKey in the delete modal
     * @example User -> Are you sure you want to delete the User: {deleteKey}
     */
    deleteMessage: React.ReactNode;
    object?: (entity: T | null) => void;
    headerClassName?: string;
    customHeaderButtons?: (entity: T) => HorizontalButtonGroupButton[];
    customHeaderComponents?: (obj: T) => React.ReactNode;
    valueOverwrite?: Map<keyof T, React.ReactNode>;
}
export const ObjectDetail2 = <T extends object>(props: ObjectDetailProps2<T>) => {
    const viewProps = useObjectDetail2<T>(props);

    if (!viewProps.object) {
        return (
            <div id={props.id}>
                <div className='card mt-3'>
                    <div className='card-header'>{props.loadingTitle}</div>
                    <div className='card-body'>
                        <Spinner />
                    </div>
                </div>
            </div>
        );
    }
    return (
        <>
            {viewProps.filteredSubSections.length > 0 && (
                <Subsection
                    activeSubsection={viewProps.activeSubSection}
                    onChange={viewProps.onChange}
                    subsections={viewProps.subSections}
                />
            )}
            {!viewProps.activeSubSection || viewProps.activeSubSection === 'main' || viewProps.activeSubSection === 'Main' ? (
                <div
                    id={props.id}
                    className='d-flex flex-row flex-wrap justify-content-between'>
                    <div className={props.headerClassName ? props.headerClassName : 'card mt-3 w-100'}>
                        <div className='card-header d-flex justify-content-between'>
                            <h4>{props.objectHeader(viewProps.object)}</h4>
                            <HorizontalButtonGroup
                                direction='left'
                                buttons={[
                                    {
                                        onClick: () => viewProps.changeShowDeleteModal(true),
                                        icon: ICONS.BUTTON.DELETE,
                                        type: ClassType.DANGER,
                                        id: 'ObjectDetail.DeleteButton',
                                        text: CommonMessage.BUTTONS.DELETE
                                    },
                                    {
                                        hide: !props.onUpdate,
                                        onClick: viewProps.onUpdate,
                                        icon: ICONS.BUTTON.UPDATE,
                                        type: ClassType.INFO,
                                        id: 'ObjectDetail.UpdateButton',
                                        text: CommonMessage.BUTTONS.UPDATE
                                    },
                                    ...(props.customHeaderButtons ? props.customHeaderButtons(viewProps.object) : [])
                                ]}
                            />
                        </div>
                        <div className='card-body d-flex flex-wrap justify-content-between'>
                            <ObjectDetailMapper<T>
                                object={viewProps.object}
                                order={props.order}
                                valueOverwrite={props.valueOverwrite}
                            />
                        </div>
                    </div>
                    {props.customHeaderComponents && props.customHeaderComponents(viewProps.object)}
                    {viewProps.main}
                    {viewProps.showDeleteModal && (
                        <ConfirmationModal
                            handleClose={() => viewProps.changeShowDeleteModal(false)}
                            message={CommonMessage.FORMS.DELETE_OBJECT(props.deleteMessage, viewProps.object[props.deleteKey] as string)}
                            onConfirm={viewProps.onDeleteConfirmed}
                            severity={ClassType.DANGER}
                            show={viewProps.showDeleteModal}
                        />
                    )}
                </div>
            ) : (
                viewProps.subSections.find((s) => s.identifier.toLowerCase() === viewProps.activeSubSection)?.children
            )}
        </>
    );
};
