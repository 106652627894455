import { DefaultQueryParams } from '@frontend/api-utils';
import { Filter } from '@frontend/elements';
import { changeFilter, changeFilters, useListRepository } from '@frontend/repository';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import { FaFilter } from 'react-icons/fa';

import FilterModal from './filter-modal/filter-modal.component';
import useHeaderFilter from './header-filter.controller';

export interface Props<T extends object & { id: string }> extends ReturnType<typeof useListRepository<T>> {
    dispatch: ThunkDispatch<any, any, Action>;
    filter?: FilterOptions<T>;
}

// eslint disabled because T is not used but probably will be in the future
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
export interface FilterOptions<T extends object & { id: string }> {
    keys?: (DefaultQueryParams | string | number)[];
    default?: boolean;
    keyProps?: Map<string, any>;
    queryParamKeyOverwrite?: Map<string, string>;
    hideKeys?: (string | number)[];
    type?: 'platform' | 'terminal';
    defaultValues?: Map<string, any>;
}

export const HeaderFilter = <T extends object & { id: string }>(props: Props<T>) => {
    const viewProps = useHeaderFilter(props);
    return (
        <div className='d-flex flex-row w-100 justify-content-end align-content-end align-items-end'>
            {props.filter?.default && (
                <Filter
                    label={null}
                    className='mb-0'
                    filterValue={(value) => {
                        props.dispatch(
                            changeFilter({
                                listId: props.listId,
                                key: 'search',
                                value: value
                            })
                        );
                    }}
                />
            )}
            {(props.filter?.type === 'platform' || props.filter?.type === undefined) && props.filter?.keys && props.filter.keys.length > 0 && (
                <button
                    className='btn btn-primary ms-2 mb-0'
                    style={{ position: 'relative' }}
                    onClick={(e) => {
                        e.preventDefault();
                        viewProps.changeShowModal(true);
                    }}>
                    <FaFilter />
                    {viewProps.filters && Object.keys(viewProps.filters).filter((p) => !p.includes('search')).length > 0 && (
                        <span
                            className='badge badge-sm badge-circle badge-info border-white'
                            style={{ position: 'absolute', top: 0, right: 0, transform: 'translateX(50%) translateY(-50%)' }}>
                            {Object.keys(viewProps.filters).filter((p) => !p.includes('search')).length}
                        </span>
                    )}
                </button>
            )}
            {viewProps.showModal && (
                <FilterModal
                    handleClose={() => viewProps.changeShowModal(false)}
                    show={viewProps.showModal}
                    changeURLParams={(v) => {
                        if (Object.keys(v).length === 0) {
                            props.dispatch(
                                changeFilter({
                                    listId: props.listId,
                                    key: 'search',
                                    value: viewProps.filters ? viewProps.filters['search'] : undefined
                                })
                            );
                            if (viewProps.filters) {
                                Object.keys(viewProps.filters).forEach((k) => {
                                    props.dispatch(changeFilter({ listId: props.listId, key: k, value: null }));
                                });
                            }
                        } else {
                            const obj = { ...viewProps.filters, ...v };
                            const cleanObj = Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== undefined && v !== null));
                            props.dispatch(
                                changeFilters({
                                    listId: props.listId,
                                    filters: Object.entries(cleanObj).map(([key, value]) => {
                                        return { key, value };
                                    })
                                })
                            );
                        }
                    }}
                    {...props}
                />
            )}
        </div>
    );
};
