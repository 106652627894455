import { useAppSelector } from '@frontend/common';
import { ErrorHandler } from '@frontend/error-handler';
import { CommonMessage } from '@frontend/lang';
import { changeSubmitted, formStore, useFormRepository } from '@frontend/repository';
import { ToastUtil } from '@frontend/toast-utils';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { FormProps, defaultObjectFormRowClassName } from './form.component';

interface ViewProps<S extends object> extends ReturnType<typeof useFormRepository> {
    header: React.ReactNode;
    rowClass: string;
    show: (keyof S)[];
    submitted: boolean;
    submit: (e: any) => void;
}

const useForm = <T extends object, S extends object>(props: FormProps<T, S>): ViewProps<S> => {
    const repository = useFormRepository({ dispatch: props.dispatch, initialValue: props.value });
    const store = useAppSelector(useSelector, formStore);
    const form = store[repository.formId];

    useEffect(() => {
        if (form && props.onChange && form.value) props.onChange(form.value as S);
    }, [form]);

    useEffect(() => {
        props.formIdCallback && props.formIdCallback(repository.formId);
    }, [repository.formId]);

    const header = useMemo(() => {
        if (props.label) return props.label;
        const h = props.headerOverwrite ? props.headerOverwrite : props.type.replace(/_/g, ' ');
        if (props.object) return CommonMessage.FORMS.UPDATE_OBJECT(h);
        return CommonMessage.FORMS.CREATE_OBJECT(h);
    }, [props.label]);
    const show = useMemo(() => [...new Set([...(props.show ?? []), ...(props.required ?? [])])], [props.show, props.required]);

    const submit = (e: any) => {
        if (props.onSubmit === undefined || !store[repository.formId]) return;
        e.preventDefault();
        props.dispatch(changeSubmitted({ formId: repository.formId, value: true }));
        props
            .onSubmit(store[repository.formId].value as S)
            .then((result) => {
                ToastUtil.success(
                    props.successMessageOverwrite
                        ? props.successMessageOverwrite
                        : `Successfully ${props.object ? 'updated' : 'created'} ${(props.type[0].toUpperCase() + props.type.slice(1)).split('_').join(' ')}`
                );
                if (typeof result !== 'undefined') {
                    props.onSubmitSuccess && props.onSubmitSuccess(result);
                } else {
                    props.onVoidSuccess && props.onVoidSuccess(result);
                }
            })
            .catch((e) => ErrorHandler.handleError({ error: e, entity_type: props.type }))
            .finally(() => props.dispatch(changeSubmitted({ formId: repository.formId, value: false })));
    };

    return {
        ...repository,
        header,
        rowClass: props.rowClassName ?? defaultObjectFormRowClassName,
        show,
        submitted: form?.submitted ?? false,
        submit
    };
};

export default useForm;
