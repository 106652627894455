import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';

import { initForm } from './form-slice';

interface Props<T> {
    dispatch: ThunkDispatch<any, any, Action>;
    initialValue?: T;
}

export function useFormRepository<T>(props: Props<T>) {
    const [formId] = useState<string>(crypto.randomUUID());

    useEffect(() => {
        props.dispatch(initForm({ formId, value: props.initialValue }));
    }, [formId, props.initialValue]);

    return {
        formId
    };
}
