import { AuthenticationError, AuthenticationManager } from '@frontend/authentication-v2';
import { ToastUtil } from '@frontend/toast-utils';
import { Dispatch, SetStateAction, useState } from 'react';

interface ViewProps {
    submitted: boolean;
    formValues: FormResult;
    changeFormValues: Dispatch<SetStateAction<FormResult>>;
    emailValid: boolean;
    changeEmailValid: Dispatch<SetStateAction<boolean>>;
    onSubmit: (e: any) => void;
}

interface FormResult {
    email: string;
}

const useForgotPassword = (): ViewProps => {
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [formValues, changeFormValues] = useState<FormResult>({ email: '' });
    const [emailValid, changeEmailValid] = useState<boolean>(true);
    const url = window.location.origin + '/forgot-password-confirmation';
    const onSubmit = (e: any) => {
        e.preventDefault();
        if (emailValid) {
            changeSubmitted(true);
            AuthenticationManager.getInstance()
                .forgotPassword(formValues.email, url)
                .then((res) => ToastUtil.success('Success', res.message))
                .catch((err) => {
                    if (typeof (err as AuthenticationError).detail == 'string') {
                        ToastUtil.error((err as AuthenticationError).detail as string);
                    } else if (typeof err == 'string') {
                        ToastUtil.error(err);
                    } else {
                        ((err as AuthenticationError).detail as any[]).forEach((error) => {
                            ToastUtil.error(error.msg);
                        });
                    }
                    changeSubmitted(false);
                });
        }
    };

    return {
        changeEmailValid,
        changeFormValues,
        emailValid,
        formValues,
        submitted,
        onSubmit
    };
};

export default useForgotPassword;
