import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { CreatePackage, Package, PackageListResponse, PackageQueryParams, UpdatePackage } from '@frontend/package/types';

const endpoint = '/package-api/v1';

export class PackageClient extends APIClient {
    public static async fetchPackages(queryParams?: ApiQueryParams<DefaultQueryParams | PackageQueryParams>): Promise<PackageListResponse> {
        return await this.apiPaginated<PackageListResponse, DefaultQueryParams | PackageQueryParams>(`${endpoint}/packages`, queryParams);
    }

    public static async fetchNonProcessedPackages(queryParams?: ApiQueryParams<DefaultQueryParams | PackageQueryParams>): Promise<PackageListResponse> {
        return await this.apiPaginated<PackageListResponse, DefaultQueryParams | PackageQueryParams>(`${endpoint}/non-processed-packages`, queryParams);
    }

    public static async resolvePackages(packageIds: string[]): Promise<PackageListResponse> {
        const response = await this.post(`${endpoint}/packages-resolve`, { ids: packageIds });
        return await this.handleResponse<PackageListResponse>(response);
    }

    public static async deletePackages(packageIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/packages-delete`, { ids: packageIds });
        return await this.handleVoidResponse(response);
    }

    public static async fetchAccountPackages(accountId: string, queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<PackageListResponse> {
        return await this.apiPaginated(`${endpoint}/accounts/${accountId}/packages`, queryParams);
    }

    public static async fetchAccountTransactionPackages(
        accountId: string,
        transactionId: string,
        queryParams?: ApiQueryParams<DefaultQueryParams>
    ): Promise<PackageListResponse> {
        return await this.apiPaginated(`${endpoint}/accounts/${accountId}/transactions/${transactionId}/packages`, queryParams);
    }

    public static async fetchPackage(accountId: string, transactionId: string, packageId: string): Promise<Package> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/transactions/${transactionId}/packages/${packageId}`);
        return await this.handleResponse<Package>(response);
    }

    public static async postPackage(accountId: string, transactionId: string, body: CreatePackage): Promise<Package> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/transactions/${transactionId}/packages`, body);
        return await this.handleResponse<Package>(response);
    }

    public static async patchPackage(accountId: string, transactionId: string, packageId: string, body: UpdatePackage): Promise<Package> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/transactions/${transactionId}/packages/${packageId}`, body);
        return await this.handleResponse<Package>(response);
    }

    public static async deletePackage(accountId: string, transactionId: string, packageId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/transactions/${transactionId}/packages/${packageId}`);
        return await this.handleVoidResponse(response);
    }

    public static async postIoTSeed(accountId: string, iotId: string, body: { account_id: string }): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots/${iotId}/seed`, body);
        return this.handleResponse(response);
    }
}
