import { EntityType } from "@frontend/common";
import { moduleTemplateRequest } from "@frontend/module/events";
import { ModuleTemplate } from "@frontend/module/types";
import { ObjectBadge, ObjectBadgeProps } from "@frontend/rendering/badge";
import React from "react";

export const ModuleTemplateBadge = (props: ObjectBadgeProps<ModuleTemplate>) => {
    return (
        <ObjectBadge<ModuleTemplate>
            id={props.id}
            type={EntityType.TEMPLATE}
            mapDisplayName={(template: ModuleTemplate) => template.name}
            resolve={moduleTemplateRequest}
            {...props}
        />
    );
};
