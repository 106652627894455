import { APIClient } from "@frontend/api-utils";
import { UserRemoteLogin } from "@frontend/user/types";

const endpoint = '/user-api/v1';

export class UserDeviceClient extends APIClient {
    public static async postIdentifyDevice(accountId: string, userId: string, body: UserRemoteLogin): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/users/${userId}/identify-device`, body);
        return await this.handleVoidResponse(response);
    }
}
