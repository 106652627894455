import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { TransactionClient, TransactionEnumClient } from '@frontend/transaction/api';
import { Transaction, TransactionListResponse, TransactionQueryParams } from '@frontend/transaction/types';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isArray, toNumber } from 'lodash';

export interface TransactionSliceState {
    unordered: Transaction[];
    transactions: TransactionListResponse | null;
    transactionsByAccount: { [accountId: string]: TransactionListResponse } | null;
    transactionStates: string[] | null;
    transactionTriggers: string[] | null;
    transactionActions: string[] | null;
    status: SliceStatus;
}

const initialState: TransactionSliceState = {
    unordered: [],
    transactions: null,
    transactionsByAccount: null,
    transactionStates: null,
    transactionTriggers: null,
    transactionActions: null,
    status: SliceStatus.INIT
};

const transactionSlice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        seedTransactions(state, action: PayloadAction<Transaction[]>) {
            state.unordered = [...state.unordered.filter((transaction) => action.payload.find((t) => t.id == transaction.id) == undefined), ...action.payload];
        },
        updateTransaction(state, action: PayloadAction<Transaction>) {
            state.unordered = state.unordered.map((transaction) => (transaction.id == action.payload.id ? action.payload : transaction));
            if (state.transactions) {
                state.transactions.results = state.transactions.results.map((transaction) =>
                    transaction?.id == action.payload.id ? action.payload : transaction
                );
            }
        },
        addTransaction(state, action: PayloadAction<Transaction>) {
            state.unordered.push(action.payload);
            if (state.transactions != null) {
                state.transactions.count++;
                state.transactions.results = [action.payload].concat(state.transactions.results);
            }
        },
        removeTransaction(state, action: PayloadAction<string>) {
            state.unordered = state.unordered.filter((t) => t.id != action.payload);
            if (state.transactions != null) {
                state.transactions.results = state.transactions.results.filter((t) => t.id != action.payload);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTransactions.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchTransactions.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.size) * toNumber(action.meta.arg.index);
                if (state.transactions == null) {
                    state.transactions = {
                        ...action.payload,
                        results: new Array(action.payload.count)
                    };
                    state.transactions.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.transactions.results.length !== action.payload.count) {
                        state.transactions.count = action.payload.count;
                        state.transactions.results = new Array(action.payload.count);
                    }
                    state.transactions.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }

                if (action.meta.arg.account_id && !isArray(action.meta.arg.account_id)) {
                    if (!state.transactionsByAccount) {
                        state.transactionsByAccount = { [action.meta.arg.account_id]: { ...action.payload, results: new Array(action.payload.count) } };
                        state.transactionsByAccount[action.meta.arg.account_id].results.splice(
                            startPos,
                            action.payload.results.length,
                            ...action.payload.results
                        );
                    } else if (state.transactionsByAccount && !state.transactionsByAccount[action.meta.arg.account_id]) {
                        state.transactionsByAccount[action.meta.arg.account_id] = { ...action.payload, results: new Array(action.payload.count) };
                        state.transactionsByAccount[action.meta.arg.account_id].results.splice(
                            startPos,
                            action.payload.results.length,
                            ...action.payload.results
                        );
                    } else {
                        if (state.transactionsByAccount[action.meta.arg.account_id].results.length !== action.payload.count) {
                            state.transactionsByAccount[action.meta.arg.account_id].count = action.payload.count;
                            state.transactionsByAccount[action.meta.arg.account_id].results = new Array(action.payload.count);
                        }
                        state.transactionsByAccount[action.meta.arg.account_id].results.splice(
                            startPos,
                            action.payload.results.length,
                            ...action.payload.results
                        );
                    }
                }
                state.unordered = [
                    ...state.unordered.filter((account) => action.payload.results.find((a) => a.id == account.id) == undefined),
                    ...action.payload.results
                ];
                state.status = SliceStatus.IDLE;
            })
            .addCase(deleteAccountTransaction.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(deleteAccountTransaction.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                if (state.transactions == null) return;
                const found = state.transactions.results.find((t) => t.id == action.meta.arg.transactionId);
                if (found == undefined) return;
                state.transactions.results.splice(state.transactions.results.indexOf(found), 1);
            })
            .addCase(deleteTransactions.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(deleteTransactions.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                state.unordered = state.unordered.filter((u) => !action.meta.arg.includes(u.id));
                if (state.transactions == null) return;
                state.transactions.count = state.transactions.count - action.meta.arg.length;
                state.transactions.results = state.transactions.results.filter((t) => !action.meta.arg.includes(t.id));
            })
            .addCase(fetchTransactionStates.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchTransactionStates.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                state.transactionStates = action.payload;
            })
            .addCase(fetchTransactionTriggers.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchTransactionTriggers.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                state.transactionTriggers = action.payload;
            })
            .addCase(fetchTransactionActions.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchTransactionActions.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                state.transactionActions = action.payload;
            });
    }
});

export const fetchTransactions = createAsyncThunk<TransactionListResponse, ApiQueryParams<DefaultQueryParams | TransactionQueryParams>>(
    'fetchTransactions',
    async (queryParams: ApiQueryParams<DefaultQueryParams | TransactionQueryParams>, { rejectWithValue }) => {
        try {
            return await TransactionClient.fetchTransactions(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchSpotTransactions = createAsyncThunk<TransactionListResponse, { accountId: string; queryParams: ApiQueryParams<DefaultQueryParams> }>(
    'fetchSpotTransactions',
    async (params: { accountId: string; queryParams: ApiQueryParams<DefaultQueryParams> }, { rejectWithValue }) => {
        try {
            return await TransactionClient.fetchAccountTransactions(params.accountId, params.queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const deleteAccountTransaction = createAsyncThunk<void, { accountId: string; transactionId: string }>(
    'deleteSpotTransaction',
    async (variables: { accountId: string; transactionId: string }, { rejectWithValue }) => {
        try {
            return await TransactionClient.deleteTransaction(variables.accountId, variables.transactionId);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const deleteTransactions = createAsyncThunk<void, string[]>('deleteTransactions', async (transactionIds: string[], { rejectWithValue }) => {
    try {
        return await TransactionClient.deleteTransactions(transactionIds);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const fetchTransactionStates = createAsyncThunk<string[]>('fetchTransactionStates', async (_, { rejectWithValue }) => {
    try {
        return await TransactionEnumClient.fetchTransactionStates();
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const fetchTransactionTriggers = createAsyncThunk<string[]>('fetchTransactionTriggers', async (_, { rejectWithValue }) => {
    try {
        return await TransactionEnumClient.fetchTransactionTriggers();
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const fetchTransactionActions = createAsyncThunk<string[]>('fetchTransactionActions', async (_, { rejectWithValue }) => {
    try {
        return await TransactionEnumClient.fetchTransactionActions();
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const transactionStore = { transactions: transactionSlice.reducer };
export const { seedTransactions, updateTransaction, addTransaction, removeTransaction } = transactionSlice.actions;
