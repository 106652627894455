import { EntityType } from '@frontend/common';
import { problemRequest } from '@frontend/problem/events';
import { Problem } from '@frontend/problem/types';
import { ObjectBadge, ObjectBadgeProps } from '@frontend/rendering/badge';
import React from 'react';

export const ProblemBadge = (props: ObjectBadgeProps<Problem>) => {
    return (
        <ObjectBadge<Problem>
            id={props.id}
            type={EntityType.PROBLEM}
            mapDisplayName={(ac: Problem) => ac.name}
            resolve={problemRequest}
            {...props}
        />
    );
};
