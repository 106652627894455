import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { Token } from '@frontend/authentication-v2';
import { CreateProduct, IProduct, ProductListResponse, ProductQueryParams, UpdateProduct } from '@frontend/product/types';

const endpoint = '/product-api/v1';
export class ProductClient extends APIClient {
    public static async fetchProducts(
        queryParams?: ApiQueryParams<DefaultQueryParams & ProductQueryParams>,
        withCredentials?: Token
    ): Promise<ProductListResponse> {
        return await this.apiPaginated<ProductListResponse, DefaultQueryParams & ProductQueryParams>(`${endpoint}/products`, queryParams, withCredentials);
    }

    /**
     * @deprecated
     */
    public static async fetchCatalogProducts(
        queryParams?: ApiQueryParams<DefaultQueryParams & ProductQueryParams & 'catalog_id'>,
        withCredentials?: Token
    ): Promise<ProductListResponse> {
        return await this.apiPaginated<ProductListResponse, DefaultQueryParams & ProductQueryParams & 'catalog_id'>(
            `${endpoint}/catalog-products`,
            queryParams,
            withCredentials
        );
    }

    /**
     * @deprecated
     */
    public static async fetchCategoryProducts(
        accountId: string,
        queryParams?: ApiQueryParams<DefaultQueryParams & ProductQueryParams>,
        withCredentials?: Token
    ): Promise<ProductListResponse> {
        return await this.apiPaginated<ProductListResponse, DefaultQueryParams & ProductQueryParams>(
            `${endpoint}/accounts/${accountId}/category-products`,
            queryParams,
            withCredentials
        );
    }

    public static async resolveProducts(productIds: string[], withCredentials?: Token): Promise<ProductListResponse> {
        const response = await this.post(`${endpoint}/products-resolve`, { ids: productIds }, true, withCredentials);
        return await this.handleResponse<ProductListResponse>(response);
    }

    public static async fetchAccountProducts(accountId: string, queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<ProductListResponse> {
        return await this.apiPaginated<ProductListResponse, DefaultQueryParams>(`${endpoint}/accounts/${accountId}/products`, queryParams);
    }

    public static async postProduct(accountId: string, product: CreateProduct): Promise<IProduct> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/products`, product);
        return await this.handleResponse<IProduct>(response);
    }

    public static async fetchProduct(accountId: string, productId: string): Promise<IProduct> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/products/${productId}`);
        return await this.handleResponse<IProduct>(response);
    }

    public static async fetchProductsNotInGroup(
        accountId: string,
        groupId: string,
        queryParams?: ApiQueryParams<DefaultQueryParams>
    ): Promise<ProductListResponse> {
        return await this.apiPaginated<ProductListResponse, DefaultQueryParams>(`${endpoint}/accounts/${accountId}/groups/${groupId}/products`, queryParams);
    }

    public static async patchProduct(accountId: string, productId: string, product: UpdateProduct): Promise<IProduct> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/products/${productId}`, product);
        return await this.handleResponse<IProduct>(response);
    }

    public static async deleteProduct(accountId: string, productId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/products/${productId}`);
        return await this.handleVoidResponse(response);
    }

    public static async deleteProducts(productIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/products-delete`, { ids: productIds });
        return await this.handleVoidResponse(response);
    }

    public static async postIoTSeed(accountId: string, iotId: string, body: { account_id: string }): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots/${iotId}/seed`, body);
        return this.handleResponse(response);
    }
}
