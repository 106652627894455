import React from 'react';
import { FaRegCircleCheck, FaRegCircleXmark } from 'react-icons/fa6';

interface BooleanBadgeProps {
    value: boolean;
}

export const BooleanBadge = (props: BooleanBadgeProps) => {
    return props.value ? (
        <FaRegCircleCheck
            className='text-success'
            size={20}
        />
    ) : (
        <FaRegCircleXmark
            className='text-danger'
            size={20}
        />
    );
};
