import { AttributeLinkingClient } from '@frontend/attribute/api';
import { EntityType } from '@frontend/common';
import { useResolver } from '@frontend/repository';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

declare global {
    interface WindowEventMap {
        'useVariantLinking:request': CustomEvent<string>;
    }
}

const REQUEST_VARIANT_EVENT = 'useVariantLinking:request';
const STORAGE_KEY = 'VAR_LINK_REQ';

export function variantLinkingRequest(variantId: string): string {
    window.dispatchEvent(new CustomEvent(REQUEST_VARIANT_EVENT, { detail: variantId }));
    return STORAGE_KEY + '_loading';
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function VariantLinkingResolver(props: Props) {
    useResolver(
        STORAGE_KEY,
        REQUEST_VARIANT_EVENT,
        EntityType.VARIANT_LINKING,
        (ids) => AttributeLinkingClient.resovleAttributeLinkingsWithAttributeName(ids, 'variant_id'),
        props.dispatch
    );
    return <></>;
}
