import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { AnswerRoutes } from './answer/answer-routes';

const QuestionOverview = lazy(() => import('../question/question-overview'));
const QuestionCreate = lazy(() => import('../question/question-create/question-create.component'));
const QuestionDetail = lazy(() => import('../question/question-detail/question-detail.component'));
const QuestionUpdate = lazy(() => import('../question/question-update/question-update.component'));

const AnswerRoute = AnswerRoutes();

export function QuestionRoutes() {
    return (
        <Route path='questions'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <QuestionOverview />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <QuestionCreate />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <QuestionCreate />
                    </AsyncComponent>
                }
            />
            <Route path=':questionId'>
                <Route
                    path='detail'
                    element={
                        <AsyncComponent>
                            <QuestionDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <QuestionUpdate />
                        </AsyncComponent>
                    }
                />
            </Route>
            {AnswerRoute}
            <Route
                index
                element={<Navigate to='/admin/questions' />}
            />
        </Route>
    );
}
