import { Row, flexRender } from '@tanstack/react-table';
import React from 'react';

import { RowProps } from '../rows/TRow';

export interface RowElementProps<T extends object> extends RowProps {
    className?: string;
    row: Row<T>;
    onClick?: (element: T) => void;
    id: string;
}

export const RowElement = <T extends object>(props: RowElementProps<T>) => {
    return (
        <tr
            id={`${props.id}-Row`}
            onClick={() => props.onClick && props.onClick(props.row.original)}
            className={
                (props.row.getIsExpanded() && props.row.getCanExpand() ? `bg-gray-${500 - props.row.depth * 100}` : ' ') +
                (props.className || (props.onClick ? 'hover cursor-pointer' : '')) +
                ((props.row.original as any)?.delete_timestamp ? ' bg-danger-medium' : '')
            }>
            {props.row.getAllCells().map((cell) => flexRender(cell.column.columnDef.cell, cell.getContext()))}
        </tr>
    );
};
