import { EntityType } from '@frontend/common';
import { AsyncComponent } from '@frontend/elements';
import { IdentificationType } from '@frontend/identification/types';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

const EquipmentOverview = lazy(() => import('./equipment-overview/equipment-overview.component'));
const EquipmentCreate = lazy(() => import('./equipment-create/equipment-create.component'));
const EquipmentCertificateCreate = lazy(() => import('../../equipment-certificate/equipment-certificate-create/equipment-certificate-create.component'));
const EquipmentCertificateDetail = lazy(() => import('../../equipment-certificate/equipment-certificate-detail/equipment-certificate-detail.component'));
const EquipmentCertificateUpdate = lazy(() => import('../../equipment-certificate/equipment-certificate-update/equipment-certificate-update.component'));
const EquipmentVariableCreate = lazy(() => import('../../equipment-variable/equipment-variable-create/equipment-variable-create.component'));
const EquipmentVariableUpdate = lazy(() => import('../../equipment-variable/equipment-variable-update/equipment-variable-update.component'));
const IdentificationCreate = lazy(() => import('../../identification/identification-create/identification-create.component'));
const IdentificationDetail = lazy(() => import('../../identification/identification-detail/identification-detail.component'));
const LinkTag = lazy(() => import('./equipment-detail/link-tag/link-tag.component'));
const ProblemCreate = lazy(() => import('../../problem/problem-create/problem-create.component'));
const ProblemDetail = lazy(() => import('../../problem/problem-detail/problem-detail.component'));
const ProblemUpdate = lazy(() => import('../../problem/problem-update/problem-update.component'));
const EquipmentDetail = lazy(() => import('./equipment-detail/equipment-detail.component'));
const EquipmentUpdate = lazy(() => import('./equipment-update/equipment-update.component'));
const ReportProblem = lazy(() => import('../report-problem/report-problem.component'));

export function EquipmentRoutes() {
    return (
        <Route path='equipments'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <EquipmentOverview />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <EquipmentCreate />
                    </AsyncComponent>
                }
            />

            <Route path=':equipmentId'>
                <Route
                    path='detail'
                    element={
                        <AsyncComponent>
                            <EquipmentDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <EquipmentUpdate />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='add-tag'
                    element={
                        <IdentificationCreate
                            entity_type={EntityType.EQUIPMENT}
                            type={IdentificationType.TAG}
                            paramKey='equipmentId'
                        />
                    }
                />
                <Route
                    path='link-tag'
                    element={
                        <AsyncComponent>
                            <LinkTag />
                        </AsyncComponent>
                    }
                />
                <Route path='equipment-certificates'>
                    <Route
                        path='create'
                        element={
                            <AsyncComponent>
                                <EquipmentCertificateCreate />
                            </AsyncComponent>
                        }
                    />
                </Route>

                <Route path='certificates'>
                    <Route path=':certificateId'>
                        <Route path='equipment-certificates'>
                            <Route path=':equipmentCertificateId'>
                                <Route
                                    index
                                    path='detail'
                                    element={
                                        <AsyncComponent>
                                            <EquipmentCertificateDetail />
                                        </AsyncComponent>
                                    }
                                />
                                <Route
                                    path='update'
                                    element={
                                        <AsyncComponent>
                                            <EquipmentCertificateUpdate />
                                        </AsyncComponent>
                                    }
                                />
                            </Route>
                        </Route>
                    </Route>
                </Route>

                <Route path='problems'>
                    <Route
                        path='create'
                        element={
                            <AsyncComponent>
                                <ProblemCreate />
                            </AsyncComponent>
                        }
                    />
                    <Route
                        index
                        element={<Navigate to='/admin/equipments' />}
                    />
                    <Route path=':problemId'>
                        <Route
                            path='detail'
                            element={
                                <AsyncComponent>
                                    <ProblemDetail />
                                </AsyncComponent>
                            }
                        />
                        <Route
                            path='update'
                            element={
                                <AsyncComponent>
                                    <ProblemUpdate />
                                </AsyncComponent>
                            }
                        />
                        <Route
                            index
                            element={<Navigate to='equipments' />}
                        />
                    </Route>
                </Route>

                <Route path=':identificationType'>
                    <Route
                        index
                        element={<Navigate to='../detail' />}
                    />
                    <Route path=':identificationId'>
                        <Route
                            path='detail'
                            element={
                                <AsyncComponent>
                                    <IdentificationDetail />
                                </AsyncComponent>
                            }
                        />
                    </Route>
                </Route>
                <Route
                    path='report-problem'
                    element={
                        <AsyncComponent>
                            <ReportProblem />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='add-variable'
                    element={
                        <AsyncComponent>
                            <EquipmentVariableCreate />
                        </AsyncComponent>
                    }
                />
                <Route path='variables'>
                    <Route
                        path=':variableId/update'
                        element={<EquipmentVariableUpdate />}
                    />
                </Route>
            </Route>
        </Route>
    );
}
