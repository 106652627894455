import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

const UserGroupCreate = lazy(() => import('../user-group/user-group-create/user-group-create.component'));
const UserGroupOverview = lazy(() => import('../user-group/user-group-overview'));
const UserGroupDetail = lazy(() => import('../user-group/user-group-detail/user-group-detail.component'));
const UserGroupUpdate = lazy(() => import('../user-group/user-group-update/user-group-update.component'));
const UserGroupMemberAdd = lazy(() => import('../user-group/user-group-member-add/user-group-member-add.component'));

export function UserGroupRoutes() {
    return (
        <Route path='user-groups'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <UserGroupOverview />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <UserGroupCreate />
                    </AsyncComponent>
                }
            />
            <Route path=':groupId'>
                <Route
                    index
                    path='detail'
                    element={
                        <AsyncComponent>
                            <UserGroupDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <UserGroupUpdate />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='members/add'
                    element={
                        <AsyncComponent>
                            <UserGroupMemberAdd />
                        </AsyncComponent>
                    }
                />
            </Route>
            <Route
                index
                element={<Navigate to='/admin/user-groups' />}
            />
        </Route>
    );
}
