import { ApiError } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { ModuleEnumClient } from '@frontend/module/api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

interface ModuleState {
    moduleTypes: string[];
    status: SliceStatus;
}

const initialState: ModuleState = {
    moduleTypes: [],
    status: SliceStatus.INIT
};

const moduleSlice = createSlice({
    name: 'moduleSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchModuleTypes.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchModuleTypes.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                state.moduleTypes = action.payload;
            });
    }
});

export const fetchModuleTypes = createAsyncThunk<string[], void>('fetchModuleTypes', async (_, { rejectWithValue }) => {
    try {
        return await ModuleEnumClient.fetchModuleTypes();
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const moduleStore = { modules: moduleSlice.reducer };
