import { lazy } from 'react';

const EntityTypeSelect = lazy(() => import('@frontend/basic-forms').then((module) => ({ default: module.EntityTypeSelect })));
const LanguageSelect = lazy(() => import('@frontend/basic-forms').then((module) => ({ default: module.LanguageSelect })));
const NotificationChannelSelect = lazy(() => import('@frontend/basic-forms').then((module) => ({ default: module.NotificationChannelSelect })));
const RightSelect = lazy(() => import('@frontend/basic-forms').then((module) => ({ default: module.RightSelect })));
const LevelSelect = lazy(() => import('@frontend/events/select').then((module) => ({ default: module.LevelSelect })));
const SlotStatusSelect = lazy(() => import('@frontend/slot/select').then((module) => ({ default: module.SlotStatusSelect })));
const ImpexEntityTypeSelect = lazy(() => import('@frontend/impex/select').then((module) => ({ default: module.ImpexEntityTypeSelect })));
const ImpexActionSelect = lazy(() => import('@frontend/impex/select').then((module) => ({ default: module.ImpexActionSelect })));
const ImpexFormatTypeSelect = lazy(() => import('@frontend/impex/select').then((module) => ({ default: module.ImpexFormatTypeSelect })));
const ImpexChannelSelect = lazy(() => import('@frontend/impex/select').then((module) => ({ default: module.ImpexChannelSelect })));
const ImpexStateSelect = lazy(() => import('@frontend/impex/select').then((module) => ({ default: module.ImpexStateSelect })));
const ModuleTypeSelect = lazy(() => import('@frontend/module/select').then((module) => ({ default: module.ModuleTypeSelect })));
const EquipmentStateSelect = lazy(() => import('@frontend/equipment/select').then((module) => ({ default: module.EquipmentStateSelect })));
const IdentificationTypeSelect = lazy(() => import('@frontend/identification/select').then((module) => ({ default: module.IdentificationTypeSelect })));
const ProductTypeSelect = lazy(() => import('@frontend/product/select').then((module) => ({ default: module.ProductTypeSelect })));
const ProblemTypeSelect = lazy(() => import('@frontend/problem/select').then((module) => ({ default: module.ProblemTypeSelect })));
const CertificateTypeSelect = lazy(() => import('@frontend/certificate/select').then((module) => ({ default: module.CertificateTypeSelect })));
const AnswerResultSelect = lazy(() => import('@frontend/question/common').then((module) => ({ default: module.AnswerResultSelect })));
const PeriodSelect = lazy(() => import('@frontend/budget/select').then((module) => ({ default: module.PeriodSelect })));
const GroupTypeSelect = lazy(() => import('@frontend/group/select').then((module) => ({ default: module.GroupTypeSelect })));

const IoTTypeSelect = lazy(() => import('@frontend/iot/select').then((module) => ({ default: module.IoTTypeSelect })));
const IoTBrandSelect = lazy(() => import('@frontend/iot/select').then((module) => ({ default: module.IoTBrandSelect })));
const WorkflowTypeSelect = lazy(() => import('@frontend/workflow/select').then((module) => ({ default: module.WorkflowTypeSelect })));
const DeviceBrandSelect = lazy(() => import('@frontend/device/select').then((module) => ({ default: module.DeviceBrandSelect })));
const DeviceProtocolSelect = lazy(() => import('@frontend/device/select').then((module) => ({ default: module.DeviceProtocolSelect })));
const DeviceTypeSelect = lazy(() => import('@frontend/device/select').then((module) => ({ default: module.DeviceTypeSelect })));
const UserInterfaceTypeSelect = lazy(() => import('@frontend/user-interface/common').then((module) => ({ default: module.UserInterfaceTypeSelect })));
const UserInterfacePlatformSelect = lazy(() => import('@frontend/user-interface/common').then((module) => ({ default: module.UserInterfacePlatformSelect })));
const UserInterfaceButtonTypeSelect = lazy(() =>
    import('@frontend/user-interface-button/select').then((module) => ({ default: module.UserInterfaceButtonTypeSelect }))
);
const UserInterfaceButtonActionSelect = lazy(() =>
    import('@frontend/user-interface-button/select').then((module) => ({ default: module.UserInterfaceButtonActionSelect }))
);
const PackageStateSelect = lazy(() => import('@frontend/package/common').then((module) => ({ default: module.PackageStateSelect })));
const IoTStateSelect = lazy(() => import('@frontend/iot/select').then((module) => ({ default: module.IoTStateSelect })));
const StockTypeSelect = lazy(() => import('@frontend/stock/select').then((module) => ({ default: module.StockTypeSelect })));
const TransactionTriggerSelect = lazy(() => import('@frontend/transaction/components').then((module) => ({ default: module.TransactionTriggerSelect })));
const EquipmentTriggerSelect = lazy(() => import('@frontend/equipment/select').then((module) => ({ default: module.EquipmentTriggerSelect })));
const PackageTriggerSelect = lazy(() => import('@frontend/package/common').then((module) => ({ default: module.PackageTriggerSelect })));
const IoTTriggerSelect = lazy(() => import('@frontend/iot/select').then((module) => ({ default: module.IoTTriggerSelect })));
const SlotTypeSelect = lazy(() => import('@frontend/slot/select').then((module) => ({ default: module.SlotTypeSelect })));
const ConstraintTypeSelect = lazy(() => import('@frontend/constraint/select').then((module) => ({ default: module.ConstraintTypeSelect })));
const RequirementAlgorithmSelect = lazy(() => import('@frontend/stock/select').then((module) => ({ default: module.RequirementAlgorithmSelect })));
const TransactionStateSelect = lazy(() => import('@frontend/transaction/components').then((module) => ({ default: module.TransactionStateSelect })));
const TransactionTypeSelect = lazy(() => import('@frontend/transaction/select').then((module) => ({ default: module.TransactionTypeSelect })));
const ModuleTemplateTypeSelect = lazy(() => import('@frontend/module/select').then((module) => ({ default: module.ModuleTemplateTypeSelect })));
const PackageTypeSelect = lazy(() => import('@frontend/package/select').then((module) => ({ default: module.PackageTypeSelect })));
const DatePeriodSelect = lazy(() => import('@frontend/basic-forms').then((module) => ({ default: module.PeriodSelect })));
const ChangeStatusSelect = lazy(() => import('@frontend/sync/select').then((module) => ({ default: module.ChangeStatusSelect })));
const ConstraintModeSelect = lazy(() => import('@frontend/constraint/select').then((module) => ({ default: module.ConstraintModeSelect })));
const GroupNodeTypeSelect = lazy(() => import('@frontend/group/select').then((module) => ({ default: module.GroupNodeTypeSelect })));

const TransactionActionSelect = lazy(() => import('@frontend/transaction/components').then((module) => ({ default: module.TransactionActionSelect })));
const EquipmentActionSelect = lazy(() => import('@frontend/equipment/select').then((module) => ({ default: module.EquipmentActionSelect })));
const PackageActionSelect = lazy(() => import('@frontend/package/common').then((module) => ({ default: module.PackageActionSelect })));
const IoTActionSelect = lazy(() => import('@frontend/iot/select').then((module) => ({ default: module.IoTActionSelect })));
const UserIdentifierSelect = lazy(() => import('@frontend/account/select').then((module) => ({ default: module.UserIdentifierSelect })));
/**
 * Enum selects should be put here
 * @deprecated
 */
export const EnumSelectList = {
    entity_type: EntityTypeSelect,
    notification_channel: NotificationChannelSelect,
    right: RightSelect,
    language: LanguageSelect,
    result: AnswerResultSelect,
    impex_entity_type: ImpexEntityTypeSelect,
    impex_action: ImpexActionSelect,
    impex_format_type: ImpexFormatTypeSelect,
    impex_channel: ImpexChannelSelect,
    impex_state: ImpexStateSelect,
    module_type: ModuleTypeSelect,
    identification_type: IdentificationTypeSelect,
    problem_type: ProblemTypeSelect,
    certificate_type: CertificateTypeSelect,
    period: PeriodSelect,
    user_interface_button_type: UserInterfaceButtonTypeSelect,
    user_interface_button_action: UserInterfaceButtonActionSelect,
    group_type: GroupTypeSelect,
    stock_type: StockTypeSelect,
    slot_type: SlotTypeSelect,
    level: LevelSelect,
    constraint_type: ConstraintTypeSelect,
    requirement_algorithm: RequirementAlgorithmSelect,
    transaction_type: TransactionTypeSelect,
    module_template_type: ModuleTemplateTypeSelect,
    date_period: DatePeriodSelect
};

export const EnumSelectListV2 = {
    group_node_type: GroupNodeTypeSelect,
    user_interface_type: UserInterfaceTypeSelect,
    user_interface_platform: UserInterfacePlatformSelect,
    transaction_trigger: TransactionTriggerSelect,
    equipment_trigger: EquipmentTriggerSelect,
    package_trigger: PackageTriggerSelect,
    iot_trigger: IoTTriggerSelect,
    transaction_state: TransactionStateSelect,
    equipment_state: EquipmentStateSelect,
    package_state: PackageStateSelect,
    iot_state: IoTStateSelect,
    transaction_action: TransactionActionSelect,
    equipment_action: EquipmentActionSelect,
    package_action: PackageActionSelect,
    iot_action: IoTActionSelect,
    user_identifier_field: UserIdentifierSelect,
    device_type: DeviceTypeSelect,
    device_brand: DeviceBrandSelect,
    device_protocol: DeviceProtocolSelect,
    workflow_type: WorkflowTypeSelect,
    iot_type: IoTTypeSelect,
    iot_brand: IoTBrandSelect,
    constraint_mode: ConstraintModeSelect,
    change_status: ChangeStatusSelect,
    slot_status: SlotStatusSelect,
    product_type: ProductTypeSelect,
    package_type: PackageTypeSelect
};
