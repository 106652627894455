import { authorizationStore } from '@frontend/authorization';
import { equipmentStore } from '@frontend/equipment/redux';
import { impexStore } from '@frontend/impex/redux';
import { formStore, listStore, resolveStore, wizardStore } from '@frontend/repository';
import { userStore } from '@frontend/user/redux';
import { workflowSharedStore } from '@frontend/workflow/redux';
import { configureStore } from '@reduxjs/toolkit';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

export const store = configureStore({
    reducer: {
        ...authorizationStore,
        ...userStore,
        ...listStore,
        ...formStore,
        ...wizardStore,
        ...resolveStore,
        ...equipmentStore,
        ...workflowSharedStore,
        ...impexStore
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
