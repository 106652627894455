/* eslint-disable react/prop-types */
import { hasAnyPermission, Permission, UserPermissions } from '@frontend/authorization';
import { ClassType, EntityType } from '@frontend/common';
import { ConfirmationModal, Dropdown, DropdownItem, DropdownItemProps } from '@frontend/elements';
import { ErrorHandler } from '@frontend/error-handler';
import { ICONS } from '@frontend/icons';
import { CommonMessage } from '@frontend/lang';
import { Checkbox } from '@frontend/table';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { CellContext, ColumnDef, createColumnHelper, HeaderContext, Row } from '@tanstack/react-table';
import React from 'react';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';

import { ObjectCellMapper } from './cells/object-cell-mapper';

export interface ObjectColumnsProps<T extends { id: string } & object> {
    listStoreId?: string;
    dispatch: ThunkDispatch<any, any, Action>;
    columns: (keyof T)[] | string[];
    showIdAsEntity?: EntityType;
    headerOverwrite?: Map<keyof T | string, React.ReactNode>;
    valueOverwrite?: Map<keyof T, keyof T>;
    /**
     * @deprecated
     * should use the document repository
     */
    blobColumnOverwrite?: Map<keyof T, (object: T) => Promise<Blob>>;
    documentColumnOverwrite?: {
        key: keyof T;
        entityType: EntityType;
    };
    /**
     * Should be used if no generic cell is available for the required functionality
     */
    customCells?: Map<string, (props: CellContext<T, any>) => React.ReactNode>;
    /**
     * the first provided key should contain the key holding the entity id
     * the second should hold the entity type
     */
    entityColumnOverwrite?: Map<keyof T, keyof T>;
    entityColumnOverwriteProps?: Map<keyof T, any>;
    hidden?: (keyof T | string)[];
    selectEnabled?: boolean;
    /**
     * @description keys should be provided in the correct order to use the delete function
     */
    customActionButtons?: (Omit<DropdownItemProps, 'onClick' | 'hide'> & { onClick: (obj: T) => void; hide?: ((obj: T) => boolean) | boolean })[];
    cellClassName?: string | ((row: Row<T>) => string);
    update?: {
        updateAction?: (object: T) => void;
        requiredPermissions?: Permission[];
    };
    delete?: {
        deleteAction?: (...args: any) => Promise<void>;
        deleteKeys?: (keyof T)[];
        displayName?: (value: T) => React.ReactNode;
        requiredPermissions?: Permission[];
    };
    permissions?: UserPermissions | null;
    expandEnabled?: (row: Row<T>) => boolean;
    onExpand?: (row: Row<T>) => void;
    onAllExpand?: (obj: T) => void;
    showExpandAll?: boolean;
    recovery?: {
        onRecover: (object: T) => void;
        requiredPermissions?: Permission[];
    };
}
export const defaultItemClassName = 'text-xs text-primary align-content-center';
/**
 * @param props
 * @returns
 */
export const ObjectColumns = <T extends { id: string } & object>(props: ObjectColumnsProps<T>): ColumnDef<T>[] => {
    return convert<T>(props);
};

function convert<T extends { id: string } & object>(props: ObjectColumnsProps<T>): ColumnDef<T>[] {
    const result: ColumnDef<T>[] = [];
    const columnHelper = createColumnHelper<T>();
    if (props.selectEnabled && props.listStoreId !== undefined) {
        result.push(columnHelper.display({
            header: (headerProps: HeaderContext<T, unknown>) => (
                    <Checkbox
                        dispatch={props.dispatch}
                        listStoreId={props.listStoreId!}
                        {...headerProps.table.getToggleAllPageRowsSelectedHandler()}
                    />
            ),
            id: 'selection',
            cell: (cellProps: CellContext<T, any>) => {
                return (
                    <td
                        style={{ width: '30px' }}
                        onClick={(e) => e.stopPropagation()}
                        key={cellProps.row.original.id}>
                        <Checkbox
                            dispatch={props.dispatch}
                            listStoreId={props.listStoreId!}
                            original={cellProps.row.original}
                            {...cellProps.row.getToggleSelectedHandler()}
                        />
                    </td>
                );
            }
        }));
    }

    props.columns.forEach((col) => {
        if (props.hidden?.includes(col)) return;
        result.push({
            header: (headerProps: HeaderContext<T, unknown>) => {
                if (typeof col != 'string') return;
                if (props.headerOverwrite && props.headerOverwrite.has(col)) {
                    return props.headerOverwrite.get(col);
                } else {
                    return (col[0].toUpperCase() + col.slice(1)).replace(/_|timestamp/g, ' ');
                }
            },
            id: col as string,
            cell: (cellProps: CellContext<T, any>) => (
                <ObjectCellMapper
                    className={props.cellClassName}
                    {...cellProps}
                    parent={props}
                    cellKey={col}
                />
            ),
            accessorKey: col as keyof T
        });
    });

    if (props.update || props.delete || props.customActionButtons || props.recovery) {
        const permissions = [
            ...(props.update?.requiredPermissions || []),
            ...(props.delete?.requiredPermissions || []),
            ...(props.customActionButtons?.map((button) => (button.requiredPermissions ? button.requiredPermissions : [])).flat() || []),
            ...(props.recovery?.requiredPermissions || [])
        ];
        if (props.permissions && permissions) {
            if (hasAnyPermission(props.permissions, permissions) || permissions.length === 0) {
                result.push(columnHelper.display({
                    header: '',
                    id: 'actions',
                    cell: (cellProps: CellContext<T, any>) => {
                        const [showDeleteModal, changeShowDeleteModal] = React.useState(false);
                        return (
                            <td
                                className='align-content-center'
                                onClick={(e) => e.stopPropagation()}>
                                <Dropdown
                                    label='...'
                                    buttonStyle='font-weight-bolder text-lg'
                                    hideChevron
                                    requiredPermissions={permissions.length > 0 ? permissions : undefined}>
                                    <DropdownItem
                                        label={CommonMessage.BUTTONS.UPDATE}
                                        icon={ICONS.BUTTON.UPDATE}
                                        onClick={() => props.update?.updateAction && props.update.updateAction(cellProps.row.original)}
                                        classType={ClassType.INFO}
                                        hide={!props.update}
                                        requiredPermissions={props.update?.requiredPermissions}
                                    />
                                    <DropdownItem
                                        label={CommonMessage.BUTTONS.DELETE}
                                        icon={ICONS.BUTTON.DELETE}
                                        onClick={() => changeShowDeleteModal(true)}
                                        classType={ClassType.DANGER}
                                        hide={!props.delete}
                                        requiredPermissions={props.delete?.requiredPermissions}
                                    />
                                    <DropdownItem
                                        label={CommonMessage.BUTTONS.RECOVER}
                                        icon={ICONS.BUTTON.RECOVER}
                                        onClick={() => props.recovery?.onRecover && props.recovery.onRecover(cellProps.row.original)}
                                        classType={ClassType.SUCCESS}
                                        hide={!props.recovery || !(cellProps.row.original as any).delete_timestamp}
                                        requiredPermissions={props.recovery?.requiredPermissions}
                                    />
                                    {props.customActionButtons &&
                                        props.customActionButtons.map((button) => (
                                            <DropdownItem
                                                {...button}
                                                onClick={() => button.onClick && button.onClick(cellProps.row.original)}
                                                requiredPermissions={button.requiredPermissions}
                                                hide={
                                                    button.hide !== undefined
                                                        ? () => (typeof button.hide === 'function' ? button.hide!(cellProps.row.original) : button.hide!)
                                                        : undefined
                                                }
                                            />
                                        ))}
                                </Dropdown>
                                {showDeleteModal && (
                                    <ConfirmationModal
                                        show={showDeleteModal}
                                        handleClose={() => changeShowDeleteModal(false)}
                                        onConfirm={() =>
                                            props.delete?.deleteAction &&
                                            props.delete
                                                .deleteAction(
                                                    ...(props.delete?.deleteKeys ? props.delete.deleteKeys.map((key) => cellProps.row.original[key])! : [])
                                                )
                                                .then(() => changeShowDeleteModal(false))
                                                .catch(ErrorHandler.handleError)
                                        }
                                        message={CommonMessage.FORMS.DELETE_OBJECT(
                                            props.delete?.displayName ? (props.delete.displayName(cellProps.row.original) as string) : ''
                                        )}
                                        severity={ClassType.DANGER}
                                        cascadeWarning
                                    />
                                )}
                            </td>
                        );
                    }
                }));
            }
        }
    }
    if (props.expandEnabled) {
        result.push(columnHelper.display({
            header: (headerProps: HeaderContext<T, unknown>) => {
                if (!props.showExpandAll) return <td></td>;
                return headerProps.table.getIsAllRowsExpanded() ? (
                    <FaChevronDown
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            headerProps.table.toggleAllRowsExpanded(false);
                        }}
                        className='cursor-pointer'
                    />
                ) : (
                    <FaChevronRight
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            headerProps.table.getCoreRowModel().rows.forEach((row) => {
                                const recursiveFetch = async (r: Row<T>) => {
                                    if (r.original && (r.original as any).children) {
                                        if ((r.original as any).children.length === 0) {
                                            return;
                                        }
                                        for (const child of (r.original as any).children) {
                                            props.onAllExpand && props.onAllExpand(child);
                                            await recursiveFetch(child);
                                        }
                                    }
                                };
                                recursiveFetch(row);
                            })
                            headerProps.table.toggleAllRowsExpanded(true);
                        }}
                        className='cursor-pointer'
                    />
                );
            },
            id: 'expand',
            cell: (cellProps: CellContext<T, any>) => {
                if (props.expandEnabled && !props.expandEnabled(cellProps.row)) return <td></td>;
                return (
                    <td
                        style={{ width: '30px' }}
                        onClick={(e) => e.stopPropagation()}
                        key={cellProps.row.original.id}>
                        {cellProps.row.getIsExpanded() ? (
                            <FaChevronDown
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    cellProps.row.toggleExpanded(false);
                                }}
                                className='cursor-pointer'
                            />
                        ) : (
                            <FaChevronRight
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    props.onExpand && props.onExpand(cellProps.row);
                                    cellProps.row.toggleExpanded(true);
                                }}
                                className='cursor-pointer'
                            />
                        )}
                    </td>
                );
            }
        }));
    }

    return result;
}
