import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Route } from 'react-router-dom';

const StockOverview = lazy(() => import('../stock/stock-overview/stock-overview'));
const StockCreate = lazy(() => import('../stock/stock-create/stock-create.component'));
const StockDetail = lazy(() => import('../stock/stock-detail/stock-detail.component'));
const StockUpdate = lazy(() => import('../stock/stock-update/stock-update.component'));
export function StockRoutes() {
    return (
        <Route path='stocks'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <StockOverview />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <StockCreate />
                    </AsyncComponent>
                }
            />
            <Route path=':stockId'>
                <Route
                    index
                    path='detail'
                    element={
                        <AsyncComponent>
                            <StockDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <StockUpdate />
                        </AsyncComponent>
                    }
                />
            </Route>
        </Route>
    );
}
