import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { EquipmentHistoricalUseListResponse, EquipmentInventoryListResponse } from '@frontend/equipment/types';

const endpoint = '/equipment-api/v1';
export class EquipmentReportClient extends APIClient {
    public static async fetchEquipmentHistoryReport(
        accountId: string,
        queryParams?: ApiQueryParams<DefaultQueryParams>
    ): Promise<EquipmentHistoricalUseListResponse> {
        return await this.apiPaginated<EquipmentHistoricalUseListResponse, DefaultQueryParams>(
            `${endpoint}/accounts/${accountId}/equipment-historical-use`,
            queryParams
        );
    }

    public static async fetchEquipmentInventoryReport(accountId: string, queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<EquipmentInventoryListResponse> {
        return await this.apiPaginated<EquipmentInventoryListResponse, DefaultQueryParams>(
            `${endpoint}/accounts/${accountId}/equipment-inventory`,
            queryParams
        );
    }
}
