import { AccountClient } from '@frontend/account/api';
import { BrandingClient } from '@frontend/branding/api';
import { useAccountRepository } from '@frontend/repository';
import { useEffect, useState } from 'react';

export const useTheme = () => {
    const { store } = useAccountRepository();
    const [theme, changeTheme] = useState<any | null>(null);

    useEffect(() => {
        if (store.currentAccount)
            AccountClient.fetchAccount(store.currentAccount).then((account) => {
                if (!account.branding_id) return;
                BrandingClient.fetchBranding(account.branding_id).then((branding) => {
                    const mainColor = branding.main_color;
                    const secondaryColor = branding.secondary_color;
                    if (!mainColor && !secondaryColor) return;
                    changeTheme({
                        bodyColor: mainColor,
                        bgColor: secondaryColor,
                        sidenavBgColor: secondaryColor,
                        cardBgColor: secondaryColor,
                        cardBoxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)',
                        inputBgImage:
                            'linear-gradient(0deg, #e91e63 2px, rgba(156, 39, 176, 0) 0), linear-gradient(0deg, rgba(210, 210, 210, 0.6) 1px, rgba(209, 209, 209, 0) 0)',
                        borderColor: '#fff',
                        tableColor: '#fff',
                        caretSidebarColor: 'rgba(206, 212, 218, 0.7)',
                        navLinkActiveShadow: '0 0 2rem 0 rgba(0, 0, 0, 0.15)',
                        gradientDark: mainColor,
                        gradientStateDark: secondaryColor,
                        sidebarNavActiveBg: mainColor,
                        gradient: mainColor,
                        gradientState: secondaryColor,
                        secondary: secondaryColor,
                        primary: mainColor
                    });
                });
            });
    }, [store.currentAccount]);

    useEffect(() => {
        if (theme !== null) applyTheme(theme);
    }, [theme]);
};

function applyTheme(theme: any) {
    const root = document.documentElement;
    if (theme.bgColor) root.style.setProperty('--dark-version-bg-color', theme.bgColor);
    if (theme.bodyColor) root.style.setProperty('--dark-version-body-color', theme.bodyColor);
    if (theme.sidenavBgColor) root.style.setProperty('--dark-version-sidenav-bg-color', theme.sidenavBgColor);
    if (theme.cardBgColor) root.style.setProperty('--dark-version-card-bg-color', theme.cardBgColor);
    if (theme.cardBoxShadow) root.style.setProperty('--dark-version-card-box-shadow', theme.cardBoxShadow);
    if (theme.inputBgImage) root.style.setProperty('--dark-version-input-bg-image', theme.inputBgImage);
    if (theme.borderColor) root.style.setProperty('--dark-version-border-color', theme.borderColor);
    if (theme.tableColor) root.style.setProperty('--dark-version-table-color', theme.tableColor);
    if (theme.caretSidebarColor) root.style.setProperty('--dark-version-caret-sidebar-color', theme.caretSidebarColor);
    if (theme.navLinkActiveShadow) root.style.setProperty('--dark-nav-link-active-shadow', theme.navLinkActiveShadow);
    if (theme.gradientDark) root.style.setProperty('--dark-gradient-dark', theme.gradientDark);
    if (theme.gradientStateDark) root.style.setProperty('--dark-gradient-state-dark', theme.gradientStateDark);
    if (theme.sidebarNavActiveBg) root.style.setProperty('--dark-sidebar-nav-active-bg', theme.sidebarNavActiveBg);
    if (theme.gradient) root.style.setProperty('--dark-gradient', theme.gradient);
    if (theme.gradientState) root.style.setProperty('--dark-gradient-state', theme.gradientState);
    if (theme.primary) root.style.setProperty('--primary', theme.primary);
    if (theme.secondary) root.style.setProperty('--secondary', theme.secondary);

    const mockElement = {
        attributes: {
            checked: false // Initial value of the checked attribute
        },
        getAttribute: function (attr: string | number) {
            return (this.attributes as any)[attr] || null; // Return the attribute value or null
        },
        removeAttribute: function (attr: any) {
            delete (this.attributes as any)[attr]; // Remove the attribute from the mock element
        },
        setAttribute: function (attr: any, value: any) {
            (this.attributes as any)[attr] = value; // Set the attribute value
        }
    };
    darkMode(mockElement);
}

function darkMode(el: any) {
    const body = document.getElementsByTagName('body')[0];
    const hr = document.querySelectorAll('div:not(.sidenav) > hr');
    const sidebar = document.querySelector('.sidenav');
    const sidebarWhite = document.querySelectorAll('.sidenav.bg-white');
    const hr_card = document.querySelectorAll('div:not(.bg-gradient-dark) hr');
    const text_btn = document.querySelectorAll('button:not(.btn) > .text-dark');
    const text_span = document.querySelectorAll('span.text-dark, .breadcrumb .text-dark');
    const text_span_white = document.querySelectorAll('span.text-white');
    const text_strong = document.querySelectorAll('strong.text-dark');
    const text_strong_white = document.querySelectorAll('strong.text-white');
    const text_nav_link = document.querySelectorAll('a.nav-link.text-dark');
    const secondary = document.querySelectorAll('.text-secondary');
    const bg_gray_100 = document.querySelectorAll('.bg-gray-100');
    const bg_gray_600 = document.querySelectorAll('.bg-gray-600');
    const btn_text_dark = document.querySelectorAll('.btn.btn-link.text-dark, .btn .ni.text-dark');
    const btn_text_white = document.querySelectorAll('.btn.btn-link.text-white, .btn .ni.text-white');
    const card_border = document.querySelectorAll('.card.border');
    const card_border_dark = document.querySelectorAll('.card.border.border-dark');
    const svg = document.querySelectorAll('g');
    const navbarBrand = document.querySelector('.navbar-brand-img');
    const navbarBrandImg = (navbarBrand as any).src;
    const navLinks = document.querySelectorAll('.navbar-main .nav-link, .navbar-main .breadcrumb-item, .navbar-main .breadcrumb-item a, .navbar-main h6');
    const cardNavLinksIcons = document.querySelectorAll('.card .nav .nav-link i');
    const cardNavSpan = document.querySelectorAll('.card .nav .nav-link span');

    if (!el.getAttribute('checked')) {
        body.classList.add('dark-version');
        if (navbarBrandImg.includes('logo-ct-dark.png')) {
            const navbarBrandImgNew = navbarBrandImg.replace('logo-ct-dark', 'logo-ct');
            (navbarBrand as any).src = navbarBrandImgNew;
        }
        for (let i = 0; i < cardNavLinksIcons.length; i++) {
            if (cardNavLinksIcons[i].classList.contains('text-dark')) {
                cardNavLinksIcons[i].classList.remove('text-dark');
                cardNavLinksIcons[i].classList.add('text-white');
            }
        }
        for (let i = 0; i < cardNavSpan.length; i++) {
            if (cardNavSpan[i].classList.contains('text-sm')) {
                cardNavSpan[i].classList.add('text-white');
            }
        }
        for (let i = 0; i < hr.length; i++) {
            if (hr[i].classList.contains('dark')) {
                hr[i].classList.remove('dark');
                hr[i].classList.add('light');
            }
        }
        for (let i = 0; i < hr_card.length; i++) {
            if (hr_card[i].classList.contains('dark')) {
                hr_card[i].classList.remove('dark');
                hr_card[i].classList.add('light');
            }
        }
        for (let i = 0; i < text_btn.length; i++) {
            if (text_btn[i].classList.contains('text-dark')) {
                text_btn[i].classList.remove('text-dark');
                text_btn[i].classList.add('text-white');
            }
        }
        for (let i = 0; i < text_span.length; i++) {
            if (text_span[i].classList.contains('text-dark')) {
                text_span[i].classList.remove('text-dark');
                text_span[i].classList.add('text-white');
            }
        }
        for (let i = 0; i < text_strong.length; i++) {
            if (text_strong[i].classList.contains('text-dark')) {
                text_strong[i].classList.remove('text-dark');
                text_strong[i].classList.add('text-white');
            }
        }
        for (let i = 0; i < text_nav_link.length; i++) {
            if (text_nav_link[i].classList.contains('text-dark')) {
                text_nav_link[i].classList.remove('text-dark');
                text_nav_link[i].classList.add('text-white');
            }
        }
        for (let i = 0; i < secondary.length; i++) {
            if (secondary[i].classList.contains('text-secondary')) {
                secondary[i].classList.remove('text-secondary');
                secondary[i].classList.add('text-white');
                secondary[i].classList.add('opacity-8');
            }
        }
        for (let i = 0; i < bg_gray_100.length; i++) {
            if (bg_gray_100[i].classList.contains('bg-gray-100')) {
                bg_gray_100[i].classList.remove('bg-gray-100');
                bg_gray_100[i].classList.add('bg-gray-600');
            }
        }
        for (let i = 0; i < btn_text_dark.length; i++) {
            btn_text_dark[i].classList.remove('text-dark');
            btn_text_dark[i].classList.add('text-white');
        }
        for (let i = 0; i < sidebarWhite.length; i++) {
            sidebarWhite[i].classList.remove('bg-white');
        }
        for (let i = 0; i < svg.length; i++) {
            if (svg[i].hasAttribute('fill')) {
                svg[i].setAttribute('fill', '#fff');
            }
        }
        for (let i = 0; i < card_border.length; i++) {
            card_border[i].classList.add('border-dark');
        }

        el.setAttribute('checked', 'true');
    } else {
        body.classList.remove('dark-version');
        sidebar?.classList.add('bg-white');
        if (navbarBrandImg.includes('logo-ct.png')) {
            const navbarBrandImgNew = navbarBrandImg.replace('logo-ct', 'logo-ct-dark');
            (navbarBrand as any).src = navbarBrandImgNew;
        }
        for (let i = 0; i < navLinks.length; i++) {
            if (navLinks[i].classList.contains('text-dark')) {
                navLinks[i].classList.add('text-white');
                navLinks[i].classList.remove('text-dark');
            }
        }
        for (let i = 0; i < cardNavLinksIcons.length; i++) {
            if (cardNavLinksIcons[i].classList.contains('text-white')) {
                cardNavLinksIcons[i].classList.remove('text-white');
                cardNavLinksIcons[i].classList.add('text-dark');
            }
        }
        for (let i = 0; i < cardNavSpan.length; i++) {
            if (cardNavSpan[i].classList.contains('text-white')) {
                cardNavSpan[i].classList.remove('text-white');
            }
        }
        for (let i = 0; i < hr.length; i++) {
            if (hr[i].classList.contains('light')) {
                hr[i].classList.add('dark');
                hr[i].classList.remove('light');
            }
        }
        for (let i = 0; i < hr_card.length; i++) {
            if (hr_card[i].classList.contains('light')) {
                hr_card[i].classList.add('dark');
                hr_card[i].classList.remove('light');
            }
        }
        for (let i = 0; i < text_btn.length; i++) {
            if (text_btn[i].classList.contains('text-white')) {
                text_btn[i].classList.remove('text-white');
                text_btn[i].classList.add('text-dark');
            }
        }
        for (let i = 0; i < text_span_white.length; i++) {
            if (
                text_span_white[i].classList.contains('text-white') &&
                !text_span_white[i].closest('.sidenav') &&
                !text_span_white[i].closest('.card.bg-gradient-dark')
            ) {
                text_span_white[i].classList.remove('text-white');
                text_span_white[i].classList.add('text-dark');
            }
        }
        for (let i = 0; i < text_strong_white.length; i++) {
            if (text_strong_white[i].classList.contains('text-white')) {
                text_strong_white[i].classList.remove('text-white');
                text_strong_white[i].classList.add('text-dark');
            }
        }
        for (let i = 0; i < secondary.length; i++) {
            if (secondary[i].classList.contains('text-white')) {
                secondary[i].classList.remove('text-white');
                secondary[i].classList.remove('opacity-8');
                secondary[i].classList.add('text-dark');
            }
        }
        for (let i = 0; i < bg_gray_600.length; i++) {
            if (bg_gray_600[i].classList.contains('bg-gray-600')) {
                bg_gray_600[i].classList.remove('bg-gray-600');
                bg_gray_600[i].classList.add('bg-gray-100');
            }
        }
        for (let i = 0; i < svg.length; i++) {
            if (svg[i].hasAttribute('fill')) {
                svg[i].setAttribute('fill', '#252f40');
            }
        }
        for (let i = 0; i < btn_text_white.length; i++) {
            if (!btn_text_white[i].closest('.card.bg-gradient-dark')) {
                btn_text_white[i].classList.remove('text-white');
                btn_text_white[i].classList.add('text-dark');
            }
        }
        for (let i = 0; i < card_border_dark.length; i++) {
            card_border_dark[i].classList.remove('border-dark');
        }
        el.removeAttribute('checked');
    }
}
