import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';


import { CopyWorkflow, CreateWorkflow, SearchWorkflowQueryParams, UpdateWorkflow, Workflow, WorkflowField, WorkflowListResponse, WorkflowQueryParams } from '@frontend/workflow/types';


const endpoint = '/workflow-api/v1';
export class WorkflowClient extends APIClient {
    public static async fetchWorkflows(queryParams?: ApiQueryParams<DefaultQueryParams | WorkflowQueryParams>): Promise<WorkflowListResponse> {
        return await this.apiPaginated<WorkflowListResponse, DefaultQueryParams | WorkflowQueryParams>(`${endpoint}/workflows`, queryParams);
    }

    public static async resolveWorkflows(workflowIds: string[]): Promise<WorkflowListResponse> {
        const response = await this.post(`${endpoint}/workflows-resolve`, { ids: workflowIds });
        return await this.handleResponse<WorkflowListResponse>(response);
    }

    public static async searchWorkflows(queryParams?: ApiQueryParams<DefaultQueryParams | SearchWorkflowQueryParams>): Promise<WorkflowListResponse> {
        return await this.apiPaginated<WorkflowListResponse, DefaultQueryParams | SearchWorkflowQueryParams>(`${endpoint}/workflows-search`, queryParams);
    }

    public static async fetchAccountWorkflows(accountId: string, queryParams?: ApiQueryParams<DefaultQueryParams | WorkflowQueryParams>): Promise<WorkflowListResponse> {
        return await this.apiPaginated<WorkflowListResponse, DefaultQueryParams | WorkflowQueryParams>(`${endpoint}/accounts/${accountId}/workflows`, queryParams);
    }

    public static async postWorkflow(accountId: string, workflow: CreateWorkflow): Promise<Workflow> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/workflows`, workflow);
        return await this.handleResponse<Workflow>(response);
    }

    public static async fetchWorkflow(accountId: string, workflowId: string): Promise<Workflow> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/workflows/${workflowId}`);
        return await this.handleResponse<Workflow>(response);
    }

    public static async patchWorkflow(accountId: string, workflowId: string, workflow: UpdateWorkflow): Promise<Workflow> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/workflows/${workflowId}`, workflow);
        return await this.handleResponse<Workflow>(response);
    }

    public static async deleteAccountWorkflow(accountId: string, workflowId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/workflows/${workflowId}`);
        return await this.handleVoidResponse(response);
    }

    public static async fetchIncompleteWorkflowFields(accountId: string, workflowId: string): Promise<WorkflowField[]> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/workflows/${workflowId}/incomplete-fields`);
        return await this.handleResponse<WorkflowField[]>(response);
    }

    public static async copyAccountWorkflow(accountId: string, workflowId: string, body: CopyWorkflow): Promise<Workflow> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/workflows/${workflowId}/copy`, body);
        return await this.handleResponse<Workflow>(response);
    }

    public static async deleteWorkflows(workflowIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/workflows-delete`, { ids: workflowIds });
        return await this.handleVoidResponse(response);
    }

    public static async postIoTSeed(accountId: string, iotId: string, body: { account_id: string }): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots/${iotId}/seed`, body);
        return this.handleResponse(response);
    }
}
