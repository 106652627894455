import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Route } from 'react-router-dom';

const RoleCreate2 = lazy(() => import('../role/role-create-2/role-create-2.component'));
const RoleCreatePredefined = lazy(() => import('../role/role-create-predefined/role-create-predefined.component'));
export function CreateRoleRoutes() {
    return (
        <Route path='roles'>
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <RoleCreate2 />
                    </AsyncComponent>
                }
            />
            <Route
                path='predefined'
                element={
                    <AsyncComponent>
                        <RoleCreatePredefined />
                    </AsyncComponent>
                }
            />
        </Route>
    );
}
