import { RoleTemplateClient } from '@frontend/role/api';
import { CustomRoleTemplate } from '@frontend/role/types';
import { useEffect, useMemo, useState } from 'react';

import { CustomRoleTemplateSelectProps } from './custom-role-template-select.component';

interface ViewProps {
    options: { [key: string]: string[] } | null;
    onChange: (key: string, right: string, value: boolean) => void;
}

const useCustomRoleTemplateSelect = (props: CustomRoleTemplateSelectProps): ViewProps => {
    const [template, changeTemplate] = useState<CustomRoleTemplate | null>(null);
    const options = useMemo(() => {
        if (props.entity_type === undefined || template === null) return null;
        return template[props.entity_type];
    }, [template, props.entity_type]);

    useEffect(() => {
        RoleTemplateClient.fetchCustomRoleTemplates().then(changeTemplate);
    }, []);

    useEffect(() => {
        if (options) {
            if (props.value === undefined || compareArrays(Object.keys(options), Object.keys(props.value)).nonOverlapping.length !== 0) {
                if (props.value) {
                    const overlap = compareArrays(Object.keys(options), Object.keys(props.value)).overlapping;
                    const noOverlap = compareArrays(Object.keys(options), Object.keys(props.value)).nonOverlapping;
                    const val = JSON.parse(JSON.stringify(options));
                    overlap.forEach((key) => {
                        val[key] = props.value?.[key];
                    });
                    noOverlap.forEach((key) => {
                        val[key] = [];
                    });
                    props.onChange(val)
                } else {
                    const val = JSON.parse(JSON.stringify(options));
                    Object.keys(val).forEach((key) => {
                        val[key] = [];
                    });
                    props.onChange(val);
                }
            }
        }
    }, [options]);

    const onChange = (key: string, right: string, newValue: boolean) => {
        if (props.value) {
            const val = JSON.parse(JSON.stringify(props.value));
            if (newValue) val[key] = [...val[key], right];
            else val[key] = val[key].filter((v: string) => v !== right);
            props.onChange(val);
        }
    };

    return { options, onChange };
};

export default useCustomRoleTemplateSelect;

function compareArrays(array1: string[], array2: string[]): { overlapping: string[]; nonOverlapping: string[] } {
    const set1 = new Set(array1);
    const set2 = new Set(array2);

    const overlapping = Array.from(set1).filter((item) => set2.has(item));
    const nonOverlapping1 = Array.from(set1).filter((item) => !set2.has(item));
    const nonOverlapping2 = Array.from(set2).filter((item) => !set1.has(item));
    const nonOverlapping = [...nonOverlapping1, ...nonOverlapping2];

    return { overlapping, nonOverlapping };
}
