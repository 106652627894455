import { ApiQueryParams } from '@frontend/api-utils';
import { DateInput, NumberInput } from '@frontend/basic-forms';
import { DefaultModal, DefaultModalProps, ModalType } from '@frontend/elements';
import { onQueryParamChange } from '@frontend/form';
import { CommonMessage } from '@frontend/lang';
import { EnumSelectList, ObjectSelectMapper } from '@frontend/rendering/select';
import { useListRepository } from '@frontend/repository';
import moment from 'moment';
import React from 'react';

import { FilterOptions } from '../header-filter';
import useFilterModal from './filter-modal.controller';

export interface FilterModalProps<T extends object & { id: string }> extends DefaultModalProps, ReturnType<typeof useListRepository<T>> {
    filter?: FilterOptions<T>;
    changeURLParams: (params: ApiQueryParams<string | number>) => void;
}

const FilterModal = <T extends object & { id: string }>(props: FilterModalProps<T>) => {
    const viewProps = useFilterModal(props);
    return (
        <DefaultModal
            type={viewProps.width < 600 ? ModalType.BOTTOM : ModalType.PANE}
            handleClose={props.handleClose}
            show={props.show}
            customWidth={30}>
            <div className='modal-header d-flex flex-wrap'>
                <button
                    className='btn btn-secondary'
                    onClick={props.handleClose}>
                    {CommonMessage.BUTTONS.CLOSE}
                </button>
                {Object.keys(props.params).filter((p) => !p.includes('search')).length > 0 && (
                    <button
                        className='btn btn-danger'
                        onClick={() => {
                            viewProps.setSearchParams({});
                            props.changeURLParams({});
                            props.handleClose();
                        }}>
                        {CommonMessage.BUTTONS.CLEAR}
                    </button>
                )}
                <button
                    className='btn btn-success'
                    onClick={() => {
                        props.changeURLParams(viewProps.params);
                        props.handleClose();
                    }}>
                    {CommonMessage.BUTTONS.SUBMIT}
                </button>
            </div>
            <div className='modal-body'>
                {props.filter?.keys?.map((key) => {
                    if (typeof key === 'string') {
                        const extraProps = props.filter?.keyProps?.get(key) ?? {};
                        if ((key as string).includes('_id')) {
                            return (
                                <ObjectSelectMapper<T>
                                    key={key}
                                    id={key}
                                    objectKey={key as keyof T}
                                    submitted={false}
                                    useConditionedStyling={false}
                                    onChange={(newValue) => {
                                        viewProps.changeParams({
                                            ...viewProps.params,
                                            [props.filter?.queryParamKeyOverwrite?.get(key.toString()) ?? key]: newValue
                                        });
                                    }}
                                    value={viewProps.params?.[props.filter?.queryParamKeyOverwrite?.get(key.toString()) ?? key]}
                                    className='w-100 me-1'
                                    isClearable
                                    allowURLChange
                                    {...extraProps}
                                    {...props}
                                />
                            );
                        }
                        if (['from', 'to', 'timestamp'].includes(key)) {
                            let keyValue = key;
                            if (key.includes('from')) keyValue = 'after';
                            if (key.includes('to')) keyValue = 'before';
                            return (
                                <DateInput
                                    key={key}
                                    id={key}
                                    label={key}
                                    useConditionedStyling={false}
                                    onChange={(newValue) => {
                                        viewProps.changeParams({
                                            ...props.params,
                                            [props.filter?.queryParamKeyOverwrite?.get(keyValue.toString()) ?? keyValue]: newValue
                                                ? moment(newValue).format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ')
                                                : undefined
                                        });
                                    }}
                                    value={viewProps.params?.[props.filter?.queryParamKeyOverwrite?.get(keyValue.toString()) ?? keyValue]}
                                    submitted={false}
                                    className='w-100 me-1'
                                    isClearable
                                    {...extraProps}
                                    {...props}
                                />
                            );
                        }
                        if (['row', 'column'].includes(key)) {
                            return (
                                <NumberInput
                                    key={key}
                                    id={key}
                                    label={key}
                                    onChange={(value) => {
                                        viewProps.changeParams((prev) => ({
                                            ...prev,
                                            [props.filter?.queryParamKeyOverwrite?.get(key.toString()) ?? key]: value.toString()
                                        }));

                                        onQueryParamChange(value.toString(), key.toString(), viewProps.setSearchParams);
                                    }}
                                    value={viewProps.params?.[props.filter?.queryParamKeyOverwrite?.get(key.toString()) ?? key]}
                                    className='w-100 me-1'
                                    isClearable
                                    allowURLChange
                                    {...extraProps}
                                    {...props}
                                />
                            );
                        }
                    }
                    if (key in EnumSelectList) {
                        const extraProps = props.filter?.keyProps?.get(key as string) ?? {};
                        const EnumInput = EnumSelectList[key as keyof typeof EnumSelectList];
                        return (
                            <EnumInput
                                key={key}
                                id={key.toString()}
                                submitted={false}
                                isClearable
                                className='me-1'
                                useConditionedStyling={false}
                                required={false}
                                onChange={(newValue: any) => {
                                    const value = newValue?.value !== '' ? newValue?.value : null;
                                    viewProps.changeParams((prev) => ({
                                        ...prev,
                                        [props.filter?.queryParamKeyOverwrite?.get(key.toString()) ?? key]: value
                                    }));

                                    onQueryParamChange(value, key.toString(), viewProps.setSearchParams);
                                }}
                                value={viewProps.params?.[props.filter?.queryParamKeyOverwrite?.get(key.toString()) ?? key] as any}
                                allowURLChange
                                {...extraProps}
                                {...props}
                            />
                        );
                    }
                    return <></>;
                })}
            </div>
        </DefaultModal>
    );
};

export default FilterModal;
