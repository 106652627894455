import { EntityType } from '@frontend/common';
import { ObjectBadge, ObjectBadgeProps } from '@frontend/rendering/badge';
import { customRoleRequest } from '@frontend/role/events';
import { Role } from '@frontend/role/types';
import React from 'react';

export const CustomRoleBadge = (props: ObjectBadgeProps<Role>) => {
    return (
        <ObjectBadge<Role>
            id={props.id}
            type={EntityType.ROLE}
            mapDisplayName={(ac: Role) => ac.name ?? ''}
            resolve={customRoleRequest}
            navigateString={(object: Role) => `/accounts/${object.account_id}/roles/${object.id}`}
            {...props}
        />
    );
};
