import { isWeekend } from '@frontend/common';
import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import useDateInput from './date-input.controller';

export interface DateInputProps {
    id?: string;
    required?: boolean;
    className?: string;
    valid?: boolean;
    isValidCallback?: (valid: boolean) => void;
    onChange?: (value: Date | undefined) => void;
    onFocusChange?: () => void;
    value?: Date;
    errorMessage?: React.ReactNode;
    label: React.ReactNode;
    helpMessage?: React.ReactNode;
    submitted: boolean;
    disabled?: boolean;

    disallowWeekends?: boolean;
    addedValidCheck?: (date: Date | undefined) => boolean;
    minDate?: Date;
    maxDate?: Date;
    dateTime?: boolean;
}

export const DateInput = (props: DateInputProps) => {
    const viewProps = useDateInput(props);

    return (
        <div className={`form-group ${props.className}`}>
            <label>
                {props.required ? <span className='text-danger me-1'>&#9679;</span> : <></>}
                {props.label}
            </label>
            <input
                className='form-control'
                id={props.id ? props.id : 'date-input'}
                value={props.value ? moment(props.value).format(viewProps.format) : ''}
                type={props.dateTime ? 'datetime-local' : 'date'}
                required={props.required}
                onFocus={() => {
                    viewProps.changeTouched(true);
                    props.onFocusChange && props.onFocusChange();
                }}
                onChange={(e) => {
                    if (e.target.value.length === 0) {
                        props.onChange && props.onChange(undefined);
                    } else {
                        props.onChange && props.onChange(new Date(e.target.value));
                    }
                    viewProps.isValid();
                }}
                min={props.minDate ? moment(props.minDate).format(viewProps.format) : undefined}
                max={props.maxDate ? moment(props.maxDate).format(viewProps.format) : undefined}
                disabled={props.disabled}
            />
            {viewProps.valid === false && viewProps.touched === true && (
                <>
                    {props.disallowWeekends === true && props.value && isWeekend(props.value) ? (
                        <span className='badge bg-gradient-danger mt-2'>
                            <small>{'Weekends not allowed'}</small>
                        </span>
                    ) : (
                        <>
                            {props.minDate && props.value && props.minDate.getTime() > props.value.getTime() ? (
                                <span className='badge bg-gradient-danger mt-2'>
                                    <small>
                                        <FormattedMessage
                                            id='DateTimeInput.HourSelectedToEarly'
                                            description='The error message on the DateTimePicker when the selected time is before the allowed dateTime.'
                                            defaultMessage='You cannot schedule this early. Please pick an moment after {time}.'
                                            values={{
                                                time:
                                                    props.minDate &&
                                                    props.minDate.getHours() +
                                                        ':' +
                                                        (props.minDate.getMinutes() < 10 ? '0' + props.minDate.getMinutes() : props.minDate.getMinutes())
                                            }}
                                        />
                                    </small>
                                </span>
                            ) : (
                                <span className='badge bg-gradient-danger mt-2'>
                                    <small>{props.errorMessage ? props.errorMessage : 'Please enter a valid date and time.'}</small>
                                </span>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
};
