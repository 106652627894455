import { AccountClient } from '@frontend/account/api';
import { Account } from '@frontend/account/types';
import { useAccount } from '@frontend/account/utils';
import { ErrorHandler } from '@frontend/error-handler';
import { useEffect, useState } from 'react';

import { AccountMembershipProps } from './account-membership.component';

interface ViewProps {
    onChangeMembership: (value: string | null) => void;
    accounts: Account[];
    selectedAccount: Account | null;
}

const useAccountMembership = (props: AccountMembershipProps): ViewProps => {
    const accountInfo = useAccount();
    const [accounts, changeAccounts] = useState<Account[]>([]);
    const [selectedAccount, changeSelectedAccount] = useState<Account | null>(null);

    useEffect(() => {
        AccountClient.fetchAccounts()
            .then((res) => changeAccounts(res.results))
            .catch(ErrorHandler.handleError);
    }, []);

    useEffect(() => {
        if (accountInfo.currentAccount) {
            changeSelectedAccount(accounts.find((acc) => acc.id === accountInfo.currentAccount) ?? null);
        }
    }, [accountInfo]);

    const onChangeMembership = (value: string | null) => {
        accountInfo.updateCurrentAccount(value);
    };

    return {
        onChangeMembership,
        selectedAccount,
        accounts
    };
};

export default useAccountMembership;
