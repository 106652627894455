import { APIClient, ApiQueryParams, DefaultQueryParams } from "@frontend/api-utils";
import { CreateProblemEntity, ProblemEntity, ProblemEntityListResponse, ProblemEntityQueryParams } from "@frontend/problem/types";

const endpoint = '/problem-api/v1';

export class ProblemEntityClient extends APIClient {
    public static async fetchProblemEntities(queryParams?: ApiQueryParams<DefaultQueryParams & ProblemEntityQueryParams>): Promise<ProblemEntityListResponse> {
        return await this.apiPaginated<ProblemEntityListResponse, DefaultQueryParams & ProblemEntityQueryParams>(`${endpoint}/problem-entities`, queryParams);
    }

    public static async resolveProblemEntities(ids: string[]): Promise<ProblemEntityListResponse> {
        const response = await this.post(`${endpoint}/problem-entities-resolve`, { ids: ids });
        return await this.handleResponse<ProblemEntityListResponse>(response);
    }

    public static async deleteProblemEntities(ids: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/problem-entities-delete`, { ids: ids });
        return await this.handleVoidResponse(response);
    }

    public static async postProblemEntity(accountId: string, problemId: string, body: CreateProblemEntity): Promise<ProblemEntity> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/problems/${problemId}/entities`, body);
        return await this.handleResponse<ProblemEntity>(response);
    }

    public static async fetchProblemEntity(accountId: string, problemId: string, problemEntityId: string): Promise<ProblemEntity> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/problems/${problemId}/entities/${problemEntityId}`);
        return await this.handleResponse<ProblemEntity>(response);
    }

    public static async deleteProblemEntity(accountId: string, problemId: string, problemEntityId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/problems/${problemId}/entities/${problemEntityId}`);
        return await this.handleVoidResponse(response);
    }
}