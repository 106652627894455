import { EntityType } from '@frontend/common';
import { CommonMessage } from '@frontend/lang';
import { EnumSelectList, EnumSelectListV2 } from '@frontend/rendering/select';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

import { FormField } from './form-field';
import useForm from './form.controller';

const ID = 'form';
export const defaultObjectFormRowClassName = 'w-100 my-1';
export interface FormProps<T extends object, S extends object> {
    id?: string;
    dispatch: ThunkDispatch<any, any, Action>;
    type: EntityType;
    label?: React.ReactNode;
    object?: T;
    value: S;

    /**
     * @description will only show the keys provided in the same order as provided.
     * Is required to make sure undefined values are also rendered.
     * Keys provided in the hidden array will overrule keys provided here.
     *
     * Keys provided in the reqiured prop are merged and therefore do not need to be provided here.
     */
    show?: (keyof S)[];
    /**
     * @description will the keys provided required. Overrules the hidden array.
     * Keys provided here will be merged with the show array. So no need to provide them there again.
     */
    required?: (keyof S)[];
    /**
     * @description will hide the keys provided. If not provided, all keys will be shown.
     */
    hidden?: (keyof S)[];

    /**
     * @description Can be used for keys in the object mapper that dont exist on the object itself.
     */
    objectSelectKeyOverwrite?: Map<keyof S, string>;
    /**
     * @description Keys will be used to populate the labels. This variable allows you to overwrite it.
     */
    keyOverwrite?: Map<keyof S, React.ReactNode>;
    fieldOverwrite?: Map<keyof S, React.ReactNode>;
    fieldProps?: Map<keyof S, any>;
    expectedType?: Map<keyof S, 'object' | 'boolean' | 'number' | 'string'>;
    /**
     * @description can be used to overwrite a key of the object if the key does not match the correct key from the EnumObjectSelectMapper
     * eg. object.type will probable be an enum. but there is no one enum for type, since type is used in multiple objects
     * @example enumOverwrite={new Map([['type', 'entity_type']])}
     */
    enumOverwrite?: Map<keyof S, keyof typeof EnumSelectList | keyof typeof EnumSelectListV2>;
    successMessageOverwrite?: React.ReactNode;
    headerOverwrite?: React.ReactNode;

    bodyClassname?: string;
    rowClassName?: string;
    headerClassName?: string;
    cardBodyClassName?: string;
    submitDisabled?: boolean;

    children?: React.ReactNode;
    append?: React.ReactNode;

    onChange?: (value: S) => void;
    onSubmit?: (value: S) => Promise<T> | Promise<void>;
    onSubmitSuccess?: (value: T) => void;
    onVoidSuccess?: (value: void) => void;
    onCancel?: (e: any) => void;
    formIdCallback?: (id: string) => void;
}

export const Form = <T extends object, S extends object>(props: FormProps<T, S>) => {
    const viewProps = useForm(props);
    const header = props.headerOverwrite ? props.headerOverwrite : props.type.replace(/_/g, ' ');
    return (
        <form
            id={props.id ?? ID}
            className={props.bodyClassname ? props.bodyClassname : 'card w-100 w-lg-50'}
            onSubmit={props.onSubmit ? viewProps.submit : undefined}>
            <div className={props.headerClassName ? props.headerClassName : 'card-header'}>
                <h4>{props.label ? props.label : props.object ? CommonMessage.FORMS.UPDATE_OBJECT(header) : CommonMessage.FORMS.CREATE_OBJECT(header)}</h4>
            </div>
            <div className={props.cardBodyClassName ? props.cardBodyClassName : 'card-body d-flex flex-wrap flex-column justify-content-between w-100'}>
                {props.children}

                <div className='w-100'>
                    {viewProps.show.map((key) => (
                        <FormField
                            dispatch={props.dispatch}
                            formId={viewProps.formId}
                            fieldKey={key}
                            label={props.keyOverwrite?.get(key)}
                            objectSelectKeyOverwrite={props.objectSelectKeyOverwrite?.get(key)}
                            fieldOverwrite={props.fieldOverwrite?.get(key)}
                            enumOverwrite={props.enumOverwrite?.get(key)}
                            expectedType={props.expectedType?.get(key)}
                            fieldProps={props.fieldProps?.get(key)}
                            required={props.required?.includes(key)}
                            hidden={props.hidden?.includes(key)}
                            className={viewProps.rowClass}
                        />
                    ))}
                </div>

                {props.append}
            </div>
            {props.onSubmit && (
                <div className='card-footer d-flex justify-content-between'>
                    {props.onCancel ? (
                        <button
                            type='button'
                            onClick={props.onCancel}
                            className='btn btn-secondary'>
                            <span>{CommonMessage.BUTTONS.CANCEL}</span>
                        </button>
                    ) : (
                        <span></span>
                    )}
                    <button
                        type='submit'
                        className='btn btn-success'
                        disabled={props.submitDisabled || viewProps.submitted}>
                        <span>{CommonMessage.BUTTONS.SUBMIT}</span>
                    </button>
                </div>
            )}
        </form>
    );
};

export default Form;
