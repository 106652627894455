import { ApiError } from '@frontend/api-utils';
import { EntityType, SliceStatus } from '@frontend/common';
import { ImpexEntityTypeEnumClient, ImpexEntityTypeFieldEnumClient } from '@frontend/impex/api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

interface ImpexState {
    impexEntityTypes: string[];
    impexEntityTypeFields: { [key: string]: string[] };
    status: SliceStatus;
    error: string | null;
}

const initialState: ImpexState = {
    impexEntityTypes: [],
    impexEntityTypeFields: {},
    status: SliceStatus.INIT,
    error: null
};

export const fetchImpexEntityTypes = createAsyncThunk<string[], void>('impex/fetchImpexEntityTypes', async (_, { rejectWithValue }) => {
    try {
        return await ImpexEntityTypeEnumClient.fetchEntityTypes();
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const fetchImpexEntityTypeFields = createAsyncThunk<string[], EntityType>(
    'impex/fetchImpexEntityTypeFields',
    async (entityType, { rejectWithValue }) => {
        try {
            return await ImpexEntityTypeFieldEnumClient.fetchEntityTypeFields(entityType);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

const impexSlice = createSlice({
    name: 'impexSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchImpexEntityTypes.pending, (state) => {
                state.status = SliceStatus.LOADING;
                state.error = null;
            })
            .addCase(fetchImpexEntityTypes.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                state.impexEntityTypes = action.payload;
            })
            .addCase(fetchImpexEntityTypes.rejected, (state, action) => {
                state.status = SliceStatus.ERROR;
                state.error = action.error.message ?? 'Failed to fetch entity types';
            });

        builder
            .addCase(fetchImpexEntityTypeFields.pending, (state) => {
                state.status = SliceStatus.LOADING;
                state.error = null;
            })
            .addCase(fetchImpexEntityTypeFields.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                const entityType = action.meta.arg;
                state.impexEntityTypeFields[entityType] = action.payload;
            })
            .addCase(fetchImpexEntityTypeFields.rejected, (state, action) => {
                state.status = SliceStatus.ERROR;
                state.error = action.error.message ?? 'Failed to fetch entity type fields';
            });
    }
});

export const impexStore = { impex: impexSlice.reducer };
