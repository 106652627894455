import { EntityType } from '@frontend/common';
import { ObjectBadge, ObjectBadgeProps } from '@frontend/rendering/badge';
import { userRequest } from '@frontend/user/events';
import { IUser } from '@frontend/user/types';
import { User } from '@frontend/user/utils';
import React from 'react';

export const UserBadge = (props: ObjectBadgeProps<IUser>) => {
    return (
        <ObjectBadge<IUser>
            id={props.id}
            type={EntityType.USER}
            mapDisplayName={(user: IUser) => new User(user).getDisplayName()}
            resolve={userRequest}
            navigateString={(object: IUser) => `/accounts/${object.account_id}/users/${object.id}`}
            {...props}
        />
    );
};
