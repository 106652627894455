import { Action, ThunkDispatch } from '@reduxjs/toolkit';

import { EntityType } from '@frontend/common';
import { ModuleClient } from '@frontend/module/api';
import { useResolver } from '@frontend/repository';
import React from 'react';

declare global {
    interface WindowEventMap {
        'useModule:request': CustomEvent<string>;
    }
}

const REQUEST_MODULE_EVENT = 'useModule:request';
const STORAGE_KEY = 'MODS_REQ';

export function moduleRequest(moduleId: string): string {
    window.dispatchEvent(new CustomEvent(REQUEST_MODULE_EVENT, { detail: moduleId }));
    return STORAGE_KEY + '_loading';
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function ModuleResolver(props: Props) {
    useResolver(STORAGE_KEY, REQUEST_MODULE_EVENT, EntityType.MODULE, (ids) => ModuleClient.resolveModules(ids), props.dispatch);
    return <></>;
}
