import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { CreateAttribute, Attribute, AttributeListResponse, AttributeQueryParams, UpdateAttribute } from '@frontend/attribute/types';

const endpoint = '/attribute-api/v1';

export class AttributeClient extends APIClient {
    public static async fetchAttributes(queryParams?: ApiQueryParams<DefaultQueryParams & AttributeQueryParams>): Promise<AttributeListResponse> {
        return await this.apiPaginated<AttributeListResponse, DefaultQueryParams & AttributeQueryParams>(`${endpoint}/attributes`, queryParams);
    }

    public static async resolveAttributes(ids: string[]): Promise<AttributeListResponse> {
        const response = await this.post(`${endpoint}/attributes-resolve`, { ids: ids });
        return await this.handleResponse<AttributeListResponse>(response);
    }

    public static async deleteAttributes(ids: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/attributes-delete`, { ids: ids });
        return await this.handleVoidResponse(response);
    }

    public static async postAttribute(accountId: string, attribute: CreateAttribute): Promise<Attribute> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/attributes`, attribute);
        return await this.handleResponse<Attribute>(response);
    }

    public static async fetchAttribute(accountId: string, attributeId: string): Promise<Attribute> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/attributes/${attributeId}`);
        return await this.handleResponse<Attribute>(response);
    }

    public static async patchAttribute(accountId: string, attributeId: string, attribute: UpdateAttribute): Promise<Attribute> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/attributes/${attributeId}`, attribute);
        return await this.handleResponse<Attribute>(response);
    }

    public static async deleteAttribute(accountId: string, attributeId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/attributes/${attributeId}`);
        return await this.handleVoidResponse(response);
    }

    public static async postIoTSeed(accountId: string, iotId: string, body: { account_id: string }): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots/${iotId}/seed`, body);
        return this.handleResponse(response);
    }
}
