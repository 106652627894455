import { EntityType } from '@frontend/common';
import { AttributeLinkingClient } from '@frontend/attribute/api';
import { useResolver } from '@frontend/repository';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

declare global {
    interface WindowEventMap {
        'useAttributeLinking:request': CustomEvent<string>;
    }
}

const REQUEST_ATTRIBUTE_LINKING_EVENT = 'useAttributeLinking:request';
const STORAGE_KEY = 'ATTRLNK_REQ';

export function attributeLinkingRequest(attributeLinkingId: string): string {
    window.dispatchEvent(new CustomEvent(REQUEST_ATTRIBUTE_LINKING_EVENT, { detail: attributeLinkingId }));
    return STORAGE_KEY + '_loading';
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function AttributeLinkingResolver(props: Props) {
    useResolver(STORAGE_KEY, REQUEST_ATTRIBUTE_LINKING_EVENT, EntityType.ATTRIBUTE_LINKING, (ids) => AttributeLinkingClient.resolveAttributeLinkings(ids), props.dispatch);
    return <></>;
}
