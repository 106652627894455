import { ObjectDetailMapper } from '@frontend/rendering/details';

export interface IotDataModalProps {
    handleClose: () => void;
    data: { [key: string]: unknown };
}
const IotDataModal = (props: IotDataModalProps) => {
    return (
        <>
            <div className='modal-header d-flex justify-content-between'>
                <h4>Data</h4>
                <button
                    type='button'
                    className='btn btn-secondary m-0'
                    onClick={props.handleClose}>
                    X
                </button>
            </div>
            <div className='modal-body'>
                <ObjectDetailMapper
                    object={props.data}
                    hidden={['id', 'delete_timestamp']}
                />
            </div>
        </>
    );
};

export default IotDataModal;
