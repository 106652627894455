import { CheckboxInput, CreatableSelectV2, NumberInput, SingleOption, TextInput } from '@frontend/basic-forms';
import React, { useMemo } from 'react';

import { ObjectSelectMapper } from '../object-select-mapper';
import { DataTypeInputProps } from './data-type-input.component';

/**
 * @param props
 * @returns
 */
const useDataTypeInput = (props: DataTypeInputProps) => {
    const input = useMemo(() => {
        if (props.options) {
            return (
                <CreatableSelectV2
                    {...props}
                    options={props.options.map((op) => ({ label: op, value: op }) as SingleOption<any>)}
                    onSelect={(v) => (v ? props.onChange((v as any).value) : undefined)}
                    placeholder={'Type something and press Enter or Tab'}
                    value={props.value as string | null | undefined}
                    label={props.label}
                    key={`${props.type}-${props.label}`}
                    id={`${props.type}-${props.label}`}
                />
            );
        } else if (props.type.endsWith('_id')) {
            return (
                <ObjectSelectMapper<any>
                    id={props.type}
                    objectKey={props.type}
                    {...props}
                    value={props.value as string | null | undefined}
                />
            );
        } else
            switch (props.type) {
                case 'string':
                case 'str':
                case 'color': // TODO: add color picker
                    return (
                        <TextInput
                            {...props}
                            value={props.value as string | undefined}
                        />
                    );
                case 'number':
                case 'int':
                    return (
                        <NumberInput
                            {...props}
                            value={props.value as number | undefined}
                        />
                    );
                case 'bool':
                case 'boolean':
                    return (
                        <CheckboxInput
                            {...props}
                            value={props.value as boolean | undefined}
                        />
                    );
                default:
                    return <></>;
            }
    }, [props]);

    return { input };
};

export default useDataTypeInput;
