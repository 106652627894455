import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

const UserInterfaceCopy = lazy(() => import('../user-interface/user-interface-copy/user-interface-copy.component'));
const UserInterfaceCreate = lazy(() => import('../user-interface/user-interface-create/user-interface-create.component'));
const UserInterfaceUpdate = lazy(() => import('../user-interface/user-interface-update/user-interface-update.component'));
const UserInterfaceDetail = lazy(() => import('../user-interface/user-interface-detail/user-interface-detail.component'));
const UserInterfaceButtonCreate = lazy(() => import('../user-interface/user-interface-button-create/user-interface-button-create.component'));
const UserInterfaceOverview = lazy(() => import('../user-interface/user-interface-overview'));
const UserInterfaceButtonUpdate = lazy(() => import('../user-interface/user-interface-button-update/user-interface-button-update.component'));
export function UserInterfaceRoutes() {
    return (
        <Route path='user-interfaces'>
            <Route element={<Navigate to='/admin/user-interfaces' />} />
            <Route
                index
                element={
                    <AsyncComponent>
                        <UserInterfaceOverview />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <UserInterfaceCreate />
                    </AsyncComponent>
                }
            />
            <Route path=':userInterfaceId'>
                <Route
                    index
                    path='detail'
                    element={
                        <AsyncComponent>
                            <UserInterfaceDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <UserInterfaceUpdate />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='copy'
                    element={
                        <AsyncComponent>
                            <UserInterfaceCopy />
                        </AsyncComponent>
                    }
                />
                <Route path='button'>
                    <Route
                        path='create'
                        element={
                            <AsyncComponent>
                                <UserInterfaceButtonCreate />
                            </AsyncComponent>
                        }
                    />
                </Route>
                <Route path='buttons'>
                    <Route path=':buttonId'>
                        <Route
                            path='update'
                            element={
                                <AsyncComponent>
                                    <UserInterfaceButtonUpdate />
                                </AsyncComponent>
                            }
                        />
                    </Route>
                </Route>
            </Route>
        </Route>
    );
}
