import { PaginatedResponse } from '@frontend/api-utils';

export interface WorkflowField {
    id: string;
    key: WorkflowFieldKey;
    type: WorkflowFieldType;
    value: string | boolean | number | null;
    account_id: string;
    workflow_id: string;
    step_id: string;
    required?: boolean;
}

export type WorkflowFieldListResponse = PaginatedResponse<WorkflowField>;

export interface CreateWorkflowField {
    key: WorkflowFieldKey;
    type: WorkflowFieldType;
    value: string | boolean | number | null;
}

export interface UpdateWorkflowField {
    value: string | boolean | number | null;
}

export enum WorkflowFieldKey {
    CONTACT = 'contact_id',
    SENDER_ID = 'sender_id',
    RECEIVER_ID = 'receiver_id',
    NOTIFICATION_TEMPLATE = 'template_id',
    DELAY_MINUTES = 'delay_minutes',
    TIME_OUT_HOURS = 'time_out_hours',
    REPEAT_HOURS = 'repeat_hours',
    USER_INTERFACE_ID = 'user_interface_id',
    PARAMETER_NAME = 'parameter_name',
    UPDATE_OPERATOR = 'update_operator',
    UPDATE_VALUE = 'update_value',
    VERIFY_OPERATOR = 'verify_operator',
    VERIFY_VALUE = 'verify_value',
    TRUE_NEXT_STATE = 'true_next_state',
    FALSE_NEXT_STATE = 'false_next_state',
    IOT = 'iot_id',
    TRIGGER = 'trigger'
}

export enum WorkflowFieldType {
    ANY = 'any',
    INT = 'int',
    FLOAT = 'float',
    BOOL = 'bool',
    STRING = 'str',
    CONTACT_ID = 'contact_id',
    TEMPLATE_ID = 'template_id',
    WORKFLOW_ID = 'workflow_id',
    USER_INTERFACE_ID = 'user_interface_id',
    PRODUCT_STATE = 'product_state',
    SPOT_ID = 'spot_id',
    IOT_ID = 'iot_id',
    IOT_TRIGGER = 'iot_trigger',
    
    VERIFY_OPERATOR = 'verify_operator',
    UPDATE_OPERATOR = 'update_operator',
    ENTITY_PARAMETER = 'entity_parameter'
}
