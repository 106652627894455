import { PaginatedResponse } from '@frontend/api-utils';

export interface Workflow {
    id: string;
    name: string;
    account_id: string;
    creation_timestamp: string;
    active: boolean;
    revision: number;
    update_timestamp: string;
    type: WorkflowType;
    tags: string[];
}

export type WorkflowListResponse = PaginatedResponse<Workflow>;
export interface CreateWorkflow {
    name?: string;
    active?: boolean;
    revision?: number;
    type?: WorkflowType;
    tags?: string[];
}

export interface UpdateWorkflow {
    active?: boolean;
    name?: string | null;
    revision?: number;
    type?: WorkflowType | null;
    tags?: string[];
}

export interface CopyWorkflow {
    account_id?: string;
}

export enum WorkflowQueryParams {
    TYPE = 'type',
    ACCOUNT = 'account_id',
    TAGS = 'tags'
}

export enum SearchWorkflowQueryParams {
    PARTIAL_TEXT = 'partial_text'
}

export enum WorkflowType {
    EQUIPMENT = 'equipment',
    IOT = 'iot',
    PACKAGE = 'package',
    TRANSACTION = 'transaction'
}
