import { useAppSelector } from '@frontend/common';
import { ListInfo, changePage, changePageSize, changeSorting, listStore } from '@frontend/repository';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { ColumnDef, Row, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import React, { JSX, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { THeader } from '../headers/THeader';
import { TRow } from '../rows/TRow';
import PaginationButtonNavigation from './pagination/pagination-button-navigation';

export interface TableProps {
    dispatch: ThunkDispatch<any, any, Action>;
    listId: string;
    headerClassName?: string;
    headerItemClassName?: string;
    rowClassName?: string;
    customRowClassName?: (row: Row<object>) => string | undefined;

    selectEnabled?: boolean;
    expandEnabled?: boolean;
    selectedItemsCallback?: (objects: any[]) => void;
    columns: ColumnDef<any>[];
    data: any[];
    autoResetPage?: boolean;
    next?: string | null | undefined;
    count?: number | undefined;
    onSelectElement?: (object: any) => void;
    RowElement: (props: any) => JSX.Element;
    renderSubComponent?: (props: { row: any }) => React.ReactElement;
    pagination?: {
        page?: (value: number) => void;
        initialPageSize?: number;
        hidePagination?: boolean;

        allowPageSizeSelect?: boolean;
        allowPageJumpInput?: { bottom?: boolean; top?: boolean };
        allowPageButtonNavigation?: {
            bottom?: boolean;
            bottomProps?: React.ComponentProps<typeof PaginationButtonNavigation>;
            top?: boolean;
            topProps?: React.ComponentProps<typeof PaginationButtonNavigation>;
        };
    };
    id?: string;
    hideColumns?: string[];
    sortingColumns?: string[];
    showCount?: boolean;
    includeDeletedCallback?: (value: boolean) => void;
}

const headerClass = 'sticky-top bg-light bg-gradient shadow-sm';

export function Table(props: TableProps) {
    const store = useAppSelector(useSelector, listStore);
    const list: ListInfo | undefined = store[props.listId];
    const possiblePageSizes = [10, 25, 50, 100, 150];
    const pageCount = list && list.count ? Math.ceil(list.count / list.pageSize) : 0;

    useEffect(() => {
        if (props.pagination?.initialPageSize !== undefined && !possiblePageSizes.includes(props.pagination.initialPageSize)) {
            possiblePageSizes.push(props.pagination.initialPageSize);
            possiblePageSizes.sort((a, b) => a - b);
            props.dispatch(changePageSize({ listId: props.listId, pageSize: props.pagination.initialPageSize }));
        }
    }, [props.pagination?.initialPageSize]);

    const tableInstance = useReactTable({
        columns: props.columns,
        data: props.data,
        autoResetPageIndex: props.autoResetPage ? props.autoResetPage : false,
        manualPagination: true,
        pageCount: pageCount,
        manualSorting: true,
        autoResetExpanded: false,
        getSubRows: (row: any) => row?.children,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel()
    });

    useEffect(() => {
        if (props.selectEnabled && props.selectedItemsCallback) {
            props.selectedItemsCallback(tableInstance.getSelectedRowModel().flatRows.map((r) => r.original));
        }
    }, [tableInstance.getSelectedRowModel().flatRows]);

    useEffect(() => {
        tableInstance.getState().sorting;
        props.dispatch(changeSorting({ listId: props.listId, sorting: tableInstance.getState().sorting }));
    }, [tableInstance.getState().sorting]);

    useEffect(() => {
        tableInstance.getAllColumns().forEach((col) => {
            col.toggleVisibility(!props.hideColumns?.includes(col.id));
        });
    }, [props.hideColumns]);

    const startRow = list ? list.pageSize * list.pageIndex : 0;
    const endRow = list ? startRow + list.pageSize : 1000;

    const hidePagination = props.pagination?.hidePagination ?? (list ? props.data.length < list.pageSize : true);

    return (
        <div id={props.id}>
            {!hidePagination ? (
                <div className='d-flex justify-content-between p-2'>
                    <div className='d-flex justify-content-start'>
                        {(!props.pagination || props.pagination.allowPageSizeSelect !== false) && (
                            <div className='d-flex flex-row dataTable-dropdown align-items-center'>
                                <select
                                    className='form-control me-2'
                                    value={list.pageSize}
                                    onChange={(e) => {
                                        props.dispatch(changePageSize({ listId: props.listId, pageSize: Number(e.target.value) }));
                                    }}>
                                    {possiblePageSizes.map((pageSize) => (
                                        <option
                                            key={pageSize}
                                            value={pageSize}>
                                            {pageSize}
                                        </option>
                                    ))}
                                </select>
                                <span className='text-nowrap text-secondary'>
                                    <FormattedMessage
                                        id='table.entriesPerPage'
                                        description='The entries per page in the default table message.'
                                        defaultMessage='entries per page'
                                    />{' '}
                                    <strong>
                                        <small>
                                            <FormattedMessage
                                                id='table.totalEntries'
                                                description='The entries per page in the default table message.'
                                                defaultMessage='(total entries: {total})'
                                                values={{ total: props.data.length }}
                                            />
                                        </small>
                                    </strong>
                                </span>
                            </div>
                        )}
                    </div>
                    {(!props.pagination || !props.pagination.allowPageJumpInput || props.pagination.allowPageJumpInput.top !== false) && (
                        <span className='d-flex flex-row align-items-center text-secondary'>
                            <FormattedMessage
                                id='table.currentPage'
                                description='The current page in the default table message.'
                                defaultMessage='Page'
                            />
                            <input
                                className='form-control mx-1'
                                type='number'
                                defaultValue={list.pageIndex + 1}
                                value={list.pageIndex + 1}
                                onChange={(e) => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                    props.dispatch(changePage({ listId: props.listId, page: page }));
                                }}
                                style={{ width: '75px' }}
                            />
                            <strong className='text-secondary'>
                                <FormattedMessage
                                    id='table.currentPage.ofPages'
                                    description='The current page of total amount of pages in the default table message.'
                                    defaultMessage='of'
                                />
                                {' ' + pageCount}
                            </strong>
                        </span>
                    )}
                    {(!props.pagination || !props.pagination.allowPageButtonNavigation || props.pagination.allowPageButtonNavigation.top !== false) &&
                        pageCount > 1 && (
                            <PaginationButtonNavigation
                                dispatch={props.dispatch}
                                listStoreId={props.listId}
                                id={props.id + '-top'}
                                {...props.pagination?.allowPageButtonNavigation?.topProps}
                            />
                        )}
                </div>
            ) : (
                <>
                    <div className='d-flex flex-row w-100'>
                        {(!props.pagination || props.pagination.allowPageSizeSelect !== false) && props.showCount && props.data.length > 0 && (
                            <strong className='d-flex w-100 justify-content-start'>
                                <small>
                                    <FormattedMessage
                                        id='table.totalEntries'
                                        description='The entries per page in the default table message.'
                                        defaultMessage='(total entries: {total})'
                                        values={{ total: props.data.length }}
                                    />
                                </small>
                            </strong>
                        )}
                    </div>
                </>
            )}
            <table className='table align-items-center mb-0 overflow-scroll'>
                <thead className={props.headerClassName || headerClass}>
                    {tableInstance.getHeaderGroups().map((headerGroup, index) => (
                        <THeader
                            className={props.headerItemClassName}
                            key={index + '_' + headerGroup.id}
                            headerGroup={headerGroup}
                            sortingColumns={props.sortingColumns}
                        />
                    ))}
                </thead>

                <tbody>
                    {props.data.length === 0 && (
                        <tr>
                            <td>
                                <p className='text-secondary'>No data to display</p>
                            </td>
                        </tr>
                    )}
                    {tableInstance.getRowModel().rows.map((row) => {
                        if (startRow <= row.index && row.index < endRow && row.original) {
                            return (
                                <TRow
                                    className={props.customRowClassName ? props.customRowClassName(row) : props.rowClassName}
                                    key={row.id}
                                    row={row}
                                    selectEnabled={props.selectEnabled}
                                    onClick={props.onSelectElement}
                                    RowElement={props.RowElement}
                                />
                            );
                        } else return <></>;
                    })}
                </tbody>
            </table>
            {!props.pagination?.hidePagination && (
                <>
                    {pageCount > 1 && <hr className='horizontal dark my-3' />}

                    {pageCount > 1 && (
                        <div className='d-flex flex-row justify-content-between align-items-center m-2'>
                            {(!props.pagination || !props.pagination.allowPageJumpInput || props.pagination.allowPageJumpInput.bottom !== false) && (
                                <span className='d-flex flex-row align-items-center text-secondary'>
                                    <FormattedMessage
                                        id='table.currentPage'
                                        description='The current page in the default table message.'
                                        defaultMessage='Page'
                                    />
                                    <input
                                        className='form-control mx-1'
                                        type='number'
                                        defaultValue={list.pageIndex + 1}
                                        onChange={(e) => {
                                            const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                            props.dispatch(changePage({ listId: props.listId, page: page }));
                                        }}
                                        style={{ width: '75px' }}
                                    />
                                    <strong className='text-secondary'>
                                        <FormattedMessage
                                            id='table.currentPage.ofPages'
                                            description='The current page of total amount of pages in the default table message.'
                                            defaultMessage='of'
                                        />
                                        {' ' + pageCount}
                                    </strong>
                                </span>
                            )}

                            {(!props.pagination ||
                                !props.pagination.allowPageButtonNavigation ||
                                props.pagination.allowPageButtonNavigation.bottom !== false) && (
                                <PaginationButtonNavigation
                                    dispatch={props.dispatch}
                                    listStoreId={props.listId}
                                    id={props.id + '-bottom'}
                                    {...props.pagination?.allowPageButtonNavigation?.bottomProps}
                                />
                            )}
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
