import { DocumentClient } from '@frontend/document/api';
import { EntityType } from '@frontend/common';
import { useResolver } from '@frontend/repository';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

declare global {
    interface WindowEventMap {
        'useDocument:request': CustomEvent<string>;
    }
}

const REQUEST_DOCUMENT_EVENT = 'useDocument:request';
const STORAGE_KEY = 'DOC_REQ';

export function documentRequest(documentId: string): string {
    window.dispatchEvent(new CustomEvent(REQUEST_DOCUMENT_EVENT, { detail: documentId }));
    return STORAGE_KEY + '_loading';
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function DocumentResolver(props: Props) {
    useResolver(STORAGE_KEY, REQUEST_DOCUMENT_EVENT, EntityType.DOCUMENT, (ids) => DocumentClient.resolveDocuments(ids), props.dispatch);
    return <></>;
}
