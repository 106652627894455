import { EntityType } from '@frontend/common';
import { ObjectBadge, ObjectBadgeProps } from '@frontend/rendering/badge';
import { transactionRequest } from '@frontend/transaction/events';
import { Transaction } from '@frontend/transaction/types';
import React from 'react';

export const TransactionBadge = (props: ObjectBadgeProps<Transaction>) => {
    return (
        <ObjectBadge<Transaction>
            id={props.id}
            type={EntityType.TRANSACTION}
            mapDisplayName={(transaction: Transaction) => transaction.tracking_number ?? transaction.id.split('-')[0]}
            resolve={transactionRequest}
            key={props.id}
            navigateString={(object: Transaction) => `/accounts/${object.account_id}/transactions/${object.id}`}
            {...props}
        />
    );
};
