import { Dimension } from '@frontend/common';
import React from 'react';

import { NumberInput } from '../number-input/number-input.component';
import useDimensionInput from './dimension-input.controller';

const ID = 'dimension-input';
export interface DimensionInputProps {
    id?: string;
    label?: string;
    className?: string;
    required?: boolean;
    submitted: boolean;
    onChange: (newValue: Dimension) => void;
    value?: Dimension;
}

export const DimensionInput = (props: DimensionInputProps) => {
    const viewProps = useDimensionInput(props);

    return (
        <div
            id={ID}
            className='d-flex flex-row justify-content-between w-100'>
            <div className='w-30 m-1'>
                <NumberInput
                    id='height'
                    label='Height'
                    required
                    min={0}
                    submitted={props.submitted}
                    value={viewProps.dimension.height}
                    onChange={(value) => {
                        viewProps.changeDimension({ ...viewProps.dimension, height: value });
                    }}
                />
            </div>
            <div className='w-30 m-1'>
                <NumberInput
                    id='width'
                    label='Width'
                    required
                    min={0}
                    submitted={props.submitted}
                    value={viewProps.dimension.width}
                    onChange={(value) => {
                        viewProps.changeDimension({ ...viewProps.dimension, width: value });
                    }}
                />
            </div>
            <div className='w-30 m-1'>
                <NumberInput
                    id='depth'
                    label='Depth'
                    required
                    min={0}
                    submitted={props.submitted}
                    value={viewProps.dimension.depth}
                    onChange={(value) => {
                        viewProps.changeDimension({ ...viewProps.dimension, depth: value });
                    }}
                />
            </div>
        </div>
    );
};

export default DimensionInput;
