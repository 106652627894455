import { Logger } from '@frontend/Logger';
import { Group } from '@frontend/group/types';
import { EventListener } from '@frontend/pub-sub';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

enum MessageTypes {
    UPDATED = 'group_updated',
    DELETED = 'group_deleted',
    CREATED = 'group_created'
}

export class GroupEventListener extends EventListener<Group> {
    
    private static instance: GroupEventListener | null = null;
    private constructor(dispatch: ThunkDispatch<any, any, Action>) {
        super('group', dispatch);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>): GroupEventListener {
        if (this.instance == null) {
            this.instance = new GroupEventListener(dispatch);
        }
        return this.instance;
    }

    getInstance(dispatch: ThunkDispatch<any, any, Action>): EventListener<Group> {
        return GroupEventListener.getInstance(dispatch);
    }

    protected onUpdate(payload: { message: MessageTypes; data: Group; changes?: any }) {
        Logger.log('Updating group in store', { group: payload.data.id }, payload.data);
    }

    protected onDelete(payload: { message: MessageTypes; data: Group }) {
        Logger.log('Removing group from store', { group: payload.data.id }, payload.data);
    }

    protected onCreate(payload: { message: MessageTypes; data: Group; changes?: any }) {
        Logger.log('Adding group to store', { group: payload.data.id }, payload.data);
    }
}