import { useEffect, useState } from 'react';

import { SelectOption, SingleOption, explodeOptions } from '../select-types';
import { AsyncSelectInputV2Props } from './async-select-input-v2.component';

interface ViewProps<T extends object, D extends string | number> {
    value?: string;
    onSearch: (value: string) => void;
    searchResultOptions: SelectOption<T>[];
    isLoading: boolean;

    onSelect: (option: SingleOption<T> | null) => void;
    selected: SingleOption<T> | null;
    changeSelected: React.Dispatch<React.SetStateAction<SingleOption<T> | null>>;
}

const useAsyncSelectInputV2 = <T extends object, D extends string | number>(props: AsyncSelectInputV2Props<T, D>): ViewProps<T, D> => {
    const [initValue, changeInitValue] = useState<string | undefined | null>(props.value);
    const [value, changeValue] = useState<string | undefined>(undefined);
    const [searchResultOptions, changeSearchResultOptions] = useState<SelectOption<T>[]>([]);
    const [selected, changeSelected] = useState<SingleOption<T> | null>(null);
    const [isLoading, changeIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (props.searchValueOverwrite !== undefined) onSearch(props.searchValueOverwrite);
    }, [props.searchValueOverwrite]);

    useEffect(() => {
        if (props.value) {
            if (initValue == null) changeInitValue(props.value);
            changeIsLoading(true);
            if (props.singleSearch) {
                props.singleSearch(props.value).then((res) => {
                    changeSearchResultOptions([res]);
                    onSelect(res);
                    changeIsLoading(false);
                });
            } else if (props.options) {
                const options = explodeOptions(props.options);
                const found = options.find((op) => op.value === props.value);
                if (found) {
                    onSelect(found);
                }
                changeIsLoading(false);
            }
        } else {
            changeValue(undefined);
            changeSelected(null);
        }
    }, [props.value]);

    useEffect(() => {
        changeIsLoading(true);
        if (props.options === null) {
            changeSearchResultOptions([]);
        } else {
            if (initValue && value === undefined && props.options.length > 0) {
                const options = explodeOptions(props.options);
                const found = options.find((op) => op.value === props.value);
                if (found) {
                    onSelect(found);
                }
                changeInitValue(undefined);
            }
            changeSearchResultOptions(props.options);
        }
        changeIsLoading(false);
    }, [props.options]);

    const onSelect = (option: SingleOption<T> | null) => {
        changeSelected(option);
        changeValue(option?.label || undefined);
        props.onSelect && props.onSelect(option);
    };

    const onSearch = (value: string) => {
        changeIsLoading(true);
        changeSelected(null);
        changeValue(value);
        props.onSearch && props.onSearch(value);
    };

    return {
        value: value,
        onSearch,
        searchResultOptions,
        isLoading: isLoading ?? props.isLoading,

        onSelect,
        selected,
        changeSelected
    };
};

export default useAsyncSelectInputV2;
