import { AuthenticationManager } from '@frontend/authentication-v2';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface ViewProps {
    response: { type: string; message: string } | undefined;
    onSubmit: (e: any) => void;
    submitted: boolean;
}

const useBasicSignUpConfirmation = (): ViewProps => {
    const { pathname } = useLocation();
    const token = pathname.split('/')[2];
    const [response, changeResponse] = useState<{ type: string; message: string } | undefined>(undefined);
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const onSubmit = async (e: any) => {
        e.preventDefault();
        changeSubmitted(true);
        const response = await AuthenticationManager.getInstance().confirmSignUp(token);
        changeResponse(response);
    };

    useEffect(() => {
        if (!token) {
            changeResponse({ type: 'error', message: 'No token provided' });
            return;
        }
    }, []);

    useEffect(() => {
        if (response && response.type === 'success') {
            setTimeout(() => {
                window.location.href = '/';
            }, 3000);
        }
    }, [response]);

    return {
        response,
        onSubmit,
        submitted
    };
};

export default useBasicSignUpConfirmation;
