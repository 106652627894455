import React from 'react';
import { FormattedMessage } from 'react-intl';

export const Navigation = {
    USER_MANAGER: (
        <FormattedMessage
            id='Navigation.group.user-manager'
            defaultMessage='User manager'
            description='User manager navigation group title'
        />
    ),
    ORGANIZATION: (
        <FormattedMessage
            id='Navigation.group.organization'
            defaultMessage='Organization'
            description='Organization navigation group title'
        />
    ),
    FINANCIALS: (
        <FormattedMessage
            id='Navigation.group.financials'
            defaultMessage='Financials'
            description='Financials navigation group title'
        />
    ),
    QUESTION_AND_ANSWER: (
        <FormattedMessage
            id='Navigation.group.question-and-answer'
            defaultMessage='Question and Answer'
            description='Question and Answer navigation group title'
        />
    ),
    CONTENT_AND_MEDIA: (
        <FormattedMessage
            id='Navigation.content-and-media'
            defaultMessage='Content and Media'
            description='Content and Media navigation title'
        />
    ),
    PLAYLISTS: (
        <FormattedMessage
            id='Navigation.playlist'
            defaultMessage='Playlists'
            description='Playlists navigation title'
        />
    ),
    APPLICATIONS: (
        <FormattedMessage
            id='Navigation.applications'
            defaultMessage='Applications'
            description='Applications navigation title'
        />
    ),
    INVENTORY: (
        <FormattedMessage
            id='Navigation.inventory'
            defaultMessage='Inventory'
            description='Inventory navigation title'
        />
    ),
    EQUIPMENT: (
        <FormattedMessage
            id='Navigation.equipment'
            defaultMessage='Equipment'
            description='Equipment navigation title'
        />
    ),
    QUOTAS_AND_CONSTRAINTS: (
        <FormattedMessage
            id='Navigation.quotas-and-constraints'
            defaultMessage='Quotas and Constraints'
            description='Quotas and Constraints navigation title'
        />
    ),
    TRANSACTIONS: (
        <FormattedMessage
            id='Navigation.transactions'
            defaultMessage='Transactions'
            description='Transactions navigation title'
        />
    ),
    PRODUCT: (
        <FormattedMessage
            id='Navigation.product'
            defaultMessage='Product'
            description='Product navigation title'
        />
    )
};
