import { AccountClient } from '@frontend/account/api';
import { Account } from '@frontend/account/types';
import { AuthenticationManager } from '@frontend/authentication-v2';
import { BrandingClient } from '@frontend/branding/api';
import { Branding } from '@frontend/branding/types';
import { getCookie, setCookie } from '@frontend/common';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const TELLOPORT_SELECTED_ACCOUNT_COOKIE = 'tp_prev_sel_acc';
const EVENT_TYPE = `cookie:${TELLOPORT_SELECTED_ACCOUNT_COOKIE}`;

interface AccountStore {
    availableAccountsInfo: Account[];
    availableAccounts: string[];
    lastUpdate: number;
    cookieAccount: string | null;
    tokenAccount: string | null;
    currentAccount: string | null;
    branding: Branding | null;
}

const AccountStoreContext = createContext<{ store: AccountStore; updateCurrentAccount: (account: string | null) => void } | null>(null);

export const AccountStoreProvider = (props: { children: React.ReactNode }) => {
    const [store, changeStore] = useState<AccountStore>({
        availableAccountsInfo: [],
        availableAccounts: [],
        lastUpdate: 0,
        cookieAccount: getCookie(TELLOPORT_SELECTED_ACCOUNT_COOKIE) ?? null,
        tokenAccount: null,
        currentAccount: null,
        branding: null
    });
    const storeRef = useRef(store);
    const navigate = useNavigate();
    const location = useLocation();

    const listener = () => {
        changeStore((prev) => ({ ...prev, cookieAccount: getCookie(TELLOPORT_SELECTED_ACCOUNT_COOKIE) ?? null }));
    };

    useEffect(() => {
        AuthenticationManager.getInstance()
            .waitForToken()
            .then((res) => {
                changeStore((prev) => ({ ...prev, tokenAccount: res.account_id }));
            });
        AccountClient.fetchAccounts()
            .then((res) => {
                changeStore((prev) => ({ ...prev, availableAccountsInfo: res.results, availableAccounts: res.results.map((account) => account.id) }));
            })
            .catch((err) => {
                if (window.location.host.includes('terminal') || window.location.host.includes('localhost:8')) {
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000 * 10);
                } else navigate('/unauthorized');
            });

        window.addEventListener(EVENT_TYPE, listener);
        return () => window.removeEventListener(EVENT_TYPE, listener);
    }, []);

    const updateCurrentAccount = (account: string | null) => {
        if (account) {
            setCookie(TELLOPORT_SELECTED_ACCOUNT_COOKIE, account);
            window.dispatchEvent(new CustomEvent(EVENT_TYPE, { detail: {} }));
        }
    };

    useEffect(() => {
        if (store.tokenAccount && store.availableAccounts && store.availableAccounts.length > 0) {
            if (store.cookieAccount && store.availableAccounts.includes(store.cookieAccount)) {
                changeStore((prev) => ({ ...prev, currentAccount: store.cookieAccount }));
            } else {
                updateCurrentAccount(store.tokenAccount);
            }
        }
    }, [store.availableAccounts, store.cookieAccount, store.tokenAccount]);

    useEffect(() => {
        const splitPath = location.pathname.split('/');
        const found = splitPath.find((path) => path.includes('accounts'));
        if (found) {
            const accountId = splitPath[splitPath.indexOf(found) + 1];
            if (accountId) {
                updateCurrentAccount(accountId);
            }
        }
        if (store.currentAccount) {
            window.dispatchEvent(new CustomEvent(EVENT_TYPE, { detail: {} }));
        }
    }, [location]);

    useEffect(() => {
        if (store.currentAccount && store.availableAccountsInfo.length > 0) {
            const foundAccount = store.availableAccountsInfo.find((acc) => acc.id === store.currentAccount);
            if (!foundAccount || !foundAccount.branding_id) {
                changeStore((prev) => ({ ...prev, branding: null }));
            } else if (foundAccount.branding_id) {
                BrandingClient.fetchBrandingByAccount(store.currentAccount).then((res) => {
                    changeStore((prev) => ({ ...prev, branding: res }));
                });
            }
        }
    }, [store.currentAccount]);

    useEffect(() => {
        storeRef.current = store;
    }, [store]);

    return <AccountStoreContext.Provider value={{ store, updateCurrentAccount }}>{props.children}</AccountStoreContext.Provider>;
};

export const useAccountRepository = () => {
    const context = useContext(AccountStoreContext);
    if (!context) {
        throw new Error('useAccountRepository must be used within an AccountStoreProvider');
    }
    return context;
};
