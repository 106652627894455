import { IUser } from '@frontend/user/types';

export class User implements IUser {
    first_name: string | null;
    last_name: string | null;
    employee_number: string | null;
    unique_identifier: string;
    source: string | null;
    account_id: string;
    id: string;
    creation_timestamp: string;
    update_timestamp: string;

    constructor(user: IUser) {
        this.first_name = user.first_name;
        this.last_name = user.last_name;
        this.employee_number = user.employee_number;
        this.unique_identifier = user.unique_identifier;
        this.source = user.source;
        this.account_id = user.account_id;
        this.id = user.id;
        this.creation_timestamp = user.creation_timestamp;
        this.update_timestamp = user.update_timestamp;
    }

    getName(): string {
        return `${this.first_name ? this.first_name : ''} ${this.last_name ? this.last_name : ''}`
    }

    getDisplayName(): string {
        if (!this.first_name && !this.last_name) return this.unique_identifier;
        return `${this.first_name ? this.first_name : ''} ${this.last_name ? this.last_name : ''}`
    }
}