import { DefaultQueryParams, PaginatedResponse } from '@frontend/api-utils';
import { EntityType } from '@frontend/common';

export interface Change {
    id: string;
    account_id: string;
    creation_timestamp: string;
    iot_identifier: string | null;
    type: ChangeType;
    status: ChangeStatus;
    entity_type: EntityType;
    entity_id: string;
    data: { [key: string]: unknown };
    update_timestamp: string;
    change_timestamp: string;
}

export interface CreateChange {
    type?: ChangeType;
    data?: { [key: string]: unknown };
    iot_id?: string | null;
    service_name?: string;
    entity_type?: EntityType;
    entity_id?: string;
}

export enum ChangeType {
    SEED = 'seed',
    CREATE = 'create',
    EVENT = 'event',
    UPDATE = 'update',
    DELETE = 'delete',
    REVERT = 'revert'
}

export enum ChangeStatus {
    PENDING = 'pending',
    FAILED = 'failed',
    SUCCEEDED = 'succeeded'
}

enum QueryParams {
    ENTITY_TYPE = 'entity_type',
    ENTITY_ID = 'entity_id',
    IOT_IDENTIFIER = 'iot_identifier',
    TYPE = 'type',
    STATUS = 'status'
}

export type ChangeQueryParams = QueryParams & DefaultQueryParams;

export type ChangeListResponse = PaginatedResponse<Change>;
