/* eslint-disable react/prop-types */
// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../../react-table-config.d.ts" />
import { useAppSelector } from '@frontend/common';
import { changePage, changePageSize, changeSorting, listStore } from '@frontend/repository';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Column, Row, useExpanded, usePagination, useRowSelect, useSortBy, useTable } from 'react-table';

import { THeader } from '../headers/THeader';
import { TRow } from '../rows/TRow';
import PaginationButtonNavigation from './pagination/pagination-button-navigation';

export interface TableProps {
    dispatch: ThunkDispatch<any, any, Action>;
    listStoreId: string;
    headerClassName?: string;
    headerItemClassName?: string;
    rowClassName?: string;
    customRowClassName?: (row: Row<object>) => string | undefined;

    selectEnabled?: boolean;
    expandEnabled?: boolean;
    selectedItemsCallback?: (objects: any[]) => void;
    columns: Column<any>[];
    data: any[];
    autoResetPage?: boolean;
    next?: string | null | undefined;
    count?: number | undefined;
    pageCount?: number | undefined;
    onSelectElement?: (object: any) => void;
    RowElement: (props: any) => JSX.Element;
    renderSubComponent?: (props: { row: any }) => React.ReactElement;
    pagination?: {
        page?: (value: number) => void;
        initialPageSize?: number;
        hidePagination?: boolean;

        allowPageSizeSelect?: boolean;
        allowPageJumpInput?: { bottom?: boolean; top?: boolean };
        allowPageButtonNavigation?: {
            bottom?: boolean;
            bottomProps?: React.ComponentProps<typeof PaginationButtonNavigation>;
            top?: boolean;
            topProps?: React.ComponentProps<typeof PaginationButtonNavigation>;
        };
    };
    id?: string;
    hideColumns?: string[];
    sortingColumns?: string[];
    showCount?: boolean;
}

const headerClass = 'sticky-top bg-light bg-gradient shadow-sm';

export function Table(props: TableProps) {
    const store = useAppSelector(useSelector, listStore);
    const possiblePageSizes = [10, 25, 50, 100, 150];

    useEffect(() => {
        if (props.pagination?.initialPageSize !== undefined && !possiblePageSizes.includes(props.pagination.initialPageSize)) {
            possiblePageSizes.push(props.pagination.initialPageSize);
            possiblePageSizes.sort((a, b) => a - b);
            props.dispatch(changePageSize({ listId: props.listStoreId, pageSize: props.pagination.initialPageSize }));
        }
    }, [props.pagination?.initialPageSize]);

    const tableInstance = useTable(
        {
            columns: props.columns,
            data: props.data,
            autoResetPage: props.autoResetPage ? props.autoResetPage : false,
            manualPagination: props.pageCount ? true : false,
            ...(props.pageCount && { pageCount: props.pageCount }),
            manualSortBy: true,
            autoResetExpanded: false
        },
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        pageOptions,
        pageCount,
        gotoPage,
        setPageSize,
        selectedFlatRows,
        state: { pageIndex, pageSize, sortBy }
    } = tableInstance;

    useEffect(() => {
        if (props.selectEnabled && props.selectedItemsCallback) {
            props.selectedItemsCallback(
                selectedFlatRows.filter((row) => row.index >= pageIndex * pageSize && row.index < (pageIndex + 1) * pageSize).map((r) => r.original)
            );
        }
    }, [selectedFlatRows]);

    useEffect(() => {
        const list = store.list[props.listStoreId];
        if (list) {
            if (pageIndex !== list.pageIndex) gotoPage(list.pageIndex);
            if (pageSize !== list.pageSize) setPageSize(list.pageSize);
        }
    }, [store.list[props.listStoreId], pageIndex, pageSize]);

    useEffect(() => {
        props.dispatch(changeSorting({ listId: props.listStoreId, sorting: sortBy }));
    }, [sortBy]);

    useEffect(() => {
        tableInstance.setHiddenColumns(props.hideColumns ?? []);
    }, [props.hideColumns]);

    const startRow = pageSize * pageIndex;
    const endRow = startRow + pageSize;

    const hidePagination = props.pagination?.hidePagination ?? props.data.length < pageSize;

    return (
        <div id={props.id}>
            {!hidePagination ? (
                <div className='d-flex justify-content-between p-2'>
                    <div className='d-flex justify-content-start'>
                        {(!props.pagination || props.pagination.allowPageSizeSelect !== false) && (
                            <div className='d-flex flex-row dataTable-dropdown align-items-center'>
                                <select
                                    className='form-control me-2'
                                    value={pageSize}
                                    onChange={(e) => {
                                        props.dispatch(changePageSize({ listId: props.listStoreId, pageSize: Number(e.target.value) }));
                                    }}>
                                    {possiblePageSizes.map((pageSize) => (
                                        <option
                                            key={pageSize}
                                            value={pageSize}>
                                            {pageSize}
                                        </option>
                                    ))}
                                </select>
                                <span className='text-nowrap text-secondary'>
                                    <FormattedMessage
                                        id='table.entriesPerPage'
                                        description='The entries per page in the default table message.'
                                        defaultMessage='entries per page'
                                    />{' '}
                                    <strong>
                                        <small>
                                            <FormattedMessage
                                                id='table.totalEntries'
                                                description='The entries per page in the default table message.'
                                                defaultMessage='(total entries: {total})'
                                                values={{ total: props.data.length }}
                                            />
                                        </small>
                                    </strong>
                                </span>
                            </div>
                        )}
                    </div>
                    {(!props.pagination || !props.pagination.allowPageJumpInput || props.pagination.allowPageJumpInput.top !== false) && (
                        <span className='d-flex flex-row align-items-center text-secondary'>
                            <FormattedMessage
                                id='table.currentPage'
                                description='The current page in the default table message.'
                                defaultMessage='Page'
                            />
                            <input
                                className='form-control mx-1'
                                type='number'
                                defaultValue={pageIndex + 1}
                                value={pageIndex + 1}
                                onChange={(e) => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                    props.dispatch(changePage({ listId: props.listStoreId, page: page }));
                                }}
                                style={{ width: '75px' }}
                            />
                            <strong className='text-secondary'>
                                <FormattedMessage
                                    id='table.currentPage.ofPages'
                                    description='The current page of total amount of pages in the default table message.'
                                    defaultMessage='of'
                                />
                                {' ' + (props.pageCount ? pageCount : pageOptions.length)}
                            </strong>
                        </span>
                    )}

                    {(!props.pagination || !props.pagination.allowPageButtonNavigation || props.pagination.allowPageButtonNavigation.top !== false) &&
                        pageCount > 1 && (
                            <PaginationButtonNavigation
                                dispatch={props.dispatch}
                                listStoreId={props.listStoreId}
                                id={props.id + '-top'}
                                {...props.pagination?.allowPageButtonNavigation?.topProps}
                            />
                        )}
                </div>
            ) : (
                <>
                    {(!props.pagination || props.pagination.allowPageSizeSelect !== false) && props.showCount && props.data.length > 0 && (
                        <div className='d-flex flex-row justify-content-start'>
                            <strong>
                                <small>
                                    <FormattedMessage
                                        id='table.totalEntries'
                                        description='The entries per page in the default table message.'
                                        defaultMessage='(total entries: {total})'
                                        values={{ total: props.data.length }}
                                    />
                                </small>
                            </strong>
                        </div>
                    )}
                </>
            )}
            <table
                {...getTableProps()}
                className='table align-items-center mb-0'>
                <thead className={props.headerClassName || headerClass}>
                    {headerGroups.map((headerGroup, index) => (
                        <THeader
                            className={props.headerItemClassName}
                            key={index + '_' + headerGroup.id}
                            headerGroup={headerGroup}
                            sortingColumns={props.sortingColumns}
                        />
                    ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                    {page.length === 0 && (
                        <tr>
                            <td>
                                <p className='text-secondary'>No data to display</p>
                            </td>
                        </tr>
                    )}
                    {props.pageCount
                        ? page.map((row) => {
                              if (startRow <= row.index && row.index < endRow) {
                                  prepareRow(row);
                                  return (
                                      <>
                                          <TRow
                                              className={props.customRowClassName ? props.customRowClassName(row) : props.rowClassName}
                                              key={row.id}
                                              row={row}
                                              expandEnabled={props.expandEnabled}
                                              selectEnabled={props.selectEnabled}
                                              onClick={props.onSelectElement}
                                              RowElement={props.RowElement}
                                          />
                                          {row.isExpanded ? <>{props.renderSubComponent && props.renderSubComponent({ row })}</> : null}
                                      </>
                                  );
                              } else return <></>;
                          })
                        : page.map((row) => {
                              prepareRow(row);
                              return (
                                  <>
                                      <TRow
                                          className={props.customRowClassName ? props.customRowClassName(row) : props.rowClassName}
                                          key={row.id}
                                          row={row}
                                          expandEnabled={props.expandEnabled}
                                          selectEnabled={props.selectEnabled}
                                          onClick={props.onSelectElement}
                                          RowElement={props.RowElement}
                                      />
                                      {row.isExpanded ? <>{props.renderSubComponent && props.renderSubComponent({ row })}</> : null}
                                  </>
                              );
                          })}
                </tbody>
            </table>
            {!props.pagination?.hidePagination && (
                <>
                    {pageCount > 1 && <hr className='horizontal dark my-3' />}

                    {pageCount > 1 && (
                        <div className='d-flex flex-row justify-content-between align-items-center m-2'>
                            {(!props.pagination || !props.pagination.allowPageJumpInput || props.pagination.allowPageJumpInput.bottom !== false) && (
                                <span className='d-flex flex-row align-items-center text-secondary'>
                                    <FormattedMessage
                                        id='table.currentPage'
                                        description='The current page in the default table message.'
                                        defaultMessage='Page'
                                    />
                                    <input
                                        className='form-control mx-1'
                                        type='number'
                                        defaultValue={pageIndex + 1}
                                        onChange={(e) => {
                                            const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                            props.dispatch(changePage({ listId: props.listStoreId, page: page }));
                                        }}
                                        style={{ width: '75px' }}
                                    />
                                    <strong className='text-secondary'>
                                        <FormattedMessage
                                            id='table.currentPage.ofPages'
                                            description='The current page of total amount of pages in the default table message.'
                                            defaultMessage='of'
                                        />
                                        {' ' + (props.pageCount ? pageCount : pageOptions.length)}
                                    </strong>
                                </span>
                            )}

                            {(!props.pagination ||
                                !props.pagination.allowPageButtonNavigation ||
                                props.pagination.allowPageButtonNavigation.bottom !== false) && (
                                <PaginationButtonNavigation
                                    dispatch={props.dispatch}
                                    listStoreId={props.listStoreId}
                                    id={props.id + '-bottom'}
                                    {...props.pagination?.allowPageButtonNavigation?.bottomProps}
                                />
                            )}
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
