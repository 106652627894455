import { ApiError, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { UserClient } from '@frontend/user/api';
import { User, UserListResponse, UserQueryParams } from '@frontend/user/types';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';

export interface UserState {
    unordered: User[];
    users: UserListResponse | null;
    status: SliceStatus;
    current: User | null;
}

const initialState: UserState = {
    unordered: [],
    users: null,
    status: SliceStatus.INIT,
    current: null,
};

export const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        seedUsers(state, action: PayloadAction<User[]>) {
            state.unordered = [...state.unordered.filter((user) => action.payload.find((u) => u.id == user.id) == undefined), ...action.payload];
        },
        updateUser(state, action: PayloadAction<User>) {
            state.unordered = state.unordered.map((user) => (user.id == action.payload.id ? action.payload : user));
            if (state.users) {
                state.users.results = state.users.results.map((user) => (user.id == action.payload.id ? action.payload : user));
            }
        },
        addUser(state, action: PayloadAction<User>) {
            state.unordered.push(action.payload);
            if (state.users) {
                state.users.count++;
                state.users.results = [action.payload].concat(state.users.results);
            }
        },
        removeUser(state, action: PayloadAction<string>) {
            state.unordered = state.unordered.filter((user) => user.id != action.payload);
            if (state.users) {
                state.users.count--;
                state.users.results = state.users.results.filter((user) => user.id != action.payload);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsers.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                const startPos = toNumber(action.meta.arg.size) * toNumber(action.meta.arg.index);
                if (state.users == null) {
                    state.users = {
                        ...action.payload,
                        results: new Array(action.payload.count)
                    };
                    state.users.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.users.results.length !== action.payload.count) {
                        state.users.count = action.payload.count;
                        state.users.results = new Array(action.payload.count);
                    }
                    state.users.results.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
                state.unordered = [
                    ...state.unordered.filter((user) => action.payload.results.find((u) => u.id == user.id) == undefined),
                    ...action.payload.results
                ];
                state.status = SliceStatus.IDLE;
            })
            .addCase(fetchUser.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchUser.fulfilled, (state, action) => {
                if (state.users) {
                    const found = state.users.results.find((u) => u.id == action.meta.arg.userId);
                    if (found) {
                        state.users.results.splice(state.users.results.indexOf(found), 1, action.payload);
                    } else state.users.results.push(action.payload);
                }
                state.unordered = [...state.unordered.filter((user) => action.payload.id !== user.id), action.payload];
            })
            .addCase(deleteUser.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                if (state.users == null) return;
                const found = state.users.results.find((u) => u.id == action.meta.arg.userId);
                if (found == undefined) return;
                state.users.results.splice(state.users.results.indexOf(found), 1);
            })
            .addCase(deleteUsers.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(deleteUsers.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                state.unordered = state.unordered.filter((u) => !action.meta.arg.includes(u.id));
                if (state.users == null) return;
                state.users.count = state.users.count - action.meta.arg.length;
                state.users.results = state.users.results.filter((u) => !action.meta.arg.includes(u.id));
            })
            .addCase(fetchProfile.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchProfile.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                state.current = action.payload;
            });
    }
});

export const fetchUsers = createAsyncThunk<UserListResponse, ApiQueryParams<DefaultQueryParams | UserQueryParams>>(
    'fetchUsers',
    async (queryParams: ApiQueryParams<DefaultQueryParams | UserQueryParams>, { rejectWithValue }) => {
        try {
            return await UserClient.fetchUsers(queryParams);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchUser = createAsyncThunk<User, { accountId: string; userId: string }>(
    'fetchUser',
    async (variables: { accountId: string; userId: string }, { rejectWithValue }) => {
        try {
            return await UserClient.fetchUser(variables.accountId, variables.userId);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const deleteUser = createAsyncThunk<void, { accountId: string; userId: string }>(
    'deleteUser',
    async (variables: { accountId: string; userId: string }, { rejectWithValue }) => {
        try {
            return await UserClient.deleteUser(variables.accountId, variables.userId);
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const deleteUsers = createAsyncThunk<void, string[]>('deleteUsers', async (userIds: string[], { rejectWithValue }) => {
    try {
        return await UserClient.deleteUsers(userIds);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const fetchProfile = createAsyncThunk<User, void>('fetchProfile', async (_, { rejectWithValue }) => {
    try {
        return await UserClient.getProfile();
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});
export const userStore = { users: userSlice.reducer };
export const { seedUsers, updateUser, removeUser, addUser } = userSlice.actions;
