import { ClassType, EntityType, Rights } from '@frontend/common';
import { ConfirmationModal, Dropdown, DropdownItem, Spinner } from '@frontend/elements';
import { ICONS } from '@frontend/icons';
import { Identification, IdentificationType } from '@frontend/identification/types';
import { CommonMessage } from '@frontend/lang';
import { ObjectDetailMapper } from '@frontend/rendering/details';

import useIdentificationDetail from './identification-detail.controller';

const ID = 'identification-detail';

const IdentificationDetail = () => {
    const viewProps = useIdentificationDetail();
    if (viewProps.object == null) {
        return (
            <div id={ID}>
                <div className='card mt-3'>
                    <div className='card-header'>Identification</div>
                    <div className='card-body'>
                        <Spinner />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div id={ID}>
            <div className='card mt-3'>
                <div className='card-header d-flex justify-content-between'>
                    <h4>{viewProps.object.key}</h4>
                    <Dropdown
                        icon={ICONS.BUTTON.SETTINGS}
                        classType={ClassType.LIGHT}
                        requiredPermissions={[
                            { item: EntityType.IDENTIFICATION, source: EntityType.ACCOUNT, right: Rights.DELETE || Rights.UPDATE, uid: '?' }
                        ]}>
                        <DropdownItem
                            label={CommonMessage.BUTTONS.DELETE}
                            icon={ICONS.BUTTON.DELETE}
                            classType={ClassType.DANGER}
                            onClick={() => viewProps.changeShowDeleteModal(true)}
                            requiredPermissions={[{ item: EntityType.IDENTIFICATION, source: EntityType.ACCOUNT, right: Rights.DELETE, uid: '?' }]}
                        />
                        <DropdownItem
                            label={CommonMessage.BUTTONS.UPDATE}
                            icon={ICONS.BUTTON.UPDATE}
                            classType={ClassType.INFO}
                            onClick={viewProps.onUpdate}
                            requiredPermissions={[{ item: EntityType.IDENTIFICATION, source: EntityType.ACCOUNT, right: Rights.UPDATE, uid: '?' }]}
                        />
                    </Dropdown>
                </div>

                <div className='card-body d-flex flex-wrap justify-content-between'>
                    <ObjectDetailMapper<Identification>
                        object={viewProps.object}
                        order={['key', 'entity_id', ...getRelevantIdentificationColumns(viewProps.object.type)]}
                    />
                </div>
            </div>

            {viewProps.showDeleteModal && (
                <ConfirmationModal
                    handleClose={() => viewProps.changeShowDeleteModal(false)}
                    message={CommonMessage.FORMS.DELETE_OBJECT(viewProps.object.type, viewProps.object.key)}
                    onConfirm={viewProps.onDeleteConfirmed}
                    severity={ClassType.DANGER}
                    show={viewProps.showDeleteModal}
                />
            )}
        </div>
    );
};

export default IdentificationDetail;

function getRelevantIdentificationColumns(type: IdentificationType): (keyof Identification)[] {
    switch (type) {
        case IdentificationType.BASIC_AUTH:
            return ['secret'];
        default:
            return [];
    }
}
