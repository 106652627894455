import { DropZone, DropZoneProps } from '@frontend/elements';
import React, { ReactNode } from 'react';

import Form, { FormProps } from '../form/form.component';

const ID = 'upload-file-form';

interface Props<T extends object, S extends object> {
    children?: ReactNode;
    objectForm: FormProps<T, S>;
    dropZone: DropZoneProps;
}

export const UploadFileForm = <T extends object, S extends object>(props: Props<T, S>) => {
    return (
        <div id={ID}>
            <Form<T, S> {...props.objectForm}>
                {props.children}
                <DropZone {...props.dropZone} />
            </Form>
        </div>
    );
};
