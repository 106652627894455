import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

const RoleOverview = lazy(() => import('../role/role-overview'));
const RoleCreate = lazy(() => import('../role/role-create/role-create.component'));
const RoleDetail = lazy(() => import('../role/role-detail/role-detail.component'));
const RoleUpdate = lazy(() => import('../role/role-update/role-update.component'));
const RoleCreate2 = lazy(() => import('../role/role-create-2/role-create-2.component'));
const RoleCreatePredefined = lazy(() => import('../role/role-create-predefined/role-create-predefined.component'));

export function RoleRoutes() {
    return (
        <Route path='roles'>
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <RoleCreate2 />
                    </AsyncComponent>
                }
            />
            <Route
                path='predefined'
                element={
                    <AsyncComponent>
                        <RoleCreatePredefined />
                    </AsyncComponent>
                }
            />
            <Route
                index
                element={
                    <AsyncComponent>
                        <RoleOverview />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <RoleCreate />
                    </AsyncComponent>
                }
            />
            <Route path=':roleId'>
                <Route
                    index
                    path='detail'
                    element={
                        <AsyncComponent>
                            <RoleDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <RoleUpdate />
                        </AsyncComponent>
                    }
                />
            </Route>
            <Route
                index
                element={<Navigate to='/admin/roles' />}
            />
        </Route>
    );
}
