import { EntityType } from '@frontend/common';
import { ProductCategoryClient } from '@frontend/group/api';
import { useResolver } from '@frontend/repository';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

declare global {
    interface WindowEventMap {
        'useProductCategory:request': CustomEvent<string>;
    }
}

const REQUEST_PRODUCT_CATEGORY_EVENT = 'useProductCategory:request';
const STORAGE_KEY = 'PRD_CAT_REQ';

export function productCategoryRequest(groupId: string): string {
    window.dispatchEvent(new CustomEvent(REQUEST_PRODUCT_CATEGORY_EVENT, { detail: groupId }));
    return STORAGE_KEY + '_loading';
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function ProductCategoryResolver(props: Props) {
    useResolver(STORAGE_KEY, REQUEST_PRODUCT_CATEGORY_EVENT, EntityType.PRODUCT_CATEGORY, (ids) => ProductCategoryClient.resolveProductCategories(ids), props.dispatch);
    return <></>;
}
