import { GroupedOptions, SelectOption, SingleOption, isSingleOption } from '@frontend/basic-forms';
import { useLocale } from '@frontend/lang';
import { useAccountRepository } from '@frontend/repository';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { mapResultSetToMappedOptions, mapResultSetToOptions, usePrevious } from '../object-select-input-v2/object-select-input-v2.controller';
import { MultiObjectSelectInputPropsV2 } from './multi-object-select-input-v2.component';

interface ViewProps<T extends { id: string }> {
    options: SelectOption<T>[];
    value?: T[];
    onScrollToBottom: () => void;
    search: (value: string) => void;
    onSelect: (option: SingleOption<T>[] | null) => void;
    overwriteDisabled?: boolean;
    hide: boolean;
}

const pageSize = '100';

const useMultiObjectSelectInputV2 = <T extends { id: string }, D extends string | number>(props: MultiObjectSelectInputPropsV2<T, D>): ViewProps<T> => {
    const { locale } = useLocale();
    const params = useParams();
    const {
        store: { currentAccount }
    } = useAccountRepository();
    const [urlOverwrite, setUrlOverwrite] = useState<T | null>(null);
    const [cache, setCache] = useState<T[]>([]);
    const timer = useRef<NodeJS.Timeout | null>(null);
    const [count, setCount] = useState<number>(0);
    const [index, setIndex] = useState<number>(0);
    const [options, setOptions] = useState<SelectOption<T>[]>([]);
    const [mappedOptions, setMappedOptions] = useState<GroupedOptions<T>[]>([]);

    const value: T[] = useMemo(() => {
        if (options.length === 0) return [];
        const v = cache.filter((o) => props.value.includes(o.id));
        return v;
    }, [props.value, props.idValues, options]);

    const onSelect = (option: SingleOption<T>[] | null) => {
        if (option == null) return;
        props.onChange && props.onChange(cache.filter((c) => option.some((o) => o.value === c.id)));
    };

    const previous = usePrevious({ params: props.queryParams, index: index, account: currentAccount });
    useEffect(() => {
        if (!currentAccount) return;
        if (props.disableFetch) return;
        if (
            ((previous == undefined ||
                JSON.stringify(previous.params) != JSON.stringify(props.queryParams) ||
                previous.index != index ||
                previous.account != currentAccount) &&
                currentAccount) ||
            !props.value
        ) {
            props.fetch({ index: index.toString(), size: pageSize, account_id: currentAccount, ...props.queryParams }).then((result) => {
                setCount(result.count);
                if (index == 0) {
                    if (!props.mapOptions) {
                        setOptions(mapResultSetToOptions(result.results, props.itemLabel));
                        setCache(result.results);
                    } else {
                        setMappedOptions(mapResultSetToMappedOptions(result.results, props.mapOptions, props.itemLabel));
                    }
                } else {
                    if (!props.mapOptions) {
                        setOptions((options ?? []).concat(mapResultSetToOptions(result.results, props.itemLabel)));
                        setCache((cache ?? []).concat(result.results));
                    } else {
                        setOptions((mappedOptions ?? []).concat(mapResultSetToMappedOptions(result.results, props.mapOptions, props.itemLabel)));
                        setCache((cache ?? []).concat(result.results));
                    }
                }
            });
        }
    }, [index, props.queryParams, currentAccount, locale]);

    useEffect(() => {
        if (props.mapOptions) {
            mapResultSetToMappedOptions(cache, props.mapOptions, props.itemLabel);
        } else {
            setOptions(mapResultSetToOptions(cache, props.itemLabel));
        }
    }, [props.itemLabel, locale]);

    const search = (value: string) => {
        if (props.disableFetch) return;
        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
            props.fetch({ search: value, index: '0', size: pageSize, account_id: currentAccount, ...props.queryParams }).then((result) => {
                setOptions(mapResultSetToOptions(result.results, props.itemLabel));
                setCache(result.results);
            });
        }, 500);
    };

    const scrollBottomVariableRef = useRef({ count, pageSize, index });
    useEffect(() => {
        scrollBottomVariableRef.current = { count, pageSize, index };
    }, [count, pageSize, index]);
    const onScrollToBottom = () => {
        const { count, pageSize, index } = scrollBottomVariableRef.current;
        if (count / parseInt(pageSize) > 1 && parseInt(pageSize) * index < count) {
            setIndex(index + 1);
        }
    };

    const opt = useMemo(() => {
        if (props.subset) {
            if (props.subset.length === 0) return [];
            if (props.mapOptions) {
                const result: { label: string; options: { value: string; label: string }[] }[] = [];
                mappedOptions.forEach((o) => {
                    const current: { label: string; options: { value: string; label: string }[] } = { label: o.label, options: [] };
                    o.options.filter((op) => props.subset!.includes(op.value)).forEach((op) => current.options.push(op));
                    if (current.options.length > 0) result.push(current);
                });
                return result;
            } else {
                return options.filter((o) => {
                    if (isSingleOption(o)) {
                        return props.subset!.includes(o.value);
                    }
                    return o.options.filter((op) => props.subset!.includes(op.value)).length > 0;
                });
            }
        } else return props.mapOptions ? mappedOptions : options;
    }, [props.mapOptions, props.subset, options, mappedOptions]);

    return {
        options: opt,
        value,
        onScrollToBottom,
        search,
        onSelect,
        overwriteDisabled:
            urlOverwrite !== null || (!props.hideOnOneOption && options.length === 1 && !props.isClearable && props.required) || props.disableFetch,
        hide: props.hidden === true || (props.hideOnOneOption === true && options.length <= 1 && !props.isClearable)
    };
};

export default useMultiObjectSelectInputV2;
