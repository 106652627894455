import { AuthenticationManager } from '@frontend/authentication-v2';
import { Breadcrumbs } from '@frontend/breadcrumbs';
import { ClassType } from '@frontend/common';
import { Dropdown, DropdownItem, LanguageSelect } from '@frontend/elements';
import { ICONS } from '@frontend/icons';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import { FaUser } from 'react-icons/fa';
import { FaArrowsRotate } from 'react-icons/fa6';
import { IoExit } from 'react-icons/io5';

import AccountMembership from '../account-membership/account-membership.component';
import useHeader from './header-controller';

export interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
    color?: ClassType;
    version: string;
}

export const Header = (props: Props) => {
    const viewProps = useHeader(props);
    return (
        <nav
            className={`navbar navbar-main navbar-expand-lg px-2 shadow-none bg-${props.color || 'primary'}`}
            id='navbarBlur'
            data-scroll='false'>
            <div className='d-flex flex-row justify-content-start align-items-center w-100 py-1 px-3'>
                <div className='sidenav-toggler sidenav-toggler-inner d-block d-xl-none pe-5'>
                    <div
                        onClick={() => viewProps.changeNavbarPinned(!viewProps.navbarPinned)}
                        className='nav-link p-0 cursor-pointer'>
                        <div className='sidenav-toggler-inner'>
                            <i className='sidenav-toggler-line bg-light'></i>
                            <i className='sidenav-toggler-line bg-light'></i>
                            <i className='sidenav-toggler-line bg-light'></i>
                        </div>
                    </div>
                </div>

                <div className='d-flex flex-column-reverse flex-md-row align-items-center justify-content-md-between w-100'>
                    <Breadcrumbs />
                    <div
                        className='collapse navbar-collapse'
                        id='navbar'>
                        <span className='d-flex w-100 flex-row align-items-center justify-content-end'>
                            <Dropdown
                                icon={FaUser}
                                classType={ClassType.LIGHT}>
                                <DropdownItem
                                    label='Profile'
                                    onClick={() => viewProps.navigate('/profile')}
                                    classType={ClassType.PRIMARY}
                                    icon={FaUser}
                                />
                                {viewProps.udpateAvailable && (
                                    <DropdownItem
                                        label='Update application now'
                                        icon={FaArrowsRotate}
                                        classType={ClassType.SUCCESS}
                                        onClick={viewProps.updateApplication}
                                    />
                                )}
                                <DropdownItem
                                    label='Sign out'
                                    icon={IoExit}
                                    classType={ClassType.DANGER}
                                    onClick={() => AuthenticationManager.getInstance().doLogout()}
                                />
                            </Dropdown>
                            <span className='ms-2'>
                                <LanguageSelect />
                            </span>
                            <AccountMembership className='ms-2' />
                            <button
                                id='admin.button'
                                className='ms-2 btn btn-light fs-7'
                                onClick={() => viewProps.navigate('/admin')}>
                                <ICONS.BUTTON.SETTINGS className='p-0 m-0' />
                            </button>
                            <button
                                id='user.button'
                                className='ms-2 btn btn-light fs-7'
                                onClick={() => viewProps.navigate('/overview')}>
                                <ICONS.BUTTON.OVERVIEW className='p-0 m-0' />
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </nav>
    );
};
