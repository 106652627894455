
import { EventListener } from '@frontend/pub-sub';
import { Playlist } from '@frontend/playlist/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

export class PlaylistEventListener extends EventListener<Playlist> {
    
    private static instance: PlaylistEventListener | null = null;
    private constructor(dispatch: ThunkDispatch<any, any, Action>) {
        super('playlist', dispatch);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>): PlaylistEventListener {
        if (this.instance == null) {
            this.instance = new PlaylistEventListener(dispatch);
        }
        return this.instance;
    }

    getInstance(dispatch: ThunkDispatch<any, any, Action>): EventListener<Playlist> {
        return PlaylistEventListener.getInstance(dispatch);
    }

    protected onUpdate() {}

    protected onDelete() {}

    protected onCreate() {}
}
