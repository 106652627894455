import { EntityType } from '@frontend/common';
import { notificationTemplateRequest } from '@frontend/notification-service/events';
import { NotificationTemplate } from '@frontend/notification-service/types';
import { ObjectBadge, ObjectBadgeProps } from '@frontend/rendering/badge';
import React from 'react';

export const NotificationTemplateBadge = (props: ObjectBadgeProps<NotificationTemplate>) => {
    return (
        <ObjectBadge<NotificationTemplate>
            id={props.id}
            type={EntityType.NOTIFICATION_TEMPLATE}
            mapDisplayName={(obj: NotificationTemplate) => obj.name}
            resolve={notificationTemplateRequest}
            navigateString={(object: NotificationTemplate) => `/accounts/${object.account_id}/users/${object.id}`}
            {...props}
        />
    );
};
