import React from 'react';

import { WizardProps } from './wizard.component';

interface ViewProps {
    childrenArray: React.ReactNode[];
    currentStep: number;
    changeCurrentStep: React.Dispatch<React.SetStateAction<number>>;
    goToNextStep: () => void;
    goToPreviousStep: () => void;
    isDisabled: (index: number) => boolean;
}

export const useWizard = (props: WizardProps): ViewProps => {
    const childrenArray = React.Children.toArray(props.children);
    const [currentStep, changeCurrentStep] = React.useState<number>(0);

    const goToNextStep = () => {
        if (currentStep < childrenArray.length - 1 && !props.disabled?.[currentStep]) {
            changeCurrentStep(currentStep + 1);
        }
    };

    const goToPreviousStep = () => {
        if (currentStep > 0) {
            changeCurrentStep(currentStep - 1);
        }
    };

    function isDisabled(index: number): boolean {
        return !!(props.disabled && props.disabled.findIndex(Boolean) !== -1 && props.disabled.findIndex(Boolean) < index);
    }

    return {
        childrenArray,
        changeCurrentStep,
        currentStep,
        goToNextStep,
        goToPreviousStep,
        isDisabled
    };
};
