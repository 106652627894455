import { ApiError, ApiQueryParams, DefaultQueryParams, PaginatedResponse } from '@frontend/api-utils';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';
import { SortingRule } from 'react-table';

export interface ListState {
    list: {
        [key: string]: {
            results:
                | {
                      id: string;
                      [key: string]: any;
                  }[]
                | null;
            count: number | null;
            selected: {
                id: string;
                [key: string]: any;
            }[];
            pageIndex: number;
            pageSize: number;
            sorting: SortingRule<object>[];
        };
    };
}

const initialState: ListState = {
    list: {}
};

const initialListValues = {
    selected: [],
    pageIndex: 0,
    pageSize: 25,
    sorting: []
};

export const listSlice = createSlice({
    name: 'list',
    initialState,
    reducers: {
        update(state, action: PayloadAction<{ key: string; value: any }>) {
            if (state.list[action.payload.key] && state.list[action.payload.key].results) {
                state.list[action.payload.key].results = state.list[action.payload.key].results!.map((e: any) =>
                    e.id == action.payload.value.id ? action.payload.value : e
                );
            }
        },
        clear(state, action: PayloadAction<string>) {
            if (state.list[action.payload] && state.list[action.payload].results) {
                state.list[action.payload].results = null;
                state.list[action.payload].count = null;
                state.list[action.payload].selected = [];
            }
        },
        changePage(state, action: PayloadAction<{ listId: string; page: number }>) {
            if (state.list[action.payload.listId]) {
                state.list[action.payload.listId].pageIndex = action.payload.page;
            }
        },
        changePageSize(state, action: PayloadAction<{ listId: string; pageSize: number }>) {
            if (state.list[action.payload.listId]) {
                state.list[action.payload.listId].pageSize = action.payload.pageSize;
            }
        },
        changeSorting(state, action: PayloadAction<{ listId: string; sorting: SortingRule<object>[] }>) {
            if (state.list[action.payload.listId]) {
                state.list[action.payload.listId].sorting = action.payload.sorting;
            }
        },
        select(state, action: PayloadAction<{ listId: string; objId: string }>) {
            if (state.list[action.payload.listId]) {
                const found = state.list[action.payload.listId].selected.find((o) => o.id == action.payload.objId);
                if (found) {
                    state.list[action.payload.listId].selected = state.list[action.payload.listId].selected.filter((o) => o.id != action.payload.objId);
                } else {
                    if (
                        state.list[action.payload.listId].results != null &&
                        state.list[action.payload.listId].results!.find((o) => o?.id == action.payload.objId)
                    )
                        state.list[action.payload.listId].selected.push(state.list[action.payload.listId].results!.find((o) => o?.id == action.payload.objId)!);
                }
            }
        },
        allSelect(state, action: PayloadAction<{ listId: string; index: number; size: number }>) {
            if (state.list[action.payload.listId] && state.list[action.payload.listId].results) {
                const startPos = action.payload.index * action.payload.size;
                const relevantObjects = state.list[action.payload.listId].results!.slice(startPos, startPos + action.payload.size);
                if (relevantObjects.every((obj) => state.list[action.payload.listId].selected!.find((o) => o.id == obj.id) != undefined)) {
                    state.list[action.payload.listId].selected = state.list[action.payload.listId].selected!.filter(
                        (obj) => relevantObjects.find((o) => o.id == obj.id) == undefined
                    );
                } else {
                    state.list[action.payload.listId].selected = state.list[action.payload.listId].selected!.concat(relevantObjects);
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetch.fulfilled, (state, action) => {
            if (action.meta.arg.params['index'] !== undefined && action.meta.arg.params['size'] !== undefined) {
                const startPos = toNumber(action.meta.arg.params['index']) * toNumber(action.meta.arg.params['size']);
                if (state.list[action.meta.arg.key] == null || state.list[action.meta.arg.key].results == null) {
                    state.list[action.meta.arg.key] = { ...initialListValues, ...action.payload, results: new Array(action.payload.count) };
                    state.list[action.meta.arg.key].results!.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state.list[action.meta.arg.key].results!.length != action.payload.count) {
                        state.list[action.meta.arg.key].count = action.payload.count;
                        state.list[action.meta.arg.key].results = new Array(action.payload.count);
                    }
                    state.list[action.meta.arg.key].results!.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
            } else {
                state.list[action.meta.arg.key] = { ...initialListValues, ...action.payload };
            }
        });
    }
});

type Options = {
    key: string;
    params: ApiQueryParams<DefaultQueryParams | string | number>;
    func: (queryParams?: ApiQueryParams<DefaultQueryParams | string | number>) => Promise<PaginatedResponse<any>>;
};
export const fetch = createAsyncThunk<PaginatedResponse<any>, Options>('fetch', async (options: Options, { rejectWithValue }) => {
    try {
        return await options.func(options.params);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const listStore = { lists: listSlice.reducer };
export const { update, clear, changePage, changePageSize, changeSorting, select, allSelect } = listSlice.actions;
