import { Dispatch, SetStateAction, useState } from 'react';

import { StatisticsDetailProps } from './statistics-detail.component';

interface ViewProps {
    filterSet: { [key: string]: string | null };
    changeFilterSet: Dispatch<SetStateAction<{ [key: string]: string | null }>>;
}

const useStatisticsDetail = (props: StatisticsDetailProps): ViewProps => {
    const [filterSet, changeFilterSet] = useState<{ [key: string]: string | null }>({});

    return {
        filterSet,
        changeFilterSet
    };
};

export default useStatisticsDetail;
