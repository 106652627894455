import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { ProductAttributesRoutes } from '../product-attribute/product-attribute-routes';
import { EquipmentRoutes } from './equipment/equipment-routes';

const EquipmentProductOverview = lazy(() => import('./equipment-product-overview/equipment-product-overview.component'));
const EquipmentProductDetail = lazy(() => import('./equipment-product-detail/equipment-product-detail.component'));
const EquipmentProductCreate = lazy(() => import('./equipment-product-create/equipment-product-create.component'));
const EquipmentProductUpdate = lazy(() => import('./equipment-product-update/equipment-product-update.component'));
const EquipmentCreate = lazy(() => import('./equipment/equipment-create/equipment-create.component'));

export function EquipmentProductRoutes() {
    const ProductAttributeRoute = ProductAttributesRoutes();
    const EquipmentRoute = EquipmentRoutes();
    return (
        <Route path='equipment-products'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <EquipmentProductOverview />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <EquipmentProductCreate />
                    </AsyncComponent>
                }
            />
            <Route
                path='equipment/create'
                element={
                    <AsyncComponent>
                        <EquipmentCreate />
                    </AsyncComponent>
                }
            />
            <Route path=':productId'>
                <Route
                    path='detail'
                    element={
                        <AsyncComponent>
                            <EquipmentProductDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <EquipmentProductUpdate />
                        </AsyncComponent>
                    }
                />
                {EquipmentRoute}
                {ProductAttributeRoute}
            </Route>
        </Route>
    );
}
