export interface SingleOption<T> {
    display?: React.ReactNode;
    label: string;
    value: string;
    metadata?: T;
    selected?: boolean;
}

export type SelectOption<T> = SingleOption<T> | GroupedOptions<T>;

export type GroupedOptions<T> = {
    display?: React.ReactNode;
    label: string;
    options: SingleOption<T>[];
};

export function isSingleOption<T>(option: SingleOption<T> | GroupedOptions<T>): option is SingleOption<T> {
    return 'value' in option;
}

export function isGroupedOptions<T>(option: SingleOption<T> | GroupedOptions<T>): option is GroupedOptions<T> {
    return 'options' in option;
}

export function explodeOptions<T>(options: SelectOption<T>[]): SingleOption<T>[] {
    return options.reduce<SingleOption<T>[]>((acc, option) => acc.concat(isSingleOption(option) ? option : option.options), []);
}
