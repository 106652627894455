import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

const WorkflowCreate = lazy(() => import('../workflow/workflow-create/workflow-create.component'));
const WorkflowUpdate = lazy(() => import('../workflow/workflow-update/workflow-update.component'));
const WorkflowDetail = lazy(() => import('../workflow/workflow-detail/workflow-detail.component'));
const WorkflowOverview = lazy(() => import('../workflow/workflow-overview'));
const WorkflowCopy = lazy(() => import('../workflow/workflow-copy/workflow-copy.component'));
const StepCreate = lazy(() => import('../workflow/step/step-create/step-create.component'));
const FieldsUpdate = lazy(() => import('../workflow/fields/fields-update/fields-update.component'));
export function WorkflowRoutes() {
    return (
        <Route path='workflows'>
            <Route
                path='*'
                element={<Navigate to='/admin/workflows' />}
            />
            <Route
                index
                element={
                    <AsyncComponent>
                        <WorkflowOverview />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <WorkflowCreate />
                    </AsyncComponent>
                }
            />
            <Route path=':workflowId'>
                <Route
                    index
                    path='detail'
                    element={
                        <AsyncComponent>
                            <WorkflowDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='copy'
                    element={
                        <AsyncComponent>
                            <WorkflowCopy />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <WorkflowUpdate />
                        </AsyncComponent>
                    }
                />
                <Route path='steps'>
                    <Route
                        path='create'
                        element={
                            <AsyncComponent>
                                <StepCreate />
                            </AsyncComponent>
                        }
                    />
                    <Route path=':stepId'>
                        <Route path='fields'>
                            <Route
                                path='update'
                                element={<FieldsUpdate />}
                            />
                        </Route>
                    </Route>
                </Route>
            </Route>
        </Route>
    );
}
