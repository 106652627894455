import { useEffect, useState } from 'react';

import { getClassName } from '../input-class';
import { ColorInputProps } from './color-input.component';

interface ViewProps {
    valid: boolean;
    touched: boolean;
    inputClass: string;
    changeTouched: React.Dispatch<React.SetStateAction<boolean>>;
    isValid: () => boolean;
}

const useColorInput = (props: ColorInputProps): ViewProps => {
    const [touched, changeTouched] = useState<boolean>(false);
    const [valid, changeValid] = useState<boolean>(false);

    const regex = /^#([a-f0-9]{6}|[a-f0-9]{3})$/i;

    const isValid = (): boolean => {
        let check = true;
        if (!props.value && props.required) check = false;
        if (props.value) {
            if (regex.test(props.value)) check = true;
            else check = false;
        }
        changeValid(check);
        return check;
    };

    useEffect(() => {
        if (props.isValidCallback) {
            props.isValidCallback(isValid());
        } else isValid();
    }, [props.value, props.valid]);

    return {
        inputClass: getClassName(touched, valid, props.value),
        isValid,
        changeTouched,
        touched,
        valid
    };
};

export default useColorInput;
