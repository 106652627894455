import { lazy } from 'react';
import { Route } from 'react-router-dom';

const StockOverview = lazy(() => import('./stock-overview/stock-overview'));

export function StockRoutes() {
    return (
        <Route path='stocks'>
            <Route
                index
                element={<StockOverview />}
            />
        </Route>
    );
}
