import { APIClient, ApiQueryParams } from "@frontend/api-utils";
import { CreateEquipmentUse, EquipmentUse, EquipmentUseListResponse, EquipmentUseQueryParams } from "@frontend/equipment/types";

const endpoint = '/equipment-api/v1';
export class EquipmentUseClient extends APIClient {
    public static async fetchEquipmentUses(queryParams?: ApiQueryParams<EquipmentUseQueryParams>): Promise<EquipmentUseListResponse> {
        return await this.apiPaginated<EquipmentUseListResponse, EquipmentUseQueryParams>(`${endpoint}/uses`, queryParams);
    }

    public static async postEquipmentUse(accountId: string, equipmentId: string, equipmentUse: CreateEquipmentUse): Promise<EquipmentUse> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/equipments/${equipmentId}/uses`, equipmentUse);
        return this.handleResponse<EquipmentUse>(response);
    }

    public static async fetchEquipmentUse(accountId: string, equipmentId: string, equipmentUseId: string): Promise<EquipmentUse> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/equipments/${equipmentId}/uses/${equipmentUseId}`);
        return await this.handleResponse<EquipmentUse>(response);
    }

    public static async deleteEquipmentUse(accountId: string, equipmentId: string, equipmentUseId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/equipments/${equipmentId}/uses/${equipmentUseId}`);
        await this.handleVoidResponse(response);
    }
}