import { EntityType } from '@frontend/common';
import { IdentificationClient } from '@frontend/identification/api';
import { IdentificationType } from '@frontend/identification/types';
import { IoTClient } from '@frontend/iot/api';
import { IoT } from '@frontend/iot/types';
import { useAccountRepository } from '@frontend/repository';
import { Change } from '@frontend/sync/types';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch } from '../../redux/store';

interface ViewProps {
    api: string | undefined;
    currentAccount: string | null;
    dispatch: ReturnType<typeof useAppDispatch>;
    iot: IoT | null;
    selectedChange: Change | null;
    setSelectedChange: Dispatch<SetStateAction<Change | null>>;
    iotIdentifier: string | null;
}

const useIoTSeedOverview = (): ViewProps => {
    const { iotId, api } = useParams();
    const {
        store: { currentAccount }
    } = useAccountRepository();
    const dispatch = useAppDispatch();
    const [iot, setIot] = useState<IoT | null>(null);
    const [iotIdentifier, setIotIdentifier] = useState<string | null>(null);
    const [selectedChange, setSelectedChange] = useState<Change | null>(null);
    useEffect(() => {
        if (!iotId || !currentAccount) return;
        IoTClient.fetchIoT(currentAccount, iotId).then((res) => {
            setIot(res);
            IdentificationClient.fetchIdentifications({ entity_id: res.id, entity_type: EntityType.IOT, type: IdentificationType.IOT }).then((res) => {
                if (res.results.length > 0) {
                    setIotIdentifier(res.results[0].key);
                }
            });
        });
    }, [currentAccount, iotId]);
    return {
        api,
        currentAccount,
        dispatch,
        iot,
        selectedChange,
        setSelectedChange,
        iotIdentifier
    };
};

export default useIoTSeedOverview;
