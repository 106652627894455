import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { CreateModule, Module, ModuleListResponse, ModuleQueryParams, UpdateModule } from '@frontend/module/types';

const endpoint = '/module-api/v1';

export class ModuleClient extends APIClient {
    public static async fetchModules(queryParams?: ApiQueryParams<DefaultQueryParams | ModuleQueryParams>): Promise<ModuleListResponse> {
        return this.apiPaginated<ModuleListResponse, DefaultQueryParams | ModuleQueryParams>(`${endpoint}/modules`, queryParams);
    }

    public static async resolveModules(moduleIds: string[]): Promise<ModuleListResponse> {
        const response = await this.post(`${endpoint}/modules-resolve`, { ids: moduleIds });
        return await this.handleResponse<ModuleListResponse>(response);
    }

    public static async deleteModules(moduleIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/modules-delete`, { ids: moduleIds });
        return await this.handleVoidResponse(response);
    }

    public static async postModule(accountId: string, spotId: string, module: CreateModule): Promise<Module> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/spots/${spotId}/modules`, module);
        return await this.handleResponse<Module>(response);
    }

    public static async fetchModule(accountId: string, spotId: string, moduleId: string): Promise<Module> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/spots/${spotId}/modules/${moduleId}`);
        return await this.handleResponse<Module>(response);
    }

    public static async patchModule(accountId: string, spotId: string, moduleId: string, module: UpdateModule): Promise<Module> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/spots/${spotId}/modules/${moduleId}`, module);
        return await this.handleResponse<Module>(response);
    }

    public static async deleteModule(accountId: string, spotId: string, moduleId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/spots/${spotId}/modules/${moduleId}`);
        return await this.handleVoidResponse(response);
    }

    public static async postIoTSeed(accountId: string, iotId: string, body: { account_id: string }): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots/${iotId}/seed`, body);
        return this.handleResponse(response);
    }
}
