import { Logger } from '@frontend/Logger';
import { Locale } from '@frontend/common';

export const getCorrectTranslation = <T extends object>(locale: Locale | undefined, object: T, translateKey: keyof T) => {
    const userLocale = locale?.toLowerCase().replace(/-/g, '_');
    const found = Object.entries(object).find(([key]) => key.includes((translateKey as string) + '_' + userLocale));
    if (found && found[1] && found[1] != '') return found[1];
    else return object[translateKey];
};

export interface TranslatedStringProps<T extends object> {
    locale: Locale | undefined;
    object: T;
    objectKey: string;
}

export const TranslatedString = <T extends object>(props: TranslatedStringProps<T>) => {
    if (!props.locale) return '';
    const filteredKeys = Object.keys(props.object).filter((key) => key.includes(props.objectKey));
    const foundKey = filteredKeys.find((key) => key.includes(props.locale!.toLowerCase().replace(/-/g, '_')));

    if (!foundKey) {
        Logger.error(`No translation found for key ${props.objectKey}`);
        return (props.object as { [objectKey: string]: string })[props.objectKey];
    }
    return (props.object as { [foundKey: string]: string })[foundKey];
};
