import { APIClient, ApiQueryParams, buildApiEndpoint } from '@frontend/api-utils';
import { BarSeries, ChartQueryParams, TimeLineChart } from '@frontend/chart/types';

const endpoint = '/budget-api/v1';
export class BudgetChartClient extends APIClient {
    public static async fetchConsumption(accountId: string, queryParams?: ApiQueryParams<ChartQueryParams>): Promise<TimeLineChart> {
        const url = buildApiEndpoint(`${endpoint}/accounts/${accountId}/consumption`, queryParams);
        const response = await this.fetch(url);
        return await this.handleResponse<TimeLineChart>(response);
    }

    public static async fetchConsumptionByProduct(accountId: string, queryParams?: ApiQueryParams<ChartQueryParams>): Promise<BarSeries> {
        const url = buildApiEndpoint(`${endpoint}/accounts/${accountId}/consumption-by-product`, queryParams);
        const response = await this.fetch(url);
        return await this.handleResponse<BarSeries>(response);
    }

    public static async fetchConsumptionByUser(accountId: string, queryParams?: ApiQueryParams<ChartQueryParams>): Promise<BarSeries> {
        const url = buildApiEndpoint(`${endpoint}/accounts/${accountId}/consumption-by-user`, queryParams);
        const response = await this.fetch(url);
        return await this.handleResponse<BarSeries>(response);
    }
}
