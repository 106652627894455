import { Spinner } from '@frontend/elements';
import React from 'react';
import { IconType } from 'react-icons';

import useHeaderNavigation from './header-navigation.controller';

export interface HeaderNavigationProps {
    onChange: (active: string) => void;
    items: HeaderNavigationItem[];
}

interface HeaderNavigationItem {
    icon: IconType;
    title: React.ReactNode;
    link: string;
}

const HeaderNavigation = (props: HeaderNavigationProps) => {
    const viewProps = useHeaderNavigation(props);
    if (!viewProps.active) return <Spinner />;
    return (
        <div className='nav-wrapper position-relative end-09'>
            <ul
                className='nav nav-pills nav-fill p-1'
                role='tablist'>
                {props.items.map((item, index) => (
                    <li
                        key={index}
                        className='nav-item'
                        onClick={() => viewProps.changeActive(item.link)}>
                        <button className={`nav-link mb-0 px-0 py-1 ${viewProps.active === item.link ? 'active' : ''}`}>
                            <item.icon /> {item.title}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default HeaderNavigation;
