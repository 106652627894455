import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { CreateQuestion, Question, QuestionListResponse, QuestionQueryParams, UpdateQuestion } from '@frontend/question/types';

const endpoint = '/question-api/v1';
export class QuestionClient extends APIClient {
    public static async fetchQuestions(queryParams?: ApiQueryParams<DefaultQueryParams | QuestionQueryParams>): Promise<QuestionListResponse> {
        return await this.apiPaginated<QuestionListResponse, DefaultQueryParams>(`${endpoint}/questions`, queryParams);
    }

    public static async resolveQuestions(ids: string[]): Promise<QuestionListResponse> {
        const response = await this.post(`${endpoint}/questions-resolve`, { ids: ids });
        return await this.handleResponse<QuestionListResponse>(response);
    }

    public static async deleteQuestions(ids: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/questions-delete`, { ids: ids });
        return await this.handleVoidResponse(response);
    }

    public static async postQuestion(accountId: string, body: CreateQuestion): Promise<Question> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/questions`, body);
        return await this.handleResponse<Question>(response);
    }

    public static async patchQuestion(accountId: string, question_id: string, body: UpdateQuestion): Promise<Question> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/questions/${question_id}`, body);
        return await this.handleResponse<Question>(response);
    }

    public static async fetchQuestionsNotInGroup(accountId: string, groupId: string): Promise<QuestionListResponse> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/groups/${groupId}/questions`);
        return await this.handleResponse<QuestionListResponse>(response);
    }

    public static async fetchQuestion(accountId: string, question_id: string): Promise<Question> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/questions/${question_id}`);
        return await this.handleResponse<Question>(response);
    }

    public static async deleteQuestion(accountId: string, question_id: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/questions/${question_id}`);
        return await this.handleVoidResponse(response);
    }

    public static async postIoTSeed(accountId: string, iotId: string, body: { account_id: string }): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots/${iotId}/seed`, body);
        return this.handleResponse(response);
    }
}
