import { Logger } from '@frontend/Logger';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface FormInfo {
    value: { [key: string]: any };
    submitted: boolean;
}
export interface FormState {
    [key: string]: FormInfo;
}
const initialState: FormState = {};

const initialFormState: FormInfo = {
    value: {},
    submitted: false
};

export const formSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {
        initForm(state, action: PayloadAction<{ formId: string; value: any }>) {
            if (state[action.payload.formId] && state[action.payload.formId].value) {
                Logger.warn('form already initialized - skipping init to prevent overwrite', {}, { formId: action.payload.formId });
            } else {
                state[action.payload.formId] = { ...initialFormState, value: action.payload.value };
            }
        },
        changeSubmitted(state, action: PayloadAction<{ formId: string; value: boolean }>) {
            if (state[action.payload.formId] && state[action.payload.formId].value) {
                state[action.payload.formId].submitted = action.payload.value;
            }
        },
        updateField(state, action: PayloadAction<{ formId: string; key: string; value: any }>) {
            if (state[action.payload.formId] && state[action.payload.formId].value) {
                state[action.payload.formId].value[action.payload.key] = action.payload.value;
            }
        },
        updateForm(state, action: PayloadAction<{ formId: string; value: any }>) {
            if (state[action.payload.formId] && state[action.payload.formId].value) {
                state[action.payload.formId].value = action.payload.value;
            }
        }
    }
});

export const formStore = { forms: formSlice.reducer };
export const { initForm, updateField, changeSubmitted, updateForm } = formSlice.actions;
