import { BUTTON_ICONS } from './button-icons';
import { ENTITY_ICONS } from './entity-icons';
import { NAVIGATION_ICONS } from './navigation-icons';

export const ICONS = {
    ORGANIZATION: ENTITY_ICONS,
    ENTITY: ENTITY_ICONS,
    BUTTON: BUTTON_ICONS,
    NAVIGATION: NAVIGATION_ICONS
};
