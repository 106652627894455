import { EntityType } from "@frontend/common";
import { ModuleTemplateClient } from "@frontend/module/api";
import { useResolver } from "@frontend/repository";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import React from "react";

declare global {
    interface WindowEventMap {
        'useModuleTemplate:request': CustomEvent<string>;
    }
}

const REQUEST_EVENT = 'useModuleTemplate:request';
const STORAGE_KEY = 'MDL_TMP_REQ';

export function moduleTemplateRequest(id: string): string {
    window.dispatchEvent(new CustomEvent(REQUEST_EVENT, { detail: id }));
    return STORAGE_KEY + '_loading';
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function ModuleTemplateResolver(props: Props) {
    useResolver(STORAGE_KEY, REQUEST_EVENT, EntityType.TEMPLATE, (ids) => ModuleTemplateClient.resolveModuleTemplates(ids), props.dispatch);
    return <></>;
}