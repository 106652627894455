import React from "react";
import { FormattedMessage } from "react-intl";

export enum UserUniqueIdentifierValue {
    EMAIL = 'email',
    EMPLOYEE_NUMBER = 'employee_number',
}

export class UserUniqueIdentifier {
    static readonly EMAIL = new UserUniqueIdentifier(UserUniqueIdentifierValue.EMAIL, (
        <FormattedMessage
            id='UserUniqueIdentifier.email'
            description='The displayed value for the UserUniqueIdentifier email'
            defaultMessage='Email'
        />
    ));
    static readonly EMPLOYEE_NUMBER = new UserUniqueIdentifier(UserUniqueIdentifierValue.EMPLOYEE_NUMBER, (
        <FormattedMessage
            id='UserUniqueIdentifier.employee_number'
            description='The displayed value for the UserUniqueIdentifier employee_number'
            defaultMessage='Employee number'
        />
    ));

    static readonly ALL = [UserUniqueIdentifier.EMAIL, UserUniqueIdentifier.EMPLOYEE_NUMBER];

    private constructor(public readonly value: UserUniqueIdentifierValue, public readonly displayName: React.ReactNode) {}
}
