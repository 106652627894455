import ReactApexChart from 'react-apexcharts';

import useConsumption from './consumption.controller';

export interface ConsumptionProps {
    product_id?: string | null;
    user_id?: string | null;
}

const Consumption = (props: ConsumptionProps) => {
    const viewProps = useConsumption(props);

    return (
        <div>
            <div className='chart'>
                <ReactApexChart
                    options={viewProps.options}
                    series={viewProps.data}
                    type='area'
                    height={400}
                />
            </div>
        </div>
    );
};

export default Consumption;
