import { RoleTemplateClient } from '@frontend/role/api';
import { PredefinedRoleTemplate } from '@frontend/role/types';
import { useEffect, useMemo, useState } from 'react';
import { PredefinedRoleSelectProps } from './predefined-role-select.component';

interface ViewProps {
    name: string | null;
    changeName: React.Dispatch<React.SetStateAction<string | null>>;
    names: { label: string; value: string }[] | null;
}

const usePredefinedRoleSelect = (props: PredefinedRoleSelectProps): ViewProps => {
    const [roleTemplates, changeRoleTemplates] = useState<PredefinedRoleTemplate | null>(null);
    const [name, changeName] = useState<string | null>(null);
    const names = useMemo(() => {
        if (roleTemplates === null) return null;
        if (props.item == null) return null;
        return roleTemplates[props.item as string].map((value) => {
            return {
                label: value,
                value: value
            }
        });
    }, [roleTemplates, props.item]);

    useEffect(() => {
        RoleTemplateClient.fetchPredefinedRoleTemplates().then(changeRoleTemplates);
    }, []);

    useEffect(() => {
        if (props.onChange) {
            if(props.item == null) props.onChange(null);
            else props.onChange(name);
        }
    }, [props.item, name])

    return {
        name,
        changeName,
        names
    };
};

export default usePredefinedRoleSelect;
