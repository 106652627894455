
import { EventListener } from '@frontend/pub-sub';
import { PlaylistItem } from '@frontend/playlist/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

export class PlaylistItemEventListener extends EventListener<PlaylistItem> {
    
    private static instance: PlaylistItemEventListener | null = null;
    private constructor(dispatch: ThunkDispatch<any, any, Action>) {
        super('playlist', dispatch);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>): PlaylistItemEventListener {
        if (this.instance == null) {
            this.instance = new PlaylistItemEventListener(dispatch);
        }
        return this.instance;
    }

    getInstance(dispatch: ThunkDispatch<any, any, Action>): EventListener<PlaylistItem> {
        return PlaylistItemEventListener.getInstance(dispatch);
    }

    protected onUpdate() {}

    protected onDelete() {}

    protected onCreate() {}
}
