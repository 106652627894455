import { EntityType } from '@frontend/common';
import { PlaylistClient } from '@frontend/playlist/api';
import { useResolver } from '@frontend/repository';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

declare global {
    interface WindowEventMap {
        'usePlaylist:request': CustomEvent<string>;
    }
}

const REQUEST_PLAYLIST_EVENT = 'usePlaylist:request';
const STORAGE_KEY = 'PLYLST_REQ';

export function playlistRequest(playlistId: string): string {
    window.dispatchEvent(new CustomEvent(REQUEST_PLAYLIST_EVENT, { detail: playlistId }));
    return STORAGE_KEY + '_loading';
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function PlaylistResolver(props: Props) {
    useResolver(STORAGE_KEY, REQUEST_PLAYLIST_EVENT, EntityType.PLAYLIST, (ids) => PlaylistClient.resolvePlaylists(ids), props.dispatch);
    return <></>;
}
