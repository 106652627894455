import { APIClient } from "@frontend/api-utils";
import { ProblemListResponse } from "@frontend/problem/types";


const endpoint  = '/problem-api/v1'
export class ProblemEquipmentClient extends APIClient {
    public static async fetchEquipmentProblems(accountId: string, equipmentId: string): Promise<ProblemListResponse> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/equipments/${equipmentId}/problems`);
        return await this.handleResponse<ProblemListResponse>(response);
    }
}