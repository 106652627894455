import { Location } from '@frontend/common';
import React from 'react';

import { NumberInput } from '../number-input/number-input.component';
import useLocationInput from './location-input.controller';

const ID = 'location-input';
export interface LocationInputProps {
    id?: string;
    label?: string;
    className?: string;
    required?: boolean;
    submitted: boolean;
    onChange: (newValue: Location) => void;
    value?: Location;
}

export const LocationInput = (props: LocationInputProps) => {
    const viewProps = useLocationInput(props);

    return (
        <form
            id={ID}
            className='d-flex flex-column w-100'>
            <h6 className='ps-1 mt-2 mb-0'>{props.label}</h6>
            <div className='d-flex flex-row w-100'>
                <div className='w-25 m-1'>
                    <NumberInput
                        id='x'
                        label='x'
                        required={props.required}
                        min={0}
                        submitted={props.submitted}
                        value={viewProps.location.x}
                        onChange={(value) => {
                            viewProps.changeLocation({ ...viewProps.location, x: value });
                        }}
                    />
                </div>
                <div className='w-25 m-1'>
                    <NumberInput
                        id='y'
                        label='y'
                        required={props.required}
                        min={0}
                        submitted={props.submitted}
                        value={viewProps.location.y}
                        onChange={(value) => {
                            viewProps.changeLocation({ ...viewProps.location, y: value });
                        }}
                    />
                </div>
                <div className='w-25 m-1'>
                    <NumberInput
                        id='z'
                        label='z'
                        required={props.required}
                        min={0}
                        submitted={props.submitted}
                        value={viewProps.location.z}
                        onChange={(value) => {
                            viewProps.changeLocation({ ...viewProps.location, z: value });
                        }}
                    />
                </div>
                <div className='w-25 m-1'>
                    <NumberInput
                        id='rotation'
                        label='rotation'
                        required={props.required}
                        min={0}
                        max={359}
                        submitted={props.submitted}
                        value={viewProps.location.rotation}
                        onChange={(value) => {
                            viewProps.changeLocation({ ...viewProps.location, rotation: value });
                        }}
                    />
                </div>
            </div>
        </form>
    );
};
