import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

const QuestionGroupCreate = lazy(() => import('../question-group/question-group-create/question-group-create.component'));
const QuestionGroupDetail = lazy(() => import('../question-group/question-group-detail/question-group-detail.component'));
const QuestionGroupOverview = lazy(() => import('../question-group/question-group-overview'));
const QuestionGroupUpdate = lazy(() => import('../question-group/question-group-update/question-group-update.component'));
const QuestionGroupMemberAdd = lazy(() => import('../question-group/question-group-member-add/question-group-member-add.component'));
const QuestionGroupMemberUpdate = lazy(
    () => import('../question-group/question-group-detail/question-group-member-update/question-group-member-update.component')
);

export function QuestionGroupRoutes() {
    return (
        <Route path='questionnaires'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <QuestionGroupOverview />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <QuestionGroupCreate />
                    </AsyncComponent>
                }
            />
            <Route path=':groupId'>
                <Route
                    index
                    path='detail'
                    element={
                        <AsyncComponent>
                            <QuestionGroupDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <QuestionGroupUpdate />
                        </AsyncComponent>
                    }
                />
                <Route path='members'>
                    <Route
                        path='add'
                        element={
                            <AsyncComponent>
                                <QuestionGroupMemberAdd />
                            </AsyncComponent>
                        }
                    />
                    <Route path=':memberId'>
                        <Route
                            path='update'
                            element={<QuestionGroupMemberUpdate />}
                        />
                    </Route>
                </Route>
            </Route>
            <Route
                index
                element={<Navigate to='/admin/questionnaires' />}
            />
        </Route>
    );
}
