import { APIClient, ApiQueryParams } from "@frontend/api-utils";
import { Token } from "@frontend/authentication-v2";
import { MaintainEquipmentListResponse, MaintainEquipmentQueryParams } from "@frontend/equipment/types";

const endpoint = '/equipment-api/v1';
export class EquipmentMaintainClient extends APIClient {
    public static async fetchMaintainEquipments(accountId: string, spotId: string, queryParams?: ApiQueryParams<MaintainEquipmentQueryParams>, withCredentials?: Token): Promise<MaintainEquipmentListResponse> {
        return await this.apiPaginated<MaintainEquipmentListResponse, MaintainEquipmentQueryParams>(`${endpoint}/accounts/${accountId}/spots/${spotId}/maintain-equipments`, queryParams, withCredentials);
    }

}