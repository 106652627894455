import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { Token } from '@frontend/authentication-v2';
import { CreateDocument, CreateDocumentFile, Document, DocumentListResponse, DocumentQueryParams, UpdateDocument } from '@frontend/document/types';

const endpoint = '/document-api/v1';
export class DocumentClient extends APIClient {
    public static async fetchDocuments(queryParams?: ApiQueryParams<DocumentQueryParams>): Promise<DocumentListResponse> {
        return await this.apiPaginated<DocumentListResponse, DocumentQueryParams>(`${endpoint}/documents`, queryParams);
    }

    public static async resolveDocuments(ids: string[], field: keyof Document = 'id'): Promise<DocumentListResponse> {
        const response = await this.post(`${endpoint}/documents-resolve`, { field, ids: ids });
        return await this.handleResponse<DocumentListResponse>(response);
    }

    public static async deleteDocuments(ids: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/documents-delete`, { ids: ids });
        return await this.handleVoidResponse(response);
    }

    public static async fetchAccountDocuments(accountId: string, queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<DocumentListResponse> {
        return await this.apiPaginated<DocumentListResponse, DefaultQueryParams>(`${endpoint}/accounts/${accountId}/documents`, queryParams);
    }

    public static async postDocument(accountId: string, body: CreateDocument): Promise<Document> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/documents`, body);
        return await this.handleResponse<Document>(response);
    }

    public static async fetchAccountDocument(accountId: string, documentId: string): Promise<Document> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/documents/${documentId}`);
        return await this.handleResponse<Document>(response);
    }

    public static async patchDocument(accountId: string, documentId: string, body: UpdateDocument): Promise<Document> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/documents/${documentId}`, body);
        return await this.handleResponse<Document>(response);
    }

    public static async deleteDocument(accountId: string, documentId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/documents/${documentId}`);
        return await this.handleVoidResponse(response);
    }

    // Document file
    public static async postDocumentFile(accountId: string, document_id: string, body: CreateDocumentFile): Promise<Document> {
        const formData = new FormData();
        formData.append('file', body.file!);
        const response = await this.post(`${endpoint}/accounts/${accountId}/documents/${document_id}/file`, formData);
        return await this.handleResponse<Document>(response);
    }

    public static async fetchDocumentFile(accountId: string, document_id: string, withCredentials?: Token): Promise<Blob> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/documents/${document_id}/file`, {}, true, withCredentials);
        return await this.handleBlobResponse(response);
    }

    public static async fetchDocumentSasUrl(accountId: string, document_id: string): Promise<string> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/documents/${document_id}/file-sas`);
        return await this.handleResponse<string>(response);
    }

    public static async generateDocumentSasUrl(accountId: string, document_id: string): Promise<string> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/documents/${document_id}/generate-file-sas-url`);
        return await this.handleResponse<string>(response);
    }

    //Sync
    public static async postIoTSeed(accountId: string, iotId: string, body: { account_id: string }): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots/${iotId}/seed`, body);
        return this.handleResponse(response);
    }
}
