import React from 'react';

import { HorizontalButtonGroupButton } from '../../buttons/horizontal-button-group/horizontal-button-group.component';
import { Spinner } from '../../spinner.component';

const ID = 'modal-footer';
export interface ModalFooterProps {
    buttons: HorizontalButtonGroupButton[];
    justifyContent?: 'start' | 'end' | 'between' | 'around';
}

export const ModalFooter = (props: ModalFooterProps) => {
    return (
        <div
            id={ID}
            className={`modal-footer d-flex justify-content-${props.justifyContent ? props.justifyContent : 'start'}`}>
            {props.buttons.map((button, i) => {
                if (button.hide === true) return null;
                return (
                    <button
                        id={button.id}
                        onClick={button.onClick}
                        className={'my-0 btn ' + (button.type ? 'btn-' + button.type : 'btn-secondary')}
                        key={i}
                        type={button.buttonType || 'button'}
                        disabled={button.disabled}>
                        <div className='d-flex flex-row align-items-center'>
                            {button.isLoading ? (
                                <Spinner />
                            ) : (
                                <>
                                    {button.icon && <button.icon />}
                                    <span className={button.icon ? 'ps-2' : ''}>{button.text}</span>
                                </>
                            )}
                        </div>
                    </button>
                );
            })}
        </div>
    );
};
