import { ApiError } from '@frontend/api-utils';
import { EmailInput, PasswordInput } from '@frontend/basic-forms';
import { BrandingClient } from '@frontend/branding/api';
import { Branding } from '@frontend/branding/types';
import { ErrorHandler } from '@frontend/error-handler';
import { useEffect, useMemo, useState } from 'react';

import defaultLogo from '../../../../assets/images/telloport_logo_horizontal.png';
import useBasicLogin from './basic-login.controller';

export const ID = 'basic-login';
export interface Props {
    callback?: () => void;
}

const BasicLogin = (props: Props) => {
    const viewProps = useBasicLogin(props);
    const [branding, changeBranding] = useState<Branding | null>(null);
    const [isLoading, changeIsLoading] = useState(true);
    useEffect(() => {
        changeIsLoading(true);
        BrandingClient.fetchBrandingByDomain(window.location.hostname)
            .then((res) => {
                changeBranding(res);
                changeIsLoading(false);
            })
            .catch((e) => {
                if ((e as ApiError).code !== 404) {
                    ErrorHandler.handleError(e);
                }
                changeIsLoading(false);
            });
    }, []);

    const logo = useMemo(() => {
        if (isLoading) return '';
        if (branding?.logo_navbar_expanded) return branding.logo_navbar_expanded;
        return defaultLogo;
    }, [branding, isLoading]);
    return (
        <div
            id={ID}
            className='d-flex flex-column justify-content-center align-items-center m-5'>
            <form
                className='card w-50'
                onSubmit={viewProps.onSubmit}>
                <div className='card-header text-center'>
                    <img
                        src={logo}
                        className='img-fluid w-25'
                    />
                    <h4 className='m-3'>Sign in</h4>
                </div>
                <div className='card-body d-flex flex-column align-items-center w-100'>
                    <EmailInput
                        className='w-100'
                        required
                        label='Email'
                        errorMessage='Please fill in a valid email.'
                        autoFocus
                        value={viewProps.formValues.email}
                        onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, email: value })}
                        isValidCallback={(value) => {
                            if (viewProps.submitted) viewProps.changeEmailValid(value);
                        }}
                        submitted={viewProps.submitted}
                        valid={viewProps.emailValid}
                        noConditionedStyling
                    />
                    <PasswordInput
                        className='w-100'
                        required
                        label='Password'
                        errorMessage='Please fill in your password.'
                        value={viewProps.formValues.password}
                        onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, password: value })}
                        isValidCallback={(value) => {
                            if (viewProps.submitted) viewProps.changePasswordValid(value);
                        }}
                        noConditionedStyling
                        valid={viewProps.passwordValid}
                    />
                </div>

                <div className='card-footer text-center'>
                    <div className='col-12'>
                        <button
                            type='submit'
                            className='btn btn-primary'
                            disabled={!viewProps.emailValid || !viewProps.passwordValid || viewProps.submitted}>
                            Sign in
                        </button>
                    </div>
                    <div className='col-12'>
                        <span
                            onClick={() => viewProps.navigate('/forgot-password')}
                            className='ms-4 cursor-pointer font-weight-bolder'>
                            Forgot password
                        </span>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default BasicLogin;
