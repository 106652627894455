import { EntityType } from '@frontend/common';
import { DocumentClient, DocumentEntityClient } from '@frontend/document/api';
import { Document, DocumentEntity } from '@frontend/document/types';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

const DOCUMENT_RESOLVE_KEY = 'dcm_res';
interface DocumentStore {
    [entity_type: string]: {
        [entity_id: string]: EntityDocumentsService;
    };
}

const DocumentStoreContext = createContext<{
    store: DocumentStore;
    preLoadFiles: (entityType: EntityType, entityIds: string[]) => Promise<void>;
    resolveFile: (entityType: EntityType, entityId: string) => Promise<EntityDocumentsService>;
    getFiles: (entityType: EntityType, entityId: string) => Promise<EntityDocumentsService>;
} | null>(null);

export const DocumentStoreProvider = (props: { children: React.ReactNode }) => {
    const [store, changeStore] = useState<DocumentStore>({});
    const storeRef = useRef(store);
    const [pending, changePending] = useState<{ entityType: EntityType; entityId: string }[]>([]);
    const [timeoutSet, changeTimeoutSet] = useState<boolean>(false);
    const [timeoutFinished, changeTimeoutFinished] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            for (const entity_type in store) {
                for (const entity_id in store[entity_type]) {
                    store[entity_type][entity_id].revokeURLs();
                }
            }
        };
    }, []);

    useEffect(() => {
        storeRef.current = store;
    }, [store]);

    useEffect(() => {
        if (timeoutFinished) {
            const stored = localStorage.getItem(DOCUMENT_RESOLVE_KEY);
            if (stored != null) {
                changePending(JSON.parse(stored));
                changeTimeoutSet(false);
                changeTimeoutFinished(false);
                localStorage.removeItem(DOCUMENT_RESOLVE_KEY);
            }
        }
    }, [timeoutFinished]);

    useEffect(() => {
        if (pending.length > 0) {
            const toResolve = new Map<EntityType, string[]>();
            pending.forEach((p) => {
                if (!toResolve.has(p.entityType)) {
                    toResolve.set(p.entityType, []);
                }
                toResolve.get(p.entityType)?.push(p.entityId);
            });
            Array.from(toResolve.keys()).forEach((entityType) => {
                const entityIds = toResolve.get(entityType);
                if (entityIds) {
                    preLoadFiles(entityType, entityIds);
                }
            });
            changePending([]);
            changeTimeoutSet(false);
            changeTimeoutFinished(false);
        }
    }, [pending]);

    const preLoadFiles = async (entityType: EntityType, entityIds: string[]): Promise<void> => {
        const storeCopy = { ...store };
        if (!storeCopy[entityType]) {
            storeCopy[entityType] = {};
        }
        const newDocumentEntities: DocumentEntity[] = (await DocumentEntityClient.resolveDocumentEntities(entityIds, 'entity_id')).results;
        const newDocuments: Document[] = (await DocumentClient.resolveDocuments(newDocumentEntities.map((de) => de.document_id))).results;
        entityIds.forEach((entityId) => {
            const documentEntities = newDocumentEntities.filter((e) => e.entity_id === entityId);
            const documents: Document[] = [];
            documentEntities.forEach((de) => {
                const doc = newDocuments.find((d) => d.id === de.document_id);
                if (doc) documents.push(doc);
            });
            if (!storeCopy[entityType][entityId]) storeCopy[entityType][entityId] = new EntityDocumentsService({ documentEntities, documents });
            else {
                storeCopy[entityType][entityId].setDocumentEntities(documentEntities);
                storeCopy[entityType][entityId].setDocuments(documents);
            }
        });
        changeStore(storeCopy);
        return;
    };

    const resolveFile = async (entityType: EntityType, entityId: string): Promise<EntityDocumentsService> => {
        if (store[entityType] && store[entityType][entityId]) {
            return Promise.resolve(store[entityType][entityId]);
        }

        if (timeoutSet === false) {
            changeTimeoutSet(true);
            setTimeout(() => {
                changeTimeoutFinished(true);
            }, 1000);
        }
        const stored = localStorage.getItem(DOCUMENT_RESOLVE_KEY);
        if (stored == null) {
            localStorage.setItem(DOCUMENT_RESOLVE_KEY, JSON.stringify([{ entityType, entityId }]));
        } else if (!JSON.parse(stored).includes({ entityType, entityId })) {
            localStorage.setItem(DOCUMENT_RESOLVE_KEY, JSON.stringify([...JSON.parse(stored), { entityType, entityId }]));
        }

        return new Promise((resolve) => {
            const interval = setInterval(() => {
                if (storeRef.current[entityType] && storeRef.current[entityType][entityId]) {
                    resolve(storeRef.current[entityType][entityId]);
                    clearInterval(interval);
                }
            }, 500);
        });
    };

    const getFiles = async (entityType: EntityType, entityId: string): Promise<EntityDocumentsService> => {
        if (store[entityType] && store[entityType][entityId]) {
            return Promise.resolve(store[entityType][entityId]);
        }
        const documentEntities = (await DocumentEntityClient.resolveDocumentEntities([entityId])).results;
        const documents = (await DocumentClient.resolveDocuments(documentEntities.map((de) => de.document_id))).results;
        const service = new EntityDocumentsService({ documentEntities, documents });
        const storeCopy = { ...store, [entityType]: { ...store[entityType], [entityId]: service } };
        changeStore(storeCopy);
        return Promise.resolve(service);
    };

    return <DocumentStoreContext.Provider value={{ store, preLoadFiles, resolveFile, getFiles }}>{props.children}</DocumentStoreContext.Provider>;
};

export const useDocumentRepository = () => {
    const context = useContext(DocumentStoreContext);
    if (!context) {
        throw new Error('useDocumentRepository must be used within an DocumentStoreContext');
    }
    return context;
};

interface EntityDocuments {
    documentEntities: DocumentEntity[];
    documents: DocumentInfo[];
}

interface DocumentInfo extends Document {
    blob?: Blob;
    url?: string;
    sasUrl?: string;
}

class EntityDocumentsService {
    private entityDocuments: EntityDocuments;
    constructor(entityDocuments: EntityDocuments) {
        this.entityDocuments = entityDocuments;
    }

    public getDocumentEntities(): DocumentEntity[] {
        return this.entityDocuments.documentEntities;
    }

    public setDocumentEntities(documentEntities: DocumentEntity[]) {
        this.entityDocuments.documentEntities = documentEntities;
    }

    public getDocuments(): Document[] {
        return this.entityDocuments.documents;
    }

    public setDocuments(documents: Document[]) {
        const documentsCopy: Document[] = [...this.entityDocuments.documents];
        documents.forEach((newDocument) => {
            const found = documentsCopy.find((d) => d.id === newDocument.id);
            if (found) {
                if (found.update_timestamp != newDocument.update_timestamp) {
                    const index = documentsCopy.indexOf(found);
                    documentsCopy[index] = newDocument;
                }
            } else documentsCopy.push(newDocument);
        });
        this.entityDocuments.documents = documentsCopy;
    }

    revokeURLs(): void {
        this.entityDocuments.documents.forEach((document) => {
            if (!!document && !!document.url) {
                URL.revokeObjectURL(document.url);
            }
        });
    }
    revokeURL(documentId: string): void {
        const document = this.entityDocuments.documents?.find((f) => f.id === documentId);
        if (!!document && !!document.url) {
            URL.revokeObjectURL(document.url);
        }
    }
    async getUrl(documentId: string): Promise<string> {
        const document = this.entityDocuments.documents?.find((f) => f.id === documentId);
        if (!document) throw new Error('Document not found');
        if (document.url) return document.url;
        else {
            const index = this.entityDocuments.documentEntities?.findIndex((de) => de.document_id === document.id);
            const result = await DocumentClient.fetchDocumentFile(document.account_id, document.id).then((res) => {
                const url = URL.createObjectURL(res);
                this.entityDocuments.documents[index] = {
                    ...document,
                    blob: res,
                    url: url
                };
                return url;
            });
            return result;
        }
    }

    async getBlob(documentId: string): Promise<Blob> {
        const document = this.entityDocuments.documents?.find((f) => f.id === documentId);
        if (!document) throw new Error('Document not found');
        if (document.blob) return document.blob;
        else {
            const index = this.entityDocuments.documentEntities?.findIndex((de) => de.document_id === document.id);
            const blob = await DocumentClient.fetchDocumentFile(document.account_id, document.id).then((res) => {
                this.entityDocuments.documents[index] = {
                    ...document,
                    blob: res,
                    url: URL.createObjectURL(res)
                };
                return res;
            });
            return blob;
        }
    }

    /**
     * @todo not implemented yet
     */
    async getSasUrl(documentId: string): Promise<string> {
        throw new Error('Not implemented yet');
    }
}
