import { APIClient } from '@frontend/api-utils';
import { HeatmapChartData } from '@frontend/chart/types';

const endpoint = '/product-api/v1';

export class ProductChartClient extends APIClient {
    public static async fetchAvailableSlots(accountId: string, spotId: string, moduleId: string, productId: string): Promise<HeatmapChartData> {
        const url = `${endpoint}/accounts/${accountId}/spots/${spotId}/modules/${moduleId}/products/${productId}/available-slots`;
        const response = await this.fetch(url);
        return await this.handleResponse<HeatmapChartData>(response);
    }
}
