import { ClassType, EntityType, calculateTimeDifferenceBetweenDates } from '@frontend/common';
import { Dropdown, DropdownItem, Spinner, StatusBadge } from '@frontend/elements';
import { EquipmentReportClient } from '@frontend/equipment/api';
import { EquipmentStateName, IEquipmentHistoricalUse } from '@frontend/equipment/types';
import { ExportClient } from '@frontend/export/api';
import { FormatTypeName } from '@frontend/impex/types';
import { CommonMessage } from '@frontend/lang';
import { ModuleBadge } from '@frontend/module/badge';
import { ObjectList } from '@frontend/rendering/list';
import { SlotBadge } from '@frontend/slot/badge';
import { SpotBadge } from '@frontend/spot/badge';
import { FormattedMessage } from 'react-intl';

import useEquipmentHistoricalUse from './equipment-historical-use.controller';

const EquipmentHistoricalUse = () => {
    const viewProps = useEquipmentHistoricalUse();
    if (!viewProps.currentAccount) return <Spinner />;
    return (
        <ObjectList<IEquipmentHistoricalUse>
            fetch={(arg) => EquipmentReportClient.fetchEquipmentHistoryReport(viewProps.currentAccount!, arg)}
            dispatch={viewProps.dispatch}
            type={EntityType.EQUIPMENT}
            label={
                <FormattedMessage
                    id='equipment-historical-use.label'
                    defaultMessage='Equipment Usage Report'
                    description='Label for the Equipment Usage Report'
                />
            }
            columns={{
                columns: [
                    'withdraw_user_id',
                    'withdraw_timestamp',
                    'withdraw_location',
                    'return_timestamp',
                    'duration',
                    'return_location',
                    'product_name',
                    'equipment_id',
                    'return_state'
                ],
                customCells: new Map([
                    [
                        'return_state',
                        (o) => (
                            <td>
                                <StatusBadge<EquipmentStateName>
                                    value={o.row.original.return_state}
                                    colorMap={
                                        new Map([
                                            [[EquipmentStateName.EQUIPMENT_CHARGING], ClassType.ALERT],
                                            [[EquipmentStateName.EQUIPMENT_CREATED], ClassType.SUCCESS]
                                        ])
                                    }
                                />
                            </td>
                        )
                    ],
                    [
                        'withdraw_location',
                        (o) => (
                            <td className='align-items-between'>
                                <SpotBadge id={o.row.original.withdraw_spot_id ?? undefined} />
                                <ModuleBadge id={o.row.original.withdraw_module_id ?? undefined} />
                                <SlotBadge id={o.row.original.withdraw_slot_id ?? undefined} />
                            </td>
                        )
                    ],
                    [
                        'return_location',
                        (o) => (
                            <td className='align-items-between'>
                                <SpotBadge id={o.row.original.return_spot_id ?? undefined} />
                                <ModuleBadge id={o.row.original.return_module_id ?? undefined} />
                                <SlotBadge id={o.row.original.return_slot_id ?? undefined} />
                            </td>
                        )
                    ],
                    [
                        'duration',
                        (o) => (
                            <td className='text-xs text-primary'>
                                {o.row.original.withdraw_timestamp && o.row.original.return_timestamp
                                    ? calculateTimeDifferenceBetweenDates(o.row.original.withdraw_timestamp, o.row.original.return_timestamp)
                                    : null}
                            </td>
                        )
                    ]
                ])
            }}
            filter={{
                default: true,
                keys: ['withdraw_timestamp', 'return_timestamp', 'user_id', 'product_id'],
                queryParamKeyOverwrite: new Map([
                    ['withdraw_timestamp', 'after'],
                    ['return_timestamp', 'before']
                ])
            }}
            customHeaderComponent={
                <Dropdown label={CommonMessage.BUTTONS.EXPORT as any}>
                    {Object.values(FormatTypeName)
                        .filter((k) => k !== FormatTypeName.JSON)
                        .map((key) => (
                            <DropdownItem
                                key={key}
                                label={key.toLowerCase().replace(/_/g, ' ')}
                                onClick={() =>
                                    ExportClient.exportEquipmentHistoricalUse(viewProps.currentAccount!, 'equipment-historical-use', { format_type: key })
                                }
                            />
                        ))}
                </Dropdown>
            }
        />
    );
};

export default EquipmentHistoricalUse;
