import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { CreatePlaylistItem, PlaylistItem, PlaylistItemListResponse, PlaylistItemQueryParams, UpdatePlaylistItem } from '@frontend/playlist/types';

const endpoint = '/spot-api/v1';

export class PlaylistItemClient extends APIClient {
    public static async fetchPlaylistItems(queryParams?: ApiQueryParams<DefaultQueryParams & PlaylistItemQueryParams>): Promise<PlaylistItemListResponse> {
        return await this.apiPaginated<PlaylistItemListResponse, DefaultQueryParams & PlaylistItemQueryParams>(`${endpoint}/playlist-items`, queryParams);
    }

    public static async deletePlaylistItems(ids: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/playlist-items-delete`, { ids: ids });
        return await this.handleVoidResponse(response);
    }

    public static async postPlaylistItem(accountId: string, playlistId: string, playlistItem: CreatePlaylistItem): Promise<PlaylistItem> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/playlists/${playlistId}/playlist-items`, playlistItem);
        return await this.handleResponse<PlaylistItem>(response);
    }

    public static async fetchPlaylistItem(accountId: string, playlistId: string, playlistItemId: string): Promise<PlaylistItem> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/playlists/${playlistId}/playlist-items/${playlistItemId}`);
        return await this.handleResponse<PlaylistItem>(response);
    }

    public static async patchPlaylistItem(accountId: string, playlistId: string, playlistItemId: string, playlistItem: UpdatePlaylistItem): Promise<PlaylistItem> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/playlists/${playlistId}/playlist-items/${playlistItemId}`, playlistItem);
        return await this.handleResponse<PlaylistItem>(response);
    }

    public static async deletePlaylistItem(accountId: string, playlistId: string, playlistItemId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/playlists/${playlistId}/playlist-items/${playlistItemId}`);
        return await this.handleVoidResponse(response);
    }
}
