import { EventListener } from '@frontend/pub-sub';
import { Question } from '@frontend/question/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

enum MessageTypes {
    UPDATED = 'question_updated',
    DELETED = 'question_deleted',
    CREATED = 'question_created'
}

export class QuestionEventListener extends EventListener<Question> {
    private static instance: QuestionEventListener | null = null;
    private constructor(dispatch: ThunkDispatch<any, any, Action>) {
        super('question', dispatch);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>): QuestionEventListener {
        if (this.instance == null) {
            this.instance = new QuestionEventListener(dispatch);
        }
        return this.instance;
    }

    getInstance(dispatch: ThunkDispatch<any, any, Action>): EventListener<Question> {
        return QuestionEventListener.getInstance(dispatch);
    }

    protected onUpdate() {}

    protected onDelete() {}

    protected onCreate() {}
}
