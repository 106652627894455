import { ApiQueryParams, DefaultQueryParams, PaginatedResponse } from '@frontend/api-utils';
import { MultiSelectInputV2 } from '@frontend/basic-forms';
import React from 'react';

import { UrlParams } from '../object-select-input-v2/object-select-input-v2.component';
import useMultiObjectSelectInputV2 from './multi-object-select-input-v2.controller';

export interface MultiObjectSelectInputPropsV2<T extends { id: string }, D extends string | number> {
    id?: string;
    label: React.ReactNode;
    helpMessage?: React.ReactNode;
    className?: string;

    required?: boolean;
    disabled?: boolean;
    hideOnOneOption?: boolean;
    hidden?: boolean;
    isClearable?: boolean;

    /**
     * @description Will check the current URL for a path param that matches this value.
     * If found (using the fetch function) the select will be disabled and the value will be set.
     * Otherwise the select will function as usual.
     * Do not use with useQueryParam (may cause unexpected behaviour)
     */
    useUrlOverwrite?: UrlParams<T>;
    /**
     * @description Will check the current URL for a query param that matches this value.
     * If found (using the fetch function) the value will be set.
     * When selecting a new value, the URL will be updated.
     * Do not use with useUrlOverwrite (may cause unexpected behaviour)
     */
    useQueryParam?: UrlParams<T>;
    onChange?: (value: T[]) => void;
    idValues?: string[];
    value: string[];
    searchValueOverwrite?: string;
    queryParams?: ApiQueryParams<D>;
    fetch: (queryParams?: ApiQueryParams<DefaultQueryParams | D>) => Promise<PaginatedResponse<T>>;
    singleFetch?: (id: string) => Promise<T>;
    itemLabel: (item: T) => string;
    disableFetch?: boolean;
    mapOptions?: keyof T;
    subset?: string[];
    onFocus?: React.FocusEventHandler<HTMLInputElement> | undefined;
    onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
    isOpenOverwrite?: boolean;
}

export const MultiObjectSelectInputV2 = <T extends { id: string }, D extends string | number>(props: MultiObjectSelectInputPropsV2<T, D>) => {
    const viewProps = useMultiObjectSelectInputV2(props);
    if (viewProps.hide) return <></>;

    return (
        <MultiSelectInputV2
            id={props.id}
            label={props.label}
            className={props.className}
            options={viewProps.options}
            onSelect={viewProps.onSelect}
            value={viewProps.value}
            isClearable={props.isClearable}
            disabled={props.disabled}
        />
    );
};
