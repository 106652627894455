import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { EntityType } from '@frontend/common';
import { CreateRole, MultiRoleCreate, Role, RoleListResponse, UpdateRole } from '@frontend/role/types';

const endpoint = '/authorization-api/v1';

export class RoleClient extends APIClient {
    public static async fetchRoles(queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<RoleListResponse> {
        return await this.apiPaginated<RoleListResponse, DefaultQueryParams>(`${endpoint}/roles`, queryParams);
    }

    public static async deleteRoles(ids: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/roles-delete`, { ids: ids });
        return await this.handleVoidResponse(response);
    }

    public static async fetchEntityRoles(
        accountId: string,
        entityType: EntityType,
        entityId: string,
        queryParams?: ApiQueryParams<DefaultQueryParams>
    ): Promise<RoleListResponse> {
        return await this.apiPaginated<RoleListResponse, DefaultQueryParams>(`${endpoint}/accounts/${accountId}/${entityType}s/${entityId}/roles`, queryParams);
    }

    public static async postRole(accountId: string, entityType: EntityType, entityId: string, role: CreateRole | MultiRoleCreate): Promise<Role> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/${entityType}s/${entityId}/roles`, role);
        return await this.handleResponse<Role>(response);
    }

    public static async fetchRole(accountId: string, entityType: EntityType, entityId: string, roleId: string): Promise<Role> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/${entityType}s/${entityId}/roles/${roleId}`);
        return await this.handleResponse<Role>(response);
    }

    public static async patchRole(accountId: string, entityType: EntityType, entityId: string, roleId: string, role: UpdateRole): Promise<Role> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/${entityType}s/${entityId}/roles/${roleId}`, role);
        return await this.handleResponse<Role>(response);
    }

    public static async deleteRole(accountId: string, entityType: EntityType, entityId: string, roleId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/${entityType}s/${entityId}/roles/${roleId}`);
        return await this.handleVoidResponse(response);
    }
}
