import React from 'react';

import { Spinner } from '../Spinner';
import { Permission } from '../permission';
import useAuthorizationWrapper from './authorization-wrapper.controller';

const ID = 'authorization-wrapper';
export interface AuthorizationWrapperProps {
    children: React.ReactNode;
    requiredPermissions?: Permission[];

    showLoading?: boolean;
    showUnauthorized?: boolean;
    loadingContent?: React.ReactNode;
    unauthorizedContent?: React.ReactNode;
    callback?: (authorized: boolean) => void;
}

export const AuthorizationWrapper = (props: AuthorizationWrapperProps) => {
    const viewProps = useAuthorizationWrapper(props);

    if (props.requiredPermissions === undefined) return props.children;

    if (viewProps.isLoading) {
        if (props.showLoading === false) return <></>;
        return props.loadingContent || <Spinner />;
    }
    if (!viewProps.authorized) {
        if (props.showUnauthorized === true) return props.unauthorizedContent || <>Not authorized</>;
        return <></>;
    }
    return props.children;
};
