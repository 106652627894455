import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

const EquipmentGroupCreate = lazy(() => import('../equipment-group/equipment-group-create/equipment-group-create.component'));
const EquipmentGroupDetail = lazy(() => import('../equipment-group/equipment-group-detail/equipment-group-detail.component'));
const EquipmentGroupOverview = lazy(() => import('../equipment-group/equipment-group-overview'));
const EquipmentGroupUpdate = lazy(() => import('../equipment-group/equipment-group-update/equipment-group-update.component'));
const EquipmentGroupMemberAdd = lazy(() => import('../equipment-group/equipment-group-member-add/equipment-group-member-add.component'));

export function EquipmentGroupRoutes() {
    return (
        <Route path='equipment-groups'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <EquipmentGroupOverview />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <EquipmentGroupCreate />
                    </AsyncComponent>
                }
            />
            <Route path=':groupId'>
                <Route
                    index
                    path='detail'
                    element={
                        <AsyncComponent>
                            <EquipmentGroupDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <EquipmentGroupUpdate />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='members/add'
                    element={
                        <AsyncComponent>
                            <EquipmentGroupMemberAdd />
                        </AsyncComponent>
                    }
                />
            </Route>
            <Route
                index
                element={<Navigate to='/admin/equipment-groups' />}
            />
        </Route>
    );
}
