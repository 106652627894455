import { ProblemEntity } from '@frontend/problem/types';
import { EventListener } from '@frontend/pub-sub';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

export class ProblemEntityEventListener extends EventListener<ProblemEntity> {
    private static instance: ProblemEntityEventListener | null = null;
    private constructor(dispatch: ThunkDispatch<any, any, Action>) {
        super('problem', dispatch);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>): ProblemEntityEventListener {
        if (this.instance == null) {
            this.instance = new ProblemEntityEventListener(dispatch);
        }
        return this.instance;
    }

    getInstance(dispatch: ThunkDispatch<any, any, Action>): EventListener<ProblemEntity> {
        return ProblemEntityEventListener.getInstance(dispatch);
    }

    protected onUpdate() {}
    protected onDelete() {}
    protected onCreate() {}
}
