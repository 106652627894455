import { EntityType } from '@frontend/common';
import { questionRequest } from '@frontend/question/events';
import { Question } from '@frontend/question/types';
import { ObjectBadge, ObjectBadgeProps } from '@frontend/rendering/badge';
import React from 'react';

export const QuestionBadge = (props: ObjectBadgeProps<Question>) => {
    return (
        <ObjectBadge<Question>
            id={props.id}
            type={EntityType.QUESTION}
            mapDisplayName={(eq: Question) => eq.question}
            resolve={questionRequest}
            navigateString={(object: Question) => `/accounts/${object.account_id}/questions/${object.id}`}
            {...props}
        />
    );
};
