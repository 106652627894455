import { attributeRequest } from '@frontend/attribute/events';
import { Attribute } from '@frontend/attribute/types';
import { EntityType } from '@frontend/common';
import { getCorrectTranslation, useLocale } from '@frontend/lang';
import { ObjectBadge, ObjectBadgeProps } from '@frontend/rendering/badge';
import React from 'react';

export const AttributeBadge = (props: ObjectBadgeProps<Attribute>) => {
    const { locale } = useLocale();

    if (!locale) {
        return null;
    }
    return (
        <ObjectBadge<Attribute>
            id={props.id}
            type={EntityType.ATTRIBUTE}
            mapDisplayName={(o: Attribute) => getCorrectTranslation(locale, o, 'name')}
            resolve={attributeRequest}
            key={props.id}
            navigateString={(object: Attribute) => `/accounts/${object.account_id}/attributes/${object.id}`}
            {...props}
        />
    );
};
