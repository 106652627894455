import React from 'react';
import { Link } from 'react-router-dom';

const ID = 'text-breadcrumb';
export interface TextBreadcrumbProps {
    value: string;
    path: string;
    highlighted?: boolean;
}

const TextBreadcrumb = (props: TextBreadcrumbProps) => {
    return (
        <li
            key={props.value}
            id={ID}
            className={`breadcrumb-item text-sm m-0 ${props.highlighted ? 'active' : ''} text-nowrap`}
            aria-current='page'>
            <Link
                className={`${props.highlighted ? '' : 'opacity-5'} text-white`}
                to={props.path}>
                {props.value}
            </Link>
        </li>
    );
};

export default TextBreadcrumb;
