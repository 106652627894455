import { APIClient } from '@frontend/api-utils';
import { Equipment, EquipmentStateTrigger, UpdateEquipmentState } from '@frontend/equipment/types';

const endpoint = '/equipment-api/v1';
export class EquipmentWorkflowClient extends APIClient {
    public static async updateEquipmentState(accountId: string, equipmentId: string, equipmentState: UpdateEquipmentState): Promise<Equipment> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/equipments/${equipmentId}/change-state`, equipmentState);
        return await this.handleResponse<Equipment>(response);
    }

    public static async postTriggerEquipmentState(accountId: string, equipmentId: string, trigger: EquipmentStateTrigger): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/equipments/${equipmentId}/trigger`, trigger);
        return await this.handleVoidResponse(response);
    }
}
