import { EntityType } from '@frontend/common';
import { packageRequest } from '@frontend/package/events';
import { Package } from '@frontend/package/types';
import { ObjectBadge, ObjectBadgeProps } from '@frontend/rendering/badge';
import React from 'react';

export const PackageBadge = (props: ObjectBadgeProps<Package>) => {
    return (
        <ObjectBadge<Package>
            id={props.id}
            type={EntityType.PACKAGE}
            mapDisplayName={(pack: Package) => pack.id}
            resolve={packageRequest}
            key={props.id}
            navigateString={(object: Package) => `/accounts/${object.account_id}/transactions/${object.transaction_id}/packages/${object.id}`}
            {...props}
        />
    );
};
