import { EntityType } from '@frontend/common';
import { QuestionnaireClient } from '@frontend/group/api';
import { useResolver } from '@frontend/repository';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

declare global {
    interface WindowEventMap {
        'useQuestionnaire:request': CustomEvent<string>;
    }
}

const REQUEST_QUESTIONNAIRE_EVENT = 'useQuestionnaire:request';
const STORAGE_KEY = 'QSTNR_REQ';

export function questionnaireRequest(groupId: string): string {
    window.dispatchEvent(new CustomEvent(REQUEST_QUESTIONNAIRE_EVENT, { detail: groupId }));
    return STORAGE_KEY + '_loading';
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function QuestionnaireResolver(props: Props) {
    useResolver(STORAGE_KEY, REQUEST_QUESTIONNAIRE_EVENT, EntityType.QUESTIONNAIRE, (ids) => QuestionnaireClient.resolveQuestionnaires(ids), props.dispatch);
    return <></>;
}
