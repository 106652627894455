import { EntityType } from '@frontend/common';
import { NotificationEmailClient } from '@frontend/notification-service/api';
import { useResolver } from '@frontend/repository';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

declare global {
    interface WindowEventMap {
        'useEmail:request': CustomEvent<string>;
    }
}

const REQUEST_CERTIFICATE_EVENT = 'useEmail:request';
const STORAGE_KEY = 'NOTIF_EML';

export function notificationEmailRequest(emailId: string): string {
    window.dispatchEvent(new CustomEvent(REQUEST_CERTIFICATE_EVENT, { detail: emailId }));
    return STORAGE_KEY + '_loading';
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function NotificationEmailResolver(props: Props) {
    useResolver(
        STORAGE_KEY,
        REQUEST_CERTIFICATE_EVENT,
        EntityType.CERTIFICATE,
        (ids) => NotificationEmailClient.resolveNotificationEmails(ids),
        props.dispatch
    );
    return <></>;
}
