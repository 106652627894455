import { FaCheck } from 'react-icons/fa';
import { MdError } from 'react-icons/md';

import useBasicSignUpConfirmation from './basic-sign-up-confirmation.controller';

const BasicSignUpConfirmation = () => {
    const viewProps = useBasicSignUpConfirmation();

    return (
        <div
            className='d-flex flex-column justify-content-center align-items-center'
            style={{ height: '100vh' }}>
            <div className='card w-50'>
                <div className='card-header d-flex justify-content-center'>
                    <h3>Confirming sign-up</h3>
                </div>
                <div className='card-body'>
                    <div className='d-flex flex-column align-items-center'>
                        {viewProps.response ? (
                            <ConfirmationComponent
                                submitted={viewProps.submitted}
                                response={viewProps.response}
                            />
                        ) : (
                            <button
                                className='btn btn-primary'
                                onClick={viewProps.onSubmit}>
                                Confirm
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BasicSignUpConfirmation;

export const ConfirmationComponent = (props: { submitted: boolean; response: any }) => {
    if (props.submitted && !props.response) {
        return <div className='spinner-border'></div>;
    } else if (props.response && props.response.type === 'error') {
        return (
            <>
                <MdError
                    size={30}
                    className='text-danger mb-2'
                />
                <strong>{props.response.message}</strong>
            </>
        );
    } else if (props.response && props.response.type === 'success') {
        return (
            <>
                <FaCheck
                    size={30}
                    className='text-success mb-2'
                />
                <strong>{props.response.message}</strong>
            </>
        );
    } else {
        return null;
    }
};
