import { APIClient, ApiQueryParams } from '@frontend/api-utils';
import { Branding, BrandingListResponse, BrandingQueryParams, CreateBranding, UpdateBranding } from '@frontend/branding/types';

const endpoint = '/account-api/v1';

export class BrandingClient extends APIClient {
    public static async fetchBrandingByDomain(domain: string): Promise<Branding> {
        return await this.fetchWithoutToken(`${endpoint}/domains/${domain}`);
    }

    public static async fetchBrandingByAccount(accountId: string): Promise<Branding> {
        return await this.fetchWithoutToken(`${endpoint}/accounts/${accountId}/branding`);
    }

    public static async fetchBrandings(queryParams?: ApiQueryParams<BrandingQueryParams>): Promise<BrandingListResponse> {
        return this.apiPaginated<BrandingListResponse, BrandingQueryParams>(`${endpoint}/brandings`, queryParams);
    }

    public static async resolveBrandings(ids: string[]): Promise<BrandingListResponse> {
        const response = await this.post(`${endpoint}/brandings-resolve`, { ids: ids });
        return await this.handleResponse<BrandingListResponse>(response);
    }

    public static async deleteBrandings(ids: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/brandings-delete`, { ids: ids });
        await this.handleVoidResponse(response);
    }

    public static async postBranding(branding: CreateBranding): Promise<Branding> {
        const response = await this.post(`${endpoint}/brandings`, branding);
        return await this.handleResponse<Branding>(response);
    }

    public static async fetchBranding(brandingId: string): Promise<Branding> {
        const response = await this.fetch(`${endpoint}/brandings/${brandingId}`);
        return await this.handleResponse<Branding>(response);
    }

    public static async patchBranding(brandingId: string, branding: UpdateBranding): Promise<Branding> {
        const response = await this.patch(`${endpoint}/brandings/${brandingId}`, branding);
        return await this.handleResponse<Branding>(response);
    }

    public static async deleteBranding(brandingId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/brandings/${brandingId}`);
        await this.handleVoidResponse(response);
    }
}
