import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

const IdentificationDetail = lazy(() => import('./identification-detail/identification-detail.component'));

export function IdentificationRoutes() {
    return (
        <Route path=':identificationType'>
            <Route
                index
                element={<Navigate to='../detail' />}
            />
            <Route path=':identificationId'>
                <Route
                    path='detail'
                    element={
                        <AsyncComponent>
                            <IdentificationDetail />
                        </AsyncComponent>
                    }
                />
            </Route>
        </Route>
    );
}
