import { ClassType, EntityType } from '@frontend/common';
import { DefaultModal, Spinner, StatusBadge } from '@frontend/elements';
import { ObjectList } from '@frontend/rendering/list';
import { ChangeClient } from '@frontend/sync/api';
import { Change, ChangeStatus, ChangeType } from '@frontend/sync/types';

import IotDataModal from './iot-data-modal/iot-data-modal.component';
import useIoTSeedOverview from './iot-seed-overview.controller';

const IoTSeedOverview = () => {
    const viewProps = useIoTSeedOverview();
    if (!viewProps.api || !viewProps.currentAccount || !viewProps.iot || !viewProps.iotIdentifier) {
        return <Spinner />;
    }
    return (
        <>
            <ObjectList<Change>
                fetch={(arg) => ChangeClient.fetchServiceChanges(viewProps.api!, { ...arg, iot_identifier: viewProps.iotIdentifier })}
                dispatch={viewProps.dispatch}
                type={EntityType.CHANGE}
                label={viewProps.iot.name}
                onSelectElement={(o) => viewProps.setSelectedChange(o)}
                columns={{
                    columns: ['entity_id', 'entity_type', 'type', 'status', 'creation_timestamp'],
                    entityColumnOverwrite: new Map([['entity_id', 'entity_type']]),
                    customCells: new Map([
                        [
                            'status',
                            (o) => (
                                <td>
                                    <StatusBadge
                                        value={o.row.original.status}
                                        colorMap={
                                            new Map([
                                                [[ChangeStatus.PENDING], ClassType.WARNING],
                                                [[ChangeStatus.FAILED], ClassType.DANGER],
                                                [[ChangeStatus.SUCCEEDED], ClassType.SUCCESS]
                                            ])
                                        }
                                    />
                                </td>
                            )
                        ],
                        [
                            'type',
                            (o) => (
                                <td>
                                    <StatusBadge
                                        value={o.row.original.type}
                                        colorMap={
                                            new Map([
                                                [[ChangeType.CREATE], ClassType.SUCCESS],
                                                [[ChangeType.DELETE], ClassType.DANGER],
                                                [[ChangeType.UPDATE], ClassType.WARNING],
                                                [[ChangeType.EVENT, ChangeType.SEED, ChangeType.REVERT], ClassType.INFO]
                                            ])
                                        }
                                    />
                                </td>
                            )
                        ]
                    ])
                }}
                showCount
                filter={{
                    keys: ['change_status'],
                    queryParamKeyOverwrite: new Map([['change_status', 'status']])
                }}
                defaultListValues={{ filter: { status: ChangeStatus.PENDING } }}
            />
            {!!viewProps.selectedChange && (
                <DefaultModal
                    handleClose={() => viewProps.setSelectedChange(null)}
                    show={!!viewProps.selectedChange}>
                    <IotDataModal
                        handleClose={() => viewProps.setSelectedChange(null)}
                        data={viewProps.selectedChange.data}
                    />
                </DefaultModal>
            )}
        </>
    );
};

export default IoTSeedOverview;
