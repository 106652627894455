import { lazy } from 'react';
import { Route } from 'react-router-dom';

const AttributeValueCreate = lazy(() => import('./attribute-value-create/attribute-value-create.component'));
const AttributeValueUpdate = lazy(() => import('./attribute-value-update/attribute-value-update.component'));
export function AttributeValueRoutes() {
    return (
        <Route path='attribute-values'>
            <Route
                path='create'
                element={<AttributeValueCreate />}
            />
            <Route path=':attributeValueId'>
                <Route
                    path='update'
                    element={<AttributeValueUpdate />}
                />
            </Route>
        </Route>
    );
}
