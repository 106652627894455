import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { HeaderNavigationProps } from './header-navigation.component';

interface ViewProps {
    changeActive: React.Dispatch<React.SetStateAction<string | undefined>>;
    active: string | undefined;
}

const useHeaderNavigation = (props: HeaderNavigationProps): ViewProps => {
    const [active, changeActive] = useState<string | undefined>(undefined);
    const [searchParams, changeSearchParams] = useSearchParams();
    useEffect(() => {
        const param = searchParams.get('active');
        if (!param) {
            changeActive(props.items[0].link);
        } else {
            changeActive(param);
        }
    }, []);

    useEffect(() => {
        if (!active) return;
        changeSearchParams((prev) => {
            const params = new URLSearchParams(prev);
            params.set('active', active);
            return params;
        });
        props.onChange(active);
    }, [active]);

    return {
        changeActive,
        active
    };
};

export default useHeaderNavigation;
