import { EntityType, useAppSelector } from '@frontend/common';
import { equipmentRequest } from '@frontend/equipment/events';
import { Equipment } from '@frontend/equipment/types';
import { getCorrectTranslation, useLocale } from '@frontend/lang';
import { productRequest } from '@frontend/product/events';
import { ObjectBadge, ObjectBadgeProps } from '@frontend/rendering/badge';
import { resolveStore } from '@frontend/repository';
import React from 'react';
import { useSelector } from 'react-redux';

export const EquipmentBadge = (props: ObjectBadgeProps<Equipment>) => {
    const { locale } = useLocale();
    const resolveState = useAppSelector(useSelector, resolveStore);
    return (
        <ObjectBadge<Equipment>
            id={props.id}
            type={EntityType.EQUIPMENT}
            mapDisplayName={(eq: Equipment) => {
                if (!resolveState.resolved?.[EntityType.PRODUCT] || !resolveState.resolved?.[EntityType.PRODUCT][eq.product_id]) {
                    productRequest(eq.product_id);
                    return '';
                } else {
                    const productName = getCorrectTranslation(locale, resolveState.resolved[EntityType.PRODUCT][eq.product_id], 'name');
                    return `${eq.serial_number} (${productName})`;
                }
            }}
            resolve={equipmentRequest}
            navigateString={(object: Equipment) => `/accounts/${object.account_id}/equipment-products/${object.product_id}/equipments/${object.id}`}
            key={props.id}
            {...props}
        />
    );
};
