import React from 'react';
import { FormattedMessage } from 'react-intl';

export const Errors = {
    DEFAULT: (
        <FormattedMessage
            id='errors.default'
            description='Default error message'
            defaultMessage='An error occurred'
        />
    ),
    NOT_FOUND: (
        <FormattedMessage
            id='errors.notFound'
            description='Not found error message'
            defaultMessage='Not found'
        />
    )
};
