import { APIClient } from "@frontend/api-utils";

const endpoint = '/equipment-api/v1';

export class EquipmentEnumClient extends APIClient {
    public static async fetchEquipmentStates(): Promise<string[]> {
        const response = await this.fetch(`${endpoint}/equipment-states`);
        return await this.handleResponse<string[]>(response);
    }

    public static async fetchEquipmentTriggers(): Promise<string[]> {
        const response = await this.fetch(`${endpoint}/equipment-triggers`);
        return await this.handleResponse<string[]>(response);
    }

    public static async fetchEquipmentActions(): Promise<string[]> {
        const response = await this.fetch(`${endpoint}/equipment-actions`);
        return await this.handleResponse<string[]>(response);
    }
}