import { UserUniqueIdentifier, UserUniqueIdentifierValue } from "@frontend/account/types";
import { SelectInputV2, SelectInputV2Props, SelectOption } from "@frontend/basic-forms";
import React, { useMemo } from "react";

const ID = 'user-identifier-select';

export interface UserIdentifierSelectProps extends Omit<SelectInputV2Props<UserUniqueIdentifierValue>, 'options'> {
    options?: SelectOption<UserUniqueIdentifierValue>[];
}

export const UserIdentifierSelect = (props: UserIdentifierSelectProps) => {
    const options = useMemo(() => {
        return UserUniqueIdentifier.ALL.map((identifier) => {
            return {
                value: identifier.value,
                label: identifier.value.replace(/_/g, ' '),
                display: identifier.displayName,
            }
        })
    }, []);

    return (
        <SelectInputV2<UserUniqueIdentifierValue>
            {...props}
            id={ID + props.id}
            label={props.label || 'User interface type'}
            options={props.options || options}
        />
    );
};
