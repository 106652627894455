import { CommonMessage } from '@frontend/lang';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Spinner } from '../spinner.component';
import { useWizard } from './wizard.controller';

export interface WizardProps {
    children: React.ReactNode;
    submit: () => void;
    isLoading?: boolean;
    /**
     * @description Array of booleans to disable the next button for each step
     * should be given in order of the elements
     */
    disabled?: boolean[];
}

/**
 * @deprecated
 */
export const Wizard = (props: WizardProps) => {
    const viewProps = useWizard(props);
    const navigate = useNavigate();
    if (props.isLoading) {
        return (
            <div className='card w-50'>
                <div className='card-body'>
                    <Spinner />
                </div>
            </div>
        );
    }
    return (
        <div className='card w-100 w-lg-50 w-md-50 h-50'>
            <div className='card-header d-flex justify-content-center'>
                <div className='wizard-stepper'>
                    {viewProps.childrenArray.map((child, index) => (
                        <div
                            key={child?.toString()}
                            className={`wizard-step ${viewProps.currentStep === index ? 'active' : ''} ${viewProps.isDisabled(index) ? 'disabled' : ''}`}
                            onClick={() => {
                                if (viewProps.isDisabled(index)) {
                                    return;
                                } else {
                                    viewProps.changeCurrentStep(index);
                                }
                            }}>
                            {index + 1}
                        </div>
                    ))}
                </div>
            </div>
            <div className='card-body'>{viewProps.childrenArray[viewProps.currentStep]}</div>
            <div className='card-footer'>
                <div className='d-flex justify-content-between w-100'>
                    <button
                        className='btn btn-secondary'
                        onClick={() => navigate(-1)}>
                        {CommonMessage.BUTTONS.CANCEL}
                    </button>
                    {viewProps.currentStep > 0 && (
                        <button
                            className='btn btn-primary'
                            onClick={viewProps.goToPreviousStep}
                            disabled={viewProps.currentStep === 0}>
                            {CommonMessage.BUTTONS.PREVIOUS}
                        </button>
                    )}

                    <div className='d-flex justify-content-end'>
                        {viewProps.currentStep === viewProps.childrenArray.length - 1 ? (
                            <button
                                className='btn btn-success'
                                onClick={props.submit}
                                disabled={props.disabled?.includes(true)}>
                                {CommonMessage.BUTTONS.SUBMIT}
                            </button>
                        ) : (
                            <button
                                className='btn btn-primary'
                                onClick={viewProps.goToNextStep}
                                disabled={props.disabled?.[viewProps.currentStep]}>
                                {CommonMessage.BUTTONS.NEXT}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
