import { EntityType } from '@frontend/common';
import { useEffect, useState } from 'react';

import { Permission, UserPermissions } from '../permission';
import { useAuthorization } from './authorization-provider/authorization-provider';
import { AuthorizationWrapperProps } from './authorization-wrapper.component';

interface ViewProps {
    isLoading: boolean;
    authorized: boolean | undefined;
}

const useAuthorizationWrapper = (props: AuthorizationWrapperProps): ViewProps => {
    const { permissions, changeLastRequest } = useAuthorization();
    const [authorized, changeAuthorized] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        if (permissions && props.requiredPermissions) {
            changeLastRequest(Date.now());
            const result = hasAnyPermission(permissions, props.requiredPermissions);
            changeAuthorized(result);
            props.callback && props.callback(result);
        } else if (!props.requiredPermissions) props.callback && props.callback(true);
    }, [permissions, props.requiredPermissions]);

    return {
        isLoading: permissions == null || authorized == undefined,
        authorized
    };
};

export default useAuthorizationWrapper;

export function hasAnyPermission(userPermissions: UserPermissions, requiredPermissions: Permission[]): boolean {
    for (const permission of requiredPermissions) {
        if (hasPermission(userPermissions, permission)) {
            return true;
        }
    }
    return false;
}

function hasPermission(userPermissions: UserPermissions, requiredPermissions: Permission): boolean {
    const sources = [requiredPermissions.source.toString()];

    if ('*' in userPermissions) {
        sources.push(EntityType.ALL);
    }

    for (let i = 0; i < sources.length; i++) {
        const source = sources[i];
        if (source in userPermissions === false) {
            continue;
        }

        const item = requiredPermissions.item;
        if (item in userPermissions[source] === false) {
            continue;
        }

        const rights = [requiredPermissions.right];

        if ('*' in userPermissions[source][item]) {
            rights.push('*');
        }

        for (let j = 0; j < rights.length; j++) {
            const right: string = (Array.isArray(rights[j]) ? rights[j][0] : rights[j]) as string;
            if (right in userPermissions[source][item] === false) {
                continue;
            }

            if (userPermissions[source][item][right].includes('*')) {
                return true;
            }

            if (requiredPermissions.uid === '?') {
                return true;
            }

            if (userPermissions[source][item][right].includes(requiredPermissions.uid)) {
                return true;
            }
        }
    }

    return false;
}
