import { Logger } from '@frontend/Logger';
import { EntityType, Locale, convertLanguage } from '@frontend/common';
import { AddressBadge, AsyncComponent, BooleanBadge, DateBadge, DimensionBadge, LocationBadge } from '@frontend/elements';
import moment from 'moment';
import React from 'react';
import { LuAsterisk } from 'react-icons/lu';

import { ObjectBadgeMapper } from './object-badge-mapper';

interface Props<T extends object> {
    object: T;
    /**
     * @description Can be used for keys in the object mapper that dont exist on the object itself.
     */
    objectBadgeKeyOverwrite?: Map<keyof T, string>;
    keyOverwrite?: Map<keyof T, React.ReactNode>;
    /**
     * @description will overwrite the value of the key provided.
     */
    valueOverwrite?: Map<keyof T, React.ReactNode>;
    /**
     * @description will hide the keys provided. If not provided, all keys will be shown.
     */
    hidden?: (keyof T)[];
    /**
     * @description will only show the keys provided in the same order as provided. If not provided, all keys will be shown.
     * Keys provided in the hidden array will overrule key provided here.
     */
    order?: (keyof T)[];
    /**
     * @deprecated
     * should no longer be handled seperately
     */
    rowClass?: string;
}

export const ObjectDetailMapper = <T extends object>(props: Props<T>) => {
    const rows = convert(props);
    return (
        <>
            <table>
                {rows.map(([key, keyDisplay, value]) => (
                    <tr key={key}>
                        <td className='me-2 pe-4 py-2'>{keyDisplay}</td>
                        <td>
                            <div className='ms-2 mb-0 overflow-hidden w-100'>{value}</div>
                        </td>
                    </tr>
                ))}
            </table>
        </>
    );
};

function convert<T extends object>(props: Props<T>): [string, React.ReactNode, React.ReactNode][] {
    const result: [string, React.ReactNode, React.ReactNode][] = [];
    for (const [key, value] of Object.entries(props.object)) {
        let index = null;
        let finalKey = null;
        if (props.hidden && props.hidden.includes(key as keyof T)) {
            continue;
        }
        if (props.order && !props.order.includes(key as keyof T)) {
            continue;
        } else {
            index = props.order?.indexOf(key as keyof T);
        }
        const translatedKey = props.keyOverwrite?.get(key as keyof T);
        if (translatedKey) {
            //TODO: Maybe also do some automatic translations for common fields.
            finalKey = translatedKey;
        } else if (Object.keys(translationKeys).includes((key as string).slice(-5))) {
            // slice -5 because translation fields will always end with their locale
            const found = Object.entries(translationKeys).find((k) => (key as string).includes(k[0]));
            finalKey = (key as string)[0].toUpperCase() + (key as string).slice(1).replace(found![0], `(${found![1]})`);
            finalKey = finalKey.replace(/_/g, ' ');
        } else {
            const splitKey = key
                .split('_')
                .filter((k) => k !== 'id')
                .join(' ');

            finalKey = splitKey[0].toUpperCase() + splitKey.slice(1);

            finalKey = finalKey.replace(/_/g, ' ').replace(/timestamp/g, '');
        }

        let finalValue: React.ReactNode = <></>;
        if (props.valueOverwrite && props.valueOverwrite.has(key as keyof T)) {
            finalValue = props.valueOverwrite.get(key as keyof T);
        } else if (typeof value === 'string') {
            if (isUUID(value)) {
                if (key === 'id') {
                    //Id of object itself so just display id
                    finalValue = <span className='text-truncate'>{value}</span>;
                } else if (key === 'entity_id' && Object.keys(props.object).includes('entity_type')) {
                    finalValue = (
                        <AsyncComponent>
                            <ObjectBadgeMapper
                                id={value}
                                type={(props.object as { entity_type: EntityType }).entity_type}
                            />
                        </AsyncComponent>
                    );
                } else {
                    //Render as badge
                    finalValue = (
                        <ObjectBadgeMapper
                            id={value}
                            type={
                                props.objectBadgeKeyOverwrite && props.objectBadgeKeyOverwrite.has(key as keyof T)
                                    ? props.objectBadgeKeyOverwrite.get(key as keyof T)!
                                    : key
                            }
                        />
                    );
                }
            } else if ((key.includes('time') || key.includes('date')) && moment(value, moment.ISO_8601, true).isValid()) {
                finalValue = (
                    <DateBadge
                        date={value}
                        displayTime
                        inline
                    />
                );
            } else if (key.includes('address')) {
                const city = 'city' in props.object ? props.object['city'] : undefined;
                const zip = 'zip_code' in props.object ? props.object['zip_code'] : undefined;
                finalValue = (
                    <AddressBadge
                        address={value}
                        city={city as string | undefined}
                        zip={zip as string | undefined}
                    />
                );
            } else if (key === 'language') {
                finalValue = convertLanguage(value as Locale);
            } else if (key === 'secret') {
                finalValue = <span>{Array(8).fill(<LuAsterisk size={10} />)}</span>;
            } else {
                finalValue = <span className='text-truncate'>{value}</span>;
            }
        } else if (typeof value === 'number') {
            finalValue = <span className='text-truncate'>{value}</span>;
        } else if (typeof value === 'boolean') {
            finalValue = <BooleanBadge value={value} />;
        } else if (value === null || value === undefined) {
            finalValue = <span className='text-truncate'>-</span>;
        } else if (key === 'location' || key === 'absolute') {
            finalValue = <LocationBadge location={value} />;
        } else if (key === 'dimension' || key === 'inner' || key === 'outer') {
            finalValue = <DimensionBadge dimension={value} />;
        } else if (Array.isArray(value)) {
            finalValue = (
                <div className='row'>
                    {value.map((v) => (
                        <div
                            key={v}
                            className='col-12 col-xl-3 col-lg-4 col-md-6'
                            style={{ minWidth: 'fit-content' }}>
                            <span>{(v as string).replace(/[_.]/g, ' ')}</span>
                        </div>
                    ))}
                </div>
            );
        } else if (key === 'data') {
            finalValue = (
                <div className='d-flex flex-column'>
                    {Object.entries(value).map(([k, v]) => (
                        <div
                            key={k}
                            className='d-flex flex-row'>
                            <strong>{k}</strong>: {v as string | number | boolean}
                        </div>
                    ))}
                </div>
            );
        } else {
            Logger.warn('Unknown type', {}, value);
        }

        const toAdd: [string, React.ReactNode, React.ReactNode] = [key, <strong>{finalKey}</strong>, finalValue];

        if (index != null) result[index] = toAdd;
        else result.push(toAdd);
    }

    return result;
}
export function isUUID(str: string): boolean {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidRegex.test(str);
}

const translationKeys = { nl_be: 'NL', en_us: 'EN', fr_fr: 'FR', de_de: 'DE' };
