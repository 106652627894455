import { CommonMessage } from '@frontend/lang';
import { ToastUtil } from '@frontend/toast-utils';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LinkObjectFormProps } from './link-object-form.component';

interface ViewProps<T> {
    objects: T[];
    changeObjects: (objects: T[]) => void;
    onCancel?: () => void;
    onSubmit?: (e: any) => void;
}

const useLinkObjectForm = <T extends { id: string }>(props: LinkObjectFormProps<T>): ViewProps<T> => {
    const navigate = useNavigate();
    const [objects, changeObjects] = useState<T[]>([]);

    const onCancel = () => {
        navigate(-1);
    };

    const onSubmit = (e: any) => {
        e.preventDefault();
        if (props.submitDisabled) return;
        ToastUtil.promise(
            () => props.onSubmit(objects),
            {
                message: CommonMessage.OBJECTS.IDENTIFICATION.PENDING_LINK_MESSAGE(props.type)
            },
            {
                message: CommonMessage.OBJECTS.IDENTIFICATION.SUCCESS_LINK_MESSAGE(props.type),
                onSuccess: () => {
                    onCancel();
                }
            },
            {
                message: CommonMessage.OBJECTS.IDENTIFICATION.ERROR_LINK_MESSAGE(props.type)
            }
        );
    };

    return {
        objects,
        changeObjects,
        onCancel,
        onSubmit
    };
};

export default useLinkObjectForm;
