import { APIClient, ApiQueryParams } from "@frontend/api-utils";
import { CreateEquipmentCertificate, EquipmentCertificate, EquipmentCertificateListResponse, EquipmentCertificateQueryParams, UpdateEquipmentCertificate } from "@frontend/certificate/types";

const endpoint = '/certificate-api/v1';
export class EquipmentCertificateClient extends APIClient {
    public static async fetchEquipmentCertificates(queryParams?: ApiQueryParams<EquipmentCertificateQueryParams>): Promise<EquipmentCertificateListResponse> {
        return await this.apiPaginated<EquipmentCertificateListResponse, EquipmentCertificateQueryParams>(`${endpoint}/equipment-certificates`, queryParams);
    }

    public static async resolveEquipmentCertificates(ids: string[]): Promise<EquipmentCertificateListResponse> {
        const response = await this.post(`${endpoint}/equipment-certificates-resolve`, { ids: ids });
        return await this.handleResponse<EquipmentCertificateListResponse>(response);
    }

    public static async deleteEquipmentCertificates(ids: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/equipment-certificates-delete`, { ids: ids });
        return await this.handleVoidResponse(response);
    }

    public static async postEquipmentCertificate(accountId: string, certificateId: string, body: CreateEquipmentCertificate): Promise<EquipmentCertificate> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/certificates/${certificateId}/equipments`, body);
        return await this.handleResponse<EquipmentCertificate>(response);
    }

    public static async fetchEquipmentCertificate(accountId: string, certificateId: string, equipmentCertificateId: string): Promise<EquipmentCertificate> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/certificates/${certificateId}/equipments/${equipmentCertificateId}`);
        return await this.handleResponse<EquipmentCertificate>(response);
    }

    public static async patchEquipmentCertificate(accountId: string, certificateId: string, equipmentCertificateId: string, body: UpdateEquipmentCertificate): Promise<EquipmentCertificate> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/certificates/${certificateId}/equipments/${equipmentCertificateId}`, body);
        return await this.handleResponse<EquipmentCertificate>(response);
    }

    public static async deleteEquipmentCertificate(accountId: string, certificateId: string, equipmentCertificateId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/certificates/${certificateId}/equipments/${equipmentCertificateId}`);
        return await this.handleVoidResponse(response);
    }
}