import { ApiQueryParams, DefaultQueryParams, PaginatedResponse } from '@frontend/api-utils';
import { EntityType, useAppSelector } from '@frontend/common';
import { useLocale } from '@frontend/lang';
import { MqttBroker } from '@frontend/pub-sub';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { useAccountRepository } from '../account/account-repository';
import { allSelect, clear, fetch as func, listStore, select } from './list-slice';

interface Props<T> {
    fetch: (arg?: ApiQueryParams<DefaultQueryParams | string | number>) => Promise<PaginatedResponse<T>>;
    dispatch: ThunkDispatch<any, any, Action>;
    eventListener?: EntityType;
    filters?: ApiQueryParams<DefaultQueryParams | string | number>;
    refreshRef?: React.MutableRefObject<() => void>;
}

export function useListRepository<T>(props: Props<T>) {
    const store = useAppSelector(useSelector, listStore);
    const { store: accountStore } = useAccountRepository();
    const { locale } = useLocale();
    const [id] = useState<string>(crypto.randomUUID());
    const [searchValue, changeSearchValue] = useState<string>('');
    const [params, changeParams] = useState<ApiQueryParams<string | number>>({});
    const pageIndex = store.list[id]?.pageIndex ?? 0;
    const pageSize = store.list[id]?.pageSize ?? 25;
    const sorting = store.list[id]?.sorting ?? [];
    const [refreshIndex, changeRefreshIndex] = useState<string>('');

    const currentAccountRef = useRef(accountStore.currentAccount);

    useEffect(() => {
        currentAccountRef.current = accountStore.currentAccount;
    }, [accountStore.currentAccount]);

    const refreshList = () => {
        props.dispatch(
            func({
                key: id,
                params: {
                    index: pageIndex.toString(),
                    size: pageSize.toString(),
                    account_id: currentAccountRef.current,
                    ...(sorting.length > 0 && {
                        sort_field: sorting[0].id,
                        sort_direction: sorting[0].desc ? 'desc' : 'asc'
                    }),
                    ...(searchValue != '' && { search: searchValue }),
                    ...params,
                    ...props.filters,
                    language: locale
                },
                func: props.fetch
            })
        );
    };

    const onSelect = (objId: string) => {
        props.dispatch(
            select({
                listId: id,
                objId: objId
            })
        );
    };

    const onAllSelect = () => {
        props.dispatch(
            allSelect({
                listId: id,
                index: pageIndex,
                size: pageSize
            })
        );
    };

    useEffect(() => {
        if (props.refreshRef) props.refreshRef.current = refreshList;
    }, [props.refreshRef]);

    useEffect(() => {
        if (props.eventListener == undefined || !accountStore.currentAccount) return;
        const onEvent = (message: string, data: any) => {
            refreshList();
        };
        const topic = props.eventListener === EntityType.ACCOUNT ? 'account' : 'accounts/' + accountStore.currentAccount + '/' + props.eventListener;
        MqttBroker.getInstance().subscribe(id, topic, onEvent);
        return () => {
            MqttBroker.getInstance().unSubscribe(id, topic);
            props.dispatch(clear(id));
        };
    }, [accountStore.currentAccount]);

    useEffect(() => {
        if (!currentAccountRef.current) return;

        //Extra check for changes because react will call this function multiple times
        const newIndex = JSON.stringify({
            sorting: sorting,
            pageIndex: pageIndex,
            pageSize: pageSize,
            searchValue: searchValue,
            params: params,
            account: currentAccountRef.current,
            filters: props.filters
        });

        if (newIndex != refreshIndex) {
            refreshList();
            changeRefreshIndex(newIndex);
        }
    }, [sorting, pageIndex, pageSize, searchValue, params, currentAccountRef.current, props.filters]);

    const data = useMemo(() => {
        if (store.list[id] == undefined || store.list[id].results == null) return null;
        return store.list[id].results as T[];
    }, [store]);

    const totalCount = useMemo(() => {
        if (store.list[id] == undefined || store.list[id].count == null) return 0;
        return store.list[id].count;
    }, [store]);

    const pageCount = store.list[id] != null && store.list[id].count != null ? Math.ceil(store.list[id].count! / (store.list[id]?.pageSize ?? 25)) : 1;

    const selected = useMemo(() => {
        if (store.list[id] == undefined) return [];
        return store.list[id].selected as T[];
    }, [store]);

    const isAllSelected = useMemo(() => {
        if (store.list[id] && store.list[id].results) {
            if (store.list[id].results.length == 0) return false;
            const startPos = pageIndex * pageSize;
            const relevantObjects = store.list[id].results!.slice(startPos, startPos + pageSize);
            return relevantObjects.every((obj) => store.list[id].selected!.find((o) => o.id == obj.id) != undefined);
        }
        return false;
    }, [store, store.list[id]]);

    return {
        listStoreId: id,
        totalCount,
        data,
        pageCount,
        searchValue,
        changeSearchValue,
        params,
        changeParams,
        refreshList,
        selected,
        isAllSelected,
        onSelect,
        onAllSelect
    };
}

export default useListRepository;
