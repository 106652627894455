import { ClassType } from '@frontend/common';
import { Dropdown, DropdownItem } from '@frontend/elements';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

import useAccountMembership from './account-membership.controller';

export interface AccountMembershipProps {
    dispatch: ThunkDispatch<any, any, Action>;
}

const AccountMembership = (props: AccountMembershipProps) => {
    const viewProps = useAccountMembership(props);

    return (
        <Dropdown
            label={viewProps.selectedAccount?.name ?? 'Select account'}
            classType={ClassType.LIGHT}>
            {viewProps.accounts.map((acc) => {
                return (
                    <DropdownItem
                        key={acc.id}
                        label={acc.name}
                        onClick={() => viewProps.onChangeMembership(acc.id)}
                    />
                );
            })}
        </Dropdown>
    );
};

export default AccountMembership;
