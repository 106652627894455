import { Logger } from '@frontend/Logger';
import {
    CheckboxInput,
    ColorInput,
    CountryInput,
    DateInput,
    DimensionInput,
    EmailInput,
    LocationInput,
    NumberInput,
    PhoneInput,
    TextInput
} from '@frontend/basic-forms';
import { EntityType, useAppSelector } from '@frontend/common';
import { AsyncComponent } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import { EnumSelectList, EnumSelectListV2, ObjectSelectMapper } from '@frontend/rendering/select';
import { formStore, updateField } from '@frontend/repository';
import { CustomRoleTemplateSelect } from '@frontend/role/select';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import { useSelector } from 'react-redux';

const ID = 'form-field';
const translationKeys = { nl_be: 'NL', en_us: 'EN', fr_fr: 'FR', de_de: 'DE' };
const commonKeys = ['location', 'dimension', 'permissions', 'inner', 'outer', 'country', 'location', 'email', 'phone'];
const objectSelectMapperExclusions = ['identifier', 'unique_identifier'];
const fallback = (
    <span className='placeholder-glow'>
        <span className='placeholder'>loading</span>
    </span>
);

function isUUID(str: string): boolean {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidRegex.test(str);
}

interface Props<S extends object> {
    dispatch: ThunkDispatch<any, any, Action>;
    formId: string;
    fieldKey: keyof S;
    label?: React.ReactNode;
    objectSelectKeyOverwrite?: string;
    fieldOverwrite?: React.ReactNode;
    enumOverwrite?: string;
    expectedType?: 'object' | 'boolean' | 'number' | 'string';
    fieldProps?: any;

    required?: boolean;
    hidden?: boolean;

    className?: string;
}

export const FormField = <S extends object>(props: Props<S>) => {
    const store = useAppSelector(useSelector, formStore);
    const form = store[props.formId];
    if (!form) return null;
    if (props.hidden && !props.required) return null;

    const value = (form.value as S)[props.fieldKey];
    let finalKey = props.label;
    if (finalKey === undefined) {
        const splitKey = (props.fieldKey as string).split('_');
        if (splitKey.length > 1 && splitKey[splitKey.length - 1] === 'id') {
            splitKey.pop();
            const joinedKey = splitKey.join('_').toUpperCase();
            finalKey = (CommonMessage.OBJECTS[joinedKey as string as keyof typeof CommonMessage.OBJECTS] as any) ? (CommonMessage.OBJECTS[joinedKey as string as keyof typeof CommonMessage.OBJECTS] as any).DEFAULT ?? undefined : undefined;
        } else if (Object.keys(CommonMessage.FORMS).includes((props.fieldKey as string).toUpperCase())) {
            finalKey =
                typeof CommonMessage.FORMS[(props.fieldKey as string).toUpperCase() as keyof typeof CommonMessage.FORMS] === 'function'
                    ? (CommonMessage.FORMS[(props.fieldKey as string).toUpperCase() as keyof typeof CommonMessage.FORMS] as any)()
                    : (CommonMessage.FORMS[(props.fieldKey as string).toUpperCase() as keyof typeof CommonMessage.FORMS] as any);
        } else if (Object.keys(translationKeys).includes((props.fieldKey as string).slice(-5))) {
            // slice -5 because translation fields will always end with their locale
            const found = Object.entries(translationKeys).find((k) => (props.fieldKey as string).includes(k[0]));
            finalKey = (props.fieldKey as string)[0].toUpperCase() + (props.fieldKey as string).slice(1).replace(found![0], `(${found![1]})`);
            finalKey = (finalKey as string).split('_').join(' ');
        } else {
            finalKey = (props.fieldKey as string)[0].toUpperCase() + (props.fieldKey as string).slice(1);
            finalKey = (finalKey as string)
                .split('_')
                .filter((part) => part !== 'id')
                .join(' ')
                .replace('timestamp', '');
        }
    }

    const fieldProps = {
        className: props.className,
        id: ID + '-' + (props.fieldKey as string),
        label: finalKey,
        value,
        onChange: (value: any) => props.dispatch(updateField({ formId: props.formId, key: props.fieldKey as string, value })),
        onSelect: (value: any) => props.dispatch(updateField({ formId: props.formId, key: props.fieldKey as string, value: value?.value ?? value })),
        required: props.required,
        submitted: false,
        allowURLChange: false,
        ...props.fieldProps
    };

    if (props.fieldOverwrite) return props.fieldOverwrite;
    if (
        (props.enumOverwrite && EnumSelectList[props.enumOverwrite as keyof typeof EnumSelectList]) ||
        EnumSelectList[props.fieldKey as keyof typeof EnumSelectList]
    ) {
        const EnumInput = props.enumOverwrite
            ? EnumSelectList[props.enumOverwrite as keyof typeof EnumSelectList]
            : EnumSelectList[props.fieldKey as keyof typeof EnumSelectList];
        return (
            <AsyncComponent fallback={fallback}>
                <EnumInput
                    {...fieldProps}
                    onChange={(value) =>
                        props.dispatch(updateField({ formId: props.formId, key: props.fieldKey as string, value: (value as any)?.value ?? value }))
                    }
                />
            </AsyncComponent>
        );
    }

    if (
        (props.enumOverwrite && EnumSelectListV2[props.enumOverwrite as keyof typeof EnumSelectListV2]) ||
        EnumSelectListV2[props.fieldKey as keyof typeof EnumSelectListV2]
    ) {
        const EnumInput = props.enumOverwrite
            ? EnumSelectListV2[props.enumOverwrite as keyof typeof EnumSelectListV2]
            : EnumSelectListV2[props.fieldKey as keyof typeof EnumSelectListV2];
        return (
            <AsyncComponent fallback={fallback}>
                <EnumInput {...fieldProps} />
            </AsyncComponent>
        );
    }

    if (commonKeys.includes(props.fieldKey as string)) {
        switch (props.fieldKey as string) {
            case 'location':
                return <LocationInput {...fieldProps} />;
            case 'dimension':
            case 'inner':
            case 'outer':
                return <DimensionInput {...fieldProps} />;
            case 'permissions':
                return (
                    <CustomRoleTemplateSelect
                        {...fieldProps}
                        entity_type={(form.value as S)['entity_type' as keyof S] as EntityType | undefined}
                    />
                );
            case 'country':
                return <CountryInput {...fieldProps} />;
            case 'location':
                return <LocationInput {...fieldProps} />;
            case 'email':
                return <EmailInput {...fieldProps} />;
            case 'phone_number':
            case 'mobile':
                return <PhoneInput {...fieldProps} />;
        }
    }

    if ((props.fieldKey as string).includes('time') || (props.fieldKey as string).includes('date')) {
        return (
            <AsyncComponent fallback={fallback}>
                <DateInput
                    {...fieldProps}
                    value={value ? new Date(value as string) : undefined}
                    onChange={(value) => props.dispatch(updateField({ formId: props.formId, key: props.fieldKey as string, value: value?.toISOString() }))}
                    dateTime={fieldProps?.dateTime !== false ? true : false}
                />
            </AsyncComponent>
        );
    }

    if ((props.fieldKey as string).includes('color')) return <ColorInput {...fieldProps} />;

    if (typeof value === 'number' || props.expectedType === 'number') return <NumberInput {...fieldProps} />;

    if (typeof value === 'boolean' || props.expectedType === 'boolean') return <CheckboxInput {...fieldProps} />;

    if (
        (typeof value === 'string' && isUUID(value)) ||
        ((props.fieldKey as string).includes('id') && !objectSelectMapperExclusions.includes(props.fieldKey as string))
    ) {
        return (
            <ObjectSelectMapper
                {...fieldProps}
                objectKey={props.objectSelectKeyOverwrite ?? props.fieldKey}
            />
        );
    }

    if (Array.isArray(value)) {
        return (
            <TextInput
                {...fieldProps}
                value={(value as []).join(', ')}
                onChange={(value) =>
                    props.dispatch(updateField({ formId: props.formId, key: props.fieldKey as string, value: value.split(',').map((item) => item.trim()) }))
                }
            />
        );
    }

    if (typeof value === 'string' || props.expectedType === 'string') return <TextInput {...fieldProps} />;

    if (value === undefined || value === null) return <TextInput {...fieldProps} />;

    Logger.warn('Unknown type', {}, value, props.fieldKey);
    return <></>;
};
