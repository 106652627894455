import { useAppSelector } from '@frontend/common';
import { allSelect, listStore, select } from '@frontend/repository';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React, { forwardRef, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

export const Checkbox = forwardRef<
    HTMLInputElement,
    {
        indeterminate?: boolean | undefined;
        original?: { id: string } & object;
        dispatch: ThunkDispatch<any, any, Action>;
        listStoreId: string;
    }
>(({ indeterminate, original, dispatch, listStoreId, ...rest }, ref) => {
    const store = useAppSelector(useSelector, listStore);
    const pageIndex = store[listStoreId]?.pageIndex ?? 0;
    const pageSize = store[listStoreId]?.pageSize ?? 25;
    const resolvedRef = ref;
    const selected = useMemo(() => {
        if (original) {
            if (!store[listStoreId]) return false;
            return store[listStoreId].selected!.find((o) => o.id == original!.id) != undefined;
        } else {
            if (store[listStoreId] && store[listStoreId].results) {
                if (store[listStoreId].results!.length == 0) return false;
                const startPos = pageIndex * pageSize;
                const relevantObjects = store[listStoreId].results!.slice(startPos, startPos + pageSize);
                return relevantObjects.every((obj) => store[listStoreId].selected!.find((o) => o.id == obj.id) != undefined);
            }
            return false;
        }
    }, [store[listStoreId]]);

    const onClick = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        if (original) {
            dispatch(
                select({
                    listId: listStoreId,
                    objId: original.id
                })
            );
        } else {
            dispatch(
                allSelect({
                    listId: listStoreId,
                    index: pageIndex,
                    size: pageSize
                })
            );
        }
    };

    useEffect(() => {
        if (resolvedRef && resolvedRef != null && typeof resolvedRef !== 'function' && resolvedRef.current && indeterminate != undefined)
            resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
        <div className='form-check text-lg ms-2 my-0 py-0'>
            <input
                className='form-check-input my-0 py-0'
                type='checkbox'
                ref={resolvedRef}
                {...rest}
                checked={selected}
                onClick={onClick}
            />
        </div>
    );
});

Checkbox.displayName = 'Search';
