import { APIClient } from "@frontend/api-utils";
import { CustomRoleTemplate, EntityTypePermissionTemplate, PossibleRolesTemplate, PredefinedPermissionTemplate, PredefinedRoleTemplate } from "@frontend/role/types";

const endpoint = '/authorization-api/v1';

export class RoleTemplateClient extends APIClient {

    public static async fetchPredefinedRoleTemplates(): Promise<PredefinedRoleTemplate> {
        const response = await this.fetch(`${endpoint}/predefined-role-templates`);
        return await this.handleResponse<any>(response);
    }

    public static async fetchPredefinedPermissionTemplates(): Promise<PredefinedPermissionTemplate> {
        const response = await this.fetch(`${endpoint}/predefined-permission-templates`);
        return await this.handleResponse<any>(response);
    }

    public static async fetchCustomRoleTemplates(): Promise<CustomRoleTemplate> {
        const response = await this.fetch(`${endpoint}/custom-role-templates`);
        return await this.handleResponse<any>(response);
    }

    public static async fetchEntityTypePermissions(): Promise<EntityTypePermissionTemplate> {
        const response = await this.fetch(`${endpoint}/entity-type-permissions`);
        return await this.handleResponse<EntityTypePermissionTemplate>(response);
    }

    public static async fetchPossibleRoles(accountId: string, entityType: string): Promise<PossibleRolesTemplate> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/${entityType}s/possible-roles`);
        return await this.handleResponse<PossibleRolesTemplate>(response);
    }
}