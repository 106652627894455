import React from 'react';
import { GroupBase } from 'react-select';
import AsyncSelect, { AsyncProps } from 'react-select/async';

import { getClassName, getInputClassMultiSelect } from '../input-class';
import useAsyncSelectInput from './async-select-input.controller';

const ID = 'async-select-input';

export interface AsyncSelectInputProps<
    OptionType extends GroupBase<OptionType> = any,
    IsMulti extends boolean = false | true,
    GroupType extends OptionType = OptionType
> extends AsyncProps<OptionType, IsMulti, GroupType> {
    label: React.ReactNode;
    valid?: boolean;
    isValidCallback?: (valid: boolean) => void;
    onFocusChange?: () => void;
    submitted: boolean;
    value?: any[] | any;
    useConditionedStyling?: boolean;
    errorMessage?: React.ReactNode;
}

/**
 * @deprecated
 */
export const AsyncSelectInput = (props: AsyncSelectInputProps) => {
    const viewProps = useAsyncSelectInput(props);

    return (
        <div
            id={ID}
            className={`form-group ${props.className ? props.className : ''}`}>
            <label>
                {props.required ? <span className='text-danger me-1'>&#9679;</span> : <></>} {props.label}
            </label>
            <AsyncSelect
                id={props.id}
                styles={{
                    control: (baseStyles) => ({
                        ...baseStyles,
                        boxShadow: 'none',
                        minWidth: '190px',
                        padding: '1.204px 0px 1.204px 10px',
                        borderRadius: '0.5rem'
                    }),
                    menu: (baseStyle) => ({
                        ...baseStyle,
                        zIndex: 9999
                    }),
                    valueContainer: (baseStyle) => ({
                        ...baseStyle,
                        padding: '0px'
                    })
                }}
                classNames={{
                    control: () =>
                        props.useConditionedStyling === false
                            ? 'form-control'
                            : props.isMulti
                              ? getInputClassMultiSelect(viewProps.touched, viewProps.valid, props.value)
                              : getClassName(
                                    viewProps.touched,
                                    viewProps.valid,
                                    props.value == null || props.value == undefined ? undefined : JSON.stringify(props.value)
                                )
                }}
                isClearable={props.isClearable}
                isDisabled={props.isDisabled}
                value={viewProps.findValue()}
                cacheOptions={props.cacheOptions}
                loadOptions={props.loadOptions}
                defaultOptions={props.defaultOptions}
                isMulti={props.isMulti}
                options={props.options}
                minMenuHeight={300}
                placeholder={props.placeholder}
                noOptionsMessage={props.noOptionsMessage}
                onChange={(newValue: any, actionMeta) => {
                    viewProps.changeValue(newValue);
                    props.onChange && props.onChange(props.isMulti ? newValue.map((s: any) => s.value) : newValue, actionMeta);
                    viewProps.isValid();
                }}
                onFocus={() => {
                    viewProps.changeTouched(true);
                    props.onFocusChange && props.onFocusChange();
                }}
                onInputChange={(value, meta) => props.onInputChange && props.onInputChange(value, meta)}
                onMenuScrollToBottom={props.onMenuScrollToBottom}
            />
            {viewProps.valid === false && viewProps.touched === true ? (
                <span className='badge bg-gradient-danger mt-2'>
                    <small>{props.errorMessage ? props.errorMessage : 'Please select a valid option.'}</small>
                </span>
            ) : (
                <></>
            )}
        </div>
    );
};

export interface AsyncSelectReturn {
    value: string;
    label: string;
}
