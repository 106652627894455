
import { EventListener } from '@frontend/pub-sub';
import { AttributeLinking } from '@frontend/attribute/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

export class AttributeLinkingEventListener extends EventListener<AttributeLinking> {
    
    private static instance: AttributeLinkingEventListener | null = null;
    private constructor(dispatch: ThunkDispatch<any, any, Action>) {
        super('attribute', dispatch);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>): AttributeLinkingEventListener {
        if (this.instance == null) {
            this.instance = new AttributeLinkingEventListener(dispatch);
        }
        return this.instance;
    }

    getInstance(dispatch: ThunkDispatch<any, any, Action>): EventListener<AttributeLinking> {
        return AttributeLinkingEventListener.getInstance(dispatch);
    }

    protected onUpdate() {}

    protected onDelete() {}

    protected onCreate() {}
}
