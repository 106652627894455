import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

const UserBadgeOverview = lazy(() => import('../user-badge/user-badge-overview'));
const UserBadgeCreate = lazy(() => import('../user-badge/user-badge-create/user-badge-create.component'));
const UserBadgeUpdate = lazy(() => import('../user-badge/user-badge-update/user-badge-update.component'));
export function UserBadgeRoutes() {
    return (
        <Route path='user-badges'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <UserBadgeOverview />
                    </AsyncComponent>
                }
            />

            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <UserBadgeCreate />
                    </AsyncComponent>
                }
            />

            <Route path=':userBadgeId'>
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <UserBadgeUpdate />
                        </AsyncComponent>
                    }
                />
                <Route
                    index
                    element={<Navigate to='../' />}
                />
            </Route>
        </Route>
    );
}
