import React from "react";
import { FormattedMessage } from "react-intl";

export enum EquipmentActionName {
    EQUIPMENT_CHANGE_STATE = 'equipment_change_state',
    EQUIPMENT_UPDATE_PARAMETER = 'equipment_update_parameter',
    EQUIPMENT_VERIFY_PARAMETER = 'equipment_verify_parameter',
    EQUIPMENT_SET_FUTURE_STATE = 'equipment_set_future_state',
    EQUIPMENT_DETERMINE_FUTURE_STATE = 'equipment_determine_future_state',
    EQUIPMENT_APPLY_FUTURE_STATE = 'equipment_apply_future_state',
    EQUIPMENT_LINK_USER = 'equipment_link_user',
    EQUIPMENT_UNLINK_USER = 'equipment_unlink_user'
}

export class EquipmentAction {
    static readonly EQUIPMENT_CHANGE_STATE = new EquipmentAction(
        EquipmentActionName.EQUIPMENT_CHANGE_STATE,
        (
            <FormattedMessage
                id='EquipmentAction.equipment_change_state'
                description='The displayed value for the EquipmentAction equipment_change_state'
                defaultMessage='Change state'
            />
        )
    );

    static readonly EQUIPMENT_UPDATE_PARAMETER = new EquipmentAction(
        EquipmentActionName.EQUIPMENT_UPDATE_PARAMETER,
        (
            <FormattedMessage
                id='EquipmentAction.equipment_update_parameter'
                description='The displayed value for the EquipmentAction equipment_update_parameter'
                defaultMessage='Update parameter'
            />
        )
    );

    static readonly EQUIPMENT_VERIFY_PARAMETER = new EquipmentAction(
        EquipmentActionName.EQUIPMENT_VERIFY_PARAMETER,
        (
            <FormattedMessage
                id='EquipmentAction.equipment_verify_parameter'
                description='The displayed value for the EquipmentAction equipment_verify_parameter'
                defaultMessage='Verify parameter'
            />
        )
    );

    static readonly EQUIPMENT_SET_FUTURE_STATE = new EquipmentAction(
        EquipmentActionName.EQUIPMENT_SET_FUTURE_STATE,
        (
            <FormattedMessage
                id='EquipmentAction.equipment_set_future_state'
                description='The displayed value for the EquipmentAction equipment_set_future_state'
                defaultMessage='Set future state'
            />
        )
    );

    static readonly EQUIPMENT_DETERMINE_FUTURE_STATE = new EquipmentAction(
        EquipmentActionName.EQUIPMENT_DETERMINE_FUTURE_STATE,
        (
            <FormattedMessage
                id='EquipmentAction.equipment_determine_future_state'
                description='The displayed value for the EquipmentAction equipment_determine_future_state'
                defaultMessage='Determine future state'
            />
        )
    );

    static readonly EQUIPMENT_APPLY_FUTURE_STATE = new EquipmentAction(
        EquipmentActionName.EQUIPMENT_APPLY_FUTURE_STATE,
        (
            <FormattedMessage
                id='EquipmentAction.equipment_apply_future_state'
                description='The displayed value for the EquipmentAction equipment_apply_future_state'
                defaultMessage='Apply future state'
            />
        )
    );

    static readonly EQUIPMENT_LINK_USER = new EquipmentAction(
        EquipmentActionName.EQUIPMENT_LINK_USER,
        (
            <FormattedMessage
                id='EquipmentAction.equipment_link_user'
                description='The displayed value for the EquipmentAction equipment_link_user'
                defaultMessage='Link user'
            />
        )
    );

    static readonly EQUIPMENT_UNLINK_USER = new EquipmentAction(
        EquipmentActionName.EQUIPMENT_UNLINK_USER,
        (
            <FormattedMessage
                id='EquipmentAction.equipment_unlink_user'
                description='The displayed value for the EquipmentAction equipment_unlink_user'
                defaultMessage='Unlink user'
            />
        )
    );

    static readonly ALL = [
        EquipmentAction.EQUIPMENT_CHANGE_STATE,
        EquipmentAction.EQUIPMENT_UPDATE_PARAMETER,
        EquipmentAction.EQUIPMENT_VERIFY_PARAMETER,
        EquipmentAction.EQUIPMENT_SET_FUTURE_STATE,
        EquipmentAction.EQUIPMENT_DETERMINE_FUTURE_STATE,
        EquipmentAction.EQUIPMENT_APPLY_FUTURE_STATE,
        EquipmentAction.EQUIPMENT_LINK_USER,
        EquipmentAction.EQUIPMENT_UNLINK_USER
    ];
    private constructor(public readonly value: EquipmentActionName, public readonly displayName: React.ReactNode) {}

    static getByValue(value: EquipmentActionName): EquipmentAction | undefined {
        return EquipmentAction.ALL.find(action => action.value === value);
    }
}