import { EntityType } from '@frontend/common';
import { Spinner } from '@frontend/elements';
import { NotificationEmailClient } from '@frontend/notification-service/api';
import { NotificationEmail, UpdateNotificationEmail } from '@frontend/notification-service/types';
import { ObjectForm } from '@frontend/rendering/form';
import { useParams } from 'react-router-dom';

import useNotificationEmailUpdate from './notification-email-update.controller';

const ID = 'notification-email-update';

const NotificationEmailUpdate = () => {
    const { emailId } = useParams();
    const viewProps = useNotificationEmailUpdate();

    return (
        <div id={ID}>
            {viewProps.email === null ? (
                <Spinner />
            ) : (
                <ObjectForm<NotificationEmail, UpdateNotificationEmail>
                    object={viewProps.email}
                    type={EntityType.NOTIFICATION_EMAIL}
                    value={viewProps.formValues}
                    show={['username', 'password', 'domain', 'from_address', 'server', 'port', 'starttls', 'ssl_tls', 'use_credentials']}
                    required={['username', 'password', 'domain', 'from_address', 'server', 'port']}
                    onChange={viewProps.changeFormValues}
                    submitDisabled={
                        !viewProps.account_id ||
                        !viewProps.formValues.username ||
                        !viewProps.formValues.password ||
                        !viewProps.formValues.domain ||
                        !viewProps.formValues.from_address ||
                        !viewProps.formValues.server ||
                        !viewProps.formValues.port
                    }
                    onCancel={viewProps.onCancel}
                    onSubmit={(v) => NotificationEmailClient.patchNotificationEmail(viewProps.account_id!, emailId!, v)}
                    onSubmitSuccess={viewProps.onSubmitSuccess}
                />
            )}
        </div>
    );
};

export default NotificationEmailUpdate;
