import { APIClient, ApiQueryParams, DefaultQueryParams } from "@frontend/api-utils";
import { Token } from "@frontend/authentication-v2";
import { CreateDocumentEntity, DocumentEntity, DocumentEntityListResponse, DocumentEntityQueryParams } from "@frontend/document/types";

const endpoint = '/document-api/v1';

export class DocumentEntityClient extends APIClient {
    public static async fetchDocumentEntities(queryParams?: ApiQueryParams<DefaultQueryParams & DocumentEntityQueryParams>, withCredentials?: Token): Promise<DocumentEntityListResponse> {
        return await this.apiPaginated<DocumentEntityListResponse, DefaultQueryParams & DocumentEntityQueryParams>(`${endpoint}/document-entities`, queryParams, withCredentials);
    }

    public static async resolveDocumentEntities(ids: string[], field: keyof DocumentEntity = 'id'): Promise<DocumentEntityListResponse> {
        const response = await this.post(`${endpoint}/document-entities-resolve`, { field, ids: ids });
        return await this.handleResponse<DocumentEntityListResponse>(response);
    }

    public static async deleteDocumentEntities(ids: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/document-entities-delete`, { ids: ids });
        return await this.handleVoidResponse(response);
    }

    public static async postDocumentEntity(accountId: string, documentId: string, body: CreateDocumentEntity): Promise<DocumentEntity> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/documents/${documentId}/entities`, body);
        return await this.handleResponse<DocumentEntity>(response);
    }

    public static async fetchDocumentEntity(accountId: string, documentId: string, documentEntityId: string): Promise<DocumentEntity> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/documents/${documentId}/entities/${documentEntityId}`);
        return await this.handleResponse<DocumentEntity>(response);
    }

    public static async deleteDocumentEntity(accountId: string, documentId: string, documentEntityId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/documents/${documentId}/entities/${documentEntityId}`);
        return await this.handleVoidResponse(response);
    }
}
