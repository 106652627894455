import { APIClient, ApiQueryParams, DefaultQueryParams } from "@frontend/api-utils";
import { BudgetEntity, BudgetEntityListResponse, BudgetEntityQueryParams, CreateBudgetEntity } from "@frontend/budget/types";

const endpoint = '/budget-api/v1';

export class BudgetEntityClient extends APIClient {
    public static async fetchBudgetEntities(queryParams?: ApiQueryParams<DefaultQueryParams & BudgetEntityQueryParams>): Promise<BudgetEntityListResponse> {
        return await this.apiPaginated<BudgetEntityListResponse, DefaultQueryParams & BudgetEntityQueryParams>(`${endpoint}/budget-entities`, queryParams);
    }

    public static async resolveBudgetEntities(ids: string[]): Promise<BudgetEntityListResponse> {
        const response = await this.post(`${endpoint}/budget-entities-resolve`, { ids: ids });
        return await this.handleResponse<BudgetEntityListResponse>(response);
    }

    public static async deleteBudgetEntities(ids: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/budget-entities-delete`, { ids: ids });
        return await this.handleVoidResponse(response);
    }

    public static async postBudgetEntity(accountId: string, budgetId: string, body: CreateBudgetEntity): Promise<BudgetEntity> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/budgets/${budgetId}/entities`, body);
        return await this.handleResponse<BudgetEntity>(response);
    }

    public static async fetchBudgetEntity(accountId: string, budgetId: string, budgetEntityId: string): Promise<BudgetEntity> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/budgets/${budgetId}/entities/${budgetEntityId}`);
        return await this.handleResponse<BudgetEntity>(response);
    }

    public static async deleteBudgetEntity(accountId: string, budgetId: string, budgetEntityId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/budgets/${budgetId}/entities/${budgetEntityId}`);
        return await this.handleVoidResponse(response);
    }
}