import { Logger } from '@frontend/Logger';
import { ApiError, ApiQueryParams, DefaultQueryParams, PaginatedResponse } from '@frontend/api-utils';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SortingState } from '@tanstack/react-table';
import { toNumber } from 'lodash';

export interface ListInfo {
    results:
        | {
              id: string;
              [key: string]: any;
          }[]
        | null;
    count: number | null;
    selected: {
        id: string;
        [key: string]: any;
    }[];
    pageIndex: number;
    pageSize: number;
    sorting: SortingState;
    filter: ApiQueryParams<DefaultQueryParams | string | number>;
    refresh: boolean;
}
export interface ListState {
    [key: string]: ListInfo;
}

const initialState: ListState = {};

export interface DefaultListValues {
    results?:
        | {
              id: string;
              [key: string]: any;
          }[]
        | null;
    count?: number | null;
    selected?: {
        id: string;
        [key: string]: any;
    }[];
    pageIndex?: number;
    pageSize?: number;
    sorting?: SortingState;
    filter?: ApiQueryParams<DefaultQueryParams | string | number>;
}

const defaultListValues = {
    results: null,
    count: null,
    selected: [],
    pageIndex: 0,
    pageSize: 25,
    sorting: [],
    refresh: false,
    filter: {}
};

export const listSlice = createSlice({
    name: 'list',
    initialState,
    reducers: {
        initList(state, action: PayloadAction<{ listId: string; defaultListValues: DefaultListValues }>) {
            if (state[action.payload.listId]) {
                Logger.warn('List already initialized - skipping to prevent overwrite', {}, { listId: action.payload.listId });
            } else {
                state[action.payload.listId] = { ...defaultListValues, ...action.payload.defaultListValues };
            }
        },
        refresh(state, action: PayloadAction<{ listId: string; refresh: boolean }>) {
            state[action.payload.listId].refresh = action.payload.refresh;
        },
        /**
         * @deprecated
         * @param state
         * @param action
         */
        clear(state, action: PayloadAction<string>) {
            if (state[action.payload] && state[action.payload].results) {
                state[action.payload].results = null;
                state[action.payload].count = null;
                state[action.payload].selected = [];
            }
        },
        changePage(state, action: PayloadAction<{ listId: string; page: number }>) {
            if (state[action.payload.listId]) {
                state[action.payload.listId].pageIndex = action.payload.page;
            }
        },
        changePageSize(state, action: PayloadAction<{ listId: string; pageSize: number }>) {
            if (state[action.payload.listId]) {
                state[action.payload.listId].pageSize = action.payload.pageSize;
            }
        },
        changeSorting(state, action: PayloadAction<{ listId: string; sorting: SortingState }>) {
            if (state[action.payload.listId]) {
                state[action.payload.listId].sorting = action.payload.sorting;
            }
        },
        select(state, action: PayloadAction<{ listId: string; objId: string }>) {
            if (state[action.payload.listId]) {
                const found = state[action.payload.listId].selected.find((o) => o.id == action.payload.objId);
                if (found) {
                    state[action.payload.listId].selected = state[action.payload.listId].selected.filter((o) => o.id != action.payload.objId);
                } else {
                    if (state[action.payload.listId].results != null && state[action.payload.listId].results!.find((o) => o?.id == action.payload.objId))
                        state[action.payload.listId].selected.push(state[action.payload.listId].results!.find((o) => o?.id == action.payload.objId)!);
                }
            }
        },
        allSelect(state, action: PayloadAction<{ listId: string; index: number; size: number }>) {
            if (state[action.payload.listId] && state[action.payload.listId].results) {
                const startPos = action.payload.index * action.payload.size;
                const relevantObjects = state[action.payload.listId].results!.slice(startPos, startPos + action.payload.size);
                if (relevantObjects.every((obj) => state[action.payload.listId].selected!.find((o) => o.id == obj.id) != undefined)) {
                    state[action.payload.listId].selected = state[action.payload.listId].selected!.filter(
                        (obj) => relevantObjects.find((o) => o.id == obj.id) == undefined
                    );
                } else {
                    state[action.payload.listId].selected = state[action.payload.listId].selected!.concat(relevantObjects);
                }
            }
        },
        changeFilters(
            state,
            action: PayloadAction<{ listId: string; filters: { key: DefaultQueryParams | string | number; value: string | string[] | undefined | null }[] }>
        ) {
            if (state[action.payload.listId]) {
                action.payload.filters.forEach((filter) => {
                    state[action.payload.listId].filter[filter.key] = filter.value;
                });
            }
        },
        changeFilter(state, action: PayloadAction<{ listId: string; key: DefaultQueryParams | string | number; value: string | string[] | undefined | null }>) {
            if (state[action.payload.listId]) {
                if (state[action.payload.listId].filter == null) state[action.payload.listId].filter = {};
                if (action.payload.value == null) {
                    delete state[action.payload.listId].filter[action.payload.key];
                } else if (state[action.payload.listId].filter) {
                    state[action.payload.listId].filter[action.payload.key] = action.payload.value;
                } else {
                    state[action.payload.listId].filter = {
                        [action.payload.key]: action.payload.value
                    };
                }
            }
        },
        clearFilter(state, action: PayloadAction<{ listId: string; defaultValue?: ApiQueryParams<DefaultQueryParams | string | number> }>) {
            if (state[action.payload.listId]) {
                state[action.payload.listId].filter = action.payload.defaultValue ?? {};
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetch.fulfilled, (state, action) => {
            if (action.meta.arg.params['index'] !== undefined && action.meta.arg.params['size'] !== undefined) {
                const startPos = toNumber(action.meta.arg.params['index']) * toNumber(action.meta.arg.params['size']);
                if (state[action.meta.arg.key] == null) {
                    //shouldnt happen if init function was called
                    state[action.meta.arg.key] = { ...defaultListValues, ...action.payload, results: new Array(action.payload.count) };
                    state[action.meta.arg.key].results!.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else if (state[action.meta.arg.key].results == null) {
                    state[action.meta.arg.key] = { ...state[action.meta.arg.key], count: action.payload.count, results: new Array(action.payload.count) };
                    state[action.meta.arg.key].results!.splice(startPos, action.payload.results.length, ...action.payload.results);
                } else {
                    if (state[action.meta.arg.key].results!.length != action.payload.count) {
                        state[action.meta.arg.key].count = action.payload.count;
                        state[action.meta.arg.key].results = new Array(action.payload.count);
                    }
                    state[action.meta.arg.key].results!.splice(startPos, action.payload.results.length, ...action.payload.results);
                }
            } else {
                state[action.meta.arg.key] = { ...defaultListValues, ...action.payload };
            }
        });
    }
});

type Options = {
    key: string;
    params: ApiQueryParams<DefaultQueryParams | string | number>;
    func: (queryParams?: ApiQueryParams<DefaultQueryParams | string | number>) => Promise<PaginatedResponse<any>>;
};
export const fetch = createAsyncThunk<PaginatedResponse<any>, Options>('fetch', async (options: Options, { rejectWithValue }) => {
    try {
        return await options.func(options.params);
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const listStore = { lists: listSlice.reducer };
export const { clear, initList, refresh, changePage, changePageSize, changeSorting, select, allSelect, changeFilter, changeFilters, clearFilter } =
    listSlice.actions;
