import { ApiQueryParams, DefaultQueryParams, PaginatedResponse } from '@frontend/api-utils';
import { AsyncSelectInputV2 } from '@frontend/basic-forms';
import React from 'react';

import useObjectSelectInputV2 from './object-select-input-v2.controller';

const ID = 'object-select-input';

export interface ObjectSelectInputPropsV2<T extends { id: string }, D extends string | number> {
    id?: string;
    label: React.ReactNode;
    helpMessage?: React.ReactNode;
    className?: string;

    required?: boolean;
    disabled?: boolean;
    hideOnOneOption?: boolean;
    hidden?: boolean;
    isClearable?: boolean;

    /**
     * @description Will check the current URL for a path param that matches this value.
     * If found (using the fetch function) the select will be disabled and the value will be set.
     * Otherwise the select will function as usual.
     * Do not use with useQueryParam (may cause unexpected behaviour)
     */
    useUrlOverwrite?: UrlParams<T>;
    /**
     * @description Will check the current URL for a query param that matches this value.
     * If found (using the fetch function) the value will be set.
     * When selecting a new value, the URL will be updated.
     * Do not use with useUrlOverwrite (may cause unexpected behaviour)
     */
    useQueryParam?: UrlParams<T>;
    onChange?: (value: T | null) => void;
    idValue?: string | null;
    value?: T | null;
    searchValueOverwrite?: string;
    queryParams?: ApiQueryParams<D>;
    fetch: (queryParams?: ApiQueryParams<DefaultQueryParams | D>) => Promise<PaginatedResponse<T>>;
    singleFetch?: (id: string) => Promise<T>;
    itemLabel: (item: T) => string;
    disableFetch?: boolean;
    mapOptions?: keyof T;
    subset?: string[]; //array of valid ID's
    onFocus?: React.FocusEventHandler<HTMLInputElement> | undefined;
    onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
    isOpenOverwrite?: boolean;
}

interface UrlParams<T> {
    param: string;
    fetch: (id: string) => Promise<T>;
}

/**
 * @todo add generic create object
 * @param props
 * @returns
 */
export const ObjectSelectInputV2 = <T extends { id: string }, D extends string | number>(props: ObjectSelectInputPropsV2<T, D>) => {
    const viewProps = useObjectSelectInputV2<T, D>(props);
    if (viewProps.hide) return <></>;
    return (
        <AsyncSelectInputV2
            id={props.id || ID}
            label={props.label}
            className={props.className}
            helpMessage={props.helpMessage}
            options={viewProps.options}
            onSelect={viewProps.onSelect}
            value={viewProps.value?.id ?? props.idValue}
            searchValueOverwrite={props.searchValueOverwrite}
            onScrollToBottom={viewProps.onScrollToBottom}
            onSearch={viewProps.search}
            isClearable={props.isClearable}
            required={props.required}
            disabled={viewProps.overwriteDisabled || props.disabled}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            isOpenOverwrite={props.isOpenOverwrite}
        />
    );
};

export default ObjectSelectInputV2;
