import { EntityType, Locale } from '@frontend/common';
import { getCorrectTranslation, useLocale } from '@frontend/lang';
import { productRequest } from '@frontend/product/events';
import { IProduct, ProductType } from '@frontend/product/types';
import { ObjectBadge, ObjectBadgeProps } from '@frontend/rendering/badge';
import React from 'react';

export const ProductBadge = (props: ObjectBadgeProps<IProduct>) => {
    const { locale } = useLocale();
    return (
        <ObjectBadge<IProduct>
            {...props}
            id={props.id}
            type={EntityType.PRODUCT}
            mapDisplayName={props.mapDisplayName || ((prod: IProduct) => getCorrectTranslation(locale, prod, 'name'))}
            resolve={props.resolve || productRequest}
            key={props.id}
            navigateString={(object: IProduct) =>
                object.type === ProductType.EQUIPMENT
                    ? `/accounts/${object.account_id}/equipment-products/${object.id}`
                    : `/accounts/${object.account_id}/products/${object.id}`
            }
        />
    );
};

export function defaultMapWithAttributes(prod: IProduct, locale: Locale | undefined) {
    const keyValuePairs: string[] = [];
    if (prod.attributes === null) {
        return getCorrectTranslation(locale, prod, 'name');
    }
    for (const key in prod.attributes) {
        if (prod.attributes.hasOwnProperty(key)) {
            keyValuePairs.push(`${key}: ${prod.attributes[key]}`);
        }
    }
    const keyPairString = keyValuePairs.length > 0 ? ' (' + keyValuePairs.join(', ') + ')' : '';
    return getCorrectTranslation(locale, prod, 'name') + keyPairString;
}
