import { EntityType } from '@frontend/common';
import { NotificationContentClient } from '@frontend/notification-service/api';
import { useResolver } from '@frontend/repository';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

declare global {
    interface WindowEventMap {
        'useNotificationContent:request': CustomEvent<string>;
    }
}

const REQUEST_NOTIFICATION_CONTENT_EVENT = 'useNotificationContent:request';
const STORAGE_KEY = 'NOTIFCONTENT_REQ';

export function notificationContentRequest(notificationContentId: string): string {
    window.dispatchEvent(new CustomEvent(REQUEST_NOTIFICATION_CONTENT_EVENT, { detail: notificationContentId }));
    return STORAGE_KEY + '_loading';
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function NotificationContentResolver(props: Props) {
    useResolver(
        STORAGE_KEY,
        REQUEST_NOTIFICATION_CONTENT_EVENT,
        EntityType.NOTIFICATION_CONTENT,
        (ids) => NotificationContentClient.resolveNotificationContents(ids),
        props.dispatch
    );
    return <></>;
}
