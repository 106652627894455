import { Document } from '@frontend/document/types';
import { EventListener } from '@frontend/pub-sub';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';


export class DocumentEventListener extends EventListener<Document> {
    
    private static instance: DocumentEventListener | null = null;
    private constructor(dispatch: ThunkDispatch<any, any, Action>) {
        super('document', dispatch);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>): DocumentEventListener {
        if (this.instance == null) {
            this.instance = new DocumentEventListener(dispatch);
        }
        return this.instance;
    }

    getInstance(dispatch: ThunkDispatch<any, any, Action>): EventListener<Document> {
        return DocumentEventListener.getInstance(dispatch);
    }

    protected onUpdate() {}
    protected onDelete() {}
    protected onCreate() {}
}
