import { ApiQueryParams, DefaultQueryParams, PaginatedResponse } from '@frontend/api-utils';
import { ClassType, EntityType } from '@frontend/common';
import { ICONS } from '@frontend/icons';
import { CommonMessage } from '@frontend/lang';
import { ObjectColumnsProps, ObjectList } from '@frontend/rendering/list';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import useLinkObjectForm from './link-object-form.controller';

const ID = 'link-object-form';
export interface LinkObjectFormProps<T extends { id: string }> {
    type: EntityType;
    onSubmit: (ojbects: T[]) => Promise<any>;
    dispatch: ThunkDispatch<any, any, Action>;
    fetch: (arg?: ApiQueryParams<DefaultQueryParams | string | number>) => Promise<PaginatedResponse<T>>;
    columns: Omit<ObjectColumnsProps<T>, 'dispatch'>;
    submitDisabled?: boolean;
}

export const LinkObjectForm = <T extends { id: string }>(props: LinkObjectFormProps<T>) => {
    const viewProps = useLinkObjectForm(props);
    const navigate = useNavigate();

    return (
        <form
            onSubmit={viewProps.onSubmit}
            id={ID}
            className='card'>
            <ObjectList<T>
                id={ID + '-' + props.type + '-list'}
                label={CommonMessage.BUTTONS.LINK}
                type={props.type}
                dispatch={props.dispatch}
                fetch={(args) => props.fetch(args)}
                columns={
                    props.columns ?? {
                        columns: ['id'],
                        headerOverwrite: new Map([['id', CommonMessage.OBJECTS.COMMON_FIELDS.ITEM]]),
                        showIdAsEntity: props.type
                    }
                }
                multiSelectOptions={{
                    custom: [
                        {
                            text: CommonMessage.BUTTONS.CANCEL,
                            type: ClassType.SECONDARY,
                            onClick: (e) => {e.preventDefault(); navigate(-1);}
                        },
                        {
                            text: CommonMessage.BUTTONS.LINK,
                            enabled: (obj) => obj.length > 0,
                            type: ClassType.PRIMARY,
                            icon: ICONS.BUTTON.LINK,
                            onClick: (e) => viewProps.onSubmit && viewProps.onSubmit(e)
                        }
                    ]
                }}
                selectedObjects={viewProps.changeObjects}
                cardClassName=''
                filter={{ default: true }}
            />
        </form>
    );
};

export default LinkObjectForm;
