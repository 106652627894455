import { lazy } from 'react';
import { Route } from 'react-router-dom';

const BrandingOverview = lazy(() => import('./branding-overview'));
const BrandingCreate = lazy(() => import('./branding-create/branding-create.component'));
const BrandingUpdate = lazy(() => import('./branding-update/branding-update.component'));

export function BrandingRoutes() {
    return (
        <Route path='brandings'>
            <Route
                index
                element={<BrandingOverview />}
            />
            <Route
                path='create'
                element={<BrandingCreate />}
            />
            <Route path=':brandingId'>
                <Route
                    path='update'
                    element={<BrandingUpdate />}
                />
            </Route>
        </Route>
    );
}
