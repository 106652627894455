import { useListItem } from '@floating-ui/react';
import React, { useContext } from 'react';

import { SelectContext } from './select';
import { SingleOption } from './select-types';

export function Option<T>(props: SingleOption<T>) {
    const { getItemProps, handleSelect } = useContext(SelectContext);
    const { ref } = useListItem({ label: props.value });

    return (
        <li
            className='list-group-item list-group-item-action option'
            ref={ref}
            role='option'
            {...getItemProps({
                onClick: (e) => {
                    e.stopPropagation();
                    handleSelect(props);
                }
            })}>
            {props.display ?? props.label}
        </li>
    );
}
