import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import {
    CreateNotificationTemplate,
    NotificationTemplate,
    NotificationTemplateListResponse,
    NotificationTemplateQueryParams,
    UpdateNotificationTemplate
} from '@frontend/notification-service/types';

const endpoint = '/notification-api/v1';
export class NotificationTemplateClient extends APIClient {
    public static async fetchNotificationTemplates(
        queryParams?: ApiQueryParams<DefaultQueryParams | NotificationTemplateQueryParams>
    ): Promise<NotificationTemplateListResponse> {
        return await this.apiPaginated<NotificationTemplateListResponse, DefaultQueryParams | NotificationTemplateQueryParams>(
            `${endpoint}/templates`,
            queryParams
        );
    }

    public static async resolveNotificationTemplates(ids: string[]): Promise<NotificationTemplateListResponse> {
        const response = await this.post(`${endpoint}/templates-resolve`, { ids: ids });
        return await this.handleResponse<NotificationTemplateListResponse>(response);
    }

    public static async fetchAccountNotificationTemplates(
        accountId: string,
        queryParams?: ApiQueryParams<DefaultQueryParams>
    ): Promise<NotificationTemplateListResponse> {
        return await this.apiPaginated<NotificationTemplateListResponse, DefaultQueryParams>(`${endpoint}/accounts/${accountId}/templates`, queryParams);
    }

    public static async postNotificationTemplate(accountId: string, notificationTemplate: CreateNotificationTemplate): Promise<NotificationTemplate> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/templates`, notificationTemplate);
        return await this.handleResponse<NotificationTemplate>(response);
    }

    public static async fetchNotificationTemplate(accountId: string, notificationTemplateId: string): Promise<NotificationTemplate> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/templates/${notificationTemplateId}`);
        return await this.handleResponse<NotificationTemplate>(response);
    }

    public static async patchNotificationTemplate(
        accountId: string,
        notificationTemplateId: string,
        notificationTemplate: UpdateNotificationTemplate
    ): Promise<NotificationTemplate> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/templates/${notificationTemplateId}`, notificationTemplate);
        return await this.handleResponse<NotificationTemplate>(response);
    }

    public static async deleteNotificationTemplate(accountId: string, notificationTemplateId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/templates/${notificationTemplateId}`);
        return await this.handleVoidResponse(response);
    }

    public static async copyTemplate(accountId: string, templateId: string, body: { account_id: string }): Promise<NotificationTemplate> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/templates/${templateId}/copy`, body);
        return await this.handleResponse<NotificationTemplate>(response);
    }
}
