import { AuthenticationManager } from '@frontend/authentication-v2';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface ViewProps {
    submitted: boolean;
    passwordValid: boolean;
    passwordConfirmationValid: boolean;
    changePasswordValid: Dispatch<SetStateAction<boolean>>;
    changePasswordConfirmationValid: Dispatch<SetStateAction<boolean>>;
    onSubmit: (e: any) => void;
    response: { type: string; message: string } | undefined;
    newPassword: string;
    changeNewPassword: Dispatch<SetStateAction<string>>;
    repeatPassword: string;
    changeRepeatPassword: Dispatch<SetStateAction<string>>;
}

const useForgotPasswordConfirmation = (): ViewProps => {
    const { pathname } = useLocation();
    const token = pathname.split('/')[2];
    const [response, changeResponse] = useState<{ type: string; message: string } | undefined>(undefined);
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [newPassword, changeNewPassword] = useState<string>('');
    const [repeatPassword, changeRepeatPassword] = useState<string>('');
    const [passwordValid, changePasswordValid] = useState<boolean>(true);
    const [passwordConfirmationValid, changePasswordConfirmationValid] = useState<boolean>(true);

    const onSubmit = async (e: any) => {
        e.preventDefault();
        changeSubmitted(true);
        const response = await AuthenticationManager.getInstance().confirmForgotPassword(token, newPassword);
        changeResponse(response);
    };

    useEffect(() => {
        if (response && response.type === 'success') {
            setTimeout(() => {
                window.location.href = '/';
            }, 3000);
        }
    }, [response]);

    return {
        submitted,
        changePasswordConfirmationValid,
        changePasswordValid,
        passwordConfirmationValid,
        passwordValid,
        onSubmit,
        response,
        changeNewPassword,
        newPassword,
        changeRepeatPassword,
        repeatPassword
    };
};

export default useForgotPasswordConfirmation;
