import React, { ReactNode } from 'react';
import { FaPlus } from 'react-icons/fa';
import { GroupBase, InputActionMeta, OptionsOrGroups } from 'react-select';
import AsyncSelect from 'react-select/async';

import { getClassName } from '../input-class';
import useAsyncSelectInputSingle from './async-select-input-single.controller';

const ID = 'async-select-input-single';
export interface AsyncSelectInputSingleProps {
    isClearable?: boolean;
    isDisabled?: boolean;
    cacheOptions?: any;
    loadOptions?: (
        inputValue: string,
        callback: (options: OptionsOrGroups<any, GroupBase<any>>) => void
    ) => void | Promise<OptionsOrGroups<any, GroupBase<any>>>;
    options?: { value: string; label: string }[] | { label: string; options: { value: string; label: string }[] }[];
    placeholder?: ReactNode;
    noOptionsMessage?: (obj: { inputValue: string }) => ReactNode;
    onChange: (newValue: { value: string; label: string }) => void;
    onInputChange?: ((newValue: string, actionMeta: InputActionMeta) => void) | undefined;
    onMenuScrollToBottom?: (event: WheelEvent | TouchEvent) => void;
    id?: string;
    required?: boolean;
    label: React.ReactNode;
    helpMessage?: React.ReactNode;
    valid?: boolean;
    isValidCallback?: (valid: boolean) => void;
    onFocusChange?: () => void;
    onBlur?: () => void;
    submitted: boolean;
    value?: string | null;
    useConditionedStyling?: boolean;
    className?: string;
    errorMessage?: React.ReactNode;

    onCreate?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

/**
 * @deprecated
 */
export const AsyncSelectInputSingle = (props: AsyncSelectInputSingleProps) => {
    const viewProps = useAsyncSelectInputSingle(props);

    return (
        <div
            id={props.id || ID}
            className={props.className || 'form-group'}>
            <label className='text-primary'>
                {props.required && <span className='text-danger me-1'>&#9679;</span>} {props.label}
                {props.helpMessage && (
                    <>
                        <br />
                        <small className='form-text text-muted'>{props.helpMessage}</small>
                    </>
                )}
            </label>
            <div className='d-flex flex-row align-items-end'>
                <AsyncSelect
                    styles={styles}
                    className='w-100'
                    classNames={{
                        control: () =>
                            props.useConditionedStyling === false
                                ? 'form-control'
                                : getClassName(viewProps.touched, viewProps.valid, props.value == null ? undefined : JSON.stringify(props.value))
                    }}
                    isClearable={props.isClearable}
                    isDisabled={props.isDisabled}
                    value={viewProps.findValue()}
                    cacheOptions={props.cacheOptions}
                    loadOptions={props.loadOptions}
                    defaultOptions={props.options}
                    minMenuHeight={300}
                    formatGroupLabel={(group) => (
                        <div className='d-flex justify-content-between'>
                            <span className='font-weight-bolder text-md text-primary'>{group.label}</span>
                            <span>{group.options.length}</span>
                        </div>
                    )}
                    placeholder={props.placeholder}
                    noOptionsMessage={props.noOptionsMessage}
                    onChange={(newValue: { value: string; label: string }) => {
                        viewProps.changeValue(newValue);
                        props.onChange && props.onChange(newValue);
                        viewProps.isValid();
                    }}
                    onFocus={() => {
                        viewProps.changeTouched(true);
                        props.onFocusChange && props.onFocusChange();
                    }}
                    onBlur={props.onBlur}
                    onInputChange={props.onInputChange}
                    onMenuScrollToBottom={props.onMenuScrollToBottom}
                />

                {props.onCreate && (
                    <button
                        className='d-flex align-items-center justify-content-center btn btn-outline-secondary ms-2 mb-0'
                        style={{ height: '41px', width: '41px' }}
                        onClick={props.onCreate}>
                        <span className='d-flex align-items-center justify-content-center'>
                            <FaPlus />
                        </span>
                    </button>
                )}
            </div>
            {viewProps.valid === false && viewProps.touched === true && (
                <span className='badge bg-gradient-danger mt-2'>
                    <small>{props.errorMessage ? props.errorMessage : 'Please select a valid option.'}</small>
                </span>
            )}
        </div>
    );
};

const styles = {
    control: (baseStyles: any) => ({
        ...baseStyles,
        boxShadow: 'none',
        minWidth: '190px',
        padding: '1.204px 0px 1.204px 10px',
        borderRadius: '0.5rem'

        //TERMINAL S4M - todo
        //padding: ".5rem .75rem",
        // fontSize: "14px",
        // fontWeight: "400",
        // width: "100%",
        // backgroundClip: "padding-box",
        // appearance: "none",
        // backgroundColor: "transparent",
        //borderRadius: "25px",
        // border: "2px solid #000",
        // outline: "none",
    }),
    menu: (baseStyle: any) => ({
        ...baseStyle,
        zIndex: 9999,
        minWidth: 'fit-content'
    }),
    valueContainer: (baseStyle: any) => ({
        ...baseStyle,
        padding: '0px',
        minWidth: 'fit-content'
    })
};
