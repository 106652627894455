import { APIClient, ApiQueryParams } from "@frontend/api-utils";
import { EntityType } from "@frontend/common";
import { CreateGroup, CreateGroupMember, Group, GroupListResponse, GroupMember, GroupMemberListResponse, GroupMemberQueryParams, GroupQueryParams, UpdateGroup, UpdateGroupMember } from "@frontend/group/types";

const endpoint = '/group-api/v1';

export class EquipmentGroupClient extends APIClient {
    public static async fetchEquipmentGroups(queryParams?: ApiQueryParams<GroupQueryParams>): Promise<GroupListResponse> {
        return await this.apiPaginated<GroupListResponse, GroupQueryParams>(`${endpoint}/equipment-groups`, queryParams);
    }

    public static async resolveEquipmentGroups(groupIds: string[]): Promise<GroupListResponse> {
        const response = await this.post(`${endpoint}/equipment-groups-resolve`, { ids: groupIds });
        return await this.handleResponse<GroupListResponse>(response);
    }

    public static async deleteEquipmentGroups(groupIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/equipment-groups-delete`, { ids: groupIds });
        return await this.handleVoidResponse(response);
    }

    public static async postEquipmentGroup(accountId: string, group: CreateGroup): Promise<Group> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/equipment-groups`, group);
        return await this.handleResponse<Group>(response);
    }

    public static async fetchEquipmentGroup(accountId: string, groupId: string): Promise<Group> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/equipment-groups/${groupId}`);
        return await this.handleResponse<Group>(response);
    }

    public static async patchEquipmentGroup(accountId: string, groupId: string, group: UpdateGroup): Promise<Group> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/equipment-groups/${groupId}`, group);
        return await this.handleResponse<Group>(response);
    }

    public static async deleteEquipmentGroup(accountId: string, groupId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/equipment-groups/${groupId}`);
        return await this.handleVoidResponse(response);
    }

    public static async fetchGroupsNotInGroup(accountId: string, groupId: string): Promise<GroupListResponse> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/equipment-groups/${groupId}/groups`);
        return await this.handleResponse<GroupListResponse>(response);
    }

    //Group members
    public static async fetchEquipmentGroupMembers(queryParams?: ApiQueryParams<GroupMemberQueryParams>): Promise<GroupMemberListResponse> {
        return await this.apiPaginated<GroupMemberListResponse, GroupMemberQueryParams>(`${endpoint}/equipment-group-members`, queryParams);
    }

    public static async resolveEquipmentGroupMembers(memberIds: string[]): Promise<GroupMemberListResponse> {
        const response = await this.post(`${endpoint}/equipment-group-members-resolve`, { ids: memberIds });
        return await this.handleResponse<GroupMemberListResponse>(response);
    }

    public static async deleteEquipmentGroupMembers(memberIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/equipment-group-members-delete`, { ids: memberIds });
        return await this.handleVoidResponse(response);
    }

    public static async postEquipmentGroupMember(accountId: string, groupId: string, groupMember: CreateGroupMember): Promise<GroupMember> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/equipment-groups/${groupId}/members`, groupMember);
        return await this.handleResponse<GroupMember>(response);
    }

    public static async postEquipmentGroupMemberList(accountId: string, groupId: string, entity_type: EntityType, memberIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/equipment-groups/${groupId}/${entity_type}s/members-list`, { ids: memberIds });
        return await this.handleVoidResponse(response);
    }

    public static async fetchEquipmentGroupMember(accountId: string, groupId: string, memberId: string): Promise<GroupMember> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/equipment-groups/${groupId}/members/${memberId}`);
        return await this.handleResponse<GroupMember>(response);
    }

    public static async patchEquipmentGroupMember(accountId: string, groupId: string, memberId: string, body: UpdateGroupMember): Promise<GroupMember> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/equipment-groups/${groupId}/members/${memberId}`, body);
        return await this.handleResponse<GroupMember>(response);
    }

    public static async deleteEquipmentGroupMember(accountId: string, groupId: string, memberId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/equipment-groups/${groupId}/members/${memberId}`);
        return await this.handleVoidResponse(response);
    }
}