/* eslint-disable react/prop-types */
// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../react-table-config.d.ts" />
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Column, Row, SortingRule, TableInstance, useExpanded, usePagination, useRowSelect, useSortBy, useTable } from 'react-table';

import { THeader } from './headers/THeader';
import PaginationButtonNavigation from './pagination/pagination-button-navigation';
import { TRow } from './rows/TRow';

type OptionalOmit<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export interface TablePropsV1 {
    headerClassName?: string;
    headerItemClassName?: string;
    rowClassName?: string;
    customRowClassName?: (row: Row<object>) => string | undefined;

    selectEnabled?: boolean;
    expandEnabled?: boolean;
    selectedItemsCallback?: (objects: any[]) => void;
    columns: Column<any>[];
    data: any[];
    autoResetPage?: boolean;
    next?: string | null | undefined;
    count?: number | undefined;
    pageCount?: number | undefined;
    pageSize?: (value: number) => void;
    page?: (value: number) => void;
    onSelectElement?: (object: any) => void;
    RowElement: (props: any) => JSX.Element;
    renderSubComponent?: (props: { row: any }) => React.ReactElement;
    pagination?: {
        page?: (value: number) => void;
        initialPageSize?: number;
        initialPage?: number;
        hidePagination?: boolean;

        allowPageSizeSelect?: boolean;
        allowPageJumpInput?: { bottom?: boolean; top?: boolean };
        allowPageButtonNavigation?: {
            bottom?: boolean;
            bottomProps?: OptionalOmit<Omit<React.ComponentProps<typeof PaginationButtonNavigation>, 'pageIndex'>, 'pageCount'>;
            top?: boolean;
            topProps?: OptionalOmit<Omit<React.ComponentProps<typeof PaginationButtonNavigation>, 'pageIndex'>, 'pageCount'>;
        };
    };
    id?: string;
    sorting?: (value: SortingRule<object>[]) => void;
    hideColumns?: string[];
    sortingColumns?: string[];

    tableReffCallback?: (tableInstance: TableInstance<object>) => void;
    showCount?: boolean;
}

const headerClass = 'sticky-top bg-light bg-gradient shadow-sm';

/**
 * @deprecated (should use the new table element that uses the store more efficiently)
 * @param props
 * @returns
 */
export function TableV1(props: TablePropsV1) {
    const possiblePageSizes = [25, 50, 100, 150];
    if (props.pagination?.initialPageSize !== undefined && !possiblePageSizes.includes(props.pagination.initialPageSize)) {
        possiblePageSizes.push(props.pagination.initialPageSize);
        possiblePageSizes.sort((a, b) => a - b);
    }
    const tableInstance = useTable(
        {
            columns: props.columns,
            data: props.data,
            autoResetPage: props.autoResetPage ? props.autoResetPage : false,
            manualPagination: props.pageCount ? true : false,
            initialState: { pageSize: props.pagination?.initialPageSize ?? 25, pageIndex: props.pagination?.initialPage ?? 0 },
            ...(props.pageCount && { pageCount: props.pageCount }),
            manualSortBy: true,
            autoResetExpanded: false
        },
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        selectedFlatRows,
        state: { pageIndex, pageSize, sortBy }
    } = tableInstance;

    useEffect(() => {
        props.tableReffCallback && props.tableReffCallback(tableInstance);
    }, [props.tableReffCallback, tableInstance, props.data]);
    useEffect(() => {
        if (props.selectEnabled && props.selectedItemsCallback) {
            props.selectedItemsCallback(
                selectedFlatRows.filter((row) => row.index >= pageIndex * pageSize && row.index < (pageIndex + 1) * pageSize).map((r) => r.original)
            );
        }
    }, [selectedFlatRows]);
    useEffect(() => {
        setPageSize(props.pagination?.initialPageSize ?? 25);
    }, []);

    useEffect(() => {
        if (props.pageSize) {
            props.pageSize(pageSize);
        }
        if (props.page) {
            props.pagination?.page && props.pagination.page(pageIndex);
            props.page(pageIndex);
        }
    }, [pageSize, pageIndex]);

    useEffect(() => {
        props.sorting && props.sorting(sortBy);
    }, [sortBy]);

    useEffect(() => {
        tableInstance.setHiddenColumns(props.hideColumns ?? []);
    }, [props.hideColumns]);

    const startRow = pageSize * pageIndex;
    const endRow = startRow + pageSize;

    const hidePagination = props.pagination?.hidePagination ?? props.data.length < pageSize;

    return (
        <div id={props.id}>
            {!hidePagination ? (
                <div className='d-flex justify-content-between p-2'>
                    <div className='d-flex justify-content-start'>
                        {(!props.pagination || props.pagination.allowPageSizeSelect !== false) && (
                            <div className='d-flex flex-row dataTable-dropdown align-items-center'>
                                <select
                                    className='form-control me-2'
                                    value={pageSize}
                                    onChange={(e) => {
                                        setPageSize(Number(e.target.value));
                                    }}>
                                    {possiblePageSizes.map((pageSize) => (
                                        <option
                                            key={pageSize}
                                            value={pageSize}>
                                            {pageSize}
                                        </option>
                                    ))}
                                </select>
                                <span className='text-nowrap text-secondary'>
                                    <FormattedMessage
                                        id='table.entriesPerPage'
                                        description='The entries per page in the default table message.'
                                        defaultMessage='entries per page'
                                    />{' '}
                                    <strong>
                                        <small>
                                            <FormattedMessage
                                                id='table.totalEntries'
                                                description='The entries per page in the default table message.'
                                                defaultMessage='(total entries: {total})'
                                                values={{ total: props.data.length }}
                                            />
                                        </small>
                                    </strong>
                                </span>
                            </div>
                        )}
                    </div>
                    {(!props.pagination || !props.pagination.allowPageJumpInput || props.pagination.allowPageJumpInput.top !== false) && (
                        <span className='d-flex flex-row align-items-center text-secondary'>
                            <FormattedMessage
                                id='table.currentPage'
                                description='The current page in the default table message.'
                                defaultMessage='Page'
                            />
                            <input
                                className='form-control mx-1'
                                type='number'
                                defaultValue={pageIndex + 1}
                                value={pageIndex + 1}
                                onChange={(e) => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                    gotoPage(page);
                                }}
                                style={{ width: '75px' }}
                            />
                            <strong className='text-secondary'>
                                <FormattedMessage
                                    id='table.currentPage.ofPages'
                                    description='The current page of total amount of pages in the default table message.'
                                    defaultMessage='of'
                                />
                                {' ' + (props.pageCount ? pageCount : pageOptions.length)}
                            </strong>
                        </span>
                    )}

                    {(!props.pagination || !props.pagination.allowPageButtonNavigation || props.pagination.allowPageButtonNavigation.top !== false) &&
                        pageCount > 1 && (
                            <PaginationButtonNavigation
                                id={props.id + '-top'}
                                gotoPage={gotoPage}
                                previousPage={previousPage}
                                nextPage={nextPage}
                                pageCount={pageCount}
                                pageIndex={pageIndex}
                                {...props.pagination?.allowPageButtonNavigation?.topProps}
                            />
                        )}
                </div>
            ) : (
                <>
                    {(!props.pagination || props.pagination.allowPageSizeSelect !== false) && props.showCount && props.data.length > 0 && (
                        <div className='d-flex flex-row justify-content-start'>
                            <strong>
                                <small>
                                    <FormattedMessage
                                        id='table.totalEntries'
                                        description='The entries per page in the default table message.'
                                        defaultMessage='(total entries: {total})'
                                        values={{ total: props.data.length }}
                                    />
                                </small>
                            </strong>
                        </div>
                    )}
                </>
            )}
            <table
                {...getTableProps()}
                className='table align-items-center mb-0'>
                <thead className={props.headerClassName || headerClass}>
                    {headerGroups.map((headerGroup, index) => (
                        <THeader
                            className={props.headerItemClassName}
                            key={index + '_' + headerGroup.id}
                            headerGroup={headerGroup}
                            sortingColumns={props.sortingColumns}
                        />
                    ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                    {page.length === 0 && (
                        <tr>
                            <td>
                                <p className='text-secondary'>No data to display</p>
                            </td>
                        </tr>
                    )}
                    {props.pageCount
                        ? page.map((row) => {
                              if (startRow <= row.index && row.index < endRow) {
                                  prepareRow(row);
                                  return (
                                      <>
                                          <TRow
                                              className={props.customRowClassName ? props.customRowClassName(row) : props.rowClassName}
                                              key={row.id}
                                              row={row}
                                              expandEnabled={props.expandEnabled}
                                              selectEnabled={props.selectEnabled}
                                              onClick={props.onSelectElement}
                                              RowElement={props.RowElement}
                                          />
                                          {row.isExpanded ? <>{props.renderSubComponent && props.renderSubComponent({ row })}</> : null}
                                      </>
                                  );
                              } else return <></>;
                          })
                        : page.map((row) => {
                              prepareRow(row);
                              return (
                                  <>
                                      <TRow
                                          className={props.customRowClassName ? props.customRowClassName(row) : props.rowClassName}
                                          key={row.id}
                                          row={row}
                                          expandEnabled={props.expandEnabled}
                                          selectEnabled={props.selectEnabled}
                                          onClick={props.onSelectElement}
                                          RowElement={props.RowElement}
                                      />
                                      {row.isExpanded ? <>{props.renderSubComponent && props.renderSubComponent({ row })}</> : null}
                                  </>
                              );
                          })}
                </tbody>
            </table>
            {!props.pagination?.hidePagination && (
                <>
                    {pageCount > 1 && <hr className='horizontal dark my-3' />}

                    {pageCount > 1 && (
                        <div className='d-flex flex-row justify-content-between align-items-center m-2'>
                            {(!props.pagination || !props.pagination.allowPageJumpInput || props.pagination.allowPageJumpInput.bottom !== false) && (
                                <span className='d-flex flex-row align-items-center text-secondary'>
                                    <FormattedMessage
                                        id='table.currentPage'
                                        description='The current page in the default table message.'
                                        defaultMessage='Page'
                                    />
                                    <input
                                        className='form-control mx-1'
                                        type='number'
                                        defaultValue={pageIndex + 1}
                                        onChange={(e) => {
                                            const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                            gotoPage(page);
                                        }}
                                        style={{ width: '75px' }}
                                    />
                                    <strong className='text-secondary'>
                                        <FormattedMessage
                                            id='table.currentPage.ofPages'
                                            description='The current page of total amount of pages in the default table message.'
                                            defaultMessage='of'
                                        />
                                        {' ' + (props.pageCount ? pageCount : pageOptions.length)}
                                    </strong>
                                </span>
                            )}

                            {(!props.pagination ||
                                !props.pagination.allowPageButtonNavigation ||
                                props.pagination.allowPageButtonNavigation.bottom !== false) && (
                                <PaginationButtonNavigation
                                    id={props.id + '-bottom'}
                                    gotoPage={gotoPage}
                                    previousPage={previousPage}
                                    nextPage={nextPage}
                                    pageCount={pageCount}
                                    pageIndex={pageIndex}
                                    {...props.pagination?.allowPageButtonNavigation?.bottomProps}
                                />
                            )}
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
