import { Package } from '@frontend/package/types';
import { EventListener } from '@frontend/pub-sub';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

export class PackageEventListener extends EventListener<Package> {
    private static instance: PackageEventListener | null = null;
    private constructor(dispatch: ThunkDispatch<any, any, Action>, variables?: Array<{ [key: string]: any }>) {
        super('package', dispatch, variables);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>, variables?: Array<{ [key: string]: any }>): PackageEventListener {
        if (this.instance == null) {
            this.instance = new PackageEventListener(dispatch, variables);
        }
        return this.instance;
    }

    getInstance(dispatch: ThunkDispatch<any, any, Action>, variables?: Array<{ [key: string]: any }>): EventListener<Package> {
        return PackageEventListener.getInstance(dispatch, variables);
    }

    protected onUpdate() {}
    protected onDelete() {}
    protected onCreate() {}
}
