import { UserCertificateClient } from '@frontend/certificate/api';
import { EntityType } from '@frontend/common';
import { useResolver } from '@frontend/repository';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

declare global {
    interface WindowEventMap {
        'useUserCertificate:request': CustomEvent<string>;
    }
}

const REQUEST_USER_CERTIFICATE_EVENT = 'useUserCertificate:request';
const STORAGE_KEY = 'USER_CERT_REQ';

export function userCertificateRequest(certificateId: string): string {
    window.dispatchEvent(new CustomEvent(REQUEST_USER_CERTIFICATE_EVENT, { detail: certificateId }));
    return STORAGE_KEY + '_loading';
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function UserCertificateResolver(props: Props) {
    useResolver(
        STORAGE_KEY,
        REQUEST_USER_CERTIFICATE_EVENT,
        EntityType.USER_CERTIFICATE,
        (ids) => UserCertificateClient.resolveUserCertificates(ids),
        props.dispatch
    );
    return <></>;
}
