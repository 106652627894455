import { EntityType } from '@frontend/common';
import { getCorrectTranslation, useLocale } from '@frontend/lang';
import { problemRequest } from '@frontend/problem/events';
import { Problem } from '@frontend/problem/types';
import { ObjectBadge, ObjectBadgeProps } from '@frontend/rendering/badge';
import React from 'react';

export const ProblemBadge = (props: ObjectBadgeProps<Problem>) => {
    const { locale } = useLocale();
    return (
        <ObjectBadge<Problem>
            id={props.id}
            type={EntityType.PROBLEM}
            mapDisplayName={(ac: Problem) => getCorrectTranslation(locale, ac, 'description')}
            resolve={problemRequest}
            navigateString={(object: Problem) => `/accounts/${object.account_id}/problems/${object.id}`}
            {...props}
        />
    );
};
