import { AddressBadge } from '@frontend/address/badge';
import { AttributeBadge } from '@frontend/attribute/badge';
import { CertificateBadge, EquipmentCertificateBadge, UserCertificateBadge } from '@frontend/certificate/badge';
import { EntityType } from '@frontend/common';
import { DocumentBadge } from '@frontend/document/badge';
import { GroupBadge } from '@frontend/group/badge';
import { PackageBadge } from '@frontend/package/badge';
import { PlaylistBadge } from '@frontend/playlist/badge';
import { ProblemBadge } from '@frontend/problem/badge';
import { AnswerBadge, QuestionBadge } from '@frontend/question/badge';
import { CustomRoleBadge } from '@frontend/role/badge';
import { TransactionBadge } from '@frontend/transaction/badge';
import { UserInterfaceBadge } from '@frontend/user-interface/badge';
import { VariantBadge } from '@frontend/variant/badge';
import React, { lazy } from 'react';

const AccountBadge = lazy(() => import('@frontend/account/badge').then((module) => ({ default: module.AccountBadge })));
const ContactBadge = lazy(() => import('@frontend/contact/badge').then((module) => ({ default: module.ContactBadge })));
const DeviceBadge = lazy(() => import('@frontend/device/badge').then((module) => ({ default: module.DeviceBadge })));
const IoTBadge = lazy(() => import('@frontend/iot/badge').then((module) => ({ default: module.IoTBadge })));
const ModuleBadge = lazy(() => import('@frontend/module/badge').then((module) => ({ default: module.ModuleBadge })));
const MovementBadge = lazy(() => import('@frontend/stock/badge').then((module) => ({ default: module.MovementBadge })));
const ProductBadge = lazy(() => import('@frontend/product/badge').then((module) => ({ default: module.ProductBadge })));
const SlotBadge = lazy(() => import('@frontend/slot/badge').then((module) => ({ default: module.SlotBadge })));
const SpotBadge = lazy(() => import('@frontend/spot/badge').then((module) => ({ default: module.SpotBadge })));
const StockBadge = lazy(() => import('@frontend/stock/badge').then((module) => ({ default: module.StockBadge })));
const UserBadge = lazy(() => import('@frontend/user/badge').then((module) => ({ default: module.UserBadge })));
const WorkflowBadge = lazy(() => import('@frontend/workflow/badge').then((module) => ({ default: module.WorkflowBadge })));
const CostCenterBadge = lazy(() => import('@frontend/cost-center/badge').then((module) => ({ default: module.CostCenterBadge })));
const BudgetBadge = lazy(() => import('@frontend/budget/badge').then((module) => ({ default: module.BudgetBadge })));
const EquipmentBadge = lazy(() => import('@frontend/equipment/badge').then((module) => ({ default: module.EquipmentBadge })));

const ID = 'object-breadcrumb';
export interface ObjectBreadcrumbProps {
    value: string;
    type: string;
}

const ObjectBreadcrumb = (props: ObjectBreadcrumbProps) => {
    switch (props.type) {
        case 'accounts':
            return (
                <AccountBadge
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'workflows':
            return (
                <WorkflowBadge
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'contacts':
        case 'address-book':
            return (
                <ContactBadge
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'users':
        case 'user':
            return (
                <UserBadge
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'devices':
            return (
                <DeviceBadge
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'spots':
            return (
                <SpotBadge
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'slots':
            return (
                <SlotBadge
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'modules':
            return (
                <ModuleBadge
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'products':
            return (
                <ProductBadge
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'product-categories':
            return (
                <GroupBadge
                    type={EntityType.PRODUCT_CATEGORY}
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'cost-centers':
            return (
                <CostCenterBadge
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'budgets':
            return (
                <BudgetBadge
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'equipments':
            return (
                <EquipmentBadge
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'iots':
            return (
                <IoTBadge
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'problems':
            return (
                <ProblemBadge
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'stocks':
            return (
                <StockBadge
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'movements':
            return (
                <MovementBadge
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'user-groups':
            return (
                <GroupBadge
                    type={EntityType.USER_GROUP}
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'questionnaires':
            return (
                <GroupBadge
                    type={EntityType.QUESTIONNAIRE}
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'equipment-groups':
            return (
                <GroupBadge
                    type={EntityType.EQUIPMENT_GROUP}
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'product-groups':
            return (
                <GroupBadge
                    type={EntityType.PRODUCT_GROUP}
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'contact-groups':
            return (
                <GroupBadge
                    type={EntityType.CONTACT_GROUP}
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'roles':
            return (
                <CustomRoleBadge
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'addresses':
            return (
                <AddressBadge
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'playlists':
            return (
                <PlaylistBadge
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'questions':
            return (
                <QuestionBadge
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'answers':
            return (
                <AnswerBadge
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'documents':
            return (
                <DocumentBadge
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'user-interfaces':
            return (
                <UserInterfaceBadge
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'transactions':
            return (
                <TransactionBadge
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'packages':
            return (
                <PackageBadge
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'variants':
            return (
                <VariantBadge
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'attributes':
            return (
                <AttributeBadge
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'certificates':
            return (
                <CertificateBadge
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'user-certificates':
            return (
                <UserCertificateBadge
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
        case 'equipment-certificates':
            return (
                <EquipmentCertificateBadge
                    id={props.value}
                    className='opacity-5 text-sm text-white cursor-pointer'
                />
            );
    }

    return <div id={ID}>[placeholder]</div>;
    // return <div id={ID}>{props.type}</div>;
};

export default ObjectBreadcrumb;
