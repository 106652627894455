import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

const CostCenterDetail = lazy(() => import('../cost-center/cost-center-detail/cost-center-detail.component'));
const CostCenterOverview = lazy(() => import('../cost-center/cost-center-overview'));
const CostCenterCreate = lazy(() => import('../cost-center/cost-center-create/cost-center-create.component'));
const CostCenterUpdate = lazy(() => import('../cost-center/cost-center-update/cost-center-update.component'));

export function CostCenterRoutes() {
    return (
        <Route path='cost-centers'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <CostCenterOverview />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <CostCenterCreate />
                    </AsyncComponent>
                }
            />
            <Route
                index
                element={<Navigate to='/admin/cost-centers' />}
            />
            <Route path=':costCenterId'>
                <Route
                    path='detail'
                    element={
                        <AsyncComponent>
                            <CostCenterDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <CostCenterUpdate />
                        </AsyncComponent>
                    }
                />
            </Route>
        </Route>
    );
}
