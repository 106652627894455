/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { AccountClient } from '@frontend/account/api';
import { BrandingClient } from '@frontend/branding/api';
import { useLocalStorageState } from '@frontend/common';
import { useAccountRepository } from '@frontend/repository';
import { useEffect, useMemo, useRef } from 'react';
import { BsShop } from 'react-icons/bs';
import { FaHandHolding, FaHome, FaRegAddressBook } from 'react-icons/fa';
import { FaLocationDot } from 'react-icons/fa6';
import { GiVendingMachine } from 'react-icons/gi';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { LuPackage, LuWarehouse } from 'react-icons/lu';
import { TbClipboardText } from 'react-icons/tb';

import defaultLogoCollapsed from '../../../assets/images/logo200x200.png';
import defaultLogoExpanded from '../../../assets/images/telloport_logo_horizontal.png';
import { useTheme } from '../../theme/theme-resolver';
import { NavbarGroupItem } from '../navbar-group-item/navbar-group-item.component';
import NavbarItem from '../navbar-item/navbar-item.component';

const ID = 'sidenav-main';

const Navbar = () => {
    useTheme();
    const { store } = useAccountRepository();
    const navbarRef = useRef<HTMLDivElement>(null);
    const [navbarPinned, changeNavbarPinned] = useLocalStorageState('navbar-pinned', false);
    const [hovered, changeHovered] = useLocalStorageState('navbar-hovered', false);
    const [logoNavbarExpanded, changeLogoNavbarExpanded] = useLocalStorageState<string | undefined>('logo_navbar_expanded', '');
    const [logoNavbarCollapsed, changeLogoNavbarCollapsed] = useLocalStorageState<string | undefined>('logo_navbar_collapsed', '');
    useEffect(() => {
        if (store.currentAccount) {
            AccountClient.fetchAccount(store.currentAccount).then((account) => {
                if (!account.branding_id) {
                    changeLogoNavbarCollapsed(defaultLogoCollapsed);
                    changeLogoNavbarExpanded(defaultLogoExpanded);
                } else {
                    BrandingClient.fetchBranding(account.branding_id).then((res) => {
                        changeLogoNavbarExpanded((res.logo_navbar_expanded ?? '') as string);
                        changeLogoNavbarCollapsed((res.logo_navbar_collapsed ?? '') as string);
                    });
                }
            });
        }
    }, [store.currentAccount]);

    const logo = useMemo(() => {
        if ((!logoNavbarExpanded || !logoNavbarCollapsed) && (logoNavbarExpanded || logoNavbarCollapsed)) return logoNavbarExpanded || logoNavbarCollapsed;
        if (navbarPinned || hovered) return logoNavbarExpanded || defaultLogoExpanded;
        else return logoNavbarCollapsed || defaultLogoCollapsed;
    }, [navbarPinned, hovered, logoNavbarExpanded, logoNavbarCollapsed]);

    return (
        <div
            id={ID}
            onMouseOver={() => changeHovered(true)}
            onMouseLeave={() => changeHovered(false)}
            className='sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 fixed-start border border-danger'>
            <div className='sidenav-header d-flex flex-row justify-content-between align-items-center'>
                <a
                    className='navbar-brand m-0'
                    href='/'>
                    <img
                        src={logo}
                        className='navbar-brand-img h-100'
                        alt='main_logo'
                    />
                </a>
                {(navbarPinned || hovered) && (
                    <div className='sidenav-toggler sidenav-toggler-inner d-block pe-5'>
                        <div
                            onClick={() => changeNavbarPinned(!navbarPinned)}
                            className='nav-link p-0 cursor-pointer'>
                            <div className='sidenav-toggler-inner'>
                                <i className='sidenav-toggler-line'></i>
                                <i className='sidenav-toggler-line'></i>
                                <i className='sidenav-toggler-line'></i>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <hr className='horizontal dark mt-0' />

            <div
                className='collapse navbar-collapse w-auto h-auto'
                id='sidenav-collapse-main'
                ref={navbarRef}>
                <ul className='navbar-nav h-100'>
                    <NavbarItem
                        icon={<FaHome />}
                        link={NavbarItems.PROFILE}
                        text='Profile'
                        enabled
                    />
                    <NavbarGroupItem
                        navbarRef={navbarRef}
                        title='Vending'
                        link='Vending'
                        icon={GiVendingMachine}>
                        <>
                            <NavbarItem
                                link={NavbarItems.INVENTORY}
                                text='Inventory'
                            />
                            <NavbarItem
                                link={NavbarItems.LOCATIONS}
                                text='Locations'
                            />
                        </>
                    </NavbarGroupItem>
                    <NavbarGroupItem
                        navbarRef={navbarRef}
                        title='Lending'
                        link='Lending'
                        icon={FaHandHolding}>
                        <>
                            <NavbarItem
                                link={NavbarItems.ASSETS}
                                text='Assets'
                            />
                            <NavbarItem
                                link={NavbarItems.LOCATIONS}
                                text='Locations'
                            />
                        </>
                    </NavbarGroupItem>
                    <NavbarItem
                        link={NavbarItems.PUDO}
                        text='PuDo'
                        icon={<LuPackage />}
                        enabled
                    />
                    <NavbarGroupItem
                        navbarRef={navbarRef}
                        title='Warehouse'
                        link='Warehouse'
                        icon={LuWarehouse}>
                        <>
                            <NavbarItem
                                link={NavbarItems.INVENTORY}
                                text='Inventory'
                                parent='warehouse'
                            />
                            <NavbarItem
                                link={NavbarItems.HISTORY}
                                text='History'
                                parent='warehouse'
                            />
                        </>
                    </NavbarGroupItem>
                    <NavbarGroupItem
                        navbarRef={navbarRef}
                        title='Shop'
                        link='Shop'
                        icon={BsShop}>
                        <>
                            <NavbarItem
                                link={NavbarItems.PRODUCTS}
                                text='Products'
                            />
                            <NavbarItem
                                link={NavbarItems.LOCATIONS}
                                text='Locations'
                            />
                        </>
                    </NavbarGroupItem>
                    <NavbarGroupItem
                        navbarRef={navbarRef}
                        title='Inventory'
                        link='Inventory'
                        icon={TbClipboardText}>
                        <>
                            <NavbarItem
                                link={NavbarItems.PRODUCTS}
                                text='Products'
                            />
                            <NavbarItem
                                link={NavbarItems.CATALOGUES}
                                text='Catalogues'
                            />
                        </>
                    </NavbarGroupItem>
                    <NavbarItem
                        link={NavbarItems.ADDRESS_BOOK}
                        text='Address book'
                        icon={<FaRegAddressBook />}
                        enabled
                    />
                    <NavbarGroupItem
                        navbarRef={navbarRef}
                        title='distriSPOTs'
                        link='distriSPOTs'
                        icon={FaLocationDot}>
                        <>
                            <NavbarItem
                                link={NavbarItems.SPOTS}
                                text='My Spots'
                            />
                            <NavbarItem
                                link={NavbarItems.SHARED_SPOTS}
                                text='Shared Spots'
                            />
                        </>
                    </NavbarGroupItem>
                    <NavbarItem
                        link={NavbarItems.STATISTICS}
                        text='Statistics'
                        icon={<HiOutlineDocumentReport />}
                        enabled
                    />
                </ul>
            </div>
        </div>
    );
};

export enum NavbarItems {
    PROFILE = 'profile',
    INVENTORY = 'inventory',
    LOCATIONS = 'locations',
    ASSETS = 'assets',
    PUDO = 'pudo',
    PRODUCTS = 'products',
    CATALOGUES = 'catalogues',
    CONTACTS = 'contacts',
    ADDRESS_BOOK = 'address-book',
    SPOTS = 'spots',
    SHARED_SPOTS = 'shared-spots',
    REPORTING = 'reporting',
    COST_CENTERS = 'cost-centers',
    MONITORING = 'monitoring',
    HISTORY = 'history',
    STATISTICS = 'statistics'
}

export default Navbar;
