import { useAccount } from '@frontend/account/utils';
import { ErrorHandler } from '@frontend/error-handler';
import { EventListener } from '@frontend/pub-sub';
import { ToastUtil } from '@frontend/toast-utils';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { clear } from '../list/list-slice';

interface ViewProps<T> {
    object: T | null;
    showDeleteModal: boolean;
    changeShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
    onDeleteConfirmed: () => void;
    onUpdate: () => void;
}

export function useDetailRepository<T>(
    fetch: (...args: any) => Promise<T>,
    onDelete: (...args: any) => Promise<void>,
    /**
     * @description keys should be provided in the correct order to use the fetch and delete function
     */
    urlParamKeys: string[],
    /**
     * @description is used to route back in case of any failure or on delete
     */
    baseRoute: string,
    /**
     * @description is used to map navigation keys to their replacement values for URL construction
     */
    navigationKeyReplacements?: { [key: string]: string },
    updateKeys?: string[],
    eventListener?: EventListener<T>,
    dispatch?: ThunkDispatch<any, any, Action>
): ViewProps<T> {
    const navigate = useNavigate();
    const params = useParams();
    const { accountId } = useParams();
    const { currentAccount } = useAccount();
    const [object, changeObject] = useState<T | null>(null);
    const [showDeleteModal, changeShowDeleteModal] = useState<boolean>(false);
    const urlParams = useMemo(() => urlParamKeys.map((key) => params[key]), [urlParamKeys, params]);
    const [id] = useState<string>(crypto.randomUUID());

    const onFetch = () => {
        if (urlParams.includes(undefined)) {
            ToastUtil.error('Something went wrong trying to get the correct information.');
            navigate(baseRoute.startsWith('/') ? baseRoute : '/admin/' + baseRoute);
        } else
            fetch(...urlParams)
                .then(changeObject)
                .catch(ErrorHandler.handleError);
    };

    useEffect(() => {
        onFetch();
        if (eventListener == undefined || !dispatch) return;
        const onEvent = (message: string) => {
            if (message.includes('created') || message.includes('deleted') || message.includes('multi') || message.includes('update')) onFetch();
        };

        eventListener.getInstance(dispatch).subscribe(onEvent);
        return () => {
            eventListener.getInstance(dispatch).unsubscribe(onEvent);
            dispatch(clear(id));
        };
    }, []);

    useEffect(() => {
        if (currentAccount && accountId !== currentAccount) {
            navigate(baseRoute.startsWith('/') ? baseRoute : '/admin/' + baseRoute);
        }
    }, [currentAccount]);

    const onDeleteConfirmed = () => {
        onDelete(...urlParams).then(() => {
            changeShowDeleteModal(false);
            navigate('../../');
        });
    };

    const onUpdate = () => {
        const urlKeys = updateKeys ? updateKeys : urlParamKeys;
        const urlParts = urlKeys.map(
            (key) =>
                '/' +
                (navigationKeyReplacements && navigationKeyReplacements[key] ? navigationKeyReplacements[key] + '/' : key.replace('Id', 's/')) +
                params[key]
        );
        if (location.pathname.startsWith('/admin')) navigate('/admin' + urlParts.join('') + '/update');
        else navigate(urlParts.join('') + '/update');
    };

    return {
        object,
        showDeleteModal,
        changeShowDeleteModal,
        onDeleteConfirmed,
        onUpdate
    };
}

export default useDetailRepository;
