import { AddressEntityClient } from '@frontend/address/api';
import { EntityType } from '@frontend/common';
import { useResolver } from '@frontend/repository';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

declare global {
    interface WindowEventMap {
        'useAddressEntity:request': CustomEvent<string>;
    }
}

const REQUEST_ADDRESS_ENTITY_EVENT = 'useAddressEntity:request';
const STORAGE_KEY = 'ADDRENT_REQ';

export function addressEntityRequest(addressEntityId: string): string {
    window.dispatchEvent(new CustomEvent(REQUEST_ADDRESS_ENTITY_EVENT, { detail: addressEntityId }));
    return STORAGE_KEY + '_loading';
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function AddressEntityResolver(props: Props) {
    useResolver(STORAGE_KEY, REQUEST_ADDRESS_ENTITY_EVENT, EntityType.ADDRESS_ENTITY, (ids) => AddressEntityClient.resolveAddressEntities(ids), props.dispatch);
    return <></>;
}
