import { EntityType } from '@frontend/common';
import {
    contactGroupRequest,
    equipmentGroupRequest,
    productCategoryRequest,
    productGroupRequest,
    questionnaireRequest,
    userGroupRequest
} from '@frontend/group/events';
import { Group } from '@frontend/group/types';
import { ENTITY_ICONS, iconByType } from '@frontend/icons';
import { ObjectBadge, ObjectBadgeProps } from '@frontend/rendering/badge';
import React from 'react';

export interface GroupBadgeProps extends ObjectBadgeProps<Group> {
    type: EntityType;
}
export const GroupBadge = (props: GroupBadgeProps) => {
    let request: (id: string) => string = userGroupRequest; //default is user group
    let type: string = 'user-groups';
    switch (props.type) {
        case EntityType.USER_GROUP:
            request = userGroupRequest;
            type = 'user-groups';
            break;
        case EntityType.CONTACT_GROUP:
            request = contactGroupRequest;
            type = 'contact-groups';
            break;
        case EntityType.EQUIPMENT_GROUP:
            request = equipmentGroupRequest;
            type = 'equipment-groups';
            break;
        case EntityType.PRODUCT_GROUP:
            request = productGroupRequest;
            type = 'product-groups';
            break;
        case EntityType.PRODUCT_CATEGORY:
            request = productCategoryRequest;
            type = 'product-categories';
            break;
        case EntityType.QUESTIONNAIRE:
            request = questionnaireRequest;
            type = 'questionnaires';
            break;
    }

    return (
        <ObjectBadge<Group>
            id={props.id}
            mapDisplayName={(gr: Group) => gr.name}
            resolve={request}
            overWriteIcon={(gr) => (gr ? iconByType(gr.entity_type) : ENTITY_ICONS.GROUP)}
            navigateString={(object: Group) => `/accounts/${object.account_id}/${type}/${object.id}`}
            {...props}
        />
    );
};
