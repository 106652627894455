import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

const ContactOverview = lazy(() => import('../contact/contact-overview/contact-overview'));
const ContactDetail = lazy(() => import('../contact/contact-detail/contact-detail.component'));
const ContactCreate = lazy(() => import('../contact/contact-create/contact-create.component'));
const ContactUpdate = lazy(() => import('../contact/contact-update/contact-update.component'));
export function ContactRoutes() {
    return (
        <Route path='contacts'>
            <Route element={<Navigate to='/admin/contacts' />} />
            <Route
                index
                element={
                    <AsyncComponent>
                        <ContactOverview />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <ContactCreate />
                    </AsyncComponent>
                }
            />
            <Route path=':contactId'>
                <Route
                    path='detail'
                    element={
                        <AsyncComponent>
                            <ContactDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <ContactUpdate />
                        </AsyncComponent>
                    }
                />
            </Route>
        </Route>
    );
}
