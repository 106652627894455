import { ErrorHandler } from '@frontend/error-handler';
import { ToastUtil } from '@frontend/toast-utils';
import { useEffect, useState } from 'react';

import { ObjectFormProps } from './object-form.component';

interface ViewProps<S extends object> {
    submitted: boolean;
    formValue: S;
    onChange: (value: S) => void;
    submit: (e: any) => void;
}

const useObjectForm = <T extends object, S extends object>(props: ObjectFormProps<T, S>): ViewProps<S> => {
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [formValue, changeFormValues] = useState<S>(props.value);
    const onChange = (value: S) => {
        changeFormValues((prev) => {
            if (props.onChange) {
                props.onChange({ ...prev, ...value });
            }
            return { ...prev, ...value };
        });
    };

    useEffect(() => {
        if (props.object) {
            const formV = JSON.parse(JSON.stringify(formValue));
            Object.entries(props.object).forEach(([key, value]) => {
                if (key in formV) {
                    formV[key] = value;
                }
            });
        }
    }, [props.object]);

    useEffect(() => {
        changeFormValues(props.value);
    }, [props.value]);

    const submit = (e: any) => {
        if (props.onSubmit === undefined) return;
        e.preventDefault();
        changeSubmitted(true);
        props
            .onSubmit(formValue)
            .then((result) => {
                ToastUtil.success(
                    props.successMessageOverwrite
                        ? props.successMessageOverwrite
                        : `Successfully ${props.object ? 'updated' : 'created'} ${(props.type[0].toUpperCase() + props.type.slice(1)).split('_').join(' ')}`
                );
                if (typeof result !== 'undefined') {
                    props.onSubmitSuccess && props.onSubmitSuccess(result);
                } else {
                    props.onVoidSuccess && props.onVoidSuccess(result);
                }
            })
            .catch((e) => ErrorHandler.handleError({ error: e, entity_type: props.type }))
            .finally(() => changeSubmitted(false));
    };

    return {
        submitted,
        formValue,
        onChange,
        submit
    };
};

export default useObjectForm;
