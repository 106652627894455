import { APIClient, ApiQueryParams } from '@frontend/api-utils';
import { ChangeListResponse, ChangeQueryParams } from '@frontend/sync/types';

const endpoint = '/sync-api/v1';
export class ChangeClient extends APIClient {
    public static async fetchServiceChanges(
        serviceName: string,
        queryParams?: ApiQueryParams<ChangeQueryParams>
    ): Promise<ChangeListResponse> {
        return await this.apiPaginated<ChangeListResponse, ChangeQueryParams>(`/${serviceName}/v1/changes`, queryParams);
    }
}
