import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { EntityType } from '@frontend/common';
import { CreateSetting, Setting, SettingListResponse, SettingQueryParams, SettingsTemplateField, UpdateSetting } from '@frontend/setting/types';

const endpoint = '/setting-api/v1';
export class SettingClient extends APIClient {
    public static async fetchSettings(queryParams?: ApiQueryParams<DefaultQueryParams | SettingQueryParams>): Promise<SettingListResponse> {
        return await this.apiPaginated<SettingListResponse, DefaultQueryParams | SettingQueryParams>(`${endpoint}/settings`, queryParams);
    }

    public static async postEntitySetting(accountId: string, entityType: EntityType, entityId: string, setting: CreateSetting): Promise<Setting> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/${entityType}s/${entityId}/settings`, setting);
        return await this.handleResponse<Setting>(response);
    }

    public static async fetchEntitySetting(accountId: string, entityType: EntityType, entityId: string, settingId: string): Promise<Setting> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/${entityType}s/${entityId}/settings/${settingId}`);
        return await this.handleResponse<Setting>(response);
    }

    public static async patchEntitySetting(accountId: string, entityType: EntityType, entityId: string, settingId: string, setting: UpdateSetting): Promise<Setting> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/${entityType}s/${entityId}/settings/${settingId}`, setting);
        return await this.handleResponse<Setting>(response);
    }

    public static async deleteEntitySetting(accountId: string, entityType: EntityType, entityId: string, settingId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/${entityType}s/${entityId}/settings/${settingId}`);
        return await this.handleVoidResponse(response);
    }

    public static async postSettingsTemplate(accountId: string, entityType: EntityType, entityId: string, object: unknown): Promise<SettingsTemplateField[]> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/${entityType}s/${entityId}/settings-template`, { data: object });
        return await this.handleResponse<SettingsTemplateField[]>(response);
    }

    public static async postIoTSeed(accountId: string, iotId: string, body: { account_id: string }): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots/${iotId}/seed`, body);
        return this.handleResponse(response);
    }
}
