import { useEffect, useMemo, useState } from 'react';

import { getClassName } from '../input-class';
import { AsyncSelectInputSingleProps } from './async-select-input-single.component';

interface ViewProps {
    value: any;
    valid: boolean;
    touched: boolean;
    changeValue: React.Dispatch<any>;
    isValid: () => boolean;
    changeTouched: React.Dispatch<React.SetStateAction<boolean>>;
    className: string;
    findValue: () => any;
}

const useAsyncSelectInputSingle = (props: AsyncSelectInputSingleProps): ViewProps => {
    const [touched, changeTouched] = useState<boolean>(false);
    const [valid, changeValid] = useState<boolean>(props.valid !== undefined ? props.valid : true);
    const [value, changeValue] = useState<string | null | undefined>(props.value);
    const className = useMemo(() => {
        return props.useConditionedStyling === false ? 'form-control' : getClassName(touched, valid, props.value);
    }, [props.useConditionedStyling]);

    const isValid = (): boolean => {
        let check = true;
        if ((props.value === undefined || props.value === null) && props.required === true) check = false;
        if (props.valid !== undefined && check === true) check = props.valid;
        changeValid(check);
        return check;
    };

    useEffect(() => {
        if (props.isValidCallback) {
            props.isValidCallback(isValid());
        } else isValid();
    }, [props.value, props.valid]);

    useEffect(() => {
        if (props.submitted) {
            !touched && changeTouched(true);
        }
    }, [props.submitted]);
    const findValue = () => {
        if (props.value !== undefined && props.value !== null) {
            if (props.options && !Object.keys(props.options[0]).includes('options')) {
                const options = props.options as { value: string; label: string }[];
                return options.find((op) => op && op.value === props.value);
            } else if (props.options && Object.keys(props.options[0]).includes('options')) {
                const options = props.options as { label: string; options: { value: string; label: string }[] }[];
                return options.find((op) => op.options.find((o) => o.value === props.value));
            } else return value;
        } else {
            return null;
        }
    };

    return {
        valid,
        value,
        touched,
        className,
        findValue,
        changeValue,
        isValid,
        changeTouched
    };
};

export default useAsyncSelectInputSingle;
