import { NotificationEmailClient } from '@frontend/notification-service/api';
import { NotificationEmail, UpdateNotificationEmail } from '@frontend/notification-service/types';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

interface ViewProps {
    email: NotificationEmail | null;
    formValues: UpdateNotificationEmail;
    changeFormValues: React.Dispatch<React.SetStateAction<UpdateNotificationEmail>>;
    account_id: string | null;
    changeAccount_id: React.Dispatch<React.SetStateAction<string | null>>;
    onCancel: () => void;
    onSubmitSuccess: (email: NotificationEmail) => void;
}

const useNotificationEmailUpdate = (): ViewProps => {
    const navigate = useNavigate();
    const { accountId, emailId } = useParams();
    const [email, changeNotificationEmail] = useState<NotificationEmail | null>(null);
    const [account_id, changeAccount_id] = useState<string | null>(accountId ?? null);
    const [formValues, changeFormValues] = useState<UpdateNotificationEmail>({});

    useEffect(() => {
        if (accountId && emailId) {
            NotificationEmailClient.fetchNotificationEmail(accountId, emailId).then((email) => {
                changeNotificationEmail(email);
                changeFormValues(email);
            });
        }
    }, []);

    const onCancel = () => {
        navigate(-1);
    };

    const onSubmitSuccess = (email: NotificationEmail) => {
        navigate(`/admin/accounts/${account_id}/notification-emails/${email.id}/detail`);
    };

    return {
        email,
        formValues,
        changeFormValues,
        account_id,
        changeAccount_id,
        onCancel,
        onSubmitSuccess
    };
};

export default useNotificationEmailUpdate;
