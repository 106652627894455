import { useAppSelector } from '@frontend/common';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { WizardInfo, initWizard, wizardStore } from './wizard-slice';

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
    initialValues?: WizardInfo;
}

export function useWizardRepository(props: Props) {
    const store = useAppSelector(useSelector, wizardStore);
    const [wizardId] = useState<string>(crypto.randomUUID());

    useEffect(() => {
        props.dispatch(initWizard({ wizardId, initialValues: props.initialValues }));
    }, [wizardId, props.initialValues]);

    return {
        wizardId,
        wizardState: store[wizardId] as WizardInfo | undefined
    };
}
