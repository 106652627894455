import React, { useEffect, useRef, useState } from 'react';

import useTextAreaInput from './text-area-input.controller';

const ID = 'text-area-input';

export interface TextAreaProps {
    id?: string;
    required?: boolean;
    valid?: boolean;
    isValidCallback?: (valid: boolean) => void;
    onChange?: (value: string) => void;
    onFocusChange?: () => void;
    value?: string | null;
    errorMessage?: React.ReactNode;
    label: React.ReactNode;
    helpMessage?: React.ReactNode;
    autoFocus?: boolean;
    submitted: boolean;
    useConditionedStyling?: boolean;
    className?: string;
}

export function TextArea(props: TextAreaProps) {
    const viewProps = useTextAreaInput(props);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const [selectionRange, setSelectionRange] = useState({ start: 0, end: 0 });

    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.selectionStart = selectionRange.start;
            textAreaRef.current.selectionEnd = selectionRange.end;
        }
    }, [selectionRange, props.value]);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value, selectionStart, selectionEnd } = event.target;
        setSelectionRange({ start: selectionStart, end: selectionEnd });
        if (props.onChange) props.onChange(value);
        viewProps.isValid();
    };

    return (
        <div
            id={ID}
            className={'form-group ' + props.className}>
            <label className='text-primary'>
                {props.required && <span className='text-danger me-1'>&#9679;</span>}
                {props.label}
            </label>
            <textarea
                className={viewProps.inputClass}
                ref={textAreaRef}
                style={{ height: textAreaRef.current?.scrollHeight ? textAreaRef.current?.scrollHeight + 2 + 'px' : undefined }}
                autoFocus={props.autoFocus}
                id={props.id ? props.id : props.label?.toString()}
                value={props.value ?? undefined}
                onFocus={() => {
                    viewProps.changeTouched(true);
                    props.onFocusChange && props.onFocusChange();
                    if (textAreaRef.current) {
                        setSelectionRange({
                            start: textAreaRef.current.selectionStart,
                            end: textAreaRef.current.selectionEnd
                        });
                    }
                }}
                required={props.required}
                onChange={handleChange}
            />
            {viewProps.valid === false && viewProps.touched === true && (
                <span className='badge bg-gradient-danger mt-2'>
                    <small>{props.errorMessage}</small>
                </span>
            )}
            {props.helpMessage && <span className='text-secondary text-xs'>{props.helpMessage}</span>}
        </div>
    );
}
