import { Location } from '@frontend/common';
import React from 'react';

const ID = 'location-badge';
export interface LocationBadgeProps {
    location: Location;
}

export const LocationBadge = (props: LocationBadgeProps) => {
    return (
        <span
            id={ID}
            className='badge badge-secondary disabled'>
            X: {props.location.x}, Y: {props.location.y}, Z: {props.location.z}, R: {props.location.rotation}
        </span>
    );
};

export default LocationBadge;
