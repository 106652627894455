import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

const PlaylistOverview = lazy(() => import('../playlist/playlist-overview'));
const PlaylistCreate = lazy(() => import('../playlist/playlist-create/playlist-create.component'));
const PlaylistDetail = lazy(() => import('../playlist/playlist-detail/playlist-detail.component'));
const PlaylistUpdate = lazy(() => import('../playlist/playlist-update/playlist-update.component'));
const PlaylistItemCreate = lazy(() => import('../playlist/playlist-item-create/playlist-item-create.component'));
const PlaylistItemDetail = lazy(() => import('../playlist/playlist-item-detail/playlist-item-detail.component'));
const PlaylistItemUpdate = lazy(() => import('../playlist/playlist-item-update/playlist-item-update.component'));

export function PlaylistRoutes() {
    return (
        <Route path='playlists'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <PlaylistOverview />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <PlaylistCreate />
                    </AsyncComponent>
                }
            />
            <Route path=':playlistId'>
                <Route
                    index
                    path='detail'
                    element={
                        <AsyncComponent>
                            <PlaylistDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <PlaylistUpdate />
                        </AsyncComponent>
                    }
                />
                <Route path='playlist-items'>
                    <Route path=':playlistItemId'>
                        <Route
                            index
                            path='detail'
                            element={
                                <AsyncComponent>
                                    <PlaylistItemDetail />
                                </AsyncComponent>
                            }
                        />
                        <Route
                            path='update'
                            element={
                                <AsyncComponent>
                                    <PlaylistItemUpdate />
                                </AsyncComponent>
                            }
                        />
                    </Route>
                    <Route
                        path='create'
                        element={
                            <AsyncComponent>
                                <PlaylistItemCreate />
                            </AsyncComponent>
                        }
                    />
                    <Route
                        index
                        element={<Navigate to='../detail' />}
                    />
                </Route>
            </Route>
            <Route
                index
                element={<Navigate to='/admin/playlists' />}
            />
        </Route>
    );
}
