import { ApiQueryParams } from '@frontend/api-utils';
import { DateInput, NumberInput } from '@frontend/basic-forms';
import { DefaultModal, DefaultModalProps, ModalType } from '@frontend/elements';
import { onQueryParamChange } from '@frontend/form';
import { CommonMessage } from '@frontend/lang';
import { EnumSelectList, EnumSelectListV2, ObjectSelectMapper } from '@frontend/rendering/select';
import { changeFilter, useListRepository } from '@frontend/repository';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import moment from 'moment';
import React from 'react';

import { FilterOptions } from '../header-filter';
import useFilterModal from './filter-modal.controller';

export interface FilterModalProps<T extends object & { id: string }> extends DefaultModalProps, ReturnType<typeof useListRepository<T>> {
    dispatch: ThunkDispatch<any, any, Action>;
    filter?: FilterOptions<T>;
    changeURLParams: (params: ApiQueryParams<string | number>) => void;
}

const FilterModal = <T extends object & { id: string }>(props: FilterModalProps<T>) => {
    const viewProps = useFilterModal(props);
    return (
        <DefaultModal
            type={viewProps.width < 600 ? ModalType.BOTTOM : ModalType.PANE}
            handleClose={props.handleClose}
            show={props.show}
            customWidth={30}>
            <div className='modal-header d-flex flex-wrap'>
                <button
                    className='btn btn-secondary'
                    onClick={props.handleClose}>
                    {CommonMessage.BUTTONS.CLOSE}
                </button>
                {viewProps.params && Object.keys(viewProps.params).filter((p) => !p.includes('search')).length > 0 && (
                    <button
                        className='btn btn-danger'
                        onClick={() => {
                            viewProps.setSearchParams((prev) => {
                                const newParams = new URLSearchParams(prev);
                                if (!viewProps.searchParams || !viewProps.params) return newParams;
                                for (const key of viewProps.searchParams) {
                                    const keys = props.filter?.queryParamKeyOverwrite?.get(key[0]) ?? key;
                                    if (Object.keys(viewProps.params).includes(keys[0])) {
                                        newParams.delete(keys[0]);
                                    }
                                }
                                return newParams;
                            });
                            props.changeURLParams({});
                            props.handleClose();
                        }}>
                        {CommonMessage.BUTTONS.CLEAR}
                    </button>
                )}
                <button
                    className='btn btn-success'
                    onClick={() => {
                        viewProps.params && props.changeURLParams(viewProps.params);
                        props.handleClose();
                    }}>
                    {CommonMessage.BUTTONS.SUBMIT}
                </button>
            </div>
            <div className='modal-body'>
                {viewProps.params &&
                    props.filter?.keys?.map((key) => {
                        if (typeof key === 'string') {
                            const extraProps = props.filter?.keyProps?.get(key) ?? {};
                            if ((key as string).includes('_id')) {
                                return (
                                    <ObjectSelectMapper<T>
                                        key={key}
                                        id={key}
                                        objectKey={key as keyof T}
                                        submitted={false}
                                        useConditionedStyling={false}
                                        onChange={(newValue) => {
                                            props.dispatch(
                                                changeFilter({
                                                    listId: props.listId,
                                                    key: props.filter?.queryParamKeyOverwrite?.get(key.toString()) ?? key,
                                                    value: newValue
                                                })
                                            );
                                        }}
                                        value={viewProps.params?.[props.filter?.queryParamKeyOverwrite?.get(key.toString()) ?? key]}
                                        className='w-100 me-1'
                                        isClearable
                                        allowURLChange
                                        {...extraProps}
                                        {...props}
                                    />
                                );
                            }
                            if (['from', 'to', 'timestamp'].includes(key) || key.includes('timestamp')) {
                                let keyValue = key;
                                if (key.includes('from')) keyValue = 'after';
                                if (key.includes('to')) keyValue = 'before';
                                return (
                                    <DateInput
                                        key={key}
                                        id={key}
                                        label={key.replace(/_/g, ' ').charAt(0).toUpperCase() + key.replace(/_/g, ' ').slice(1)}
                                        useConditionedStyling={false}
                                        onChange={(newValue) => {
                                            props.dispatch(
                                                changeFilter({
                                                    listId: props.listId,
                                                    key: props.filter?.queryParamKeyOverwrite?.get(keyValue.toString()) ?? keyValue,
                                                    value: newValue ? moment(newValue).format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ') : undefined
                                                })
                                            );
                                        }}
                                        value={viewProps.params?.[props.filter?.queryParamKeyOverwrite?.get(keyValue.toString()) ?? keyValue]}
                                        submitted={false}
                                        className='w-100 me-1'
                                        isClearable
                                        {...extraProps}
                                        {...props}
                                    />
                                );
                            }
                            if (['row', 'column'].includes(key)) {
                                return (
                                    <NumberInput
                                        key={key}
                                        id={key}
                                        label={key}
                                        onChange={(value) => {
                                            props.dispatch(
                                                changeFilter({
                                                    listId: props.listId,
                                                    key: props.filter?.queryParamKeyOverwrite?.get(key.toString()) ?? key,
                                                    value: value.toString()
                                                })
                                            );
                                            onQueryParamChange(value.toString(), key.toString(), viewProps.setSearchParams);
                                        }}
                                        value={viewProps.params?.[props.filter?.queryParamKeyOverwrite?.get(key.toString()) ?? key]}
                                        className='w-100 me-1'
                                        isClearable
                                        allowURLChange
                                        {...extraProps}
                                        {...props}
                                    />
                                );
                            }
                        }
                        if (key in EnumSelectList) {
                            const extraProps = props.filter?.keyProps?.get(key as string) ?? {};
                            const EnumInput = EnumSelectList[key as keyof typeof EnumSelectList];
                            return (
                                <EnumInput
                                    {...props}
                                    key={key}
                                    id={key.toString()}
                                    submitted={false}
                                    isClearable
                                    className='me-1'
                                    useConditionedStyling={false}
                                    required={false}
                                    onChange={(newValue: any) => {
                                        const value = newValue?.value !== '' ? newValue?.value : null;
                                        props.dispatch(
                                            changeFilter({
                                                listId: props.listId,
                                                key: props.filter?.queryParamKeyOverwrite?.get(key.toString()) ?? key,
                                                value: value
                                            })
                                        );
                                        onQueryParamChange(value, key.toString(), viewProps.setSearchParams);
                                    }}
                                    value={viewProps.params?.[props.filter?.queryParamKeyOverwrite?.get(key.toString()) ?? key] as any}
                                    allowURLChange
                                    onSelect={(newValue) => {
                                        props.dispatch(
                                            changeFilter({
                                                listId: props.listId,
                                                key: props.filter?.queryParamKeyOverwrite?.get(key.toString()) ?? key,
                                                value: newValue?.value
                                            })
                                        );
                                        onQueryParamChange(newValue?.value ?? null, key.toString(), viewProps.setSearchParams);
                                    }}
                                    {...extraProps}
                                />
                            );
                        } else if (key in EnumSelectListV2) {
                            const EnumInput = EnumSelectListV2[key as keyof typeof EnumSelectListV2];
                            const extraProps = props.filter?.keyProps?.get(key as string) ?? {};

                            return (
                                <EnumInput
                                    key={key}
                                    id={key}
                                    isClearable
                                    value={viewProps.params?.[props.filter?.queryParamKeyOverwrite?.get(key.toString()) ?? key] as any}
                                    required={false}
                                    onSelect={(newValue) => {
                                        props.dispatch(
                                            changeFilter({
                                                listId: props.listId,
                                                key: props.filter?.queryParamKeyOverwrite?.get(key.toString()) ?? key,
                                                value: newValue?.value
                                            })
                                        );
                                        onQueryParamChange(
                                            newValue?.value ?? null,
                                            props.filter?.queryParamKeyOverwrite?.get(key.toString()) ?? key.toString(),
                                            viewProps.setSearchParams
                                        );
                                    }}
                                    className='me-1'
                                    {...extraProps}
                                />
                            );
                        }
                        return <></>;
                    })}
            </div>
        </DefaultModal>
    );
};

export default FilterModal;
