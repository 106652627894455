import { BudgetUseClient } from '@frontend/budget/api';
import { BudgetUse } from '@frontend/budget/types';
import { ClassType, EntityType } from '@frontend/common';
import { Spinner } from '@frontend/elements';
import { ExportClient } from '@frontend/export/api';
import { ICONS } from '@frontend/icons';
import { CommonMessage } from '@frontend/lang';
import { ObjectList } from '@frontend/rendering/list';
import { useAccountRepository } from '@frontend/repository';
import HeaderNavigation from 'libs/header/src/lib/header-navigation/header-navigation.component';
import { useMemo, useState } from 'react';
import { FaHandHolding } from 'react-icons/fa';
import { GiVendingMachine } from 'react-icons/gi';

import { useAppDispatch } from '../redux/store';
import ConsumptionDetail from '../statistics/consumption/consumption-detail/consumption-detail';
import EquipmentHistoricalUse from '../statistics/equipment-historical-use/equipment-historical-use.component';
import EquipmentInventory from '../statistics/equipment-inventory/equipment-inventory.component';

const ReportOverview = () => {
    const {
        store: { currentAccount }
    } = useAccountRepository();
    const dispatch = useAppDispatch();
    const [active, changeActive] = useState<string | undefined>(undefined);

    const content = useMemo(() => {
        if (!active) return null;
        switch (active) {
            case 'vending':
                return (
                    <div>
                        <ObjectList<BudgetUse>
                            fetch={(arg) => BudgetUseClient.fetchBudgetUses(arg)}
                            dispatch={dispatch}
                            type={EntityType.BUDGET_USE}
                            columns={{ columns: ['user_id', 'product_id', 'quantity', 'creation_timestamp'] }}
                            label={CommonMessage.OBJECTS.BUDGET_USE.PLURAL}
                            filter={{ keys: ['user_id', 'product_id'], type: 'platform' }}
                            customButtons={[
                                {
                                    text: CommonMessage.BUTTONS.EXPORT,
                                    onClick: () => ExportClient.exportConsumption(currentAccount!, 'budget-use'),
                                    disabled: !currentAccount,
                                    icon: ICONS.BUTTON.EXPORT,
                                    type: ClassType.PRIMARY
                                }
                            ]}
                        />
                        <ConsumptionDetail />
                    </div>
                );
            case 'lending':
                return (
                    <div>
                        <EquipmentInventory />
                        <EquipmentHistoricalUse />
                    </div>
                );
            default:
                return <></>;
        }
    }, [active]);

    if (!currentAccount) {
        return <Spinner />;
    }

    return (
        <>
            <HeaderNavigation
                onChange={changeActive}
                items={[
                    {
                        icon: GiVendingMachine,
                        title: 'Vending',
                        link: 'vending'
                    },
                    {
                        icon: FaHandHolding,
                        title: 'Lending',
                        link: 'lending'
                    }
                ]}
            />
            {content}
        </>
    );
};

export default ReportOverview;
