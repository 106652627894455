import { APIClient, ApiQueryParams } from "@frontend/api-utils";
import { CreateEquipmentProblem, Equipment, EquipmentProblem, EquipmentProblemListResponse, EquipmentProblemQueryParams } from "@frontend/equipment/types";

const endpoint = '/equipment-api/v1'

export class EquipmentProblemClient extends APIClient {
    public static async fetchEquipmentProblems(accountId: string, equipmentId: string, queryParams?: ApiQueryParams<EquipmentProblemQueryParams>): Promise<EquipmentProblemListResponse> {
        return this.apiPaginated<EquipmentProblemListResponse, EquipmentProblemQueryParams>(`${endpoint}/accounts/${accountId}/equipments/${equipmentId}/problems`);
    }

    public static async postEquipmentProblem(accountId: string, equipmentId: string, body: CreateEquipmentProblem): Promise<Equipment> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/equipments/${equipmentId}/problems`, body);
        return this.handleResponse<Equipment>(response);
    }

    public static async fetchEquipmentProblem(accountId: string, equipmentId: string, problemId: string): Promise<EquipmentProblem> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/equipments/${equipmentId}/problems/${problemId}`);
        return this.handleResponse<EquipmentProblem>(response);
    }

    public static async deleteEquipmentProblem(accountId: string, equipmentId: string, problemId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/equipments/${equipmentId}/problems/${problemId}`);
        return this.handleVoidResponse(response);
    }
}