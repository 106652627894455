import { lazy } from 'react';
import { Route } from 'react-router-dom';

const ProductAttributeCreate = lazy(() => import('./product-attribute-create/product-attribute-create.component'));
const ProductAttributeUpdate = lazy(() => import('./product-attribute-update/product-attribute-update.component'));

export function ProductAttributesRoutes() {
    return (
        <Route path='product-attributes'>
            <Route
                path='create'
                element={<ProductAttributeCreate />}
            />
            <Route path=':productAttributeId'>
                <Route
                    path='update'
                    element={<ProductAttributeUpdate />}
                />
            </Route>
        </Route>
    );
}
