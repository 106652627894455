import React from 'react';
import { FormattedMessage } from 'react-intl';

import useColorInput from './color-input.controller';

const ID = 'color-input';

export interface ColorInputProps {
    id?: string;
    onChange: (value: string) => void;
    value: string | null;
    label: React.ReactNode;
    className?: string;
    required?: boolean;
    valid?: boolean;
    isValidCallback?: (valid: boolean) => void;
    errorMessage?: React.ReactNode;
    onFocusChange?: () => void;
}

export const ColorInput = (props: ColorInputProps) => {
    const viewProps = useColorInput(props);
    return (
        <div
            className={'form-group ' + props.className}
            id={props.id || ID}>
            <label className='text-primary'>
                {props.required && <span className='text-danger me-1'>&#9679;</span>}
                {props.label}
            </label>
            <div className='d-flex flex-row flex-wrap gap-2'>
                <input
                    type='color'
                    className={'form-control w-100 w-sm-49'}
                    required={props.required}
                    value={props.value ?? undefined}
                    onChange={(event) => {
                        if (props.onChange) props.onChange(event.target.value);
                        viewProps.isValid();
                    }}
                    onFocus={() => {
                        viewProps.changeTouched(true);
                        props.onFocusChange && props.onFocusChange();
                    }}
                />
                <input
                    type='text'
                    className={viewProps.inputClass + ' w-100 w-sm-49'}
                    value={props.value ?? undefined}
                    onChange={(event) => {
                        if (props.onChange) props.onChange(event.target.value);
                        viewProps.isValid();
                    }}
                    onFocus={() => {
                        viewProps.changeTouched(true);
                        props.onFocusChange && props.onFocusChange();
                    }}
                />
                {viewProps.valid === false && viewProps.touched === true ? (
                    <span className='badge bg-gradient-danger mt-2'>
                        <small>{props.errorMessage ?? getErrorMessage(props)}</small>
                    </span>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

function getErrorMessage(props: ColorInputProps): React.ReactNode {
    if (props.required) {
        return <>{props.label} is required</>;
    }
    return (
        <FormattedMessage
            id='color-input.invalid'
            defaultMessage='Invalid color'
        />
    );
}
