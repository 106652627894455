import { CommonMessage } from '@frontend/lang';
import { ProductClient } from '@frontend/product/api';
import { ProductBadge } from '@frontend/product/badge';
import { IProduct } from '@frontend/product/types';
import { useAccountRepository } from '@frontend/repository';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

interface Props {
    filter: string;
}
export const ProductSearch = (props: Props) => {
    const {
        store: { currentAccount }
    } = useAccountRepository();
    const [results, changeResults] = useState<IProduct[]>([]);

    useEffect(() => {
        if (props.filter !== '')
            ProductClient.fetchProducts({ account_id: currentAccount, search: props.filter }).then((result) => {
                changeResults(result.results);
            });
    }, [props.filter, currentAccount]);

    if (results.length === 0) return null;
    return (
        <div className='p-1 mb-3'>
            <span className='d-flex flex-row justify-content-between align-items-end'>
                <h6 className='ms-1 mb-0 pb-0'>{CommonMessage.OBJECTS.PRODUCT.PLURAL}</h6>
                <Link
                    className='text-xs font-weight-bold underline text-secondary me-1'
                    to='/admin/products'>
                    Show all
                </Link>
            </span>
            <hr className='horizontal dark mt-0' />
            {results.map((result) => (
                <ProductBadge id={result.id} />
            ))}
        </div>
    );
};
