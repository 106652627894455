import React from 'react';
import { FormattedMessage } from 'react-intl';

export const Events = {
    TRANSACTION_STATE_CHANGED: (
        <FormattedMessage
            id='Events.TRANSACTION_STATE_CHANGED'
            description='Transaction state changed event'
            defaultMessage='Transaction state changed'
        />
    ),
    EQUIPMENT_STATE_CHANGED: (
        <FormattedMessage
            id='Events.EQUIPMENT_STATE_CHANGED'
            description='Equipment state changed event'
            defaultMessage='Equipment state changed'
        />
    ),
    PACKAGE_STATE_CHANGED: (
        <FormattedMessage
            id='Events.PACKAGE_STATE_CHANGED'
            description='Package state changed event'
            defaultMessage='Package state changed'
        />
    ),
    USER_STATE_CHANGED: (
        <FormattedMessage
            id='Events.USER_STATE_CHANGED'
            description='User state changed event'
            defaultMessage='User state changed'
        />
    ),
    LOCKER_STATE_CHANGED: (
        <FormattedMessage
            id='Events.LOCKER_STATE_CHANGED'
            description='Locker state changed event'
            defaultMessage='Locker state changed'
        />
    ),
    TRANSACTION_TRIGGER_RECEIVED: (
        <FormattedMessage
            id='Events.TRANSACTION_TRIGGER_RECEIVED'
            description='Transaction trigger received event'
            defaultMessage='Transaction trigger received'
        />
    ),
    EQUIPMENT_TRIGGER_RECEIVED: (
        <FormattedMessage
            id='Events.EQUIPMENT_TRIGGER_RECEIVED'
            description='Equipment trigger received event'
            defaultMessage='Equipment trigger received'
        />
    ),
    PACKAGE_TRIGGER_RECEIVED: (
        <FormattedMessage
            id='Events.PACKAGE_TRIGGER_RECEIVED'
            description='Package trigger received event'
            defaultMessage='Package trigger received'
        />
    ),
    USER_TRIGGER_RECEIVED: (
        <FormattedMessage
            id='Events.USER_TRIGGER_RECEIVED'
            description='User trigger received event'
            defaultMessage='User trigger received'
        />
    ),
    LOCKER_TRIGGER_RECEIVED: (
        <FormattedMessage
            id='Events.LOCKER_TRIGGER_RECEIVED'
            description='Locker trigger received event'
            defaultMessage='Locker trigger received'
        />
    ),
    TRANSACTION_ACTION_TRIGGERED: (
        <FormattedMessage
            id='Events.TRANSACTION_ACTION_TRIGGERED'
            description='Transaction action triggered event'
            defaultMessage='Transaction action triggered'
        />
    ),
    EQUIPMENT_ACTION_TRIGGERED: (
        <FormattedMessage
            id='Events.EQUIPMENT_ACTION_TRIGGERED'
            description='Equipment action triggered event'
            defaultMessage='Equipment action triggered'
        />
    ),
    PACKAGE_ACTION_TRIGGERED: (
        <FormattedMessage
            id='Events.PACKAGE_ACTION_TRIGGERED'
            description='Package action triggered event'
            defaultMessage='Package action triggered'
        />
    ),
    USER_ACTION_TRIGGERED: (
        <FormattedMessage
            id='Events.USER_ACTION_TRIGGERED'
            description='User action triggered event'
            defaultMessage='User action triggered'
        />
    ),
    LOCKER_ACTION_TRIGGERED: (
        <FormattedMessage
            id='Events.LOCKER_ACTION_TRIGGERED'
            description='Locker action triggered event'
            defaultMessage='Locker action triggered'
        />
    ),
    SLOT_SENSOR_RECEIVED: (
        <FormattedMessage
            id='Events.SLOT_SENSOR_RECEIVED'
            description='Slot sensor received event'
            defaultMessage='Slot sensor received'
        />
    ),
    IOT_HEARTBEAT_MISSED: (
        <FormattedMessage
            id='Events.IOT_HEARTBEAT_MISSED'
            description='Iot heartbeat missed event'
            defaultMessage='Iot heartbeat missed'
        />
    ),
    IOT_HEARTBEAT_RECOVERED: (
        <FormattedMessage
            id='Events.IOT_HEARTBEAT_RECOVERED'
            description='Iot heartbeat recovered event'
            defaultMessage='Iot heartbeat recovered'
        />
    ),
    RAKINDA_MASTER_PIN_CODE_USED: (
        <FormattedMessage
            id='Events.RAKINDA_MASTER_PIN_CODE_USED'
            description='Rakinda master pin code used event'
            defaultMessage='Rakinda master pin code used'
        />
    ),
    RAKINDA_ACCESS_GRANTED: (
        <FormattedMessage
            id='Events.RAKINDA_ACCESS_GRANTED'
            description='Rakinda access granted event'
            defaultMessage='Rakinda access granted'
        />
    ),
    RAKINDA_ACCESS_DENIED: (
        <FormattedMessage
            id='Events.RAKINDA_ACCESS_DENIED'
            description='Rakinda access denied event'
            defaultMessage='Rakinda access denied'
        />
    ),
    EMAIL_SENT: (
        <FormattedMessage
            id='Events.EMAIL_SENT'
            description='Email sent event'
            defaultMessage='Email sent'
        />
    ),
    ENTITY_SYNC_CREATED: (
        <FormattedMessage
            id='Events.ENTITY_SYNC_CREATED'
            description='Entity sync created event'
            defaultMessage='Entity sync created'
        />
    ),
    ENTITY_SYNC_UPDATED: (
        <FormattedMessage
            id='Events.ENTITY_SYNC_UPDATED'
            description='Entity sync updated event'
            defaultMessage='Entity sync updated'
        />
    ),
    ENTITY_SYNC_DELETED: (
        <FormattedMessage
            id='Events.ENTITY_SYNC_DELETED'
            description='Entity sync deleted event'
            defaultMessage='Entity sync deleted'
        />
    ),
    QR_CODE_SCANNED: (
        <FormattedMessage
            id='Events.QR_CODE_SCANNED'
            description='QR code scanned event'
            defaultMessage='QR code scanned'
        />
    ),
    NFC_BADGE_SCANNED: (
        <FormattedMessage
            id='Events.NFC_BADGE_SCANNED'
            description='NFC badge scanned event'
            defaultMessage='NFC badge scanned'
        />
    ),
    IDENTIFICATION_UPDATED: (
        <FormattedMessage
            id='Events.IDENTIFICATION_UPDATED'
            description='Identification updated event'
            defaultMessage='Identification updated'
        />
    ),
    IDENTIFICATION_REMOVED: (
        <FormattedMessage
            id='Events.IDENTIFICATION_REMOVED'
            description='Identification removed event'
            defaultMessage='Identification removed'
        />
    ),
    PRODUCT_CATEGORY_CREATED: (
        <FormattedMessage
            id='Events.PRODUCT_CATEGORY_CREATED'
            description='Product category created event'
            defaultMessage='Product category created'
        />
    ),
    PRODUCT_CATEGORY_UPDATED: (
        <FormattedMessage
            id='Events.PRODUCT_CATEGORY_UPDATED'
            description='Product category updated event'
            defaultMessage='Product category updated'
        />
    ),
    PRODUCT_IMPORTED: (
        <FormattedMessage
            id='Events.PRODUCT_IMPORTED'
            description='Product imported event'
            defaultMessage='Product imported'
        />
    ),
    EQUIPMENT_REPORTED_BROKEN: (
        <FormattedMessage
            id='Events.EQUIPMENT_REPORTED_BROKEN'
            description='Equipment reported broken event'
            defaultMessage='Equipment reported broken'
        />
    ),
    IOT_ACTION_TRIGGERED: (
        <FormattedMessage
            id='Events.IOT_ACTION_TRIGGERED'
            description='Iot action triggered event'
            defaultMessage='Iot action triggered'
        />
    ),
    IOT_STATE_CHANGED: (
        <FormattedMessage
            id='Events.IOT_STATE_CHANGED'
            description='Iot state changed event'
            defaultMessage='Iot state changed'
        />
    ),
    IOT_TRIGGER_RECEIVED: (
        <FormattedMessage
            id='Events.IOT_TRIGGER_RECEIVED'
            description='Iot trigger received event'
            defaultMessage='Iot trigger received'
        />
    ),
    KEYBOARD_INPUT_ENTERED: (
        <FormattedMessage
            id='Events.KEYBOARD_INPUT_ENTERED'
            description='Keyboard input entered event'
            defaultMessage='Keyboard input entered'
        />
    ),
    LOCKER_STATUS_UPDATED: (
        <FormattedMessage
            id='Events.LOCKER_STATUS_UPDATED'
            description='Locker status updated event'
            defaultMessage='Locker status updated'
        />
    ),
    NFC_TAG_SCANNED: (
        <FormattedMessage
            id='Events.NFC_TAG_SCANNED'
            description='NFC tag scanned event'
            defaultMessage='NFC tag scanned'
        />
    ),
    SLOT_ACTION_TRIGGERED: (
        <FormattedMessage
            id='Events.SLOT_ACTION_TRIGGERED'
            description='Slot action triggered event'
            defaultMessage='Slot action triggered'
        />
    ),
    SLOT_STATE_CHANGED: (
        <FormattedMessage
            id='Events.SLOT_STATE_CHANGED'
            description='Slot state changed event'
            defaultMessage='Slot state changed'
        />
    ),
    SLOT_TRIGGER_RECEIVED: (
        <FormattedMessage
            id='Events.SLOT_TRIGGER_RECEIVED'
            description='Slot trigger received event'
            defaultMessage='Slot trigger received'
        />
    )
};
