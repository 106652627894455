import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { CreateUser, IUser, UpdateUser, UserListResponse, UserQueryParams } from '@frontend/user/types';

const endpoint = '/user-api/v1';

export class UserClient extends APIClient {
    public static async fetchUsers(queryParams?: ApiQueryParams<DefaultQueryParams | UserQueryParams>): Promise<UserListResponse> {
        return await this.apiPaginated<UserListResponse, DefaultQueryParams | UserQueryParams>(`${endpoint}/users`, queryParams);
    }

    public static async postUser(accountId: string, user: CreateUser): Promise<IUser> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/users`, user);
        return await this.handleResponse<IUser>(response);
    }

    public static async fetchUsersNotInGroup(accountId: string, groupId: string, queryParams?: ApiQueryParams<DefaultQueryParams | UserQueryParams>): Promise<UserListResponse> {
        return await this.apiPaginated<UserListResponse, DefaultQueryParams | UserQueryParams>(`${endpoint}/accounts/${accountId}/groups/${groupId}/users`, queryParams);
    }

    public static async fetchUser(accountId: string, userId: string): Promise<IUser> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/users/${userId}`);
        return await this.handleResponse<IUser>(response);
    }

    public static async patchUser(accountId: string, userId: string, user: UpdateUser): Promise<IUser> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/users/${userId}`, user);
        return await this.handleResponse<IUser>(response);
    }

    public static async deleteUser(accountId: string, userId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/users/${userId}`);
        return await this.handleVoidResponse(response);
    }
    public static async resolveUsers(userIds: string[]): Promise<UserListResponse> {
        const response = await this.post(`${endpoint}/users-resolve`, { ids: userIds });
        return await this.handleResponse<UserListResponse>(response);
    }
    public static async deleteUsers(userIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/users-delete`, { ids: userIds });
        return await this.handleVoidResponse(response);
    }

    //Profile

    public static async getProfile(): Promise<IUser> {
        const response = await this.fetch(`${endpoint}/profile`);
        return await this.handleResponse<IUser>(response);
    }

    public static async postIoTSeed(accountId: string, iotId: string, body: { account_id: string }): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots/${iotId}/seed`, body);
        return this.handleResponse(response);
    }
}
