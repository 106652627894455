import { APIClient } from "@frontend/api-utils";
import { Job } from "@frontend/import/types";

const endpoint = '/import-api/v1'
export class ImportClient extends APIClient {
    public static async importFile(accountId: string, file: File): Promise<Job> {
        const formData = new FormData();
        formData.append('file', file);
        const response = await this.post(`${endpoint}/accounts/${accountId}/import`, formData);
        return await this.handleResponse<Job>(response);
    }
}