import { useAppSelector } from '@frontend/common';
import { changePage, listStore } from '@frontend/repository';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React, { useMemo } from 'react';
import { HiOutlineChevronDoubleLeft, HiOutlineChevronDoubleRight, HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';
import { useSelector } from 'react-redux';

import { PaginationNavigationButton } from './pagination-navigation-button';
import { PaginationNumber } from './pagination-number';

interface PaginationButtonNavigationProps {
    id?: string;
    dispatch: ThunkDispatch<any, any, Action>;
    listStoreId: string;

    className?: string;
    allowBack?: boolean;
    backDisabled?: boolean;
    allowAllBack?: boolean;
    allBackDisabled?: boolean;
    allowForward?: boolean;
    forwardDisabled?: boolean;
    allowAllForward?: boolean;
    allForwardDisabled?: boolean;
    paginationButtonProps?: React.ComponentProps<typeof PaginationNavigationButton>;

    /**
     * @description The amount of pagination numbers to display
     * Will always be odd (if an even number is given, it will be increased by 1)
     * @default 5
     */
    paginationNumberButtonAmount?: number;
    paginationNumberProps?: React.ComponentProps<typeof PaginationNumber>;
}
export const PaginationButtonNavigationClass = 'pagination pagination-primary m-0';
export const PaginationButtonNavigation = (props: PaginationButtonNavigationProps) => {
    const store = useAppSelector(useSelector, listStore);
    const pageInfo = useMemo(() => {
        const list = store[props.listStoreId];
        if (list && list.count !== null) {
            return {
                pageIndex: list.pageIndex,
                pageCount: Math.ceil(list.count / list.pageSize)
            };
        }
        return undefined;
    }, [store[props.listStoreId]]);
    const pagesToDisplay = useMemo(() => {
        if (pageInfo) return getPagesToDisplay(pageInfo.pageCount, pageInfo.pageIndex + 1, props.paginationNumberButtonAmount);
        return [];
    }, [pageInfo, props.paginationNumberButtonAmount]);
    const bwDisabled = !pageInfo || pageInfo.pageIndex <= 0;
    const fwDisabled = !pageInfo || pageInfo.pageIndex + 1 >= pageInfo.pageCount;

    const gotoPage = (index: number) => {
        if (!pageInfo) return;
        props.dispatch(changePage({ listId: props.listStoreId, page: index }));
    };

    const previousPage = () => {
        if (!pageInfo) return;
        props.dispatch(changePage({ listId: props.listStoreId, page: pageInfo!.pageIndex - 1 }));
    };

    const nextPage = () => {
        if (!pageInfo) return;
        props.dispatch(changePage({ listId: props.listStoreId, page: pageInfo!.pageIndex + 1 }));
    };
    return (
        <ul
            id={props.id}
            className={props.className ?? PaginationButtonNavigationClass}>
            {pageInfo && (
                <>
                    {props.allowAllBack !== false && (
                        <PaginationNavigationButton
                            id={props.id}
                            icon={HiOutlineChevronDoubleLeft}
                            disabled={props.allBackDisabled ?? bwDisabled}
                            onClick={() => gotoPage(0)}
                            {...props.paginationButtonProps}
                        />
                    )}

                    {props.allowBack !== false && (
                        <PaginationNavigationButton
                            id={props.id}
                            icon={HiOutlineChevronLeft}
                            disabled={props.backDisabled ?? bwDisabled}
                            onClick={previousPage}
                            {...props.paginationButtonProps}
                        />
                    )}

                    {pagesToDisplay.map((n) => (
                        <PaginationNumber
                            id={props.id}
                            key={n}
                            index={n}
                            highlighted={pageInfo.pageIndex + 1 === n}
                            onClick={() => gotoPage(n - 1)}
                            {...props.paginationNumberProps}
                        />
                    ))}

                    {props.allowForward !== false && (
                        <PaginationNavigationButton
                            id={props.id}
                            icon={HiOutlineChevronRight}
                            disabled={props.forwardDisabled ?? fwDisabled}
                            onClick={nextPage}
                            {...props.paginationButtonProps}
                        />
                    )}

                    {props.allowAllForward !== false && (
                        <PaginationNavigationButton
                            id={props.id}
                            icon={HiOutlineChevronDoubleRight}
                            disabled={props.allForwardDisabled ?? fwDisabled}
                            onClick={() => gotoPage!(pageInfo.pageCount - 1)}
                            {...props.paginationButtonProps}
                        />
                    )}
                </>
            )}
        </ul>
    );
};
export default PaginationButtonNavigation;

function getPagesToDisplay(pageCount: number, currentPage: number, pagesToDisplay = 5): number[] {
    const pagesOnEverySide = (pagesToDisplay - (pagesToDisplay % 2)) / 2;
    let pages: number[] = [];

    for (let i = currentPage - pagesOnEverySide; i <= currentPage + pagesOnEverySide; i++) {
        if (i < 1) continue;
        if (i > pageCount) break;
        pages.push(i);
    }

    while (pages.length < pagesToDisplay) {
        if (pages[0] > 1) {
            pages = [pages[0] - 1, ...pages];
        } else if (pages.indexOf(currentPage) !== pagesOnEverySide + 1 && pages[pages.length - 1] < pageCount) {
            pages = [...pages, pages[pages.length - 1] + 1];
        } else break;
    }
    return pages;
}
