import { isUUID } from '@frontend/rendering/details';
import React, { JSX, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import ObjectBreadcrumb from '../object-breadcrumb/object-breadcrumb.component';
import TextBreadcrumb from '../text-breadcrumb/text-breadcrumb.component';

const UUID_REQEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

interface ViewProps {
    breadcrumbs: JSX.Element[];
    title: string;
}

const useBreadcrumbs = (): ViewProps => {
    const location = useLocation();
    const [breadcrumbs, changeBreadcrumbs] = useState<JSX.Element[]>([]);
    const title = useMemo(() => {
        const urlElements = location.pathname.split('/');
        const pageType = urlElements[urlElements.length - 1];
        let expectedIndex = 0;

        if (['detail', 'update'].includes(pageType)) {
            if (!isUUID(urlElements[urlElements.length - 2])) {
                expectedIndex = urlElements.length - 2;
            } else {
                expectedIndex = urlElements.length - 3;
            }
        } else if (['create', 'add'].includes(pageType)) {
            expectedIndex = urlElements.length - 2;
        } else {
            expectedIndex = urlElements.length - 1;
        }
        let result = urlElements[expectedIndex].endsWith('s') ? urlElements[expectedIndex].slice(0, -1) : urlElements[expectedIndex];
        result = result + ' ' + (['detail', 'update', 'create', 'add'].includes(pageType) ? pageType : 'overview');
        return result.slice(0, 1).toUpperCase() + result.slice(1).replace(/_|-/g, ' ');
    }, [location]);

    useEffect(() => {
        const breadcrumbs: JSX.Element[] = [];
        let currentPath = window.location.origin;
        breadcrumbs.push(/*home, */ divider);
        const urlElements = location.pathname.split('/');
        urlElements.forEach((path, index) => {
            if (path !== '') {
                const isLast = index === urlElements.length - 1;
                const type = currentPath.split('/').pop();
                currentPath += `/${path}`;
                if (UUID_REQEX.test(path)) {
                    if (type)
                        breadcrumbs.push(
                            <ObjectBreadcrumb
                                value={path}
                                type={type}
                            />
                        );
                    else
                        breadcrumbs.push(
                            <TextBreadcrumb
                                value={path}
                                path={currentPath}
                                highlighted={isLast}
                            />
                        );
                } else {
                    breadcrumbs.push(
                        <TextBreadcrumb
                            value={path}
                            path={currentPath}
                            highlighted={isLast}
                        />
                    );
                }
                if (!isLast) breadcrumbs.push(divider);
            }
        });
        changeBreadcrumbs(breadcrumbs);
    }, [location]);
    return { breadcrumbs, title };
};

export default useBreadcrumbs;

const divider = <span className='m-1'>/</span>;
// const home = (
//     <li
//         className='breadcrumb-item text-sm m-1'
//         aria-current='page'>
//         <Link
//             className='opacity-5 text-white'
//             to={window.location.origin}>
//             <GoHome />
//         </Link>
//     </li>
// );
