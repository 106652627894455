import { Authentication } from '@frontend/authentication-v2';
import { AuthorizationProvider } from '@frontend/authorization';
import { ClassType, EntityType, useLocalStorageState, useScripts } from '@frontend/common';
import { AsyncComponent, Profile } from '@frontend/elements';
import { EventType } from '@frontend/events/types';
import { Header } from '@frontend/header';
import { IdentificationType } from '@frontend/identification/types';
import { CommonMessage, TranslationProvider } from '@frontend/lang';
import { DocumentStoreProvider } from '@frontend/repository';
import { lazy } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Footer from './footer/footer';
import Navbar from './navigation/navbar/navbar.component';
import { useAppDispatch } from './redux/store';
import Resolver from './utils/resolver';

const Configuration = lazy(() => import('./configuration/configuration.component'));

// #region Accounts
const AccountOverview = lazy(() => import('./account/account-overview'));
const AccountCreate = lazy(() => import('./account/account-create/account-create.component'));
const AccountDetail = lazy(() => import('./account/account-detail/account-detail.component'));
const AccountUpdate = lazy(() => import('./account/account-update/account-update.component'));
// #endregion
// #region Address
const AddressOverview = lazy(() => import('./address/address-overview'));
const AddressCreate = lazy(() => import('./address/address-create/address-create.component'));
const AddressDetail = lazy(() => import('./address/address-detail/address-detail.component'));
const AddressUpdate = lazy(() => import('./address/address-update/address-update.component'));
const AddressEntityLink = lazy(() => import('./address/address-entity-link/address-entity-link.component'));
const EntityToAddressLink = lazy(() => import('./address/entity-to-address-link/entity-to-address-link.component'));
// #endregion
// #region User Groups
const UserGroupCreate = lazy(() => import('./user-group/user-group-create/user-group-create.component'));
const UserGroupOverview = lazy(() => import('./user-group/user-group-overview'));
const UserGroupDetail = lazy(() => import('./user-group/user-group-detail/user-group-detail.component'));
const UserGroupUpdate = lazy(() => import('./user-group/user-group-update/user-group-update.component'));
const UserGroupMemberAdd = lazy(() => import('./user-group/user-group-member-add/user-group-member-add.component'));
const LinkBudgetToUsers = lazy(() => import('./user/link-budgets-to-user/link-budget-to-users.component'));
// #endregion
// #region Product Groups
const ProductCatalogueCreate = lazy(() => import('./product-catalogue/product-catalogue-create/product-catalogue-create.component'));
const ProductCatalogueOverview = lazy(() => import('./product-catalogue/product-catalogue-overview'));
const ProductCatalogueDetail = lazy(() => import('./product-catalogue/product-catalogue-detail/product-catalogue-detail.component'));
const ProductCatalogueUpdate = lazy(() => import('./product-catalogue/product-catalogue-update/product-catalogue-update.component'));
const ProductCatalogueMemberAdd = lazy(() => import('./product-catalogue/product-catalogue-member-add/product-catalogue-member-add.component'));
// #endregion
// #region Product Groups
const ProductGroupCreate = lazy(() => import('./product-group/product-group-create/product-group-create.component'));
const ProductGroupOverview = lazy(() => import('./product-group/product-group-overview'));
const ProductGroupDetail = lazy(() => import('./product-group/product-group-detail/product-group-detail.component'));
const ProductGroupUpdate = lazy(() => import('./product-group/product-group-update/product-group-update.component'));
const ProductGroupMemberAdd = lazy(() => import('./product-group/product-group-member-add/product-group-member-add.component'));
// #endregion
// #region Equipment Groups
const EquipmentGroupCreate = lazy(() => import('./equipment-group/equipment-group-create/equipment-group-create.component'));
const EquipmentGroupDetail = lazy(() => import('./equipment-group/equipment-group-detail/equipment-group-detail.component'));
const EquipmentGroupOverview = lazy(() => import('./equipment-group/equipment-group-overview'));
const EquipmentGroupUpdate = lazy(() => import('./equipment-group/equipment-group-update/equipment-group-update.component'));
const EquipmentGroupMemberAdd = lazy(() => import('./equipment-group/equipment-group-member-add/equipment-group-member-add.component'));
// #endregion
// #region Contact Groups
const ContactGroupCreate = lazy(() => import('./contact-group/contact-group-create/contact-group-create.component'));
const ContactGroupOverview = lazy(() => import('./contact-group/contact-group-overview/contact-group-overview'));
const ContactGroupDetail = lazy(() => import('./contact-group/contact-group-detail/contact-group-detail.component'));
const ContactGroupMemberAdd = lazy(() => import('./contact-group/contact-group-member-add/contact-group-member-add.component'));
const ContactGroupUpdate = lazy(() => import('./contact-group/contact-group-update/contact-group-update.component'));
// #endregion
// #region Cost center
const CostCenterDetail = lazy(() => import('./cost-center/cost-center-detail/cost-center-detail.component'));
const CostCenterOverview = lazy(() => import('./cost-center/cost-center-overview'));
const CostCenterCreate = lazy(() => import('./cost-center/cost-center-create/cost-center-create.component'));
const CostCenterUpdate = lazy(() => import('./cost-center/cost-center-update/cost-center-update.component'));
// #endregion
// #region Budget
const BudgetOverview = lazy(() => import('./budget/budget-overview'));
const BudgetCreate = lazy(() => import('./budget/budget-create/budget-create.component'));
const BudgetDetail = lazy(() => import('./budget/budget-detail/budget-detail.component'));
const BudgetUpdate = lazy(() => import('./budget/budget-update/budget-update.component'));
const BudgetRuleCreate = lazy(() => import('./budget/budget-rule-create/budget-rule-create.component'));
const BudgetRuleUpdate = lazy(() => import('./budget/budget-rule-update/budget-rule-update.component'));
const BudgetEntityLink = lazy(() => import('./budget/budget-entity-link/budget-entity-link.component'));
const EntityToBudgetLink = lazy(() => import('./budget/entity-to-budget-link/entity-to-budget-link.component'));
// #endregion
// #region Document
const DocumentOverview = lazy(() => import('./document/document-overview'));
const DocumentCreate = lazy(() => import('./document/document-create/document-create.component'));
const DocumentDetail = lazy(() => import('./document/document-detail/document-detail.component'));
const DocumentUpdate = lazy(() => import('./document/document-update/document-update.component'));
const DocumentFileUpload = lazy(() => import('./document/file-upload/file-upload.component'));
const DocumentEntityLink = lazy(() => import('./document/document-entity-link/document-entity-link.component'));
const EntityToDocumentLink = lazy(() => import('./document/entity-to-document-link/entity-to-document-link.component'));
// #endregion
// #region  Tags
const LinkTag = lazy(() => import('./identification/link-tag/link-tag.component'));
const TagOverview = lazy(() => import('./tag/tag-overview'));
const TagDetail = lazy(() => import('./tag/tag-detail/tag-detail.component'));
// #endregion
// #endregion
// #region Roles
const RoleOverview = lazy(() => import('./role/role-overview'));
const RoleCreate = lazy(() => import('./role/role-create/role-create.component'));
const RoleDetail = lazy(() => import('./role/role-detail/role-detail.component'));
const RoleUpdate = lazy(() => import('./role/role-update/role-update.component'));
const RoleWizard = lazy(() => import('./role/role-wizard/role-wizard.component'));
// #endregion
// #region Question Groups
const QuestionGroupCreate = lazy(() => import('./question-group/question-group-create/question-group-create.component'));
const QuestionGroupDetail = lazy(() => import('./question-group/question-group-detail/question-group-detail.component'));
const QuestionGroupOverview = lazy(() => import('./question-group/question-group-overview'));
const QuestionGroupUpdate = lazy(() => import('./question-group/question-group-update/question-group-update.component'));
const QuestionGroupMemberAdd = lazy(() => import('./question-group/question-group-member-add/question-group-member-add.component'));
// #endregion
// #region Question
const QuestionOverview = lazy(() => import('./question/question-overview'));
const QuestionCreate = lazy(() => import('./question/question-create/question-create.component'));
const QuestionDetail = lazy(() => import('./question/question-detail/question-detail.component'));
const QuestionUpdate = lazy(() => import('./question/question-update/question-update.component'));
// #endregion
// #region Answer
const AnswerOverview = lazy(() => import('./question/answer/answer-overview'));
const AnswerCreate = lazy(() => import('./question/answer/answer-create/answer-create.component'));
const AnswerDetail = lazy(() => import('./question/answer/answer-detail/answer-detail.component'));
const AnswerUpdate = lazy(() => import('./question/answer/answer-update/answer-update.component'));
// #endregion
// #region Playlist
const PlaylistOverview = lazy(() => import('./playlist/playlist-overview'));
const PlaylistCreate = lazy(() => import('./playlist/playlist-create/playlist-create.component'));
const PlaylistDetail = lazy(() => import('./playlist/playlist-detail/playlist-detail.component'));
const PlaylistUpdate = lazy(() => import('./playlist/playlist-update/playlist-update.component'));
const PlaylistItemCreate = lazy(() => import('./playlist/playlist-item-create/playlist-item-create.component'));
const PlaylistItemDetail = lazy(() => import('./playlist/playlist-item-detail/playlist-item-detail.component'));
const PlaylistItemUpdate = lazy(() => import('./playlist/playlist-item-update/playlist-item-update.component'));
// #endregion
// #region Impex
const ImpexOverview = lazy(() => import('./impex/impex-overview'));
const ImpexCreate = lazy(() => import('./impex/impex-create/impex-create.component'));
const ImpexDetail = lazy(() => import('./impex/impex-detail/impex-detail.component'));
// #endregion
// #region Courier
const CourierOverview = lazy(() => import('./courier/courier-overview'));
const CourierCreate = lazy(() => import('./courier/courier-create/courier-create.component'));
const CourierDetail = lazy(() => import('./courier/courier-detail/courier-detail.component'));
const CourierUpdate = lazy(() => import('./courier/courier-update/courier-update.component'));
// #endregion
// #region Spots
const SpotOverview = lazy(() => import('./spot/spot-overview'));
const SpotCreate = lazy(() => import('./spot/spot-create/spot-create.component'));
const SpotDetail = lazy(() => import('./spot/spot-detail/spot-detail.component'));
const SpotUpdate = lazy(() => import('./spot/spot-update/spot-update.component'));
// #endregion
// #region Modules
const ModuleOverview = lazy(() => import('./module/module-overview'));
const ModuleCreate = lazy(() => import('./module/module-create/module-create.component'));
const ModuleDetail = lazy(() => import('./module/module-detail/module-detail.component'));
const ModuleUpdate = lazy(() => import('./module/module-update/module-update.component'));
// #endregion
// #region Equipment
const EquipmentOverview = lazy(() => import('./equipment/equipment-overview'));
const EquipmentCreate = lazy(() => import('./equipment/equipment-create/equipment-create.component'));
const EquipmentDetail = lazy(() => import('./equipment/equipment-detail/equipment-detail.component'));
const EquipmentUpdate = lazy(() => import('./equipment/equipment-update/equipment-update.component'));
// #endregion
// #region Transaction
const TransactionOverview = lazy(() => import('./transaction/transaction-overview'));
const PuDoTransactionReportOverview = lazy(() => import('./transaction/pudo-transaction-report/pudo-transaction-report-overview'));
const PudoTransactionCreate = lazy(() => import('./transaction/pudo-transaction-create/pudo-transaction-create.component'));
const TransactionDetail = lazy(() => import('./transaction/transaction-detail/transaction-detail.component'));
// #region Slots
const SlotCreate = lazy(() => import('./slot/slot-create/slot-create.component'));
const SlotDetail = lazy(() => import('./slot/slot-detail/slot-detail.component'));
const SlotUpdate = lazy(() => import('./slot/slot-update/slot-update.component'));
// #endregion
// #region Users
const UserOverview = lazy(() => import('./user/user-overview'));
const UserDetail = lazy(() => import('./user/user-detail/user-detail.component'));
const UserCreate = lazy(() => import('./user/user-create/user-create.component'));
const UserUpdate = lazy(() => import('./user/user-update/user-update.component'));
// #endregion
// #region Products
const ProductOverview = lazy(() => import('./product/product-overview'));
const ProductCreate = lazy(() => import('./product/product-create/product-create.component'));
const ProductDetail = lazy(() => import('./product/product-detail/product-detail.component'));
const ProductUpdate = lazy(() => import('./product/product-update/product-update.component'));
const AddCertificate = lazy(() => import('./product/product-detail/add-certificate/add-certificate.component'));
// #endregion
// #region Categories
const CategoryOverview = lazy(() => import('./category/category-overview'));
const CategoryCreate = lazy(() => import('./category/category-create/category-create.component'));
const CategoryDetail = lazy(() => import('./category/category-detail/category-detail.component'));
const CategoryUpdate = lazy(() => import('./category/category-update/category-update.component'));
// #endregion
// #region Variants
const VariantOverview = lazy(() => import('./variant/variant-overview'));
const VariantCreate = lazy(() => import('./variant/variant-create/variant-create.component'));
const VariantDetail = lazy(() => import('./variant/variant-detail/variant-detail.component'));
const VariantUpdate = lazy(() => import('./variant/variant-update/variant-update.component'));
// #endregion
// #region Requirements
const RequirementCreate = lazy(() => import('./requirement/requirement-create/requirement-create.component'));
// #endregion
// #region Variants
const AttributeOverview = lazy(() => import('./attribute/attribute-overview'));
const AttributeCreate = lazy(() => import('./attribute/attribute-create/attribute-create.component'));
const AttributeDetail = lazy(() => import('./attribute/attribute-detail/attribute-detail.component'));
const AttributeUpdate = lazy(() => import('./attribute/attribute-update/attribute-update.component'));
const AttributeLinkingDetail = lazy(() => import('./attribute/attribute-linking-detail/attribute-linking-detail.component'));
const AttributeLinkingCreate = lazy(() => import('./attribute/attribute-linking-create/attribute-linking-create.component'));
const AttributeLinkingUpdate = lazy(() => import('./attribute/attribute-linking-update/attribute-linking-update.component'));
// #endregion
// #region Equipment Problems
const ProblemCreate = lazy(() => import('./problem/problem-create/problem-create.component'));
const ProblemDetail = lazy(() => import('./problem/problem-detail/problem-detail.component'));
const ProblemUpdate = lazy(() => import('./problem/problem-update/problem-update.component'));
const ProblemOverview = lazy(() => import('./problem/problem-overview/problem-overview'));
const ProblemEntityLink = lazy(() => import('./problem/problem-entity-link/problem-entity-link/problem-entity-link.component'));
const ReportProblem = lazy(() => import('./equipment/report-problem/report-problem.component'));
// #endregion
// #region Settings
const SettingCreate = lazy(() => import('./setting/setting-create/setting-create.component'));
// #endregion
// #region License
const LicenseCreate = lazy(() => import('./license/license-create/license-create.component'));
// #endregion
// #region Certificates
const CertificateOverview = lazy(() => import('./certificate/certificate-overview/certificate-overview'));
const CertificateCreate = lazy(() => import('./certificate/certificate-create/certificate-create.component'));
const CertificateUpdate = lazy(() => import('./certificate/certificate-update/certificate-update.component'));
const CertificateDetail = lazy(() => import('./certificate/certificate-detail/certificate-detail.component'));
// #endregion
// #region IoT
const IoTOverview = lazy(() => import('./iot/iot-overview/iot-overview'));
const IoTCreate = lazy(() => import('./iot/iot-create/iot-create.component'));
const IoTDetail = lazy(() => import('./iot/iot-detail/iot-detail.component'));
const IoTUpdate = lazy(() => import('./iot/iot-update/iot-update.component'));
const IoTSeed = lazy(() => import('./iot/iot-seed/iot-seed.component'));
const AttachModuleToIot = lazy(() => import('./iot/attach-module/attach-module.component'));
// #endregion
// #region Devices
const DeviceCreate = lazy(() => import('./device/device-create/device-create.component'));
const DeviceDetail = lazy(() => import('./device/device-detail/device-detail.component'));
const DeviceOverview = lazy(() => import('./device/device-overview/device-overview'));
const DeviceUpdate = lazy(() => import('./device/device-update/device-update.component'));
// #endregion
// #region Identifications
const IdentificationDetail = lazy(() => import('./identification/identification-detail/identification-detail.component'));
const IdentificationCreate = lazy(() => import('./identification/identification-create/identification-create.component'));
// #endregion
// #region Workflows
const WorkflowCreate = lazy(() => import('./workflow/workflow-create/workflow-create.component'));
const WorkflowUpdate = lazy(() => import('./workflow/workflow-update/workflow-update.component'));
const WorkflowDetail = lazy(() => import('./workflow/workflow-detail/workflow-detail.component'));
const WorkflowOverview = lazy(() => import('./workflow/workflow-overview'));
const WorkflowCopy = lazy(() => import('./workflow/workflow-copy/workflow-copy.component'));
const StepCreate = lazy(() => import('./workflow/step/step-create/step-create.component'));
// #endregion
// #region User interfaces
const UserInterfaceCreate = lazy(() => import('./user-interface/user-interface-create/user-interface-create.component'));
const UserInterfaceUpdate = lazy(() => import('./user-interface/user-interface-update/user-interface-update.component'));
const UserInterfaceDetail = lazy(() => import('./user-interface/user-interface-detail/user-interface-detail.component'));
const UserInterfaceButtonCreate = lazy(() => import('./user-interface/user-interface-button-create/user-interface-button-create.component'));
const UserInterfaceOverview = lazy(() => import('./user-interface/user-interface-overview'));
const UserInterfaceButtonUpdate = lazy(() => import('./user-interface/user-interface-button-update/user-interface-button-update.component'));
// #endregion
// #region Unknowns
const UnknownOverview = lazy(() => import('./unknown/unknown-overview/unknown-overview'));
const UnknownDetail = lazy(() => import('./unknown/unknown-detail/unknown-detail.component'));
const UnknownCreate = lazy(() => import('./unknown/unknown-create/unknown-create.component'));
// #endregion
// #region Lockers
const LockerDetail = lazy(() => import('./locker/locker-detail/locker-detail.component'));
// #endregion
// #region Stocks
const StockOverview = lazy(() => import('./stock/stock-overview/stock-overview'));
const StockCreate = lazy(() => import('./stock/stock-create/stock-create.component'));
const StockDetail = lazy(() => import('./stock/stock-detail/stock-detail.component'));
const StockUpdate = lazy(() => import('./stock/stock-update/stock-update.component'));
// #endregion
// #region Movements
const MovementOverview = lazy(() => import('./movement/movement-overview/movement-overview'));
const MovementDetail = lazy(() => import('./movement/movement-detail/movement-detail.component'));
// #endregion

// #region Warehouse
const StockOverviewReport = lazy(() => import('./stock/stock-overview-report/stock-overview-report'));
const MovementOverviewReport = lazy(() => import('./movement/movement-overview-report/movement-overview-report'));
// #endregion
// #region Budget uses
const BudgetUseCreate = lazy(() => import('./budget-use/budget-use-create/budget-use-create.component'));
// #endregion
// #region Packages
const PackageOverview = lazy(() => import('./package/package-overview/package-overview'));
const PackageDetail = lazy(() => import('./package/package-detail/package-detail.component'));
// #endregion
// #region Equipment Variables
const EquipmentVariableCreate = lazy(() => import('./equipment-variable/equipment-variable-create/equipment-variable-create.component'));
// #endregion
// #region Change Password
const ChangePassword = lazy(() => import('./authentication/change-password/change-password.component'));
// #endregion
// #region User Permissions
const UserPermissionsList = lazy(() => import('./permissions/user-permissions-list/user-permissions-list.component'));
// #endregion
// #region Contacts
const ContactOverview = lazy(() => import('./contact/contact-overview/contact-overview'));
const ContactDetail = lazy(() => import('./contact/contact-detail/contact-detail.component'));
const ContactCreate = lazy(() => import('./contact/contact-create/contact-create.component'));
const ContactUpdate = lazy(() => import('./contact/contact-update/contact-update.component'));
// #endregion

//#region Rules
const RuleOverview = lazy(() => import('./rule/rule-overview/rule-overview'));
const RuleCreate = lazy(() => import('./rule/rule-create/rule-create.component'));
const RuleDetail = lazy(() => import('./rule/rule-detail/rule-detail.component'));
// #endregion
//#region Seeds
const SeedOverview = lazy(() => import('./sync/seed-overview/seed-overview'));
// #endregion
// #region Events
const AllEventsOverview = lazy(() => import('./event/all-events-overview/all-events-overview'));
// #endregion
// #region User Certificates
const UserCertificateCreate = lazy(() => import('./user-certificate/user-certificate-create/user-certificate-create.component'));
const UserCertificateDetail = lazy(() => import('./user-certificate/user-certificate-detail/user-certificate-detail.component'));
const UserCertificateUpdate = lazy(() => import('./user-certificate/user-certificate-update/user-certificate-update.component'));
// #endregion
// #region Equipment Certificates
const EquipmentCertificateCreate = lazy(() => import('./equipment-certificate/equipment-certificate-create/equipment-certificate-create.component'));
const EquipmentCertificateDetail = lazy(() => import('./equipment-certificate/equipment-certificate-detail/equipment-certificate-detail.component'));
const EquipmentCertificateUpdate = lazy(() => import('./equipment-certificate/equipment-certificate-update/equipment-certificate-update.component'));
// #endregion
// #region Notification Templates
const NotificationTemplateOverview = lazy(() => import('./notification-template/notification-template-overview/notification-template-overview'));
const NotificationTemplateDetail = lazy(() => import('./notification-template/notification-template-detail/notification-template-detail.component'));
const NotificationTemplateCreate = lazy(() => import('./notification-template/notification-template-create/notification-template-create.component'));
const NotificationTemplateCopy = lazy(() => import('./notification-template/notification-template-copy/notification-template-copy.component'));
// #endregion
// #region Notification Contents
const NotificationContentCreate = lazy(() => import('./notification-content/notification-content-create/notification-content-create.component'));
const NotificationContentDetail = lazy(() => import('./notification-content/notification-content-detail/notification-content-detail.component'));

const envColor =
    process.env['NX_BUILD_ENV'] === 'development' ? ClassType.INFO : process.env['NX_BUILD_ENV'] === 'test' ? ClassType.SECONDARY : ClassType.PRIMARY;

export function App() {
    useScripts('https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js');
    useScripts('https://kit.fontawesome.com/42d5adcbca.js');
    const dispatch = useAppDispatch();
    const [navbarPinned] = useLocalStorageState('navbar-pinned', false);
    const [hovered] = useLocalStorageState('navbar-hovered', false);

    return (
        <TranslationProvider>
            <DocumentStoreProvider>
                <div className={`g-sidenav-show bg-gray-100 g-sidenav-${navbarPinned || hovered ? 'pinned' : 'hidden'}`}>
                    <div className={`min-height-300 bg-${envColor} position-absolute w-100 sticky-top top-bar`} />
                    <Router>
                        <Authentication>
                            <AuthorizationProvider dispatch={dispatch}>
                                <Resolver>
                                    <Navbar />
                                    <main className='main-content position-relative border-radius-lg'>
                                        <Header
                                            dispatch={dispatch}
                                            color={envColor}
                                        />
                                        <div className='container-fluid ps-4'>
                                            <AsyncComponent>
                                                <Routes>
                                                    <Route path='admin'>
                                                        <Route
                                                            index
                                                            element={<Configuration />}
                                                        />
                                                        <Route path='accounts'>
                                                            {/**
                                                        // #region Accounts
                                                        * */}
                                                            <Route
                                                                index
                                                                element={<AccountOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<AccountCreate />}
                                                            />
                                                            <Route path=':accountId'>
                                                                <Route
                                                                    index
                                                                    path='detail'
                                                                    element={<AccountDetail />}
                                                                />
                                                                <Route
                                                                    path='update'
                                                                    element={<AccountUpdate />}
                                                                />
                                                                {/**
                                                             // #region Account user groups
                                                            * */}
                                                                <Route path='user-groups'>
                                                                    <Route path=':groupId'>
                                                                        <Route
                                                                            index
                                                                            path='detail'
                                                                            element={<UserGroupDetail />}
                                                                        />
                                                                        <Route
                                                                            path='update'
                                                                            element={<UserGroupUpdate />}
                                                                        />
                                                                        <Route
                                                                            path='members/add'
                                                                            element={<UserGroupMemberAdd />}
                                                                        />
                                                                    </Route>
                                                                    <Route
                                                                        index
                                                                        element={<Navigate to='/admin/user-groups' />}
                                                                    />
                                                                </Route>
                                                                {/**
                                                             // #region Account equipment groups
                                                            * */}
                                                                <Route path='equipment-groups'>
                                                                    <Route path=':groupId'>
                                                                        <Route
                                                                            index
                                                                            path='detail'
                                                                            element={<EquipmentGroupDetail />}
                                                                        />
                                                                        <Route
                                                                            path='update'
                                                                            element={<EquipmentGroupUpdate />}
                                                                        />
                                                                        <Route
                                                                            path='members/add'
                                                                            element={<EquipmentGroupMemberAdd />}
                                                                        />
                                                                    </Route>
                                                                    <Route
                                                                        index
                                                                        element={<Navigate to='/admin/equipment-groups' />}
                                                                    />
                                                                </Route>
                                                                {/**
                                                             // #region Account product groups
                                                            * */}
                                                                <Route path='product-catalogues'>
                                                                    <Route path=':groupId'>
                                                                        <Route
                                                                            index
                                                                            path='detail'
                                                                            element={<ProductCatalogueDetail />}
                                                                        />
                                                                        <Route
                                                                            path='update'
                                                                            element={<ProductCatalogueUpdate />}
                                                                        />
                                                                        <Route
                                                                            path='members/add'
                                                                            element={<ProductCatalogueMemberAdd />}
                                                                        />
                                                                    </Route>
                                                                    <Route
                                                                        index
                                                                        element={<Navigate to='/admin/product-catalogues' />}
                                                                    />
                                                                </Route>
                                                                {/**
                                                             // #region Account product groups
                                                            * */}
                                                                <Route path='product-groups'>
                                                                    <Route path=':groupId'>
                                                                        <Route
                                                                            index
                                                                            path='detail'
                                                                            element={<ProductGroupDetail />}
                                                                        />
                                                                        <Route
                                                                            path='update'
                                                                            element={<ProductGroupUpdate />}
                                                                        />
                                                                        <Route
                                                                            path='members/add'
                                                                            element={<ProductGroupMemberAdd />}
                                                                        />
                                                                    </Route>
                                                                    <Route
                                                                        index
                                                                        element={<Navigate to='/admin/product-groups' />}
                                                                    />
                                                                </Route>
                                                                {/**
                                                             // #region Account contact groups
                                                            * */}
                                                                <Route path='contact-groups'>
                                                                    <Route path=':groupId'>
                                                                        <Route
                                                                            index
                                                                            path='detail'
                                                                            element={<ContactGroupDetail />}
                                                                        />
                                                                        <Route
                                                                            path='update'
                                                                            element={<ContactGroupUpdate />}
                                                                        />
                                                                        <Route
                                                                            path='members/add'
                                                                            element={<ContactGroupMemberAdd />}
                                                                        />
                                                                    </Route>
                                                                    <Route
                                                                        index
                                                                        element={<Navigate to='/admin/product-groups' />}
                                                                    />
                                                                </Route>

                                                                {/**
                                                                // #region Account budgets
                                                            * */}
                                                                <Route path='budgets'>
                                                                    <Route path=':budgetId'>
                                                                        <Route
                                                                            index
                                                                            path='detail'
                                                                            element={<BudgetDetail />}
                                                                        />
                                                                        <Route
                                                                            path='update'
                                                                            element={<BudgetUpdate />}
                                                                        />
                                                                        <Route path=':entityType'>
                                                                            <Route
                                                                                path='link'
                                                                                element={<BudgetEntityLink />}
                                                                            />
                                                                            <Route
                                                                                index
                                                                                element={<Navigate to='../detail' />}
                                                                            />
                                                                        </Route>

                                                                        <Route path='rules'>
                                                                            <Route path=':ruleId'>
                                                                                <Route
                                                                                    path='update'
                                                                                    element={<BudgetRuleUpdate />}
                                                                                />
                                                                            </Route>
                                                                            <Route
                                                                                path='create'
                                                                                element={<BudgetRuleCreate />}
                                                                            />
                                                                            <Route
                                                                                index
                                                                                element={<Navigate to='../detail' />}
                                                                            />
                                                                        </Route>
                                                                    </Route>
                                                                    <Route
                                                                        index
                                                                        element={<Navigate to='/admin/budgets' />}
                                                                    />
                                                                    <Route
                                                                        path='link/:entityId/:entityType/add'
                                                                        element={<EntityToBudgetLink />}
                                                                    />
                                                                </Route>
                                                                {/**
                                                                // #region Account documents
                                                            * */}
                                                                <Route path='documents'>
                                                                    <Route path=':documentId'>
                                                                        <Route
                                                                            index
                                                                            path='detail'
                                                                            element={<DocumentDetail />}
                                                                        />
                                                                        <Route
                                                                            path='update'
                                                                            element={<DocumentUpdate />}
                                                                        />
                                                                        <Route
                                                                            path='file'
                                                                            element={<DocumentFileUpload />}
                                                                        />
                                                                        <Route path=':entityType'>
                                                                            <Route
                                                                                path='link'
                                                                                element={<DocumentEntityLink />}
                                                                            />
                                                                            <Route
                                                                                index
                                                                                element={<Navigate to='../detail' />}
                                                                            />
                                                                        </Route>
                                                                    </Route>
                                                                    <Route
                                                                        index
                                                                        element={<Navigate to='/admin/documents' />}
                                                                    />
                                                                    <Route
                                                                        path='link/:entityId/:entityType/add'
                                                                        element={<EntityToDocumentLink />}
                                                                    />
                                                                </Route>
                                                                {/**
                                                             // #region Account cost centers
                                                            * */}
                                                                <Route path='cost-centers'>
                                                                    <Route
                                                                        index
                                                                        element={<Navigate to='/admin/cost-centers' />}
                                                                    />
                                                                    <Route path=':costCenterId'>
                                                                        <Route
                                                                            path='detail'
                                                                            element={<CostCenterDetail />}
                                                                        />
                                                                        <Route
                                                                            path='update'
                                                                            element={<CostCenterUpdate />}
                                                                        />
                                                                    </Route>
                                                                </Route>
                                                                {/**
                                                         // #region Account  tags
                                                         * */}
                                                                <Route path='tags'>
                                                                    <Route
                                                                        index
                                                                        element={<Navigate to='/admin/tags' />}
                                                                    />
                                                                    <Route path=':tagId'>
                                                                        <Route
                                                                            path='detail'
                                                                            element={<TagDetail />}
                                                                        />
                                                                    </Route>
                                                                </Route>
                                                                {/**
                                                             // #region Account roles
                                                            * */}
                                                                <Route path='roles'>
                                                                    <Route path=':roleId'>
                                                                        <Route
                                                                            index
                                                                            path='detail'
                                                                            element={<RoleDetail />}
                                                                        />
                                                                        <Route
                                                                            path='update'
                                                                            element={<RoleUpdate />}
                                                                        />
                                                                    </Route>
                                                                    <Route
                                                                        index
                                                                        element={<Navigate to='/admin/roles' />}
                                                                    />
                                                                </Route>
                                                                {/**
                                                             // #region Account playlists
                                                            * */}
                                                                <Route path='playlists'>
                                                                    <Route path=':playlistId'>
                                                                        <Route
                                                                            index
                                                                            path='detail'
                                                                            element={<PlaylistDetail />}
                                                                        />
                                                                        <Route
                                                                            path='update'
                                                                            element={<PlaylistUpdate />}
                                                                        />
                                                                        <Route path='playlist-items'>
                                                                            <Route path=':playlistItemId'>
                                                                                <Route
                                                                                    index
                                                                                    path='detail'
                                                                                    element={<PlaylistItemDetail />}
                                                                                />
                                                                                <Route
                                                                                    path='update'
                                                                                    element={<PlaylistItemUpdate />}
                                                                                />
                                                                            </Route>
                                                                            <Route
                                                                                path='create'
                                                                                element={<PlaylistItemCreate />}
                                                                            />
                                                                            <Route
                                                                                index
                                                                                element={<Navigate to='../detail' />}
                                                                            />
                                                                        </Route>
                                                                    </Route>
                                                                    <Route
                                                                        index
                                                                        element={<Navigate to='/admin/playlists' />}
                                                                    />
                                                                </Route>
                                                                {/**
                                                             // #region Account impexs
                                                            * */}
                                                                <Route path='impexs'>
                                                                    <Route path=':impexId'>
                                                                        <Route
                                                                            index
                                                                            path='detail'
                                                                            element={<ImpexDetail />}
                                                                        />
                                                                    </Route>
                                                                    <Route
                                                                        index
                                                                        element={<Navigate to='/admin/impexs' />}
                                                                    />
                                                                </Route>
                                                                {/**
                                                             // #region Account courier
                                                            * */}
                                                                <Route path='couriers'>
                                                                    <Route path=':courierId'>
                                                                        <Route
                                                                            index
                                                                            path='detail'
                                                                            element={<CourierDetail />}
                                                                        />
                                                                        <Route
                                                                            path='update'
                                                                            element={<CourierUpdate />}
                                                                        />
                                                                    </Route>
                                                                    <Route
                                                                        index
                                                                        element={<Navigate to='/admin/couriers' />}
                                                                    />
                                                                </Route>
                                                                {/**
                                                             // #region Account addresses
                                                            * */}
                                                                <Route path='addresses'>
                                                                    <Route path=':addressId'>
                                                                        <Route
                                                                            index
                                                                            path='detail'
                                                                            element={<AddressDetail />}
                                                                        />
                                                                        <Route
                                                                            path='update'
                                                                            element={<AddressUpdate />}
                                                                        />
                                                                        <Route path=':entityType'>
                                                                            <Route
                                                                                path='link'
                                                                                element={<AddressEntityLink />}
                                                                            />
                                                                            <Route
                                                                                index
                                                                                element={<Navigate to='../detail' />}
                                                                            />
                                                                        </Route>
                                                                    </Route>
                                                                    <Route
                                                                        index
                                                                        element={<Navigate to='/admin/addresses' />}
                                                                    />
                                                                    <Route
                                                                        path='link/:entityId/:entityType/add'
                                                                        element={<EntityToAddressLink />}
                                                                    />
                                                                </Route>
                                                                {/**
                                                             // #region Account question groups
                                                            * */}
                                                                <Route path='questionnaires'>
                                                                    <Route path=':groupId'>
                                                                        <Route
                                                                            index
                                                                            path='detail'
                                                                            element={<QuestionGroupDetail />}
                                                                        />
                                                                        <Route
                                                                            path='update'
                                                                            element={<QuestionGroupUpdate />}
                                                                        />
                                                                        <Route
                                                                            path='members/add'
                                                                            element={<QuestionGroupMemberAdd />}
                                                                        />
                                                                    </Route>
                                                                    <Route
                                                                        index
                                                                        element={<Navigate to='/admin/questionnaires' />}
                                                                    />
                                                                </Route>
                                                                {/**
                                                             // #region Account questions
                                                            * */}
                                                                <Route path='questions'>
                                                                    <Route
                                                                        path='create'
                                                                        element={<QuestionCreate />}
                                                                    />
                                                                    <Route path=':questionId'>
                                                                        <Route
                                                                            path='detail'
                                                                            element={<QuestionDetail />}
                                                                        />
                                                                        <Route
                                                                            path='update'
                                                                            element={<QuestionUpdate />}
                                                                        />
                                                                        <Route path='answers'>
                                                                            <Route
                                                                                path='create'
                                                                                element={<AnswerCreate />}
                                                                            />
                                                                            <Route path=':answerId'>
                                                                                <Route
                                                                                    path='detail'
                                                                                    element={<AnswerDetail />}
                                                                                />
                                                                                <Route
                                                                                    path='update'
                                                                                    element={<AnswerUpdate />}
                                                                                />
                                                                            </Route>
                                                                            <Route
                                                                                index
                                                                                element={<Navigate to='../detail' />}
                                                                            />
                                                                        </Route>
                                                                    </Route>
                                                                    <Route
                                                                        index
                                                                        element={<Navigate to='/admin/questions' />}
                                                                    />
                                                                </Route>
                                                                {/**
                                                            // #region Account spots
                                                            * */}
                                                                <Route path='spots'>
                                                                    <Route path=':spotId'>
                                                                        <Route
                                                                            index
                                                                            path='detail'
                                                                            element={<SpotDetail />}
                                                                        />
                                                                        <Route
                                                                            path='update'
                                                                            element={<SpotUpdate />}
                                                                        />
                                                                        {/**
                                                                    // #region Account spot requirements
                                                                     * */}
                                                                        <Route path='requirements'>
                                                                            <Route
                                                                                path='create'
                                                                                element={<RequirementCreate />}
                                                                            />
                                                                        </Route>
                                                                        {/**
                                                                     // #region Account spot modules
                                                                    * */}
                                                                        <Route path='modules'>
                                                                            <Route
                                                                                path='create'
                                                                                element={<ModuleCreate />}
                                                                            />
                                                                            <Route path=':moduleId'>
                                                                                <Route
                                                                                    index
                                                                                    path='detail'
                                                                                    element={<ModuleDetail />}
                                                                                />
                                                                                <Route
                                                                                    path='update'
                                                                                    element={<ModuleUpdate />}
                                                                                />
                                                                                {/**
                                                                             // #region Account spot module slots
                                                                            * */}
                                                                                <Route path='slots'>
                                                                                    <Route
                                                                                        path='create'
                                                                                        element={<SlotCreate />}
                                                                                    />
                                                                                    <Route path=':slotId'>
                                                                                        <Route
                                                                                            index
                                                                                            path='detail'
                                                                                            element={<SlotDetail />}
                                                                                        />
                                                                                        <Route
                                                                                            path='update'
                                                                                            element={<SlotUpdate />}
                                                                                        />
                                                                                        <Route path='stocks'>
                                                                                            <Route path=':stockId'>
                                                                                                <Route
                                                                                                    index
                                                                                                    path='detail'
                                                                                                    element={<StockDetail />}
                                                                                                />
                                                                                                <Route
                                                                                                    path='update'
                                                                                                    element={<StockUpdate />}
                                                                                                />
                                                                                            </Route>
                                                                                        </Route>
                                                                                        <Route path='stock-updates'>
                                                                                            <Route path=':movementId'>
                                                                                                <Route
                                                                                                    path='detail'
                                                                                                    element={<MovementDetail />}
                                                                                                />
                                                                                            </Route>
                                                                                        </Route>
                                                                                    </Route>
                                                                                    <Route
                                                                                        index
                                                                                        element={<Navigate to='../detail' />}
                                                                                    />
                                                                                </Route>
                                                                            </Route>
                                                                            <Route
                                                                                index
                                                                                element={<Navigate to='../detail' />}
                                                                            />
                                                                        </Route>
                                                                    </Route>
                                                                    <Route
                                                                        index
                                                                        element={<Navigate to='/admin/spots' />}
                                                                    />
                                                                </Route>
                                                                {/**
                                                             // #region Account equipment
                                                            * */}
                                                                <Route path='equipments'>
                                                                    <Route
                                                                        index
                                                                        element={<Navigate to='/admin/equipments' />}
                                                                    />
                                                                    <Route path=':equipmentId'>
                                                                        <Route
                                                                            path='detail'
                                                                            element={<EquipmentDetail />}
                                                                        />
                                                                        <Route
                                                                            path='update'
                                                                            element={<EquipmentUpdate />}
                                                                        />
                                                                        <Route
                                                                            path='add-tag'
                                                                            element={
                                                                                <IdentificationCreate
                                                                                    entity_type={EntityType.EQUIPMENT}
                                                                                    type={IdentificationType.TAG}
                                                                                    paramKey='equipmentId'
                                                                                />
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path='link-tag'
                                                                            element={<LinkTag />}
                                                                        />
                                                                        <Route path='equipment-certificates'>
                                                                            <Route
                                                                                path='create'
                                                                                element={<EquipmentCertificateCreate />}
                                                                            />
                                                                        </Route>
                                                                        <Route path='certificates'>
                                                                            <Route path=':certificateId'>
                                                                                <Route path='equipment-certificates'>
                                                                                    <Route path=':equipmentCertificateId'>
                                                                                        <Route
                                                                                            index
                                                                                            path='detail'
                                                                                            element={<EquipmentCertificateDetail />}
                                                                                        />
                                                                                        <Route
                                                                                            path='update'
                                                                                            element={<EquipmentCertificateUpdate />}
                                                                                        />
                                                                                    </Route>
                                                                                </Route>
                                                                            </Route>
                                                                        </Route>
                                                                        <Route path='problems'>
                                                                            <Route
                                                                                path='create'
                                                                                element={<ProblemCreate />}
                                                                            />
                                                                            <Route
                                                                                index
                                                                                element={<Navigate to='/admin/equipments' />}
                                                                            />
                                                                            <Route path=':problemId'>
                                                                                <Route
                                                                                    path='detail'
                                                                                    element={<ProblemDetail />}
                                                                                />
                                                                                <Route
                                                                                    path='update'
                                                                                    element={<ProblemUpdate />}
                                                                                />
                                                                                <Route
                                                                                    index
                                                                                    element={<Navigate to='equipments' />}
                                                                                />
                                                                            </Route>
                                                                        </Route>
                                                                        <Route path=':identificationType'>
                                                                            <Route
                                                                                index
                                                                                element={<Navigate to='../detail' />}
                                                                            />
                                                                            <Route path=':identificationId'>
                                                                                <Route
                                                                                    path='detail'
                                                                                    element={<IdentificationDetail />}
                                                                                />
                                                                            </Route>
                                                                        </Route>
                                                                        <Route
                                                                            path='report-problem'
                                                                            element={<ReportProblem />}
                                                                        />
                                                                        <Route
                                                                            path='add-variable'
                                                                            element={<EquipmentVariableCreate />}
                                                                        />
                                                                    </Route>
                                                                </Route>
                                                                {/**
                                                             // #region Account users
                                                            * */}
                                                                <Route path='users'>
                                                                    <Route path=':userId'>
                                                                        <Route
                                                                            index
                                                                            path='detail'
                                                                            element={<UserDetail />}
                                                                        />
                                                                        <Route
                                                                            path='update'
                                                                            element={<UserUpdate />}
                                                                        />
                                                                        <Route
                                                                            path='add-basic-auth'
                                                                            element={
                                                                                <IdentificationCreate
                                                                                    entity_type={EntityType.USER}
                                                                                    paramKey='userId'
                                                                                    type={IdentificationType.BASIC_AUTH}
                                                                                />
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path='add-budget-use'
                                                                            element={<BudgetUseCreate />}
                                                                        />
                                                                        <Route
                                                                            path='permissions'
                                                                            element={<UserPermissionsList />}
                                                                        />
                                                                        <Route
                                                                            path='add-budgets'
                                                                            element={<LinkBudgetToUsers />}
                                                                        />
                                                                        <Route path='user-certificates'>
                                                                            <Route
                                                                                path='create'
                                                                                element={<UserCertificateCreate />}
                                                                            />
                                                                        </Route>
                                                                        <Route path='certificates'>
                                                                            <Route path=':certificateId'>
                                                                                <Route path='user-certificates'>
                                                                                    <Route path=':userCertificateId'>
                                                                                        <Route
                                                                                            index
                                                                                            path='detail'
                                                                                            element={<UserCertificateDetail />}
                                                                                        />
                                                                                        <Route
                                                                                            path='update'
                                                                                            element={<UserCertificateUpdate />}
                                                                                        />
                                                                                    </Route>
                                                                                </Route>
                                                                            </Route>
                                                                        </Route>
                                                                    </Route>
                                                                    <Route
                                                                        index
                                                                        element={<Navigate to='/admin/users' />}
                                                                    />
                                                                </Route>
                                                                {/**
                                                             // #region Account products
                                                            * */}
                                                                <Route path='products'>
                                                                    <Route
                                                                        index
                                                                        element={<Navigate to='/admin/products' />}
                                                                    />
                                                                    <Route path=':productId'>
                                                                        <Route
                                                                            index
                                                                            path='detail'
                                                                            element={<ProductDetail />}
                                                                        />
                                                                        <Route
                                                                            path='update'
                                                                            element={<ProductUpdate />}
                                                                        />
                                                                        <Route
                                                                            path='add-stock'
                                                                            element={<StockCreate />}
                                                                        />
                                                                        <Route
                                                                            path='add-certificate'
                                                                            element={<AddCertificate />}
                                                                        />
                                                                        <Route path='variants'>
                                                                            <Route path=':variantId'>
                                                                                <Route
                                                                                    index
                                                                                    path='detail'
                                                                                    element={<VariantDetail />}
                                                                                />
                                                                                <Route
                                                                                    path='update'
                                                                                    element={<VariantUpdate />}
                                                                                />
                                                                                <Route path='attribute-linkings'>
                                                                                    <Route path=':attributeLinkingId'>
                                                                                        <Route
                                                                                            index
                                                                                            path='detail'
                                                                                            element={<AttributeLinkingDetail />}
                                                                                        />
                                                                                        <Route
                                                                                            path='update'
                                                                                            element={<AttributeLinkingUpdate />}
                                                                                        />
                                                                                    </Route>
                                                                                    <Route
                                                                                        path='create'
                                                                                        element={<AttributeLinkingCreate />}
                                                                                    />
                                                                                    <Route
                                                                                        index
                                                                                        element={<Navigate to='../detail' />}
                                                                                    />
                                                                                </Route>
                                                                            </Route>
                                                                            <Route
                                                                                path='create'
                                                                                element={<VariantCreate />}
                                                                            />
                                                                            <Route
                                                                                index
                                                                                element={<Navigate to='../detail' />}
                                                                            />
                                                                        </Route>
                                                                    </Route>
                                                                </Route>
                                                                {/**
                                                             // #region Account categories
                                                            * */}
                                                                <Route path='categories'>
                                                                    <Route
                                                                        index
                                                                        element={<Navigate to='/admin/categories' />}
                                                                    />
                                                                    <Route path=':categoryId'>
                                                                        <Route
                                                                            index
                                                                            path='detail'
                                                                            element={<CategoryDetail />}
                                                                        />
                                                                        <Route
                                                                            path='update'
                                                                            element={<CategoryUpdate />}
                                                                        />
                                                                    </Route>
                                                                </Route>
                                                                {/**
                                                             // #region Account attributes
                                                            * */}
                                                                <Route path='attributes'>
                                                                    <Route
                                                                        index
                                                                        element={<Navigate to='/admin/attributes' />}
                                                                    />
                                                                    <Route path=':attributeId'>
                                                                        <Route
                                                                            index
                                                                            path='detail'
                                                                            element={<AttributeDetail />}
                                                                        />
                                                                        <Route
                                                                            path='update'
                                                                            element={<AttributeUpdate />}
                                                                        />
                                                                    </Route>
                                                                </Route>
                                                                {/**
                                                             // #region Account Entities
                                                            * */}
                                                                <Route path=':entityType/:entityId'>
                                                                    {/**
                                                                     * // #region Entity Rules
                                                                     */}
                                                                    <Route path='rules'>
                                                                        <Route
                                                                            path='create'
                                                                            element={<RuleCreate />}
                                                                        />
                                                                    </Route>
                                                                    {/**
                                                                 // #region Entity roles
                                                                * */}
                                                                    <Route path='roles'>
                                                                        <Route
                                                                            path='create'
                                                                            element={<RoleWizard />}
                                                                        />
                                                                    </Route>
                                                                    {/**
                                                                 // #region Settings
                                                                * */}
                                                                    <Route path='settings'>
                                                                        <Route
                                                                            path='create'
                                                                            element={<SettingCreate />}
                                                                        />
                                                                    </Route>

                                                                    <Route path=':identificationType'>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='../detail' />}
                                                                        />
                                                                        <Route path=':identificationId'>
                                                                            <Route
                                                                                path='detail'
                                                                                element={<IdentificationDetail />}
                                                                            />
                                                                        </Route>
                                                                    </Route>
                                                                </Route>
                                                                {/**
                                                             // #region Account Licenses
                                                            * */}
                                                                <Route path='license'>
                                                                    <Route
                                                                        index
                                                                        element={<Navigate to='../detail' />}
                                                                    />
                                                                    <Route
                                                                        path='create'
                                                                        element={<LicenseCreate />}
                                                                    />
                                                                </Route>

                                                                {/**
                                                             // #region Account Certificates
                                                            * */}
                                                                <Route path='certificates'>
                                                                    <Route
                                                                        index
                                                                        element={<Navigate to='/admin/certificates' />}
                                                                    />
                                                                    <Route path=':certificateId'>
                                                                        <Route
                                                                            path='update'
                                                                            element={<CertificateUpdate />}
                                                                        />
                                                                        <Route
                                                                            path='detail'
                                                                            element={<CertificateDetail />}
                                                                        />
                                                                        <Route path='equipment-certificates'>
                                                                            <Route
                                                                                path='create'
                                                                                element={<EquipmentCertificateCreate />}
                                                                            />
                                                                        </Route>
                                                                        <Route path='user-certificates'>
                                                                            <Route
                                                                                path='create'
                                                                                element={<UserCertificateCreate />}
                                                                            />
                                                                        </Route>
                                                                    </Route>
                                                                </Route>
                                                                {/**
                                                             // #region Account Workflows
                                                            * */}
                                                                <Route path='workflows'>
                                                                    <Route
                                                                        index
                                                                        element={<Navigate to='/admin/workflows' />}
                                                                    />
                                                                    <Route path=':workflowId'>
                                                                        <Route
                                                                            index
                                                                            path='detail'
                                                                            element={<WorkflowDetail />}
                                                                        />
                                                                        <Route
                                                                            path='copy'
                                                                            element={<WorkflowCopy />}
                                                                        />
                                                                        <Route
                                                                            path='update'
                                                                            element={<WorkflowUpdate />}
                                                                        />
                                                                        <Route path='steps'>
                                                                            <Route
                                                                                path='create'
                                                                                element={<StepCreate />}
                                                                            />
                                                                        </Route>
                                                                    </Route>
                                                                </Route>
                                                                {/**
                                                             // #region Account user interfaces
                                                            * */}
                                                                <Route path='user-interfaces'>
                                                                    <Route
                                                                        index
                                                                        element={<Navigate to='/admin/user-interfaces' />}
                                                                    />
                                                                    <Route path=':userInterfaceId'>
                                                                        <Route
                                                                            index
                                                                            path='detail'
                                                                            element={<UserInterfaceDetail />}
                                                                        />
                                                                        <Route
                                                                            path='update'
                                                                            element={<UserInterfaceUpdate />}
                                                                        />
                                                                        <Route path='button'>
                                                                            <Route
                                                                                path='create'
                                                                                element={<UserInterfaceButtonCreate />}
                                                                            />
                                                                        </Route>
                                                                        <Route path='buttons'>
                                                                            <Route path=':buttonId'>
                                                                                <Route
                                                                                    path='update'
                                                                                    element={<UserInterfaceButtonUpdate />}
                                                                                />
                                                                            </Route>
                                                                        </Route>
                                                                    </Route>
                                                                </Route>
                                                                {/**
                                                             // #region Account IoTs
                                                            * */}
                                                                <Route path='iots'>
                                                                    <Route
                                                                        index
                                                                        element={<Navigate to='/admin/iots' />}
                                                                    />
                                                                    <Route path=':iotId'>
                                                                        <Route
                                                                            path='detail'
                                                                            element={<IoTDetail />}
                                                                        />
                                                                        <Route
                                                                            path='update'
                                                                            element={<IoTUpdate />}
                                                                        />
                                                                        <Route
                                                                            path='seed'
                                                                            element={<IoTSeed />}
                                                                        />
                                                                        <Route path='devices'>
                                                                            <Route
                                                                                index
                                                                                element={<Navigate to='../detail' />}
                                                                            />
                                                                            <Route
                                                                                path='create'
                                                                                element={<DeviceCreate />}
                                                                            />
                                                                            <Route path=':deviceId'>
                                                                                <Route
                                                                                    path='detail'
                                                                                    element={<DeviceDetail />}
                                                                                />
                                                                                <Route
                                                                                    path='attach-module'
                                                                                    element={<AttachModuleToIot />}
                                                                                />
                                                                                <Route
                                                                                    path='update'
                                                                                    element={<DeviceUpdate />}
                                                                                />
                                                                                <Route path='lockers'>
                                                                                    <Route path=':lockerId'>
                                                                                        <Route
                                                                                            path='detail'
                                                                                            element={<LockerDetail />}
                                                                                        />
                                                                                    </Route>
                                                                                </Route>
                                                                            </Route>
                                                                        </Route>
                                                                    </Route>
                                                                </Route>
                                                                {/**
                                                                 * // #region Account Problems
                                                                 */}
                                                                <Route path='problems'>
                                                                    <Route
                                                                        index
                                                                        element={<Navigate to='/admin/problems' />}
                                                                    />
                                                                    <Route path=':problemId'>
                                                                        <Route
                                                                            path='detail'
                                                                            element={<ProblemDetail />}
                                                                        />
                                                                        <Route
                                                                            path='update'
                                                                            element={<ProblemUpdate />}
                                                                        />
                                                                        <Route path=':entityType'>
                                                                            <Route
                                                                                path='link'
                                                                                element={<ProblemEntityLink />}
                                                                            />
                                                                            <Route
                                                                                index
                                                                                element={<Navigate to='../detail' />}
                                                                            />
                                                                        </Route>
                                                                    </Route>
                                                                </Route>
                                                                {/**
                                                             // #region Account Contacts
                                                            * */}
                                                                <Route path='contacts'>
                                                                    <Route
                                                                        index
                                                                        element={<Navigate to='/admin/contacts' />}
                                                                    />
                                                                    <Route path=':contactId'>
                                                                        <Route
                                                                            path='detail'
                                                                            element={<ContactDetail />}
                                                                        />
                                                                        <Route
                                                                            path='update'
                                                                            element={<ContactUpdate />}
                                                                        />
                                                                    </Route>
                                                                </Route>
                                                                {/**
                                                             // #region Account Transactions
                                                            * */}
                                                                <Route path='transactions'>
                                                                    <Route
                                                                        index
                                                                        element={<Navigate to='/admin/transactions' />}
                                                                    />
                                                                    <Route path=':transactionId'>
                                                                        <Route
                                                                            path='detail'
                                                                            element={<TransactionDetail />}
                                                                        />
                                                                        <Route path='packages'>
                                                                            <Route
                                                                                index
                                                                                element={<Navigate to='/admin/packages' />}
                                                                            />
                                                                            <Route path=':packageId'>
                                                                                <Route
                                                                                    path='detail'
                                                                                    element={<PackageDetail />}
                                                                                />
                                                                            </Route>
                                                                        </Route>
                                                                    </Route>
                                                                </Route>
                                                                {/**
                                                             // #region Account Rules
                                                             * */}
                                                                <Route path='rules'>
                                                                    <Route
                                                                        index
                                                                        element={<Navigate to='/admin/rules' />}
                                                                    />
                                                                    <Route path=':ruleId'>
                                                                        <Route
                                                                            path='detail'
                                                                            element={<RuleDetail />}
                                                                        />
                                                                    </Route>
                                                                </Route>
                                                                {/**
                                                                  // #region Account Notification Templates
                                                                 * */}
                                                                <Route path='notification-templates'>
                                                                    <Route path=':templateId'>
                                                                        <Route
                                                                            index
                                                                            path='detail'
                                                                            element={<NotificationTemplateDetail />}
                                                                        />
                                                                        <Route
                                                                            path='copy'
                                                                            element={<NotificationTemplateCopy />}
                                                                        />
                                                                        <Route path='content'>
                                                                            <Route
                                                                                path='create'
                                                                                element={<NotificationContentCreate />}
                                                                            />
                                                                            <Route path=':contentId'>
                                                                                <Route
                                                                                    index
                                                                                    path='detail'
                                                                                    element={<NotificationContentDetail />}
                                                                                />
                                                                            </Route>
                                                                        </Route>
                                                                    </Route>
                                                                    <Route
                                                                        index
                                                                        element={<Navigate to='/admin/notification-templates' />}
                                                                    />
                                                                </Route>
                                                            </Route>
                                                        </Route>
                                                        {/**
                                                     // #region User groups
                                                    * */}
                                                        <Route path='user-groups'>
                                                            <Route
                                                                index
                                                                element={<UserGroupOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<UserGroupCreate />}
                                                            />
                                                        </Route>
                                                        {/**
                                                     // #region Equipment groups
                                                    * */}
                                                        <Route path='equipment-groups'>
                                                            <Route
                                                                index
                                                                element={<EquipmentGroupOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<EquipmentGroupCreate />}
                                                            />
                                                        </Route>
                                                        {/**
                                                     // #region Product catalogues
                                                    * */}
                                                        <Route path='product-catalogues'>
                                                            <Route
                                                                index
                                                                element={<ProductCatalogueOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<ProductCatalogueCreate />}
                                                            />
                                                        </Route>
                                                        {/**
                                                     // #region Product groups
                                                    * */}
                                                        <Route path='product-groups'>
                                                            <Route
                                                                index
                                                                element={<ProductGroupOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<ProductGroupCreate />}
                                                            />
                                                        </Route>
                                                        <Route path='contact-groups'>
                                                            <Route
                                                                index
                                                                element={<ContactGroupOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<ContactGroupCreate />}
                                                            />
                                                        </Route>
                                                        {/**
                                                     // #region Cost centers
                                                    * */}
                                                        <Route path='cost-centers'>
                                                            <Route
                                                                index
                                                                element={<CostCenterOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<CostCenterCreate />}
                                                            />
                                                        </Route>
                                                        {/**
                                                        // #region Budgets
                                                    * */}
                                                        <Route path='budgets'>
                                                            <Route
                                                                index
                                                                element={<BudgetOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<BudgetCreate />}
                                                            />
                                                        </Route>
                                                        {/**
                                                        // #region Document
                                                    * */}
                                                        <Route path='documents'>
                                                            <Route
                                                                index
                                                                element={<DocumentOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<DocumentCreate />}
                                                            />
                                                        </Route>
                                                        {/**
                                                    // #region  Tags
                                                    * */}
                                                        <Route path='tags'>
                                                            <Route
                                                                index
                                                                element={<TagOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<IdentificationCreate type={IdentificationType.TAG} />}
                                                            />
                                                        </Route>
                                                        {/*
                                                        // #region Roles
                                                    * */}
                                                        <Route path='roles'>
                                                            <Route
                                                                index
                                                                element={<RoleOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<RoleCreate />}
                                                            />
                                                        </Route>
                                                        {/**
                                                     // #region Question groups
                                                    * */}
                                                        <Route path='questionnaires'>
                                                            <Route
                                                                index
                                                                element={<QuestionGroupOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<QuestionGroupCreate />}
                                                            />
                                                        </Route>
                                                        {/**
                                                        // #region Questions
                                                    * */}
                                                        <Route path='questions'>
                                                            <Route
                                                                index
                                                                element={<QuestionOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<QuestionCreate />}
                                                            />
                                                        </Route>
                                                        {/**
                                                        // #region Answers
                                                    * */}
                                                        <Route path='answers'>
                                                            <Route
                                                                index
                                                                element={<AnswerOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<AnswerCreate />}
                                                            />
                                                        </Route>
                                                        {/*
                                                        // #region Addresses
                                                    * */}
                                                        <Route path='addresses'>
                                                            <Route
                                                                index
                                                                element={<AddressOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<AddressCreate />}
                                                            />
                                                        </Route>
                                                        {/*
                                                        // #region Variants
                                                    * */}
                                                        <Route path='variants'>
                                                            <Route
                                                                index
                                                                element={<VariantOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<VariantCreate />}
                                                            />
                                                        </Route>
                                                        {/*
                                                        // #region Playlists
                                                    * */}
                                                        <Route path='playlists'>
                                                            <Route
                                                                index
                                                                element={<PlaylistOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<PlaylistCreate />}
                                                            />
                                                        </Route>
                                                        {/*
                                                        // #region Playlists
                                                    * */}
                                                        <Route path='impexs'>
                                                            <Route
                                                                index
                                                                element={<ImpexOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<ImpexCreate />}
                                                            />
                                                        </Route>
                                                        {/*
                                                        // #region Couriers
                                                    * */}
                                                        <Route path='couriers'>
                                                            <Route
                                                                index
                                                                element={<CourierOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<CourierCreate />}
                                                            />
                                                        </Route>
                                                        {/*
                                                        // #region Spots
                                                    * */}
                                                        <Route path='spots'>
                                                            <Route
                                                                index
                                                                element={<SpotOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<SpotCreate />}
                                                            />
                                                        </Route>
                                                        {/*
                                                        // #region Modules
                                                    * */}
                                                        <Route path='modules'>
                                                            <Route
                                                                index
                                                                element={<ModuleOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<ModuleCreate />}
                                                            />
                                                        </Route>
                                                        {/*
                                                        // #region Equipment
                                                    * */}
                                                        <Route path='equipments'>
                                                            <Route
                                                                index
                                                                element={<EquipmentOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<EquipmentCreate />}
                                                            />
                                                        </Route>
                                                        {/*
                                                        // #region Transactions
                                                    * */}
                                                        <Route path='transactions'>
                                                            <Route
                                                                index
                                                                element={<TransactionOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<PudoTransactionCreate />}
                                                            />
                                                        </Route>
                                                        {/*
                                                        // #region Slots
                                                    * */}
                                                        {/* <Route path='slots'>
                                                        <Route
                                                            index
                                                            element={<SlotOverview />}
                                                        />
                                                        <Route
                                                            path='create'
                                                            element={<SlotCreate />}
                                                        />
                                                    </Route> */}
                                                        {/*
                                                        // #region Users
                                                    * */}
                                                        <Route path='users'>
                                                            <Route
                                                                index
                                                                element={<UserOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<UserCreate />}
                                                            />
                                                        </Route>
                                                        {/*
                                                        // #region Products
                                                    * */}
                                                        <Route path='products'>
                                                            <Route
                                                                index
                                                                element={<ProductOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<ProductCreate />}
                                                            />
                                                        </Route>
                                                        {/*
                                                        // #region Categories
                                                    * */}
                                                        <Route path='categories'>
                                                            <Route
                                                                index
                                                                element={<CategoryOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<CategoryCreate />}
                                                            />
                                                        </Route>
                                                        {/*
                                                        // #region Attributes
                                                    * */}
                                                        <Route path='attributes'>
                                                            <Route
                                                                index
                                                                element={<AttributeOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<AttributeCreate />}
                                                            />
                                                        </Route>
                                                        {/*
                                                        // #region Certificates
                                                    * */}
                                                        <Route path='certificates'>
                                                            <Route
                                                                index
                                                                element={<CertificateOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<CertificateCreate />}
                                                            />
                                                        </Route>
                                                        {/*
                                                        // #region Workflows
                                                    * */}
                                                        <Route path='workflows'>
                                                            <Route
                                                                index
                                                                element={<WorkflowOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<WorkflowCreate />}
                                                            />
                                                        </Route>
                                                        {/*
                                                        // #region User interfaces
                                                    * */}
                                                        <Route path='user-interfaces'>
                                                            <Route
                                                                index
                                                                element={<UserInterfaceOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<UserInterfaceCreate dispatch={dispatch} />}
                                                            />
                                                        </Route>
                                                        {/*
                                                        // #region IoT
                                                    * */}
                                                        <Route path='iots'>
                                                            <Route
                                                                index
                                                                element={<IoTOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<IoTCreate />}
                                                            />
                                                        </Route>
                                                        {/*
                                                        // #region Problems
                                                    * */}
                                                        <Route path='problems'>
                                                            <Route
                                                                index
                                                                element={<ProblemOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<ProblemCreate />}
                                                            />
                                                        </Route>
                                                        {/** *
                                                 // #region Unknowns
                                                 * */}
                                                        <Route path='unknowns'>
                                                            <Route
                                                                index
                                                                element={<UnknownOverview />}
                                                            />
                                                            <Route path=':unknownId'>
                                                                <Route
                                                                    index
                                                                    path='detail'
                                                                    element={<UnknownDetail />}
                                                                />
                                                                <Route
                                                                    path='create-iot'
                                                                    element={<UnknownCreate />}
                                                                />
                                                            </Route>
                                                        </Route>
                                                        {/*
                                                        // #region Devices
                                                    * */}
                                                        <Route path='devices'>
                                                            <Route
                                                                index
                                                                element={<DeviceOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<DeviceCreate />}
                                                            />
                                                        </Route>
                                                        {/*
                                                        // #region Stocks
                                                    * */}
                                                        <Route path='stocks'>
                                                            <Route
                                                                index
                                                                element={<StockOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<StockCreate />}
                                                            />
                                                        </Route>
                                                        {/*
                                                        // #region Movements
                                                    * */}
                                                        <Route path='stock-updates'>
                                                            <Route
                                                                index
                                                                element={<MovementOverview />}
                                                            />
                                                        </Route>
                                                        {/*
                                                        // #region Packages
                                                    * */}
                                                        <Route path='packages'>
                                                            <Route
                                                                index
                                                                element={<PackageOverview />}
                                                            />
                                                        </Route>
                                                        {/*
                                                        // #region Contacts
                                                    * */}
                                                        <Route path='contacts'>
                                                            <Route
                                                                index
                                                                element={<ContactOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<ContactCreate />}
                                                            />
                                                        </Route>
                                                        {/*
                                                        // #region Rules
                                                    * */}
                                                        <Route path='rules'>
                                                            <Route
                                                                index
                                                                element={<RuleOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<RuleCreate />}
                                                            />
                                                        </Route>
                                                        {/*
                                                        // #region Seeds
                                                    * */}
                                                        <Route path='seeds'>
                                                            <Route
                                                                index
                                                                element={<SeedOverview />}
                                                            />
                                                        </Route>
                                                        {/*
                                                        // #region Events
                                                    * */}
                                                        <Route path='sync-events'>
                                                            <Route
                                                                index
                                                                element={
                                                                    <AllEventsOverview
                                                                        type={[
                                                                            EventType.ENTITY_SYNC_DELETED,
                                                                            EventType.ENTITY_SYNC_UPDATED,
                                                                            EventType.ENTITY_SYNC_CREATED
                                                                        ]}
                                                                        title={CommonMessage.OBJECTS.EVENT.SYNC_EVENTS}
                                                                    />
                                                                }
                                                            />
                                                        </Route>
                                                        <Route path='notification-templates'>
                                                            <Route
                                                                index
                                                                element={<NotificationTemplateOverview />}
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={<NotificationTemplateCreate />}
                                                            />
                                                        </Route>
                                                    </Route>
                                                    {/** *
                                                     * // #region Warehouse
                                                     * */}
                                                    <Route path='warehouse'>
                                                        <Route
                                                            path='inventory'
                                                            element={<StockOverviewReport />}
                                                        />
                                                        <Route
                                                            path='history'
                                                            element={<MovementOverviewReport />}
                                                        />
                                                    </Route>
                                                    {/** *
                                                     * // #region Change Password
                                                     * */}
                                                    <Route path='change-password'>
                                                        <Route
                                                            index
                                                            element={<ChangePassword />}
                                                        />
                                                    </Route>
                                                    {/** *
                                                 // #region Profile
                                                 * */}
                                                    <Route path='profile'>
                                                        <Route
                                                            index
                                                            element={<Profile dispatch={dispatch} />}
                                                        />
                                                    </Route>
                                                    {/** *
                                                     * // #region PuDoTransactionReportOverview
                                                     * */}
                                                    <Route path='pudo'>
                                                        <Route
                                                            path='transactions'
                                                            element={<PuDoTransactionReportOverview />}
                                                        />
                                                    </Route>
                                                    <Route
                                                        path='/'
                                                        element={<Navigate to='/profile' />}
                                                    />
                                                    <Route
                                                        path='/*'
                                                        element={<Navigate to='/profile' />}
                                                    />
                                                </Routes>
                                            </AsyncComponent>
                                        </div>
                                        <Footer />
                                    </main>
                                </Resolver>
                            </AuthorizationProvider>
                        </Authentication>
                    </Router>
                    <ToastContainer />
                </div>
            </DocumentStoreProvider>
        </TranslationProvider>
    );
}

export default App;
