import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { JobQueryParams, PaginatedJobErrorResponse } from '@frontend/import/types';

const endpoint = '/import-api/v1'

export class JobErrorClient extends APIClient {
    public static async fetchJobErrors(queryParams?: ApiQueryParams<JobQueryParams>): Promise<PaginatedJobErrorResponse> {
        return await this.apiPaginated<PaginatedJobErrorResponse, DefaultQueryParams>(`${endpoint}/errors`, queryParams);
    }

    public static async resolveJobErrors(jobIds: string[]): Promise<PaginatedJobErrorResponse> {
        const response = await this.post(`${endpoint}/errors-resolve`, { ids: jobIds });
        return await this.handleResponse<PaginatedJobErrorResponse>(response);
    }
}
