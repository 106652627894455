/* eslint-disable no-unused-vars */

/* eslint-disable @typescript-eslint/no-use-before-define */
import { useAccount } from '@frontend/account/utils';
import { useLocalStorageState } from '@frontend/common';
import { SettingClient } from '@frontend/setting/api';
import { useEffect, useMemo, useRef } from 'react';
import { BiSolidReport } from 'react-icons/bi';
import { BsShop } from 'react-icons/bs';
import { FaHandHolding, FaHome } from 'react-icons/fa';
import { FaLocationDot } from 'react-icons/fa6';
import { GiVendingMachine } from 'react-icons/gi';
import { LuPackage, LuWarehouse } from 'react-icons/lu';
import { MdOutlineMonitorHeart } from 'react-icons/md';
import { TbClipboardText } from 'react-icons/tb';

import defaultLogoCollapsed from '../../../assets/images/logo200x200.png';
import defaultLogoExpanded from '../../../assets/images/telloport_logo_horizontal.png';
import { useTheme } from '../../theme/theme-resolver';
import { NavbarGroupItem } from '../navbar-group-item/navbar-group-item.component';
import NavbarItem from '../navbar-item/navbar-item.component';

const ID = 'sidenav-main';

const Navbar = () => {
    useTheme();
    const { currentAccount } = useAccount();
    const navbarRef = useRef<HTMLDivElement>(null);
    const [navbarPinned, changeNavbarPinned] = useLocalStorageState('navbar-pinned', false);
    const [hovered, changeHovered] = useLocalStorageState('navbar-hovered', false);
    const [logoNavbarExpanded, changeLogoNavbarExpanded] = useLocalStorageState<string | undefined>('logo_navbar_expanded', '');
    const [logoNavbarCollapsed, changeLogoNavbarCollapsed] = useLocalStorageState<string | undefined>('logo_navbar_collapsed', '');
    useEffect(() => {
        if (currentAccount)
            SettingClient.fetchSettings({ account_id: currentAccount }).then((settings) => {
                changeLogoNavbarExpanded((settings.results.find((setting) => setting.key === 'logo_navbar_expanded')?.value ?? '') as string);
                changeLogoNavbarCollapsed((settings.results.find((setting) => setting.key === 'logo_navbar_collapsed')?.value ?? '') as string);
            });
    }, [currentAccount]);

    const logo = useMemo(() => {
        if ((!logoNavbarExpanded || !logoNavbarCollapsed) && (logoNavbarExpanded || logoNavbarCollapsed)) return logoNavbarExpanded || logoNavbarCollapsed;
        if (navbarPinned || hovered) return logoNavbarExpanded || defaultLogoExpanded;
        else return logoNavbarCollapsed || defaultLogoCollapsed;
    }, [navbarPinned, hovered, logoNavbarExpanded, logoNavbarCollapsed]);

    return (
        <div
            id={ID}
            onMouseOver={() => changeHovered(true)}
            onMouseLeave={() => changeHovered(false)}
            className='sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 fixed-start border border-danger'>
            <div className='sidenav-header d-flex flex-row justify-content-between align-items-center'>
                <a
                    className='navbar-brand m-0'
                    href='/'>
                    <img
                        src={logo}
                        className='navbar-brand-img h-100'
                        alt='main_logo'
                    />
                </a>
                {(navbarPinned || hovered) && (
                    <div className='sidenav-toggler sidenav-toggler-inner d-block pe-5'>
                        <div
                            onClick={() => changeNavbarPinned(!navbarPinned)}
                            className='nav-link p-0 cursor-pointer'>
                            <div className='sidenav-toggler-inner'>
                                <i className='sidenav-toggler-line'></i>
                                <i className='sidenav-toggler-line'></i>
                                <i className='sidenav-toggler-line'></i>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <hr className='horizontal dark mt-0' />

            <div
                className='collapse navbar-collapse w-auto h-auto'
                id='sidenav-collapse-main'
                ref={navbarRef}>
                <ul className='navbar-nav h-100'>
                    <NavbarItem
                        icon={<FaHome />}
                        link={NavbarItems.PROFILE}
                        text='Profile'
                        enabled
                    />
                    <NavbarGroupItem
                        navbarRef={navbarRef}
                        title='Vending'
                        link='Vending'
                        icon={GiVendingMachine}>
                        <>
                            <NavbarItem
                                link={NavbarItems.INVENTORY}
                                text='Inventory'
                            />
                            <NavbarItem
                                link={NavbarItems.LOCATIONS}
                                text='Locations'
                            />
                        </>
                    </NavbarGroupItem>
                    <NavbarGroupItem
                        navbarRef={navbarRef}
                        title='Lending'
                        link='Lending'
                        icon={FaHandHolding}>
                        <>
                            <NavbarItem
                                link={NavbarItems.ASSETS}
                                text='Assets'
                            />
                            <NavbarItem
                                link={NavbarItems.LOCATIONS}
                                text='Locations'
                            />
                        </>
                    </NavbarGroupItem>
                    <NavbarGroupItem
                        navbarRef={navbarRef}
                        title='PuDo'
                        link='PuDo'
                        icon={LuPackage}>
                        <>
                            <NavbarItem
                                link={NavbarItems.TRANSACTIONS}
                                text='Transactions'
                                parent='PuDo'
                            />
                            <NavbarItem
                                link={NavbarItems.LOCATIONS}
                                text='Locations'
                            />
                            <NavbarItem
                                link={NavbarItems.CONTACTS}
                                text='My Contacts'
                            />
                            <NavbarItem
                                link={NavbarItems.SHARED_CONTACTS}
                                text='Shared Contacts'
                            />
                        </>
                    </NavbarGroupItem>
                    <NavbarGroupItem
                        navbarRef={navbarRef}
                        title='Warehouse'
                        link='Warehouse'
                        icon={LuWarehouse}>
                        <>
                            <NavbarItem
                                link={NavbarItems.INVENTORY}
                                text='Inventory'
                                parent='warehouse'
                            />
                            <NavbarItem
                                link={NavbarItems.HISTORY}
                                text='History'
                                parent='warehouse'
                            />
                        </>
                    </NavbarGroupItem>
                    <NavbarGroupItem
                        navbarRef={navbarRef}
                        title='Shop'
                        link='Shop'
                        icon={BsShop}>
                        <>
                            <NavbarItem
                                link={NavbarItems.PRODUCTS}
                                text='Products'
                            />
                            <NavbarItem
                                link={NavbarItems.LOCATIONS}
                                text='Locations'
                            />
                        </>
                    </NavbarGroupItem>
                    <NavbarGroupItem
                        navbarRef={navbarRef}
                        title='Inventory'
                        link='Inventory'
                        icon={TbClipboardText}>
                        <>
                            <NavbarItem
                                link={NavbarItems.PRODUCTS}
                                text='Products'
                            />
                            <NavbarItem
                                link={NavbarItems.CATALOGUES}
                                text='Catalogues'
                            />
                        </>
                    </NavbarGroupItem>
                    {/*<NavbarGroupItem*/}
                    {/*    navbarRef={navbarRef}*/}
                    {/*    title='AddressBook'*/}
                    {/*    link='AddressBook'*/}
                    {/*    icon={FaRegAddressBook}>*/}
                    {/*    <>*/}
                    {/*        <NavbarItem*/}
                    {/*            link={NavbarItems.CONTACTS}*/}
                    {/*            text='My Contacts'*/}
                    {/*        />*/}
                    {/*        <NavbarItem*/}
                    {/*            link={NavbarItems.SHARED_CONTACTS}*/}
                    {/*            text='Shared Contacts'*/}
                    {/*        />*/}
                    {/*    </>*/}
                    {/*</NavbarGroupItem>*/}
                    <NavbarGroupItem
                        navbarRef={navbarRef}
                        title='distriSPOTs'
                        link='distriSPOTs'
                        icon={FaLocationDot}>
                        <>
                            <NavbarItem
                                link={NavbarItems.SPOTS}
                                text='My Spots'
                            />
                            <NavbarItem
                                link={NavbarItems.SHARED_SPOTS}
                                text='Shared Spots'
                            />
                        </>
                    </NavbarGroupItem>
                    <NavbarGroupItem
                        navbarRef={navbarRef}
                        title='Reporting'
                        link='Reporting'
                        icon={BiSolidReport}>
                        <>
                            <NavbarItem
                                text='Reporting'
                                icon={<BiSolidReport />}
                                link={NavbarItems.REPORTING}
                            />
                            <NavbarItem
                                text='Monitoring'
                                icon={<MdOutlineMonitorHeart />}
                                link={NavbarItems.MONITORING}
                            />
                        </>
                    </NavbarGroupItem>
                    {/*<NavbarGroupItem*/}
                    {/*    navbarRef={navbarRef}*/}
                    {/*    title={CommonMessage.NAVIGATION.FINANCIALS}*/}
                    {/*    link='Financials'*/}
                    {/*    icon={ICONS.NAVIGATION.FINANCIALS}>*/}
                    {/*    <></>*/}
                    {/*</NavbarGroupItem>*/}
                </ul>
            </div>
        </div>
    );
};

export enum NavbarItems {
    PROFILE = 'profile',
    INVENTORY = 'inventory',
    LOCATIONS = 'locations',
    ASSETS = 'assets',
    TRANSACTIONS = 'transactions',
    PRODUCTS = 'products',
    CATALOGUES = 'catalogues',
    CONTACTS = 'contacts',
    SHARED_CONTACTS = 'shared-contacts',
    SPOTS = 'spots',
    SHARED_SPOTS = 'shared-spots',
    REPORTING = 'reporting',
    COST_CENTERS = 'cost-centers',
    MONITORING = 'monitoring',
    HISTORY = 'history'
}

export default Navbar;
