import { useLocalStorageState } from '@frontend/common';
import { useLocation } from 'react-router-dom';

import { NavbarItemProps } from './navbar-item.component';

interface ViewProps {
    isActive: () => boolean;
    path: () => string;
    expanded: boolean;
    enabled: boolean;
}

const useNavbarItem = (props: NavbarItemProps): ViewProps => {
    const location = useLocation();

    const [navbarPinned] = useLocalStorageState('navbar-pinned', true);
    const [hovered] = useLocalStorageState('navbar-hovered', false);

    const enabled = props.enabled ?? false;

    const isActive = () => {
        if (props.parent) {
            return location.pathname.split('/')[2] === props.link.toLowerCase();
        } else return location.pathname.split('/')[1] === props.link.toLowerCase();
    };
    const path = () => {
        if (props.parent) {
            return `/${props.parent}/${props.link.toLowerCase()}`;
        } else {
            return `/${props.link.toLowerCase()}`;
        }
    };

    return {
        isActive,
        path,
        enabled,
        expanded: navbarPinned || hovered
    };
};

export default useNavbarItem;
