import { EntityType } from '@frontend/common';
import { EquipmentGroupClient } from '@frontend/group/api';
import { useResolver } from '@frontend/repository';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

declare global {
    interface WindowEventMap {
        'useEquipmentGroupMember:request': CustomEvent<string>;
    }
}

const REQUEST_EQUIPMENT_GROUP_MEMBER_EVENT = 'useEquipmentGroupMember:request';
const STORAGE_KEY = 'EQW_GRP_MBR_REQ';

export function equipmentGroupMemberRequest(memberId: string): string {
    window.dispatchEvent(new CustomEvent(REQUEST_EQUIPMENT_GROUP_MEMBER_EVENT, { detail: memberId }));
    return STORAGE_KEY + '_loading';
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function equipmentGroupMemberResolver(props: Props) {
    useResolver(STORAGE_KEY, REQUEST_EQUIPMENT_GROUP_MEMBER_EVENT, EntityType.MEMBER, (ids) => EquipmentGroupClient.resolveEquipmentGroupMembers(ids), props.dispatch);
    return <></>;
}
