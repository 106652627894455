import { AuthorizationWrapper, Permission } from '@frontend/authorization';
import { Link } from 'react-router-dom';

import { NavbarItems } from '../navbar/navbar.component';
import useNavbarItem from './navbar-item.controller';

export interface NavbarItemProps {
    text: string;
    link: NavbarItems;
    icon?: React.ReactNode;
    parent?: string;
    requiredPermissions?: Permission[];
    enabled?: boolean;
}

const NavbarItem = (props: NavbarItemProps) => {
    const viewProps = useNavbarItem(props);

    if (props.requiredPermissions) {
        return (
            <AuthorizationWrapper requiredPermissions={props.requiredPermissions}>
                <Link
                    id={'nav-' + props.link.toLowerCase()}
                    to={viewProps.path()}
                    className={viewProps.isActive() ? 'd-block w-100 bg-light rounded' : ''}>
                    <li
                        className={viewProps.enabled ? `nav-item` : 'nav-item cursor-disabled'}
                        key={props.text}>
                        <a className='nav-link'>
                            {props.icon && (
                                <div className='icon icon-sm d-flex align-items-center justify-content-center'>
                                    <i className={`text-${viewProps.enabled ? 'primary' : 'secondary'} fs-${viewProps.expanded ? '6' : '5'} opacity-10`}>
                                        {props.icon}
                                    </i>
                                </div>
                            )}

                            {viewProps.expanded && <span className='nav-link-text d-sm-inline d-none  m-2'>{props.text}</span>}
                        </a>
                    </li>
                </Link>
            </AuthorizationWrapper>
        );
    }
    return (
        <Link
            id={'nav-' + props.link.toLowerCase()}
            to={viewProps.path()}
            className={viewProps.isActive() ? 'd-block w-100 bg-light rounded' : ''}>
            <li
                className={viewProps.enabled ? `nav-item` : 'nav-item cursor-disabled'}
                key={props.text}>
                <a className='nav-link'>
                    <div className='icon icon-sm d-flex align-items-center justify-content-center'>
                        <i className={`text-${viewProps.enabled ? 'primary' : 'secondary'} fs-${viewProps.expanded ? '6' : '5'} opacity-10`}>{props.icon}</i>
                    </div>
                    {viewProps.expanded && <span className='nav-link-text d-sm-inline d-none  m-2'>{props.text}</span>}
                </a>
            </li>
        </Link>
    );
};

export default NavbarItem;
