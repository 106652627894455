import { AuthorizationProvider } from '@frontend/authorization';
import { ClassType, useLocalStorageState, useScripts } from '@frontend/common';
import { AsyncComponent, Profile } from '@frontend/elements';
import { Header } from '@frontend/header';
import { TranslationProvider } from '@frontend/lang';
import { AccountStoreProvider, DocumentStoreProvider } from '@frontend/repository';
import { lazy } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { VERSION } from '../version';
import { AdminRoutes } from './admin-routes';
import { Authentication } from './authentication/basic-authentication-wrapper/authentication.component';
import Footer from './footer/footer';
import Navbar from './navigation/navbar/navbar.component';
import { useAppDispatch } from './redux/store';
import { StatisticRoutes } from './statistics/statistic-routes';
import Unauthorized from './unauthorized/unauthorized';
import Resolver from './utils/resolver';

// #region PuDo
const PuDoTransactionOverview = lazy(() => import('./pudo/pudo-transaction-overview/pudo-transaction-overview.component'));
const PudoTransactionCreate = lazy(() => import('./pudo/pudo-transaction-create/pudo-transaction-create.component'));
const PudoTransactionDetail = lazy(() => import('./pudo/pudo-transaction-detail/pudo-transaction-detail.component'));
const PudoTransactionWizard = lazy(() => import('./pudo/pudo-transaction-wizard/pudo-transaction-wizard.component'));
// #endregion
// #region AddressBook
const AddressBookContactOverview = lazy(() => import('./address-book/contact-overview/contact-overview.component'));
const AddressBookContactCreate = lazy(() => import('./address-book/contact-create/contact-create.component'));
const AddressBookContactDetail = lazy(() => import('./address-book/contact-detail/contact-detail.component'));
const AddressBookContactUpdate = lazy(() => import('./address-book/contact-update/contact-update.component'));
// #endregion
// #region Warehouse
const StockOverviewReport = lazy(() => import('./stock/stock-overview-report/stock-overview-report'));
const MovementOverviewReport = lazy(() => import('./movement/movement-overview-report/movement-overview-report'));
// #endregion
// #region Change Password
const ChangePassword = lazy(() => import('./authentication/change-password/change-password.component'));
// #endregion

const envColor =
    process.env['NX_PUBLIC_BUILD_ENV'] === 'development'
        ? ClassType.INFO
        : process.env['NX_PUBLIC_BUILD_ENV'] === 'test'
          ? ClassType.SECONDARY
          : ClassType.PRIMARY;

export function App() {
    useScripts('https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js');
    useScripts('https://kit.fontawesome.com/42d5adcbca.js');
    const dispatch = useAppDispatch();
    const [navbarPinned] = useLocalStorageState('navbar-pinned', false);
    const [hovered] = useLocalStorageState('navbar-hovered', false);
    const StatisticRoute = StatisticRoutes();
    const AdminRoute = AdminRoutes();

    return (
        <TranslationProvider>
            <DocumentStoreProvider>
                <div className={`g-sidenav-show bg-gray-100 g-sidenav-${navbarPinned || hovered ? 'pinned' : 'hidden'}`}>
                    <div className={`min-height-300 bg-${envColor} position-absolute w-100 sticky-top top-bar`} />
                    <Router>
                        <Authentication>
                            <AccountStoreProvider>
                                <AuthorizationProvider dispatch={dispatch}>
                                    <Resolver>
                                        <Navbar />
                                        <main
                                            className='main-content position-relative border-radius-lg'
                                            id='app-content'>
                                            <Header
                                                dispatch={dispatch}
                                                color={envColor}
                                                version={VERSION}
                                            />
                                            <div className='container-fluid ps-4'>
                                                <AsyncComponent>
                                                    <Routes>
                                                        <Route path='pudo'>
                                                            <Route
                                                                index
                                                                element={
                                                                    <AsyncComponent>
                                                                        <PuDoTransactionOverview />
                                                                    </AsyncComponent>
                                                                }
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={
                                                                    <AsyncComponent>
                                                                        <PudoTransactionCreate />
                                                                    </AsyncComponent>
                                                                }
                                                            />
                                                            <Route path=':transactionId'>
                                                                <Route
                                                                    index
                                                                    path='detail'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <PudoTransactionDetail />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                        </Route>
                                                        <Route path='address-book'>
                                                            <Route
                                                                index
                                                                element={
                                                                    <AsyncComponent>
                                                                        <AddressBookContactOverview />
                                                                    </AsyncComponent>
                                                                }
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={
                                                                    <AsyncComponent>
                                                                        <AddressBookContactCreate />
                                                                    </AsyncComponent>
                                                                }
                                                            />
                                                            <Route path=':contactId'>
                                                                <Route
                                                                    index
                                                                    path='detail'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <AddressBookContactDetail />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    index
                                                                    path='update'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <AddressBookContactUpdate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                        </Route>
                                                        {AdminRoute}
                                                        {/**
                                                         * // #region Statistics
                                                         */}
                                                        {StatisticRoute}

                                                        {/** *
                                                      // #region Warehouse
                                                     * */}
                                                        <Route path='warehouse'>
                                                            <Route
                                                                path='inventory'
                                                                element={
                                                                    <AsyncComponent>
                                                                        <StockOverviewReport />
                                                                    </AsyncComponent>
                                                                }
                                                            />
                                                            <Route
                                                                path='history'
                                                                element={
                                                                    <AsyncComponent>
                                                                        <MovementOverviewReport />
                                                                    </AsyncComponent>
                                                                }
                                                            />
                                                        </Route>
                                                        {/** *
                                                         * // #region Change Password
                                                         * */}
                                                        <Route path='change-password'>
                                                            <Route
                                                                index
                                                                element={
                                                                    <AsyncComponent>
                                                                        <ChangePassword />
                                                                    </AsyncComponent>
                                                                }
                                                            />
                                                        </Route>
                                                        {/** *
                                                 // #region Profile
                                                 * */}
                                                        <Route path='profile'>
                                                            <Route
                                                                index
                                                                element={
                                                                    <AsyncComponent>
                                                                        <Profile dispatch={dispatch} />
                                                                    </AsyncComponent>
                                                                }
                                                            />
                                                        </Route>
                                                        <Route
                                                            path='unauthorized'
                                                            element={<Unauthorized />}
                                                        />
                                                        <Route
                                                            path='/'
                                                            element={<Navigate to='/pudo' />}
                                                        />
                                                        <Route
                                                            path='/*'
                                                            element={<Navigate to='/pudo' />}
                                                        />
                                                    </Routes>
                                                </AsyncComponent>
                                            </div>
                                            <Footer />
                                        </main>
                                    </Resolver>
                                </AuthorizationProvider>
                            </AccountStoreProvider>
                        </Authentication>
                    </Router>
                    <ToastContainer />
                </div>
            </DocumentStoreProvider>
        </TranslationProvider>
    );
}

export default App;
