import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { GlobalSearchProps } from './global-search.component';

interface ViewProps {
    value: string;
    changeValue: (value: string) => void;
    filterValue: string;
    modalRef: React.MutableRefObject<null>;
    showContent: boolean;
}

const useGlobalSearch = (props: GlobalSearchProps): ViewProps => {
    const location = useLocation();
    const modalRef = useRef(null);
    const [loc, changeLoc] = useState(location.pathname);
    const [value, changeValue] = useState<string>('');
    const [filterValue, changeFilterValue] = useState<string>('');

    useEffect(() => {
        if (loc === '') {
            changeLoc(location.pathname);
        } else if (location.pathname !== loc) {
            props.onClose();
        }
    }, [location]);

    useEffect(() => {
        const handleClickOutside = (event: { target: any }) => {
            if (modalRef.current && !(modalRef.current as any).contains(event.target)) {
                props.onClose();
            }
        };
        const handleKeydown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                props.onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside, true);
        document.addEventListener('keydown', handleKeydown, true);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
            document.removeEventListener('keydown', handleKeydown, true);
        };
    }, [modalRef, props.onClose]);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (value && value.length > 0) {
                changeFilterValue(value);
            }
        }, 600);
        return () => clearTimeout(timeOutId);
    }, [value]);

    return {
        value,
        changeValue,
        filterValue,
        modalRef,
        showContent: value.length > 0
    };
};

export default useGlobalSearch;
