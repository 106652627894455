import React from 'react';
import { FaHome } from 'react-icons/fa';

import { DetailItemProps } from '../detail-item/detail-item.component';

interface SubsectionProps {
    subsections: DetailItemProps[];
    onChange: (key: string) => void;
    activeSubsection?: string;
}

const Subsection = (props: SubsectionProps) => {
    return (
        <div className='nav-wrapper position-relative end-09'>
            <ul className='nav nav-pills nav-fill p-1'>
                {props.subsections.map((key) => {
                    const Icon = key.icon;
                    return (
                        <li
                            className='nav-item'
                            data-testid={`nav-item-${key.identifier}`}
                            onClick={() => props.onChange(key.identifier)}
                            key={key.identifier}>
                            <button className={`nav-link mb-0 px-0 py-1 ${props.activeSubsection === key.identifier.toLowerCase() ? 'active' : ''}`}>
                                <div className='d-flex justify-content-center align-content-center align-items-center gap-2'>
                                    {Icon ? <Icon /> : <FaHome />}
                                    {key.label ?? 'Basic'}
                                </div>
                            </button>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default Subsection;
