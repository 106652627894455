import { Permission } from '@frontend/authorization';
import { ClassType, EntityType, useAppSelector } from '@frontend/common';
import { HorizontalButtonGroup, HorizontalButtonGroupButton } from '@frontend/elements';
import { ICONS } from '@frontend/icons';
import { CommonMessage } from '@frontend/lang';
import { ListInfo, changeFilter, listStore } from '@frontend/repository';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React, { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';

import { MultiSelectOptions } from './object-list.component';

interface Props<T> {
    type: EntityType;
    create?: {
        label?: React.ReactNode;
        onCreate: () => void;
        requiredPermissions?: Permission[];
        overwriteCreateLabel?: React.ReactNode;
    };
    multiSelectOptions?: MultiSelectOptions<T>;
    customButtons?: HorizontalButtonGroupButton[];
    selectedObjects: T[];
    changeShowMultiDeleteModal: Dispatch<SetStateAction<boolean>>;
    changeShowImportModal: Dispatch<SetStateAction<boolean>>;
    changeShowExportModal: Dispatch<SetStateAction<boolean>>;
    includeDeleted?: {
        requiredPermissions: Permission[];
    };
    dispatch?: ThunkDispatch<any, any, Action>;
    listId: string;
}

export const ObjectListActionButtons = <T,>(props: Props<T>) => {
    const store = useAppSelector(useSelector, listStore);
    const list: ListInfo | undefined = store[props.listId];
    return (
        <HorizontalButtonGroup
            direction='left'
            buttons={[
                ...(props.create
                    ? [
                          {
                              hide: false,
                              onClick: props.create.onCreate,
                              icon: ICONS.BUTTON.CREATE,
                              type: ClassType.PRIMARY,
                              id: 'ObjectList.CreateButton',
                              text: props.create.overwriteCreateLabel
                                  ? props.create.overwriteCreateLabel
                                  : CommonMessage.FORMS.CREATE_OBJECT(props.create.label ? props.create.label : props.type.replace(/_/g, ' ')),
                              requiredPermissions: props.create.requiredPermissions,
                              className: 'm-0 btn btn-primary'
                          }
                      ]
                    : []),
                ...(props.multiSelectOptions?.delete
                    ? [
                          {
                              hide: !props.multiSelectOptions?.delete?.enabled(props.selectedObjects),
                              onClick: () => props.changeShowMultiDeleteModal(true),
                              icon: ICONS.BUTTON.DELETE,
                              type: ClassType.DANGER,
                              id: 'ObjectList.DeleteButton',
                              text: CommonMessage.BUTTONS.DELETE,
                              requiredPermissions: props.multiSelectOptions.delete.requiredPermissions
                          }
                      ]
                    : []),
                ...(props.multiSelectOptions?.import
                    ? [
                          {
                              onClick: () => props.changeShowImportModal(true),
                              icon: ICONS.BUTTON.IMPORT,
                              type: ClassType.LIGHT,
                              id: 'ObjectList.ImportButton',
                              text: CommonMessage.BUTTONS.IMPORT,
                              requiredPermissions: props.multiSelectOptions.import.requiredPermissions
                          }
                      ]
                    : []),
                ...(props.multiSelectOptions?.export
                    ? [
                          {
                              onClick: () => props.changeShowExportModal(true),
                              icon: ICONS.BUTTON.EXPORT,
                              type: ClassType.LIGHT,
                              id: 'ObjectList.ImportButton',
                              text: CommonMessage.BUTTONS.EXPORT,
                              requiredPermissions: props.multiSelectOptions.export.requiredPermissions
                          }
                      ]
                    : []),
                ...(props.multiSelectOptions?.custom
                    ? props.multiSelectOptions.custom.map((button) => ({
                          hide: button.enabled ? !button.enabled(props.selectedObjects) : false,
                          disabled: button.enabled ? !button.enabled(props.selectedObjects) : button.disabled,
                          requiredPermissions: button.requiredPermissions,
                          ...button
                      }))
                    : []),
                ...(props.customButtons ? props.customButtons : []),
                ...(props.includeDeleted
                    ? [
                          {
                              hide: false,
                              onClick: () =>
                                  props.dispatch &&
                                  props.dispatch(
                                      changeFilter({
                                          listId: props.listId,
                                          key: 'include_deleted',
                                          value: list.filter?.['include_deleted'] === 'true' ? null : 'true'
                                      })
                                  ),
                              type: list?.filter?.['include_deleted'] === 'true' ? ClassType.SUCCESS : ClassType.LIGHT,
                              id: 'ObjectList.IncludeDeletedButton',
                              text: CommonMessage.BUTTONS.INCLUDE_DELETED,
                              requiredPermissions: props.includeDeleted.requiredPermissions
                          }
                      ]
                    : [])
            ]}
        />
    );
};
