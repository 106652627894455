import { attributeValueRequest } from '@frontend/attribute/events';
import { AttributeValue } from '@frontend/attribute/types';
import { EntityType } from '@frontend/common';
import { ObjectBadge, ObjectBadgeProps } from '@frontend/rendering/badge';
import React from 'react';

export const AttributeValueBadge = (props: ObjectBadgeProps<AttributeValue>) => {
    return (
        <ObjectBadge<AttributeValue>
            id={props.id}
            type={EntityType.ATTRIBUTE_VALUE}
            mapDisplayName={(o: AttributeValue) => o.value}
            resolve={attributeValueRequest}
            key={props.id}
            {...props}
        />
    );
};
