import { DefaultSelectProps, SelectInput } from '@frontend/basic-forms';
import { EntityType } from '@frontend/common';
import { CommonMessage } from '@frontend/lang';
import React from 'react';
import usePredefinedRoleSelect from './predefined-role-select.controller';

const ID = 'predefined-role-select';

export interface PredefinedRoleSelectProps extends DefaultSelectProps {
    item?: EntityType;
}

export const PredefinedRoleSelect = (props: PredefinedRoleSelectProps) => {
    const viewProps = usePredefinedRoleSelect(props);

    return (
        <SelectInput
            {...props}
            id={props.id || ID}
            isClearable={props.isClearable}
            className={props.className}
            options={viewProps.names || []}
            value={viewProps.name}
            label={props.label || CommonMessage.OBJECTS.COMMON_FIELDS.NAME}
            submitted={props.submitted}
            required={props.required}
            onChange={(value) => viewProps.changeName((value as { label: string; value: string })?.value)}
            isDisabled={props.item == null && viewProps.names === null}
        />
    );
};

export default PredefinedRoleSelect;
