import { Logger } from '@frontend/Logger';
import { GroupMember } from '@frontend/group/types';
import { EventListener } from '@frontend/pub-sub';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

enum MessageTypes {
    UPDATED = 'group_member_updated',
    DELETED = 'group_member_deleted',
    CREATED = 'group_member_created',
    MULTI_ADDED = 'group_member_multi_created',
    MULTI_DELETE = 'group_member_multi_deleted'
}

export class GroupMemberEventListener extends EventListener<GroupMember> {
    
    private static instance: GroupMemberEventListener | null = null;
    private constructor(dispatch: ThunkDispatch<any, any, Action>) {
        super('group', dispatch);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>): GroupMemberEventListener {
        if (this.instance == null) {
            this.instance = new GroupMemberEventListener(dispatch);
        }
        return this.instance;
    }

    getInstance(dispatch: ThunkDispatch<any, any, Action>): EventListener<GroupMember> {
        return GroupMemberEventListener.getInstance(dispatch);
    }

    protected onUpdate(payload: { message: MessageTypes; data: GroupMember; changes?: any }) {
        Logger.log('Updating group member in store', { group_member: payload.data.id }, payload.data);
    }

    protected onDelete(payload: { message: MessageTypes; data: GroupMember }) {
        Logger.log('Removing group member from store', { group_member: payload.data.id }, payload.data);
    }

    protected onCreate(payload: { message: MessageTypes; data: GroupMember; changes?: any }) {
        Logger.log('Adding group member to store', { group_member: payload.data.id }, payload.data);
    }
}