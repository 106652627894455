
import { EventListener } from '@frontend/pub-sub';
import { Variant } from '@frontend/variant/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

export class VariantEventListener extends EventListener<Variant> {
    
    private static instance: VariantEventListener | null = null;
    private constructor(dispatch: ThunkDispatch<any, any, Action>) {
        super('variant', dispatch);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>): VariantEventListener {
        if (this.instance == null) {
            this.instance = new VariantEventListener(dispatch);
        }
        return this.instance;
    }

    getInstance(dispatch: ThunkDispatch<any, any, Action>): EventListener<Variant> {
        return VariantEventListener.getInstance(dispatch);
    }

    protected onUpdate() {}

    protected onDelete() {}

    protected onCreate() {}
}
