import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { Playlist, PlaylistListResponse, CreatePlaylist, UpdatePlaylist } from '@frontend/playlist/types';

const endpoint = '/playlist-api/v1';
export class PlaylistClient extends APIClient {
    public static async fetchPlaylists(queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<PlaylistListResponse> {
        return await this.apiPaginated<PlaylistListResponse, DefaultQueryParams>(`${endpoint}/playlists`, queryParams);
    }

    public static async resolvePlaylists(ids: string[]): Promise<PlaylistListResponse> {
        const response = await this.post(`${endpoint}/playlists-resolve`, { ids: ids });
        return await this.handleResponse<PlaylistListResponse>(response);
    }

    public static async deletePlaylists(ids: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/playlists-delete`, { ids: ids });
        return await this.handleVoidResponse(response);
    }

    public static async fetchAccountPlaylists(accountId: string, queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<PlaylistListResponse> {
        return await this.apiPaginated<PlaylistListResponse, DefaultQueryParams>(`${endpoint}/accounts/${accountId}/playlists`, queryParams);
    }

    public static async postPlaylist(accountId: string, body: CreatePlaylist): Promise<Playlist> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/playlists`, body);
        return await this.handleResponse<Playlist>(response);
    }

    public static async fetchAccountPlaylist(accountId: string, playlistId: string): Promise<Playlist> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/playlists/${playlistId}`);
        return await this.handleResponse<Playlist>(response);
    }

    public static async patchPlaylist(accountId: string, playlistId: string, body: UpdatePlaylist): Promise<Playlist> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/playlists/${playlistId}`, body);
        return await this.handleResponse<Playlist>(response);
    }

    public static async deletePlaylist(accountId: string, playlistId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/playlists/${playlistId}`);
        return await this.handleVoidResponse(response);
    }

    public static async postIoTSeed(accountId: string, iotId: string, body: { account_id: string }): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots/${iotId}/seed`, body);
        return this.handleResponse(response);
    }
}
