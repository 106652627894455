import { ApiQueryParams } from '@frontend/api-utils';
import { useAppSelector } from '@frontend/common';
import { ListInfo, changeFilter, changeFilters, listStore } from '@frontend/repository';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SetURLSearchParams, useSearchParams } from 'react-router-dom';

import { Props } from './header-filter';

interface ViewProps {
    showModal: boolean;
    changeShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    setSearchParams: SetURLSearchParams;
    filters?: ApiQueryParams<string | number>;
}

const useHeaderFilter = <T extends object & { id: string }>(props: Props<T>): ViewProps => {
    const store = useAppSelector(useSelector, listStore);
    const list: ListInfo | undefined = store[props.listId];
    const [showModal, changeShowModal] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [defaultValuesInitialized, setDefaultValuesInitialized] = useState<boolean>(false);
    useEffect(() => {
        if (!props.filter || !props.filter.keys) return;
        const params: { [key: string]: any } = { ...list?.filter };
        props.filter.keys.forEach((key) => {
            const override = props.filter?.queryParamKeyOverwrite?.get(key as string);
            if (typeof key === 'number') return;
            const found = searchParams.get(key);
            if (!found) return;
            params[override ?? key] = found;
        });
        if (Object.keys(params).length > 0) {
            props.dispatch(
                changeFilters({
                    listId: props.listId,
                    filters: Object.entries({ ...params }).map(([key, value]) => {
                        return { key, value };
                    })
                })
            );
        }
    }, []);

    useEffect(() => {
        if (!props.filter || !props.filter.defaultValues || !list || !list.count || defaultValuesInitialized) return;
        props.filter.defaultValues.forEach((value, key) => {
            props.dispatch(
                changeFilter({
                    listId: props.listId,
                    key: key,
                    value: value
                })
            );
        });
        setDefaultValuesInitialized(true);
    }, [list]);

    return {
        changeShowModal,
        showModal,
        setSearchParams,
        filters: list?.filter
    };
};

export default useHeaderFilter;
