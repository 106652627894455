import { AttributeLinkingWithName } from '@frontend/attribute/types';
import { ObjectBadgeProps } from '@frontend/rendering/badge';
import React from 'react';

import useVariantAttributesBadge from './variant-attributes-badge.controller';

export const VariantAttributesBadge = (props: ObjectBadgeProps<AttributeLinkingWithName>) => {
    const viewProps = useVariantAttributesBadge(props);

    if (viewProps.isLoading) {
        return (
            <span className='badge badge-secondary disabled'>
                <span className='placeholder-glow'>
                    <span className='placeholder'>Loading</span>
                </span>
            </span>
        );
    }

    return (
        <span className='badge badge-secondary disabled' onClick={(e) => e.stopPropagation()}>
            {viewProps.attributes.length === 0 ? (
                <span>None</span>
            ) : (
                viewProps.attributes.map((attr) => (
                    <span
                        key={attr.id}
                        className='me-1'>
                        {attr.attribute_name}: {attr.choice}
                    </span>
                ))
            )}
        </span>
    );
};
