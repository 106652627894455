import { AccountClient } from '@frontend/account/api';
import { Account, AccountQueryParams } from '@frontend/account/types';
import { ApiQueryParams } from '@frontend/api-utils';
import { ObjectSelectInputV2 } from '@frontend/form';
import React, { useState } from 'react';

const ID = 'account-select';
export interface AccountSelectProps {
    id?: string;
    label?: React.ReactNode;
    required?: boolean;
    submitted: boolean;
    onChange?: (newValue: string | null) => void;
    onChangeObject?: (newValue: Account | null) => void;
    value?: string | null;
    className?: string;
    disabled?: boolean;
    isClearable?: boolean;
    allowURLChange?: boolean;
    createEnabled?: boolean;
    queryParams?: ApiQueryParams<AccountQueryParams>;
}

export const AccountSelect = (props: AccountSelectProps) => {
    const [v, cV] = useState<Account | null>(null);
    return (
        <ObjectSelectInputV2<Account, AccountQueryParams>
            id={`${ID}${props.id && '-' + props.id}`}
            label={props.label || 'Account'}
            className={props.className}
            onChange={(v) => {
                props.onChange && props.onChange(v?.id || null);
                props.onChangeObject && props.onChangeObject(v);
                cV(v);
            }}
            required={props.required}
            queryParams={props.queryParams}
            value={v}
            idValue={props.value || v?.id}
            fetch={(props) => AccountClient.fetchAccounts(props)}
            itemLabel={(account) => account.name}
            useQueryParam={
                props.allowURLChange
                    ? {
                        param: 'account',
                        fetch: (pathParam) => AccountClient.fetchAccount(pathParam)
                    }
                    : undefined
            }
            disabled={props.disabled}
            isClearable={props.isClearable}
        />
    );
};
