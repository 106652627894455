import { ApiQueryParams } from '@frontend/api-utils';
import { EntityType } from '@frontend/common';
import { AsyncComponent } from '@frontend/elements';
import React from 'react';

const AccountSelect = React.lazy(() => import('@frontend/account/select').then((module) => ({ default: module.AccountSelect })));
const WorkflowSelect = React.lazy(() => import('@frontend/workflow/select').then((module) => ({ default: module.WorkflowSelect })));
const IoTSelect = React.lazy(() => import('@frontend/iot/select').then((module) => ({ default: module.IoTSelect })));
const SpotSelect = React.lazy(() => import('@frontend/spot/select').then((module) => ({ default: module.SpotSelect })));
const ModuleSelect = React.lazy(() => import('@frontend/module/select').then((module) => ({ default: module.ModuleSelect })));
const ModuleTemplateSelect = React.lazy(() => import('@frontend/module/select').then((module) => ({ default: module.ModuleTemplateSelect })));
const DeviceSelect = React.lazy(() => import('@frontend/device/select').then((module) => ({ default: module.DeviceSelect })));
const PlaylistSelect = React.lazy(() => import('@frontend/playlist/select').then((module) => ({ default: module.PlaylistSelect })));
const ProblemSelect = React.lazy(() => import('@frontend/problem/select').then((module) => ({ default: module.ProblemSelect })));
const UserSelect = React.lazy(() => import('@frontend/user/select').then((module) => ({ default: module.UserSelect })));
const AttributeSelect = React.lazy(() => import('@frontend/attribute/select').then((module) => ({ default: module.AttributeSelect })));
const GroupSelect = React.lazy(() => import('@frontend/group/select').then((module) => ({ default: module.GroupSelect })));
const BudgetSelect = React.lazy(() => import('@frontend/budget/select').then((module) => ({ default: module.BudgetSelect })));
const SlotSelect = React.lazy(() => import('@frontend/slot/select').then((module) => ({ default: module.SlotSelect })));
const SlotTemplateSelect = React.lazy(() => import('@frontend/slot/select').then((module) => ({ default: module.SlotTemplateSelect })));
const QuestionSelect = React.lazy(() => import('@frontend/question/select').then((module) => ({ default: module.QuestionSelect })));
const ContactSelect = React.lazy(() => import('@frontend/contact/select').then((module) => ({ default: module.ContactSelect })));
const ProductSelect = React.lazy(() => import('@frontend/product/select').then((module) => ({ default: module.ProductSelect })));
const UserInterfaceSelect = React.lazy(() => import('@frontend/user-interface/select').then((module) => ({ default: module.UserInterfaceSelect })));
const TransactionSelect = React.lazy(() => import('@frontend/transaction/select').then((module) => ({ default: module.TransactionSelect })));
const EquipmentSelect = React.lazy(() => import('@frontend/equipment/select').then((module) => ({ default: module.EquipmentSelect })));
const AttributeValueSelect = React.lazy(() => import('@frontend/attribute/select').then((module) => ({ default: module.AttributeValueSelect })));
const BrandingSelect = React.lazy(() => import('@frontend/branding/select').then((module) => ({ default: module.BrandingSelect })));

interface Props<S> {
    id: string;
    objectKey: keyof S | string;
    submitted: boolean;
    required?: boolean;
    disabled?: boolean;
    useConditionedStyling?: boolean;
    isClearable?: boolean;
    label?: React.ReactNode;
    value?: string | null;
    onChange?: (value: string | null) => void;
    fieldProps?: Map<keyof S, any>;
    queryParams?: ApiQueryParams<string | number>;
    className?: string;
    allowURLChange?: boolean;
    defaultValue?: string | null;
}

const fallback = (
    <span className='placeholder-glow'>
        <span className='placeholder'>loading</span>
    </span>
);
export const ObjectSelectMapper = <S,>(props: Props<S>) => {
    switch (props.objectKey) {
        case 'account_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <AccountSelect
                        {...props}
                        {...props.fieldProps}
                    />
                </AsyncComponent>
            );
        case 'workflow_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <WorkflowSelect
                        {...props}
                        {...props.fieldProps}
                    />
                </AsyncComponent>
            );
        case 'question_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <QuestionSelect
                        {...props}
                        {...props.fieldProps}
                    />
                </AsyncComponent>
            );
        case 'product_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <ProductSelect
                        {...props}
                        {...props.fieldProps}
                    />
                </AsyncComponent>
            );
        case 'product_category_id':
        case 'default_product_category_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <GroupSelect
                        {...props}
                        {...props.fieldProps}
                        type={EntityType.PRODUCT_CATEGORY}
                    />
                </AsyncComponent>
            );
        case 'product_group_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <GroupSelect
                        {...props}
                        {...props.fieldProps}
                        type={EntityType.PRODUCT_GROUP}
                    />
                </AsyncComponent>
            );
        case 'equipment_group_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <GroupSelect
                        {...props}
                        {...props.fieldProps}
                        type={EntityType.EQUIPMENT_GROUP}
                    />
                </AsyncComponent>
            );
        case 'questionnaire_id':
        case 'consume_questionnaire_id':
        case 'return_questionnaire_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <GroupSelect
                        {...props}
                        {...props.fieldProps}
                        type={EntityType.QUESTIONNAIRE}
                    />
                </AsyncComponent>
            );
        case 'contact_group_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <GroupSelect
                        {...props}
                        {...props.fieldProps}
                        type={EntityType.CONTACT_GROUP}
                    />
                </AsyncComponent>
            );
        case 'user_group_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <GroupSelect
                        {...props}
                        {...props.fieldProps}
                        type={EntityType.USER_GROUP}
                    />
                </AsyncComponent>
            );
        case 'spot_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <SpotSelect
                        {...props}
                        {...props.fieldProps}
                    />
                </AsyncComponent>
            );
        case 'module_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <ModuleSelect
                        {...props}
                        {...props.fieldProps}
                    />
                </AsyncComponent>
            );
        case 'module_template_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <ModuleTemplateSelect
                        {...props}
                        {...props.fieldProps}
                    />
                </AsyncComponent>
            );
        case 'slot_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <SlotSelect
                        {...props}
                        {...props.fieldProps}
                    />
                </AsyncComponent>
            );
        case 'slot_template_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <SlotTemplateSelect
                        {...props}
                        {...props.fieldProps}
                    />
                </AsyncComponent>
            );
        case 'iot_id':
        case 'twin_iot_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <IoTSelect
                        {...props}
                        {...props.fieldProps}
                    />
                </AsyncComponent>
            );
        case 'device_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <DeviceSelect
                        {...props}
                        {...props.fieldProps}
                    />
                </AsyncComponent>
            );

        case 'playlist_id':
        case 'default_playlist_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <PlaylistSelect
                        {...props}
                        {...props.fieldProps}
                    />
                </AsyncComponent>
            );
        case 'user_interface_id':
        case 'next_user_interface_id':
        case 'default_user_interface_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <UserInterfaceSelect
                        {...props}
                        {...props.fieldProps}
                    />
                </AsyncComponent>
            );
        case 'problem_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <ProblemSelect
                        {...props}
                        {...props.fieldProps}
                    />
                </AsyncComponent>
            );
        case 'user_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <UserSelect
                        {...props}
                        {...props.fieldProps}
                    />
                </AsyncComponent>
            );
        case 'attribute_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <AttributeSelect
                        {...props}
                        {...props.fieldProps}
                    />
                </AsyncComponent>
            );
        case 'budget_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <BudgetSelect
                        {...props}
                        {...props.fieldProps}
                    />
                </AsyncComponent>
            );
        case 'contact_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <ContactSelect
                        {...props}
                        {...props.fieldProps}
                    />
                </AsyncComponent>
            );
        case 'transaction_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <TransactionSelect
                        {...props}
                        {...props.fieldProps}
                    />
                </AsyncComponent>
            );
        case 'equipment_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <EquipmentSelect
                        {...props}
                        {...props.fieldProps}
                    />
                </AsyncComponent>
            );
        case 'value_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <AttributeValueSelect
                        {...props}
                        {...props.fieldProps}
                    />
                </AsyncComponent>
            );
        case 'branding_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <BrandingSelect
                        {...props}
                        {...props.fieldProps}
                    />
                </AsyncComponent>
            );
    }
    return <></>;
};
