import { EntityType } from '@frontend/common';
import { useResolver } from '@frontend/repository';
import { CustomRoleClient } from '@frontend/role/api';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

declare global {
    interface WindowEventMap {
        'useCustomRole:request': CustomEvent<string>;
    }
}

const REQUEST_CUSTOM_ROLE_EVENT = 'useCustomRole:request';
const STORAGE_KEY = 'CSTMRL_REQ';

export function customRoleRequest(customRoleId: string): string {
    window.dispatchEvent(new CustomEvent(REQUEST_CUSTOM_ROLE_EVENT, { detail: customRoleId }));
    return STORAGE_KEY + '_loading';
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function CustomRoleResolver(props: Props) {
    useResolver(STORAGE_KEY, REQUEST_CUSTOM_ROLE_EVENT, EntityType.ROLE, (ids) => CustomRoleClient.resolveCustomRoles(ids), props.dispatch);
    return <></>;
}
