import { APIClient, ApiQueryParams } from "@frontend/api-utils";
import { EntityType } from "@frontend/common";
import { CreateGroup, CreateGroupMember, Group, GroupListResponse, GroupMember, GroupMemberListResponse, GroupMemberQueryParams, GroupQueryParams, UpdateGroup, UpdateGroupMember } from "@frontend/group/types";

const endpoint = '/group-api/v1';

export class QuestionnaireClient extends APIClient {
    public static async fetchQuestionnaires(queryParams?: ApiQueryParams<GroupQueryParams>): Promise<GroupListResponse> {
        return await this.apiPaginated<GroupListResponse, GroupQueryParams>(`${endpoint}/questionnaires`, queryParams);
    }

    public static async resolveQuestionnaires(groupIds: string[]): Promise<GroupListResponse> {
        const response = await this.post(`${endpoint}/questionnaires-resolve`, { ids: groupIds });
        return await this.handleResponse<GroupListResponse>(response);
    }

    public static async deleteQuestionnaires(groupIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/questionnaires-delete`, { ids: groupIds });
        return await this.handleVoidResponse(response);
    }

    public static async postQuestionnaire(accountId: string, group: CreateGroup): Promise<Group> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/questionnaires`, group);
        return await this.handleResponse<Group>(response);
    }

    public static async fetchQuestionnaire(accountId: string, groupId: string): Promise<Group> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/questionnaires/${groupId}`);
        return await this.handleResponse<Group>(response);
    }

    public static async patchQuestionnaire(accountId: string, groupId: string, group: UpdateGroup): Promise<Group> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/questionnaires/${groupId}`, group);
        return await this.handleResponse<Group>(response);
    }

    public static async deleteQuestionnaire(accountId: string, groupId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/questionnaires/${groupId}`);
        return await this.handleVoidResponse(response);
    }

    public static async fetchGroupsNotInGroup(accountId: string, groupId: string): Promise<GroupListResponse> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/questionnaires/${groupId}/groups`);
        return await this.handleResponse<GroupListResponse>(response);
    }

    //Group members
    public static async fetchQuestionnaireMembers(queryParams?: ApiQueryParams<GroupMemberQueryParams>): Promise<GroupMemberListResponse> {
        return await this.apiPaginated<GroupMemberListResponse, GroupMemberQueryParams>(`${endpoint}/questionnaire-members`, queryParams);
    }

    public static async resolveQuestionnaireMembers(memberIds: string[]): Promise<GroupMemberListResponse> {
        const response = await this.post(`${endpoint}/questionnaire-members-resolve`, { ids: memberIds });
        return await this.handleResponse<GroupMemberListResponse>(response);
    }

    public static async deleteQuestionnaireMembers(memberIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/questionnaire-members-delete`, { ids: memberIds });
        return await this.handleVoidResponse(response);
    }

    public static async postQuestionnaireMember(accountId: string, groupId: string, groupMember: CreateGroupMember): Promise<GroupMember> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/questionnaires/${groupId}/members`, groupMember);
        return await this.handleResponse<GroupMember>(response);
    }

    public static async postQuestionnaireMemberList(accountId: string, groupId: string, entity_type: EntityType, memberIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/questionnaires/${groupId}/${entity_type}s/members-list`, { ids: memberIds });
        return await this.handleVoidResponse(response);
    }

    public static async fetchQuestionnaireMember(accountId: string, groupId: string, memberId: string): Promise<GroupMember> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/questionnaires/${groupId}/members/${memberId}`);
        return await this.handleResponse<GroupMember>(response);
    }

    public static async patchQuestionnaireMember(accountId: string, groupId: string, memberId: string, body: UpdateGroupMember): Promise<GroupMember> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/questionnaires/${groupId}/members/${memberId}`, body);
        return await this.handleResponse<GroupMember>(response);
    }

    public static async deleteQuestionnaireMember(accountId: string, groupId: string, memberId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/questionnaires/${groupId}/members/${memberId}`);
        return await this.handleVoidResponse(response);
    }
}