import { Logger } from '@frontend/Logger';
import {
    CheckboxInput,
    ColorInput,
    CountryInput,
    DateInput,
    DimensionInput,
    EmailInput,
    LocationInput,
    NumberInput,
    PhoneInput,
    TextInput
} from '@frontend/basic-forms';
import { EntityType } from '@frontend/common';
import { AsyncComponent } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import { EnumSelectList, EnumSelectListV2, ObjectSelectMapper } from '@frontend/rendering/select';
import { CustomRoleTemplateSelect } from '@frontend/role/select';
import React from 'react';

import { defaultObjectFormRowClassName } from '../form/form.component';
import useObjectForm from './object-form.controller';

const ID = 'object-form';
const fallback = (
    <span className='placeholder-glow'>
        <span className='placeholder'>loading</span>
    </span>
);

export interface ObjectFormProps<T extends object, S extends object> {
    type: EntityType;
    label?: React.ReactNode;
    object?: T;
    value: S;
    /**
     * @description Can be used for keys in the object mapper that dont exist on the object itself.
     */
    objectSelectKeyOverwrite?: Map<keyof S, string>;
    /**
     * @description Keys will be used to populate the labels. This variable allows you to overwrite it.
     */
    keyOverwrite?: Map<keyof S, React.ReactNode>;
    fieldOverwrite?: Map<keyof S, React.ReactNode>;
    fieldProps?: Map<keyof S, any>;
    /**
     * @description will the keys provided required. Overrules the hidden array.
     * Keys provided here will be merged with the show array. So no need to provide them there again.
     */
    required?: (keyof S)[];
    /**
     * @description will hide the keys provided. If not provided, all keys will be shown.
     */
    hidden?: (keyof S)[];
    /**
     * @description will only show the keys provided in the same order as provided.
     * Is required to make sure undefined values are also rendered.
     * Keys provided in the hidden array will overrule keys provided here.
     *
     * Keys provided in the reqiured prop are merged and therefore do not need to be provided here.
     */
    show?: (keyof S)[];
    expectedType?: Map<keyof S, 'undefined' | 'object' | 'boolean' | 'number' | 'string'>;
    bodyClassname?: string;
    rowClassName?: string;
    submitDisabled?: boolean;
    onSubmit?: (value: S) => Promise<T> | Promise<void>;
    onSubmitSuccess?: (value: T) => void;
    onVoidSuccess?: (value: void) => void;
    onCancel?: (e: any) => void;
    onChange?: (value: S) => void;
    children?: React.ReactNode;
    append?: React.ReactNode;
    /**
     * @description can be used to overwrite a key of the object if the key does not match the correct key from the EnumObjectSelectMapper
     * eg. object.type will probable be an enum. but there is no one enum for type, since type is used in multiple objects
     * @example enumOverwrite={new Map([['type', 'entity_type']])}
     */
    enumOverwrite?: Map<keyof S, keyof typeof EnumSelectList | keyof typeof EnumSelectListV2>;
    successMessageOverwrite?: React.ReactNode;
    headerOverwrite?: React.ReactNode;
}

/**
 * @todo enums are not yet supported (they can however be passed as children)
 * @todo add support for Entity by type select
 * @todo add support for DateInput/DateTimeInput
 * @deprecated
 * @param props
 * @returns
 */
export const ObjectForm = <T extends object, S extends object>(props: ObjectFormProps<T, S>) => {
    const viewProps = useObjectForm<T, S>(props);
    const fields = convert<S>({
        object: viewProps.formValue,
        onChange: viewProps.onChange,
        objectSelectKeyOverwrite: props.objectSelectKeyOverwrite,
        keyOverwrite: props.keyOverwrite,
        valueOverwrite: props.fieldOverwrite,
        required: props.required,
        hidden: props.hidden,
        show: props.show,
        expectedType: props.expectedType,
        fieldProps: props.fieldProps,
        enumOverwrite: props.enumOverwrite,
        className: props.rowClassName
    });

    const header = props.headerOverwrite ? props.headerOverwrite : props.type.replace(/_/g, ' ');

    return (
        <form
            id={ID}
            className={props.bodyClassname ? props.bodyClassname : 'card w-100 w-lg-50'}
            onSubmit={props.onSubmit ? viewProps.submit : undefined}>
            <div className='card-header'>
                <h4>{props.label ? props.label : props.object ? CommonMessage.FORMS.UPDATE_OBJECT(header) : CommonMessage.FORMS.CREATE_OBJECT(header)}</h4>
            </div>
            <div className='card-body d-flex flex-wrap flex-column justify-content-between w-100'>
                {props.children}

                <div className='w-100'>{fields.map(([, value]) => value)}</div>

                {props.append}
            </div>
            {props.onSubmit && (
                <div className='card-footer d-flex justify-content-between'>
                    {props.onCancel ? (
                        <button
                            type='button'
                            onClick={props.onCancel}
                            className='btn btn-secondary'>
                            <span>{CommonMessage.BUTTONS.CANCEL}</span>
                        </button>
                    ) : (
                        <span></span>
                    )}
                    <button
                        type='submit'
                        className='btn btn-success'
                        disabled={props.submitDisabled}>
                        <span>{CommonMessage.BUTTONS.SUBMIT}</span>
                    </button>
                </div>
            )}
        </form>
    );
};

export default ObjectForm;

/**
 * if object is undefined the form is CREATE otherwise its UPDATE
 * how to do cross dependencies like account filters
 */

interface ConvertProps<S extends object> {
    object: S;
    onChange: (value: S) => void;
    objectSelectKeyOverwrite?: Map<keyof S, string>;
    keyOverwrite?: Map<keyof S, React.ReactNode>;
    valueOverwrite?: Map<keyof S, React.ReactNode>;
    required?: (keyof S)[];
    hidden?: (keyof S)[];
    show?: (keyof S)[];
    expectedType?: Map<keyof S, 'undefined' | 'object' | 'boolean' | 'number' | 'string'>;
    fieldProps?: Map<keyof S, any>;
    enumOverwrite?: Map<keyof S, string>;
    className?: string;
}

function convert<S extends object>(props: ConvertProps<S>): [string, React.ReactNode][] {
    const rowClass = props.className ? props.className : defaultObjectFormRowClassName;
    const result: [string, React.ReactNode][] = [];
    const show = [...new Set([...(props.show ?? []), ...(props.required ?? [])])];
    for (const key of show) {
        const value = props.object[key];
        let index = null;
        let finalKey = null;
        if (props.hidden && props.hidden.includes(key as keyof S) && !props.required?.includes(key as keyof S)) {
            continue;
        }
        if (!show.includes(key as keyof S)) {
            continue;
        } else {
            index = show.indexOf(key as keyof S);
        }
        const translatedKey = props.keyOverwrite?.get(key as keyof S);
        if (translatedKey) {
            //TODO: Maybe also do some automatic translations for common fields.
            finalKey = translatedKey;
        } else if (Object.keys(translationKeys).includes((key as string).slice(-5))) {
            // slice -5 because translation fields will always end with their locale
            const found = Object.entries(translationKeys).find((k) => (key as string).includes(k[0]));
            finalKey = (key as string)[0].toUpperCase() + (key as string).slice(1).replace(found![0], `(${found![1]})`);
            finalKey = finalKey.split('_').join(' ');
        } else {
            finalKey = (key as string)[0].toUpperCase() + (key as string).slice(1);
            finalKey = finalKey
                .split('_')
                .filter((part) => part !== 'id')
                .join(' ')
                .replace('timestamp', '');
        }

        let finalValue: React.ReactNode = <></>;
        const fieldProps = props.fieldProps && props.fieldProps.get(key) ? props.fieldProps.get(key) : {};

        if (props.valueOverwrite && props.valueOverwrite.has(key as keyof S)) {
            finalValue = props.valueOverwrite.get(key as keyof S);
        } else if (
            (typeof value === 'string' || value === null || value === undefined) &&
            !commonKeys.includes(key as string) &&
            (!props.expectedType || !props.expectedType.get(key as keyof S) || !['number', 'boolean'].includes(props.expectedType.get(key as keyof S)!))
        ) {
            if (
                (typeof value === 'string' && isUUID(value)) ||
                ((key as string).includes('id') && (key as string) !== 'identifier' && (key as string) !== 'unique_identifier')
            ) {
                finalValue = (
                    <ObjectSelectMapper
                        className={rowClass}
                        objectKey={props.objectSelectKeyOverwrite && props.objectSelectKeyOverwrite.has(key) ? props.objectSelectKeyOverwrite.get(key) : key}
                        id={ID + '-' + (key as string)}
                        label={finalKey}
                        value={props.object[key as keyof S] as string}
                        onChange={(value) => props.onChange({ ...props.object, [key]: value })}
                        required={props.required?.includes(key as keyof S)}
                        submitted={false}
                        {...fieldProps}
                    />
                );
            } else if (
                (props.enumOverwrite && props.enumOverwrite.has(key) && EnumSelectList[props.enumOverwrite.get(key) as keyof typeof EnumSelectList]) ||
                EnumSelectList[key as keyof typeof EnumSelectList]
            ) {
                const EnumInput =
                    props.enumOverwrite && props.enumOverwrite.has(key)
                        ? EnumSelectList[props.enumOverwrite.get(key) as keyof typeof EnumSelectList]
                        : EnumSelectList[key as keyof typeof EnumSelectList];
                finalValue = (
                    <AsyncComponent fallback={fallback}>
                        <EnumInput
                            className={rowClass}
                            id={ID + '-' + (key as string)}
                            label={finalKey}
                            value={props.object[key as keyof S] as string}
                            onChange={(value) => {
                                props.onChange({ ...props.object, [key]: (value as any)?.value ?? value });
                            }}
                            required={props.required?.includes(key as keyof S)}
                            submitted={false}
                            allowURLChange={false}
                            {...fieldProps}
                        />
                    </AsyncComponent>
                );
            } else if (
                (props.enumOverwrite && props.enumOverwrite.has(key) && EnumSelectListV2[props.enumOverwrite.get(key) as keyof typeof EnumSelectListV2]) ||
                EnumSelectListV2[key as keyof typeof EnumSelectListV2]
            ) {
                const EnumInput =
                    props.enumOverwrite && props.enumOverwrite.has(key)
                        ? EnumSelectListV2[props.enumOverwrite.get(key) as keyof typeof EnumSelectListV2]
                        : EnumSelectListV2[key as keyof typeof EnumSelectListV2];
                finalValue = (
                    <AsyncComponent fallback={fallback}>
                        <EnumInput
                            {...fieldProps}
                            className={rowClass}
                            id={ID + '-' + (key as string)}
                            label={finalKey}
                            value={props.object[key as keyof S] as string}
                            onSelect={(value) => {
                                props.onChange({ ...props.object, [key]: value?.value ?? value });
                            }}
                            required={props.required?.includes(key as keyof S)}
                            allowURLChange={false}
                        />
                    </AsyncComponent>
                );
            } else if ((key as string).includes('time') || (key as string).includes('date')) {
                finalValue = (
                    <AsyncComponent fallback={fallback}>
                        <DateInput
                            className={rowClass}
                            id={ID + '-' + (key as string)}
                            label={finalKey}
                            value={props.object[key as keyof S] ? new Date(props.object[key as keyof S] as string) : undefined}
                            onChange={(value) => props.onChange({ ...props.object, [key]: value?.toISOString() })}
                            required={props.required?.includes(key as keyof S)}
                            submitted={false}
                            dateTime={fieldProps?.dateTime !== false ? true : false}
                            {...fieldProps}
                        />
                    </AsyncComponent>
                );
            } else if (key === 'country') {
                finalValue = (
                    <CountryInput
                        className={rowClass}
                        id={ID + '-' + (key as string)}
                        label={finalKey}
                        value={props.object[key as keyof S] as string}
                        onChange={(value) => props.onChange({ ...props.object, [key]: value })}
                        required={props.required?.includes(key as keyof S)}
                        submitted={false}
                        {...fieldProps}
                    />
                );
            } else if (key === 'location') {
                finalValue = (
                    <LocationInput
                        className={rowClass}
                        id={ID + '-' + (key as string)}
                        label={finalKey}
                        value={props.object[key as keyof S]}
                        onChange={(value) => props.onChange({ ...props.object, [key]: value })}
                        required={props.required?.includes(key as keyof S)}
                        submitted={false}
                        {...fieldProps}
                    />
                );
            } else if (key === 'email') {
                finalValue = (
                    <EmailInput
                        className={rowClass}
                        id={ID + '-' + (key as string)}
                        label={finalKey}
                        value={props.object[key as keyof S] as string}
                        onChange={(value) => props.onChange({ ...props.object, [key]: value })}
                        required={props.required?.includes(key as keyof S)}
                        submitted={false}
                        {...fieldProps}
                    />
                );
            } else if (key === 'phone_number' || key === 'mobile') {
                finalValue = (
                    <PhoneInput
                        className={rowClass}
                        id={ID + '-' + (key as string)}
                        label={finalKey}
                        value={props.object[key as keyof S] as string}
                        onChange={(value) => props.onChange({ ...props.object, [key]: value })}
                        required={props.required?.includes(key as keyof S)}
                        submitted={false}
                        {...fieldProps}
                    />
                );
            } else if ((key as string).includes('color')) {
                finalValue = (
                    <ColorInput
                        className={rowClass}
                        id={ID + '-' + (key as string)}
                        label={finalKey}
                        value={props.object[key as keyof S] as string}
                        onChange={(value) => props.onChange({ ...props.object, [key]: value })}
                        required={props.required?.includes(key as keyof S)}
                        {...fieldProps}
                    />
                );
            } else {
                finalValue = (
                    <TextInput
                        className={rowClass}
                        id={ID + '-' + (key as string)}
                        label={finalKey}
                        value={props.object[key as keyof S] as string}
                        onChange={(value) => props.onChange({ ...props.object, [key]: value })}
                        required={props.required?.includes(key as keyof S)}
                        submitted={false}
                        {...fieldProps}
                    />
                );
            }
        } else if (typeof value === 'number' || props.expectedType?.get(key) === 'number') {
            finalValue = (
                <NumberInput
                    className={rowClass}
                    id={ID + '-' + (key as string)}
                    label={finalKey}
                    value={props.object[key as keyof S] as number}
                    onChange={(value) => props.onChange({ ...props.object, [key]: value })}
                    required={props.required?.includes(key as keyof S)}
                    submitted={false}
                    {...fieldProps}
                />
            );
        } else if (Array.isArray(value)) {
            finalValue = (
                <TextInput
                    className={rowClass}
                    id={ID + '-' + (key as string)}
                    label={finalKey}
                    value={(props.object[key as keyof S] as []).join(', ')}
                    onChange={(value) => props.onChange({ ...props.object, [key]: value.split(',').map((item) => item.trim()) })}
                    required={props.required?.includes(key as keyof S)}
                    submitted={false}
                    {...fieldProps}
                />
            );
        } else if (typeof value === 'boolean' || props.expectedType?.get(key) === 'boolean') {
            finalValue = (
                <CheckboxInput
                    className={rowClass}
                    id={ID + '-' + (key as string)}
                    label={finalKey}
                    value={props.object[key as keyof S] as boolean}
                    onChange={(value) => props.onChange({ ...props.object, [key]: value })}
                    required={props.required?.includes(key as keyof S)}
                    submitted={false}
                    {...fieldProps}
                />
            );
        }

        if (commonKeys.includes(key as string)) {
            switch (key) {
                case 'location':
                    finalValue = (
                        <LocationInput
                            className={rowClass}
                            id={ID + '-' + (key as string)}
                            label={finalKey}
                            value={props.object[key as keyof S]}
                            onChange={(value) => props.onChange({ ...props.object, [key]: value })}
                            required={props.required?.includes(key as keyof S)}
                            submitted={false}
                            {...fieldProps}
                        />
                    );
                    break;
                case 'dimension':
                case 'inner':
                case 'outer':
                    finalValue = (
                        <DimensionInput
                            className={rowClass}
                            id={ID + '-' + (key as string)}
                            label={finalKey}
                            value={props.object[key as keyof S]}
                            onChange={(value) => props.onChange({ ...props.object, [key]: value })}
                            required={props.required?.includes(key as keyof S)}
                            submitted={false}
                            {...fieldProps}
                        />
                    );
                    break;
                case 'permissions':
                    finalValue = (
                        <CustomRoleTemplateSelect
                            className={rowClass}
                            id={ID + '-' + (key as string)}
                            label={finalKey}
                            entity_type={props.object['entity_type' as keyof S] as EntityType | undefined}
                            value={props.object[key as keyof S]}
                            onChange={(value) => props.onChange({ ...props.object, [key]: value })}
                            required={props.required?.includes(key as keyof S)}
                            submitted={false}
                            {...fieldProps}
                        />
                    );
                    break;
            }
        } else if (finalValue === undefined) {
            Logger.warn('Unknown type', {}, value, key);
        }

        const toAdd: [string, React.ReactNode] = [key as string, finalValue];

        if (index != null) result[index] = toAdd;
        else result.push(toAdd);
    }

    return result;
}
function isUUID(str: string): boolean {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidRegex.test(str);
}

const translationKeys = { nl_be: 'NL', en_us: 'EN', fr_fr: 'FR', de_de: 'DE' };
const commonKeys = ['location', 'dimension', 'permissions', 'inner', 'outer'];
