import { ApiError } from '@frontend/api-utils';
import { SliceStatus } from '@frontend/common';
import { EquipmentEnumClient } from '@frontend/equipment/api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

interface EquipmentState {
    equipmentStates: string[] | null;
    equipmentTriggers: string[] | null;
    equipmentActions: string[] | null;
    status: SliceStatus;
}

const initialState: EquipmentState = {
    equipmentStates: null,
    equipmentTriggers: null,
    equipmentActions: null,
    status: SliceStatus.INIT
};

const equipmentSlice = createSlice({
    name: 'equipments',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchEquipmentStates.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchEquipmentStates.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                state.equipmentStates = action.payload;
            })
            .addCase(fetchEquipmentTriggers.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchEquipmentTriggers.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                state.equipmentTriggers = action.payload;
            })
            .addCase(fetchEquipmentActions.pending, (state) => {
                state.status = SliceStatus.LOADING;
            })
            .addCase(fetchEquipmentActions.fulfilled, (state, action) => {
                state.status = SliceStatus.IDLE;
                state.equipmentActions = action.payload;
            });
    }
});

export const fetchEquipmentStates = createAsyncThunk<string[]>('fetchEquipmentStates', async (_, { rejectWithValue }) => {
    try {
        return await EquipmentEnumClient.fetchEquipmentStates();
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const fetchEquipmentTriggers = createAsyncThunk<string[]>('fetchEquipmentTriggers', async (_, { rejectWithValue }) => {
    try {
        return await EquipmentEnumClient.fetchEquipmentTriggers();
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const fetchEquipmentActions = createAsyncThunk<string[]>('fetchEquipmentActions', async (_, { rejectWithValue }) => {
    try {
        return await EquipmentEnumClient.fetchEquipmentActions();
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});

export const equipmentStore = { equipments: equipmentSlice.reducer };
