import { Logger } from '@frontend/Logger';

import { BasicAuthenticationClient } from './basic-authentication-client';
import { Token } from './models';

export class RefreshHelper {
    #location: string = 'token';
    private static instance: RefreshHelper | null;
    #blocked: boolean = false;
    private constructor(location?: string) {
        if (location) this.#location = location;
    }

    public static getInstance = (location?: string): RefreshHelper => {
        if (!RefreshHelper.instance) {
            RefreshHelper.instance = new RefreshHelper(location);
        }
        return RefreshHelper.instance;
    };

    async refresh(): Promise<void> {
        if (this.blocked) {
            return new Promise((resolve) => {
                const interval = setInterval(() => {
                    if (!this.blocked) {
                        resolve();
                        clearInterval(interval);
                    }
                }, 300);
            });
        }

        this.blocked = true;
        const tokenString = localStorage.getItem(this.#location);
        if (tokenString == null) {
            throw new Error('No token information available.');
        }
        const token = JSON.parse(tokenString) as Token;
        try {
            const credentials = await BasicAuthenticationClient.refresh(token.refresh_token);
            if ((credentials as any).detail) throw new Error((credentials as any).detail);
            localStorage.setItem(this.#location, JSON.stringify(credentials));
        } catch (error) {
            Logger.error('Something went wrong trying to refresh the token. Logging out.', {}, error);
            localStorage.removeItem(this.#location);
            window.location.reload();
            return new Promise((r, reject) => reject());
        }
        this.blocked = false;
        return;
    }

    public get blocked(): boolean {
        return this.#blocked;
    }
    protected set blocked(value: boolean) {
        if (this.#blocked) return;
        if (value) {
            setTimeout(() => {
                if (this.#blocked) this.#blocked = false;
            }, 3000);
        }
        this.#blocked = value;
    }
}
