import { APIClient } from '@frontend/api-utils';

const endpoint = '/export-api/v1';

export class ExportClient extends APIClient {
    public static async exportSpotReplenishment(accountId: string, spotId: string, name?: string): Promise<void> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/spots/${spotId}/replenishment`);
        await this.handleFileDownload(response, `${name}-replenishment`);
    }

    public static async exportConsumption(accountId: string, name?: string): Promise<void> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/consumption`);
        await this.handleFileDownload(response, `${name}`);
    }

    public static async exportEquipmentInventory(accountId: string, name?: string, body?: { format_type?: any }): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/equipment-inventory`, body);
        await this.handleFileDownload(response, `${name}`);
    }

    public static async exportEquipmentHistoricalUse(accountId: string, name?: string, body?: { format_type?: any }): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/equipment-historical-use`, body);
        await this.handleFileDownload(response, `${name}`);
    }
}
