import { AuthorizationWrapper, Permission } from '@frontend/authorization';
import { ClassType } from '@frontend/common';
import React from 'react';
import { IconType } from 'react-icons';

import { Spinner } from '../../spinner.component';
import useHorizontalButtonGroup from './horizontal-button-group.controller';

const ID = 'horizontal-button-group';
export interface HorizontalButtonGroupButton {
    text: React.ReactNode;
    disabled?: boolean;
    onClick?: (e: any) => void;
    icon?: IconType;
    type?: ClassType;
    isLoading?: boolean;
    hide?: boolean;
    id?: string;
    requiredPermissions?: Permission[];
    buttonType?: 'submit' | 'button' | 'reset';
    enabled?: (obj: any[]) => boolean;
}

export interface HorizontalButtonGroupProps {
    buttons: HorizontalButtonGroupButton[];
    direction: 'left' | 'right';
    noFlex?: boolean;
}

export const HorizontalButtonGroup = (props: HorizontalButtonGroupProps) => {
    const viewProps = useHorizontalButtonGroup(props);
    return (
        <div
            id={ID}
            style={viewProps.style}>
            {viewProps.buttons.map((button, i) => {
                const marginStyle = {
                    marginRight: props.direction === 'right' ? '10px' : '0',
                    marginLeft: props.direction === 'left' ? '10px' : '0'
                };
                const content = (
                    <button
                        id={button.id}
                        onClick={button.onClick}
                        className={'my-0 btn ' + (button.type ? 'btn-' + button.type : 'btn-secondary')}
                        key={i}
                        style={{ ...marginStyle }}
                        disabled={button.disabled}>
                        <div className='d-flex flex-row align-items-center'>
                            {button.isLoading ? (
                                <Spinner />
                            ) : (
                                <>
                                    {button.icon && <button.icon />}
                                    <span className={`${button.icon ? 'ps-2' : ''}`}>{button.text}</span>
                                </>
                            )}
                        </div>
                    </button>
                );

                return button.requiredPermissions ? (
                    <AuthorizationWrapper requiredPermissions={button.requiredPermissions}>{content}</AuthorizationWrapper>
                ) : (
                    content
                );
            })}
        </div>
    );
};
