import { AuthenticationManager } from '@frontend/authentication-v2';
import { ToastUtil } from '@frontend/toast-utils';
import { useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { Props } from './basic-login.component';

interface ViewProps {
    submitted: boolean;
    formValues: FormResult;
    emailValid: boolean;
    passwordValid: boolean;
    onSubmit: (e: any) => void;
    changeFormValues: React.Dispatch<React.SetStateAction<FormResult>>;
    changeEmailValid: React.Dispatch<React.SetStateAction<boolean>>;
    changePasswordValid: React.Dispatch<React.SetStateAction<boolean>>;
    navigate: NavigateFunction;
}

interface FormResult {
    email: string;
    password: string;
}

const useBasicLogin = (props: Props): ViewProps => {
    const [submitted, changeSubmitted] = useState<boolean>(false);
    const [formValues, changeFormValues] = useState<FormResult>({ email: '', password: '' });
    const [emailValid, changeEmailValid] = useState<boolean>(true);
    const [passwordValid, changePasswordValid] = useState<boolean>(true);
    const navigate = useNavigate();
    const onSubmit = (e: any) => {
        e.preventDefault();
        if (emailValid && passwordValid) {
            changeSubmitted(true);
            AuthenticationManager.getInstance()
                .authenticate(formValues.email.toLowerCase(), formValues.password)
                .then(() => props.callback && props.callback())
                .catch(() => {
                    ToastUtil.error('Your username or password is incorrect. Please correct and try again.');
                    changeSubmitted(false);
                });
        }
    };
    return {
        submitted,
        formValues,
        emailValid,
        passwordValid,
        onSubmit,
        changeFormValues,
        changeEmailValid,
        changePasswordValid,
        navigate
    };
};

export default useBasicLogin;
