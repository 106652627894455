import { PasswordInput } from '@frontend/basic-forms';
import { FormattedMessage } from 'react-intl';

import logo from '../../../../../assets/images/telloport_logo_horizontal.png';
import { ConfirmationComponent } from '../../basic-sign-up/basic-sign-up-confirmation/basic-sign-up-confirmation.component';
import useForgotPasswordConfirmation from './forgot-password-confirmation.controller';

const ID = 'forgot-password-confirmation';

const ForgotPasswordConfirmation = () => {
    const viewProps = useForgotPasswordConfirmation();

    return (
        <div
            id={ID}
            className='d-flex flex-column justify-content-center align-items-center m-5'>
            <form
                className='card w-50'
                onSubmit={viewProps.onSubmit}>
                <div className='card-header text-center'>
                    <img
                        src={logo}
                        className='img-fluid w-25'
                        alt=''
                    />
                    <h4 className='m-3'>
                        <FormattedMessage
                            id='forgot-password-confirmation.title'
                            description='Title for the forgot password confirmation page.'
                            defaultMessage='Reset password'
                        />
                    </h4>
                </div>
                {viewProps.response ? (
                    <div className='card-body'>
                        <div className='d-flex flex-column align-items-center'>
                            <ConfirmationComponent
                                submitted={viewProps.submitted}
                                response={viewProps.response}
                            />
                        </div>
                    </div>
                ) : (
                    <>
                        <div className='card-body d-flex flex-column align-items-center w-100'>
                            <PasswordInput
                                className='w-100'
                                required
                                label={
                                    <FormattedMessage
                                        id='forgot-password-confirmation.new-password'
                                        description='Label for the new password field.'
                                        defaultMessage='New password'
                                    />
                                }
                                value={viewProps.newPassword}
                                onChange={(value) => viewProps.changeNewPassword(value)}
                                isValidCallback={(value) => {
                                    viewProps.changePasswordValid(value);
                                }}
                                strength
                            />
                            <PasswordInput
                                id='confirm'
                                className='w-100'
                                required
                                label={
                                    <FormattedMessage
                                        id='forgot-password-confirmation.confirm-new-password'
                                        description='Label for the confirm new password field.'
                                        defaultMessage='Confirm new password'
                                    />
                                }
                                value={viewProps.repeatPassword}
                                onChange={(value) => viewProps.changeRepeatPassword(value)}
                                isValidCallback={(value) => {
                                    viewProps.changePasswordConfirmationValid(value);
                                }}
                                toRepeat={viewProps.newPassword}
                            />
                        </div>
                        <div className='card-footer text-center'>
                            <button
                                type='submit'
                                className='btn btn-primary'
                                disabled={!viewProps.passwordValid || !viewProps.passwordConfirmationValid || viewProps.submitted}
                                data-testid='resetPasswordButton'>
                                <FormattedMessage
                                    id='forgot-password-confirmation.title'
                                    description='Title for the forgot password confirmation page.'
                                    defaultMessage='Reset password'
                                />
                            </button>
                        </div>
                    </>
                )}
            </form>
        </div>
    );
};

export default ForgotPasswordConfirmation;
