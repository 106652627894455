import { EntityType } from '@frontend/common';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const Forms = {
    NAME: (
        <FormattedMessage
            id='account.forms.createAccount.nameInput.label'
            description='The label for the name input'
            defaultMessage='Name'
        />
    ),
    FIRST_NAME: (
        <FormattedMessage
            id='Forms.FIRST_NAME'
            description='The label for the first name input'
            defaultMessage='First name'
        />
    ),
    LAST_NAME: (
        <FormattedMessage
            id='Forms.LAST_NAME'
            description='The label for the last name input'
            defaultMessage='Last name'
        />
    ),
    EMAIL: formattedEmail,
    COUNTRY: (
        <FormattedMessage
            id='Forms.COUNTRY'
            description='The label for the country input'
            defaultMessage='Country'
        />
    ),
    ZIP_CODE: (
        <FormattedMessage
            id='Forms.ZIP_CODE'
            description='The label for the zip code input'
            defaultMessage='Zip code'
        />
    ),
    CITY: (
        <FormattedMessage
            id='Forms.CITY'
            description='The label for the city input'
            defaultMessage='City'
        />
    ),
    SPOT: (
        <FormattedMessage
            id='Forms.SPOT'
            description='Label for SPOT'
            defaultMessage='SPOT'
        />
    ),
    SLOT_NUMBER: (
        <FormattedMessage
            id='Forms.SLOT_NUMBER'
            description='Label for SLOT number'
            defaultMessage='SLOT number'
        />
    ),
    ALIAS: (
        <FormattedMessage
            id='Forms.ALIAS'
            description='Label for alias'
            defaultMessage='Alias'
        />
    ),
    LANGUAGE: (
        <FormattedMessage
            id='Forms.LANGUAGE'
            description='Label for language'
            defaultMessage='Language'
        />
    ),
    MOBILE: (
        <FormattedMessage
            id='Forms.MOBILE'
            description='Label for mobile'
            defaultMessage='Mobile'
        />
    ),
    ADDRESS: (
        <FormattedMessage
            id='Forms.ADDRESS'
            description='Label for address'
            defaultMessage='Address'
        />
    ),
    IDENTIFIER: (
        <FormattedMessage
            id='Forms.IDENTIFIER'
            description='Label for identifier'
            defaultMessage='Identifier'
        />
    ),
    CONTACT: (
        <FormattedMessage
            id='Forms.CONTACT'
            description='Label for contact'
            defaultMessage='Contact'
        />
    ),
    PERIOD: (
        <FormattedMessage
            id='Forms.PERIOD'
            description='Label for period'
            defaultMessage='Period'
        />
    ),
    CONTACT_INFO: (
        <FormattedMessage
            id='Forms.CONTACT_INFO'
            description='Label for contact info'
            defaultMessage='Contact info'
        />
    ),
    USER: {
        CREATE: (
            <FormattedMessage
                id='Forms.USER.CREATE'
                description='Create message for user form'
                defaultMessage='Create User'
            />
        )
    },
    CREATE_OBJECT: formattedCreateMessage,
    UPDATE_OBJECT: formattedUpdateMessage,
    ADDRESS_LINE: formattedAddressLine,
    DELETE_OBJECT: formattedDelete,
    MODULE: (
        <FormattedMessage
            id='Forms.MODULE'
            description='Label for module'
            defaultMessage='Module'
        />
    ),
    OPEN_OBJECT: openEntity,
    UNIQUE_IDENTIFIER: (
        <FormattedMessage
            id='Forms.UNIQUE_IDENTIFIER'
            description='Label for unique identifier'
            defaultMessage='Unique identifier'
        />
    ),
    EMPLOYEE_NUMBER: (
        <FormattedMessage
            id='Forms.EMPLOYEE_NUMBER'
            description='Label for employee number'
            defaultMessage='Employee number'
        />
    ),
    ENTITY_TYPE: (
        <FormattedMessage
            id='Objects.ENTITY_TYPE.DEFAULT'
            description='Label for entity type'
            defaultMessage='Entity type'
        />
    ),
    PERMISSIONS: (
        <FormattedMessage
            id='Forms.PERMISSIONS'
            description='Label for permissions'
            defaultMessage='Permissions'
        />
    ),
    DESCRIPTION: (
        <FormattedMessage
            id='Objects.COMMON_FIELDS.DESCRIPTION'
            description='Label for description'
            defaultMessage='Description'
        />
    ),
    KEY: (
        <FormattedMessage
            id='Objects.COMMON_FIELDS.KEY'
            description='Label for key'
            defaultMessage='Key'
        />
    ),
    STREET: (
        <FormattedMessage
            id='Forms.STREET'
            description='Label for street'
            defaultMessage='Street'
        />
    ),
    NUMBER: (
        <FormattedMessage
            id='Forms.NUMBER'
            description='Label for number'
            defaultMessage='Number'
        />
    ),
    BUS: (
        <FormattedMessage
            id='Forms.BUS'
            description='Label for bus'
            defaultMessage='Bus'
        />
    ),
    PROVINCE: (
        <FormattedMessage
            id='Forms.PROVINCE'
            description='Label for province'
            defaultMessage='Province'
        />
    ),
    REGION: (
        <FormattedMessage
            id='Forms.REGION'
            description='Label for region'
            defaultMessage='Region'
        />
    ),
    TYPE: (
        <FormattedMessage
            id='Forms.TYPE'
            description='Label for type'
            defaultMessage='Type'
        />
    ),
    DOORS: (
        <FormattedMessage
            id='Forms.DOORS'
            description='Label for doors'
            defaultMessage='Doors'
        />
    ),
    DIMENSION: (
        <FormattedMessage
            id='Objects.DIMENSION.DEFAULT'
            description='Label for dimension'
            defaultMessage='Dimension'
        />
    ),
    INNER: (
        <FormattedMessage
            id='Forms.INNER'
            description='Label for inner'
            defaultMessage='Inner'
        />
    ),
    OUTER: (
        <FormattedMessage
            id='Forms.OUTER'
            description='Label for outer'
            defaultMessage='Outer'
        />
    ),
    BRANDING: (
        <FormattedMessage
            id='Objects.BRANDING.DEFAULT'
            description='Label for branding'
            defaultMessage='Branding'
        />
    ),
    DOMAIN: (
        <FormattedMessage
            id='Forms.DOMAIN'
            description='Label for domain'
            defaultMessage='Domain'
        />
    ),
    QUANTITY: (
        <FormattedMessage
            id='Objects.COMMON_FIELDS.QUANTITY'
            description='Label for quantity'
            defaultMessage='Quantity'
        />
    ),
    SERIAL_NUMBER: (
        <FormattedMessage
            id='Objects.EQUIPMENT.SERIAL_NUMBER'
            description='Label for serial number'
            defaultMessage='Serial number'
        />
    ),
    DURATION: (
        <FormattedMessage
            id='Objects.QUESTION.DURATION'
            description='Label for duration'
            defaultMessage='Duration'
        />
    ),
    VISIBLE: (
        <FormattedMessage
            id='Objects.PLAYLIST_ITEM.VISIBLE'
            description='Label for visibility'
            defaultMessage='Visibility'
        />
    ),
    SORT_ORDER: (
        <FormattedMessage
            id='Objects.PLAYLIST_ITEM.SORT_ORDER'
            description='Order play list items are sorted'
            defaultMessage='Sort order'
        />
    ),
    SECONDS_TO_SHOW: (
        <FormattedMessage
            id='Forms.SECONDS_TO_SHOW'
            description='Seconds to show play list item'
            defaultMessage='Seconds to show'
        />
    ),
    FROM_TIMESTAMP: (
        <FormattedMessage
            id='Objects.BUDGET.FROM_TIMESTAMP'
            description='Label for from timestamp'
            defaultMessage='From'
        />
    ),
    TO_TIMESTAMP: (
        <FormattedMessage
            id='Objects.BUDGET.TO_TIMESTAMP'
            description='Label for to timestamp'
            defaultMessage='To'
        />
    ),
    EVERY: (
        <FormattedMessage
            id='Forms.EVERY'
            description='Label for every'
            defaultMessage='Every'
        />
    ),
    CONCURRENT: (
        <FormattedMessage
            id='Forms.CONCURRENT'
            description='Label for concurrent'
            defaultMessage='Concurrent'
        />
    ),
    BRAND: (
        <FormattedMessage
            id='Forms.BRAND'
            description='Label for brand'
            defaultMessage='Brand'
        />
    ),
    DISPLAY: (
        <FormattedMessage
            id='Objects.SLOT.DISPLAY'
            description='Label for display'
            defaultMessage='Display'
        />
    ),
    ACTIVE: (
        <FormattedMessage
            id='Objects.SLOT.ACTIVE'
            description='Label for active'
            defaultMessage='Active'
        />
    ),
    TAGS: (
        <FormattedMessage
            id='Objects.SLOT.TAGS'
            description='Label for tags'
            defaultMessage='Tags'
        />
    ),
    PLATFORM: (
        <FormattedMessage
            id='LogSource.PLATFORM'
            description='The displayed value for the log source PLATFORM'
            defaultMessage='Platform'
        />
    ),
    VALUE: (
        <FormattedMessage
            id='Forms.VALUE'
            description='Label for value'
            defaultMessage='Value'
        />
    ),
    ORDER: (
        <FormattedMessage
            id='Forms.ORDER'
            description='Label for order'
            defaultMessage='Order'
        />
    ),
    REVISION: (
        <FormattedMessage
            id='Forms.REVISION'
            description='Label for revision'
            defaultMessage='Revision'
        />
    ),
    CODE: (
        <FormattedMessage
            id='Forms.CODE'
            description='Label for code'
            defaultMessage='Code'
        />
    ),
    EMAIL_CHANNEL: (
        <FormattedMessage
            id='Forms.EMAIL_CHANNEL'
            description='Label for email channel'
            defaultMessage='Email channel'
        />
    ),
    MOBILE_CHANNEL: (
        <FormattedMessage
            id='Forms.MOBILE_CHANNEL'
            description='Label for mobile channel'
            defaultMessage='Mobile channel'
        />
    ),
    WHATSAPP_CHANNEL: (
        <FormattedMessage
            id='Forms.WHATSAPP_CHANNEL'
            description='Label for whatsapp channel'
            defaultMessage='Whatsapp channel'
        />
    )
};

function formattedAddressLine(number: string): React.ReactNode {
    return (
        <FormattedMessage
            id='Forms.ADDRESS_LINE'
            description='Label for address line'
            defaultMessage='Address line {number}'
            values={{
                number: number
            }}
        />
    );
}

function formattedCreateMessage(text: React.ReactNode): React.ReactNode {
    return (
        <FormattedMessage
            id='Forms.CREATE'
            description='Text for create with variable'
            defaultMessage='Create {text}'
            values={{
                text: text
            }}
        />
    );
}

function formattedUpdateMessage(text: React.ReactNode): React.ReactNode {
    return (
        <FormattedMessage
            id='Forms.UPDATE'
            description='Text for update with variable'
            defaultMessage='Update {text}'
            values={{
                text: text
            }}
        />
    );
}

function formattedEmail(text?: string): React.ReactNode {
    return (
        <FormattedMessage
            id='Forms.EMAIL'
            description='The label for the email input'
            defaultMessage='Email {text}'
            values={{ text: text }}
        />
    );
}

function formattedDelete(property: React.ReactNode, name?: React.ReactNode): React.ReactNode {
    return (
        <FormattedMessage
            id='Forms.DELETE'
            description='delete message'
            defaultMessage='You are about to delete {property} {name}'
            values={{
                property: property,
                name: name
            }}
        />
    );
}

function openEntity(entity: EntityType, name: React.ReactNode): React.ReactNode {
    return (
        <FormattedMessage
            id='Forms.OPEN_SLOT'
            description='Open slot message'
            defaultMessage='You are about to open {entity} {name}'
            values={{
                entity: entity.replace(/_/g, ' '),
                name: name
            }}
        />
    );
}
