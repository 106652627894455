import { CheckboxInput, DateInput, PasswordInput } from '@frontend/basic-forms';
import { EntityType } from '@frontend/common';
import { Spinner } from '@frontend/elements';
import { IdentificationClient } from '@frontend/identification/api';
import { Identification, IdentificationType, UpdateIdentification } from '@frontend/identification/types';
import { CommonMessage } from '@frontend/lang';
import { ObjectForm } from '@frontend/rendering/form';
import { useAccountRepository } from '@frontend/repository';
import { FormattedMessage } from 'react-intl';

import useIdentificationUpdate from './identification-update.controller';

const ID = 'identification-update';

const IdentificationUpdate = () => {
    const { store } = useAccountRepository();
    const viewProps = useIdentificationUpdate();
    if (!viewProps.identification) {
        return <Spinner />;
    }
    return (
        <div id={ID}>
            <ObjectForm<Identification, UpdateIdentification>
                type={EntityType.IDENTIFICATION}
                value={viewProps.formValues}
                headerOverwrite={viewProps.identification.type.replace(/_/g, ' ')}
                onChange={viewProps.changeFormValues}
                onCancel={viewProps.onCancel}
                onSubmit={(v) => IdentificationClient.postIdentification(store.currentAccount!, v)}
                onSubmitSuccess={viewProps.onSubmitSuccess}
                show={['key', 'valid_timestamp']}
                required={['key']}>
                {![IdentificationType.TAG, IdentificationType.BADGE].includes(viewProps.identification.type) && (
                    <>
                        <CheckboxInput
                            label={CommonMessage.OBJECTS.IDENTIFICATION.INDEFINITELY}
                            className='w-100'
                            submitted={false}
                            value={viewProps.isValidIndefinitely}
                            onChange={(v) => {
                                if (v === true) {
                                    delete viewProps.formValues.valid_timestamp;
                                }
                                viewProps.changeIsValidIndefinitely(v);
                            }}
                        />
                        <DateInput
                            label={
                                <FormattedMessage
                                    id='identification-update.label'
                                    description='Label for valid timestamp'
                                    defaultMessage='Valid until'
                                />
                            }
                            submitted={false}
                            className='w-100'
                            value={new Date(viewProps.formValues.valid_timestamp ?? '')}
                            onChange={(v) => viewProps.changeFormValues({ ...viewProps.formValues, valid_timestamp: v?.toISOString() })}
                            dateTime
                            disabled={viewProps.isValidIndefinitely}
                        />
                    </>
                )}
                {viewProps.identification.type === IdentificationType.BASIC_AUTH && (
                    <PasswordInput
                        label='Secret'
                        className='w-100'
                        value={viewProps.formValues.secret ?? undefined}
                        onChange={(v) => viewProps.changeFormValues({ ...viewProps.formValues, secret: v })}
                    />
                )}
            </ObjectForm>
        </div>
    );
};

export default IdentificationUpdate;
