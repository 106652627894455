import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

const CertificateOverview = lazy(() => import('../certificate/certificate-overview/certificate-overview'));
const CertificateCreate = lazy(() => import('../certificate/certificate-create/certificate-create.component'));
const CertificateUpdate = lazy(() => import('../certificate/certificate-update/certificate-update.component'));
const CertificateDetail = lazy(() => import('../certificate/certificate-detail/certificate-detail.component'));
const UserCertificateCreate = lazy(() => import('../user-certificate/user-certificate-create/user-certificate-create.component'));
const EquipmentCertificateCreate = lazy(() => import('../equipment-certificate/equipment-certificate-create/equipment-certificate-create.component'));

export function CertificateRoutes() {
    return (
        <Route path='certificates'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <CertificateOverview />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <CertificateCreate />
                    </AsyncComponent>
                }
            />
            <Route
                index
                element={<Navigate to='/admin/certificates' />}
            />
            <Route path=':certificateId'>
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <CertificateUpdate />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='detail'
                    element={
                        <AsyncComponent>
                            <CertificateDetail />
                        </AsyncComponent>
                    }
                />
                <Route path='equipment-certificates'>
                    <Route
                        path='create'
                        element={
                            <AsyncComponent>
                                <EquipmentCertificateCreate />
                            </AsyncComponent>
                        }
                    />
                </Route>
                <Route path='user-certificates'>
                    <Route
                        path='create'
                        element={
                            <AsyncComponent>
                                <UserCertificateCreate />
                            </AsyncComponent>
                        }
                    />
                </Route>
            </Route>
        </Route>
    );
}
