import { PaginatedResponse } from '@frontend/api-utils';

export interface IProduct {
    sku: string | null;
    ean: string | null;
    name: string;
    name_nl_be: string | null;
    name_en_us: string | null;
    name_fr_fr: string | null;
    name_de_de: string | null;
    description: string | null;
    description_nl_be: string | null;
    description_en_us: string | null;
    description_fr_fr: string | null;
    description_de_de: string | null;
    consume_questionnaire_id: string | null;
    return_questionnaire_id: string | null;
    attributes: { [key: string]: string } | null;
    update_timestamp: string;
    type: ProductType;
    id: string;
    account_id: string;
    creation_timestamp: string;
}

export type ProductListResponse = PaginatedResponse<IProduct>;

export enum ProductType {
    CONSUMABLE = 'consumable',
    EQUIPMENT = 'equipment',
    UNKNOWN = ''
}
export interface CreateProduct {
    sku?: string | null;
    ean?: string | null;
    name?: string;
    name_nl_be?: string | null;
    name_en_us?: string | null;
    name_fr_fr?: string | null;
    name_de_de?: string | null;
    description?: string | null;
    description_nl_be?: string | null;
    description_en_us?: string | null;
    description_fr_fr?: string | null;
    description_de_de?: string | null;
    type?: ProductType;
    consume_questionnaire_id?: string | null;
    return_questionnaire_id?: string | null;
}

export interface UpdateProduct {
    sku?: string | null;
    ean?: string | null;
    name?: string | null;
    name_nl_be?: string | null;
    name_en_us?: string | null;
    name_fr_fr?: string | null;
    name_de_de?: string | null;
    description?: string | null;
    description_nl_be?: string | null;
    description_en_us?: string | null;
    description_fr_fr?: string | null;
    description_de_de?: string | null;
    consume_questionnaire_id?: string | null;
    return_questionnaire_id?: string | null;
    null_fields?: string[] | null;
}

export enum ProductQueryParams {
    CATEGORY_ID = 'category_id',
    TYPE = 'type',
    SUPPLIER = 'supplier',
    NAME = 'name',
    EAN = 'ean',
    SKU = 'sku',
    ACCOUNT_ID = 'account_id',
    CATALOG_ID = 'catalog_id',
    ROOT_CATEGORY_ID = 'root_category_id',
    CONSUME_QUESTIONNAIRE_ID = 'consume_questionnaire_id',
    RETURN_QUESTIONNAIRE_ID = 'return_questionnaire_id'
}

export enum ProductGroupQueryParams {
    GROUP_ID = 'group_id'
}

export interface ProductReport {
    id: string;
    account_id: string;
    ean: string | null;
    sku: string | null;
    name: string | null;
    description: string | null;
    supplier: string | null;
    category_id: string | null;
    total_quantity: number;
    update_timestamp: string;
}

export type ProductReportListResponse = PaginatedResponse<ProductReport>;
