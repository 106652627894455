import { APIClient, ApiQueryParams, DefaultQueryParams } from "@frontend/api-utils";
import { IdentificationListResponse } from "@frontend/identification/types";

const endpoint = '/identification-api/v1';

export class IdentificationTagClient extends APIClient {
    public static async fetchAvailableTags(queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<IdentificationListResponse> {
        return await this.apiPaginated<IdentificationListResponse, DefaultQueryParams>(`${endpoint}/available-tags`, queryParams);
    }
}
