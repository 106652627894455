import React from 'react';
import { FaEnvelope } from 'react-icons/fa';

import useEmailInput from './email-input.controller';

export const ID = 'email-input';
export interface Props {
    required?: boolean;
    valid?: boolean;
    isValidCallback?: (valid: boolean) => void;
    onChange?: (value: string) => void;
    onFocusChange?: () => void;
    value?: string;
    errorMessage?: React.ReactNode;
    label?: React.ReactNode;
    helpMessage?: React.ReactNode;
    autoFocus?: boolean;
    submitted?: boolean;
    id?: string;

    className?: string;
    noAutoComplete?: boolean;
    noConditionedStyling?: boolean;
    isDisabled?: boolean;
}

export const EmailInput = (props: Props) => {
    const viewProps = useEmailInput(props);

    return (
        <div
            id={`div${ID}${props.id}`}
            className={'form-group ' + props.className}>
            <label>
                {props.required ? <span className='text-danger me-1'>&#9679;</span> : <></>}
                {props.label ? props.label : 'Email'}
            </label>
            <div className='input-group'>
                <span className='input-group-text'>
                    <FaEnvelope />
                </span>
                <input
                    className={viewProps.inputClass}
                    data-testid='emailInput'
                    autoComplete={props.noAutoComplete ? 'off' : 'email'}
                    name='no-complete-email'
                    id={props.id ? props.id + '-email-input' : 'email-input'}
                    value={props.value}
                    autoFocus={props.autoFocus}
                    type='text'
                    placeholder='you@example.com'
                    onFocus={() => {
                        viewProps.changeTouched(true);
                        props.onFocusChange && props.onFocusChange();
                    }}
                    required={props.required}
                    onChange={(event) => {
                        if (props.onChange) props.onChange(event.target.value);
                        viewProps.isValid();
                    }}
                    disabled={props.isDisabled}
                />
            </div>

            {viewProps.valid === false && viewProps.touched === true && (
                <div className='badge bg-gradient-danger mt-2'>
                    <small>{props.errorMessage ? props.errorMessage : 'Please fill in a valid email address.'} </small>
                    <br />
                </div>
            )}

            {props.helpMessage && (
                <div>
                    <small>{props.helpMessage}</small>
                </div>
            )}
        </div>
    );
};
