import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Route } from 'react-router-dom';

const MovementOverview = lazy(() => import('../movement/movement-overview/movement-overview'));

export function MovementRoutes() {
    return (
        <Route path='stock-updates'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <MovementOverview />
                    </AsyncComponent>
                }
            />
        </Route>
    );
}
