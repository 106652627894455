import { useAccountRepository } from '@frontend/repository';

import { useAppDispatch } from '../../redux/store';

interface ViewProps {
    currentAccount: string | null;
    dispatch: ReturnType<typeof useAppDispatch>;
}

const useEquipmentHistoricalUse = (): ViewProps => {
    const {
        store: { currentAccount }
    } = useAccountRepository();
    const dispatch = useAppDispatch();

    return {
        currentAccount,
        dispatch
    };
};

export default useEquipmentHistoricalUse;
