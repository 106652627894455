import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { CreateProblem, Problem, ProblemListResponse, ProblemQueryParams, UpdateProblem } from '@frontend/problem/types';

const endpoint = '/problem-api/v1';
export class ProblemClient extends APIClient {
    public static async fetchProblems(queryParams?: ApiQueryParams<DefaultQueryParams & ProblemQueryParams>): Promise<ProblemListResponse> {
        return await this.apiPaginated<ProblemListResponse, DefaultQueryParams & ProblemQueryParams>(`${endpoint}/problems`, queryParams);
    }

    public static async resolveProblems(ids: string[]): Promise<ProblemListResponse> {
        const response = await this.post(`${endpoint}/problems-resolve`, { ids: ids });
        return await this.handleResponse<ProblemListResponse>(response);
    }

    public static async deleteProblems(ids: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/problems-delete`, { ids: ids });
        return await this.handleVoidResponse(response);
    }

    public static async postProblem(accountId: string, body: CreateProblem): Promise<Problem> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/problems`, body);
        return await this.handleResponse<Problem>(response);
    }

    public static async fetchProblem(accountId: string, problemId: string): Promise<Problem> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/problems/${problemId}`);
        return await this.handleResponse<Problem>(response);
    }

    public static async patchProblem(accountId: string, problemId: string, body: UpdateProblem): Promise<Problem> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/problems/${problemId}`, body);
        return await this.handleResponse<Problem>(response);
    }

    public static async deleteProblem(accountId: string, problemId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/problems/${problemId}`);
        return await this.handleVoidResponse(response);
    }

    public static async postIoTSeed(accountId: string, iotId: string, body: { account_id: string }): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots/${iotId}/seed`, body);
        return this.handleResponse(response);
    }
}
