import { useEffect, useState } from 'react';

import { SelectOption, SingleOption, explodeOptions } from '../select-types';
import { SelectInputV2Props } from './select-input-v2.component';

interface ViewProps<T> {
    value?: string;
    onSearch: (value: string) => void;
    searchResultOptions: SelectOption<T>[];

    onSelect: (option: SingleOption<T> | null) => void;
    selected: SingleOption<T> | null;
    changeSelected: React.Dispatch<React.SetStateAction<SingleOption<T> | null>>;
}

const useSelectInputV2 = <T>(props: SelectInputV2Props<T>): ViewProps<T> => {
    const [value, changeValue] = useState<string | undefined>(undefined);
    const [searchResultOptions, changeSearchResultOptions] = useState<SelectOption<T>[]>([]);
    const [selected, changeSelected] = useState<SingleOption<T> | null>(null);

    useEffect(() => {
        if (props.searchValueOverwrite !== undefined) onSearch(props.searchValueOverwrite);
    }, [props.searchValueOverwrite]);

    useEffect(() => {
        if (props.value) {
            if (props.options) {
                const options = explodeOptions(props.options);
                const found = options.find((op) => op.value === props.value);
                if (found) onSelect(found);
            }
        }
    }, [props.value]);

    useEffect(() => {
        if (value && value !== '' && value !== selected?.value) {
            changeSearchResultOptions(props.options?.filter((op) => op.label.toLowerCase().includes(value.toLowerCase())));
        } else {
            changeSearchResultOptions(props.options || []);
        }
    }, [props.options]);

    const onSelect = (option: SingleOption<T> | null) => {
        changeSelected(option);
        changeValue(option?.label || undefined);
        props.onSelect && props.onSelect(option);
    };

    const onSearch = (value: string) => {
        changeSelected(null);
        changeValue(value);
        props.onSearch && props.onSearch(value);
        if (value && value !== '') {
            changeSearchResultOptions(props.options?.filter((op) => op.label.toLowerCase().includes(value.toLowerCase())));
        } else {
            changeSearchResultOptions(props.options || []);
        }
    };

    useEffect(() => {}, [props.value]);

    return {
        value: value,
        onSearch,
        searchResultOptions,

        onSelect,
        selected,
        changeSelected
    };
};

export default useSelectInputV2;
