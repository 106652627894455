import { AsyncComponent } from '@frontend/elements';
import { EventType } from '@frontend/events/types';
import { CommonMessage } from '@frontend/lang';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { AttributeRoutes } from './attribute/attribute-routes';
import { BrandingRoutes } from './branding/branding-routes';
import { BudgetRoutes } from './budget/budget-routes';
import { CertificateRoutes } from './certificate/certificate-routes';
import { ContactGroupRoutes } from './contact-group/contact-group-routes';
import { ContactRoutes } from './contact/contact-routes';
import { CostCenterRoutes } from './cost-center/cost-center-routes';
import { DeviceRoutes } from './device/device-routes';
import { DocumentRoutes } from './document/document-routes';
import { EquipmentGroupRoutes } from './equipment-group/equipment-group-routes';
import { EquipmentRoutes } from './equipment/equipment-routes';
import { IdentificationRoutes } from './identification/identification-routes';
import { ImportRoutes } from './import/import-routes';
import { IOTRoutes } from './iot/iot-routes';
import { ModuleTemplateRoutes } from './module-template/module-template-routes';
import { MovementRoutes } from './movement/movement-routes';
import { NotificationEmailRoutes } from './notification-email/notification-email-routes';
import { NotificationTemplateRoutes } from './notification-template/notification-template-routes';
import { PackageRoutes } from './package/package-routes';
import { PlaylistRoutes } from './playlist/playlist-routes';
import { ProblemRoutes } from './problem/problem-routes';
import { ProductCategoryRoutes } from './product-category/product-category-routes';
import { ProductGroupRoutes } from './product-group/product-group-routes';
import { ProductRoutes } from './product/product-routes';
import { QuestionGroupRoutes } from './question-group/question-group-routes';
import { AnswerRoutes } from './question/answer/answer-routes';
import { QuestionRoutes } from './question/question-routes';
import { RoleRoutes } from './role/role-routes';
import { SlotTemplateRoutes } from './slot-template/slot-template-routes';
import { SpotRoutes } from './spot/spot-routes';
import { StockRoutes } from './stock/stock-routes';
import { TagRoutes } from './tag/tag-routes';
import { TransactionRoutes } from './transaction/transaction-routes';
import { UnknownRoutes } from './unknown/unknown-routes';
import { UserBadgeRoutes } from './user-badge/user-badge-routes';
import { UserGroupRoutes } from './user-group/user-group-routes';
import { UserInterfaceRoutes } from './user-interface/user-interface-routes';
import { UserRoutes } from './user/user-routes';
import { WorkflowRoutes } from './workflow/workflow-routes';

const Configuration = lazy(() => import('./configuration/configuration.component'));

// #region Accounts
const AccountOverview = lazy(() => import('./account/account-overview'));
const AccountCreate = lazy(() => import('./account/account-create/account-create.component'));
const AccountDetail = lazy(() => import('./account/account-detail/account-detail.component'));
const AccountUpdate = lazy(() => import('./account/account-update/account-update.component'));
// #endregion
// #region License
const LicenseCreate = lazy(() => import('./license/license-create/license-create.component'));
// #endregion
// #region Events
const AllEventsOverview = lazy(() => import('./event/all-events-overview/all-events-overview'));
// #endregion
const Troubleshoot = lazy(() => import('./troubleshoot/troubleshoot.component'));
const PermissionsList = lazy(() => import('./permissions/permissions-list.component'));

const AnswerRoute = AnswerRoutes();
const AttributeRoute = AttributeRoutes();
const BrandingRoute = BrandingRoutes();
const BudgetRoute = BudgetRoutes();
const CertificateRoute = CertificateRoutes();
const ContactRoute = ContactRoutes();
const ContactGroupRoute = ContactGroupRoutes();
const CostCenterRoute = CostCenterRoutes();
const DeviceRoute = DeviceRoutes();
const DocumentRoute = DocumentRoutes();
const EquipmentRoute = EquipmentRoutes();
const EquipmentGroupRoute = EquipmentGroupRoutes();
const IdentificationRoute = IdentificationRoutes();
const ImportRoute = ImportRoutes();
const IoTRoutes = IOTRoutes();
const ModuleTemplateRoute = ModuleTemplateRoutes();
const MovementRoute = MovementRoutes();
const NotificationEmailRoute = NotificationEmailRoutes();
const NotificationTemplateRoute = NotificationTemplateRoutes();
const PackageRoute = PackageRoutes();
const PlaylistRoute = PlaylistRoutes();
const ProblemRoute = ProblemRoutes();
const ProductCategoryRoute = ProductCategoryRoutes();
const ProductRoute = ProductRoutes();
const ProductGroupRoute = ProductGroupRoutes();
const QuestionRoute = QuestionRoutes();
const QuestionGroupRoute = QuestionGroupRoutes();
const RoleRoute = RoleRoutes();
const SlotTemplateRoute = SlotTemplateRoutes();
const SpotRoute = SpotRoutes();
const StockRoute = StockRoutes();
const TagRoute = TagRoutes();
const TransactionRoute = TransactionRoutes();
const UnknownRoute = UnknownRoutes();
const UserBadgeRoute = UserBadgeRoutes();
const UserInterfaceRoute = UserInterfaceRoutes();
const UserRoute = UserRoutes();
const UserGroupRoute = UserGroupRoutes();
const WorkflowRoute = WorkflowRoutes();

export function AdminRoutes() {
    return (
        <Route path='admin'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <Configuration />
                    </AsyncComponent>
                }
            />
            <Route path='accounts'>
                {/**
    // #region Accounts
    * */}
                <Route
                    index
                    element={
                        <AsyncComponent>
                            <AccountOverview />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='create'
                    element={
                        <AsyncComponent>
                            <AccountCreate />
                        </AsyncComponent>
                    }
                />
                <Route path=':accountId'>
                    <Route
                        index
                        path='detail'
                        element={
                            <AsyncComponent>
                                <AccountDetail />
                            </AsyncComponent>
                        }
                    />
                    <Route
                        path='update'
                        element={
                            <AsyncComponent>
                                <AccountUpdate />
                            </AsyncComponent>
                        }
                    />
                    {/**
         // #region Account Entities
        * */}
                    <Route path=':entityType'>
                        <Route path=':entityId'>
                            <Route
                                path='permissions'
                                element={<PermissionsList />}
                            />
                            <Route
                                path='debug'
                                element={<Troubleshoot />}
                            />
                            {RoleRoute}

                            {IdentificationRoute}
                        </Route>
                    </Route>
                    {/**
         // #region Account user groups
        * */}
                    {UserGroupRoute}
                    {/**
         // #region Account equipment groups
        * */}
                    {EquipmentGroupRoute}
                    {/**
         // #region Account product groups
        * */}
                    {ProductGroupRoute}
                    {/**
         // #region Account contact groups
        * */}
                    {ContactGroupRoute}

                    {/**
            // #region Account budgets
        * */}
                    {BudgetRoute}
                    {/**
            // #region Account documents
        * */}
                    {DocumentRoute}
                    {/**
         // #region Account cost centers
        * */}
                    {CostCenterRoute}
                    {/**
                 // #region Account  tags
                * */}
                    {TagRoute}
                    {/**
         // #region Account roles
        * */}
                    {RoleRoute}
                    {/**
         // #region Account playlists
        * */}
                    {PlaylistRoute}
                    {/**
         // #region Account question groups
        * */}
                    {QuestionGroupRoute}
                    {/**
         // #region Account questions
        * */}
                    {QuestionRoute}
                    {/**
        // #region Account Module templates
        * */}
                    {ModuleTemplateRoute}
                    {/**
        // #region Account Slot templates
        * */}
                    {SlotTemplateRoute}
                    {/**
        // #region Account spots
        * */}
                    {SpotRoute}
                    {/**
         // #region Account equipment
        * */}
                    {EquipmentRoute}
                    {/**
         // #region Account users
        * */}
                    {UserRoute}
                    {/**
         // #region Account products
        * */}
                    {ProductRoute}
                    {/**
         // #region Account attributes
        * */}
                    {AttributeRoute}

                    {/**
         // #region Account Licenses
        * */}
                    <Route path='license'>
                        <Route
                            index
                            element={<Navigate to='../detail' />}
                        />
                        <Route
                            path='create'
                            element={
                                <AsyncComponent>
                                    <LicenseCreate />
                                </AsyncComponent>
                            }
                        />
                    </Route>

                    {/**
         // #region Account Certificates
        * */}
                    {CertificateRoute}
                    {/**
         // #region Account Workflows
        * */}
                    {WorkflowRoute}
                    {/**
         // #region Account user interfaces
        * */}
                    {UserInterfaceRoute}
                    {/**
         // #region Account IoTs
        * */}
                    {IoTRoutes}
                    {/**
                     * // #region Account Problems
                     */}
                    {ProblemRoute}
                    {/**
         // #region Account Contacts
        * */}
                    {ContactRoute}
                    {/**
         // #region Account Transactions
        * */}
                    {TransactionRoute}
                    {/**
              // #region Account Notification Templates
             * */}
                    {NotificationTemplateRoute}
                    {/**
              // #region Account Notification Emails
             * */}
                    {NotificationEmailRoute}
                    {/**
         // #region Account product categories
        * */}
                    {ProductCategoryRoute}
                </Route>
            </Route>
            {/**
 // #region User groups
* */}
            {UserGroupRoute}
            {/**
 // #region Equipment groups
* */}
            {EquipmentGroupRoute}
            {/**
 // #region Product groups
* */}
            {ProductGroupRoute}

            {ContactGroupRoute}
            {/**
 // #region Cost centers
* */}
            {CostCenterRoute}
            {/**
    // #region Budgets
* */}
            {BudgetRoute}
            {/**
    // #region Document
* */}
            {DocumentRoute}
            {/**
        // #region  Tags
        * */}
            {TagRoute}
            {/*
    // #region Roles
* */}
            {RoleRoute}
            {/**
 // #region Question groups
* */}
            {QuestionGroupRoute}
            {/**
    // #region Questions
* */}
            {QuestionRoute}
            {/**
    // #region Answers
* */}
            {AnswerRoute}
            {/*
    // #region Playlists
* */}
            {PlaylistRoute}
            {/*
    // #region Spots
* */}
            {SpotRoute}
            {/*
    // #region Module templates
* */}
            {ModuleTemplateRoute}
            {/*
        {/*
    // #region Slot templates
* */}
            {SlotTemplateRoute}
            {/*
    // #region Equipment
* */}
            {EquipmentRoute}
            {/*
    // #region Transactions
* */}
            {TransactionRoute}

            {/*
    // #region Users
* */}
            {UserRoute}
            {/*
    // #region Products
* */}
            {ProductRoute}
            {/*
    // #region Attributes
* */}
            {AttributeRoute}
            {/*
    // #region Certificates
* */}
            {CertificateRoute}
            {/*
    // #region Workflows
* */}
            {WorkflowRoute}
            {/*
    // #region User interfaces
* */}
            {UserInterfaceRoute}
            {/*
    // #region IoT
* */}
            {IoTRoutes}

            {/*
    // #region Problems
* */}
            {ProblemRoute}
            {/** *
// #region Unknowns
* */}
            {UnknownRoute}
            {/*
    // #region Devices
* */}
            {DeviceRoute}
            {/*
    // #region Stocks
* */}
            {StockRoute}
            {/*
    // #region Movements
* */}
            {MovementRoute}
            {/*
    // #region Packages
* */}
            {PackageRoute}
            {/*
    // #region Contacts
* */}
            {ContactRoute}
            {/*
    // #region Events
* */}
            <Route path='sync-events'>
                <Route
                    index
                    element={
                        <AllEventsOverview
                            type={[EventType.ENTITY_SYNC_DELETED, EventType.ENTITY_SYNC_UPDATED, EventType.ENTITY_SYNC_CREATED]}
                            title={CommonMessage.OBJECTS.EVENT.SYNC_EVENTS}
                        />
                    }
                />
            </Route>
            {ImportRoute}
            {/** 
     // #region Notification Templates
     */}
            {NotificationTemplateRoute}
            {/** 
     // #region Notification Emails
     */}
            {NotificationEmailRoute}
            {/** 
     // #region Product Categories
     */}
            {ProductCategoryRoute}
            {/**
             // #region Branding
             */}
            {BrandingRoute}
            <Route path=':entityType'>
                <Route path=':entityId'>
                    <Route
                        path='permissions'
                        element={<PermissionsList />}
                    />
                    <Route
                        path='debug'
                        element={<Troubleshoot />}
                    />
                </Route>
            </Route>

            {UserBadgeRoute}
        </Route>
    );
}
