import { APIClient, ApiQueryParams } from "@frontend/api-utils";
import { EntityType } from "@frontend/common";
import { CreateGroup, CreateGroupMember, Group, GroupListResponse, GroupMember, GroupMemberListResponse, GroupMemberQueryParams, GroupQueryParams, UpdateGroup, UpdateGroupMember } from "@frontend/group/types";

const endpoint = '/group-api/v1';

export class UserGroupClient extends APIClient {
    public static async fetchUserGroups(queryParams?: ApiQueryParams<GroupQueryParams>): Promise<GroupListResponse> {
        return await this.apiPaginated<GroupListResponse, GroupQueryParams>(`${endpoint}/user-groups`, queryParams);
    }

    public static async resolveUserGroups(groupIds: string[]): Promise<GroupListResponse> {
        const response = await this.post(`${endpoint}/user-groups-resolve`, { ids: groupIds });
        return await this.handleResponse<GroupListResponse>(response);
    }

    public static async deleteUserGroups(groupIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/user-groups-delete`, { ids: groupIds });
        return await this.handleVoidResponse(response);
    }

    public static async postUserGroup(accountId: string, group: CreateGroup): Promise<Group> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/user-groups`, group);
        return await this.handleResponse<Group>(response);
    }

    public static async fetchUserGroup(accountId: string, groupId: string): Promise<Group> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/user-groups/${groupId}`);
        return await this.handleResponse<Group>(response);
    }

    public static async patchUserGroup(accountId: string, groupId: string, group: UpdateGroup): Promise<Group> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/user-groups/${groupId}`, group);
        return await this.handleResponse<Group>(response);
    }

    public static async deleteUserGroup(accountId: string, groupId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/user-groups/${groupId}`);
        return await this.handleVoidResponse(response);
    }

    public static async fetchGroupsNotInGroup(accountId: string, groupId: string): Promise<GroupListResponse> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/user-groups/${groupId}/groups`);
        return await this.handleResponse<GroupListResponse>(response);
    }

    //Group members
    public static async fetchUserGroupMembers(queryParams?: ApiQueryParams<GroupMemberQueryParams>): Promise<GroupMemberListResponse> {
        return await this.apiPaginated<GroupMemberListResponse, GroupMemberQueryParams>(`${endpoint}/user-group-members`, queryParams);
    }

    public static async resolveUserGroupMembers(memberIds: string[]): Promise<GroupMemberListResponse> {
        const response = await this.post(`${endpoint}/user-group-members-resolve`, { ids: memberIds });
        return await this.handleResponse<GroupMemberListResponse>(response);
    }

    public static async deleteUserGroupMembers(memberIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/user-group-members-delete`, { ids: memberIds });
        return await this.handleVoidResponse(response);
    }

    public static async postUserGroupMember(accountId: string, groupId: string, groupMember: CreateGroupMember): Promise<GroupMember> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/user-groups/${groupId}/members`, groupMember);
        return await this.handleResponse<GroupMember>(response);
    }

    public static async postUserGroupMemberList(accountId: string, groupId: string, entity_type: EntityType, memberIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/user-groups/${groupId}/${entity_type}s/members-list`, { ids: memberIds });
        return await this.handleVoidResponse(response);
    }

    public static async fetchUserGroupMember(accountId: string, groupId: string, memberId: string): Promise<GroupMember> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/user-groups/${groupId}/members/${memberId}`);
        return await this.handleResponse<GroupMember>(response);
    }

    public static async patchUserGroupMember(accountId: string, groupId: string, memberId: string, body: UpdateGroupMember): Promise<GroupMember> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/user-groups/${groupId}/members/${memberId}`, body);
        return await this.handleResponse<GroupMember>(response);
    }

    public static async deleteUserGroupMember(accountId: string, groupId: string, memberId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/user-groups/${groupId}/members/${memberId}`);
        return await this.handleVoidResponse(response);
    }
}