import { TranslatedString, TranslatedStringProps } from '@frontend/lang';
import React from 'react';

export const TranslationCell = <T extends object>(props: TranslatedStringProps<T>) => {
    return (
        <td className='text-xs text-primary align-items-center'>
            <TranslatedString {...props} />
        </td>
    );
};
