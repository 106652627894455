import { EntityType } from '@frontend/common';
import { ProductGroupClient } from '@frontend/group/api';
import { useResolver } from '@frontend/repository';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

declare global {
    interface WindowEventMap {
        'useProductGroup:request': CustomEvent<string>;
    }
}

const REQUEST_PRODUCT_GROUP_EVENT = 'useProductGroup:request';
const STORAGE_KEY = 'PRD_GRP_REQ';

export function productGroupRequest(groupId: string): string {
    window.dispatchEvent(new CustomEvent(REQUEST_PRODUCT_GROUP_EVENT, { detail: groupId }));
    return STORAGE_KEY + '_loading';
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function ProductGroupResolver(props: Props) {
    useResolver(STORAGE_KEY, REQUEST_PRODUCT_GROUP_EVENT, EntityType.PRODUCT_GROUP, (ids) => ProductGroupClient.resolveProductGroups(ids), props.dispatch);
    return <></>;
}
