import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { CreateEquipment, Equipment, EquipmentListResponse, EquipmentQueryParams, UpdateEquipment } from '@frontend/equipment/types';

const endpoint = '/equipment-api/v1';
export class EquipmentClient extends APIClient {
    public static async fetchEquipments(queryParams?: ApiQueryParams<DefaultQueryParams | EquipmentQueryParams>): Promise<EquipmentListResponse> {
        return await this.apiPaginated<EquipmentListResponse, DefaultQueryParams>(`${endpoint}/equipments`, queryParams);
    }
    public static async resolveEquipments(ids: string[]): Promise<EquipmentListResponse> {
        const response = await this.post(`${endpoint}/equipments-resolve`, { ids: ids });
        return await this.handleResponse<EquipmentListResponse>(response);
    }

    public static async deleteEquipments(ids: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/equipments-delete`, { ids: ids });
        await this.handleVoidResponse(response);
    }

    public static async postEquipment(accountId: string, equipment: CreateEquipment): Promise<Equipment> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/equipments`, equipment);
        return await this.handleResponse<Equipment>(response);
    }

    public static async fetchEquipmentsNotInGroup(accountId: string, groupId: string): Promise<EquipmentListResponse> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/groups/${groupId}/equipments`);
        return await this.handleResponse<EquipmentListResponse>(response);
    }

    public static async fetchEquipment(accountId: string, equipmentId: string): Promise<Equipment> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/equipments/${equipmentId}`);
        return await this.handleResponse<Equipment>(response);
    }

    public static async patchEquipment(accountId: string, equipmentId: string, equipment: UpdateEquipment): Promise<Equipment> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/equipments/${equipmentId}`, equipment);
        return await this.handleResponse<Equipment>(response);
    }

    public static async deleteEquipment(accountId: string, equipmentId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/equipments/${equipmentId}`);
        await this.handleVoidResponse(response);
    }

    public static async postIoTSeed(accountId: string, iotId: string, body: { account_id: string }): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots/${iotId}/seed`, body);
        return this.handleResponse(response);
    }
}
