import React from 'react';
import { FormattedMessage } from 'react-intl';

export const Toasts = {
    PROMISE: {
        PENDING: (
            <FormattedMessage
                id='toasts.promise-pending'
                description='Pending message on promise'
                defaultMessage='Pending'
            />
        ),
        SUCCESS: (
            <FormattedMessage
                id='toasts.promise-success'
                description='Success message on promise'
                defaultMessage='Success'
            />
        ),
        ERROR: (
            <FormattedMessage
                id='toasts.promise-error'
                description='Error message on promise'
                defaultMessage='Error'
            />
        )
    },
    UDPATE: {
        ERROR: UpdateError,
        SUCCESS: UpdateSuccess,
        PENDING: UpdatePending
    },
    DELETE: {
        ERROR: DeleteError,
        SUCCESS: DeleteSuccess,
        PENDING: DeletePending
    },
    CREATE: {
        ERROR: CreateError,
        SUCCESS: CreateSuccess,
        PENDING: CreatePending
    }
};

export function UpdatePending(text: React.ReactNode) {
    return (
        <FormattedMessage
            id='toasts.update-pending'
            description='Pending message on update'
            defaultMessage='Updating {text}'
            values={{ text }}
        />
    );
}

export function UpdateSuccess(text: React.ReactNode) {
    return (
        <FormattedMessage
            id='toasts.update-success'
            description='Success message on update'
            defaultMessage='{text} updated'
            values={{ text }}
        />
    );
}

export function UpdateError(text: React.ReactNode) {
    return (
        <FormattedMessage
            id='toasts.update-error'
            description='Error message on update'
            defaultMessage='Error updating {text}'
            values={{ text }}
        />
    );
}

export function DeleteError(text: React.ReactNode) {
    return (
        <FormattedMessage
            id='toasts.delete-error'
            description='Error message on delete'
            defaultMessage='Error deleting {text}'
            values={{ text }}
        />
    );
}

export function DeleteSuccess(text: React.ReactNode) {
    return (
        <FormattedMessage
            id='toasts.delete-success'
            description='Success message on delete'
            defaultMessage='{text} deleted'
            values={{ text }}
        />
    );
}

export function DeletePending(text: React.ReactNode) {
    return (
        <FormattedMessage
            id='toasts.delete-pending'
            description='Pending message on delete'
            defaultMessage='Deleting {text}'
            values={{ text }}
        />
    );
}

export function CreateError(text: React.ReactNode) {
    return (
        <FormattedMessage
            id='toasts.create-error'
            description='Error message on create'
            defaultMessage='Error creating {text}'
            values={{ text }}
        />
    );
}

export function CreateSuccess(text: React.ReactNode) {
    return (
        <FormattedMessage
            id='toasts.create-success'
            description='Success message on create'
            defaultMessage='{text} created'
            values={{ text }}
        />
    );
}

export function CreatePending(text: React.ReactNode) {
    return (
        <FormattedMessage
            id='toasts.create-pending'
            description='Pending message on create'
            defaultMessage='Creating {text}'
            values={{ text }}
        />
    );
}
