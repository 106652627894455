import { EntityType } from '@frontend/common';
import { contactRequest } from '@frontend/contact/events';
import { IContact } from '@frontend/contact/types';
import { Contact } from '@frontend/contact/utils';
import { ObjectBadge, ObjectBadgeProps } from '@frontend/rendering/badge';
import React from 'react';

export const ContactBadge = (props: ObjectBadgeProps<IContact>) => {
    return (
        <ObjectBadge<IContact>
            id={props.id}
            type={EntityType.CONTACT}
            mapDisplayName={(co: IContact) => new Contact(co).getDisplayName()}
            resolve={contactRequest}
            key={props.id}
            navigateString={(object: IContact) => `/accounts/${object.account_id}/contacts/${object.id}`}
            {...props}
        />
    );
};
