import { Account, AccountListResponse, AccountQueryParams, CreateAccount, UpdateAccount } from '@frontend/account/types';
import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';

const endpoint = '/account-api/v1';

export class AccountClient extends APIClient {
    public static async fetchAccounts(queryParams?: ApiQueryParams<DefaultQueryParams | AccountQueryParams>): Promise<AccountListResponse> {
        return await this.apiPaginated<AccountListResponse, DefaultQueryParams>(`${endpoint}/accounts`, queryParams);
    }

    public static async postAccount(account: CreateAccount): Promise<Account> {
        const response = await this.post(`${endpoint}/accounts`, account);
        return await this.handleResponse<Account>(response);
    }

    public static async resolveAccounts(accountIds: string[]): Promise<AccountListResponse> {
        const response = await this.post(`${endpoint}/accounts-resolve`, { ids: accountIds });
        return await this.handleResponse<AccountListResponse>(response);
    }

    public static async fetchAccount(accountId: string): Promise<Account> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}`);
        return await this.handleResponse<Account>(response);
    }

    public static async patchAccount(accountId: string, account: UpdateAccount): Promise<Account> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}`, account);
        return await this.handleResponse<Account>(response);
    }

    public static async deleteAccount(accountId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}`);
        return await this.handleVoidResponse(response);
    }

    public static async deleteAccounts(accountIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/accounts-delete`, { ids: accountIds });
        return await this.handleVoidResponse(response);
    }

    public static async postIoTSeed(accountId: string, iotId: string, body: { account_id: string }): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots/${iotId}/seed`, body);
        return this.handleResponse(response);
    }

    public static async initializeAccount(accountId: string): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/initialize`);
        return await this.handleVoidResponse(response);
    }
}
