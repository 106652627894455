import { EntityType } from '@frontend/common';
import { Spinner } from '@frontend/elements';
import { CommonMessage } from '@frontend/lang';
import { NotificationEmailClient } from '@frontend/notification-service/api';
import { NotificationEmail } from '@frontend/notification-service/types';
import { ObjectForm } from '@frontend/rendering/form';
import { useAccountRepository } from '@frontend/repository';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import useNotificationEmailCopy from './notification-email-copy.controller';

const ID = 'notification-email-copy';

const NotificationEmailCopy = () => {
    const viewProps = useNotificationEmailCopy();
    const {
        store: { currentAccount }
    } = useAccountRepository();
    const { emailId } = useParams();

    if (!currentAccount || !emailId) {
        return <Spinner />;
    }

    return (
        <div id={ID}>
            <ObjectForm<NotificationEmail, { account_id: string }>
                type={EntityType.NOTIFICATION_EMAIL}
                value={viewProps.formValues}
                label={
                    <FormattedMessage
                        id='notification-email-copy.copy-label'
                        defaultMessage='Copy {value}'
                        values={{ value: CommonMessage.OBJECTS.NOTIFICATION_EMAIL.DEFAULT }}
                    />
                }
                required={['account_id']}
                onChange={viewProps.changeFormValues}
                submitDisabled={!viewProps.formValues.account_id}
                onCancel={viewProps.onCancel}
                onSubmit={(a) => NotificationEmailClient.copyNotificationEmail(currentAccount, emailId, a)}
                onSubmitSuccess={viewProps.onSubmitSuccess}
            />
        </div>
    );
};

export default NotificationEmailCopy;
