import { EventListener } from '@frontend/pub-sub';
import { Transaction } from '@frontend/transaction/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

export class TransactionEventListener extends EventListener<Transaction> {
    
    private static instance: TransactionEventListener | null = null;
    private constructor(dispatch: ThunkDispatch<any, any, Action>, variables?: Array<{ [key: string]: any }>) {
        super('transaction', dispatch, variables);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>, variables?: Array<{ [key: string]: any }>): TransactionEventListener {
        if (this.instance == null) {
            this.instance = new TransactionEventListener(dispatch, variables);
        }
        return this.instance;
    }

    getInstance(dispatch: ThunkDispatch<any, any, Action>, variables?: Array<{ [key: string]: any }>): EventListener<Transaction> {
        return TransactionEventListener.getInstance(dispatch, variables);
    }

    protected onUpdate() {}
    protected onDelete() {}
    protected onCreate() {}
}