import { ApiQueryParams } from '@frontend/api-utils';
import { useAppSelector } from '@frontend/common';
import { ListInfo, listStore } from '@frontend/repository';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SetURLSearchParams, useSearchParams } from 'react-router-dom';

import { FilterModalProps } from './filter-modal.component';

interface ViewProps {
    params?: ApiQueryParams<string | number>;
    setSearchParams: SetURLSearchParams;
    width: number;
    searchParams?: URLSearchParams;
}

const useFilterModal = <T extends object & { id: string }>(props: FilterModalProps<T>): ViewProps => {
    const store = useAppSelector(useSelector, listStore);
    const list: ListInfo | undefined = store[props.listId];
    const [searchParams, setSearchParams] = useSearchParams();
    const [width, changeWidth] = useState<number>(window.innerWidth);
    useEffect(() => {
        window.addEventListener('resize', () => {
            changeWidth(window.innerWidth);
        });

        return () => {
            window.removeEventListener('resize', () => {});
        };
    }, []);
    return {
        params: list?.filter,
        setSearchParams,
        width,
        searchParams
    };
};

export default useFilterModal;
