import { BadgeProps, useBadgeRepository } from '@frontend/repository';
import React from 'react';
import { IconType } from 'react-icons';
import { RiExternalLinkLine } from 'react-icons/ri';

export interface ObjectBadgeProps<T> {
    id?: string;
    className?: string;
    callback?: (object: T) => void;
    navigateString?: (object: T) => string;
    navigateOnDisplayName?: boolean;
    /**
     * @deprecated
     */
    icon?: IconType | null;
    disableClick?: boolean;
    mapDisplayName?: (entity: T) => string;
    resolve?: (id: string) => string;
}

export const ObjectBadge = <T,>(props: BadgeProps<T>) => {
    const repository = useBadgeRepository<T>(props);
    return (
        <span
            id={'badge-' + props.id}
            className={
                props.className
                    ? props.className
                    : `d-flex align-items-center text-sm ${repository.disabled ? 'disabled' : props.navigateOnDisplayName ? 'badge-display-link text-primary' : ''} m-1`
            }>
            {repository.displayName ? (
                <span
                    onClick={(e) =>
                        !props.disableClick &&
                        !repository.disabled &&
                        repository.isPlatform &&
                        props.navigateString &&
                        repository.object &&
                        props.navigateOnDisplayName &&
                        repository.onClick(e, props.navigateString(repository.object))
                    }>
                    {repository.displayName}
                </span>
            ) : (
                <span className='placeholder-glow'>
                    <span className='placeholder'>loading</span>
                </span>
            )}
            {!props.disableClick &&
                !repository.disabled &&
                repository.isPlatform &&
                props.navigateString &&
                repository.object &&
                !props.navigateOnDisplayName && (
                    <RiExternalLinkLine
                        onClick={(e) =>
                            !props.disableClick &&
                            !repository.disabled &&
                            repository.isPlatform &&
                            props.navigateString &&
                            repository.object &&
                            !props.navigateOnDisplayName &&
                            repository.onClick(e, props.navigateString(repository.object))
                        }
                        fontSize={15}
                        className='ms-1 badge-link'
                    />
                )}
        </span>
    );
};
