import { ClassType } from '@frontend/common';
import { Dropdown, DropdownItem } from '@frontend/elements';
import React from 'react';

import useAccountMembership from './account-membership.controller';

interface Props {
    className?: string;
}
const AccountMembership = (props: Props) => {
    const viewProps = useAccountMembership();

    return (
        <span className={props.className}>
            {viewProps.accounts.length > 1 ? (
                <Dropdown
                    label={viewProps.selectedAccount?.name ?? 'Select account'}
                    classType={ClassType.LIGHT}>
                    {viewProps.accounts.map((acc) => {
                        return (
                            <DropdownItem
                                key={acc.id}
                                label={acc.name}
                                onClick={() => viewProps.onChangeMembership(acc.id)}
                            />
                        );
                    })}
                </Dropdown>
            ) : (
                <button className='btn btn-light'>{viewProps.selectedAccount?.name}</button>
            )}
        </span>
    );
};

export default AccountMembership;
