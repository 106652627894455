import React from 'react';
import { FormattedMessage } from 'react-intl';

import useNumberInput, { NumberInputError } from './number-input.controller';

const ID = 'number-input';
export interface NumberInputProps {
    required?: boolean;
    valid?: boolean;
    isValidCallback?: (valid: boolean) => void;
    onChange?: (value: number) => void;
    onFocusChange?: () => void;
    step?: number;
    value?: string | number;
    errorMessage?: React.ReactNode;
    label?: React.ReactNode;
    helpMessage?: React.ReactNode;
    autoFocus?: boolean;
    disabled?: boolean;
    submitted: boolean;
    min?: number;
    max?: number;
    id?: string;
    minLength?: number;
    maxLength?: number;
    isClearable?: boolean;
    className?: string;
    onBlur?: () => void;
}

export const NumberInput = (props: NumberInputProps) => {
    const viewProps = useNumberInput(props);

    return (
        <div
            className={'form-group ' + props.className}
            id={props.id || ID}>
            <label>
                {props.required ? <span className='text-danger me-1'>&#9679;</span> : <></>} {props.label}
                {props.helpMessage && (
                    <>
                        <br />
                        <small className='form-text text-muted'>{props.helpMessage}</small>
                    </>
                )}
            </label>
            <input
                className={viewProps.inputClass}
                autoFocus={props.autoFocus}
                id={props.id}
                value={props.value}
                step={props.step || 1}
                min={props.min}
                max={props.max}
                minLength={props.minLength}
                maxLength={props.maxLength}
                type='number'
                onFocus={() => {
                    viewProps.changeTouched(true);
                    props.onFocusChange && props.onFocusChange();
                }}
                required={props.required}
                onChange={(event) => {
                    const numberValue = parseFloat(event.target.value);
                    if (isNaN(numberValue)) {
                        if (props.isClearable) {
                            viewProps.changeTouched(false);
                            props.isValidCallback && props.isValidCallback(true);
                        } else {
                            props.isValidCallback && props.isValidCallback(false);
                        }
                    }
                    if (!isNaN(numberValue) || event.target.value === '') {
                        if (event.target.value.length > 0) {
                            viewProps.changeTouched(true);
                        }
                        if (props.onChange) {
                            props.onChange(numberValue);
                            if (props.min !== undefined) {
                                if (numberValue < props.min) {
                                    props.onChange(props.min);
                                }
                            }
                            if (props.max !== undefined) {
                                if (numberValue > props.max) {
                                    props.onChange(props.max);
                                }
                            }
                            if (props.maxLength !== undefined) {
                                if (numberValue.toString().length > props.maxLength) {
                                    const stringValue = numberValue.toString();
                                    props.onChange(parseFloat(stringValue.slice(0, props.maxLength)));
                                }
                            }
                        }
                    }
                    viewProps.isValid();
                }}
                disabled={props.disabled}
                onBlur={props.onBlur}
            />

            {viewProps.valid === false && viewProps.touched === true ? (
                <>
                    {props.errorMessage === undefined ? (
                        <>
                            {viewProps.error === NumberInputError.MIN_LENGTH && MinLengthError(props.minLength)}
                            {viewProps.error === NumberInputError.MAX_LENGTH && MaxLengthError(props.maxLength)}
                            {viewProps.error === NumberInputError.MIN_MAX_LENGTH && MinMaxLengthError(props.minLength, props.maxLength)}
                        </>
                    ) : (
                        <span className='badge bg-gradient-danger mt-2'>
                            <small>{props.errorMessage}</small>
                        </span>
                    )}
                </>
            ) : (
                <></>
            )}
        </div>
    );
};

const MinLengthError = (minLength: number | undefined) => (
    <span className='badge bg-gradient-danger mt-2'>
        <small>
            <FormattedMessage
                id='NumberInput.MinLength'
                description='Min length error message'
                defaultMessage={`Minimum length is {minLength}`}
                values={{ minLength: minLength }}
            />
        </small>
    </span>
);

const MaxLengthError = (maxLength: number | undefined) => (
    <span className='badge bg-gradient-danger mt-2'>
        <small>
            <FormattedMessage
                id='NumberInput.maxLength'
                description='Max length error message'
                defaultMessage={`Maximum length is {maxLength}`}
                values={{ maxLength: maxLength }}
            />
        </small>
    </span>
);

const MinMaxLengthError = (minLength: number | undefined, maxLength: number | undefined) => (
    <span className='badge bg-gradient-danger mt-2'>
        <small>
            <FormattedMessage
                id='NumberInput.minMaxLength'
                description='Min max length error message'
                defaultMessage={`Length of the number should be between {minLength} and {maxLength}`}
                values={{ minLength: minLength, maxLength: maxLength }}
            />
        </small>
    </span>
);
