import { APIClient, ApiQueryParams, buildApiEndpoint } from '@frontend/api-utils';
import { ActivityChartData, PackageChartQueryParams } from '@frontend/package/types';

const endpoint = '/package-api/v1';

export class PackageChartClient extends APIClient {
    public static async fetchActivityPerHour(accountId: string, queryParams?: ApiQueryParams<PackageChartQueryParams>): Promise<ActivityChartData> {
        const url = buildApiEndpoint(`${endpoint}/accounts/${accountId}/activity-per-hour`, queryParams);
        const response = await this.fetch(url);
        return await this.handleResponse<ActivityChartData>(response);
    }

    public static async fetchActivityPerDay(accountId: string, queryParams?: ApiQueryParams<PackageChartQueryParams>): Promise<ActivityChartData> {
        const url = buildApiEndpoint(`${endpoint}/accounts/${accountId}/activity-per-day`, queryParams);
        const response = await this.fetch(url);
        return await this.handleResponse<ActivityChartData>(response);
    }
}
