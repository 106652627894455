import { APIClient, ApiQueryParams, DefaultQueryParams } from "@frontend/api-utils";
import { BudgetRule, BudgetRuleListResponse, BudgetRuleQueryParams, CreateBudgetRule, UpdateBudgetRule } from "@frontend/budget/types";

const endpoint = '/budget-api/v1';

export class BudgetRuleClient extends APIClient {
    public static async fetchBudgetRules(queryParams?: ApiQueryParams<DefaultQueryParams & BudgetRuleQueryParams>): Promise<BudgetRuleListResponse> {
        return await this.apiPaginated<BudgetRuleListResponse, DefaultQueryParams & BudgetRuleQueryParams>(`${endpoint}/budget-rules`, queryParams);
    }

    public static async resolveBudgetRules(ids: string[]): Promise<BudgetRuleListResponse> {
        const response = await this.post(`${endpoint}/budget-rules-resolve`, { ids: ids });
        return await this.handleResponse<BudgetRuleListResponse>(response);
    }

    public static async deleteBudgetRules(ids: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/budget-rules-delete`, { ids: ids });
        return await this.handleVoidResponse(response);
    }

    public static async postBudgetRule(accountId: string, budgetId: string, body: CreateBudgetRule): Promise<BudgetRule> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/budgets/${budgetId}/rules`, body);
        return await this.handleResponse<BudgetRule>(response);
    }

    public static async fetchBudgetRule(accountId: string, budgetId: string, budgetRuleId: string): Promise<BudgetRule> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/budgets/${budgetId}/rules/${budgetRuleId}`);
        return await this.handleResponse<BudgetRule>(response);
    }

    public static async patchBudgetRule(accountId: string, budgetId: string, budgetRuleId: string, body: UpdateBudgetRule): Promise<BudgetRule> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/budgets/${budgetId}/rules/${budgetRuleId}`, body);
        return await this.handleResponse<BudgetRule>(response);
    }

    public static async deleteBudgetRule(accountId: string, budgetId: string, budgetRuleId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/budgets/${budgetId}/rules/${budgetRuleId}`);
        return await this.handleVoidResponse(response);
    }
}