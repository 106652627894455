import { WorkflowTrigger, WorkflowTriggerName } from "@frontend/workflow/types";
import React from "react";
import { FormattedMessage } from "react-intl";

export enum EquipmentTriggerName {
    EQUIPMENT_INITIALISED = 'equipment_initialised',
    EQUIPMENT_STATE_CHANGED = 'equipment_state_changed',
    EQUIPMENT_RETURN = 'equipment_return',
    EQUIPMENT_RETURNED = 'equipment_returned',
    EQUIPMENT_WITHDRAW = 'equipment_withdraw',
    EQUIPMENT_WITHDRAWN = 'equipment_withdrawn',
    EQUIPMENT_BROKEN = 'equipment_broken',
    EQUIPMENT_LOST = 'equipment_lost',
    EQUIPMENT_CONTAMINATED = 'equipment_contaminated',
    EQUIPMENT_IDENTIFIED = 'equipment_identified',
    EQUIPMENT_CERTIFICATE_EXPIRED = 'equipment_certificate_expired',
}

export class EquipmentTrigger extends WorkflowTrigger {

    static readonly EQUIPMENT_STATE_CHANGED = new EquipmentTrigger(
        EquipmentTriggerName.EQUIPMENT_STATE_CHANGED,
        (
            <FormattedMessage
                id='EquipmentTrigger.equipment_state_changed'
                description='The displayed value for the EquipmentTrigger equipment_state_changed'
                defaultMessage='Equipment state changed'
            />
        )
    );

    static readonly EQUIPMENT_INITIALISED = new EquipmentTrigger(
        EquipmentTriggerName.EQUIPMENT_INITIALISED,
        (
            <FormattedMessage
                id='EquipmentTrigger.equipment_initialised'
                description='The displayed value for the EquipmentTrigger equipment_initialised'
                defaultMessage='Equipment initialised'
            />
        )
    );

    static readonly EQUIPMENT_RETURN = new EquipmentTrigger(
        EquipmentTriggerName.EQUIPMENT_RETURN,
        (
            <FormattedMessage
                id='EquipmentTrigger.equipment_return'
                description='The displayed value for the EquipmentTrigger equipment_return'
                defaultMessage='Equipment return'
            />
        )
    );

    static readonly EQUIPMENT_RETURNED = new EquipmentTrigger(
        EquipmentTriggerName.EQUIPMENT_RETURNED,
        (
            <FormattedMessage
                id='EquipmentTrigger.equipment_returned'
                description='The displayed value for the EquipmentTrigger equipment_returned'
                defaultMessage='Equipment returned'
            />
        )
    );

    static readonly EQUIPMENT_WITHDRAW = new EquipmentTrigger(
        EquipmentTriggerName.EQUIPMENT_WITHDRAW,
        (
            <FormattedMessage
                id='EquipmentTrigger.equipment_withdraw'
                description='The displayed value for the EquipmentTrigger equipment_withdraw'
                defaultMessage='Equipment withdraw'
            />
        )
    );

    static readonly EQUIPMENT_WITHDRAWN = new EquipmentTrigger(
        EquipmentTriggerName.EQUIPMENT_WITHDRAWN,
        (
            <FormattedMessage
                id='EquipmentTrigger.equipment_withdrawn'
                description='The displayed value for the EquipmentTrigger equipment_withdrawn'
                defaultMessage='Equipment withdrawn'
            />
        )
    );

    static readonly EQUIPMENT_BROKEN = new EquipmentTrigger(
        EquipmentTriggerName.EQUIPMENT_BROKEN,
        (
            <FormattedMessage
                id='EquipmentTrigger.equipment_broken'
                description='The displayed value for the EquipmentTrigger equipment_broken'
                defaultMessage='Equipment broken'
            />
        )
    );

    static readonly EQUIPMENT_LOST = new EquipmentTrigger(
        EquipmentTriggerName.EQUIPMENT_LOST,
        (
            <FormattedMessage
                id='EquipmentTrigger.equipment_lost'
                description='The displayed value for the EquipmentTrigger equipment_lost'
                defaultMessage='Equipment lost'
            />
        )
    );

    static readonly EQUIPMENT_CONTAMINATED = new EquipmentTrigger(
        EquipmentTriggerName.EQUIPMENT_CONTAMINATED,
        (
            <FormattedMessage
                id='EquipmentTrigger.equipment_contaminated'
                description='The displayed value for the EquipmentTrigger equipment_contaminated'
                defaultMessage='Equipment contaminated'
            />
        )
    );

    static readonly EQUIPMENT_IDENTIFIED = new EquipmentTrigger(
        EquipmentTriggerName.EQUIPMENT_IDENTIFIED,
        (
            <FormattedMessage
                id='EquipmentTrigger.equipment_identified'
                description='The displayed value for the EquipmentTrigger equipment_identified'
                defaultMessage='Equipment identified'
            />
        )
    );

    static readonly EQUIPMENT_CERTIFICATE_EXPIRED = new EquipmentTrigger(
        EquipmentTriggerName.EQUIPMENT_CERTIFICATE_EXPIRED,
        (
            <FormattedMessage
                id='EquipmentTrigger.equipment_certificate_expired'
                description='The displayed value for the EquipmentTrigger equipment_certificate_expired'
                defaultMessage='Equipment certificate expired'
            />
        )
    );

    static override readonly ALL = [
        EquipmentTrigger.EQUIPMENT_INITIALISED,
        EquipmentTrigger.EQUIPMENT_STATE_CHANGED,
        EquipmentTrigger.EQUIPMENT_RETURN,
        EquipmentTrigger.EQUIPMENT_WITHDRAW,
        EquipmentTrigger.EQUIPMENT_WITHDRAWN,
        EquipmentTrigger.EQUIPMENT_RETURNED,
        EquipmentTrigger.EQUIPMENT_BROKEN,
        EquipmentTrigger.EQUIPMENT_LOST,
        EquipmentTrigger.EQUIPMENT_CONTAMINATED,
        EquipmentTrigger.EQUIPMENT_IDENTIFIED,
        EquipmentTrigger.EQUIPMENT_CERTIFICATE_EXPIRED,
        ...super.ALL
    ];

    static override getByValue(trigger: EquipmentTriggerName | WorkflowTriggerName): WorkflowTrigger | undefined {
        return this.ALL.find((a) => a.value == trigger);
    }
}