import { DocType, IMAGE_MIME_TYPES, TEXT_MIME_TYPES, VIDEO_MIME_TYPES } from '@frontend/repository';
import React, { JSX, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { DropZoneProps } from './drop-zone.component';

interface ViewProps {
    dropZoneRef: React.RefObject<HTMLDivElement | null>;
    isDropping: boolean;
    accept?: string;
    error?: JSX.Element | null;
    allowedTypes?: string | null;
}

const useDropZone = (props: DropZoneProps): ViewProps => {
    const dropZoneRef = useRef<HTMLDivElement | null>(null);
    const [isDropping, changeIsDropping] = useState<boolean>(false);
    const [error, changeError] = useState<JSX.Element | null>(null);
    const accept = useMemo<string | undefined>(() => {
        if (props.type == undefined) return undefined;
        if (Array.isArray(props.type)) {
            if (props.type.length === 0) return '';
            return props.type.join(', ');
        }
        if (typeof props.type === 'string') return props.type;
        return props.type.toString();
    }, [props.type]);

    useEffect(() => {
        if (!dropZoneRef) return;
        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
            dropZoneRef.current?.addEventListener(eventName, preventDefaults, false);
        });
        ['dragenter', 'dragover'].forEach((eventName) => {
            dropZoneRef.current?.addEventListener(eventName, () => changeIsDropping(true), false);
        });
        ['dragleave', 'drop'].forEach((eventName) => {
            dropZoneRef.current?.addEventListener(eventName, () => changeIsDropping(false), false);
        });
        dropZoneRef.current?.addEventListener('drop', handleDrop, false);
    }, [dropZoneRef, props.file]);

    function preventDefaults(e: any) {
        e.preventDefault();
        e.stopPropagation();
    }

    function handleDrop(e: DragEvent) {
        const droppedFile = e.dataTransfer;
        if (!droppedFile) return;
        if (droppedFile.files.length > 1) {
            changeError(
                <FormattedMessage
                    id='DropZone.ErrorMessages.OnlyOneFile'
                    defaultMessage='Only 1 file can be uploaded at a time.'
                />
            );
            return;
        }
        const file = droppedFile.files.item(0);
        if (!file) return;
        if (Array.isArray(props.type)) {
            if (!(props.type as string[]).includes(file.type)) {
                changeError(
                    <FormattedMessage
                        id='DropZone.ErrorMessages.InvalidFileType'
                        defaultMessage='Invalid file type. Please upload a file of type {type}.'
                        values={{ type: props.type.join(', ') }}
                    />
                );
                return;
            }
        } else if (typeof props.type === 'string') {
            if (!file.type.includes(props.type)) {
                changeError(
                    <FormattedMessage
                        id='DropZone.ErrorMessages.InvalidFileType'
                        defaultMessage='Invalid file type. Please upload a file of type {type}.'
                        values={{ type: props.type }}
                    />
                );
                return;
            }
        }
        changeError(null);
        props.onUpload && props.onUpload(file);
        props.onChange(file);
    }

    return {
        dropZoneRef,
        isDropping,
        accept,
        error,
        allowedTypes: formatDisplayableDocTypes(props.type)
    };
};

export default useDropZone;

const formatDisplayableDocTypes = (type?: DocType | DocType[] | string | string[]): string | null => {
    if (type === undefined) return null;
    return getDisplayableDocTypes(type).replace(/(image\/|video\/|text\/|application\/)/g, '.');
};

const getDisplayableDocTypes = (type: DocType | DocType[] | string | string[]): string => {
    if (typeof type === 'string') {
        return type;
    }

    if (Array.isArray(type)) {
        return type
            .map((item) => {
                if (typeof item === 'string') {
                    return item;
                } else {
                    return getTypesFromDocType(item);
                }
            })
            .join(', ');
    }

    return getTypesFromDocType(type);
};

function getTypesFromDocType(type: DocType): string {
    switch (type) {
        case DocType.IMAGE:
            return IMAGE_MIME_TYPES.join(', ');
        case DocType.VIDEO:
            return VIDEO_MIME_TYPES.join(', ');
        case DocType.TEXT:
            return TEXT_MIME_TYPES.join(', ');
        default:
            return '';
    }
}
