import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

const SlotTemplateOverview = lazy(() => import('../slot-template/slot-template-overview'));
const SlotTemplateCreate = lazy(() => import('../slot-template/slot-template-create/slot-template-create.component'));
const SlotTemplateDetail = lazy(() => import('../slot-template/slot-template-detail/slot-template-detail.component'));
const SlotTemplateUpdate = lazy(() => import('../slot-template/slot-template-update/slot-template-update.component'));
const SlotTemplateCopy = lazy(() => import('../slot-template/slot-template-copy/slot-template-copy.component'));

export function SlotTemplateRoutes() {
    return (
        <Route path='slot-templates'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <SlotTemplateOverview />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <SlotTemplateCreate />
                    </AsyncComponent>
                }
            />
            <Route path=':slotTemplateId'>
                <Route
                    index
                    path='detail'
                    element={
                        <AsyncComponent>
                            <SlotTemplateDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <SlotTemplateUpdate />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='copy'
                    element={
                        <AsyncComponent>
                            <SlotTemplateCopy />
                        </AsyncComponent>
                    }
                />
            </Route>
            <Route
                index
                element={<Navigate to='/admin/slot-templates' />}
            />
        </Route>
    );
}
