import { ErrorHandler } from '@frontend/error-handler';
import { clear, useAccountRepository } from '@frontend/repository';
import { ToastUtil } from '@frontend/toast-utils';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { EntityType } from '@frontend/common';
import { MqttBroker } from '@frontend/pub-sub';
import { ObjectDetailProps } from './object-detail.component';

interface ViewProps<T> {
    object: T | null;
    showDeleteModal: boolean;
    changeShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
    onDeleteConfirmed: () => void;
    onUpdate: () => void;
}

const useObjectDetail = <T>(props: ObjectDetailProps<T>): ViewProps<T> => {
    const navigate = useNavigate();
    const params = useParams();
    const { accountId } = useParams();
    const { store: { currentAccount } } = useAccountRepository();
    const [object, changeObject] = useState<T | null>(null);
    const [showDeleteModal, changeShowDeleteModal] = useState<boolean>(false);
    const urlParams = useMemo(() => props.urlParamKeys.map((key) => params[key]), [props.urlParamKeys, params]);
    const [id] = useState<string>(crypto.randomUUID());

    useEffect(() => {
        if (object) {
            props.object && props.object(object);
        }
    }, [object]);

    const onFetch = () => {
        if (urlParams.includes(undefined)) {
            ToastUtil.error('Something went wrong trying to get the correct information.');
            navigate(props.baseRoute.startsWith('/') ? props.baseRoute : '/admin/' + props.baseRoute);
        } else
            props
                .fetch(...urlParams)
                .then(changeObject)
                .catch(ErrorHandler.handleError);
    };
    
    useEffect(() => {
        onFetch();
        if (props.eventListener == undefined || !props.dispatch) return;
        const onEvent = (message: string, data: any) => {
            if (message.includes('created') || message.includes('update')) onFetch();
        };
        const topic = props.eventListener === EntityType.ACCOUNT ? 'account' : 'accounts/' + currentAccount + '/' + props.eventListener;
        MqttBroker.getInstance().subscribe(id, topic, onEvent);
        return () => {
            MqttBroker.getInstance().unSubscribe(id, props.eventListener as EntityType);
            props.dispatch!(clear(id));
        };
    }, [currentAccount]);

    useEffect(() => {
        if (accountId !== undefined && currentAccount && accountId !== currentAccount) {
            navigate(props.baseRoute.startsWith('/') ? props.baseRoute : '/admin/' + props.baseRoute);
        }
    }, [currentAccount]);

    const onDeleteConfirmed = () => {
        props.onDelete && props.onDelete(...urlParams).then(() => {
            changeShowDeleteModal(false);
            if (props.onDeleteSuccess) props.onDeleteSuccess();
            else navigate('../../');
        });
    };

    const onUpdate = () => {
        if (props.onUpdate) props.onUpdate();
        else {
            const urlKeys = props.updateKeys ? props.updateKeys : props.urlParamKeys;
            const urlParts = urlKeys.map(
                (key) =>
                    '/' +
                    (props.navigationKeyReplacements && props.navigationKeyReplacements[key]
                        ? props.navigationKeyReplacements[key] + '/'
                        : key.replace('Id', 's/')) +
                    params[key]
            );
            if (location.pathname.startsWith('/admin')) navigate('/admin' + urlParts.join('') + '/update');
            else navigate(urlParts.join('') + '/update');
        }
    };

    return {
        object,
        showDeleteModal,
        changeShowDeleteModal,
        onDeleteConfirmed,
        onUpdate
    };
};

export default useObjectDetail;
