import { Locale, convertLanguage } from '@frontend/common';
import { getCorrectTranslation, useLocale } from '@frontend/lang';
import { ObjectBadgeMapper } from '@frontend/rendering/details';
import { DateTimeCell } from '@frontend/table';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { LuAsterisk } from 'react-icons/lu';
import { CellProps, Row } from 'react-table';

import { ObjectColumnsProps } from '../object-column-mapper';
import { AsyncBlobCell } from './async-blob-cell';
import { AsyncDocumentCell } from './async-document-cell';
import { BooleanCell } from './boolean-cell';

export interface ObjectCellMapperProps<T extends { id: string } & object> extends CellProps<T, any> {
    className?: string | ((row: Row<T>) => string);
    parent: ObjectColumnsProps<T>;
    cellKey: keyof T | string;
}

export const ObjectCellMapper = <T extends { id: string } & object>(props: ObjectCellMapperProps<T>) => {
    const { locale } = useLocale();
    if (typeof props.cellKey === 'string' && props.parent.customCells && props.parent.customCells.has(props.cellKey)) {
        return <>{props.parent.customCells.get(props.cellKey)!(props)}</>;
    }

    const userLocale = locale ? locale.toLowerCase().replace(/-/g, '_') : undefined;
    const className = props.className ? (typeof props.className === 'string' ? props.className : props.className(props.row)) : undefined;
    if (Object.keys(props.row.original).includes((props.cellKey as string) + '_' + userLocale)) {
        return (
            <td className={className || 'text-xs text-primary align-content-center'}>
                {getCorrectTranslation(locale, props.row.original, props.cellKey as keyof T)}
            </td>
        );
    } else {
        const key =
            props.parent.valueOverwrite && props.parent.valueOverwrite.has(props.cellKey as keyof T)
                ? props.parent.valueOverwrite.get(props.cellKey as keyof T)!
                : props.cellKey;
        const obj = props.row.original;
        if (typeof key != 'string') return <></>;
        if (props.parent.blobColumnOverwrite && props.parent.blobColumnOverwrite.has(key as keyof T)) {
            return (
                <AsyncBlobCell
                    object={obj}
                    fetchBlob={props.parent.blobColumnOverwrite.get(key as keyof T)!}
                />
            );
        }
        if (props.parent.documentColumnOverwrite && props.parent.documentColumnOverwrite.key === key) {
            return (
                <AsyncDocumentCell
                    object={obj}
                    objectKey={props.parent.documentColumnOverwrite!.key}
                    entityType={props.parent.documentColumnOverwrite!.entityType}
                />
            );
        }
        if (props.parent.entityColumnOverwrite && props.parent.entityColumnOverwrite.has(key as keyof T)) {
            const extraProps = props.parent.entityColumnOverwriteProps?.get(key as keyof T);
            const type = (obj[props.parent.entityColumnOverwrite.get(key as keyof T)!] as string)?.endsWith('_id')
                ? (obj[props.parent.entityColumnOverwrite.get(key as keyof T)!] as string)
                : (obj[props.parent.entityColumnOverwrite.get(key as keyof T)!] as string) + '_id';
            return (
                <td className={'align-content-center'}>
                    <ObjectBadgeMapper
                        id={obj[key as keyof T] as string}
                        type={type}
                        {...extraProps}
                    />
                </td>
            );
        }
        if (key === 'id') {
            if (props.parent.showIdAsEntity) {
                return (
                    <td className={'align-content-center'}>
                        <ObjectBadgeMapper
                            id={obj[key as keyof T] as string}
                            type={props.parent.showIdAsEntity + '_id'}
                        />
                    </td>
                );
            }
            return (
                <td className={className || 'text-xs text-primary align-content-center'}>
                    <p>{obj[key] as string}</p>
                </td>
            );
        }
        if (key.includes('id') && key.length > 2 && key !== 'forbidden' && !key.includes('identifier')) {
            return (
                <td className={'align-content-center'}>
                    <ObjectBadgeMapper
                        id={obj[key as keyof T] as string}
                        type={key}
                    />
                </td>
            );
        }
        if (key.includes('date') || key.includes('timestamp')) {
            return (
                <DateTimeCell
                    className={className}
                    date={obj[key as keyof T] as string}
                    displayTime
                    inline
                />
            );
        }
        if (typeof obj[key as keyof T] === 'boolean') {
            return (
                <BooleanCell
                    className={className}
                    value={obj[key as keyof T] as boolean}
                />
            );
        }
        if (key === 'secret') {
            const icon = <LuAsterisk size={10} />;
            return <td className='text-xs text-primary align-content-center'>{Array(8).fill(icon)}</td>;
        }
        if (key === 'language') {
            return <td className={className || 'text-xs text-primary align-content-center'}>{convertLanguage(obj[key as keyof T] as Locale)}</td>;
        }
        if (Array.isArray(obj[key as keyof T])) {
            const content = obj[key as keyof T] as unknown[];
            return (
                <OverlayTrigger
                    overlay={<Tooltip id={'tooltip'}>{(obj[key as keyof T] as unknown[]).join('\n')}</Tooltip>}
                    placement='auto'
                    delay={{ show: 300, hide: 150 }}>
                    <td className={className || 'text-xs text-primary align-content-center text-truncate'}>{content.length}</td>
                </OverlayTrigger>
            );
        }
        if (typeof obj[key as keyof T] === 'string') {
            return <td className={className || 'text-xs text-primary align-content-center text-truncate'}>{(obj[key as keyof T] as string) ?? ''}</td>;
        }

        if (obj[key as keyof T] === null) {
            return <td className={className || 'text-xs text-primary align-content-center text-truncate'}></td>;
        }
        return <td className={className || 'text-xs text-primary align-content-center text-truncate'}>{JSON.stringify(obj[key as keyof T])}</td>;
    }
};
