import React from 'react';
import { IconType } from 'react-icons';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';

import useNavbarGroupItemController from './navbar-group-item.controller';

export interface NavbarGroupItemProps {
    title: React.ReactNode;
    link: string;
    children: React.ReactNode;
    navbarRef: React.RefObject<HTMLDivElement | null>;
    icon: IconType;
    enabled?: boolean;
}

export const NavbarGroupItem = (props: NavbarGroupItemProps) => {
    const viewProps = useNavbarGroupItemController(props);

    return (
        <>
            <li
                className={viewProps.enabled ? `nav-item cursor-pointer` : 'nav-item cursor-disabled'}
                ref={viewProps.navbarGroupItemRef}>
                <div
                    className={`nav-link d-flex justify-content-${viewProps.expanded ? 'between' : 'center'} ps-1`}
                    onClick={viewProps.handleTitleClick}>
                    <div className='icon icon-sm d-flex align-items-center justify-content-start'>
                        <i className={`text-${viewProps.enabled ? 'primary' : 'secondary'} fs-${viewProps.expanded ? '6' : '5'} opacity-10`}>
                            <props.icon />
                        </i>
                        {viewProps.expanded && <span className={`nav-link-text m-2 ms-3`}>{props.title}</span>}
                    </div>
                    {viewProps.expanded && viewProps.enabled && (
                        <div className='d-flex justify-content-end'>{viewProps.openIndex != null ? <FaChevronDown /> : <FaChevronRight />}</div>
                    )}
                </div>
            </li>
            {viewProps.openIndex !== null && (
                <div
                    className='d-flex navbar-group-items flex-column ps-2'
                    ref={viewProps.navbarItemRef}>
                    {props.children}
                </div>
            )}
        </>
    );
};
