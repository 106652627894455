import { APIClient, ApiQueryParams, DefaultQueryParams } from "@frontend/api-utils";
import { AddressEntity, AddressEntityListResponse, AddressEntityQueryParams, CreateAddressEntity } from "@frontend/address/types";

const endpoint = '/address-api/v1';

export class AddressEntityClient extends APIClient {
    public static async fetchAddressEntities(queryParams?: ApiQueryParams<DefaultQueryParams & AddressEntityQueryParams>): Promise<AddressEntityListResponse> {
        return await this.apiPaginated<AddressEntityListResponse, DefaultQueryParams & AddressEntityQueryParams>(`${endpoint}/address-entities`, queryParams);
    }

    public static async resolveAddressEntities(ids: string[]): Promise<AddressEntityListResponse> {
        const response = await this.post(`${endpoint}/address-entities-resolve`, { ids: ids });
        return await this.handleResponse<AddressEntityListResponse>(response);
    }

    public static async deleteAddressEntities(ids: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/address-entities-delete`, { ids: ids });
        return await this.handleVoidResponse(response);
    }

    public static async postAddressEntity(accountId: string, addressId: string, body: CreateAddressEntity): Promise<AddressEntity> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/addresses/${addressId}/entities`, body);
        return await this.handleResponse<AddressEntity>(response);
    }

    public static async fetchAddressEntity(accountId: string, addressId: string, addressEntityId: string): Promise<AddressEntity> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/addresses/${addressId}/entities/${addressEntityId}`);
        return await this.handleResponse<AddressEntity>(response);
    }

    public static async deleteAddressEntity(accountId: string, addressId: string, addressEntityId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/addresses/${addressId}/entities/${addressEntityId}`);
        return await this.handleVoidResponse(response);
    }
}
