import { EventListener } from '@frontend/pub-sub';
import { PuDoTransaction } from '@frontend/transaction/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

export class PuDoTransactionEventListener extends EventListener<PuDoTransaction> {
    
    private static instance: PuDoTransactionEventListener | null = null;
    private constructor(dispatch: ThunkDispatch<any, any, Action>, variables?: Array<{ [key: string]: any }>) {
        super('transaction', dispatch, variables);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>, variables?: Array<{ [key: string]: any }>): PuDoTransactionEventListener {
        if (this.instance == null) {
            this.instance = new PuDoTransactionEventListener(dispatch, variables);
        }
        return this.instance;
    }

    getInstance(dispatch: ThunkDispatch<any, any, Action>, variables?: Array<{ [key: string]: any }>): EventListener<PuDoTransaction> {
        return PuDoTransactionEventListener.getInstance(dispatch, variables);
    }

    protected onUpdate() {}
    protected onDelete() {}
    protected onCreate() {}
}