import { APIClient } from "@frontend/api-utils";

const endpoint = '/impex-api/v1'

export class ImpexEntityTypeEnumClient extends APIClient {
    public static async fetchEntityTypes(): Promise<string[]> {
        const response = await this.fetch(`${endpoint}/entity-types`);
        return this.handleResponse<string[]>(response);
    }
}
