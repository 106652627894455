import { EntityType } from '@frontend/common';
import { ObjectBadge, ObjectBadgeProps } from '@frontend/rendering/badge';
import { variantRequest } from '@frontend/variant/events';
import { Variant } from '@frontend/variant/types';
import React from 'react';

export const VariantBadge = (props: ObjectBadgeProps<Variant>) => {
    return (
        <ObjectBadge<Variant>
            id={props.id}
            type={EntityType.VARIANT}
            mapDisplayName={(variant: Variant) => variant.name}
            resolve={variantRequest}
            key={props.id}
            navigateString={(object: Variant) => `/accounts/${object.account_id}/products/${object.product_id}/variants/${object.id}`}
            {...props}
        />
    );
};
