import React from 'react';

import { Option } from '../option';
import { Select } from '../select';
import { SelectOption, SingleOption, isSingleOption } from '../select-types';
import useCreatableSelectV2 from './creatable-select-v2.controller';

const ID = 'creatable-select-v2';

export interface CreatableSelectV2Props<T> {
    id?: string;
    label?: React.ReactNode;
    helpMessage?: React.ReactNode;

    options: SelectOption<T>[];
    onSelect?: (option: SingleOption<T> | null) => void;
    value?: string | null;
    searchValueOverwrite?: string;

    onSearch?: (value: string) => void;
    onScrollToBottom?: () => void;

    required?: boolean;
    disabled?: boolean;

    isClearable?: boolean;
    isLoading?: boolean;

    className?: string;

    onFocus?: React.FocusEventHandler<HTMLInputElement> | undefined;
    onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
    placeholder?: string;
}

export const CreatableSelectV2 = <T,>(props: CreatableSelectV2Props<T>) => {
    const viewProps = useCreatableSelectV2<T>(props);

    return (
        <div
            id={ID}
            className={'form-group w-100 ' + props.className}>
            <label className='text-primary'>
                {props.required ? <span className='text-danger me-1'>&#9679;</span> : <></>} {props.label}
                {props.helpMessage && (
                    <>
                        <br />
                        <small className='form-text text-muted'>{props.helpMessage}</small>
                    </>
                )}
            </label>
            <Select
                inputRefCallback={viewProps.setRef}
                id={props.id || ID}
                onSelect={viewProps.onSelect}
                value={viewProps.value}
                onChange={viewProps.onSearch}
                isClearable={props.isClearable}
                onScrollToBottom={props.onScrollToBottom}
                placeholder={props.placeholder}
                onFocus={props.onFocus}
                onBlur={props.onBlur}>
                {viewProps.searchResultOptions &&
                    viewProps.searchResultOptions.map((option) => {
                        if (isSingleOption(option)) {
                            return (
                                <Option
                                    key={option.value}
                                    selected={viewProps.selected?.value === option.value}
                                    {...option}
                                />
                            );
                        }
                        return (
                            <>
                                {option.label}
                                {option.options.map((option: SingleOption<T>) => (
                                    <Option
                                        key={option.value}
                                        selected={viewProps.selected?.value === option.value}
                                        {...option}
                                    />
                                ))}
                            </>
                        );
                    })}
            </Select>
        </div>
    );
};
