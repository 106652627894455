import { Buttons } from './Buttons';
import { Dropzone } from './Dropzone';
import { Errors } from './Errors';
import { Events } from './Events';
import { Forms } from './Forms';
import { Lists } from './List';
import { Navigation } from './Navigation';
import { Objects } from './Objects';
import { Toasts } from './Toasts';

export const CommonMessage = {
    NAVIGATION: Navigation,
    OBJECTS: Objects,
    FORMS: Forms,
    BUTTONS: Buttons,
    EVENTS: Events,
    LISTS: Lists,
    DROPZONE: Dropzone,
    TOAST: Toasts,
    ERRORS: Errors
};
