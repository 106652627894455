import { useLocation } from 'react-router-dom';

interface ViewProps {
    isDetailPage: boolean;
    isEntityPage?: boolean;
    isEntityBadge?: boolean;
    isAdminRoute: boolean;
}

export const useDetailPage = (type: DetailPageType, entity?: string | null): ViewProps => {
    const { pathname, search } = useLocation();
    return {
        isDetailPage: pathname.includes('detail') && pathname.includes(type),
        isEntityPage: entity
            ? pathname.includes(entity) && (pathname.split(entity)[1] == null || pathname.split(entity)[1].startsWith('/detail')) && pathname.includes(type)
            : undefined,
        isEntityBadge: entity ? search.includes(entity) : undefined,
        isAdminRoute: pathname.split('/')[1].includes('admin')
    };
};

export enum DetailPageType {
    ACCOUNT = 'account',
    WORKFLOW = 'workflow',
    TRANSACTION = 'transaction',
    CONTACT = 'contact',
    USER = 'user',
    TERMINAL = 'terminal',
    SPOT = 'spot',
    SLOT = 'slot',
    RAKINDA = 'rakinda',
    BADGE = 'badge',
    TEMPLATE = 'template',
    USER_INTERFACE = 'user-interface',
    PRODUCT = 'product',
    PACKAGE = 'package',
    PRODUCT_CATEGORY = 'product-categories',
    IMPORT_LOG = 'import-log',
    CERTIFICATE = 'certificate'
}
