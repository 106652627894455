import { EntityType } from '@frontend/common';
import { notificationContentRequest } from '@frontend/notification-service/events';
import { NotificationContent } from '@frontend/notification-service/types';
import { ObjectBadge, ObjectBadgeProps } from '@frontend/rendering/badge';
import React from 'react';

export const NotificationContentBadge = (props: ObjectBadgeProps<NotificationContent>) => {
    return (
        <ObjectBadge<NotificationContent>
            id={props.id}
            type={EntityType.NOTIFICATION_CONTENT}
            mapDisplayName={(obj: NotificationContent) => obj.subject ?? obj.id}
            resolve={notificationContentRequest}
            navigateString={(object: NotificationContent) => `/accounts/${object.account_id}/users/${object.id}`}
            {...props}
        />
    );
};
