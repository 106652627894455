import { EntityType } from '@frontend/common';
import { UserGroupClient } from '@frontend/group/api';
import { useResolver } from '@frontend/repository';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

declare global {
    interface WindowEventMap {
        'useUserGroupMember:request': CustomEvent<string>;
    }
}

const REQUEST_USER_GROUP_MEMBER_EVENT = 'useUserGroupMember:request';
const STORAGE_KEY = 'USER_GRP_MBR_REQ';

export function userGroupMemberRequest(memberId: string): string {
    window.dispatchEvent(new CustomEvent(REQUEST_USER_GROUP_MEMBER_EVENT, { detail: memberId }));
    return STORAGE_KEY + '_loading';
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function userGroupMemberResolver(props: Props) {
    useResolver(STORAGE_KEY, REQUEST_USER_GROUP_MEMBER_EVENT, EntityType.MEMBER, (ids) => UserGroupClient.resolveUserGroupMembers(ids), props.dispatch);
    return <></>;
}
