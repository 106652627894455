import { EntityType } from '@frontend/common';
import { IconType } from 'react-icons';
import { BiDollarCircle, BiSolidCategory, BiSolidDoorOpen } from 'react-icons/bi';
import { BsBoxes, BsBuildingLock, BsFillGrid1X2Fill } from 'react-icons/bs';
import { CgKeyboard } from 'react-icons/cg';
import { FaFileExport, FaFileImport, FaSitemap, FaStore, FaTools, FaUserLock, FaUsers } from 'react-icons/fa';
import { FaCircleDot, FaCubesStacked, FaFlaskVial, FaLocationDot } from 'react-icons/fa6';
import { GiEntryDoor } from 'react-icons/gi';
import { GoProjectTemplate } from 'react-icons/go';
import { HiDeviceTablet, HiOutlineIdentification } from 'react-icons/hi';
import { LiaCertificateSolid } from 'react-icons/lia';
import { LuBoxes, LuBuilding, LuContact2, LuHardDrive, LuLayoutTemplate, LuPackage, LuUsers2, LuWorkflow } from 'react-icons/lu';
import { MdEvent, MdMoveDown, MdOutlineCloudSync, MdOutlineReportProblem, MdSettings } from 'react-icons/md';
import { PiCoins } from 'react-icons/pi';
import { RiContactsBook2Fill, RiDeviceLine, RiKey2Fill } from 'react-icons/ri';
import { TbDatabaseExport, TbGitBranch, TbLogs } from 'react-icons/tb';

export const ENTITY_ICONS = {
    ACCOUNT: LuBuilding,
    WORKFLOW: LuWorkflow,
    PACKAGE: LuPackage,
    TRANSACTION: TbGitBranch,
    USER: LuUsers2,
    DEVICE: HiDeviceTablet,
    IOT: LuHardDrive,
    RAKINDA: CgKeyboard,
    ROLE: RiKey2Fill,
    CONTACT: LuContact2,
    NOTIFICATION_TEMPLATE: LuLayoutTemplate,
    BADGE: HiOutlineIdentification,
    STOCK: FaCubesStacked,
    SPOT: FaLocationDot,
    MODULE: BsFillGrid1X2Fill,
    SLOT: BiSolidDoorOpen,
    PRODUCT: BsBoxes,
    USER_INTERFACE: GoProjectTemplate,
    SEED: TbDatabaseExport,
    INTEGRATION_TEST: FaFlaskVial,
    CHANGE: MdOutlineCloudSync,
    EVENT: MdEvent,
    BASIC_AUTH: FaUserLock,
    MOVEMENT: MdMoveDown,
    CATEGORY: BiSolidCategory,
    PRODUCT_CATALOG: FaStore,
    ACCESS_LOG: GiEntryDoor,
    SETTINGS: MdSettings,
    IMPORT_LOGS: TbLogs,
    CERTIFICATE: LiaCertificateSolid,
    IMPORT: FaFileImport,
    EXPORT: FaFileExport,
    COST_CENTER: BiDollarCircle,
    BUDGET: PiCoins,
    GROUP: FaSitemap,
    EQUIPMENT: RiDeviceLine,
    PROBLEM: MdOutlineReportProblem,
    PRODUCT_GROUP: LuBoxes,
    CONTACT_GROUP: RiContactsBook2Fill,
    EQUIPMENT_GROUP: FaTools,
    USER_GROUP: FaUsers,
    SLOT_GROUP: BsBuildingLock
};

export function iconByType(type: EntityType): IconType {
    switch (type) {
        case EntityType.ACCOUNT:
            return ENTITY_ICONS.ACCOUNT;
        case EntityType.WORKFLOW:
            return ENTITY_ICONS.WORKFLOW;
        case EntityType.PACKAGE:
            return ENTITY_ICONS.PACKAGE;
        case EntityType.TRANSACTION:
            return ENTITY_ICONS.TRANSACTION;
        case EntityType.USER:
            return ENTITY_ICONS.USER;
        case EntityType.DEVICE:
            return ENTITY_ICONS.DEVICE;
        case EntityType.IOT:
            return ENTITY_ICONS.IOT;
        case EntityType.RAKINDA:
            return ENTITY_ICONS.RAKINDA;
        case EntityType.CONTACT:
            return ENTITY_ICONS.CONTACT;
        case EntityType.NOTIFICATION_TEMPLATE:
            return ENTITY_ICONS.NOTIFICATION_TEMPLATE;
        case EntityType.BADGE:
            return ENTITY_ICONS.BADGE;
        case EntityType.STOCK:
            return ENTITY_ICONS.STOCK;
        case EntityType.SPOT:
            return ENTITY_ICONS.SPOT;
        case EntityType.MODULE:
            return ENTITY_ICONS.MODULE;
        case EntityType.SLOT:
            return ENTITY_ICONS.SLOT;
        case EntityType.PRODUCT:
            return ENTITY_ICONS.PRODUCT;
        case EntityType.USER_INTERFACE:
            return ENTITY_ICONS.USER_INTERFACE;
        case EntityType.SEED:
            return ENTITY_ICONS.SEED;
        case EntityType.INTEGRATION_TEST:
            return ENTITY_ICONS.INTEGRATION_TEST;
        case EntityType.CHANGE:
            return ENTITY_ICONS.CHANGE;
        case EntityType.EVENT:
            return ENTITY_ICONS.EVENT;
        case EntityType.BASIC_AUTH:
            return ENTITY_ICONS.BASIC_AUTH;
        case EntityType.MOVEMENT:
            return ENTITY_ICONS.MOVEMENT;
        case EntityType.CATEGORY:
            return ENTITY_ICONS.CATEGORY;
        case EntityType.PRODUCT_CATALOG:
            return ENTITY_ICONS.PRODUCT_CATALOG;
        case EntityType.ACCESS_LOG:
            return ENTITY_ICONS.ACCESS_LOG;
        case EntityType.SETTINGS:
            return ENTITY_ICONS.SETTINGS;
        case EntityType.CERTIFICATE:
            return ENTITY_ICONS.CERTIFICATE;
        case EntityType.COST_CENTER:
            return ENTITY_ICONS.COST_CENTER;
        case EntityType.BUDGET:
            return ENTITY_ICONS.BUDGET;
        case EntityType.GROUP:
            return ENTITY_ICONS.GROUP;
        case EntityType.EQUIPMENT:
            return ENTITY_ICONS.EQUIPMENT;
        case EntityType.PROBLEM:
            return ENTITY_ICONS.PROBLEM;
        case EntityType.ROLE:
            return ENTITY_ICONS.ROLE;
        case EntityType.PRODUCT_GROUP:
            return ENTITY_ICONS.PRODUCT_GROUP;
        case EntityType.CONTACT_GROUP:
            return ENTITY_ICONS.CONTACT_GROUP;
        case EntityType.EQUIPMENT_GROUP:
            return ENTITY_ICONS.EQUIPMENT_GROUP;
        case EntityType.USER_GROUP:
            return ENTITY_ICONS.USER_GROUP;
        case EntityType.SLOT_GROUP:
            return ENTITY_ICONS.SLOT_GROUP;
        default:
            return FaCircleDot;
    }
}
