import { EventListener } from '@frontend/pub-sub';
import { Answer } from '@frontend/question/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

enum MessageTypes {
    UPDATED = 'answer_updated',
    DELETED = 'answer_deleted',
    CREATED = 'answer_created'
}

export class AnswerEventListener extends EventListener<Answer> {
    private static instance: AnswerEventListener | null = null;
    private constructor(dispatch: ThunkDispatch<any, any, Action>) {
        super('question', dispatch);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>): AnswerEventListener {
        if (this.instance == null) {
            this.instance = new AnswerEventListener(dispatch);
        }
        return this.instance;
    }

    getInstance(dispatch: ThunkDispatch<any, any, Action>): EventListener<Answer> {
        return AnswerEventListener.getInstance(dispatch);
    }

    protected onUpdate() {}

    protected onDelete() {}

    protected onCreate() {}
}
