import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import { Row as ReactTableRow } from 'react-table';

import ExpandCell, { ExpandCellProps } from '../cell/expand-cell/expand-cell.component';
import OptionsCell from '../cell/options-cell/options-cell.component';
import SelectCell from '../cell/select-cell/select-cell.component';

interface RowProps<T extends object> extends ReactTableRow<T> {
    listStoreId: string;
    dispatch: ThunkDispatch<any, any, Action>;
    hover?: boolean;
    onClick?: () => void;
    expand?: ExpandCellProps<T>;
    selected?: (items: T[]) => void;
    options?: React.ReactNode;
}

export const Row = <T extends object>(props: RowProps<T>) => {
    return (
        <tr className={`${props.hover ? 'hover' : ''} ${props.onClick ? 'cursor-pointer' : ''}`}>
            {props.selected && (
                <SelectCell
                    listStoreId={props.listStoreId}
                    dispatch={props.dispatch}
                    {...props.getToggleRowExpandedProps()}
                />
            )}
            {props.expand && (
                <ExpandCell
                    {...props.getToggleRowExpandedProps()}
                    expendable={props.expand.expendable}
                    row={props}
                />
            )}
            {props.cells.map((cell) => {
                return cell.render('Cell');
            })}
            {props.options && <OptionsCell>{props.options}</OptionsCell>}
        </tr>
    );
};

export default Row;
