import { useAccount } from '@frontend/account/utils';
import { ApiQueryParams, DefaultQueryParams, PaginatedResponse } from '@frontend/api-utils';
import { useAppSelector } from '@frontend/common';
import { EventListener } from '@frontend/pub-sub';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { SortingRule } from 'react-table';

import { clear, fetch as func, listStore } from './list-slice';

export function useListRepository<T>(
    fetch: (arg?: ApiQueryParams<DefaultQueryParams | string | number>) => Promise<PaginatedResponse<T>>,
    dispatch: ThunkDispatch<any, any, Action>,
    eventListener?: EventListener<T>,
    filters?: ApiQueryParams<DefaultQueryParams | string | number>
) {
    const store = useAppSelector(useSelector, listStore);
    const { currentAccount } = useAccount();
    const [id] = useState<string>(crypto.randomUUID());
    const [pageSize, changePageSize] = useState(25);
    const [page, changePage] = useState(0);
    const [sortState, changeSortState] = useState<SortingRule<object>[]>([]);
    const [searchValue, changeSearchValue] = useState<string>('');
    const [params, changeParams] = useState<ApiQueryParams<string | number>>({});
    const currentAccountRef = useRef(currentAccount);

    useEffect(() => {
        currentAccountRef.current = currentAccount;
    }, [currentAccount]);

    const onUpdate = () => {
        dispatch(
            func({
                key: id,
                params: {
                    index: page.toString(),
                    size: pageSize.toString(),
                    account_id: currentAccountRef.current,
                    ...(sortState.length > 0 && { sort_field: sortState[0].id, sort_direction: sortState[0].desc ? 'desc' : 'asc' }),
                    ...(searchValue != '' && { search: searchValue }),
                    ...params
                },
                func: fetch
            })
        );
    };

    useEffect(() => {
        if (eventListener == undefined) return;
        const onEvent = (message: string, data?: T) => {
            if (currentAccountRef.current && data && (data as any).account_id && (data as any).account_id != currentAccountRef.current) return;
            if (filters && data) {
                if (isMessageRelevant(message) && isDataRelevant(filters, data)) onUpdate();
            } else if (isMessageRelevant(message)) onUpdate();
        };

        eventListener.getInstance(dispatch).subscribe(onEvent);
        return () => {
            eventListener.getInstance(dispatch).unsubscribe(onEvent);
            dispatch(clear(id));
        };
    }, []);

    useEffect(() => {
        //if (!accountId) return;
        onUpdate();
    }, [sortState, page, pageSize, searchValue, params, currentAccount]);

    const data = useMemo(() => {
        if (store.list[id] == undefined || store.list[id].results == null) return null;
        return store.list[id].results as T[];
    }, [store]);

    const pageCount = store.list[id] != null && store.list[id].count != null ? Math.ceil(store.list[id].count! / pageSize) : 1;

    return {
        data,
        pageSize,
        changePageSize,
        page,
        changePage,
        pageCount,
        sortState,
        changeSortState,
        searchValue,
        changeSearchValue,
        params,
        changeParams
    };
}

export default useListRepository;

const isDataRelevant = (filters: ApiQueryParams<DefaultQueryParams | string | number>, data: any) => {
    return Object.entries(filters).every(([key, value]) => {
        const dataValue = (data as any)[key];
        if (Array.isArray(value)) {
            return value.includes(dataValue);
        } else {
            return dataValue == value;
        }
    });
};

const isMessageRelevant = (message: string) => {
    return message.includes('created') || message.includes('deleted') || message.includes('multi') || message.includes('update');
};
