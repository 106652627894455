import React from 'react';

export const TablePlaceholder = () => {
    return (
        <table className='table align-items-center mb-0'>
            <thead className='sticky-top bg-light bg-gradient shadow-sm'>
                <tr>
                    <th
                        scope='col'
                        style={{ width: '10%' }}>
                        <span className='placeholder-glow'>
                            <span className='placeholder col-8'></span>
                        </span>
                    </th>
                    <th
                        scope='col'
                        style={{ width: '25%' }}>
                        <span className='placeholder-glow'>
                            <span className='placeholder col-12'></span>
                        </span>
                    </th>
                    <th
                        scope='col'
                        style={{ width: '35%' }}>
                        <span className='placeholder-glow'>
                            <span className='placeholder col-12'></span>
                        </span>
                    </th>
                    <th
                        scope='col'
                        style={{ width: '30%' }}>
                        <span className='placeholder-glow'>
                            <span className='placeholder col-10'></span>
                        </span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className='text-primary align-content-center'>
                        <span className='placeholder-glow'>
                            <span className='placeholder col-6'></span>
                        </span>
                    </td>
                    <td className='text-primary align-content-center'>
                        <span className='placeholder-glow'>
                            <span className='placeholder col-12'></span>
                        </span>
                    </td>
                    <td className='text-primary align-content-center'>
                        <span className='placeholder-glow'>
                            <span className='placeholder col-12'></span>
                        </span>
                    </td>
                    <td className='text-primary align-content-center'>
                        <span className='placeholder-glow'>
                            <span className='placeholder col-8'></span>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td className='text-primary align-content-center'>
                        <span className='placeholder-glow'>
                            <span className='placeholder col-6'></span>
                        </span>
                    </td>
                    <td className='text-primary align-content-center'>
                        <span className='placeholder-glow'>
                            <span className='placeholder col-12'></span>
                        </span>
                    </td>
                    <td className='text-primary align-content-center'>
                        <span className='placeholder-glow'>
                            <span className='placeholder col-12'></span>
                        </span>
                    </td>
                    <td className='text-primary align-content-center'>
                        <span className='placeholder-glow'>
                            <span className='placeholder col-8'></span>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td className='text-primary align-content-center'>
                        <span className='placeholder-glow'>
                            <span className='placeholder col-6'></span>
                        </span>
                    </td>
                    <td className='text-primary align-content-center'>
                        <span className='placeholder-glow'>
                            <span className='placeholder col-12'></span>
                        </span>
                    </td>
                    <td className='text-primary align-content-center'>
                        <span className='placeholder-glow'>
                            <span className='placeholder col-12'></span>
                        </span>
                    </td>
                    <td className='text-primary align-content-center'>
                        <span className='placeholder-glow'>
                            <span className='placeholder col-8'></span>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};
