import { EntityType } from '@frontend/common';
import { ContactGroupClient } from '@frontend/group/api';
import { useResolver } from '@frontend/repository';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

declare global {
    interface WindowEventMap {
        'useContactGroup:request': CustomEvent<string>;
    }
}

const REQUEST_CONTACT_GROUP_EVENT = 'useContactGroup:request';
const STORAGE_KEY = 'CONTACT_GRP_REQ';

export function contactGroupRequest(groupId: string): string {
    window.dispatchEvent(new CustomEvent(REQUEST_CONTACT_GROUP_EVENT, { detail: groupId }));
    return STORAGE_KEY + '_loading';
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function ContactGroupResolver(props: Props) {
    useResolver(STORAGE_KEY, REQUEST_CONTACT_GROUP_EVENT, EntityType.CONTACT_GROUP, (ids) => ContactGroupClient.resolveContactGroups(ids), props.dispatch);
    return <></>;
}
