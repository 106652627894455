import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';

import { useAppDispatch } from '../redux/store';

const UserResolver = lazy(() => import('@frontend/user/events').then((module) => ({ default: module.UserResolver })));
const CostCenterResolver = lazy(() => import('@frontend/cost-center/events').then((module) => ({ default: module.CostCenterResolver })));
const AccountResolver = lazy(() => import('@frontend/account/events').then((module) => ({ default: module.AccountResolver })));
const AddressResolver = lazy(() => import('@frontend/address/events').then((module) => ({ default: module.AddressResolver })));
const UserGroupResolver = lazy(() => import('@frontend/group/events').then((module) => ({ default: module.UserGroupResolver })));
const ContactGroupResolver = lazy(() => import('@frontend/group/events').then((module) => ({ default: module.ContactGroupResolver })));
const ProductCategoryResolver = lazy(() => import('@frontend/group/events').then((module) => ({ default: module.ProductCategoryResolver })));
const EquipmentGroupResolver = lazy(() => import('@frontend/group/events').then((module) => ({ default: module.EquipmentGroupResolver })));
const ProductGroupResolver = lazy(() => import('@frontend/group/events').then((module) => ({ default: module.ProductGroupResolver })));
const QuestionnaireResolver = lazy(() => import('@frontend/group/events').then((module) => ({ default: module.QuestionnaireResolver })));
const BudgetResolver = lazy(() => import('@frontend/budget/events').then((module) => ({ default: module.BudgetResolver })));
const SpotResolver = lazy(() => import('@frontend/spot/events').then((module) => ({ default: module.SpotResolver })));
const ModuleResolver = lazy(() => import('@frontend/module/events').then((module) => ({ default: module.ModuleResolver })));
const ModuleTemplateResolver = lazy(() => import('@frontend/module/events').then((module) => ({ default: module.ModuleTemplateResolver })));
const PlaylistResolver = lazy(() => import('@frontend/playlist/events').then((module) => ({ default: module.PlaylistResolver })));
const SlotResolver = lazy(() => import('@frontend/slot/events').then((module) => ({ default: module.SlotResolver })));
const SlotTemplateResolver = lazy(() => import('@frontend/slot/events').then((module) => ({ default: module.SlotTemplateResolver })));
const ProductResolver = lazy(() => import('@frontend/product/events').then((module) => ({ default: module.ProductResolver })));
const CertificateResolver = lazy(() => import('@frontend/certificate/events').then((module) => ({ default: module.CertificateResolver })));
const IoTResolver = lazy(() => import('@frontend/iot/events').then((module) => ({ default: module.IoTResolver })));
const EquipmentResolver = lazy(() => import('@frontend/equipment/events').then((module) => ({ default: module.EquipmentResolver })));
const DeviceResolver = lazy(() => import('@frontend/device/events').then((module) => ({ default: module.DeviceResolver })));
const WorkflowResolver = lazy(() => import('@frontend/workflow/events').then((module) => ({ default: module.WorkflowResolver })));
const UserInterfaceResolver = lazy(() => import('@frontend/user-interface/events').then((module) => ({ default: module.UserInterfaceResolver })));
const ProblemResolver = lazy(() => import('@frontend/problem/events').then((module) => ({ default: module.ProblemResolver })));
const StockResolver = lazy(() => import('@frontend/stock/events').then((module) => ({ default: module.StockResolver })));
const DocumentResolver = lazy(() => import('@frontend/document/events').then((module) => ({ default: module.DocumentResolver })));
const CourierResolver = lazy(() => import('@frontend/courier/events').then((module) => ({ default: module.CourierResolver })));
const VariantResolver = lazy(() => import('@frontend/variant/events').then((module) => ({ default: module.VariantResolver })));
const AttributeResolver = lazy(() => import('@frontend/attribute/events').then((module) => ({ default: module.AttributeResolver })));
const TransactionResolver = lazy(() => import('@frontend/transaction/events').then((module) => ({ default: module.TransactionResolver })));
const CustomRoleResolver = lazy(() => import('@frontend/role/events').then((module) => ({ default: module.CustomRoleResolver })));
const QuestionResolver = lazy(() => import('@frontend/question/events').then((module) => ({ default: module.QuestionResolver })));
const RequirementResolver = lazy(() => import('@frontend/stock/events').then((module) => ({ default: module.RequirementResolver })));
const ContactResolver = lazy(() => import('@frontend/contact/events').then((module) => ({ default: module.ContactResolver })));
const PackageResolver = lazy(() => import('@frontend/package/events').then((module) => ({ default: module.PackageResolver })));
const VariantLinkingResolver = lazy(() => import('@frontend/variant/events').then((module) => ({ default: module.VariantLinkingResolver })));
const AnswerResolver = lazy(() => import('@frontend/question/events').then((module) => ({ default: module.AnswerResolver })));
const UserCertificateResolver = lazy(() => import('@frontend/certificate/events').then((module) => ({ default: module.UserCertificateResolver })));
const EquipmentCertificateResolver = lazy(() => import('@frontend/certificate/events').then((module) => ({ default: module.EquipmentCertificateResolver })));

const Resolver = (props: { children: React.ReactNode }) => {
    const dispatch = useAppDispatch();
    return (
        <AsyncComponent>
            <StockResolver dispatch={dispatch} />
            <ProblemResolver dispatch={dispatch} />
            <DeviceResolver dispatch={dispatch} />
            <IoTResolver dispatch={dispatch} />
            <EquipmentResolver dispatch={dispatch} />
            <CertificateResolver dispatch={dispatch} />
            <ProductResolver dispatch={dispatch} />
            <UserResolver dispatch={dispatch} />
            <BudgetResolver dispatch={dispatch} />
            <DocumentResolver dispatch={dispatch} />
            <UserGroupResolver dispatch={dispatch} />
            <ProductGroupResolver dispatch={dispatch} />
            <ProductCategoryResolver dispatch={dispatch} />
            <ContactGroupResolver dispatch={dispatch} />
            <QuestionnaireResolver dispatch={dispatch} />
            <EquipmentGroupResolver dispatch={dispatch} />
            <AccountResolver dispatch={dispatch} />
            <AddressResolver dispatch={dispatch} />
            <CostCenterResolver dispatch={dispatch} />
            <SpotResolver dispatch={dispatch} />
            <ModuleResolver dispatch={dispatch} />
            <ModuleTemplateResolver dispatch={dispatch} />
            <PlaylistResolver dispatch={dispatch} />
            <SlotResolver dispatch={dispatch} />
            <SlotTemplateResolver dispatch={dispatch} />
            <WorkflowResolver dispatch={dispatch} />
            <UserInterfaceResolver dispatch={dispatch} />
            <QuestionResolver dispatch={dispatch} />
            <CourierResolver dispatch={dispatch} />
            <VariantResolver dispatch={dispatch} />
            <AttributeResolver dispatch={dispatch} />
            <TransactionResolver dispatch={dispatch} />
            <RequirementResolver dispatch={dispatch} />
            <CustomRoleResolver dispatch={dispatch} />
            <ContactResolver dispatch={dispatch} />
            <PackageResolver dispatch={dispatch} />
            <VariantLinkingResolver dispatch={dispatch} />
            <AnswerResolver dispatch={dispatch} />
            <UserCertificateResolver dispatch={dispatch} />
            <EquipmentCertificateResolver dispatch={dispatch} />
            {props.children}
        </AsyncComponent>
    );
};

export default Resolver;
