import React from 'react';
import { FormattedMessage } from 'react-intl';
import { GroupBase } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { SelectComponents } from 'react-select/dist/declarations/src/components';

import useCreatableSelectInput, { Option } from './creatable-select-input.controller';

export interface CreatableSelectInputProps {
    isClearable?: boolean;
    isDisabled?: boolean;
    options?: Option[];
    placeholder?: string;
    onChange: (newValue: string[]) => void;
    id?: string;
    required?: boolean;
    label: React.ReactNode;
    helpMessage?: React.ReactNode;
    submitted: boolean;
    value?: string[];
    className?: string;
    menuIsOpen?: boolean;
    components?: Partial<SelectComponents<Option, true, GroupBase<Option>>> | undefined;
}

const ID = 'creatable-select-input';

export const CreatableSelectInput = (props: CreatableSelectInputProps) => {
    const viewProps = useCreatableSelectInput(props);

    return (
        <div
            id={props.id || ID}
            className={props.className || 'form-group'}>
            <label className='text-primary'>
                {props.required && <span className='text-danger me-1'>&#9679;</span>} {props.label}
                {props.helpMessage && (
                    <>
                        <br />
                        <small className='form-text text-muted'>{props.helpMessage}</small>
                    </>
                )}
            </label>
            <div className='d-flex flex-row align-items-end'>
                <CreatableSelect
                    components={props.components}
                    inputValue={viewProps.inputValue}
                    isClearable
                    isMulti
                    className='w-100'
                    menuIsOpen={props.menuIsOpen}
                    onChange={viewProps.changeValue}
                    onInputChange={viewProps.changeInputValue}
                    onKeyDown={viewProps.handleKeyDown}
                    placeholder={props.placeholder || defaultPlaceholder}
                    value={viewProps.value}
                    styles={styles}
                />
            </div>
        </div>
    );
};

const styles = {
    control: (baseStyles: any) => ({
        ...baseStyles,
        boxShadow: 'none',
        minWidth: '190px',
        padding: '1.204px 0px 1.204px 10px',
        borderRadius: '0.5rem'

        //TERMINAL S4M - todo
        //padding: ".5rem .75rem",
        // fontSize: "14px",
        // fontWeight: "400",
        // width: "100%",
        // backgroundClip: "padding-box",
        // appearance: "none",
        // backgroundColor: "transparent",
        //borderRadius: "25px",
        // border: "2px solid #000",
        // outline: "none",
    }),
    menu: (baseStyle: any) => ({
        ...baseStyle,
        zIndex: 9999,
        minWidth: 'fit-content'
    }),
    valueContainer: (baseStyle: any) => ({
        ...baseStyle,
        padding: '0px',
        minWidth: 'fit-content'
    })
};

const defaultPlaceholder = (
    <FormattedMessage
        id='creatable-select-input.default-placeholder'
        defaultMessage='Type something and press Enter or Tab'
        description='Default creatable select placeholder'
    />
);
