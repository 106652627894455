import { APIClient, ApiQueryParams } from '@frontend/api-utils';
import { AttributeValue, AttributeValueListResponse, AttributeValueQueryParams, CreateAttributeValue } from '@frontend/attribute/types';

const endpoint = '/product-api/v1';

export class AttributeValueClient extends APIClient {
    public static async fetchAttributeValues(queryParams?: ApiQueryParams<AttributeValueQueryParams>): Promise<AttributeValueListResponse> {
        return await this.apiPaginated<AttributeValueListResponse, AttributeValueQueryParams>(`${endpoint}/attribute-values`, queryParams);
    }

    public static async resolveAttributeValues(ids: string[]): Promise<AttributeValueListResponse> {
        const response = await this.post(`${endpoint}/attribute-values-resolve`, { ids: ids });
        return await this.handleResponse<AttributeValueListResponse>(response);
    }

    public static async deleteAttributeValues(ids: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/attribute-values-delete`, { ids: ids });
        return await this.handleVoidResponse(response);
    }

    public static async postAttributeValue(accountId: string, attributeId: string, body: CreateAttributeValue): Promise<AttributeValue> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/attributes/${attributeId}/attribute-values`, body);
        return await this.handleResponse(response);
    }

    public static async fetchAttributeValue(accountId: string, attributeId: string, attributeValueId: string): Promise<AttributeValue> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/attributes/${attributeId}/attribute-values/${attributeValueId}`);
        return await this.handleResponse(response);
    }

    public static async patchAttributeValue(
        accountId: string,
        attributeId: string,
        attributeValueId: string,
        body: CreateAttributeValue
    ): Promise<AttributeValue> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/attributes/${attributeId}/attribute-values/${attributeValueId}`, body);
        return await this.handleResponse(response);
    }

    public static async deleteAttributeValue(accountId: string, attributeId: string, attributeValueId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/attributes/${attributeId}/attribute-values/${attributeValueId}`);
        return await this.handleVoidResponse(response);
    }
}
