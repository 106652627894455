import { BsFillBuildingsFill } from 'react-icons/bs';
import { CiCloudOn } from 'react-icons/ci';
import { FaFileImport, FaLink, FaMoneyBillWave, FaPlay, FaProductHunt, FaStore, FaSync, FaTools, FaUsers } from 'react-icons/fa';
import { GrTemplate } from 'react-icons/gr';
import { IoMdApps } from 'react-icons/io';
import { MdEvent, MdOutlineQuestionAnswer, MdRule } from 'react-icons/md';
import { PiCertificate } from 'react-icons/pi';
import { RiContactsBook2Fill } from 'react-icons/ri';
import { VscGraph } from 'react-icons/vsc';

export const NAVIGATION_ICONS = {
    USER_MANAGER: FaUsers,
    ORGANIZATION: BsFillBuildingsFill,
    FINANCIALS: FaMoneyBillWave,
    INVENTORY: FaStore,
    CONTENT_AND_MEDIA: FaPlay,
    APPLICATIONS: IoMdApps,
    QUESTION_AND_ANSWER: MdOutlineQuestionAnswer,
    EQUIPMENT: FaTools,
    QUOTAS_AND_CONSTRAINTS: FaLink,
    CERTIFICATES: PiCertificate,
    IOT: CiCloudOn,
    PRODUCT: FaProductHunt,
    CONTACT: RiContactsBook2Fill,
    IMPORT: FaFileImport,
    RULE: MdRule,
    SYNC: FaSync,
    EVENT: MdEvent,
    NOTIFICATION_TEMPLATE: GrTemplate,
    REPORT: VscGraph
};
