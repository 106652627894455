import { APIClient, ApiQueryParams, buildApiEndpoint } from '@frontend/api-utils';
import { ChartQueryParams, HeatmapChartData } from '@frontend/chart/types';

const endpoint = '/slot-api/v1';

export class SlotChartClient extends APIClient {
    public static async fetchOccupiedSlots(
        accountId: string,
        spotId: string,
        moduleId: string,
        queryParams?: ApiQueryParams<ChartQueryParams>
    ): Promise<HeatmapChartData> {
        const url = buildApiEndpoint(`${endpoint}/accounts/${accountId}/spots/${spotId}/modules/${moduleId}/occupied-slots`, queryParams);
        const response = await this.fetch(url);
        return await this.handleResponse<HeatmapChartData>(response);
    }
}
