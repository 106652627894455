import { ApiQueryParams, DefaultQueryParams, PaginatedResponse } from '@frontend/api-utils';
import { Permission } from '@frontend/authorization';
import { ClassType, EntityType, Locale } from '@frontend/common';
import { ConfirmationModal, HorizontalButtonGroup, HorizontalButtonGroupButton, TablePlaceholder } from '@frontend/elements';
import { ICONS } from '@frontend/icons';
import { Export, Import } from '@frontend/impex/components';
import { FormatTypeName, ImportResponse } from '@frontend/impex/types';
import { CommonMessage } from '@frontend/lang';
import { RowElement, Table, TableProps } from '@frontend/table';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5';
import { FormattedMessage } from 'react-intl';
import { Column } from 'react-table';

import { FilterOptions, FilterPosition, HeaderFilter } from '../header/header-filter';
import { ObjectColumnsProps } from '../object-column-mapper';
import useObjectList from './object-list.controller';

export interface ObjectListProps<T extends { id: string }> {
    listStoreIdCallback?: (id: string) => void;
    label?: React.ReactNode;
    fetch: (arg?: ApiQueryParams<DefaultQueryParams | string | number>) => Promise<PaginatedResponse<T>>;
    dispatch: ThunkDispatch<any, any, Action>;
    refreshRef?: React.MutableRefObject<() => void>;
    eventListener?: EntityType;
    id: string;
    columns: Omit<ObjectColumnsProps<T>, 'dispatch'>;
    multiSelectOptions?: MultiSelectOptions<T>;
    managedSelect?: string;
    onSelectElement?: (object: T) => void;
    sortingColumns?: string[];
    create?: {
        label?: React.ReactNode;
        onCreate: () => void;
        requiredPermissions?: Permission[];
        overwriteCreateLabel?: React.ReactNode;
    };
    type: EntityType;
    filter?: FilterOptions<T>;
    selectedObjects?: (obj: T[]) => void;
    queryParams?: ApiQueryParams<DefaultQueryParams | string | number>;

    cardClassName?: string;
    cardHeaderClassName?: string;
    cardBodyClassName?: string;
    customButtons?: HorizontalButtonGroupButton[];
    customHeaderComponent?: React.ReactNode;
    tableProps?: { [K in keyof TableProps]?: TableProps[K] };
    hideColumns?: string[];

    collapse?:
        | {
              default?: 'opened' | 'closed';
          }
        | false;
    showCount?: boolean;
    locale?: Locale;
}

interface MultiSelectOptions<T> {
    delete?: {
        enabled: (obj: T[]) => boolean;
        delete: (ids: string[]) => Promise<void>;
        parent?: React.ReactNode;
        requiredPermissions?: Permission[];
    };
    import?: {
        fetchTemplate: () => Promise<void>;
        import: (args: { accountId: string; file: File; dry_run: boolean }) => Promise<ImportResponse>;
        type: EntityType;
        requiredPermissions?: Permission[];
    };
    export?: {
        export: (format: FormatTypeName) => Promise<void>;
        type: EntityType;
        requiredPermissions?: Permission[];
    };
    custom?: HorizontalButtonGroupButton[];
}

export const ObjectList = <T extends { id: string }>(props: ObjectListProps<T>) => {
    const viewProps = useObjectList<T>(props);

    return (
        <>
            <div
                id={props.id}
                className={props.cardClassName !== undefined ? props.cardClassName : 'card overflow-scroll mt-3 h-100'}>
                <div
                    className={
                        props.cardHeaderClassName !== undefined
                            ? props.cardHeaderClassName
                            : 'card-header d-flex flex-wrap justify-content-between align-items-center w-100'
                    }>
                    <span className='w-100 w-sm-45 w-lg-30'>
                        <HorizontalButtonGroup
                            direction='left'
                            buttons={[
                                ...(props.create
                                    ? [
                                          {
                                              hide: false,
                                              onClick: props.create.onCreate,
                                              icon: ICONS.BUTTON.CREATE,
                                              type: ClassType.PRIMARY,
                                              id: 'ObjectList.CreateButton',
                                              text: props.create.overwriteCreateLabel ? props.create.overwriteCreateLabel : CommonMessage.FORMS.CREATE_OBJECT(props.create.label ? props.create.label : props.type.replace(/_/g, ' ')),
                                              requiredPermissions: props.create.requiredPermissions
                                          }
                                      ]
                                    : []),
                                ...(props.multiSelectOptions?.delete
                                    ? [
                                          {
                                              hide: !props.multiSelectOptions?.delete?.enabled(viewProps.selectedObjects),
                                              onClick: () => viewProps.changeShowMultiDeleteModal(true),
                                              icon: ICONS.BUTTON.DELETE,
                                              type: ClassType.DANGER,
                                              id: 'ObjectList.DeleteButton',
                                              text: CommonMessage.BUTTONS.DELETE,
                                              requiredPermissions: props.multiSelectOptions.delete.requiredPermissions
                                          }
                                      ]
                                    : []),
                                ...(props.multiSelectOptions?.import
                                    ? [
                                          {
                                              onClick: () => viewProps.changeShowImportModal(true),
                                              icon: ICONS.BUTTON.IMPORT,
                                              type: ClassType.LIGHT,
                                              id: 'ObjectList.ImportButton',
                                              text: CommonMessage.BUTTONS.IMPORT,
                                              requiredPermissions: props.multiSelectOptions.import.requiredPermissions
                                          }
                                      ]
                                    : []),
                                ...(props.multiSelectOptions?.export
                                    ? [
                                          {
                                              onClick: () => viewProps.changeShowExportModal(true),
                                              icon: ICONS.BUTTON.EXPORT,
                                              type: ClassType.LIGHT,
                                              id: 'ObjectList.ImportButton',
                                              text: CommonMessage.BUTTONS.EXPORT,
                                              requiredPermissions: props.multiSelectOptions.export.requiredPermissions
                                          }
                                      ]
                                    : []),
                                ...(props.multiSelectOptions?.custom
                                    ? props.multiSelectOptions.custom.map((button) => ({
                                          hide: button.enabled ? !button.enabled(viewProps.selectedObjects) : false,
                                          disabled: button.enabled ? !button.enabled(viewProps.selectedObjects) : button.disabled,
                                          requiredPermissions: button.requiredPermissions,
                                          ...button
                                      }))
                                    : []),
                                ...(props.customButtons ? props.customButtons : [])
                            ]}
                        />
                        {props.customHeaderComponent && props.customHeaderComponent}
                    </span>
                    <span className='w-100 w-sm-45 w-lg-30 text-center my-0 p-0'>
                        <h4 className='my-0 p-0'>{props.label}</h4>
                    </span>
                    <span className='w-100 w-sm-45 w-lg-30 text-end'>
                        {props.collapse !== false &&
                            (viewProps.collapsed ? (
                                <span
                                    className='badge rounded-pill bg-secondary'
                                    onClick={() => viewProps.changeCollapsed(false)}>
                                    <span className='d-flex flex-row align-items-center justify-content-center'>
                                        {viewProps.totalCount}
                                        <IoChevronDownOutline className='ms-2' />
                                    </span>
                                </span>
                            ) : (
                                <span
                                    className='badge rounded-pill bg-secondary'
                                    onClick={() => viewProps.changeCollapsed(true)}>
                                    <span className='d-flex flex-row align-items-center justify-content-center'>
                                        {viewProps.totalCount}
                                        <IoChevronUpOutline className='ms-2' />
                                    </span>
                                </span>
                            ))}
                    </span>
                </div>
                {!viewProps.collapsed &&
                    (props.filter?.position === FilterPosition.TOP ||
                        (props.filter?.position === undefined && (
                            <div className='d-flex justify-content-end w-100'>
                                <HeaderFilter<T>
                                    filter={props.filter}
                                    {...viewProps}
                                />
                            </div>
                        )))}

                {!viewProps.collapsed && (
                    <div className={props.cardBodyClassName ? props.cardBodyClassName : 'card-body'}>
                        {props.filter?.position === FilterPosition.RIGHT ? (
                            <>
                                {viewProps.data ? (
                                    <div className='d-flex flex-row w-100 px-2 m-0 py-0'>
                                        <div className='w-80'>
                                            <Table
                                                dispatch={props.dispatch}
                                                listStoreId={viewProps.listStoreId}
                                                key={props.id}
                                                id={props.id + '-table'}
                                                columns={viewProps.columns as Column<any>[]}
                                                RowElement={RowElement}
                                                data={viewProps.data}
                                                selectEnabled={!!props.multiSelectOptions}
                                                onSelectElement={props.onSelectElement}
                                                pageCount={viewProps.pageCount}
                                                sortingColumns={props.sortingColumns}
                                                hideColumns={props.hideColumns}
                                                showCount={props.showCount}
                                                {...props.tableProps}
                                            />
                                        </div>
                                        <div className='w-20 p-2'>
                                            <HeaderFilter<T>
                                                filter={props.filter}
                                                {...viewProps}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <TablePlaceholder />
                                )}
                            </>
                        ) : (
                            <>
                                {viewProps.data ? (
                                    <Table
                                        dispatch={props.dispatch}
                                        listStoreId={viewProps.listStoreId}
                                        key={props.id}
                                        id={props.id + '-table'}
                                        columns={viewProps.columns as Column<any>[]}
                                        RowElement={RowElement}
                                        data={viewProps.data}
                                        selectEnabled={!!props.multiSelectOptions || !!props.managedSelect}
                                        onSelectElement={props.onSelectElement}
                                        pageCount={viewProps.pageCount}
                                        sortingColumns={[...(props.sortingColumns || []), ...viewProps.sortKeys]}
                                        hideColumns={props.hideColumns}
                                        showCount={props.showCount}
                                        {...props.tableProps}
                                    />
                                ) : (
                                    <TablePlaceholder />
                                )}
                            </>
                        )}
                    </div>
                )}
            </div>

            {viewProps.showMultiDeleteModal && !!props.multiSelectOptions?.delete && (
                <ConfirmationModal
                    handleClose={() => viewProps.changeShowMultiDeleteModal(false)}
                    message={
                        props.multiSelectOptions.delete.parent
                            ? parentMultiDeleteMessage(viewProps.selectedObjects.length, props.type, props.multiSelectOptions.delete.parent)
                            : listMultiDeleteMessage(viewProps.selectedObjects.length, props.type)
                    }
                    onConfirm={() => {
                        props
                            .multiSelectOptions!.delete!.delete(viewProps.selectedObjects.map((o) => o.id))
                            .then(() => viewProps.changeSelectedObjects([]))
                            .then(() => viewProps.changeShowMultiDeleteModal(false));
                    }}
                    severity={ClassType.DANGER}
                    show={viewProps.showMultiDeleteModal}
                />
            )}
            {viewProps.showImportModal && !!props.multiSelectOptions!.import && (
                <Import
                    show={viewProps.showImportModal}
                    onClose={() => viewProps.changeShowImportModal(false)}
                    fetchTemplate={props.multiSelectOptions!.import?.fetchTemplate}
                    import={props.multiSelectOptions!.import?.import}
                    type={props.multiSelectOptions!.import?.type}
                />
            )}
            {viewProps.showExportModal && !!props.multiSelectOptions!.export && (
                <Export
                    show={viewProps.showExportModal}
                    onClose={() => viewProps.changeShowExportModal(false)}
                    export={props.multiSelectOptions!.export?.export}
                    type={props.multiSelectOptions!.export?.type}
                />
            )}
        </>
    );
};

const listMultiDeleteMessage = (count: number, type: EntityType) => {
    return (
        <FormattedMessage
            id='ObjectList.multi-delete-modal.message'
            defaultMessage='Are you sure you want to remove {count} {type}s?'
            values={{ count, type: type.replace(/_/g, ' ') }}
        />
    );
};

const parentMultiDeleteMessage = (count: number, type: EntityType, parent: React.ReactNode) => {
    return (
        <FormattedMessage
            id='ObjectList.multi-delete-modal.message'
            defaultMessage='Are you sure you want to remove {count} {type}s from this {parent}?'
            values={{ count, type: type.replace(/_/g, ' '), parent }}
        />
    );
};
