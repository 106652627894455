import { EntityType } from '@frontend/common';
import { answerRequest } from '@frontend/question/events';
import { Answer } from '@frontend/question/types';
import { ObjectBadge, ObjectBadgeProps } from '@frontend/rendering/badge';
import React from 'react';

export const AnswerBadge = (props: ObjectBadgeProps<Answer>) => {
    return (
        <ObjectBadge<Answer>
            id={props.id}
            type={EntityType.QUESTION}
            mapDisplayName={(eq: Answer) => eq.answer}
            resolve={answerRequest}
            navigateString={(object: Answer) => `/accounts/${object.account_id}/answers/${object.id}`}
            {...props}
        />
    );
};
