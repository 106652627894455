import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { AttributeValueRoutes } from '../attribute-value/attribute-value-routes';

const AttributeOverview = lazy(() => import('./attribute-overview'));
const AttributeCreate = lazy(() => import('./attribute-create/attribute-create.component'));
const AttributeDetail = lazy(() => import('./attribute-detail/attribute-detail.component'));
const AttributeUpdate = lazy(() => import('./attribute-update/attribute-update.component'));

const AttributeValueRoute = AttributeValueRoutes();
export function AttributeRoutes() {
    return (
        <Route path='attributes'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <AttributeOverview />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <AttributeCreate />
                    </AsyncComponent>
                }
            />
            <Route path=':attributeId'>
                <Route
                    path='detail'
                    element={
                        <AsyncComponent>
                            <AttributeDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <AttributeUpdate />
                        </AsyncComponent>
                    }
                />
                {AttributeValueRoute}
            </Route>
        </Route>
    );
}

export default AttributeRoutes;
