import { EntityType } from '@frontend/common';
import { ErrorHandler } from '@frontend/error-handler';
import { ICONS } from '@frontend/icons';
import { CommonMessage } from '@frontend/lang';
import { MqttBroker } from '@frontend/pub-sub';
import { useAccountRepository } from '@frontend/repository';
import { ToastUtil } from '@frontend/toast-utils';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { DetailItem, DetailItemProps } from './detail-item/detail-item.component';
import { ObjectDetailProps2 } from './object-detail-2.component';

interface ViewProps<T> {
    object: T | null;
    showDeleteModal: boolean;
    changeShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
    onDeleteConfirmed: () => void;
    onUpdate: () => void;
    subSections: DetailItemProps[];
    filteredSubSections: DetailItemProps[];
    activeSubSection: string | undefined;
    changeActiveSubSection: React.Dispatch<React.SetStateAction<string | undefined>>;
    main: React.ReactElement[];
    onChange: (identifier: string) => void;
}

const useObjectDetail2 = <T>(props: ObjectDetailProps2<T>): ViewProps<T> => {
    const navigate = useNavigate();
    const params = useParams();
    const {
        store: { currentAccount }
    } = useAccountRepository();
    const [object, changeObject] = useState<T | null>(null);
    const [showDeleteModal, changeShowDeleteModal] = useState<boolean>(false);
    const urlParams = useMemo(() => props.urlParamKeys.map((key) => params[key]), [props.urlParamKeys, params]);
    const [id] = useState<string>(crypto.randomUUID());
    const [subSections, changeSubSections] = useState<DetailItemProps[]>([
        { identifier: 'Main', label: CommonMessage.DETAIL.MAIN, icon: ICONS.DETAIL.MAIN, children: [] }
    ]);
    const [main, changeMain] = useState<React.ReactElement[]>([]);
    const [activeSubSection, changeActiveSubSection] = useState<string | undefined>(subSections ? subSections[0].identifier : undefined);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams && searchParams.get('menu') && subSections.filter((section) => section.identifier !== 'Main').length > 0) {
            changeActiveSubSection(searchParams.get('menu') as string);
        } else {
            changeActiveSubSection(subSections[0].identifier.toLowerCase());
        }
    }, [subSections]);

    const onChange = (identifier: string) => {
        setSearchParams((prev) => {
            const search = new URLSearchParams(prev);
            search.set('menu', identifier.toLowerCase());
            return search.toString();
        });
    };

    useEffect(() => {
        if (object && props.children) {
            React.Children.forEach(props.children(object), (child) => {
                if (!React.isValidElement(child)) return;
                if (child.type === DetailItem) {
                    processDetailItem(child);
                } else {
                    const children = child.props.children;
                    if (children) {
                        React.Children.forEach(children, (subChild) => {
                            if (!React.isValidElement(subChild)) return;
                            if (subChild.type === DetailItem) {
                                processDetailItem(subChild);
                            }
                        });
                    }
                }
            });
        }
    }, [props.children, object]);

    const processDetailItem = (detailItem: JSX.Element) => {
        if (detailItem.props.main) {
            changeMain((prev) => {
                const existingIndex = prev.findIndex((item) => item.key === detailItem.key);
                if (existingIndex !== -1) {
                    const updatedMain = [...prev];
                    updatedMain[existingIndex] = detailItem;
                    return updatedMain;
                } else {
                    return [...prev, detailItem];
                }
            });
        } else {
            changeSubSections((prev) => {
                const existingIndex = prev.findIndex((item) => item.identifier === detailItem.props.identifier);
                if (existingIndex !== -1) {
                    const updatedSubSections = [...prev];
                    updatedSubSections[existingIndex] = detailItem.props;
                    return updatedSubSections;
                } else {
                    return [...prev, detailItem.props];
                }
            });
        }
    };

    useEffect(() => {
        if (object) {
            props.object && props.object(object);
        }
    }, [object]);

    const onFetch = () => {
        if (urlParams.includes(undefined)) {
            ToastUtil.error('Something went wrong trying to get the correct information.');
            navigate(props.baseRoute.startsWith('/') ? props.baseRoute : '/admin/' + props.baseRoute);
        } else
            props
                .fetch(...urlParams)
                .then(changeObject)
                .catch(ErrorHandler.handleError);
    };

    useEffect(() => {
        if (!currentAccount) return;
        if (object && currentAccount && currentAccount !== (object as unknown as { account_id: string | undefined })?.account_id) {
            return;
        }
        onFetch();
        if (props.eventListener == undefined || !props.dispatch) return;
        const onEvent = (message: string, data: any) => {
            if (message.includes('created') || message.includes('deleted') || message.includes('multi') || message.includes('update')) onFetch();
        };
        const topic = props.eventListener === EntityType.ACCOUNT ? 'account' : 'accounts/' + currentAccount + '/' + props.eventListener;
        MqttBroker.getInstance().subscribe(id, topic, onEvent);
        return () => {
            MqttBroker.getInstance().unSubscribe(id, topic);
        };
    }, [currentAccount]);

    useEffect(() => {
        if (object && currentAccount && currentAccount !== (object as unknown as { account_id: string | undefined })?.account_id) {
            navigate(props.baseRoute.startsWith('/') ? props.baseRoute : '/admin/' + props.baseRoute);
        }
    }, [currentAccount]);

    const onDeleteConfirmed = () => {
        props
            .onDelete(...urlParams)
            .then(() => {
                changeShowDeleteModal(false);
                if (props.onDeleteSuccess) props.onDeleteSuccess();
                else navigate('../../');
            })
            .catch((e) => {
                ErrorHandler.handleError({ error: e });
            });
    };

    const onUpdate = () => {
        if (props.onUpdate) props.onUpdate();
        else {
            const urlKeys = props.updateKeys ? props.updateKeys : props.urlParamKeys;
            const urlParts = urlKeys.map(
                (key) =>
                    '/' +
                    (props.navigationKeyReplacements && props.navigationKeyReplacements[key]
                        ? props.navigationKeyReplacements[key] + '/'
                        : key.replace('Id', 's/')) +
                    params[key]
            );
            if (location.pathname.startsWith('/admin')) navigate('/admin' + urlParts.join('') + '/update');
            else navigate(urlParts.join('') + '/update');
        }
    };

    return {
        object,
        showDeleteModal,
        changeShowDeleteModal,
        onDeleteConfirmed,
        onUpdate,
        subSections,
        changeActiveSubSection,
        activeSubSection,
        filteredSubSections: subSections.filter((section) => section.identifier !== 'Main'),
        main,
        onChange
    };
};

export default useObjectDetail2;
