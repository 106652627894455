import { ClassType } from '@frontend/common';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { DefaultModal, DefaultModalProps } from '../default-modal/default-modal.component';

interface Props extends DefaultModalProps {
    severity?: ClassType;
    title?: React.ReactNode;
    message: React.ReactNode;
    onConfirm: () => void;
    cascadeWarning?: boolean;
}

export function ConfirmationModal(props: Props) {
    return (
        <DefaultModal
            id='ConfirmationModal'
            customWidth={40}
            {...props}>
            <div className='modal-body overflow-auto'>
                <h3 className={`text-${props.severity ? props.severity : 'info'} text-gradient`}>
                    {props.title ? (
                        props.title
                    ) : (
                        <FormattedMessage
                            id='ConfirmationModal.title'
                            description='The title of the modal asking for confirmation'
                            defaultMessage='Are you sure?'
                        />
                    )}
                </h3>
                <div className='mb-3'>{props.message}</div>
                {props.cascadeWarning && (
                    <span className='text-warning font-weight-bolder'>
                        <FormattedMessage
                            id='ConfirmationModal.cascade-warning'
                            description='A warning that deleting this item will delete other items'
                            defaultMessage='Be Careful! Other linked items might get unlinked or deleted.'
                        />
                    </span>
                )}
            </div>
            <div className='d-flex modal-footer flex-row justify-content-between align-items-center mb-3 pb-0'>
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        props.onConfirm();
                    }}
                    id='confirmation-confirm-button'
                    className={`btn btn-${props.severity ? props.severity : 'primary'} m-0`}>
                    <FormattedMessage
                        id='ConfirmationModal.answer.yes'
                        description='The no answer yes the confirmation'
                        defaultMessage='Yes'
                    />
                </button>
                <button
                    id='confirmation-cancel-button'
                    onClick={props.handleClose}
                    className='btn btn-outline-secondary m-0'>
                    <FormattedMessage
                        id='ConfirmationModal.answer.no'
                        description='The no answer on the confirmation'
                        defaultMessage='No'
                    />
                </button>
            </div>
        </DefaultModal>
    );
}
