import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Route } from 'react-router-dom';

const RequirementCreate = lazy(() => import('../requirement/requirement-create/requirement-create.component'));
const RequirementDetail = lazy(() => import('../requirement/requirement-detail/requirement-detail.component'));
const RequirementUpdate = lazy(() => import('../requirement/requirement-update/requirement-update.component'));
const RequirementOverview = lazy(() => import('../requirement/requirement-overview'));
export function RequirementRoutes() {
    return (
        <Route path='requirements'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <RequirementOverview />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <RequirementCreate />
                    </AsyncComponent>
                }
            />
            <Route path=':requirementId'>
                <Route
                    index
                    path='detail'
                    element={
                        <AsyncComponent>
                            <RequirementDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <RequirementUpdate />
                        </AsyncComponent>
                    }
                />
            </Route>
        </Route>
    );
}
