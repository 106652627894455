import { useEffect, useState } from 'react';

import { SelectOption, SingleOption, explodeOptions } from '../select-types';
import { MultiSelectInputV2Props } from './multi-select-input-v2.component';

interface ViewProps<T> {
    value?: string[];
    onSearch: (value: string) => void;
    searchResultOptions: SelectOption<T>[];

    onSelect: (option: SingleOption<T> | null) => void;
    selected: SingleOption<T>[] | null;
    setSelected: React.Dispatch<React.SetStateAction<SingleOption<T>[] | null>>;
    onRemove: (index: number) => void;
    searchValue: string | undefined;
    setSearchValue: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const useMultiSelectInputV2 = <T>(props: MultiSelectInputV2Props<T>): ViewProps<T> => {
    const [value, setValue] = useState<string[]>([]);
    const [searchResultOptions, setSearchResultOptions] = useState<SelectOption<T>[]>([]);
    const [selected, setSelected] = useState<SingleOption<T>[] | null>(null);
    const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (props.searchValueOverwrite !== undefined) onSearch(props.searchValueOverwrite);
    }, [props.searchValueOverwrite]);

    useEffect(() => {
        if (props.value && props.value.length > 0) {
            if (props.options) {
                const options = explodeOptions(props.options);
                const found = options.filter((op) => props.value?.some((v) => (v as any).id === op.value));
                if (!selected && found.length > 0) {
                    setSelected(found);
                    setValue(found.map((f) => f.label));
                } else if (found.length > 0 && found.length !== selected!.length && found.some((f) => selected?.every((s) => s.value !== f.value))) {
                    setSelected(found);
                    setValue(found.map((f) => f.label));
                }
            }
        }
    }, [props.value, props.options]);

    useEffect(() => {
        if (selected && selected.length > 0) {
            setSearchResultOptions(props.options?.filter((op) => selected.every((s) => s.value !== (op as any).value)));
        } else {
            setSearchResultOptions(props.options);
        }
    }, [props.options]);

    const onSelect = (option: SingleOption<T> | null) => {
        if (!option) return;
        setSelected((prev) => (prev ? [...prev, option] : [option]));
        setValue((prev) => (prev ? [...prev, option.label] : [option.label]));
        setSearchValue(undefined);
    };

    useEffect(() => {
        props.onSelect && props.onSelect(selected);
    }, [selected]);

    const onSearch = (value: string) => {
        props.onSearch && props.onSearch(value);
        if (value && value !== '') {
            setSearchResultOptions(props.options?.filter((op) => op.label.toLowerCase().includes(value.toLowerCase())));
        } else {
            setSearchResultOptions(props.options || []);
        }
    };

    useEffect(() => {
        onSearch(searchValue || '');
    }, [searchValue]);

    const onRemove = (index: number) => {
        setSelected((prev) => {
            if (!prev) return null;
            const newSelected = [...prev];
            newSelected.splice(index, 1);
            return newSelected;
        });
        setValue((prev) => {
            if (!prev) return [];
            const newValue = [...prev];
            newValue.splice(index, 1);
            return newValue;
        });
    };

    useEffect(() => {}, [props.value]);

    return {
        value: value,
        onSearch,
        searchResultOptions,
        searchValue,
        setSearchValue,

        onSelect,
        selected,
        setSelected,
        onRemove
    };
};

export default useMultiSelectInputV2;
