import { BadgeProps, useBadgeRepository } from '@frontend/repository';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export interface ObjectBadgeProps<T> {
    id?: string;
    className?: string;
    callback?: (object: T) => void;
    navigateString?: (object: T) => string;
}

export const ObjectBadge = <T,>(props: BadgeProps<T>) => {
    const repository = useBadgeRepository<T>(props);
    const Icon = props.overWriteIcon ? props.overWriteIcon(repository.object as T) : repository.icon;
    return (
        <span
            id={'badge-' + props.id}
            // TODO put back in once badges are clickable
            //className={props.className ? props.className : `badge badge-secondary disabled m-1`} //currently always disabled, we dont use them currently anyway
            className={props.className ? props.className : `badge badge-secondary ${repository.disabled ? 'disabled' : 'cursor-pointer'} m-1`}
            onClick={(e) =>
                !repository.disabled &&
                repository.isPlatform &&
                props.navigateString &&
                repository.object &&
                repository.onClick(e, props.navigateString(repository.object))
            }>
            <Icon className='me-2' />
            {repository.displayName ? (
                repository.displayName.length > 30 ? (
                    <OverlayTrigger
                        overlay={<Tooltip id={'tooltip'}>{repository.displayName}</Tooltip>}
                        placement='auto'
                        delay={{ show: 300, hide: 150 }}>
                        <span>{repository.displayName.slice(0, 20) + '...'}</span>
                    </OverlayTrigger>
                ) : (
                    <span>{repository.displayName}</span>
                )
            ) : (
                <span className='placeholder-glow'>
                    <span className='placeholder'>loading</span>
                </span>
            )}
        </span>
    );
};
