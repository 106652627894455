import { sha256 } from '@frontend/common';

import LoggingDatabase from './logging-db-connection';
import { FilterData, Log, LogViewModel } from './models/log';
import { LogMessageTypeName } from './models/log-message-type';

const environment = process.env['NODE_ENV'];
const isProd = environment === 'production';
export class Logger {
    static async debug(message: string | any, filter?: FilterData, ...data: any) {
        !isProd && console.debug(message, data, this?.getSource());
        this?.save(message, LogMessageTypeName.DEBUG, filter, data);
    }

    static async log(message: string | any, filter?: FilterData, ...data: any) {
        console.log(message, data, this?.getSource());
        this?.save(message, LogMessageTypeName.INFO, filter, data);
    }

    static async warn(message: string | any, filter?: FilterData, ...data: any) {
        console.warn(message, data, this?.getSource());
        this?.save(message, LogMessageTypeName.WARNING, filter, data);
    }

    static async error(message: string | any, filter?: FilterData, ...data: any) {
        console.error(message, data, this?.getSource());
        this?.save(message, LogMessageTypeName.ERROR, filter, data);
    }

    private static async save(message: string | any, level: LogMessageTypeName, filter?: FilterData, ...data: any) {
        let actualMessage = '';
        if (message instanceof Error) {
            actualMessage = message.message;
        }

        const log =
            typeof message === 'string'
                ? new Log(message, level, { ...filter }, data && JSON.stringify({ ...data }))
                : new Log(actualMessage, level, { ...filter }, data && JSON.stringify([message, { ...data }]));

        this?.saveLog(log).then((success) => {
            if (!success) console.log('log was not saved');
        });
    }

    private static async saveLog(log: Log): Promise<boolean> {
        return await LoggingDatabase.saveLog(log);
    }
    static async getBasicLogs(): Promise<Log[]> {
        const logs = await LoggingDatabase.getLogs();
        return logs || [];
    }
    static async getLogs(): Promise<LogViewModel[] | undefined> {
        const logs = await LoggingDatabase.getLogs();
        return logs?.map(Log.parseToViewModel);
    }
    static async removeByids(ids: number[]): Promise<void> {
        return LoggingDatabase.removeById(ids);
    }

    /**
     * This function should be used on sensitive data before it is logged. (e.g., access-tokens, passwords, ...)
     * @param value
     * @returns
     */
    static async generateHash(value: string): Promise<string> {
        return await sha256(value);
    }

    private static getSource() {
        try {
            return new Error().stack?.split('\n')[2].trim() || 'Unknown';
        } catch {
            return undefined;
        }
    }
}
