import { APIClient } from '@frontend/api-utils';

const endpoint = '/sync-api/v1';

export class SeedClient extends APIClient {
    public static async postIoTSeed(
        serviceName: string,
        accountId: string,
        body: { account_id: string; iot_identifier: string; revert: boolean; spot_id?: string }
    ): Promise<void> {
        const response = await this.post(`/${serviceName}/v1/accounts/${accountId}/seed`, body);
        return await this.handleVoidResponse(response);
    }
}
