import { HeaderGroup } from '@tanstack/react-table';
import React from 'react';

import { THeaderItem } from './THeaderItem';

interface Props {
    headerGroup: HeaderGroup<any>;
    sortingColumns?: string[];
    className?: string;
}

export function THeader(props: Props) {
    return (
        <tr>
            {props.headerGroup.headers.map((header) => (
                <THeaderItem
                    className={props.className}
                    key={header.id}
                    header={header}
                    sort={props.sortingColumns ? props.sortingColumns.includes(header.id) : false}
                />
            ))}
        </tr>
    );
}
