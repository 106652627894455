import { EntityType, Rights } from '@frontend/common';
import { Spinner } from '@frontend/elements';
import { IdentificationClient } from '@frontend/identification/api';
import { Identification, IdentificationType } from '@frontend/identification/types';
import { ObjectDetail2 } from '@frontend/rendering/details';
import { useAccountRepository } from '@frontend/repository';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../redux/store';

const ID = 'identification-detail';

const IdentificationDetail = () => {
    const {
        store: { currentAccount }
    } = useAccountRepository();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [object, changeObject] = useState<Identification | null>(null);
    if (!currentAccount) {
        return (
            <div id={ID}>
                <div className='card mt-3'>
                    <div className='card-header'>Identification</div>
                    <div className='card-body'>
                        <Spinner />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <ObjectDetail2
            fetch={(identificationId: string) => IdentificationClient.fetchIdentification(currentAccount, identificationId)}
            urlParamKeys={['identificationId']}
            baseRoute='/admin/identifications'
            id={ID}
            dispatch={dispatch}
            objectHeader={(obj) => obj.key}
            order={['key', 'entity_id', ...getRelevantIdentificationColumns(object?.type)]}
            eventListener={EntityType.IDENTIFICATION}
            object={changeObject}
            delete={{
                onDelete: (identificationId: string) => IdentificationClient.deleteIdentification(currentAccount, identificationId),
                requiredPermissions: (obj) => [
                    { item: EntityType.IDENTIFICATION, source: EntityType.ACCOUNT, right: Rights.DELETE, uid: obj.account_id },
                    { item: EntityType.IDENTIFICATION, source: EntityType.IDENTIFICATION, right: Rights.DELETE, uid: obj.id }
                ],
                propertyName: 'Identification',
                displayName: (obj) => obj.key
            }}
            update={{
                onUpdate: () => navigate('../update'),
                requiredPermissions: (obj) => [
                    { item: EntityType.IDENTIFICATION, source: EntityType.ACCOUNT, right: Rights.UPDATE, uid: obj.account_id },
                    { item: EntityType.IDENTIFICATION, source: EntityType.IDENTIFICATION, right: Rights.UPDATE, uid: obj.id }
                ]
            }}></ObjectDetail2>
    );
};

export default IdentificationDetail;

function getRelevantIdentificationColumns(type?: IdentificationType): (keyof Identification)[] {
    switch (type) {
        case IdentificationType.BASIC_AUTH:
            return ['secret'];
        default:
            return [];
    }
}
