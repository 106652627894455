import { ClassType, EntityType } from '@frontend/common';
import { Dropdown, DropdownItem, Spinner, StatusBadge } from '@frontend/elements';
import { EquipmentReportClient } from '@frontend/equipment/api';
import { EquipmentStateName, IEquipmentInventory } from '@frontend/equipment/types';
import { ExportClient } from '@frontend/export/api';
import { FormatTypeName } from '@frontend/impex/types';
import { CommonMessage } from '@frontend/lang';
import { ModuleBadge } from '@frontend/module/badge';
import { ObjectList } from '@frontend/rendering/list';
import { SlotBadge } from '@frontend/slot/badge';
import { SpotBadge } from '@frontend/spot/badge';
import { FormattedMessage } from 'react-intl';

import useEquipmentInventory from './equipment-inventory.controller';

const EquipmentInventory = () => {
    const viewProps = useEquipmentInventory();

    if (!viewProps.currentAccount) return <Spinner />;
    return (
        <ObjectList<IEquipmentInventory>
            fetch={(arg) => EquipmentReportClient.fetchEquipmentInventoryReport(viewProps.currentAccount!, arg)}
            dispatch={viewProps.dispatch}
            label={
                <FormattedMessage
                    id='equipment-inventory.label'
                    defaultMessage='Equipment Overview Report'
                    description='Label for the Equipment Overview Report'
                />
            }
            type={EntityType.EQUIPMENT}
            columns={{
                columns: ['product_id', 'product_attributes', 'serial_number', 'tag', 'location', 'state', 'user_id', 'problem_description'],
                headerOverwrite: new Map([['product_id', CommonMessage.OBJECTS.PRODUCT.DEFAULT]]),
                customCells: new Map([
                    [
                        'state',
                        (v) => (
                            <td>
                                <StatusBadge<EquipmentStateName>
                                    value={v.row.original.state}
                                    colorMap={
                                        new Map([
                                            [[EquipmentStateName.EQUIPMENT_CHARGING], ClassType.ALERT],
                                            [[EquipmentStateName.EQUIPMENT_CREATED], ClassType.SUCCESS]
                                        ])
                                    }
                                />
                            </td>
                        )
                    ],
                    [
                        'location',
                        (o) => {
                            const isAvailable = o.row.original.state === EquipmentStateName.EQUIPMENT_AVAILABLE;
                            if (isAvailable) {
                                return (
                                    <td className='align-items-center'>
                                        <SpotBadge id={o.row.original.spot_id ?? undefined} />
                                        <ModuleBadge id={o.row.original.module_id ?? undefined} />
                                        <SlotBadge id={o.row.original.slot_id ?? undefined} />
                                    </td>
                                );
                            } else {
                                return <td></td>;
                            }
                        }
                    ]
                ])
            }}
            filter={{
                keys: ['product_id', 'equipment_state', 'spot_id', 'module_id', 'slot_id'],
                default: true,
                queryParamKeyOverwrite: new Map([['equipment_state', 'state']])
            }}
            customHeaderComponent={
                <Dropdown label={CommonMessage.BUTTONS.EXPORT as any}>
                    {Object.values(FormatTypeName)
                        .filter((k) => k !== FormatTypeName.JSON)
                        .map((key) => (
                            <DropdownItem
                                key={key}
                                label={key.toLowerCase().replace(/_/g, ' ')}
                                onClick={() => ExportClient.exportEquipmentInventory(viewProps.currentAccount!, 'equipment-inventory', { format_type: key })}
                            />
                        ))}
                </Dropdown>
            }
        />
    );
};

export default EquipmentInventory;
