import { PaginatedResponse } from '@frontend/api-utils';
import { EntityType } from '@frontend/common';

export interface Role {
    name: string | null;
    item: string | null;
    uid: string;
    id: string;
    role_id: string | null;
    type: RoleType;
    account_id: string;
    entity_type: EntityType;
    entity_id: string;
    creation_timestamp: string;
    context: AuthorizationContext | null;
}

export type RoleListResponse = PaginatedResponse<Role>;

export interface CreateRole {
    name?: string | null;
    item?: string | null;
    uid?: string;
    type?: RoleType;
    role_id?: string | null;
    context?: AuthorizationContext | null;
}

export interface MultiRoleCreate {
    roles: CreateRole[];
}

export interface UpdateRole {
    name?: string | null;
    item?: string | null;
    role_id?: string | null;
    uid?: string;
    context?: AuthorizationContext | null;
}

export enum RoleType {
    CUSTOM = 'custom',
    PREDEFINED = 'predefined'
}

export interface CustomRoleWithPermissions {
    account_id: string;
    creation_timestamp: string;
    entity_type: EntityType;
    id: string;
    name: string;
    permissions: { [key: string]: string[] };
}

export interface AuthorizationContext {
    entity_type?: EntityType;
    entity_id?: string;
}
