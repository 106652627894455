import { EmailInput } from '@frontend/basic-forms';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import useForgotPassword from './forgot-password.controller';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const logo = require('../../../assets/images/telloport_logo_horizontal.png');
const ID = 'forgot-password';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const viewProps = useForgotPassword();
    return (
        <div
            id={ID}
            className='d-flex flex-column justify-content-center align-items-center m-5'>
            <form
                className='card w-50'
                onSubmit={viewProps.onSubmit}>
                <div className='card-header text-center'>
                    <img
                        src={logo}
                        className='img-fluid w-25'
                    />
                    <h4 className='m-3'>Forgot password</h4>
                    <div className='d-flex flex-column'>
                        <span className='text-sm font-weight-bolder col-12'>
                            <FormattedMessage
                                id='forgot-password.description'
                                description='Description for the forgot password page.'
                                defaultMessage="Enter your email address and we'll send you a link to reset your password."
                            />
                        </span>
                        <span className='text-sm font-weight-bolder col-12'>
                            <FormattedMessage
                                id='forgot-password.description2'
                                description='Description for the forgot password page.'
                                defaultMessage='If you do not receive an email, please check your spam folder.'
                            />
                        </span>
                    </div>
                </div>
                <div className='card-body d-flex flex-column align-items-center w-100'>
                    <EmailInput
                        className='w-100'
                        required
                        label='Email'
                        errorMessage='Please fill in a valid email.'
                        autoFocus
                        value={viewProps.formValues.email}
                        onChange={(value) => viewProps.changeFormValues({ ...viewProps.formValues, email: value })}
                        isValidCallback={(value) => {
                            if (viewProps.submitted) viewProps.changeEmailValid(value);
                        }}
                        submitted={viewProps.submitted}
                        valid={viewProps.emailValid}
                        noConditionedStyling
                    />
                </div>

                <div className='card-footer text-center'>
                    <div className='col-12'>
                        <button
                            type='submit'
                            className='btn btn-primary'
                            disabled={!viewProps.emailValid}>
                            Submit
                        </button>
                    </div>
                    <div className='col-12'>
                        <span
                            className='text-sm font-weight-bolder cursor-pointer'
                            onClick={() => navigate('/admin/')}>
                            Go back
                        </span>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ForgotPassword;
