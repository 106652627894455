import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Route } from 'react-router-dom';

const UnknownOverview = lazy(() => import('../unknown/unknown-overview/unknown-overview'));
const UnknownDetail = lazy(() => import('../unknown/unknown-detail/unknown-detail.component'));
const UnknownCreate = lazy(() => import('../unknown/unknown-create/unknown-create.component'));

export function UnknownRoutes() {
    return (
        <Route path='unknowns'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <UnknownOverview />
                    </AsyncComponent>
                }
            />
            <Route path=':unknownId'>
                <Route
                    index
                    path='detail'
                    element={
                        <AsyncComponent>
                            <UnknownDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='create-iot'
                    element={
                        <AsyncComponent>
                            <UnknownCreate />
                        </AsyncComponent>
                    }
                />
            </Route>
        </Route>
    );
}
