import { APIClient, ApiQueryParams, buildApiEndpoint } from '@frontend/api-utils';
import { ChartQueryParams, PieData } from '@frontend/chart/types';

const endpoint = '/equipment-api/v1';

export class EquipmentChartClient extends APIClient {
    public static async fetchAllEquipmentState(accountId: string, queryParams?: ApiQueryParams<ChartQueryParams>): Promise<PieData> {
        const url = buildApiEndpoint(`${endpoint}/accounts/${accountId}/equipment-state-all-chart`, queryParams);
        const response = await this.fetch(url);
        return await this.handleResponse<PieData>(response);
    }

    public static async fetchAvailableEquipmentState(accountId: string, queryParams?: ApiQueryParams<ChartQueryParams>): Promise<PieData> {
        const url = buildApiEndpoint(`${endpoint}/accounts/${accountId}/equipment-state-available-chart`, queryParams);
        const response = await this.fetch(url);
        return await this.handleResponse<PieData>(response);
    }
}
