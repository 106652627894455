import { FaDoorOpen, FaEdit, FaFileExport, FaFileImport, FaLink, FaPlus, FaTrash, FaUpload } from 'react-icons/fa';
import { FaCheck, FaLinkSlash, FaRegCopy } from 'react-icons/fa6';
import { HiHome } from 'react-icons/hi';
import { HiOutlineCog6Tooth } from 'react-icons/hi2';
import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5';
import { LuLogOut } from 'react-icons/lu';
import { MdArrowBackIos, MdFileUpload } from 'react-icons/md';
import { TbExchange, TbPasswordUser } from 'react-icons/tb';

export const BUTTON_ICONS = {
    HOME: HiHome,
    LOGOUT: LuLogOut,
    UPDATE: FaEdit,
    DELETE: FaTrash,
    IMPORT: FaFileImport,
    EXPORT: FaFileExport,
    CREATE: FaPlus,
    SETTINGS: HiOutlineCog6Tooth,
    UPLOAD: MdFileUpload,
    LINK: FaLink,
    UNLINK: FaLinkSlash,
    OPEN: FaDoorOpen,
    CHANGE_STATE: TbExchange,
    CHANGE_PASSWORD: TbPasswordUser,
    BACK: MdArrowBackIos,
    CONFIRM: FaCheck,
    CHEVRON_UP: IoChevronUpOutline,
    CHEVRON_DOWN: IoChevronDownOutline,
    SEED: FaUpload,
    COPY: FaRegCopy
};
