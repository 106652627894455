import { Dimension } from '@frontend/common';
import { CommonMessage } from '@frontend/lang';
import React from 'react';

import { NumberInput } from '../number-input/number-input.component';
import SelectInputV2 from '../select/select-input-v2/select-input-v2.component';
import useDimensionInput from './dimension-input.controller';

const ID = 'dimension-input';
export interface DimensionInputProps {
    id?: string;
    label?: string;
    className?: string;
    required?: boolean;
    submitted: boolean;
    onChange: (newValue: Dimension) => void;
    value?: Dimension;
}

export const DimensionInput = (props: DimensionInputProps) => {
    const viewProps = useDimensionInput(props);

    return (
        <div
            id={ID}
            className='d-flex flex-column w-100'>
            <h6 className='ps-1 mt-2 mb-0'>{props.label}</h6>
            <div className='d-flex flex-row justify-content-between w-100'>
                <div className='w-30 m-1'>
                    <NumberInput
                        id='height'
                        label={CommonMessage.OBJECTS.DIMENSION.HEIGHT}
                        required={props.required}
                        min={0}
                        submitted={props.submitted}
                        value={viewProps.dimension.height}
                        onChange={(value) => {
                            viewProps.changeDimension({ ...viewProps.dimension, height: value });
                        }}
                    />
                </div>
                <div className='w-30 m-1'>
                    <NumberInput
                        id='width'
                        label={CommonMessage.OBJECTS.DIMENSION.WIDTH}
                        required={props.required}
                        min={0}
                        submitted={props.submitted}
                        value={viewProps.dimension.width}
                        onChange={(value) => {
                            viewProps.changeDimension({ ...viewProps.dimension, width: value });
                        }}
                    />
                </div>
                <div className='w-30 m-1'>
                    <NumberInput
                        id='depth'
                        label={CommonMessage.OBJECTS.DIMENSION.DEPTH}
                        required={props.required}
                        min={0}
                        submitted={props.submitted}
                        value={viewProps.dimension.depth}
                        onChange={(value) => {
                            viewProps.changeDimension({ ...viewProps.dimension, depth: value });
                        }}
                    />
                </div>
                <div className='w-30 m-1'>
                    <SelectInputV2
                        options={[
                            { label: 'box', value: 'box' },
                            { label: 'pie', value: 'pie' }
                        ]}
                        id='type'
                        label={CommonMessage.OBJECTS.COMMON_FIELDS.TYPE}
                        required={props.required}
                        value={viewProps.dimension.type}
                        onSelect={(value) => {
                            viewProps.changeDimension({ ...viewProps.dimension, type: value ? (value as any).value : 'box' });
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default DimensionInput;
