import { APIClient, ApiQueryParams } from "@frontend/api-utils";
import { CopyModuleTemplate, CreateModuleTemplate, ModuleTemplate, ModuleTemplateListResponse, ModuleTemplateQueryParams, UpdateModuleTemplate } from "@frontend/module/types";

const endpoint = '/module-api/v1';

export class ModuleTemplateClient extends APIClient {
    public static async fetchModuleTemplates(queryParams?: ApiQueryParams<ModuleTemplateQueryParams>): Promise<ModuleTemplateListResponse> {
        return await this.apiPaginated<ModuleTemplateListResponse, ModuleTemplateQueryParams>(`${endpoint}/templates`, queryParams);
    }

    public static async resolveModuleTemplates(ids: string[], field: keyof ModuleTemplate = 'id'): Promise<ModuleTemplateListResponse> {
        const response = await this.post(`${endpoint}/templates-resolve`, { field, ids });
        return await this.handleResponse<ModuleTemplateListResponse>(response);
    }

    public static async deleteModuleTemplates(ids: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/templates-delete`, { ids });
        return await this.handleVoidResponse(response);
    }

    public static async postModuleTemplate(accountId: string, template: CreateModuleTemplate): Promise<ModuleTemplate> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/templates`, template);
        return await this.handleResponse<ModuleTemplate>(response);
    }

    public static async fetchModuleTemplate(accountId: string, templateId: string): Promise<ModuleTemplate> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/templates/${templateId}`);
        return await this.handleResponse<ModuleTemplate>(response);
    }

    public static async patchModuleTemplate(accountId: string, templateId: string, body: UpdateModuleTemplate): Promise<ModuleTemplate> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/templates/${templateId}`, body);
        return await this.handleResponse<ModuleTemplate>(response);
    }

    public static async deleteModuleTemplate(accountId: string, templateId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/templates/${templateId}`);
        return await this.handleVoidResponse(response);
    }
        
    public static async copyAccountModuleTemplate(accountId: string, moduleTemplateId: string, body: CopyModuleTemplate): Promise<ModuleTemplate> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/templates/${moduleTemplateId}/copy`, body);
        return await this.handleResponse<ModuleTemplate>(response);
    }

}