import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Route } from 'react-router-dom';

import NotificationEmailCopy from './notification-email-update/notification-email-copy/notification-email-copy.component';
import NotificationEmailUpdate from './notification-email-update/notification-email-update.component';

const NotificationEmailOverview = lazy(() => import('./notification-email-overview'));
const NotificationEmailDetail = lazy(() => import('./notification-email-detail/notification-email-detail.component'));
const NotificationEmailCreate = lazy(() => import('./notification-email-create/notification-email-create.component'));

export function NotificationEmailRoutes() {
    return (
        <Route path='notification-emails'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <NotificationEmailOverview />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <NotificationEmailCreate />
                    </AsyncComponent>
                }
            />
            <Route path=':emailId'>
                <Route
                    index
                    path='detail'
                    element={
                        <AsyncComponent>
                            <NotificationEmailDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    index
                    path='update'
                    element={
                        <AsyncComponent>
                            <NotificationEmailUpdate />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='copy'
                    element={
                        <AsyncComponent>
                            <NotificationEmailCopy />
                        </AsyncComponent>
                    }
                />
            </Route>
        </Route>
    );
}

export default NotificationEmailRoutes;
