import { Authentication } from '@frontend/authentication-v2';
import { AuthorizationProvider } from '@frontend/authorization';
import { ClassType, EntityType, useLocalStorageState, useScripts } from '@frontend/common';
import { AsyncComponent, Profile } from '@frontend/elements';
import { EventType } from '@frontend/events/types';
import { Header } from '@frontend/header';
import { IdentificationType } from '@frontend/identification/types';
import { CommonMessage, TranslationProvider } from '@frontend/lang';
import { AccountStoreProvider, DocumentStoreProvider } from '@frontend/repository';
import { lazy } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Footer from './footer/footer';
import Navbar from './navigation/navbar/navbar.component';
import { useAppDispatch } from './redux/store';
import Unauthorized from './unauthorized/unauthorized';
import Resolver from './utils/resolver';

const Configuration = lazy(() => import('./configuration/configuration.component'));

// #region PuDo
const PuDoTransactionOverview = lazy(() => import('./pudo/pudo-transaction-overview/pudo-transaction-overview.component'));
const PudoTransactionCreate = lazy(() => import('./pudo/pudo-transaction-create/pudo-transaction-create.component'));
const PudoTransactionDetail = lazy(() => import('./pudo/pudo-transaction-detail/pudo-transaction-detail.component'));
// #endregion
// #region AddressBook
const AddressBookContactOverview = lazy(() => import('./address-book/contact-overview/contact-overview.component'));
const AddressBookContactCreate = lazy(() => import('./address-book/contact-create/contact-create.component'));
const AddressBookContactDetail = lazy(() => import('./address-book/contact-detail/contact-detail.component'));
const AddressBookContactUpdate = lazy(() => import('./address-book/contact-update/contact-update.component'));
// #endregion
// #region Accounts
const AccountOverview = lazy(() => import('./account/account-overview'));
const AccountCreate = lazy(() => import('./account/account-create/account-create.component'));
const AccountDetail = lazy(() => import('./account/account-detail/account-detail.component'));
const AccountUpdate = lazy(() => import('./account/account-update/account-update.component'));
const AccountBrandingUpdate = lazy(() => import('./account/branding-update/branding-update.component'));
// #endregion
// #region Address
const AddressOverview = lazy(() => import('./address/address-overview'));
const AddressCreate = lazy(() => import('./address/address-create/address-create.component'));
const AddressDetail = lazy(() => import('./address/address-detail/address-detail.component'));
const AddressUpdate = lazy(() => import('./address/address-update/address-update.component'));
const AddressEntityLink = lazy(() => import('./address/address-entity-link/address-entity-link.component'));
const EntityToAddressLink = lazy(() => import('./address/entity-to-address-link/entity-to-address-link.component'));
// #endregion
// #region User Groups
const UserGroupCreate = lazy(() => import('./user-group/user-group-create/user-group-create.component'));
const UserGroupOverview = lazy(() => import('./user-group/user-group-overview'));
const UserGroupDetail = lazy(() => import('./user-group/user-group-detail/user-group-detail.component'));
const UserGroupUpdate = lazy(() => import('./user-group/user-group-update/user-group-update.component'));
const UserGroupMemberAdd = lazy(() => import('./user-group/user-group-member-add/user-group-member-add.component'));
const LinkBudgetToUsers = lazy(() => import('./user/link-budgets-to-user/link-budget-to-users.component'));
// #endregion
// #region Product Groups
const ProductGroupCreate = lazy(() => import('./product-group/product-group-create/product-group-create.component'));
const ProductGroupOverview = lazy(() => import('./product-group/product-group-overview'));
const ProductGroupDetail = lazy(() => import('./product-group/product-group-detail/product-group-detail.component'));
const ProductGroupUpdate = lazy(() => import('./product-group/product-group-update/product-group-update.component'));
const ProductGroupMemberAdd = lazy(() => import('./product-group/product-group-member-add/product-group-member-add.component'));
// #endregion
// #region Equipment Groups
const EquipmentGroupCreate = lazy(() => import('./equipment-group/equipment-group-create/equipment-group-create.component'));
const EquipmentGroupDetail = lazy(() => import('./equipment-group/equipment-group-detail/equipment-group-detail.component'));
const EquipmentGroupOverview = lazy(() => import('./equipment-group/equipment-group-overview'));
const EquipmentGroupUpdate = lazy(() => import('./equipment-group/equipment-group-update/equipment-group-update.component'));
const EquipmentGroupMemberAdd = lazy(() => import('./equipment-group/equipment-group-member-add/equipment-group-member-add.component'));
// #endregion
// #region Contact Groups
const ContactGroupCreate = lazy(() => import('./contact-group/contact-group-create/contact-group-create.component'));
const ContactGroupOverview = lazy(() => import('./contact-group/contact-group-overview/contact-group-overview'));
const ContactGroupDetail = lazy(() => import('./contact-group/contact-group-detail/contact-group-detail.component'));
const ContactGroupMemberAdd = lazy(() => import('./contact-group/contact-group-member-add/contact-group-member-add.component'));
const ContactGroupUpdate = lazy(() => import('./contact-group/contact-group-update/contact-group-update.component'));
// #endregion
// #region Cost center
const CostCenterDetail = lazy(() => import('./cost-center/cost-center-detail/cost-center-detail.component'));
const CostCenterOverview = lazy(() => import('./cost-center/cost-center-overview'));
const CostCenterCreate = lazy(() => import('./cost-center/cost-center-create/cost-center-create.component'));
const CostCenterUpdate = lazy(() => import('./cost-center/cost-center-update/cost-center-update.component'));
// #endregion
// #region Budget
const BudgetOverview = lazy(() => import('./budget/budget-overview'));
const BudgetCreate = lazy(() => import('./budget/budget-create/budget-create.component'));
const BudgetDetail = lazy(() => import('./budget/budget-detail/budget-detail.component'));
const BudgetUpdate = lazy(() => import('./budget/budget-update/budget-update.component'));
const BudgetRuleCreate = lazy(() => import('./budget/budget-rule-create/budget-rule-create.component'));
const BudgetRuleUpdate = lazy(() => import('./budget/budget-rule-update/budget-rule-update.component'));
const BudgetEntityLink = lazy(() => import('./budget/budget-entity-link/budget-entity-link.component'));
const EntityToBudgetLink = lazy(() => import('./budget/entity-to-budget-link/entity-to-budget-link.component'));
// #endregion
// #region Document
const DocumentOverview = lazy(() => import('./document/document-overview'));
const DocumentDetail = lazy(() => import('./document/document-detail/document-detail.component'));
const DocumentFileUpload = lazy(() => import('./document/file-upload/file-upload.component'));
// #endregion
// #region  Tags
const LinkTag = lazy(() => import('./identification/link-tag/link-tag.component'));
const TagOverview = lazy(() => import('./tag/tag-overview'));
const TagDetail = lazy(() => import('./tag/tag-detail/tag-detail.component'));
// #endregion
// #endregion
// #region Roles
const RoleOverview = lazy(() => import('./role/role-overview'));
const RoleCreate = lazy(() => import('./role/role-create/role-create.component'));
const RoleDetail = lazy(() => import('./role/role-detail/role-detail.component'));
const RoleUpdate = lazy(() => import('./role/role-update/role-update.component'));
const RoleCreate2 = lazy(() => import('./role/role-create-2/role-create-2.component'));
// #endregion
// #region Question Groups
const QuestionGroupCreate = lazy(() => import('./question-group/question-group-create/question-group-create.component'));
const QuestionGroupDetail = lazy(() => import('./question-group/question-group-detail/question-group-detail.component'));
const QuestionGroupOverview = lazy(() => import('./question-group/question-group-overview'));
const QuestionGroupUpdate = lazy(() => import('./question-group/question-group-update/question-group-update.component'));
const QuestionGroupMemberAdd = lazy(() => import('./question-group/question-group-member-add/question-group-member-add.component'));
const QuestionGroupMemberUpdate = lazy(
    () => import('./question-group/question-group-detail/question-group-member-update/question-group-member-update.component')
);
// #endregion
// #region Question
const QuestionOverview = lazy(() => import('./question/question-overview'));
const QuestionCreate = lazy(() => import('./question/question-create/question-create.component'));
const QuestionDetail = lazy(() => import('./question/question-detail/question-detail.component'));
const QuestionUpdate = lazy(() => import('./question/question-update/question-update.component'));
// #endregion
// #region Answer
const AnswerOverview = lazy(() => import('./question/answer/answer-overview'));
const AnswerCreate = lazy(() => import('./question/answer/answer-create/answer-create.component'));
const AnswerDetail = lazy(() => import('./question/answer/answer-detail/answer-detail.component'));
const AnswerUpdate = lazy(() => import('./question/answer/answer-update/answer-update.component'));
// #endregion
// #region Playlist
const PlaylistOverview = lazy(() => import('./playlist/playlist-overview'));
const PlaylistCreate = lazy(() => import('./playlist/playlist-create/playlist-create.component'));
const PlaylistDetail = lazy(() => import('./playlist/playlist-detail/playlist-detail.component'));
const PlaylistUpdate = lazy(() => import('./playlist/playlist-update/playlist-update.component'));
const PlaylistItemCreate = lazy(() => import('./playlist/playlist-item-create/playlist-item-create.component'));
const PlaylistItemDetail = lazy(() => import('./playlist/playlist-item-detail/playlist-item-detail.component'));
const PlaylistItemUpdate = lazy(() => import('./playlist/playlist-item-update/playlist-item-update.component'));
// #endregion
// #region Impex
const ImpexOverview = lazy(() => import('./impex/impex-overview'));
const ImpexCreate = lazy(() => import('./impex/impex-create/impex-create.component'));
const ImpexDetail = lazy(() => import('./impex/impex-detail/impex-detail.component'));
// #endregion
// #region Courier
const CourierOverview = lazy(() => import('./courier/courier-overview'));
const CourierCreate = lazy(() => import('./courier/courier-create/courier-create.component'));
const CourierDetail = lazy(() => import('./courier/courier-detail/courier-detail.component'));
const CourierUpdate = lazy(() => import('./courier/courier-update/courier-update.component'));
// #endregion
// #region Spots
const SpotOverview = lazy(() => import('./spot/spot-overview'));
const SpotCreate = lazy(() => import('./spot/spot-create/spot-create.component'));
const SpotDetail = lazy(() => import('./spot/spot-detail/spot-detail.component'));
const SpotUpdate = lazy(() => import('./spot/spot-update/spot-update.component'));
const SpotSettingsUpdate = lazy(() => import('./spot/spot-settings-update/spot-settings-update.component'));
// #endregion
// #region Modules
const ModuleOverview = lazy(() => import('./module/module-overview'));
const ModuleCreate = lazy(() => import('./module/module-create/module-create.component'));
const ModuleDetail = lazy(() => import('./module/module-detail/module-detail.component'));
const ModuleUpdate = lazy(() => import('./module/module-update/module-update.component'));
// #endregion
// #region Module templates
const ModuleTemplateOverview = lazy(() => import('./module-template/module-template-overview'));
const ModuleTemplateCreate = lazy(() => import('./module-template/module-template-create/module-template-create.component'));
const ModuleTemplateDetail = lazy(() => import('./module-template/module-template-detail/module-template-detail.component'));
const ModuleTemplateUpdate = lazy(() => import('./module-template/module-template-update/module-template-update.component'));
// #endregion
// #region Equipment
const EquipmentOverview = lazy(() => import('./equipment/equipment-overview'));
const EquipmentCreate = lazy(() => import('./equipment/equipment-create/equipment-create.component'));
const EquipmentDetail = lazy(() => import('./equipment/equipment-detail/equipment-detail.component'));
const EquipmentUpdate = lazy(() => import('./equipment/equipment-update/equipment-update.component'));
// #endregion
// #region Transaction
const TransactionOverview = lazy(() => import('./transaction/transaction-overview'));
const TransactionCreate = lazy(() => import('./transaction/pudo-transaction-create/pudo-transaction-create.component'));
const TransactionDetail = lazy(() => import('./transaction/transaction-detail/transaction-detail.component'));
// #region Slots
const SlotCreate = lazy(() => import('./slot/slot-create/slot-create.component'));
const SlotDetail = lazy(() => import('./slot/slot-detail/slot-detail.component'));
const SlotUpdate = lazy(() => import('./slot/slot-update/slot-update.component'));
// #endregion
// #region Slot templates
const SlotTemplateOverview = lazy(() => import('./slot-template/slot-template-overview'));
const SlotTemplateCreate = lazy(() => import('./slot-template/slot-template-create/slot-template-create.component'));
const SlotTemplateDetail = lazy(() => import('./slot-template/slot-template-detail/slot-template-detail.component'));
const SlotTemplateUpdate = lazy(() => import('./slot-template/slot-template-update/slot-template-update.component'));
// #endregion
// #region Users
const UserOverview = lazy(() => import('./user/user-overview'));
const UserDetail = lazy(() => import('./user/user-detail/user-detail.component'));
const UserCreate = lazy(() => import('./user/user-create/user-create.component'));
const UserUpdate = lazy(() => import('./user/user-update/user-update.component'));
const UserContactInformationUpdate = lazy(() => import('./user/user-contact-information-update/user-contact-information-update.component'));
// #endregion
// #region Products
const ProductOverview = lazy(() => import('./product/product-overview'));
const ProductCreate = lazy(() => import('./product/product-create/product-create.component'));
const ProductDetail = lazy(() => import('./product/product-detail/product-detail.component'));
const ProductUpdate = lazy(() => import('./product/product-update/product-update.component'));
const AddCertificate = lazy(() => import('./product/product-detail/add-certificate/add-certificate.component'));
// #endregion
// #region Variants
const VariantOverview = lazy(() => import('./variant/variant-overview'));
const VariantCreate = lazy(() => import('./variant/variant-create/variant-create.component'));
const VariantDetail = lazy(() => import('./variant/variant-detail/variant-detail.component'));
const VariantUpdate = lazy(() => import('./variant/variant-update/variant-update.component'));
// #endregion
// #region Requirements
const RequirementCreate = lazy(() => import('./requirement/requirement-create/requirement-create.component'));
const RequirementDetail = lazy(() => import('./requirement/requirement-detail/requirement-detail.component'));
const RequirementUpdate = lazy(() => import('./requirement/requirement-update/requirement-update.component'));
// #endregion
// #region Variants
const AttributeOverview = lazy(() => import('./attribute/attribute-overview'));
const AttributeCreate = lazy(() => import('./attribute/attribute-create/attribute-create.component'));
const AttributeDetail = lazy(() => import('./attribute/attribute-detail/attribute-detail.component'));
const AttributeUpdate = lazy(() => import('./attribute/attribute-update/attribute-update.component'));
const AttributeLinkingDetail = lazy(() => import('./attribute/attribute-linking-detail/attribute-linking-detail.component'));
const AttributeLinkingCreate = lazy(() => import('./attribute/attribute-linking-create/attribute-linking-create.component'));
const AttributeLinkingUpdate = lazy(() => import('./attribute/attribute-linking-update/attribute-linking-update.component'));
// #endregion
// #region Equipment Problems
const ProblemCreate = lazy(() => import('./problem/problem-create/problem-create.component'));
const ProblemDetail = lazy(() => import('./problem/problem-detail/problem-detail.component'));
const ProblemUpdate = lazy(() => import('./problem/problem-update/problem-update.component'));
const ProblemOverview = lazy(() => import('./problem/problem-overview/problem-overview'));
const ProblemEntityLink = lazy(() => import('./problem/problem-entity-link/problem-entity-link/problem-entity-link.component'));
const ReportProblem = lazy(() => import('./equipment/report-problem/report-problem.component'));
// #endregion
// #region License
const LicenseCreate = lazy(() => import('./license/license-create/license-create.component'));
// #endregion
// #region Certificates
const CertificateOverview = lazy(() => import('./certificate/certificate-overview/certificate-overview'));
const CertificateCreate = lazy(() => import('./certificate/certificate-create/certificate-create.component'));
const CertificateUpdate = lazy(() => import('./certificate/certificate-update/certificate-update.component'));
const CertificateDetail = lazy(() => import('./certificate/certificate-detail/certificate-detail.component'));
// #endregion
// #region IoT
const IoTOverview = lazy(() => import('./iot/iot-overview/iot-overview'));
const IoTCreate = lazy(() => import('./iot/iot-create/iot-create.component'));
const IoTDetail = lazy(() => import('./iot/iot-detail/iot-detail.component'));
const IoTUpdate = lazy(() => import('./iot/iot-update/iot-update.component'));
const IoTSeed = lazy(() => import('./iot/iot-seed/iot-seed.component'));
const AttachModuleToIot = lazy(() => import('./iot/attach-module/attach-module.component'));
const IoTSettingsUpdate = lazy(() => import('./iot/iot-settings-update/iot-settings-update.component'));
// #endregion
// #region Devices
const DeviceCreate = lazy(() => import('./device/device-create/device-create.component'));
const DeviceDetail = lazy(() => import('./device/device-detail/device-detail.component'));
const DeviceOverview = lazy(() => import('./device/device-overview/device-overview'));
const DeviceUpdate = lazy(() => import('./device/device-update/device-update.component'));
// #endregion
// #region Identifications
const IdentificationDetail = lazy(() => import('./identification/identification-detail/identification-detail.component'));
const IdentificationCreate = lazy(() => import('./identification/identification-create/identification-create.component'));
// #endregion
// #region Workflows
const WorkflowCreate = lazy(() => import('./workflow/workflow-create/workflow-create.component'));
const WorkflowUpdate = lazy(() => import('./workflow/workflow-update/workflow-update.component'));
const WorkflowDetail = lazy(() => import('./workflow/workflow-detail/workflow-detail.component'));
const WorkflowOverview = lazy(() => import('./workflow/workflow-overview'));
const WorkflowCopy = lazy(() => import('./workflow/workflow-copy/workflow-copy.component'));
const StepCreate = lazy(() => import('./workflow/step/step-create/step-create.component'));
const FieldsUpdate = lazy(() => import('./workflow/fields/fields-update/fields-update.component'));
// #endregion
// #region User interfaces
const UserInterfaceCreate = lazy(() => import('./user-interface/user-interface-create/user-interface-create.component'));
const UserInterfaceUpdate = lazy(() => import('./user-interface/user-interface-update/user-interface-update.component'));
const UserInterfaceDetail = lazy(() => import('./user-interface/user-interface-detail/user-interface-detail.component'));
const UserInterfaceButtonCreate = lazy(() => import('./user-interface/user-interface-button-create/user-interface-button-create.component'));
const UserInterfaceOverview = lazy(() => import('./user-interface/user-interface-overview'));
const UserInterfaceButtonUpdate = lazy(() => import('./user-interface/user-interface-button-update/user-interface-button-update.component'));
// #endregion
// #region Unknowns
const UnknownOverview = lazy(() => import('./unknown/unknown-overview/unknown-overview'));
const UnknownDetail = lazy(() => import('./unknown/unknown-detail/unknown-detail.component'));
const UnknownCreate = lazy(() => import('./unknown/unknown-create/unknown-create.component'));
// #endregion
// #region Lockers
const LockerDetail = lazy(() => import('./locker/locker-detail/locker-detail.component'));
// #endregion
// #region Stocks
const StockOverview = lazy(() => import('./stock/stock-overview/stock-overview'));
const StockCreate = lazy(() => import('./stock/stock-create/stock-create.component'));
const StockDetail = lazy(() => import('./stock/stock-detail/stock-detail.component'));
const StockUpdate = lazy(() => import('./stock/stock-update/stock-update.component'));
// #endregion
// #region Movements
const MovementOverview = lazy(() => import('./movement/movement-overview/movement-overview'));
const MovementDetail = lazy(() => import('./movement/movement-detail/movement-detail.component'));
// #endregion

// #region Warehouse
const StockOverviewReport = lazy(() => import('./stock/stock-overview-report/stock-overview-report'));
const MovementOverviewReport = lazy(() => import('./movement/movement-overview-report/movement-overview-report'));
// #endregion
// #region Budget uses
const BudgetUseCreate = lazy(() => import('./budget-use/budget-use-create/budget-use-create.component'));
// #endregion
// #region Packages
const PackageOverview = lazy(() => import('./package/package-overview/package-overview'));
const PackageDetail = lazy(() => import('./package/package-detail/package-detail.component'));
// #endregion
// #region Equipment Variables
const EquipmentVariableCreate = lazy(() => import('./equipment-variable/equipment-variable-create/equipment-variable-create.component'));
const EquipmentVariableUpdate = lazy(() => import('./equipment-variable/equipment-variable-update/equipment-variable-update.component'));
// #endregion
// #region Change Password
const ChangePassword = lazy(() => import('./authentication/change-password/change-password.component'));
// #endregion
// #region User Permissions
const UserPermissionsList = lazy(() => import('./permissions/user-permissions-list/user-permissions-list.component'));
// #endregion
// #region Contacts
const ContactOverview = lazy(() => import('./contact/contact-overview/contact-overview'));
const ContactDetail = lazy(() => import('./contact/contact-detail/contact-detail.component'));
const ContactCreate = lazy(() => import('./contact/contact-create/contact-create.component'));
const ContactUpdate = lazy(() => import('./contact/contact-update/contact-update.component'));
// #endregion
//#region Seeds
const SeedOverview = lazy(() => import('./sync/seed-overview/seed-overview'));
// #endregion
// #region Events
const AllEventsOverview = lazy(() => import('./event/all-events-overview/all-events-overview'));
// #endregion
// #region User Certificates
const UserCertificateCreate = lazy(() => import('./user-certificate/user-certificate-create/user-certificate-create.component'));
const UserCertificateDetail = lazy(() => import('./user-certificate/user-certificate-detail/user-certificate-detail.component'));
const UserCertificateUpdate = lazy(() => import('./user-certificate/user-certificate-update/user-certificate-update.component'));
// #endregion
// #region Equipment Certificates
const EquipmentCertificateCreate = lazy(() => import('./equipment-certificate/equipment-certificate-create/equipment-certificate-create.component'));
const EquipmentCertificateDetail = lazy(() => import('./equipment-certificate/equipment-certificate-detail/equipment-certificate-detail.component'));
const EquipmentCertificateUpdate = lazy(() => import('./equipment-certificate/equipment-certificate-update/equipment-certificate-update.component'));
// #endregion
// #region Notification Templates
const NotificationTemplateOverview = lazy(() => import('./notification-template/notification-template-overview/notification-template-overview'));
const NotificationTemplateDetail = lazy(() => import('./notification-template/notification-template-detail/notification-template-detail.component'));
const NotificationTemplateCreate = lazy(() => import('./notification-template/notification-template-create/notification-template-create.component'));
const NotificationTemplateCopy = lazy(() => import('./notification-template/notification-template-copy/notification-template-copy.component'));
// #endregion
// #region Notification Contents
const NotificationContentCreate = lazy(() => import('./notification-content/notification-content-create/notification-content-create.component'));
const NotificationContentDetail = lazy(() => import('./notification-content/notification-content-detail/notification-content-detail.component'));
// #region Constraints
const ConstraintCreate = lazy(() => import('./constraint/constraint-create/constraint-create.component'));
const ConstraintUpdate = lazy(() => import('./constraint/constraint-udpate/constraint-update.component'));
// #endregion
// #region Product Categories
const ProductCategoryOverview = lazy(() => import('./product-category/product-category-overview/product-category-overview'));
const ProductCategoryCreate = lazy(() => import('./product-category/product-category-create/product-category-create.component'));
const ProductCategoryDetail = lazy(() => import('./product-category/product-category-detail/product-category-detail.component'));
const ProductCategoryUpdate = lazy(() => import('./product-category/product-category-update/product-category-update.component'));
const ProductCategoryMemberAdd = lazy(() => import('./product-category/members/members-add/product-category-members-add.component'));
const ProductCategoryCategoryAdd = lazy(() => import('./product-category/members/category-add/category-add.component'));
// #endregion
// #region Statistics
const Statistics = lazy(() => import('./statistics/statistics'));
const TotalFillRateDetail = lazy(() => import('./statistics/total-fill-rate/total-fill-rate-detail/total-fill-rate-detail.component'));
const FillRateByProductDetail = lazy(() => import('./statistics/fill-rate-by-product/fill-rate-by-product-detail/fill-rate-by-product-detail'));
const ActivityPerDayDetail = lazy(() => import('./statistics/activity-per-day/activity-per-day-detail/activity-per-day-detail.component'));
const ActivityPerHourDetail = lazy(() => import('./statistics/activity-per-hour/activity-per-hour-detail/activity-per-hour-detail.component'));
// #endregion

const envColor =
    process.env['NX_BUILD_ENV'] === 'development' ? ClassType.INFO : process.env['NX_BUILD_ENV'] === 'test' ? ClassType.SECONDARY : ClassType.PRIMARY;

export function App() {
    useScripts('https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js');
    useScripts('https://kit.fontawesome.com/42d5adcbca.js');
    const dispatch = useAppDispatch();
    const [navbarPinned] = useLocalStorageState('navbar-pinned', false);
    const [hovered] = useLocalStorageState('navbar-hovered', false);

    return (
        <TranslationProvider>
            <DocumentStoreProvider>
                <div className={`g-sidenav-show bg-gray-100 g-sidenav-${navbarPinned || hovered ? 'pinned' : 'hidden'}`}>
                    <div className={`min-height-300 bg-${envColor} position-absolute w-100 sticky-top top-bar`} />
                    <Router>
                        <Authentication>
                            <AccountStoreProvider>
                                <AuthorizationProvider dispatch={dispatch}>
                                    <Resolver>
                                        <Navbar />
                                        <main className='main-content position-relative border-radius-lg'>
                                            <Header
                                                dispatch={dispatch}
                                                color={envColor}
                                            />
                                            <div className='container-fluid ps-4'>
                                                <AsyncComponent>
                                                    <Routes>
                                                        <Route path='pudo'>
                                                            <Route
                                                                index
                                                                element={
                                                                    <AsyncComponent>
                                                                        <PuDoTransactionOverview />
                                                                    </AsyncComponent>
                                                                }
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={
                                                                    <AsyncComponent>
                                                                        <PudoTransactionCreate />
                                                                    </AsyncComponent>
                                                                }
                                                            />
                                                            <Route path=':transactionId'>
                                                                <Route
                                                                    index
                                                                    path='detail'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <PudoTransactionDetail />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                        </Route>
                                                        <Route path='address-book'>
                                                            <Route
                                                                index
                                                                element={
                                                                    <AsyncComponent>
                                                                        <AddressBookContactOverview />
                                                                    </AsyncComponent>
                                                                }
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={
                                                                    <AsyncComponent>
                                                                        <AddressBookContactCreate />
                                                                    </AsyncComponent>
                                                                }
                                                            />
                                                            <Route path=':contactId'>
                                                                <Route
                                                                    index
                                                                    path='detail'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <AddressBookContactDetail />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    index
                                                                    path='update'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <AddressBookContactUpdate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                        </Route>
                                                        <Route path='admin'>
                                                            <Route
                                                                index
                                                                element={
                                                                    <AsyncComponent>
                                                                        <Configuration />
                                                                    </AsyncComponent>
                                                                }
                                                            />
                                                            <Route path='accounts'>
                                                                {/**
                                                        // #region Accounts
                                                        * */}
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <AccountOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <AccountCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route path=':accountId'>
                                                                    <Route
                                                                        index
                                                                        path='detail'
                                                                        element={
                                                                            <AsyncComponent>
                                                                                <AccountDetail />
                                                                            </AsyncComponent>
                                                                        }
                                                                    />
                                                                    <Route
                                                                        path='update'
                                                                        element={
                                                                            <AsyncComponent>
                                                                                <AccountUpdate />
                                                                            </AsyncComponent>
                                                                        }
                                                                    />
                                                                    <Route
                                                                        path='branding/update'
                                                                        element={
                                                                            <AsyncComponent>
                                                                                <AccountBrandingUpdate />
                                                                            </AsyncComponent>
                                                                        }
                                                                    />
                                                                    {/**
                                                             // #region Account user groups
                                                            * */}
                                                                    <Route path='user-groups'>
                                                                        <Route path=':groupId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <UserGroupDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <UserGroupUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='members/add'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <UserGroupMemberAdd />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                        </Route>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/user-groups' />}
                                                                        />
                                                                    </Route>
                                                                    {/**
                                                             // #region Account equipment groups
                                                            * */}
                                                                    <Route path='equipment-groups'>
                                                                        <Route path=':groupId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <EquipmentGroupDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <EquipmentGroupUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='members/add'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <EquipmentGroupMemberAdd />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                        </Route>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/equipment-groups' />}
                                                                        />
                                                                    </Route>
                                                                    {/**
                                                             // #region Account product groups
                                                            * */}
                                                                    <Route path='product-groups'>
                                                                        <Route path=':groupId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ProductGroupDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ProductGroupUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='members/add'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ProductGroupMemberAdd />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                        </Route>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/product-groups' />}
                                                                        />
                                                                    </Route>
                                                                    {/**
                                                             // #region Account contact groups
                                                            * */}
                                                                    <Route path='contact-groups'>
                                                                        <Route path=':groupId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ContactGroupDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ContactGroupUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='members/add'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ContactGroupMemberAdd />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                        </Route>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/product-groups' />}
                                                                        />
                                                                    </Route>

                                                                    {/**
                                                                // #region Account budgets
                                                            * */}
                                                                    <Route path='budgets'>
                                                                        <Route path=':budgetId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <BudgetDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <BudgetUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route path=':entityType'>
                                                                                <Route
                                                                                    path='link'
                                                                                    element={
                                                                                        <AsyncComponent>
                                                                                            <BudgetEntityLink />
                                                                                        </AsyncComponent>
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    index
                                                                                    element={<Navigate to='../detail' />}
                                                                                />
                                                                            </Route>

                                                                            <Route path='rules'>
                                                                                <Route path=':ruleId'>
                                                                                    <Route
                                                                                        path='update'
                                                                                        element={
                                                                                            <AsyncComponent>
                                                                                                <BudgetRuleUpdate />
                                                                                            </AsyncComponent>
                                                                                        }
                                                                                    />
                                                                                </Route>
                                                                                <Route
                                                                                    path='create'
                                                                                    element={
                                                                                        <AsyncComponent>
                                                                                            <BudgetRuleCreate />
                                                                                        </AsyncComponent>
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    index
                                                                                    element={<Navigate to='../detail' />}
                                                                                />
                                                                            </Route>
                                                                        </Route>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/budgets' />}
                                                                        />
                                                                        <Route
                                                                            path='link/:entityId/:entityType/add'
                                                                            element={
                                                                                <AsyncComponent>
                                                                                    <EntityToBudgetLink />
                                                                                </AsyncComponent>
                                                                            }
                                                                        />
                                                                    </Route>
                                                                    {/**
                                                                // #region Account documents
                                                            * */}
                                                                    <Route path='documents'>
                                                                        <Route path=':documentId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <DocumentDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='file'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <DocumentFileUpload />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                        </Route>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/documents' />}
                                                                        />
                                                                    </Route>
                                                                    {/**
                                                             // #region Account cost centers
                                                            * */}
                                                                    <Route path='cost-centers'>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/cost-centers' />}
                                                                        />
                                                                        <Route path=':costCenterId'>
                                                                            <Route
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <CostCenterDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <CostCenterUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                        </Route>
                                                                    </Route>
                                                                    {/**
                                                         // #region Account  tags
                                                         * */}
                                                                    <Route path='tags'>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/tags' />}
                                                                        />
                                                                        <Route path=':tagId'>
                                                                            <Route
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <TagDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                        </Route>
                                                                    </Route>
                                                                    {/**
                                                             // #region Account roles
                                                            * */}
                                                                    <Route path='roles'>
                                                                        <Route path=':roleId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <RoleDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <RoleUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                        </Route>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/roles' />}
                                                                        />
                                                                    </Route>
                                                                    {/**
                                                             // #region Account playlists
                                                            * */}
                                                                    <Route path='playlists'>
                                                                        <Route path=':playlistId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <PlaylistDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <PlaylistUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route path='playlist-items'>
                                                                                <Route path=':playlistItemId'>
                                                                                    <Route
                                                                                        index
                                                                                        path='detail'
                                                                                        element={
                                                                                            <AsyncComponent>
                                                                                                <PlaylistItemDetail />
                                                                                            </AsyncComponent>
                                                                                        }
                                                                                    />
                                                                                    <Route
                                                                                        path='update'
                                                                                        element={
                                                                                            <AsyncComponent>
                                                                                                <PlaylistItemUpdate />
                                                                                            </AsyncComponent>
                                                                                        }
                                                                                    />
                                                                                </Route>
                                                                                <Route
                                                                                    path='create'
                                                                                    element={
                                                                                        <AsyncComponent>
                                                                                            <PlaylistItemCreate />
                                                                                        </AsyncComponent>
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    index
                                                                                    element={<Navigate to='../detail' />}
                                                                                />
                                                                            </Route>
                                                                        </Route>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/playlists' />}
                                                                        />
                                                                    </Route>
                                                                    {/**
                                                             // #region Account impexs
                                                            * */}
                                                                    <Route path='impexs'>
                                                                        <Route path=':impexId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ImpexDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                        </Route>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/impexs' />}
                                                                        />
                                                                    </Route>
                                                                    {/**
                                                             // #region Account courier
                                                            * */}
                                                                    <Route path='couriers'>
                                                                        <Route path=':courierId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <CourierDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <CourierUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                        </Route>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/couriers' />}
                                                                        />
                                                                    </Route>
                                                                    {/**
                                                             // #region Account addresses
                                                            * */}
                                                                    <Route path='addresses'>
                                                                        <Route path=':addressId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <AddressDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <AddressUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route path=':entityType'>
                                                                                <Route
                                                                                    path='link'
                                                                                    element={
                                                                                        <AsyncComponent>
                                                                                            <AddressEntityLink />
                                                                                        </AsyncComponent>
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    index
                                                                                    element={<Navigate to='../detail' />}
                                                                                />
                                                                            </Route>
                                                                        </Route>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/addresses' />}
                                                                        />
                                                                        <Route
                                                                            path='link/:entityId/:entityType/add'
                                                                            element={
                                                                                <AsyncComponent>
                                                                                    <EntityToAddressLink />
                                                                                </AsyncComponent>
                                                                            }
                                                                        />
                                                                    </Route>
                                                                    {/**
                                                             // #region Account question groups
                                                            * */}
                                                                    <Route path='questionnaires'>
                                                                        <Route path=':groupId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <QuestionGroupDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <QuestionGroupUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route path='members'>
                                                                                <Route
                                                                                    path='add'
                                                                                    element={
                                                                                        <AsyncComponent>
                                                                                            <QuestionGroupMemberAdd />
                                                                                        </AsyncComponent>
                                                                                    }
                                                                                />
                                                                                <Route path=':memberId'>
                                                                                    <Route
                                                                                        path='update'
                                                                                        element={<QuestionGroupMemberUpdate />}
                                                                                    />
                                                                                </Route>
                                                                            </Route>
                                                                        </Route>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/questionnaires' />}
                                                                        />
                                                                    </Route>
                                                                    {/**
                                                             // #region Account questions
                                                            * */}
                                                                    <Route path='questions'>
                                                                        <Route
                                                                            path='create'
                                                                            element={
                                                                                <AsyncComponent>
                                                                                    <QuestionCreate />
                                                                                </AsyncComponent>
                                                                            }
                                                                        />
                                                                        <Route path=':questionId'>
                                                                            <Route
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <QuestionDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <QuestionUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route path='answers'>
                                                                                <Route
                                                                                    path='create'
                                                                                    element={
                                                                                        <AsyncComponent>
                                                                                            <AnswerCreate />
                                                                                        </AsyncComponent>
                                                                                    }
                                                                                />
                                                                                <Route path=':answerId'>
                                                                                    <Route
                                                                                        path='detail'
                                                                                        element={
                                                                                            <AsyncComponent>
                                                                                                <AnswerDetail />
                                                                                            </AsyncComponent>
                                                                                        }
                                                                                    />
                                                                                    <Route
                                                                                        path='update'
                                                                                        element={
                                                                                            <AsyncComponent>
                                                                                                <AnswerUpdate />
                                                                                            </AsyncComponent>
                                                                                        }
                                                                                    />
                                                                                </Route>
                                                                                <Route
                                                                                    index
                                                                                    element={<Navigate to='../detail' />}
                                                                                />
                                                                            </Route>
                                                                        </Route>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/questions' />}
                                                                        />
                                                                    </Route>
                                                                    {/**
                                                            // #region Account Module templates
                                                            * */}
                                                                    <Route path='module-templates'>
                                                                        <Route path=':moduleTemplateId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ModuleTemplateDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ModuleTemplateUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                        </Route>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/module-templates' />}
                                                                        />
                                                                    </Route>
                                                                    {/**
                                                            // #region Account Slot templates
                                                            * */}
                                                                    <Route path='slot-templates'>
                                                                        <Route path=':slotTemplateId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <SlotTemplateDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <SlotTemplateUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                        </Route>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/slot-templates' />}
                                                                        />
                                                                    </Route>
                                                                    {/**
                                                            // #region Account spots
                                                            * */}
                                                                    <Route path='spots'>
                                                                        <Route path=':spotId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <SpotDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <SpotUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='settings/update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <SpotSettingsUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            {/** 
                                                                          // #region Account spot constraints
                                                                         */}
                                                                            <Route path='constraints'>
                                                                                <Route
                                                                                    path='create'
                                                                                    element={
                                                                                        <AsyncComponent>
                                                                                            <ConstraintCreate />
                                                                                        </AsyncComponent>
                                                                                    }
                                                                                />
                                                                                <Route path=':constraintId'>
                                                                                    <Route
                                                                                        path='update'
                                                                                        element={
                                                                                            <AsyncComponent>
                                                                                                <ConstraintUpdate />
                                                                                            </AsyncComponent>
                                                                                        }
                                                                                    />
                                                                                </Route>
                                                                            </Route>
                                                                            {/**
                                                                    // #region Account spot requirements
                                                                     * */}
                                                                            <Route path='requirements'>
                                                                                <Route
                                                                                    path='create'
                                                                                    element={
                                                                                        <AsyncComponent>
                                                                                            <RequirementCreate />
                                                                                        </AsyncComponent>
                                                                                    }
                                                                                />
                                                                                <Route path=':requirementId'>
                                                                                    <Route
                                                                                        index
                                                                                        path='detail'
                                                                                        element={
                                                                                            <AsyncComponent>
                                                                                                <RequirementDetail />
                                                                                            </AsyncComponent>
                                                                                        }
                                                                                    />
                                                                                    <Route
                                                                                        path='update'
                                                                                        element={
                                                                                            <AsyncComponent>
                                                                                                <RequirementUpdate />
                                                                                            </AsyncComponent>
                                                                                        }
                                                                                    />
                                                                                </Route>
                                                                            </Route>
                                                                            {/**
                                                                     // #region Account spot modules
                                                                    * */}
                                                                            <Route path='modules'>
                                                                                <Route
                                                                                    path='create'
                                                                                    element={
                                                                                        <AsyncComponent>
                                                                                            <ModuleCreate />
                                                                                        </AsyncComponent>
                                                                                    }
                                                                                />
                                                                                <Route path=':moduleId'>
                                                                                    <Route
                                                                                        index
                                                                                        path='detail'
                                                                                        element={
                                                                                            <AsyncComponent>
                                                                                                <ModuleDetail />
                                                                                            </AsyncComponent>
                                                                                        }
                                                                                    />
                                                                                    <Route
                                                                                        path='update'
                                                                                        element={
                                                                                            <AsyncComponent>
                                                                                                <ModuleUpdate />
                                                                                            </AsyncComponent>
                                                                                        }
                                                                                    />
                                                                                    {/**
                                                                             // #region Account spot module slots
                                                                            * */}
                                                                                    <Route path='slots'>
                                                                                        <Route
                                                                                            path='create'
                                                                                            element={
                                                                                                <AsyncComponent>
                                                                                                    <SlotCreate />
                                                                                                </AsyncComponent>
                                                                                            }
                                                                                        />
                                                                                        <Route path=':slotId'>
                                                                                            <Route
                                                                                                index
                                                                                                path='detail'
                                                                                                element={
                                                                                                    <AsyncComponent>
                                                                                                        <SlotDetail />
                                                                                                    </AsyncComponent>
                                                                                                }
                                                                                            />
                                                                                            <Route
                                                                                                path='update'
                                                                                                element={
                                                                                                    <AsyncComponent>
                                                                                                        <SlotUpdate />
                                                                                                    </AsyncComponent>
                                                                                                }
                                                                                            />
                                                                                            <Route path='stocks'>
                                                                                                <Route path=':stockId'>
                                                                                                    <Route
                                                                                                        index
                                                                                                        path='detail'
                                                                                                        element={
                                                                                                            <AsyncComponent>
                                                                                                                <StockDetail />
                                                                                                            </AsyncComponent>
                                                                                                        }
                                                                                                    />
                                                                                                    <Route
                                                                                                        path='update'
                                                                                                        element={
                                                                                                            <AsyncComponent>
                                                                                                                <StockUpdate />
                                                                                                            </AsyncComponent>
                                                                                                        }
                                                                                                    />
                                                                                                </Route>
                                                                                            </Route>
                                                                                            <Route path='stock-updates'>
                                                                                                <Route path=':movementId'>
                                                                                                    <Route
                                                                                                        path='detail'
                                                                                                        element={
                                                                                                            <AsyncComponent>
                                                                                                                <MovementDetail />
                                                                                                            </AsyncComponent>
                                                                                                        }
                                                                                                    />
                                                                                                </Route>
                                                                                            </Route>
                                                                                        </Route>
                                                                                        <Route
                                                                                            index
                                                                                            element={<Navigate to='../detail' />}
                                                                                        />
                                                                                    </Route>
                                                                                </Route>
                                                                                <Route
                                                                                    index
                                                                                    element={<Navigate to='../detail' />}
                                                                                />
                                                                            </Route>
                                                                        </Route>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/spots' />}
                                                                        />
                                                                    </Route>
                                                                    {/**
                                                             // #region Account equipment
                                                            * */}
                                                                    <Route path='equipments'>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/equipments' />}
                                                                        />
                                                                        <Route path=':equipmentId'>
                                                                            <Route
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <EquipmentDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <EquipmentUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='add-tag'
                                                                                element={
                                                                                    <IdentificationCreate
                                                                                        entity_type={EntityType.EQUIPMENT}
                                                                                        type={IdentificationType.TAG}
                                                                                        paramKey='equipmentId'
                                                                                    />
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='link-tag'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <LinkTag />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route path='equipment-certificates'>
                                                                                <Route
                                                                                    path='create'
                                                                                    element={
                                                                                        <AsyncComponent>
                                                                                            <EquipmentCertificateCreate />
                                                                                        </AsyncComponent>
                                                                                    }
                                                                                />
                                                                            </Route>
                                                                            <Route path='certificates'>
                                                                                <Route path=':certificateId'>
                                                                                    <Route path='equipment-certificates'>
                                                                                        <Route path=':equipmentCertificateId'>
                                                                                            <Route
                                                                                                index
                                                                                                path='detail'
                                                                                                element={
                                                                                                    <AsyncComponent>
                                                                                                        <EquipmentCertificateDetail />
                                                                                                    </AsyncComponent>
                                                                                                }
                                                                                            />
                                                                                            <Route
                                                                                                path='update'
                                                                                                element={
                                                                                                    <AsyncComponent>
                                                                                                        <EquipmentCertificateUpdate />
                                                                                                    </AsyncComponent>
                                                                                                }
                                                                                            />
                                                                                        </Route>
                                                                                    </Route>
                                                                                </Route>
                                                                            </Route>
                                                                            <Route path='problems'>
                                                                                <Route
                                                                                    path='create'
                                                                                    element={
                                                                                        <AsyncComponent>
                                                                                            <ProblemCreate />
                                                                                        </AsyncComponent>
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    index
                                                                                    element={<Navigate to='/admin/equipments' />}
                                                                                />
                                                                                <Route path=':problemId'>
                                                                                    <Route
                                                                                        path='detail'
                                                                                        element={
                                                                                            <AsyncComponent>
                                                                                                <ProblemDetail />
                                                                                            </AsyncComponent>
                                                                                        }
                                                                                    />
                                                                                    <Route
                                                                                        path='update'
                                                                                        element={
                                                                                            <AsyncComponent>
                                                                                                <ProblemUpdate />
                                                                                            </AsyncComponent>
                                                                                        }
                                                                                    />
                                                                                    <Route
                                                                                        index
                                                                                        element={<Navigate to='equipments' />}
                                                                                    />
                                                                                </Route>
                                                                            </Route>
                                                                            <Route path=':identificationType'>
                                                                                <Route
                                                                                    index
                                                                                    element={<Navigate to='../detail' />}
                                                                                />
                                                                                <Route path=':identificationId'>
                                                                                    <Route
                                                                                        path='detail'
                                                                                        element={
                                                                                            <AsyncComponent>
                                                                                                <IdentificationDetail />
                                                                                            </AsyncComponent>
                                                                                        }
                                                                                    />
                                                                                </Route>
                                                                            </Route>
                                                                            <Route
                                                                                path='report-problem'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ReportProblem />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='add-variable'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <EquipmentVariableCreate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route path='variables'>
                                                                                <Route
                                                                                    path=':variableId/update'
                                                                                    element={<EquipmentVariableUpdate />}
                                                                                />
                                                                            </Route>
                                                                        </Route>
                                                                    </Route>
                                                                    {/**
                                                             // #region Account users
                                                            * */}
                                                                    <Route path='users'>
                                                                        <Route path=':userId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <UserDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <UserUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='contact-info/update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <UserContactInformationUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='add-basic-auth'
                                                                                element={
                                                                                    <IdentificationCreate
                                                                                        entity_type={EntityType.USER}
                                                                                        paramKey='userId'
                                                                                        type={IdentificationType.BASIC_AUTH}
                                                                                    />
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='add-budget-use'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <BudgetUseCreate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='permissions'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <UserPermissionsList />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='add-budgets'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <LinkBudgetToUsers />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route path='user-certificates'>
                                                                                <Route
                                                                                    path='create'
                                                                                    element={
                                                                                        <AsyncComponent>
                                                                                            <UserCertificateCreate />
                                                                                        </AsyncComponent>
                                                                                    }
                                                                                />
                                                                            </Route>
                                                                            <Route path='certificates'>
                                                                                <Route path=':certificateId'>
                                                                                    <Route path='user-certificates'>
                                                                                        <Route path=':userCertificateId'>
                                                                                            <Route
                                                                                                index
                                                                                                path='detail'
                                                                                                element={
                                                                                                    <AsyncComponent>
                                                                                                        <UserCertificateDetail />
                                                                                                    </AsyncComponent>
                                                                                                }
                                                                                            />
                                                                                            <Route
                                                                                                path='update'
                                                                                                element={
                                                                                                    <AsyncComponent>
                                                                                                        <UserCertificateUpdate />
                                                                                                    </AsyncComponent>
                                                                                                }
                                                                                            />
                                                                                        </Route>
                                                                                    </Route>
                                                                                </Route>
                                                                            </Route>
                                                                        </Route>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/users' />}
                                                                        />
                                                                    </Route>
                                                                    {/**
                                                             // #region Account products
                                                            * */}
                                                                    <Route path='products'>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/products' />}
                                                                        />
                                                                        <Route path=':productId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ProductDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ProductUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='add-stock'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <StockCreate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='add-certificate'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <AddCertificate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route path='variants'>
                                                                                <Route path=':variantId'>
                                                                                    <Route
                                                                                        index
                                                                                        path='detail'
                                                                                        element={
                                                                                            <AsyncComponent>
                                                                                                <VariantDetail />
                                                                                            </AsyncComponent>
                                                                                        }
                                                                                    />
                                                                                    <Route
                                                                                        path='update'
                                                                                        element={
                                                                                            <AsyncComponent>
                                                                                                <VariantUpdate />
                                                                                            </AsyncComponent>
                                                                                        }
                                                                                    />
                                                                                    <Route path='attribute-linkings'>
                                                                                        <Route path=':attributeLinkingId'>
                                                                                            <Route
                                                                                                index
                                                                                                path='detail'
                                                                                                element={
                                                                                                    <AsyncComponent>
                                                                                                        <AttributeLinkingDetail />
                                                                                                    </AsyncComponent>
                                                                                                }
                                                                                            />
                                                                                            <Route
                                                                                                path='update'
                                                                                                element={
                                                                                                    <AsyncComponent>
                                                                                                        <AttributeLinkingUpdate />
                                                                                                    </AsyncComponent>
                                                                                                }
                                                                                            />
                                                                                        </Route>
                                                                                        <Route
                                                                                            path='create'
                                                                                            element={
                                                                                                <AsyncComponent>
                                                                                                    <AttributeLinkingCreate />
                                                                                                </AsyncComponent>
                                                                                            }
                                                                                        />
                                                                                        <Route
                                                                                            index
                                                                                            element={<Navigate to='../detail' />}
                                                                                        />
                                                                                    </Route>
                                                                                </Route>
                                                                                <Route
                                                                                    path='create'
                                                                                    element={
                                                                                        <AsyncComponent>
                                                                                            <VariantCreate />
                                                                                        </AsyncComponent>
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    index
                                                                                    element={<Navigate to='../detail' />}
                                                                                />
                                                                            </Route>
                                                                        </Route>
                                                                    </Route>
                                                                    {/**
                                                             // #region Account attributes
                                                            * */}
                                                                    <Route path='attributes'>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/attributes' />}
                                                                        />
                                                                        <Route path=':attributeId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <AttributeDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <AttributeUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                        </Route>
                                                                    </Route>
                                                                    {/**
                                                             // #region Account Entities
                                                            * */}
                                                                    <Route path=':entityType/:entityId'>
                                                                        {/**
                                                                 // #region Entity roles
                                                                * */}
                                                                        <Route path='roles'>
                                                                            <Route
                                                                                path='create'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <RoleCreate2 />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                        </Route>

                                                                        <Route path=':identificationType'>
                                                                            <Route
                                                                                index
                                                                                element={<Navigate to='../detail' />}
                                                                            />
                                                                            <Route path=':identificationId'>
                                                                                <Route
                                                                                    path='detail'
                                                                                    element={
                                                                                        <AsyncComponent>
                                                                                            <IdentificationDetail />
                                                                                        </AsyncComponent>
                                                                                    }
                                                                                />
                                                                            </Route>
                                                                        </Route>
                                                                    </Route>
                                                                    {/**
                                                             // #region Account Licenses
                                                            * */}
                                                                    <Route path='license'>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='../detail' />}
                                                                        />
                                                                        <Route
                                                                            path='create'
                                                                            element={
                                                                                <AsyncComponent>
                                                                                    <LicenseCreate />
                                                                                </AsyncComponent>
                                                                            }
                                                                        />
                                                                    </Route>

                                                                    {/**
                                                             // #region Account Certificates
                                                            * */}
                                                                    <Route path='certificates'>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/certificates' />}
                                                                        />
                                                                        <Route path=':certificateId'>
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <CertificateUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <CertificateDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route path='equipment-certificates'>
                                                                                <Route
                                                                                    path='create'
                                                                                    element={
                                                                                        <AsyncComponent>
                                                                                            <EquipmentCertificateCreate />
                                                                                        </AsyncComponent>
                                                                                    }
                                                                                />
                                                                            </Route>
                                                                            <Route path='user-certificates'>
                                                                                <Route
                                                                                    path='create'
                                                                                    element={
                                                                                        <AsyncComponent>
                                                                                            <UserCertificateCreate />
                                                                                        </AsyncComponent>
                                                                                    }
                                                                                />
                                                                            </Route>
                                                                        </Route>
                                                                    </Route>
                                                                    {/**
                                                             // #region Account Workflows
                                                            * */}
                                                                    <Route path='workflows'>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/workflows' />}
                                                                        />
                                                                        <Route path=':workflowId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <WorkflowDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='copy'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <WorkflowCopy />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <WorkflowUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route path='steps'>
                                                                                <Route
                                                                                    path='create'
                                                                                    element={
                                                                                        <AsyncComponent>
                                                                                            <StepCreate />
                                                                                        </AsyncComponent>
                                                                                    }
                                                                                />
                                                                                <Route path=':stepId'>
                                                                                    <Route path='fields'>
                                                                                        <Route
                                                                                            path='update'
                                                                                            element={<FieldsUpdate />}
                                                                                        />
                                                                                    </Route>
                                                                                </Route>
                                                                            </Route>
                                                                        </Route>
                                                                    </Route>
                                                                    {/**
                                                             // #region Account user interfaces
                                                            * */}
                                                                    <Route path='user-interfaces'>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/user-interfaces' />}
                                                                        />
                                                                        <Route path=':userInterfaceId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <UserInterfaceDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <UserInterfaceUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route path='button'>
                                                                                <Route
                                                                                    path='create'
                                                                                    element={
                                                                                        <AsyncComponent>
                                                                                            <UserInterfaceButtonCreate />
                                                                                        </AsyncComponent>
                                                                                    }
                                                                                />
                                                                            </Route>
                                                                            <Route path='buttons'>
                                                                                <Route path=':buttonId'>
                                                                                    <Route
                                                                                        path='update'
                                                                                        element={
                                                                                            <AsyncComponent>
                                                                                                <UserInterfaceButtonUpdate />
                                                                                            </AsyncComponent>
                                                                                        }
                                                                                    />
                                                                                </Route>
                                                                            </Route>
                                                                        </Route>
                                                                    </Route>
                                                                    {/**
                                                             // #region Account IoTs
                                                            * */}
                                                                    <Route path='iots'>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/iots' />}
                                                                        />
                                                                        <Route path=':iotId'>
                                                                            <Route
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <IoTDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <IoTUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='seed'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <IoTSeed />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='settings/update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <IoTSettingsUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route path='devices'>
                                                                                <Route
                                                                                    index
                                                                                    element={<Navigate to='../detail' />}
                                                                                />
                                                                                <Route
                                                                                    path='create'
                                                                                    element={
                                                                                        <AsyncComponent>
                                                                                            <DeviceCreate />
                                                                                        </AsyncComponent>
                                                                                    }
                                                                                />
                                                                                <Route path=':deviceId'>
                                                                                    <Route
                                                                                        path='detail'
                                                                                        element={
                                                                                            <AsyncComponent>
                                                                                                <DeviceDetail />
                                                                                            </AsyncComponent>
                                                                                        }
                                                                                    />
                                                                                    <Route
                                                                                        path='attach-module'
                                                                                        element={
                                                                                            <AsyncComponent>
                                                                                                <AttachModuleToIot />
                                                                                            </AsyncComponent>
                                                                                        }
                                                                                    />
                                                                                    <Route
                                                                                        path='update'
                                                                                        element={
                                                                                            <AsyncComponent>
                                                                                                <DeviceUpdate />
                                                                                            </AsyncComponent>
                                                                                        }
                                                                                    />
                                                                                    <Route path='lockers'>
                                                                                        <Route path=':lockerId'>
                                                                                            <Route
                                                                                                path='detail'
                                                                                                element={
                                                                                                    <AsyncComponent>
                                                                                                        <LockerDetail />
                                                                                                    </AsyncComponent>
                                                                                                }
                                                                                            />
                                                                                        </Route>
                                                                                    </Route>
                                                                                </Route>
                                                                            </Route>
                                                                        </Route>
                                                                    </Route>
                                                                    {/**
                                                                     * // #region Account Problems
                                                                     */}
                                                                    <Route path='problems'>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/problems' />}
                                                                        />
                                                                        <Route path=':problemId'>
                                                                            <Route
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ProblemDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ProblemUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route path=':entityType'>
                                                                                <Route
                                                                                    path='link'
                                                                                    element={
                                                                                        <AsyncComponent>
                                                                                            <ProblemEntityLink />
                                                                                        </AsyncComponent>
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    index
                                                                                    element={<Navigate to='../detail' />}
                                                                                />
                                                                            </Route>
                                                                        </Route>
                                                                    </Route>
                                                                    {/**
                                                             // #region Account Contacts
                                                            * */}
                                                                    <Route path='contacts'>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/contacts' />}
                                                                        />
                                                                        <Route path=':contactId'>
                                                                            <Route
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ContactDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ContactUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                        </Route>
                                                                    </Route>
                                                                    {/**
                                                             // #region Account Transactions
                                                            * */}
                                                                    <Route path='transactions'>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/transactions' />}
                                                                        />
                                                                        <Route path=':transactionId'>
                                                                            <Route
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <TransactionDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route path='packages'>
                                                                                <Route
                                                                                    index
                                                                                    element={<Navigate to='/admin/packages' />}
                                                                                />
                                                                                <Route path=':packageId'>
                                                                                    <Route
                                                                                        path='detail'
                                                                                        element={
                                                                                            <AsyncComponent>
                                                                                                <PackageDetail />
                                                                                            </AsyncComponent>
                                                                                        }
                                                                                    />
                                                                                </Route>
                                                                            </Route>
                                                                        </Route>
                                                                    </Route>
                                                                    {/**
                                                                  // #region Account Notification Templates
                                                                 * */}
                                                                    <Route path='notification-templates'>
                                                                        <Route path=':templateId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <NotificationTemplateDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='copy'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <NotificationTemplateCopy />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route path='content'>
                                                                                <Route
                                                                                    path='create'
                                                                                    element={
                                                                                        <AsyncComponent>
                                                                                            <NotificationContentCreate />
                                                                                        </AsyncComponent>
                                                                                    }
                                                                                />
                                                                                <Route path=':contentId'>
                                                                                    <Route
                                                                                        index
                                                                                        path='detail'
                                                                                        element={
                                                                                            <AsyncComponent>
                                                                                                <NotificationContentDetail />
                                                                                            </AsyncComponent>
                                                                                        }
                                                                                    />
                                                                                </Route>
                                                                            </Route>
                                                                        </Route>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/notification-templates' />}
                                                                        />
                                                                    </Route>
                                                                    {/**
                                                             // #region Account product categories
                                                            * */}
                                                                    <Route path='product-categories'>
                                                                        <Route path=':groupId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ProductCategoryDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ProductCategoryUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='products/add'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ProductCategoryMemberAdd />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='groups/add'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ProductCategoryCategoryAdd />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                        </Route>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/product-categories' />}
                                                                        />
                                                                    </Route>
                                                                </Route>
                                                            </Route>
                                                            {/**
                                                     // #region User groups
                                                    * */}
                                                            <Route path='user-groups'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <UserGroupOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <UserGroupCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/**
                                                     // #region Equipment groups
                                                    * */}
                                                            <Route path='equipment-groups'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <EquipmentGroupOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <EquipmentGroupCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/**
                                                     // #region Product groups
                                                    * */}
                                                            <Route path='product-groups'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <ProductGroupOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <ProductGroupCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            <Route path='contact-groups'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <ContactGroupOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <ContactGroupCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/**
                                                     // #region Cost centers
                                                    * */}
                                                            <Route path='cost-centers'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <CostCenterOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <CostCenterCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/**
                                                        // #region Budgets
                                                    * */}
                                                            <Route path='budgets'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <BudgetOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <BudgetCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/**
                                                        // #region Document
                                                    * */}
                                                            <Route path='documents'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <DocumentOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/**
                                                    // #region  Tags
                                                    * */}
                                                            <Route path='tags'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <TagOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <IdentificationCreate type={IdentificationType.TAG} />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Roles
                                                    * */}
                                                            <Route path='roles'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <RoleOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <RoleCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/**
                                                     // #region Question groups
                                                    * */}
                                                            <Route path='questionnaires'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <QuestionGroupOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <QuestionGroupCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/**
                                                        // #region Questions
                                                    * */}
                                                            <Route path='questions'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <QuestionOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <QuestionCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/**
                                                        // #region Answers
                                                    * */}
                                                            <Route path='answers'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <AnswerOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <AnswerCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Addresses
                                                    * */}
                                                            <Route path='addresses'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <AddressOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <AddressCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Variants
                                                    * */}
                                                            <Route path='variants'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <VariantOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <VariantCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Playlists
                                                    * */}
                                                            <Route path='playlists'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <PlaylistOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <PlaylistCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Playlists
                                                    * */}
                                                            <Route path='impexs'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <ImpexOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <ImpexCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Couriers
                                                    * */}
                                                            <Route path='couriers'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <CourierOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <CourierCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Spots
                                                    * */}
                                                            <Route path='spots'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <SpotOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <SpotCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Modules
                                                    * */}
                                                            <Route path='modules'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <ModuleOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <ModuleCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Module templates
                                                    * */}
                                                            <Route path='module-templates'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <ModuleTemplateOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <ModuleTemplateCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                            {/*
                                                        // #region Slot templates
                                                    * */}
                                                            <Route path='slot-templates'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <SlotTemplateOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <SlotTemplateCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Equipment
                                                    * */}
                                                            <Route path='equipments'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <EquipmentOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <EquipmentCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Transactions
                                                    * */}
                                                            <Route path='transactions'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <TransactionOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <TransactionCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Slots
                                                    * */}
                                                            {/* <Route path='slots'>
                                                        <Route
                                                            index
                                                            element={<AsyncComponent><SlotOverview /></AsyncComponent>}
                                                        />
                                                        <Route
                                                            path='create'
                                                            element={<AsyncComponent><SlotCreate /></AsyncComponent>}
                                                        />
                                                    </Route> */}
                                                            {/*
                                                        // #region Users
                                                    * */}
                                                            <Route path='users'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <UserOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <UserCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Products
                                                    * */}
                                                            <Route path='products'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <ProductOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <ProductCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Attributes
                                                    * */}
                                                            <Route path='attributes'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <AttributeOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <AttributeCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Certificates
                                                    * */}
                                                            <Route path='certificates'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <CertificateOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <CertificateCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Workflows
                                                    * */}
                                                            <Route path='workflows'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <WorkflowOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <WorkflowCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region User interfaces
                                                    * */}
                                                            <Route path='user-interfaces'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <UserInterfaceOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <UserInterfaceCreate dispatch={dispatch} />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region IoT
                                                    * */}
                                                            <Route path='iots'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <IoTOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <IoTCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Problems
                                                    * */}
                                                            <Route path='problems'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <ProblemOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <ProblemCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/** *
                                                 // #region Unknowns
                                                 * */}
                                                            <Route path='unknowns'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <UnknownOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route path=':unknownId'>
                                                                    <Route
                                                                        index
                                                                        path='detail'
                                                                        element={
                                                                            <AsyncComponent>
                                                                                <UnknownDetail />
                                                                            </AsyncComponent>
                                                                        }
                                                                    />
                                                                    <Route
                                                                        path='create-iot'
                                                                        element={
                                                                            <AsyncComponent>
                                                                                <UnknownCreate />
                                                                            </AsyncComponent>
                                                                        }
                                                                    />
                                                                </Route>
                                                            </Route>
                                                            {/*
                                                        // #region Devices
                                                    * */}
                                                            <Route path='devices'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <DeviceOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <DeviceCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Stocks
                                                    * */}
                                                            <Route path='stocks'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <StockOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <StockCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Movements
                                                    * */}
                                                            <Route path='stock-updates'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <MovementOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Packages
                                                    * */}
                                                            <Route path='packages'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <PackageOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Contacts
                                                    * */}
                                                            <Route path='contacts'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <ContactOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <ContactCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Seeds
                                                    * */}
                                                            <Route path='seeds'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <SeedOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Events
                                                    * */}
                                                            <Route path='sync-events'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AllEventsOverview
                                                                            type={[
                                                                                EventType.ENTITY_SYNC_DELETED,
                                                                                EventType.ENTITY_SYNC_UPDATED,
                                                                                EventType.ENTITY_SYNC_CREATED
                                                                            ]}
                                                                            title={CommonMessage.OBJECTS.EVENT.SYNC_EVENTS}
                                                                        />
                                                                    }
                                                                />
                                                            </Route>
                                                            {/** 
                                                         // #region Notification Templates
                                                         */}
                                                            <Route path='notification-templates'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <NotificationTemplateOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <NotificationTemplateCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/** 
                                                         // #region Product Categories
                                                         */}
                                                            <Route path='product-categories'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <ProductCategoryOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <ProductCategoryCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                        </Route>
                                                        <Route path='statistics'>
                                                            <Route
                                                                index
                                                                element={<Statistics />}
                                                            />
                                                            <Route
                                                                path='total-fill-rate'
                                                                element={<TotalFillRateDetail />}
                                                            />
                                                            <Route
                                                                path='fill-rate-by-product'
                                                                element={<FillRateByProductDetail />}
                                                            />
                                                            <Route
                                                                path='activity-per-day'
                                                                element={<ActivityPerDayDetail />}
                                                            />
                                                            <Route
                                                                path='activity-per-hour'
                                                                element={<ActivityPerHourDetail />}
                                                            />
                                                        </Route>

                                                        {/** *
                                                      // #region Warehouse
                                                     * */}
                                                        <Route path='warehouse'>
                                                            <Route
                                                                path='inventory'
                                                                element={
                                                                    <AsyncComponent>
                                                                        <StockOverviewReport />
                                                                    </AsyncComponent>
                                                                }
                                                            />
                                                            <Route
                                                                path='history'
                                                                element={
                                                                    <AsyncComponent>
                                                                        <MovementOverviewReport />
                                                                    </AsyncComponent>
                                                                }
                                                            />
                                                        </Route>
                                                        {/** *
                                                         * // #region Change Password
                                                         * */}
                                                        <Route path='change-password'>
                                                            <Route
                                                                index
                                                                element={
                                                                    <AsyncComponent>
                                                                        <ChangePassword />
                                                                    </AsyncComponent>
                                                                }
                                                            />
                                                        </Route>
                                                        {/** *
                                                 // #region Profile
                                                 * */}
                                                        <Route path='profile'>
                                                            <Route
                                                                index
                                                                element={
                                                                    <AsyncComponent>
                                                                        <Profile dispatch={dispatch} />
                                                                    </AsyncComponent>
                                                                }
                                                            />
                                                        </Route>
                                                        <Route
                                                            path='unauthorized'
                                                            element={<Unauthorized />}
                                                        />
                                                        <Route
                                                            path='/'
                                                            element={<Navigate to='/pudo' />}
                                                        />
                                                        <Route
                                                            path='/*'
                                                            element={<Navigate to='/pudo' />}
                                                        />
                                                    </Routes>
                                                </AsyncComponent>
                                            </div>
                                            <Footer />
                                        </main>
                                    </Resolver>
                                </AuthorizationProvider>
                            </AccountStoreProvider>
                        </Authentication>
                    </Router>
                    <ToastContainer />
                </div>
            </DocumentStoreProvider>
        </TranslationProvider>
    );
}

export default App;
