import { ClassType } from '@frontend/common';
import { Dropdown, DropdownItem } from '@frontend/elements';
import React from 'react';

import useAccountMembership from './account-membership.controller';

interface Props {
    className?: string;
}
const AccountMembership = (props: Props) => {
    const viewProps = useAccountMembership();

    return (
        <>
            {viewProps.accounts.length > 1 ? (
                <Dropdown
                    className={props.className}
                    label={viewProps.selectedAccount?.name ?? 'Select account'}
                    classType={ClassType.LIGHT}>
                    {viewProps.accounts.map((acc) => {
                        return (
                            <DropdownItem
                                key={acc.id}
                                label={acc.name}
                                onClick={() => viewProps.onChangeMembership(acc.id)}
                            />
                        );
                    })}
                </Dropdown>
            ) : (
                <button className={'btn btn-light ' + props.className}>{viewProps.selectedAccount?.name}</button>
            )}
        </>
    );
};

export default AccountMembership;
