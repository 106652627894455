import { AuthorizationWrapper, Permission } from '@frontend/authorization';
import { EntityType, Rights } from '@frontend/common';
import { ICONS } from '@frontend/icons';
import { CommonMessage } from '@frontend/lang';
import { IconType } from 'react-icons';

import useConfiguration from './configuration-user.controller';

const ID = 'configuration-user';
export interface ConfigurationProps {}

const ConfigurationUser = (props: ConfigurationProps) => {
    const viewProps = useConfiguration(props);

    return (
        <div
            id={ID}
            className='d-flex flex-row flex-wrap justify-content-center'>
            <Card
                icon={ICONS.NAVIGATION.USER_MANAGER}
                label={CommonMessage.NAVIGATION.USER_MANAGER}
                navigate={viewProps.navigate}
                buttons={[
                    {
                        to: '/users',
                        label: CommonMessage.OBJECTS.USER.PLURAL,
                        permissions: [
                            { item: EntityType.USER, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                            { item: EntityType.USER, source: EntityType.USER, right: Rights.READ, uid: '?' }
                        ]
                    },
                    {
                        to: '/user-groups',
                        label: CommonMessage.OBJECTS.GROUP.PLURAL,
                        permissions: [
                            { item: EntityType.USER_GROUP, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                            { item: EntityType.USER_GROUP, source: EntityType.USER_GROUP, right: Rights.READ, uid: '?' }
                        ]
                    },
                    {
                        to: '/roles',
                        label: CommonMessage.OBJECTS.ROLE.PLURAL,
                        permissions: [{ item: EntityType.ROLE, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' }]
                    },
                    {
                        to: '/user-badges',
                        label: CommonMessage.OBJECTS.BADGE.PLURAL,
                        permissions: [
                            { item: EntityType.IDENTIFICATION, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                            { item: EntityType.IDENTIFICATION, source: EntityType.USER, right: Rights.READ, uid: '?' }
                        ]
                    }
                ]}
            />
            <Card
                icon={ICONS.ENTITY.SPOT}
                label={CommonMessage.OBJECTS.SPOT.DISTRI_SPOTS}
                navigate={viewProps.navigate}
                permissions={[
                    { item: EntityType.SPOT, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                    { item: EntityType.SPOT, source: EntityType.SPOT, right: Rights.READ, uid: '?' },
                    { item: EntityType.MODULE, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                    { item: EntityType.MODULE, source: EntityType.SPOT, right: Rights.READ, uid: '?' },
                    { item: EntityType.TEMPLATE, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' }
                ]}
                buttons={[
                    {
                        to: '/spots',
                        label: CommonMessage.OBJECTS.SPOT.DISTRI_SPOTS,
                        permissions: [
                            { item: EntityType.SPOT, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                            { item: EntityType.SPOT, source: EntityType.SPOT, right: Rights.READ, uid: '?' }
                        ]
                    },
                    // {
                    //     to: '/modules',
                    //     label: CommonMessage.OBJECTS.MODULE.PLURAL,
                    //     permissions: [
                    //         { item: EntityType.MODULE, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                    //         { item: EntityType.MODULE, source: EntityType.SPOT, right: Rights.READ, uid: '?' }
                    //     ]
                    // },
                    {
                        to: '/module-templates',
                        label: CommonMessage.OBJECTS.MODULE.TEMPLATE_PLURAL,
                        permissions: [{ item: EntityType.TEMPLATE, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' }]
                    },
                    {
                        to: '/slot-templates',
                        label: CommonMessage.OBJECTS.SLOT.TEMPLATE_PLURAL,
                        permissions: [{ item: EntityType.TEMPLATE, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' }]
                    }
                ]}
            />
            <Card
                icon={ICONS.NAVIGATION.ORGANIZATION}
                label={CommonMessage.NAVIGATION.ORGANIZATION}
                navigate={viewProps.navigate}
                permissions={[{ item: EntityType.ACCOUNT, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' }]}
                buttons={[
                    {
                        to: '/accounts',
                        label: CommonMessage.OBJECTS.ACCOUNT.PLURAL,
                        permissions: [{ item: EntityType.ACCOUNT, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' }]
                    },
                    {
                        to: '/brandings',
                        label: CommonMessage.OBJECTS.BRANDING.PLURAL,
                        permissions: [{ item: EntityType.BRANDING, source: EntityType.ALL, right: Rights.SUPER_ADMIN, uid: '?' }]
                    },
                    {
                        to: '/notification-templates',
                        label: CommonMessage.OBJECTS.NOTIFICATION_TEMPLATE.PLURAL,
                        permissions: [{ item: EntityType.NOTIFICATION, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' }]
                    },
                    {
                        to: '/notification-emails',
                        label: CommonMessage.OBJECTS.NOTIFICATION_EMAIL.PLURAL,
                        permissions: [{ item: EntityType.NOTIFICATION_EMAIL, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' }]
                    }
                ]}
            />
            <Card
                icon={ICONS.NAVIGATION.INVENTORY}
                label={CommonMessage.NAVIGATION.INVENTORY}
                navigate={viewProps.navigate}
                permissions={[
                    { item: EntityType.STOCK, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                    { item: EntityType.STOCK, source: EntityType.STOCK, right: Rights.READ, uid: '?' },
                    { item: EntityType.MOVEMENT, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                    { item: EntityType.MOVEMENT, source: EntityType.STOCK, right: Rights.READ, uid: '?' }
                ]}
                buttons={[
                    {
                        to: '/stocks',
                        label: CommonMessage.OBJECTS.STOCK.DEFAULT,
                        permissions: [
                            { item: EntityType.STOCK, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                            { item: EntityType.STOCK, source: EntityType.STOCK, right: Rights.READ, uid: '?' }
                        ]
                    },
                    {
                        to: '/stock-updates',
                        label: CommonMessage.OBJECTS.STOCK.UPDATE.PLURAL,
                        permissions: [
                            { item: EntityType.MOVEMENT, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                            { item: EntityType.MOVEMENT, source: EntityType.STOCK, right: Rights.READ, uid: '?' }
                        ]
                    }
                ]}
            />
            <Card
                icon={ICONS.NAVIGATION.EQUIPMENT}
                label={CommonMessage.NAVIGATION.EQUIPMENT}
                navigate={viewProps.navigate}
                permissions={[
                    { item: EntityType.EQUIPMENT, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                    { item: EntityType.EQUIPMENT, source: EntityType.EQUIPMENT, right: Rights.READ, uid: '?' },
                    { item: EntityType.EQUIPMENT_GROUP, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                    { item: EntityType.EQUIPMENT_GROUP, source: EntityType.EQUIPMENT_GROUP, right: Rights.READ, uid: '?' },
                    { item: EntityType.IDENTIFICATION, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                    { item: EntityType.IDENTIFICATION, source: EntityType.EQUIPMENT, right: Rights.READ, uid: '?' },
                    { item: EntityType.PROBLEM, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                    { item: EntityType.PROBLEM, source: EntityType.EQUIPMENT, right: Rights.READ, uid: '?' }
                ]}
                buttons={[
                    {
                        to: '/equipments',
                        label: CommonMessage.OBJECTS.EQUIPMENT.PLURAL,
                        permissions: [
                            { item: EntityType.EQUIPMENT, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                            { item: EntityType.EQUIPMENT, source: EntityType.EQUIPMENT, right: Rights.READ, uid: '?' }
                        ]
                    },
                    {
                        to: '/equipment-groups',
                        label: CommonMessage.OBJECTS.GROUP.PLURAL,
                        permissions: [
                            { item: EntityType.EQUIPMENT_GROUP, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                            { item: EntityType.EQUIPMENT_GROUP, source: EntityType.EQUIPMENT_GROUP, right: Rights.READ, uid: '?' }
                        ]
                    },
                    {
                        to: '/tags',
                        label: CommonMessage.OBJECTS.TAG.PLURAL,
                        permissions: [
                            { item: EntityType.IDENTIFICATION, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                            { item: EntityType.IDENTIFICATION, source: EntityType.EQUIPMENT, right: Rights.READ, uid: '?' }
                        ]
                    },
                    {
                        to: '/problems',
                        label: CommonMessage.OBJECTS.PROBLEM.PLURAL,
                        permissions: [
                            { item: EntityType.PROBLEM, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                            { item: EntityType.PROBLEM, source: EntityType.EQUIPMENT, right: Rights.READ, uid: '?' }
                        ]
                    }
                ]}
            />
            <Card
                icon={ICONS.NAVIGATION.CONTENT_AND_MEDIA}
                label={CommonMessage.NAVIGATION.CONTENT_AND_MEDIA}
                navigate={viewProps.navigate}
                permissions={[
                    { item: EntityType.PLAYLIST, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                    { item: EntityType.PLAYLIST, source: EntityType.PLAYLIST, right: Rights.READ, uid: '?' },
                    { item: EntityType.QUESTION, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                    { item: EntityType.QUESTION, source: EntityType.QUESTION, right: Rights.READ, uid: '?' },
                    { item: EntityType.QUESTIONNAIRE, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                    { item: EntityType.QUESTIONNAIRE, source: EntityType.QUESTIONNAIRE, right: Rights.READ, uid: '?' },
                    { item: EntityType.DOCUMENT, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                    { item: EntityType.DOCUMENT, source: EntityType.DOCUMENT, right: Rights.READ, uid: '?' }
                ]}
                buttons={[
                    {
                        to: '/playlists',
                        label: CommonMessage.NAVIGATION.PLAYLISTS,
                        permissions: [
                            { item: EntityType.PLAYLIST, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                            { item: EntityType.PLAYLIST, source: EntityType.PLAYLIST, right: Rights.READ, uid: '?' }
                        ]
                    },
                    {
                        to: '/questions',
                        label: CommonMessage.OBJECTS.QUESTION.PLURAL,
                        permissions: [
                            { item: EntityType.QUESTION, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                            { item: EntityType.QUESTION, source: EntityType.QUESTION, right: Rights.READ, uid: '?' }
                        ]
                    },
                    {
                        to: '/questionnaires',
                        label: CommonMessage.OBJECTS.QUESTION_GROUP.PLURAL,
                        permissions: [
                            { item: EntityType.QUESTIONNAIRE, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                            { item: EntityType.QUESTIONNAIRE, source: EntityType.QUESTIONNAIRE, right: Rights.READ, uid: '?' }
                        ]
                    },
                    {
                        to: '/documents',
                        label: CommonMessage.OBJECTS.DOCUMENT.PLURAL,
                        permissions: [
                            { item: EntityType.DOCUMENT, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                            { item: EntityType.DOCUMENT, source: EntityType.DOCUMENT, right: Rights.READ, uid: '?' }
                        ]
                    }
                ]}
            />
            <Card
                icon={ICONS.NAVIGATION.QUOTAS_AND_CONSTRAINTS}
                label={CommonMessage.NAVIGATION.QUOTAS_AND_CONSTRAINTS}
                navigate={viewProps.navigate}
                permissions={[
                    { item: EntityType.BUDGET, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                    { item: EntityType.BUDGET, source: EntityType.BUDGET, right: Rights.READ, uid: '?' },
                    { item: EntityType.PRODUCT_GROUP, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                    { item: EntityType.PRODUCT_GROUP, source: EntityType.PRODUCT_GROUP, right: Rights.READ, uid: '?' },
                    { item: EntityType.COST_CENTER, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                    { item: EntityType.COST_CENTER, source: EntityType.COST_CENTER, right: Rights.READ, uid: '?' }
                ]}
                buttons={[
                    {
                        to: '/budgets',
                        label: CommonMessage.OBJECTS.BUDGET.PLURAL,
                        permissions: [
                            { item: EntityType.BUDGET, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                            { item: EntityType.BUDGET, source: EntityType.BUDGET, right: Rights.READ, uid: '?' }
                        ]
                    },
                    {
                        to: '/product-groups',
                        label: CommonMessage.OBJECTS.PRODUCT_GROUP.PLURAL,
                        permissions: [
                            { item: EntityType.PRODUCT_GROUP, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                            { item: EntityType.PRODUCT_GROUP, source: EntityType.PRODUCT_GROUP, right: Rights.READ, uid: '?' }
                        ]
                    },
                    {
                        to: '/cost-centers',
                        label: CommonMessage.OBJECTS.COST_CENTER.PLURAL,
                        permissions: [
                            { item: EntityType.COST_CENTER, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                            { item: EntityType.COST_CENTER, source: EntityType.COST_CENTER, right: Rights.READ, uid: '?' }
                        ]
                    }
                ]}
            />
            <Card
                icon={ICONS.NAVIGATION.IOT}
                label={CommonMessage.OBJECTS.IOT.DEFAULT}
                navigate={viewProps.navigate}
                permissions={[
                    { item: EntityType.IOT, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                    { item: EntityType.IOT, source: EntityType.IOT, right: Rights.READ, uid: '?' },
                    { item: EntityType.DEVICE, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                    { item: EntityType.DEVICE, source: EntityType.IOT, right: Rights.READ, uid: '?' }
                ]}
                buttons={[
                    {
                        to: '/iots',
                        label: CommonMessage.OBJECTS.IOT.DEFAULT,
                        permissions: [
                            { item: EntityType.IOT, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                            { item: EntityType.IOT, source: EntityType.IOT, right: Rights.READ, uid: '?' }
                        ]
                    },
                    {
                        to: '/unknowns',
                        label: CommonMessage.OBJECTS.UNKNOWN.DEFAULT
                    }
                    // {
                    //     to: '/devices',
                    //     label: CommonMessage.OBJECTS.DEVICE.PLURAL,
                    //     permissions: [
                    //         { item: EntityType.DEVICE, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                    //         { item: EntityType.DEVICE, source: EntityType.IOT, right: Rights.READ, uid: '?' }
                    //     ]
                    // }
                ]}
            />
            <Card
                icon={ICONS.ENTITY.WORKFLOW}
                label={CommonMessage.OBJECTS.WORKFLOW.PLURAL}
                navigate={viewProps.navigate}
                permissions={[
                    { item: EntityType.WORKFLOW, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                    { item: EntityType.WORKFLOW, source: EntityType.WORKFLOW, right: Rights.READ, uid: '?' },
                    { item: EntityType.USER_INTERFACE, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                    { item: EntityType.USER_INTERFACE, source: EntityType.USER_INTERFACE, right: Rights.READ, uid: '?' }
                ]}
                buttons={[
                    {
                        to: '/workflows',
                        label: CommonMessage.OBJECTS.WORKFLOW.PLURAL,
                        permissions: [
                            { item: EntityType.WORKFLOW, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                            { item: EntityType.WORKFLOW, source: EntityType.WORKFLOW, right: Rights.READ, uid: '?' }
                        ]
                    },
                    {
                        to: '/user-interfaces',
                        label: CommonMessage.OBJECTS.USER_INTERFACE.PLURAL,
                        permissions: [
                            { item: EntityType.USER_INTERFACE, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                            { item: EntityType.USER_INTERFACE, source: EntityType.USER_INTERFACE, right: Rights.READ, uid: '?' }
                        ]
                    }
                ]}
            />
            <Card
                icon={ICONS.ENTITY.TRANSACTION}
                label={CommonMessage.NAVIGATION.TRANSACTIONS}
                navigate={viewProps.navigate}
                permissions={[
                    { item: EntityType.TRANSACTION, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                    { item: EntityType.TRANSACTION, source: EntityType.TRANSACTION, right: Rights.READ, uid: '?' },
                    { item: EntityType.PACKAGE, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                    { item: EntityType.PACKAGE, source: EntityType.TRANSACTION, right: Rights.READ, uid: '?' }
                ]}
                buttons={[
                    {
                        to: '/transactions',
                        label: CommonMessage.OBJECTS.TRANSACTION.PLURAL,
                        permissions: [
                            { item: EntityType.TRANSACTION, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                            { item: EntityType.TRANSACTION, source: EntityType.TRANSACTION, right: Rights.READ, uid: '?' }
                        ]
                    },
                    {
                        to: '/packages',
                        label: CommonMessage.OBJECTS.PACKAGE.PLURAL,
                        permissions: [
                            { item: EntityType.PACKAGE, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                            { item: EntityType.PACKAGE, source: EntityType.TRANSACTION, right: Rights.READ, uid: '?' }
                        ]
                    }
                ]}
            />

            <Card
                icon={ICONS.NAVIGATION.PRODUCT}
                label={CommonMessage.NAVIGATION.PRODUCT}
                navigate={viewProps.navigate}
                permissions={[
                    { item: EntityType.PRODUCT, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                    { item: EntityType.PRODUCT, source: EntityType.PRODUCT, right: Rights.READ, uid: '?' },
                    { item: EntityType.ATTRIBUTE, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                    { item: EntityType.ATTRIBUTE, source: EntityType.PRODUCT, right: Rights.READ, uid: '?' },
                    { item: EntityType.CERTIFICATE, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                    { item: EntityType.CERTIFICATE, source: EntityType.PRODUCT, right: Rights.READ, uid: '?' },
                    { item: EntityType.CERTIFICATE, source: EntityType.USER, right: Rights.READ, uid: '?' }
                ]}
                buttons={[
                    {
                        to: '/products',
                        label: CommonMessage.OBJECTS.PRODUCT.PLURAL,
                        permissions: [
                            { item: EntityType.PRODUCT, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                            { item: EntityType.PRODUCT, source: EntityType.PRODUCT, right: Rights.READ, uid: '?' }
                        ]
                    },
                    {
                        to: '/attributes',
                        label: CommonMessage.OBJECTS.ATTRIBUTE.PLURAL,
                        permissions: [
                            { item: EntityType.ATTRIBUTE, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                            { item: EntityType.ATTRIBUTE, source: EntityType.PRODUCT, right: Rights.READ, uid: '?' }
                        ]
                    },
                    {
                        to: '/product-categories',
                        label: CommonMessage.OBJECTS.CATEGORY.PLURAL,
                        permissions: [
                            { item: EntityType.PRODUCT, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                            { item: EntityType.PRODUCT, source: EntityType.PRODUCT, right: Rights.READ, uid: '?' },
                            { item: EntityType.PRODUCT_CATEGORY, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                            { item: EntityType.PRODUCT_CATEGORY, source: EntityType.PRODUCT_CATEGORY, right: Rights.READ, uid: '?' }
                        ]
                    },
                    {
                        to: '/certificates',
                        label: CommonMessage.OBJECTS.CERTIFICATE.PLURAL,
                        permissions: [
                            { item: EntityType.CERTIFICATE, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                            { item: EntityType.CERTIFICATE, source: EntityType.PRODUCT, right: Rights.READ, uid: '?' },
                            { item: EntityType.CERTIFICATE, source: EntityType.USER, right: Rights.READ, uid: '?' }
                        ]
                    }
                ]}
            />
            <Card
                icon={ICONS.NAVIGATION.CONTACT}
                label={CommonMessage.OBJECTS.CONTACT.DEFAULT}
                navigate={viewProps.navigate}
                permissions={[
                    { item: EntityType.CONTACT, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                    { item: EntityType.CONTACT, source: EntityType.CONTACT, right: Rights.READ, uid: '?' },
                    { item: EntityType.CONTACT_GROUP, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                    { item: EntityType.CONTACT_GROUP, source: EntityType.CONTACT_GROUP, right: Rights.READ, uid: '?' },
                    { item: EntityType.NOTIFICATION_TEMPLATE, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                    { item: EntityType.NOTIFICATION_TEMPLATE, source: EntityType.NOTIFICATION_TEMPLATE, right: Rights.READ, uid: '?' }
                ]}
                buttons={[
                    {
                        to: '/contacts',
                        label: CommonMessage.OBJECTS.CONTACT.PLURAL,
                        permissions: [
                            { item: EntityType.CONTACT, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                            { item: EntityType.CONTACT, source: EntityType.CONTACT, right: Rights.READ, uid: '?' }
                        ]
                    },
                    {
                        to: '/contact-groups',
                        label: CommonMessage.OBJECTS.GROUP.PLURAL,
                        permissions: [
                            { item: EntityType.CONTACT_GROUP, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                            { item: EntityType.CONTACT_GROUP, source: EntityType.CONTACT_GROUP, right: Rights.READ, uid: '?' }
                        ]
                    },
                    {
                        to: '/notification-templates',
                        label: CommonMessage.OBJECTS.NOTIFICATION_TEMPLATE.PLURAL,
                        permissions: [
                            { item: EntityType.NOTIFICATION_TEMPLATE, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' },
                            { item: EntityType.NOTIFICATION_TEMPLATE, source: EntityType.NOTIFICATION_TEMPLATE, right: Rights.READ, uid: '?' }
                        ]
                    }
                ]}
            />
            <Card
                icon={ICONS.NAVIGATION.IMPORT}
                label={CommonMessage.OBJECTS.IMPORT.DEFAULT}
                navigate={viewProps.navigate}
                permissions={[{ item: EntityType.IMPEX, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' }]}
                buttons={[
                    {
                        to: '/imports',
                        label: CommonMessage.OBJECTS.IMPORT.PLURAL,
                        permissions: [{ item: EntityType.IMPEX, source: EntityType.ACCOUNT, right: Rights.READ, uid: '?' }]
                    }
                ]}
            />
        </div>
    );
};

export default ConfigurationUser;

interface CardProps {
    icon: IconType;
    label: React.ReactNode;
    navigate: (to: string) => void;
    buttons: {
        to: string;
        label: React.ReactNode;
        permissions?: Permission[];
    }[];
    permissions?: Permission[];
}

const Card = (props: CardProps) => {
    const cardContent = (
        <div className='card w-100 w-sm-45 w-lg-30 m-1'>
            <div className='card-header py-3 d-flex flex-row align-items-center'>
                <props.icon className='me-2 text-secondary fs-3' />
                <h5 className='text-primary p-0 m-0'>{props.label}</h5>
            </div>
            <div className='card-body pt-1 d-flex flex-column'>
                {props.buttons.map((button) => {
                    const content = (
                        <button
                            className='btn btn-link underline text-secondary ps-0 py-0 my-1 d-flex justify-content-start'
                            key={button.to}
                            onClick={() => props.navigate(button.to)}>
                            {button.label}
                        </button>
                    );

                    if (button.permissions) {
                        return (
                            <AuthorizationWrapper
                                showLoading={false}
                                key={button.to}
                                requiredPermissions={button.permissions}>
                                {content}
                            </AuthorizationWrapper>
                        );
                    } else {
                        return content;
                    }
                })}
            </div>
        </div>
    );

    if (props.permissions) {
        return (
            <AuthorizationWrapper
                requiredPermissions={props.permissions}
                showLoading={false}>
                {cardContent}
            </AuthorizationWrapper>
        );
    } else {
        return cardContent;
    }
};
