import { TextInput } from '@frontend/basic-forms';
import { ICONS } from '@frontend/icons';
import React from 'react';
import { createPortal } from 'react-dom';

import useGlobalSearch from './global-search.controller';
import { CategorySearch } from './searchables/category-search';
import { ContactSearch } from './searchables/contact-search';
import { EquipmentSearch } from './searchables/equipment-search';
import { ProductSearch } from './searchables/product-search';
import { SpotSearch } from './searchables/spot-search';
import { UserSearch } from './searchables/user-search';

const ID = 'global-search';
export interface GlobalSearchProps {
    onClose: () => void;
}

const GlobalSearch = (props: GlobalSearchProps) => {
    const viewProps = useGlobalSearch(props);

    const root = document.getElementById('root');
    if (!root) throw new Error('Root node not found. Cannot render modal.');
    return createPortal(
        <div
            className='modal display-block'
            aria-modal='true'
            role='dialog'
            id={ID}>
            <div
                ref={viewProps.modalRef}
                className='card mh-100 w-50'
                style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <div className={'card-header d-flex flex-row justify-content-center m-0 p-0' + (viewProps.showContent ? '' : ' rounded-3')}>
                    <TextInput
                        className='w-100 m-2'
                        value={viewProps.value}
                        onChange={(value) => viewProps.changeValue(value)}
                        submitted={false}
                        autoFocus
                        placeholder='Start typing to search for anything.'
                    />
                    <button
                        onClick={props.onClose}
                        className='btn btn-outline-secondary m-2 py-1'>
                        <ICONS.BUTTON.CLOSE />
                    </button>
                </div>

                {viewProps.showContent && (
                    <>
                        <hr className='horizontal dark mt-0' />
                        <div className='card-body overflow-scroll d-flex flex-row justify-content-center m-0 p-0'>
                            {viewProps.value.length > 0 && (
                                <div className='h-100 w-100'>
                                    {/* <AccountSearch filter={viewProps.filterValue} /> */}
                                    <SpotSearch filter={viewProps.filterValue} />
                                    <UserSearch filter={viewProps.filterValue} />
                                    <ContactSearch filter={viewProps.filterValue} />
                                    <ProductSearch filter={viewProps.filterValue} />
                                    <EquipmentSearch filter={viewProps.filterValue} />
                                    <CategorySearch filter={viewProps.filterValue} />
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>,
        root
    );
};

export default GlobalSearch;
