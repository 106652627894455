import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import {
    AttributeLinking,
    AttributeLinkingListResponse,
    AttributeLinkingQueryParams,
    AttributeLinkingWithNameListResponse,
    CreateAttributeLinking,
    UpdateAttributeLinking
} from '@frontend/attribute/types';

const endpoint = '/attribute-api/v1';

export class AttributeLinkingClient extends APIClient {
    public static async fetchAttributeLinkings(
        queryParams?: ApiQueryParams<DefaultQueryParams & AttributeLinkingQueryParams>
    ): Promise<AttributeLinkingListResponse> {
        return await this.apiPaginated<AttributeLinkingListResponse, DefaultQueryParams & AttributeLinkingQueryParams>(
            `${endpoint}/attribute-linkings`,
            queryParams
        );
    }

    public static async resolveAttributeLinkings(ids: string[]): Promise<AttributeLinkingListResponse> {
        const response = await this.post(`${endpoint}/attribute-linkings-resolve`, { ids: ids });
        return await this.handleResponse<AttributeLinkingListResponse>(response);
    }

    public static async resovleAttributeLinkingsWithAttributeName(ids: string[], field?: string): Promise<AttributeLinkingWithNameListResponse> {
        const response = await this.post(`${endpoint}/attribute-linkings-resolve-with-attribute-name`, { ids: ids, field: field });
        return await this.handleResponse<AttributeLinkingWithNameListResponse>(response);
    }

    public static async deleteAttributeLinkings(ids: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/attribute-linkings-delete`, { ids: ids });
        return await this.handleVoidResponse(response);
    }

    public static async postAttributeLinking(
        accountId: string,
        productId: string,
        variantId: string,
        attributeId: string,
        attribute: CreateAttributeLinking
    ): Promise<AttributeLinking> {
        const response = await this.post(
            `${endpoint}/accounts/${accountId}/products/${productId}/variants/${variantId}/attributes/${attributeId}/attribute-linkings`,
            attribute
        );
        return await this.handleResponse<AttributeLinking>(response);
    }

    public static async fetchAttributeLinking(
        accountId: string,
        productId: string,
        variantId: string,
        attributeId: string,
        attributeLinkingId: string
    ): Promise<AttributeLinking> {
        const response = await this.fetch(
            `${endpoint}/accounts/${accountId}/products/${productId}/variants/${variantId}/attributes/${attributeId}/attribute-linkings/${attributeLinkingId}`
        );
        return await this.handleResponse<AttributeLinking>(response);
    }

    public static async patchAttributeLinking(
        accountId: string,
        productId: string,
        variantId: string,
        attributeId: string,
        attributeLinkingId: string,
        attribute: UpdateAttributeLinking
    ): Promise<AttributeLinking> {
        const response = await this.patch(
            `${endpoint}/accounts/${accountId}/products/${productId}/variants/${variantId}/attributes/${attributeId}/attribute-linkings/${attributeLinkingId}`,
            attribute
        );
        return await this.handleResponse<AttributeLinking>(response);
    }

    public static async deleteAttributeLinking(
        accountId: string,
        productId: string,
        variantId: string,
        attributeId: string,
        attributeLinkingId: string
    ): Promise<void> {
        const response = await this.delete(
            `${endpoint}/accounts/${accountId}/products/${productId}/variants/${variantId}/attributes/${attributeId}/attribute-linkings/${attributeLinkingId}`
        );
        return await this.handleVoidResponse(response);
    }
}
