import { FloatingFocusManager, FloatingList, autoUpdate, flip, useClick, useFloating, useInteractions, useListNavigation, useRole } from '@floating-ui/react';
import React, { ReactNode, createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { FaXmark } from 'react-icons/fa6';

import { SingleOption } from './select-types';

export interface SelectContextValue {
    getItemProps: ReturnType<typeof useInteractions>['getItemProps'];
    handleSelect: (option: SingleOption<any>) => void;
}
export const SelectContext = createContext<SelectContextValue>({} as SelectContextValue);

const SCROLL_TOLLERANCE = 4;
const ID = 'select-input';
interface SelectProps<T> {
    id?: string;
    placeholder?: string;
    onChange?: (value: string) => void;
    onSelect?: (option: SingleOption<T> | null) => void;
    onScrollToBottom?: () => void;
    value?: string;
    isClearable?: boolean;
    children: ReactNode;

    optionsLoading?: boolean;
    onFocus?: React.FocusEventHandler<HTMLInputElement> | undefined;
    onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
    disabled?: boolean;
    isOpenOverwrite?: boolean;
    inputRefCallback?: (ref: React.RefObject<HTMLInputElement | null>) => void;
}

export function Select<T>(props: SelectProps<T>) {
    const containerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const listRef = useRef<HTMLUListElement>(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    useEffect(() => {
        if (inputRef && props.inputRefCallback) props.inputRefCallback(inputRef);
    }, [inputRef]);

    const updateIsOpen = (value: boolean) => {
        if (props.isOpenOverwrite === undefined) setIsOpen(value);
        else setIsOpen(props.isOpenOverwrite);
    };

    useEffect(() => {
        if (props.isOpenOverwrite !== undefined) setIsOpen(props.isOpenOverwrite);
        else setIsOpen(false);
    }, [props.isOpenOverwrite]);

    useEffect(() => {
        const updateListWidth = () => {
            if (containerRef.current) setContainerWidth(containerRef.current.offsetWidth);
        };
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current && event.target && !containerRef.current.contains(event.target as Node)) updateIsOpen(false);
        };

        updateListWidth();
        window.addEventListener('resize', updateListWidth);
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            window.removeEventListener('resize', updateListWidth);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleListScroll = () => {
            if (listRef.current) {
                if (listRef.current.scrollTop + listRef.current.clientHeight >= listRef.current.scrollHeight - SCROLL_TOLLERANCE) {
                    props.onScrollToBottom && props.onScrollToBottom();
                }
            }
        };

        listRef.current?.addEventListener('scroll', handleListScroll);
        return () => {
            listRef.current?.removeEventListener('scroll', handleListScroll);
        };
    }, [listRef.current]);

    const { refs, floatingStyles, context } = useFloating({
        placement: 'bottom-start',
        whileElementsMounted: autoUpdate,
        middleware: [flip()]
    });

    const elementsRef = useRef<Array<HTMLElement | null>>([]);
    const labelsRef = useRef<Array<string | null>>([]);

    const handleSelect = useCallback(
        (option: SingleOption<T>) => {
            props.onSelect && props.onSelect(option);
            updateIsOpen(false);
        },
        [props.isOpenOverwrite, props.onSelect]
    );

    const listNav = useListNavigation(context, {
        listRef: elementsRef,
        allowEscape: false,
        activeIndex,
        onNavigate: setActiveIndex
    });
    const click = useClick(context, {
        keyboardHandlers: false
    });
    const role = useRole(context, { role: 'listbox' });

    const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([listNav, click, role]);

    const selectContext = useMemo(
        (): SelectContextValue => ({
            getItemProps,
            handleSelect
        }),
        [getItemProps, handleSelect]
    );

    return (
        <div ref={containerRef}>
            <div
                className='input-group'
                ref={refs.setReference}
                tabIndex={0}
                {...getReferenceProps()}>
                <input
                    ref={inputRef}
                    id={props.id || ID + '-search-input'}
                    aria-describedby={props.id || ID + '-dropdown-button'}
                    className='form-control'
                    type='text'
                    value={props.value ?? ''}
                    onChange={(e) => props.onChange && props.onChange(e.target.value)}
                    placeholder={props.placeholder || 'Search...'}
                    onFocus={(e) => {
                        updateIsOpen(true);
                        props.onFocus && props.onFocus(e);
                    }}
                    onBlur={props.onBlur}
                    disabled={props.disabled}
                />
                {props.isClearable && props.value && (
                    <span
                        className='input-group-text'
                        onClick={() => {
                            props.onChange && props.onChange('');
                            props.onSelect && props.onSelect(null);
                        }}
                        id={props.id || ID + '-clear-button'}>
                        <FaXmark />
                    </span>
                )}
                <span
                    className='input-group-text'
                    onClick={() => {
                        if (!isOpen) inputRef.current?.focus();
                        else updateIsOpen(false);
                    }}
                    id={props.id || ID + '-dropdown-button'}>
                    {isOpen ? <FaChevronUp /> : <FaChevronDown />}
                </span>
            </div>

            <SelectContext.Provider value={selectContext}>
                {isOpen && (
                    <FloatingFocusManager
                        context={context}
                        modal={false}>
                        <div
                            className='z-index-1051'
                            ref={refs.setFloating}
                            style={floatingStyles}
                            {...getFloatingProps()}>
                            <FloatingList
                                elementsRef={elementsRef}
                                labelsRef={labelsRef}>
                                <ul
                                    ref={listRef}
                                    role='select'
                                    className='list-group mt-1'
                                    style={{ minWidth: `${containerWidth}px`, maxHeight: '30vh', overflowY: 'scroll' }}>
                                    {props.optionsLoading ? <li className='list-group-item option'>Loading...</li> : props.children}
                                </ul>
                            </FloatingList>
                        </div>
                    </FloatingFocusManager>
                )}
            </SelectContext.Provider>
        </div>
    );
}
