import { DefaultQueryParams, PaginatedResponse } from '@frontend/api-utils';
import { ClassType } from '@frontend/common';

export enum EventEntityType {
    ACCOUNT = 'account',
    ADDRESS = 'address',
    ADDRESS_ENTITY = 'address_entity',
    ANY = 'any',
    ANSWER = 'answer',
    AUTHENTICATION = 'authentication',
    ATTRIBUTE = 'attribute',
    ATTRIBUTE_LINKING = 'attribute_linking',
    BUDGET_USE = 'budget_use',
    CATEGORY = 'category',
    CERTIFICATE = 'certificate',
    CONTACT = 'contact',
    CONTACT_GROUP = 'contact_group',
    COST_CENTER = 'cost_center',
    CUSTOM_ROLE = 'custom_role',
    DEVICE = 'device',
    DOCUMENT = 'document',
    DOCUMENT_ENTITY = 'document_entity',
    EQUIPMENT = 'equipment',
    EQUIPMENT_GROUP = 'equipment_group',
    EQUIPMENT_STATE = 'equipment_state',
    GROUP = 'group',
    GROUP_MEMBER = 'group_member',
    IDENTIFICATION = 'identification',
    IOT = 'iot',
    IOT_UNKNOWN = 'iot_unknown',
    LOCKER = 'locker',
    MEDIA_FILE = 'media_file',
    MODULE = 'module',
    PACKAGE = 'package',
    PLAYLIST = 'playlist',
    PLAYLIST_ITEM = 'playlist_item',
    PROBLEM = 'problem',
    PRODUCT = 'product',
    PRODUCT_CATEGORY = 'product_category',
    PRODUCT_GROUP = 'product_group',
    PRODUCT_OWNER = 'product_owner',
    QUESTION = 'question',
    QUESTIONNAIRE = 'questionnaire',
    ROLE = 'role',
    RULE = 'rule',
    SETTING = 'setting',
    SLOT = 'slot',
    SLOT_CONSTRAINT = 'slot_constraint',
    SLOT_GROUP = 'slot_group',
    SLOT_TWIN = 'slot_twin',
    SPOT = 'spot',
    STOCK = 'stock',
    STOCK_MOVEMENT = 'stock_movement',
    STOCK_REQUIREMENT = 'stock_requirement',
    TRANSACTION = 'transaction',
    USE = 'use',
    USER = 'user',
    USER_GROUP = 'user_group',
    USER_INTERFACE = 'user_interface',
    USER_INTERFACE_BUTTON = 'user_interface_button',
    WORKFLOW = 'workflow',
    WORKFLOW_FIELD = 'workflow_field',
    WORKFLOW_STEP = 'workflow_step',
    WORKFLOW_VARIABLE = 'workflow_variable'
}

export enum EventLevel {
    DEBUG = 'debug',
    INFO = 'info',
    WARNING = 'warning',
    ERROR = 'error',
    ALERT = 'alert'
}

export enum EventType {
    TRANSACTION_STATE_CHANGED = 'transaction_state_changed',
    EQUIPMENT_STATE_CHANGED = 'equipment_state_changed',
    PACKAGE_STATE_CHANGED = 'package_state_changed',
    USER_STATE_CHANGED = 'user_state_changed',
    LOCKER_STATE_CHANGED = 'locker_state_changed',
    IOT_STATE_CHANGED = 'iot_state_changed',
    SLOT_STATE_CHANGED = 'slot_state_changed',
    TRANSACTION_TRIGGER_RECEIVED = 'transaction_trigger_received',
    EQUIPMENT_TRIGGER_RECEIVED = 'equipment_trigger_received',
    PACKAGE_TRIGGER_RECEIVED = 'package_trigger_received',
    USER_TRIGGER_RECEIVED = 'user_trigger_received',
    LOCKER_TRIGGER_RECEIVED = 'locker_trigger_received',
    IOT_TRIGGER_RECEIVED = 'iot_trigger_received',
    SLOT_TRIGGER_RECEIVED = 'slot_trigger_received',
    TRANSACTION_ACTION_TRIGGERED = 'transaction_action_triggered',
    EQUIPMENT_ACTION_TRIGGERED = 'equipment_action_triggered',
    PACKAGE_ACTION_TRIGGERED = 'package_action_triggered',
    USER_ACTION_TRIGGERED = 'user_action_triggered',
    LOCKER_ACTION_TRIGGERED = 'locker_action_triggered',
    IOT_ACTION_TRIGGERED = 'iot_action_triggered',
    SLOT_ACTION_TRIGGERED = 'slot_action_triggered',
    SLOT_SENSOR_RECEIVED = 'slot_sensor_received',
    IOT_HEARTBEAT_MISSED = 'iot_heartbeat_missed',
    IOT_HEARTBEAT_RECOVERED = 'iot_heartbeat_recovered',
    RAKINDA_MASTER_PIN_CODE_USED = 'rakinda_master_pin_code_used',
    RAKINDA_ACCESS_GRANTED = 'rakinda_access_granted',
    RAKINDA_ACCESS_DENIED = 'rakinda_access_denied',
    EMAIL_SENT = 'email_sent',
    ENTITY_SYNC_CREATED = 'entity_sync_created',
    ENTITY_SYNC_UPDATED = 'entity_sync_updated',
    ENTITY_SYNC_DELETED = 'entity_sync_deleted',
    QR_CODE_SCANNED = 'qr_code_scanned',
    NFC_BADGE_SCANNED = 'nfc_badge_scanned',
    NFC_TAG_SCANNED = 'nfc_tag_scanned',
    KEYBOARD_INPUT_ENTERED = 'keyboard_input_entered',
    LOCKER_STATUS_UPDATED = 'locker_status_updated',
    IDENTIFICATION_UPDATED = 'identification_updated',
    IDENTIFICATION_REMOVED = 'identification_removed',
    PRODUCT_CATEGORY_CREATED = 'product_category_created',
    PRODUCT_CATEGORY_UPDATED = 'product_category_updated',
    PRODUCT_IMPORTED = 'product_imported',
    EQUIPMENT_REPORTED_BROKEN = 'equipment_reported_broken'
}

export enum EventCategory {
    ACCESS_CONTROL = 'access_control',
    IDENTIFICATION = 'identification',
    WORKFLOW = 'workflow',
    IOT = 'iot',
    NOTIFICATION = 'notification',
    SYNC = 'sync',
    WEBSOCKET = 'websocket',
    DATA_IMPORT = 'data_import',
}

export interface Event {
    level: EventLevel;
    type: EventType;
    data: string;
    id: string;
    creation_timestamp: string;
    category: EventCategory;
}

export interface EventMessage {
    message: string;
    level: { severity: ClassType; icon: React.ReactNode };
    type: React.ReactElement;
    timestamp: string;
    id: string;
    category: string;
}

export interface EventEntity {
    id: string;
    event_id: string;
    entity_type: EventEntityType;
    entity_id: string;
}

export enum QueryParams {
    LEVEL = 'level',
    TYPE = 'type',
    CATEGORY = 'category'
}

export type EventListResponse = PaginatedResponse<Event>;

export type EventEntitiesListResponse = PaginatedResponse<EventEntity>;


export type EventQueryParams = DefaultQueryParams & QueryParams


export interface IEventsFilter {
    type?: EventEntityType;
    id?: string;
    from?: string;
    to?: string;
}


