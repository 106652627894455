import { APIClient, ApiQueryParams } from "@frontend/api-utils";
import { EntityType } from "@frontend/common";
import { CreateGroup, CreateGroupMember, Group, GroupListResponse, GroupMember, GroupMemberListResponse, GroupMemberQueryParams, GroupQueryParams, UpdateGroup, UpdateGroupMember } from "@frontend/group/types";

const endpoint = '/group-api/v1';

export class ProductCategoryClient extends APIClient {
    public static async fetchProductCategories(queryParams?: ApiQueryParams<GroupQueryParams>): Promise<GroupListResponse> {
        return await this.apiPaginated<GroupListResponse, GroupQueryParams>(`${endpoint}/product-categories`, queryParams);
    }

    public static async resolveProductCategories(groupIds: string[]): Promise<GroupListResponse> {
        const response = await this.post(`${endpoint}/product-categories-resolve`, { ids: groupIds });
        return await this.handleResponse<GroupListResponse>(response);
    }

    public static async deleteProductCategories(groupIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/product-categories-delete`, { ids: groupIds });
        return await this.handleVoidResponse(response);
    }

    public static async postProductCategory(accountId: string, group: CreateGroup): Promise<Group> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/product-categories`, group);
        return await this.handleResponse<Group>(response);
    }

    public static async fetchProductCategory(accountId: string, groupId: string): Promise<Group> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/product-categories/${groupId}`);
        return await this.handleResponse<Group>(response);
    }

    public static async patchProductCategory(accountId: string, groupId: string, group: UpdateGroup): Promise<Group> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/product-categories/${groupId}`, group);
        return await this.handleResponse<Group>(response);
    }

    public static async deleteProductCategory(accountId: string, groupId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/product-categories/${groupId}`);
        return await this.handleVoidResponse(response);
    }

    public static async fetchGroupsNotInGroup(accountId: string, groupId: string): Promise<GroupListResponse> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/product-categories/${groupId}/groups`);
        return await this.handleResponse<GroupListResponse>(response);
    }

    //Group members
    public static async fetchProductCategoryMembers(queryParams?: ApiQueryParams<GroupMemberQueryParams>): Promise<GroupMemberListResponse> {
        return await this.apiPaginated<GroupMemberListResponse, GroupMemberQueryParams>(`${endpoint}/product-category-members`, queryParams);
    }

    public static async resolveProductCategoryMembers(memberIds: string[]): Promise<GroupMemberListResponse> {
        const response = await this.post(`${endpoint}/product-category-members-resolve`, { ids: memberIds });
        return await this.handleResponse<GroupMemberListResponse>(response);
    }

    public static async deleteProductCategoryMembers(memberIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/product-category-members-delete`, { ids: memberIds });
        return await this.handleVoidResponse(response);
    }

    public static async postProductCategoryMember(accountId: string, groupId: string, groupMember: CreateGroupMember): Promise<GroupMember> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/product-categories/${groupId}/members`, groupMember);
        return await this.handleResponse<GroupMember>(response);
    }

    public static async postProductCategoryMemberList(accountId: string, groupId: string, entity_type: EntityType, memberIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/product-categories/${groupId}/${entity_type}s/members-list`, { ids: memberIds });
        return await this.handleVoidResponse(response);
    }

    public static async fetchProductCategoryMember(accountId: string, groupId: string, memberId: string): Promise<GroupMember> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/product-categories/${groupId}/members/${memberId}`);
        return await this.handleResponse<GroupMember>(response);
    }

    public static async patchProductCategoryMember(accountId: string, groupId: string, memberId: string, body: UpdateGroupMember): Promise<GroupMember> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/groups/${groupId}/members/${memberId}`, body);
        return await this.handleResponse<GroupMember>(response);
    }

    public static async deleteProductCategoryMember(accountId: string, groupId: string, memberId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/product-categories/${groupId}/members/${memberId}`);
        return await this.handleVoidResponse(response);
    }
}