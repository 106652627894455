import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { Token } from '@frontend/authentication-v2';
import { EntityType } from '@frontend/common';

import { PermissionTemplatesDict, UserPermissions } from '../permission';
import { CreateRestriction, Restriction, RestrictionListResponse, UpdateRestriction } from '../restriction';
import { CreateRole, Role, RoleListResponse, UpdateRole } from '../role';

const endpoint = '/authorization-api/v1';
export class AuthorizationClient extends APIClient {
    /**
     * @deprecated
     */
    public static async fetchRoles(queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<RoleListResponse> {
        return await this.apiPaginated<RoleListResponse, DefaultQueryParams>(`${endpoint}/roles`, queryParams);
    }

    /**
     * @deprecated
     */
    public static async fetchEntityRoles(type: EntityType, entityId: string, queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<RoleListResponse> {
        return await this.apiPaginated<RoleListResponse, DefaultQueryParams>(`${endpoint}/${type}s/${entityId}/roles`, queryParams);
    }

    /**
     * @deprecated
     */
    public static async postEntityRole(role: CreateRole, type: EntityType, entityId: string): Promise<Role> {
        const response = await this.post(`${endpoint}/${type}s/${entityId}/roles`, role);
        return await this.handleResponse<Role>(response);
    }

    /**
     * @deprecated
     */
    public static async fetchEntityRole(type: EntityType, entityId: string, roleId: string): Promise<Role> {
        const response = await this.fetch(`${endpoint}/${type}s/${entityId}/roles/${roleId}`);
        return await this.handleResponse<Role>(response);
    }

    /**
     * @deprecated
     */
    public static async patchEntityRole(role: UpdateRole, type: EntityType, entityId: string, roleId: string): Promise<Role> {
        const response = await this.patch(`${endpoint}/${type}s/${entityId}/roles/${roleId}`, role);
        return await this.handleResponse<Role>(response);
    }

    /**
     * @deprecated
     */
    public static async deleteEntityRole(type: EntityType, entityId: string, roleId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/${type}s/${entityId}/roles/${roleId}`);
        return await this.handleVoidResponse(response);
    }

    /**
     * Will fetch the persmissions linked to the token that is used to send the request.
     * (the users own permissions)
     */
    public static async fetchPermissions(withToken?: Token): Promise<UserPermissions> {
        const response = await this.fetch(`${endpoint}/permissions`, {}, true, withToken);
        return await this.handleResponse<UserPermissions>(response);
    }

    /**
     * Will fetch the permissions linked to the given entity_id.
     * @param type
     * @param entityId
     * @returns
     */
    public static async fetchEntityPermissions(accountId: string, type: EntityType, entityId: string): Promise<any> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/${type}s/${entityId}/permissions`);
        return await this.handleResponse<any>(response);
    }

    public static async fetchRoleTemplates(): Promise<any> {
        const response = await this.fetch(`${endpoint}/role-templates`);
        return await this.handleResponse<any>(response);
    }

    public static async fetchPermissionTemplates(): Promise<PermissionTemplatesDict> {
        const response = await this.fetch(`${endpoint}/permission-templates`);
        return await this.handleResponse<PermissionTemplatesDict>(response);
    }

    public static async fetchRestrictions(): Promise<RestrictionListResponse> {
        const response = await this.fetch(`${endpoint}/restrictions`);
        return await this.handleResponse<RestrictionListResponse>(response);
    }

    public static async fetchEntityRestrictions(
        type: EntityType,
        entityId: string,
        queryParams?: ApiQueryParams<DefaultQueryParams>
    ): Promise<RestrictionListResponse> {
        return await this.apiPaginated<RestrictionListResponse, DefaultQueryParams>(`${endpoint}/${type}s/${entityId}/restrictions`, queryParams);
    }

    public static async postEntityRestrictions(entity_type: EntityType, entity_id: string, body: CreateRestriction): Promise<Restriction> {
        const response = await this.post(`${endpoint}/${entity_type}s/${entity_id}/restrictions`, body);
        return await this.handleResponse<Restriction>(response);
    }

    public static async patchEntityRestriction(
        entity_type: EntityType,
        entity_id: string,
        restriction_id: string,
        body: UpdateRestriction
    ): Promise<Restriction> {
        const response = await this.patch(`${endpoint}/${entity_type}s/${entity_id}/restrictions/${restriction_id}`, body);
        return await this.handleResponse<Restriction>(response);
    }

    public static async deleteEntityRestriction(entity_type: EntityType, entity_id: string, restriction_id: string): Promise<void> {
        const response = await this.delete(`${endpoint}/${entity_type}s/${entity_id}/restrictions/${restriction_id}`);
        return await this.handleVoidResponse(response);
    }

    public static async postIoTSeed(accountId: string, iotId: string, body: { account_id: string }): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots/${iotId}/seed`, body);
        return this.handleResponse(response);
    }
}
