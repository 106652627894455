import { EntityType } from '@frontend/common';
import { NotificationTemplateClient } from '@frontend/notification-service/api';
import { useResolver } from '@frontend/repository';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

declare global {
    interface WindowEventMap {
        'useNotificationTemplate:request': CustomEvent<string>;
    }
}

const REQUEST_NOTIFICATION_TEMPLATE_EVENT = 'useNotificationTemplate:request';
const STORAGE_KEY = 'NOTIFTMP_REQ';

export function notificationTemplateRequest(notificationTemplateId: string): string {
    window.dispatchEvent(new CustomEvent(REQUEST_NOTIFICATION_TEMPLATE_EVENT, { detail: notificationTemplateId }));
    return STORAGE_KEY + '_loading';
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function NotificationTemplateResolver(props: Props) {
    useResolver(STORAGE_KEY, REQUEST_NOTIFICATION_TEMPLATE_EVENT, EntityType.NOTIFICATION_TEMPLATE, (ids) => NotificationTemplateClient.resolveNotificationTemplates(ids), props.dispatch);
    return <></>;
}
