import { Route } from 'react-router-dom';

import ConfigurationUser from './configuration-user/configuration-user.component';
import { StockRoutes } from './stock-user/stock-routes';

const StockRoute = StockRoutes();
export function UserRoutes() {
    return (
        <>
            <Route
                path='overview'
                element={<ConfigurationUser />}
            />
            {StockRoute}
        </>
    );
}
