import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

const DocumentOverview = lazy(() => import('../document/document-overview'));
const DocumentDetail = lazy(() => import('../document/document-detail/document-detail.component'));
const DocumentFileUpload = lazy(() => import('../document/file-upload/file-upload.component'));

export function DocumentRoutes() {
    return (
        <Route path='documents'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <DocumentOverview />
                    </AsyncComponent>
                }
            />
            <Route path=':documentId'>
                <Route
                    index
                    path='detail'
                    element={
                        <AsyncComponent>
                            <DocumentDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='file'
                    element={
                        <AsyncComponent>
                            <DocumentFileUpload />
                        </AsyncComponent>
                    }
                />
            </Route>
            <Route
                index
                element={<Navigate to='/admin/documents' />}
            />
        </Route>
    );
}
