import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

const ModuleTemplateOverview = lazy(() => import('../module-template/module-template-overview'));
const ModuleTemplateCreate = lazy(() => import('../module-template/module-template-create/module-template-create.component'));
const ModuleTemplateDetail = lazy(() => import('../module-template/module-template-detail/module-template-detail.component'));
const ModuleTemplateUpdate = lazy(() => import('../module-template/module-template-update/module-template-update.component'));
const ModuleTemplateCopy = lazy(() => import('../module-template/module-template-copy/module-template-copy.component'));

export function ModuleTemplateRoutes() {
    return (
        <Route path='module-templates'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <ModuleTemplateOverview />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <ModuleTemplateCreate />
                    </AsyncComponent>
                }
            />
            <Route path=':moduleTemplateId'>
                <Route
                    index
                    path='detail'
                    element={
                        <AsyncComponent>
                            <ModuleTemplateDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <ModuleTemplateUpdate />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='copy'
                    element={
                        <AsyncComponent>
                            <ModuleTemplateCopy />
                        </AsyncComponent>
                    }
                />
            </Route>
            <Route
                index
                element={<Navigate to='/admin/module-templates' />}
            />
        </Route>
    );
}
