import { Logger } from '@frontend/Logger';
import { ApiError } from '@frontend/api-utils';
import { EntityType } from '@frontend/common';
import { ToastUtil } from '@frontend/toast-utils';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface ErrorResponse {
    detail:
        | {
              loc: string[];
              msg: string;
              type: string;
          }[]
        | string;
}

export class ErrorHandler {
    private static statusMessages: { [key: number]: (entity_type?: EntityType) => React.ReactNode } = {
        400: (entity_type?: EntityType) => (
            <FormattedMessage
                id='error-handler.status-message.400'
                defaultMessage='The server could not handle your request'
                description='Error message for status 400'
            />
        ),
        401: (entity_type?: EntityType) => (
            <FormattedMessage
                id='error-handler.status-message.401'
                defaultMessage='Not authorized to access this resource'
                description='Error message for status 401'
            />
        ),
        403: (entity_type?: EntityType) => (
            <FormattedMessage
                id='error-handler.status-message.403'
                defaultMessage='Insufficient rights to access this resource'
                description='Error message for status 403'
            />
        ),
        404: (entity_type?: EntityType) => (
            <FormattedMessage
                id='error-handler.status-message.404'
                defaultMessage='The requested resource could not be found'
                description='Error message for status 404'
            />
        ),
        409: (entity_type?: EntityType) => (
            <FormattedMessage
                id='error-handler.status-message.409'
                defaultMessage='This {resource} already exists'
                values={{ resource: entity_type }}
                description='Error message for status 409'
            />
        ),
        422: (entity_type?: EntityType) => (
            <FormattedMessage
                id='error-handler.status-message.422'
                defaultMessage='The server could not process your request. Please check your input'
                description='Error message for status 422'
            />
        ),
        500: (entity_type?: EntityType) => (
            <FormattedMessage
                id='error-handler.status-message.500'
                defaultMessage='Internal server error. Please try again later'
                description='Error message for status 500'
            />
        )
    };

    public static handleError(props: { error: Error | ApiError; entity_type?: EntityType }) {
        Logger.error(props.error);
        if (!props.error) return ToastUtil.error('Error, try again later');
        if (Object.keys(props.error).length === 0) return ToastUtil.error('Error, try again later');
        if ((props.error as ApiError).code != undefined && ErrorHandler.statusMessages[(props.error as ApiError).code!]) {
            return ToastUtil.error(`Error`, ErrorHandler.statusMessages[(props.error as ApiError).code!](props.entity_type));
        } else if (((props.error as ApiError).json as ErrorResponse).detail) {
            const details = ((props.error as ApiError).json as ErrorResponse).detail;
            if (Array.isArray(details)) {
                details.forEach((err: { loc: string[]; msg: string; type: string }) => {
                    return ToastUtil.error(`Error`, `${err.loc[1]}: ${err.msg}`);
                });
                return;
            } else {
                return ToastUtil.error(`Error`, details);
            }
        } else {
            return ToastUtil.error('Error');
        }
    }
}
