const Unauthorized = () => {
    return (
        <div className='card d-flex flex-column w-100'>
            <div className='justify-content-center'>
                <div className='col-lg-6 col-md-7 mx-auto text-center'>
                    <h2 className='display-2 text-bolder text-primary fadeIn1 fadeInBottom mt-5'>Unauthorized</h2>
                    <h3 className='fadeIn3 fadeInBottom mt-3 text-primary'>You are not authorized to view this page.</h3>
                </div>
            </div>
        </div>
    );
};

export default Unauthorized;
