import { EntityType } from '@frontend/common';
import { QuestionnaireClient } from '@frontend/group/api';
import { useResolver } from '@frontend/repository';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

declare global {
    interface WindowEventMap {
        'useQuestionnaireMember:request': CustomEvent<string>;
    }
}

const REQUEST_QUESTIONNAIRE_MEMBER_EVENT = 'useQuestionnaireMember:request';
const STORAGE_KEY = 'QSTNR_MBR_REQ';

export function questionnaireMemberRequest(memberId: string): string {
    window.dispatchEvent(new CustomEvent(REQUEST_QUESTIONNAIRE_MEMBER_EVENT, { detail: memberId }));
    return STORAGE_KEY + '_loading';
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function questionnaireMemberResolver(props: Props) {
    useResolver(STORAGE_KEY, REQUEST_QUESTIONNAIRE_MEMBER_EVENT, EntityType.MEMBER, (ids) => QuestionnaireClient.resolveQuestionnaireMembers(ids), props.dispatch);
    return <></>;
}
