import { useNavigate } from 'react-router-dom';

import { ConfigurationProps } from './configuration-user.component';

interface ViewProps {
    navigate: (to: string) => void;
}

const useConfigurationUser = (props: ConfigurationProps): ViewProps => {
    const navigate = useNavigate();
    return { navigate };
};

export default useConfigurationUser;
