import { Dimension } from '@frontend/common';
import React from 'react';

const ID = 'dimension-badge';
export interface DimensionBadgeProps {
    dimension: Dimension;
}

export const DimensionBadge = (props: DimensionBadgeProps) => {
    return (
        <span
            id={ID}
            className='badge badge-secondary disabled'>
            H: {props.dimension.height}, W: {props.dimension.width}, D: {props.dimension.depth}
        </span>
    );
};

export default DimensionBadge;
