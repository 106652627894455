import { EntityType } from '@frontend/common';
import { QuestionClient } from '@frontend/question/api';
import { useResolver } from '@frontend/repository';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

declare global {
    interface WindowEventMap {
        'useQuestion:request': CustomEvent<string>;
    }
}

const REQUEST_QUESTION_EVENT = 'useQuestion:request';
const STORAGE_KEY = 'QUEST_REQ';

export function questionRequest(questionId: string): string {
    window.dispatchEvent(new CustomEvent(REQUEST_QUESTION_EVENT, { detail: questionId }));
    return STORAGE_KEY + '_loading';
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function QuestionResolver(props: Props) {
    useResolver(STORAGE_KEY, REQUEST_QUESTION_EVENT, EntityType.QUESTION, (ids) => QuestionClient.resolveQuestions(ids), props.dispatch);
    return <></>;
}
