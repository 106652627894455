import { Dimension } from '@frontend/common';
import { useEffect, useState } from 'react';

import { DimensionInputProps } from './dimension-input.component';

interface ViewProps {
    dimension: Dimension;
    changeDimension: React.Dispatch<React.SetStateAction<Dimension>>;
}

const useDimensionInput = (props: DimensionInputProps): ViewProps => {
    const [dimension, changeDimension] = useState<Dimension>(isDimensionData(props.value) ? (props.value as Dimension) : { height: 0, width: 0, depth: 0 });

    useEffect(() => {
        props.onChange(dimension);
    }, [dimension]);

    return {
        dimension,
        changeDimension
    };
};

export default useDimensionInput;
export const isDimensionData = (constraintData: any): constraintData is Dimension => {
    return (
        constraintData &&
        (constraintData as Dimension).height !== undefined &&
        (constraintData as Dimension).width !== undefined &&
        (constraintData as Dimension).depth !== undefined
    );
};
