import { Logger } from '@frontend/Logger';
import { ClassType } from '@frontend/common';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface TabInfo {
    id: string;
    index?: number;
    active: boolean;
    type: ClassType;
}

export interface WizardInfo {
    tabs: TabInfo[];
    submitted: boolean;
}
export interface WizardState {
    [key: string]: WizardInfo;
}

const initialState: WizardState = {};
const initialWizardState: WizardInfo = {
    tabs: [],
    submitted: false
};

export const wizardSlice = createSlice({
    name: 'wizard',
    initialState,
    reducers: {
        initWizard(state, action: PayloadAction<{ wizardId: string; initialValues?: WizardInfo }>) {
            if (state[action.payload.wizardId]) {
                Logger.warn('wizard already initialized - skipping init to prevent overwrite', {}, { formId: action.payload.wizardId });
            } else {
                state[action.payload.wizardId] = { ...initialWizardState, ...action.payload.initialValues };
            }
        },
        initTab(state, action: PayloadAction<{ wizardId: string; tab: TabInfo }>) {
            if (state[action.payload.wizardId]) {
                const tab = action.payload.tab;
                if (state[action.payload.wizardId].tabs.length === 0) {
                    tab.active = true;
                    tab.type = ClassType.PRIMARY;
                    state[action.payload.wizardId].tabs.push(tab);
                } else {
                    const found = state[action.payload.wizardId].tabs.find((t) => t.id === action.payload.tab.id);
                    if (!found) state[action.payload.wizardId].tabs.push(action.payload.tab);
                }
                const length = state[action.payload.wizardId].tabs.length;
                if (length > 1) {
                    state[action.payload.wizardId].tabs.sort((a, b) => (a.index == undefined ? length : a.index) - (b.index == undefined ? length : b.index));
                }
            }
        },
        tabSelect(state, action: PayloadAction<{ wizardId: string; id: string }>) {
            if (state[action.payload.wizardId]) {
                state[action.payload.wizardId].tabs.forEach((tab) => {
                    tab.active = tab.id == action.payload.id;
                    if ([ClassType.PRIMARY, ClassType.SECONDARY].includes(tab.type)) {
                        if (tab.id == action.payload.id) tab.type = ClassType.PRIMARY;
                        else tab.type = ClassType.SECONDARY;
                    }
                });
            }
        },
        tabUpdate(state, action: PayloadAction<{ wizardId: string; id: string; type: ClassType }>) {
            if (state[action.payload.wizardId]) {
                state[action.payload.wizardId].tabs.forEach((tab) => tab.id == action.payload.id && (tab.type = action.payload.type));
            }
        },
        nextTab(state, action: PayloadAction<{ wizardId: string }>) {
            if (state[action.payload.wizardId]) {
                const activeTab = state[action.payload.wizardId].tabs.find((tab) => tab.active);
                if (activeTab) {
                    const index = state[action.payload.wizardId].tabs.indexOf(activeTab);
                    if (index < state[action.payload.wizardId].tabs.length - 1) {
                        state[action.payload.wizardId].tabs[index] = {
                            ...state[action.payload.wizardId].tabs[index],
                            active: false,
                            type: [ClassType.PRIMARY, ClassType.SECONDARY].includes(state[action.payload.wizardId].tabs[index].type)
                                ? ClassType.SECONDARY
                                : state[action.payload.wizardId].tabs[index].type
                        };
                        state[action.payload.wizardId].tabs[index + 1] = {
                            ...state[action.payload.wizardId].tabs[index + 1],
                            active: true,
                            type: [ClassType.PRIMARY, ClassType.SECONDARY].includes(state[action.payload.wizardId].tabs[index + 1].type)
                                ? ClassType.PRIMARY
                                : state[action.payload.wizardId].tabs[index + 1].type
                        };
                    }
                }
            }
        },
        prevTab(state, action: PayloadAction<{ wizardId: string }>) {
            if (state[action.payload.wizardId]) {
                const activeTab = state[action.payload.wizardId].tabs.find((tab) => tab.active);
                if (activeTab) {
                    const index = state[action.payload.wizardId].tabs.indexOf(activeTab);
                    if (index > 0) {
                        state[action.payload.wizardId].tabs[index] = {
                            ...state[action.payload.wizardId].tabs[index],
                            active: false,
                            type: [ClassType.PRIMARY, ClassType.SECONDARY].includes(state[action.payload.wizardId].tabs[index].type)
                                ? ClassType.SECONDARY
                                : state[action.payload.wizardId].tabs[index].type
                        };
                        state[action.payload.wizardId].tabs[index - 1] = {
                            ...state[action.payload.wizardId].tabs[index - 1],
                            active: true,
                            type: [ClassType.PRIMARY, ClassType.SECONDARY].includes(state[action.payload.wizardId].tabs[index - 1].type)
                                ? ClassType.PRIMARY
                                : state[action.payload.wizardId].tabs[index - 1].type
                        };
                    }
                }
            }
        }
    }
});

export const wizardStore = { wizards: wizardSlice.reducer };
export const { initWizard, initTab, tabSelect, tabUpdate, nextTab, prevTab } = wizardSlice.actions;
