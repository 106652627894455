import { AccountClient } from '@frontend/account/api';
import { AuthenticationManager } from '@frontend/authentication-v2';
import { getCookie, setCookie } from '@frontend/common';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const TELLOPORT_SELECTED_ACCOUNT_COOKIE = 'tp_prev_sel_acc';
const EVENT_TYPE = `cookie:${TELLOPORT_SELECTED_ACCOUNT_COOKIE}`;

/**
 * @deprecated
 */
export const useAccount = () => {
    const [availableAccounts, changeAvailableAccounts] = useState<string[] | null>(null);
    const [cookieAccount, changeCookieAccount] = useState<string | null>(getCookie(TELLOPORT_SELECTED_ACCOUNT_COOKIE) ?? null);
    const [tokenAccount, changeTokenAccount] = useState<string | null>(null);
    const [currentAccount, changeCurrentAccount] = useState<string | null>(cookieAccount);
    const location = useLocation();
    const navigate = useNavigate();

    const listener = () => {
        changeCookieAccount(getCookie(TELLOPORT_SELECTED_ACCOUNT_COOKIE) ?? null);
    };

    useEffect(() => {
        AuthenticationManager.getInstance()
            .waitForToken()
            .then((res) => {
                changeTokenAccount(res.account_id);
            });
        AccountClient.fetchAccounts()
            .then((res) => {
                changeAvailableAccounts(res.results.map((account) => account.id));
            })
            .catch((err) => {
                navigate('/unauthorized');
            });

        window.addEventListener(EVENT_TYPE, listener);
        return () => window.removeEventListener(EVENT_TYPE, listener);
    }, []);

    const updateCurrentAccount = (account: string | null) => {
        if (account) {
            setCookie(TELLOPORT_SELECTED_ACCOUNT_COOKIE, account);
            window.dispatchEvent(new CustomEvent(EVENT_TYPE, { detail: {} }));
        }
    };

    useEffect(() => {
        if (tokenAccount && availableAccounts) {
            if (cookieAccount && availableAccounts.includes(cookieAccount)) {
                changeCurrentAccount(cookieAccount);
            } else {
                updateCurrentAccount(tokenAccount);
            }
        }
    }, [availableAccounts, cookieAccount, tokenAccount]);

    useEffect(() => {
        const splitPath = location.pathname.split('/');
        const found = splitPath.find((path) => path.includes('accounts'));
        if (found) {
            const accountId = splitPath[splitPath.indexOf(found) + 1];
            if (accountId) {
                updateCurrentAccount(accountId);
            }
        }
        if (currentAccount) {
            window.dispatchEvent(new CustomEvent(EVENT_TYPE, { detail: {} }));
        }
    }, [location]);

    return { currentAccount, updateCurrentAccount, availableAccounts, tokenAccount };
};
