import { ApiQueryParams, DefaultQueryParams, PaginatedResponse } from '@frontend/api-utils';
import { EntityType, useAppSelector } from '@frontend/common';
import { useLocale } from '@frontend/lang';
import { MqttBroker } from '@frontend/pub-sub';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { useAccountRepository } from '../account/account-repository';
import { DefaultListValues, ListInfo, allSelect, fetch as func, initList, listStore, refresh, select } from './list-slice';

interface Props<T> {
    dispatch: ThunkDispatch<any, any, Action>;
    fetch: (arg?: ApiQueryParams<DefaultQueryParams | string | number>) => Promise<PaginatedResponse<T>>;
    eventListener?: EntityType;
    defaultListValues?: DefaultListValues;
}

export function useListRepository<T>(props: Props<T>) {
    const [listId] = useState<string>(crypto.randomUUID());
    const store = useAppSelector(useSelector, listStore);
    const list: ListInfo | undefined = store[listId];
    const { store: accountStore } = useAccountRepository();
    const { locale } = useLocale();
    const pageIndex = list?.pageIndex ?? 0;
    const pageSize = list?.pageSize ?? 25;
    const sorting = list?.sorting ?? [];
    const filter = list?.filter ? list.filter : props.defaultListValues?.filter ? props.defaultListValues.filter : {};
    const [refreshIndex, changeRefreshIndex] = useState<string>('');

    const currentAccountRef = useRef(accountStore.currentAccount);

    useEffect(() => {
        if (props.defaultListValues) props.dispatch(initList({ listId, defaultListValues: props.defaultListValues }));
    }, [listId, props.defaultListValues]);
    useEffect(() => {
        currentAccountRef.current = accountStore.currentAccount;
    }, [accountStore.currentAccount]);
    useEffect(() => {
        if (list && list.refresh) {
            refreshList();
            props.dispatch(refresh({ listId: listId, refresh: false }));
        }
    }, [list?.refresh]);

    const refreshList = () => {
        props.dispatch(
            func({
                key: listId,
                params: {
                    index: pageIndex.toString(),
                    size: pageSize.toString(),
                    account_id: currentAccountRef.current,
                    ...(sorting.length > 0 && {
                        sort_field: sorting[0].id,
                        sort_direction: sorting[0].desc ? 'desc' : 'asc'
                    }),
                    ...filter,
                    language: locale
                },
                func: props.fetch
            })
        );
    };

    const onSelect = (objId: string) => {
        props.dispatch(
            select({
                listId: listId,
                objId: objId
            })
        );
    };

    const onAllSelect = () => {
        props.dispatch(
            allSelect({
                listId: listId,
                index: pageIndex,
                size: pageSize
            })
        );
    };

    useEffect(() => {
        if (props.eventListener == undefined || !accountStore.currentAccount) return;
        const onEvent = (message: string, data: any) => {
            refreshList();
        };
        const topic = props.eventListener === EntityType.ACCOUNT ? 'account' : 'accounts/' + accountStore.currentAccount + '/' + props.eventListener;
        MqttBroker.getInstance().subscribe(listId, topic, onEvent);
        return () => {
            MqttBroker.getInstance().unSubscribe(listId, topic);
        };
    }, [accountStore.currentAccount, refreshIndex]);

    useEffect(() => {
        if (!currentAccountRef.current || !locale) return;

        //Extra check for changes because react will call this function multiple times
        const newIndex = JSON.stringify({
            sorting: sorting,
            pageIndex: pageIndex,
            pageSize: pageSize,
            account: currentAccountRef.current,
            filters: filter
        });

        if (newIndex != refreshIndex) {
            refreshList();
            changeRefreshIndex(() => newIndex);
        }
    }, [sorting, pageIndex, pageSize, currentAccountRef.current, list?.filter]);

    const data = useMemo(() => {
        if (list == undefined || list.results == null) return null;
        return list.results as T[];
    }, [store]);

    const totalCount = useMemo(() => {
        if (list == undefined || list.count == null) return 0;
        return list.count;
    }, [store]);

    const selected = useMemo(() => {
        if (list == undefined) return [];
        return list.selected as T[];
    }, [store]);

    const isAllSelected = useMemo(() => {
        if (list && list.results) {
            if (list.results.length == 0) return false;
            const startPos = pageIndex * pageSize;
            const relevantObjects = list.results!.slice(startPos, startPos + pageSize);
            return relevantObjects.every((obj) => list.selected!.find((o) => o.id == obj.id) != undefined);
        }
        return false;
    }, [store, list]);

    return {
        listId,
        totalCount,
        data,
        selected,
        isAllSelected,
        onSelect,
        onAllSelect
    };
}

export default useListRepository;
