import React, { Dispatch, useEffect, useRef, useState } from 'react';

import { SelectOption, SingleOption, explodeOptions } from '../select-types';
import { CreatableSelectV2Props } from './creatable-select-v2.component';

interface ViewProps<T> {
    ref: React.RefObject<HTMLDivElement | null>;
    setRef: Dispatch<React.SetStateAction<React.RefObject<HTMLDivElement | null>>>;
    value?: string;
    onSearch: (value: string) => void;
    searchResultOptions: SelectOption<T>[];

    onSelect: (option: SingleOption<T> | null) => void;
    selected: SingleOption<T> | null;
    setSelected: React.Dispatch<React.SetStateAction<SingleOption<T> | null>>;
}

const useCreatableSelectV2 = <T>(props: CreatableSelectV2Props<T>): ViewProps<T> => {
    const [value, setValue] = useState<string | undefined>(undefined);
    const [ref, setRef] = useState<React.RefObject<HTMLDivElement | null>>(useRef<HTMLDivElement>(null));
    const [selected, setSelected] = useState<SingleOption<T> | null>(null);
    const [searchResultOptions, setSearchResultOptions] = useState<SelectOption<T>[]>([]);
    const [options, setOptions] = useState<SelectOption<T>[]>(props.options || []);

    useEffect(() => {
        if (props.searchValueOverwrite !== undefined) onSearch(props.searchValueOverwrite);
    }, [props.searchValueOverwrite]);

    useEffect(() => {
        if (!ref.current) return;
        ref.current.addEventListener('keydown', (e) => {
            if (e.key === 'Enter' || e.key === 'Tab') {
                e.preventDefault();
                e.stopPropagation();
                const value = (ref.current as any).value;
                if (value && value !== '') {
                    setOptions((prev) => [...prev, { label: value, value: value }]);
                    onSelect({ label: value, value: value });
                }
            }
        });
        return () => {
            if (!ref.current) return;
            ref.current.addEventListener('keydown', (e) => console.log(e));
        };
    }, [ref]);

    useEffect(() => {
        if (props.value) {
            if (options) {
                const opt = explodeOptions(options);
                const found = opt.find((op) => op.value === props.value);
                if (found && found.value !== selected?.value) onSelect(found);
            }
        }
    }, [props.value, options]);

    useEffect(() => {
        if (value && value !== '' && value !== selected?.value) {
            setSearchResultOptions(options?.filter((op) => op.label.toLowerCase().includes(value.toLowerCase())));
        } else {
            setSearchResultOptions(options || []);
        }
    }, [options]);

    const onSelect = (option: SingleOption<T> | null) => {
        setSelected(option);
        setValue(option?.label || undefined);
        props.onSelect && props.onSelect(option);
    };

    const onSearch = (value: string) => {
        setSelected(null);
        setValue(value);
        props.onSearch && props.onSearch(value);
        if (value && value !== '') {
            setSearchResultOptions(options?.filter((op) => op.label.toLowerCase().includes(value.toLowerCase())));
        } else {
            setSearchResultOptions(options || []);
        }
    };
    return {
        ref,
        setRef,
        onSearch,
        setSelected,
        onSelect,
        searchResultOptions,
        selected,
        value
    };
};

export default useCreatableSelectV2;
