import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { Address, AddressEntityQueryParams, AddressListResponse, CreateAddress, UpdateAddress } from '@frontend/address/types';

const endpoint = '/address-api/v1';
export class AddressClient extends APIClient {
    public static async fetchAddresses(queryParams?: ApiQueryParams<DefaultQueryParams & AddressEntityQueryParams>): Promise<AddressListResponse> {
        return await this.apiPaginated<AddressListResponse, DefaultQueryParams & AddressEntityQueryParams>(`${endpoint}/addresses`, queryParams);
    }

    public static async resolveAddresses(ids: string[]): Promise<AddressListResponse> {
        const response = await this.post(`${endpoint}/addresses-resolve`, { ids: ids });
        return await this.handleResponse<AddressListResponse>(response);
    }

    public static async deleteAddresses(ids: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/addresses-delete`, { ids: ids });
        return await this.handleVoidResponse(response);
    }

    public static async fetchAccountAddresses(accountId: string, queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<AddressListResponse> {
        return await this.apiPaginated<AddressListResponse, DefaultQueryParams>(`${endpoint}/accounts/${accountId}/addresses`, queryParams);
    }

    public static async postAddress(accountId: string, body: CreateAddress): Promise<Address> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/addresses`, body);
        return await this.handleResponse<Address>(response);
    }

    public static async fetchAccountAddress(accountId: string, addressId: string): Promise<Address> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/addresses/${addressId}`);
        return await this.handleResponse<Address>(response);
    }

    public static async patchAddress(accountId: string, addressId: string, body: UpdateAddress): Promise<Address> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/addresses/${addressId}`, body);
        return await this.handleResponse<Address>(response);
    }

    public static async deleteAddress(accountId: string, addressId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/addresses/${addressId}`);
        return await this.handleVoidResponse(response);
    }

    public static async postIoTSeed(accountId: string, iotId: string, body: { account_id: string }): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots/${iotId}/seed`, body);
        return this.handleResponse(response);
    }
}
