import { ClassType } from '@frontend/common';
import { CommonMessage } from '@frontend/lang';
import React from 'react';
import { BsExclamationCircle } from 'react-icons/bs';
import { FaInfoCircle, FaRegCheckCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';

interface ToastLayout {
    title?: React.ReactNode;
    message?: React.ReactNode;
}

interface ToastSuccessLayout<T> extends ToastLayout {
    onSuccess?: (object: T) => void;
    onVoidSuccess?: () => void;
}

interface ToastErrorLayout extends ToastLayout {
    onError?: () => void;
}
export class ToastUtil {
    static error(title: React.ReactNode, message?: React.ReactNode) {
        toast.error(...this.generateToastConfig(title, message, ClassType.DANGER));
    }
    static warning(title: React.ReactNode, message?: React.ReactNode) {
        toast.warning(...this.generateToastConfig(title, message, ClassType.WARNING));
    }
    static success(title: React.ReactNode, message?: React.ReactNode) {
        toast.success(...this.generateToastConfig(title, message, ClassType.SUCCESS));
    }
    static info(title: React.ReactNode, message?: React.ReactNode) {
        toast.info(...this.generateToastConfig(title, message, ClassType.INFO));
    }

    static promise<T>(promise: () => Promise<T | void>, pending: ToastLayout, success: ToastSuccessLayout<T>, error: ToastErrorLayout) {
        toast
            .promise(promise, {
                pending: {
                    render: () => {
                        return this.generateToastConfig(pending.title ? pending.title : CommonMessage.TOAST.PROMISE.PENDING, pending.message)[0];
                    }
                },
                success: {
                    render: () => {
                        return this.generateToastConfig(success.title ? success.title : CommonMessage.TOAST.PROMISE.SUCCESS, success.message)[0];
                    }
                },
                error: {
                    render: () => {
                        return this.generateToastConfig(error.title ? error.title : CommonMessage.TOAST.PROMISE.ERROR, error.message)[0];
                    }
                }
            })
            .then((obj) => {
                if (obj) {
                    success.onSuccess && success.onSuccess(obj);
                } else {
                    success.onVoidSuccess && success.onVoidSuccess();
                }
            })
            .catch(() => error.onError && error.onError());
    }

    static show(title: React.ReactNode, message?: React.ReactNode, type?: ClassType): void {
        this.run(this.generateToastConfig(title, message, type), type);
    }

    static generateToastConfig(title: React.ReactNode, message?: React.ReactNode, type?: ClassType): [content: JSX.Element, options?: any] {
        return [
            // eslint-disable-next-line react/jsx-key
            <div>
                <div>
                    <strong className='me-auto font-weight-bolder h6'>{title ? title : 'Info'}</strong>
                </div>
                {message && <div className='toast-body'>{message}</div>}
            </div>,
            { icon: ToastUtil.getIcon(type) }
        ];
    }

    private static getIcon(type?: ClassType): JSX.Element {
        switch (type) {
            case ClassType.DANGER:
            case ClassType.WARNING:
                return <BsExclamationCircle className={`text-${type}`} />;
            case ClassType.SUCCESS:
                return <FaRegCheckCircle className='text-success' />;
            case ClassType.INFO:
            case ClassType.DARK:
            case ClassType.LIGHT:
            case ClassType.PRIMARY:
            case ClassType.SECONDARY:
            default:
                return <FaInfoCircle className={`text-${type ? type : 'primary'}`} />;
        }
    }

    private static run(params: [content: JSX.Element, options?: any], type?: ClassType): void {
        switch (type) {
            case ClassType.DANGER:
                toast.error(...params);
                break;
            case ClassType.WARNING:
                toast.warning(...params);
                break;
            case ClassType.SUCCESS:
                toast.success(...params);
                break;
            case ClassType.INFO:
                toast.info(...params);
                break;
            case ClassType.DARK:
                toast.dark(...params);
                break;
            case ClassType.LIGHT:
            case ClassType.PRIMARY:
            case ClassType.SECONDARY:
            default:
                toast(...params);
        }
    }
}
