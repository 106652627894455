import { fetchEntityRoles } from '@frontend/authorization';

import { DropdownButtonGroupProps } from './dropdown-button-group.component';

interface ViewProps {
    onClick: (e: any) => void;
    fetchRoles: () => void;
}

const useDropdownButtonGroup = (props: DropdownButtonGroupProps): ViewProps => {
    const onClick = (e: any) => {
        e.stopPropagation();
        if (!props.showJSON) return;
        const page = window.open('', '_blank');
        const pre = page?.document.createElement('pre');
        if (!pre) return;
        pre.textContent = JSON.stringify(props.showJSON.original, null, 2);
        page?.document.body.appendChild(pre);
        page?.document.close();
    };

    const fetchRoles = () => {
        if (!props.showRoles || !props.dispatch) return;
        props.dispatch(fetchEntityRoles({ type: props.showRoles.entityType, entityId: props.showRoles.entityId, queryParams: { index: '0', size: '25' } }));
    };
    return {
        onClick,
        fetchRoles
    };
};

export default useDropdownButtonGroup;
