import { EntityType } from '@frontend/common';
import { ObjectBadge, ObjectBadgeProps } from '@frontend/rendering/badge';
import { userInterfaceRequest } from '@frontend/user-interface/events';
import { UserInterface } from '@frontend/user-interface/types';
import React from 'react';

export const UserInterfaceBadge = (props: ObjectBadgeProps<UserInterface>) => {
    return (
        <ObjectBadge<UserInterface>
            id={props.id}
            type={EntityType.USER_INTERFACE}
            mapDisplayName={(ac: UserInterface) => ac.name}
            resolve={userInterfaceRequest}
            key={props.id}
            navigateString={(object: UserInterface) => `/accounts/${object.account_id}/user-interfaces/${object.id}`}
            {...props}
        />
    );
};
