import { AccountClient } from '@frontend/account/api';
import { Account } from '@frontend/account/types';
import { useAccountRepository } from '@frontend/repository';
import { useEffect, useState } from 'react';

interface ViewProps {
    onChangeMembership: (value: string | null) => void;
    accounts: Account[];
    selectedAccount: Account | null;
}

const useAccountMembership = (): ViewProps => {
    const accountInfo = useAccountRepository();
    const [accounts, changeAccounts] = useState<Account[]>([]);
    const [selectedAccount, changeSelectedAccount] = useState<Account | null>(null);

    useEffect(() => {
        AccountClient.fetchAccounts()
            .then((res) => changeAccounts(res.results))
            .catch((e) => {});
    }, []);

    useEffect(() => {
        if (accountInfo.store.currentAccount) {
            changeSelectedAccount(accounts.find((acc) => acc.id === accountInfo.store.currentAccount) ?? null);
        }
    }, [accountInfo.store, accounts]);

    const onChangeMembership = (value: string | null) => {
        accountInfo.updateCurrentAccount(value);
    };

    return {
        onChangeMembership,
        selectedAccount,
        accounts
    };
};

export default useAccountMembership;
