import { APIClient, ApiQueryParams } from "@frontend/api-utils";
import { CreateUserCertificate, UpdateUserCertificate, UserCertificate, UserCertificateListResponse, UserCertificateQueryParams } from "@frontend/certificate/types";

const endpoint = '/certificate-api/v1';
export class UserCertificateClient extends APIClient {
    public static async fetchUserCertificates(queryParams?: ApiQueryParams<UserCertificateQueryParams>): Promise<UserCertificateListResponse> {
        return await this.apiPaginated<UserCertificateListResponse, UserCertificateQueryParams>(`${endpoint}/user-certificates`, queryParams);
    }

    public static async resolveUserCertificates(ids: string[]): Promise<UserCertificateListResponse> {
        const response = await this.post(`${endpoint}/user-certificates-resolve`, { ids: ids });
        return await this.handleResponse<UserCertificateListResponse>(response);
    }

    public static async deleteUserCertificates(ids: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/user-certificates-delete`, { ids: ids });
        return await this.handleVoidResponse(response);
    }

    public static async postUserCertificate(accountId: string, certificateId: string, body: CreateUserCertificate): Promise<UserCertificate> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/certificates/${certificateId}/users`, body);
        return await this.handleResponse<UserCertificate>(response);
    }

    public static async fetchUserCertificate(accountId: string, certificateId: string, userCertificateId: string): Promise<UserCertificate> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/certificates/${certificateId}/users/${userCertificateId}`);
        return await this.handleResponse<UserCertificate>(response);
    }

    public static async patchUserCertificate(accountId: string, certificateId: string, userCertificateId: string, body: UpdateUserCertificate): Promise<UserCertificate> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/certificates/${certificateId}/users/${userCertificateId}`, body);
        return await this.handleResponse<UserCertificate>(response);
    }

    public static async deleteUserCertificate(accountId: string, certificateId: string, userCertificateId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/certificates/${certificateId}/users/${userCertificateId}`);
        return await this.handleVoidResponse(response);
    }
}