import React from 'react';

import useBreadcrumbs from './breadcrumbs.controller';

const ID = 'breadcrumbs';

export const Breadcrumbs = () => {
    const viewProps = useBreadcrumbs();

    return (
        <nav
            id={ID}
            aria-label='breadcrumbs'>
            <ol className='d-flex justify-content-center justify-content-md-start align-items-center breadcrumb bg-transparent m-0 p-0'>
                {viewProps.breadcrumbs}
            </ol>
            <h5 className='text-light'>{viewProps.title}</h5>
        </nav>
    );
};
