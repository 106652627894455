import { ClassType } from '@frontend/common';
import React from 'react';
import { GoDotFill } from 'react-icons/go';

export interface StatusBadgeProps<T> {
    value: T | null;
    type?: 'badge' | 'dots';
    size?: number;
    colorMap: Map<T[], ClassType>;
}

export const StatusBadge = <T extends string>(props: StatusBadgeProps<T>) => {
    if (!props.value) return <></>;
    switch (props.type) {
        case 'badge':
            return (
                <span className='d-flex flex-row align-items-center m-1'>
                    <span className={`badge badge-${getValueFromArrayMap(props.colorMap, props.value)}`}>{props.value as string}</span>
                </span>
            );
        case 'dots':
            return (
                <GoDotFill
                    size={props.size || undefined}
                    className={`text-${getValueFromArrayMap(props.colorMap, props.value)}`}
                />
            );
        default:
            return (
                <span className='d-flex flex-row align-items-center m-1'>
                    <span className={`badge badge-${getValueFromArrayMap(props.colorMap, props.value)}`}>{props.value as string}</span>
                </span>
            );
    }
};

function getValueFromArrayMap<T>(map: Map<T[], ClassType>, value: T): ClassType {
    for (const [k, v] of map) {
        if (k.includes(value)) return v;
    }
    return ClassType.PRIMARY;
}
