import { APIClient, ApiQueryParams } from '@frontend/api-utils';
import { CreateCustomRole, CustomRole, CustomRoleListResponse, CustomRoleQueryParams, UpdateCustomRole } from '@frontend/role/types';

const endpoint = '/authorization-api/v1';

export class CustomRoleClient extends APIClient {
    public static async fetchCustomRoles(queryParams?: ApiQueryParams<CustomRoleQueryParams>): Promise<CustomRoleListResponse> {
        return await this.apiPaginated<CustomRoleListResponse, CustomRoleQueryParams>(`${endpoint}/custom-roles`, queryParams);
    }

    static async resolveCustomRoles(roleIds: string[]): Promise<CustomRoleListResponse> {
        const response = await this.post(`${endpoint}/custom-roles-resolve`, { ids: roleIds });
        return await this.handleResponse<CustomRoleListResponse>(response);
    }

    public static async deleteCustomRoles(roleIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/custom-roles-delete`, { ids: roleIds });
        return await this.handleVoidResponse(response);
    }

    public static async postCustomRole(accountId: string, role: CreateCustomRole): Promise<CustomRole> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/custom-roles`, role);
        return await this.handleResponse<CustomRole>(response);
    }

    public static async fetchCustomRole(accountId: string, roleId: string): Promise<CustomRole> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/custom-roles/${roleId}`);
        return await this.handleResponse<CustomRole>(response);
    }

    public static async patchCustomRole(accountId: string, roleId: string, role: UpdateCustomRole): Promise<CustomRole> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/custom-roles/${roleId}`, role);
        return await this.handleResponse<CustomRole>(response);
    }

    public static async deleteCustomRole(accountId: string, roleId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/custom-roles/${roleId}`);
        return await this.handleVoidResponse(response);
    }
}