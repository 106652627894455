import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { NotificationEmail, NotificationEmailListResponse, NotificationEmailQueryParams, CreateNotificationEmail, UpdateNotificationEmail } from '@frontend/notification-service/types';


const endpoint = '/notification-api/v1';

export class NotificationEmailClient extends APIClient {
    public static async fetchNotificationEmails(queryParams?: ApiQueryParams<DefaultQueryParams | NotificationEmailQueryParams>): Promise<NotificationEmailListResponse> {
        return await this.apiPaginated<NotificationEmailListResponse, DefaultQueryParams | NotificationEmailQueryParams>(`${endpoint}/emails`, queryParams);
    }

    public static async resolveNotificationEmails(emailIds: string[]): Promise<NotificationEmailListResponse> {
        const response = await this.post(`${endpoint}/emails-resolve`, { ids: emailIds });
        return await this.handleResponse<NotificationEmailListResponse>(response);
    }

    public static async deleteNotificationEmails(emailIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/emails-delete`, { ids: emailIds });
        return await this.handleVoidResponse(response);
    }

    public static async postNotificationEmail(accountId: string, email: CreateNotificationEmail): Promise<NotificationEmail> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/emails`, email);
        return await this.handleResponse<NotificationEmail>(response);
    }

    public static async fetchNotificationEmail(accountId: string, emailId: string): Promise<NotificationEmail> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/emails/${emailId}`);
        return await this.handleResponse<NotificationEmail>(response);
    }

    public static async patchNotificationEmail(accountId: string, emailId: string, email: UpdateNotificationEmail): Promise<NotificationEmail> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/emails/${emailId}`, email);
        return await this.handleResponse<NotificationEmail>(response);
    }

    public static async deleteNotificationEmail(accountId: string, emailId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/emails/${emailId}`);
        return await this.handleVoidResponse(response);
    }

    public static async postIoTSeed(accountId: string, iotId: string, body: { account_id: string }): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots/${iotId}/seed`, body);
        return this.handleResponse(response);
    }

    public static async sendTestNotification(accountId: string): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/notification-channels/email/send-test-notification`, {});
        return this.handleResponse(response);
    }

    public static async copyNotificationEmail(accountId: string, emailId: string, body: { account_id: string }): Promise<NotificationEmail> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/emails/${emailId}/copy`, body);
        return await this.handleResponse<NotificationEmail>(response);
    }
}
