import { certificateRequest } from '@frontend/certificate/events';
import { Certificate } from '@frontend/certificate/types';
import { EntityType } from '@frontend/common';
import { ObjectBadge } from '@frontend/rendering/badge';
import React from 'react';

export interface CertificateBadgeProps {
    id?: string;
    name?: string;
    className?: string;
}

export const CertificateBadge = (props: CertificateBadgeProps) => {
    return (
        <ObjectBadge<Certificate>
            id={props.id}
            type={EntityType.CERTIFICATE}
            mapDisplayName={(ce: Certificate) => ce.code}
            resolve={certificateRequest}
            key={props.id}
            navigateString={(object: Certificate) => `/accounts/${object.account_id}/certificates/${object.id}`}
            {...props}
        />
    );
};
