import { EntityType } from '@frontend/common';
import { moduleRequest } from '@frontend/module/events';
import { Module } from '@frontend/module/types';
import { ObjectBadge, ObjectBadgeProps } from '@frontend/rendering/badge';
import React from 'react';

export const ModuleBadge = (props: ObjectBadgeProps<Module>) => {
    return (
        <ObjectBadge<Module>
            id={props.id}
            type={EntityType.MODULE}
            mapDisplayName={(module: Module) => module.name ?? module.index}
            resolve={moduleRequest}
            key={props.id}
            navigateString={(object: Module) => `/accounts/${object.account_id}/spots/${object.spot_id}/modules/${object.id}`}
            {...props}
        />
    );
};
