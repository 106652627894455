import { EntityType } from '@frontend/common';
import { documentRequest } from '@frontend/document/events';
import { Document } from '@frontend/document/types';
import { ObjectBadge, ObjectBadgeProps } from '@frontend/rendering/badge';
import React from 'react';

export const DocumentBadge = (props: ObjectBadgeProps<Document>) => {
    return (
        <ObjectBadge<Document>
            id={props.id}
            type={EntityType.DOCUMENT}
            mapDisplayName={(document: Document) => document.name}
            resolve={documentRequest}
            key={props.id}
            navigateString={(object: Document) => `/accounts/${object.account_id}/documents/${object.id}`}
            {...props}
        />
    );
};
