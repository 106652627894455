import { CertificateClient } from '@frontend/certificate/api';
import { EntityType } from '@frontend/common';
import { useResolver } from '@frontend/repository';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

declare global {
    interface WindowEventMap {
        'useCertificate:request': CustomEvent<string>;
    }
}

const REQUEST_CERTIFICATE_EVENT = 'useCertificate:request';
const STORAGE_KEY = 'CERT_REQ';

export function certificateRequest(certificateId: string): string {
    window.dispatchEvent(new CustomEvent(REQUEST_CERTIFICATE_EVENT, { detail: certificateId }));
    return STORAGE_KEY + '_loading';
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function CertificateResolver(props: Props) {
    useResolver(STORAGE_KEY, REQUEST_CERTIFICATE_EVENT, EntityType.CERTIFICATE, (ids) => CertificateClient.resolveCertificates(ids), props.dispatch);
    return <></>;
}
