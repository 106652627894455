import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { ConstraintRoutes } from '../constraint/constraint-routes';
import { SlotRoutes } from '../slot/slot-routes';

const ModuleCreate = lazy(() => import('../module/module-create/module-create.component'));
const ModuleDetail = lazy(() => import('../module/module-detail/module-detail.component'));
const ModuleUpdate = lazy(() => import('../module/module-update/module-update.component'));

const ConstraintRoute = ConstraintRoutes();
const SlotRoute = SlotRoutes();

export function ModuleRoutes() {
    return (
        <Route path='modules'>
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <ModuleCreate />
                    </AsyncComponent>
                }
            />
            <Route path=':moduleId'>
                <Route
                    index
                    path='detail'
                    element={
                        <AsyncComponent>
                            <ModuleDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <ModuleUpdate />
                        </AsyncComponent>
                    }
                />
                {ConstraintRoute}
                {SlotRoute}
            </Route>
            <Route
                index
                element={<Navigate to='../detail' />}
            />
        </Route>
    );
}
