import { PubSubConnection } from "@frontend/pub-sub";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { PackageEventHandler } from "./package-event-handler";


/**
 * @deprecated
 */
export class PackageServiceEventListener extends PubSubConnection {
    private static instance: PackageServiceEventListener | null = null;
    #packageEventHandler: PackageEventHandler;

    private constructor(dispatch: ThunkDispatch<any, any, Action>) {
        super('package');
        this.#packageEventHandler = new PackageEventHandler(dispatch);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>): PackageServiceEventListener {
        if (this.instance == null) {
            this.instance = new PackageServiceEventListener(dispatch);
        }
        return this.instance;
    }

    protected override onMessageEvent(event: MessageEvent<string>): void {
        this.#packageEventHandler.onMessageEvent(event);
    }
}