import { PaginatedResponse } from '@frontend/api-utils';

import { EventCategory, EventLevel, EventQueryParams, EventType } from './event';

export interface DefectLog {
    id: string;
    account_id: string;
    type: EventType;
    level: EventLevel;
    category: EventCategory;
    data: string | null;
    user_id: string;
    problem_id: string;
    creation_timestamp: string;
}

export enum IQueryParams {
    PROBLEM_ID = 'problem_id',
    USER_ID = 'user_id'
}

export type DefectLogQueryParams = IQueryParams & EventQueryParams;

export type DefectLogListResponse = PaginatedResponse<DefectLog>;
