import { AuthenticationManager } from '@frontend/authentication-v2';
import { ICONS } from '@frontend/icons';
import { CommonMessage } from '@frontend/lang';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import { FaLock, FaUser, FaUserAlt } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { StringPlaceholder } from '../placeholders/string-placeholder.component';
import useProfile from './profile.controller';

export interface ProfileProps {
    dispatch: ThunkDispatch<any, any, Action>;
}

export const Profile = (props: ProfileProps) => {
    const navigate = useNavigate();
    const viewProps = useProfile(props);

    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <div className='card d-flex flex-row align-items-center p-3 mb-3'>
                        <div className='card d-flex p-4'>
                            <FaUser />
                            <div className='mask bg-gradient-secondary border-radius-lg'></div>
                        </div>

                        <div className='d-flex flex-column ms-3'>
                            <StringPlaceholder
                                loaded={!!viewProps.user}
                                type='glow'>
                                <>
                                    <h5>
                                        {viewProps.user?.first_name} {viewProps.user?.last_name}
                                    </h5>
                                </>
                            </StringPlaceholder>
                        </div>

                        <div className='d-flex w-100 justify-content-end align-middle align-items-center mt-3'>
                            <button
                                className='btn btn-primary me-2'
                                onClick={() => AuthenticationManager.getInstance().doLogout()}>
                                <div className='d-flex flex-row align-items-center'>
                                    <FaUserAlt />
                                    <span className='ms-2'>
                                        <FormattedMessage
                                            id='SettingsModal.SignOutButton'
                                            description='The title in the sign out button in the settingsModal.'
                                            defaultMessage='Sign out'
                                        />
                                    </span>
                                </div>
                            </button>
                            <button
                                className='btn btn-primary me-2'
                                onClick={() => navigate('../change-password')}>
                                <div className='d-flex flex-row align-items-center'>
                                    <ICONS.BUTTON.LOGOUT />
                                    <span className='ms-2'>{CommonMessage.BUTTONS.CHANGE(CommonMessage.OBJECTS.IDENTIFICATION.PASSWORD)}</span>
                                </div>
                            </button>
                            <button
                                className='btn btn-primary me-2'
                                onClick={() => navigate(`/admin/accounts/${viewProps.user?.account_id}/users/${viewProps.user?.id}/permissions`)}>
                                <div className='d-flex flex-row align-items-center'>
                                    <FaLock />
                                    <span className='ms-2'>{CommonMessage.OBJECTS.PERMISSION.PLURAL}</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
