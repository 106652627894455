import { EntityType } from '@frontend/common';
import { AsyncComponent } from '@frontend/elements';
import { IdentificationType } from '@frontend/identification/types';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

import IdentificationDetail from '../identification/identification-detail/identification-detail.component';
import IdentificationUpdate from '../identification/identification-update/identification-update.component';

const BudgetUseCreate = lazy(() => import('../budget-use/budget-use-create/budget-use-create.component'));
const IdentificationCreate = lazy(() => import('../identification/identification-create/identification-create.component'));
const LinkBudgetToUsers = lazy(() => import('./link-budgets-to-user/link-budget-to-users.component'));
const UserCertificateCreate = lazy(() => import('../user-certificate/user-certificate-create/user-certificate-create.component'));
const UserCertificateDetail = lazy(() => import('../user-certificate/user-certificate-detail/user-certificate-detail.component'));
const UserCertificateUpdate = lazy(() => import('../user-certificate/user-certificate-update/user-certificate-update.component'));
const UserContactInformationUpdate = lazy(() => import('../user/user-contact-information-update/user-contact-information-update.component'));
const UserCreate = lazy(() => import('../user/user-create/user-create.component'));
const UserDetail = lazy(() => import('../user/user-detail/user-detail.component'));
const UserOverview = lazy(() => import('../user/user-overview'));
const UserUpdate = lazy(() => import('../user/user-update/user-update.component'));
export function UserRoutes() {
    return (
        <Route path='users'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <UserOverview />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <UserCreate />
                    </AsyncComponent>
                }
            />
            <Route path=':userId'>
                <Route
                    index
                    path='detail'
                    element={
                        <AsyncComponent>
                            <UserDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <UserUpdate />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='contact-info/update'
                    element={
                        <AsyncComponent>
                            <UserContactInformationUpdate />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='add-basic-auth'
                    element={
                        <IdentificationCreate
                            entity_type={EntityType.USER}
                            paramKey='userId'
                            type={IdentificationType.BASIC_AUTH}
                        />
                    }
                />
                <Route
                    path='add-budget-use'
                    element={
                        <AsyncComponent>
                            <BudgetUseCreate />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='add-budgets'
                    element={
                        <AsyncComponent>
                            <LinkBudgetToUsers />
                        </AsyncComponent>
                    }
                />
                <Route path='user-certificates'>
                    <Route
                        path='create'
                        element={
                            <AsyncComponent>
                                <UserCertificateCreate />
                            </AsyncComponent>
                        }
                    />
                </Route>
                <Route path='certificates'>
                    <Route path=':certificateId'>
                        <Route path='user-certificates'>
                            <Route path=':userCertificateId'>
                                <Route
                                    index
                                    path='detail'
                                    element={
                                        <AsyncComponent>
                                            <UserCertificateDetail />
                                        </AsyncComponent>
                                    }
                                />
                                <Route
                                    path='update'
                                    element={
                                        <AsyncComponent>
                                            <UserCertificateUpdate />
                                        </AsyncComponent>
                                    }
                                />
                            </Route>
                        </Route>
                    </Route>
                </Route>
                <Route path='identifications'>
                    <Route path=':identificationType'>
                        <Route
                            path='create'
                            element={<IdentificationCreate />}
                        />
                        <Route path=':identificationId'>
                            <Route
                                path='detail'
                                element={
                                    <AsyncComponent>
                                        <IdentificationDetail />
                                    </AsyncComponent>
                                }
                            />
                            <Route
                                path='update'
                                element={<IdentificationUpdate />}
                            />
                        </Route>
                    </Route>
                </Route>
                <Route
                    index
                    element={<Navigate to='./detail' />}
                />
            </Route>
            <Route
                index
                element={<Navigate to='./users' />}
            />
        </Route>
    );
}
