import { IdentificationClient } from '@frontend/identification/api';
import { Identification } from '@frontend/identification/types';
import { useDetailRepository } from '@frontend/repository';

interface ViewProps {
    object: Identification | null;
    showDeleteModal: boolean;
    changeShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
    onDeleteConfirmed: () => void;
    onUpdate: () => void;
}

const useIdentificationDetail = (): ViewProps => {
    const repository = useDetailRepository<Identification>(
        (accountId: string, identificationId: string) => IdentificationClient.fetchIdentification(accountId, identificationId),
        (accountId: string, identificationId: string) => IdentificationClient.deleteIdentification(accountId, identificationId),
        ['accountId', 'identificationId'],
        'identifications',
        undefined
    );

    return {
        ...repository
    };
};

export default useIdentificationDetail;
