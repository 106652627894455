import { EntityType } from '@frontend/common';
import { ProductCategoryClient } from '@frontend/group/api';
import { GroupBadge } from '@frontend/group/badge';
import { Group } from '@frontend/group/types';
import { CommonMessage } from '@frontend/lang';
import { useAccountRepository } from '@frontend/repository';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

interface Props {
    filter: string;
}
export const CategorySearch = (props: Props) => {
    const {
        store: { currentAccount }
    } = useAccountRepository();
    const [results, changeResults] = useState<Group[]>([]);

    useEffect(() => {
        if (props.filter !== '')
            ProductCategoryClient.fetchProductCategories({ account_id: currentAccount, search: props.filter }).then((result) => {
                changeResults(result.results);
            });
    }, [props.filter, currentAccount]);

    if (results.length === 0) return null;
    return (
        <div className='p-1 mb-3'>
            <span className='d-flex flex-row justify-content-between align-items-end'>
                <h6 className='ms-1 mb-0 pb-0'>{CommonMessage.OBJECTS.CATEGORY.PLURAL}</h6>
                <Link
                    className='text-xs font-weight-bold underline text-secondary me-1'
                    to='/admin/product-categories'>
                    Show all
                </Link>
            </span>
            <hr className='horizontal dark mt-0' />
            {results.map((result) => (
                <GroupBadge
                    type={EntityType.PRODUCT_CATEGORY}
                    id={result.id}
                />
            ))}
        </div>
    );
};
