import React from 'react';
import { FormattedMessage } from 'react-intl';

export const Dropzone = {
    DROP_YOUR_FILE_HERE: (
        <FormattedMessage
            id='Dropzone.DROP_YOUR_FILE_HERE'
            description='Drop your file here or click to browse'
            defaultMessage='Drop your file here or click to browse'
        />
    ),
    BROWSE: (
        <FormattedMessage
            id='Dropzone.BROWSE'
            description='Browse'
            defaultMessage='Browse'
        />
    )
};
