import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

const ContactGroupCreate = lazy(() => import('../contact-group/contact-group-create/contact-group-create.component'));
const ContactGroupOverview = lazy(() => import('../contact-group/contact-group-overview/contact-group-overview'));
const ContactGroupDetail = lazy(() => import('../contact-group/contact-group-detail/contact-group-detail.component'));
const ContactGroupMemberAdd = lazy(() => import('../contact-group/contact-group-member-add/contact-group-member-add.component'));
const ContactGroupUpdate = lazy(() => import('../contact-group/contact-group-update/contact-group-update.component'));

export function ContactGroupRoutes() {
    return (
        <Route path='contact-groups'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <ContactGroupOverview />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <ContactGroupCreate />
                    </AsyncComponent>
                }
            />
            <Route path=':groupId'>
                <Route
                    index
                    path='detail'
                    element={
                        <AsyncComponent>
                            <ContactGroupDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <ContactGroupUpdate />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='members/add'
                    element={
                        <AsyncComponent>
                            <ContactGroupMemberAdd />
                        </AsyncComponent>
                    }
                />
            </Route>
            <Route
                index
                element={<Navigate to='/admin/contact-groups' />}
            />
        </Route>
    );
}
