import { lazy } from 'react';
import { Route } from 'react-router-dom';

const ImportOverview = lazy(() => import('./import-overview'));
const JobDetail = lazy(() => import('./jobs/job-detail/job-detail.component'));

export function ImportRoutes() {
    return (
        <Route path='imports'>
            <Route
                index
                element={<ImportOverview />}
            />
            <Route path=':jobId'>
                <Route
                    path='detail'
                    element={<JobDetail />}
                />
            </Route>
        </Route>
    );
}
