import React from 'react';
import { FormattedMessage } from 'react-intl';

export const Lists = {
    LOAD_MORE: (
        <FormattedMessage
            id='Lists.LOAD_MORE'
            description='Load more text on the bottom of a list'
            defaultMessage='Load more'
        />
    ),
    MORE: (
        <FormattedMessage
            id='Lists.MORE'
            description='More text on the bottom of a list'
            defaultMessage='More'
        />
    ),
    LESS: (
        <FormattedMessage
            id='Lists.LESS'
            description='Less text on the bottom of a list'
            defaultMessage='Less'
        />
    ),
    OVERVIEW: (
        <FormattedMessage
            id='Lists.OVERVIEW'
            description='Overview text used to go to a lists'
            defaultMessage='Overview'
        />
    )
};
