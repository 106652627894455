import { APIClient, ApiQueryParams } from "@frontend/api-utils";
import { Token } from "@frontend/authentication-v2";
import { BudgetUse, BudgetUseListResponse, BudgetUseQueryParams, CreateBudgetUse } from "@frontend/budget/types";

const endpoint = '/budget-api/v1';
export class BudgetUseClient extends APIClient {
    public static async fetchBudgetUses(queryParams?: ApiQueryParams<BudgetUseQueryParams>, withCredentials?: Token): Promise<BudgetUseListResponse> {
        return await this.apiPaginated<BudgetUseListResponse, BudgetUseQueryParams>(`${endpoint}/budget-uses`, queryParams, withCredentials);
    }

    public static async postBudgetUse(accountId: string, userId: string, budgetUse: CreateBudgetUse): Promise<BudgetUse> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/users/${userId}/uses`, budgetUse);
        return await this.handleResponse<BudgetUse>(response);
    }

    public static async fetchBudgetUse(accountId: string, userId: string, budgetUseId: string): Promise<BudgetUse> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/users/${userId}/uses/${budgetUseId}`);
        return await this.handleResponse<BudgetUse>(response);
    }

    public static async deleteBudgetUse(accountId: string, userId: string, budgetUseId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/users/${userId}/uses/${budgetUseId}`);
        return await this.handleVoidResponse(response);
    }
}