import { APIClient } from "@frontend/api-utils";
import { Equipment, EquipmentProblem } from "@frontend/equipment/types";

const endpoint = '/equipment-api/v1'

export class EquipmentProblemClient extends APIClient {
    public static async postEquipmentProblem(accountId: string, equipmentId: string, body: EquipmentProblem): Promise<Equipment> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/equipments/${equipmentId}/problems`, body);
        return this.handleResponse<Equipment>(response);
    }
}