import { Locale } from '@frontend/common';
import { IContact } from '@frontend/contact/types';

export class Contact implements IContact {
    user_id: string | null;
    alias: string | null;
    first_name: string;
    last_name: string | null;
    email: string | null;
    mobile: string | null;
    email_channel: boolean | null;
    mobile_channel: boolean | null;
    whatsapp_channel: boolean | null;
    language: Locale;
    update_timestamp: string;
    id: string;
    account_id: string;
    creation_timestamp: string;

    constructor(contact: IContact) {
        this.user_id = contact.user_id;
        this.alias = contact.alias;
        this.first_name = contact.first_name;
        this.last_name = contact.last_name;
        this.email = contact.email;
        this.mobile = contact.mobile;
        this.email_channel = contact.email_channel;
        this.mobile_channel = contact.mobile_channel;
        this.whatsapp_channel = contact.whatsapp_channel;
        this.language = contact.language;
        this.update_timestamp = contact.update_timestamp;
        this.id = contact.id;
        this.account_id = contact.account_id;
        this.creation_timestamp = contact.creation_timestamp;
    }

    getFullName(): string {
        return `${this.first_name} ${this.last_name || ''}`;
    }

    getDisplayName(): string {
        return this.alias || this.getFullName();
    }
}
