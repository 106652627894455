import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { Variant, AttributeQueryParams, VariantListResponse, CreateVariant, UpdateVariant } from '@frontend/variant/types';

const endpoint = '/variant-api/v1';
export class VariantClient extends APIClient {
    public static async fetchVariants(queryParams?: ApiQueryParams<DefaultQueryParams & AttributeQueryParams>): Promise<VariantListResponse> {
        return await this.apiPaginated<VariantListResponse, DefaultQueryParams & AttributeQueryParams>(`${endpoint}/variants`, queryParams);
    }

    public static async resolveVariants(ids: string[]): Promise<VariantListResponse> {
        const response = await this.post(`${endpoint}/variants-resolve`, { ids: ids });
        return await this.handleResponse<VariantListResponse>(response);
    }

    public static async deleteVariants(ids: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/variants-delete`, { ids: ids });
        return await this.handleVoidResponse(response);
    }

    public static async fetchAccountVariants(accountId: string, productId: string, queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<VariantListResponse> {
        return await this.apiPaginated<VariantListResponse, DefaultQueryParams>(`${endpoint}/accounts/${accountId}/products/${productId}/variants`, queryParams);
    }

    public static async postVariant(accountId: string, productId: string, body: CreateVariant): Promise<Variant> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/products/${productId}/variants`, body);
        return await this.handleResponse<Variant>(response);
    }

    public static async fetchAccountVariant(accountId: string, productId: string, variantId: string): Promise<Variant> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/products/${productId}/variants/${variantId}`);
        return await this.handleResponse<Variant>(response);
    }

    public static async patchVariant(accountId: string, productId: string, variantId: string, body: UpdateVariant): Promise<Variant> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/products/${productId}/variants/${variantId}`, body);
        return await this.handleResponse<Variant>(response);
    }

    public static async deleteVariant(accountId: string, productId: string, variantId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/products/${productId}/variants/${variantId}`);
        return await this.handleVoidResponse(response);
    }

    public static async postIoTSeed(accountId: string, iotId: string, body: { account_id: string }): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots/${iotId}/seed`, body);
        return this.handleResponse(response);
    }
}
