import { APIClient } from "@frontend/api-utils";
import { EntityType } from "@frontend/common";

const endpoint = '/impex-api/v1'

export class ImpexEntityTypeFieldEnumClient extends APIClient {
    public static async fetchEntityTypeFields(entityType: EntityType): Promise<string[]> {
        const response = await this.fetch(`${endpoint}/entity-types/${entityType}/fields`);
        return this.handleResponse<string[]>(response);
    }
}
