import { equipmentCertificateRequest } from '@frontend/certificate/events';
import { EquipmentCertificate } from '@frontend/certificate/types';
import { EntityType } from '@frontend/common';
import { ObjectBadge } from '@frontend/rendering/badge';
import React from 'react';

export interface EquipmentCertificateBadgeProps {
    id?: string;
    name?: string;
    className?: string;
}

export const EquipmentCertificateBadge = (props: EquipmentCertificateBadgeProps) => {
    return (
        <ObjectBadge<EquipmentCertificate>
            id={props.id}
            type={EntityType.EQUIPMENT_CERTIFICATE}
            mapDisplayName={(ce: EquipmentCertificate) => ce.id}
            resolve={equipmentCertificateRequest}
            key={props.id}
            navigateString={(object: EquipmentCertificate) =>
                `/accounts/${object.account_id}/equipments/${object.equipment_id}/certificates/${object.certificate_id}/equipment-certificates/${object.id}`
            }
            {...props}
        />
    );
};
